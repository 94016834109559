import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Activity {
  /**
   * id
   */
  id!: number;

  /**
   * Activity date
   */
  @observable
  @Type(() => Date)
  date!: Date;

  /**
   * Activity time
   */
  @observable
  time!: string;

  /**
   * Activity type
   */
  @observable
  type!: number;

  /**
   * Activity category
   */
  @observable
  category!: number;

  /**
   * Activity state
   */
  @observable
  state!: number;

  /**
   * ps_id
   */
  @observable
  @Expose({ name: "ps_id" })
  psId!: number;

  /**
   * entity_type
   */
  @observable
  @Expose({ name: "entity_type" })
  entityType!: number;

  /**
   * Comment
   */
  @observable
  comment?: string;

  /**
   * Author
   */
  @observable
  @Expose({ name: "author_id" })
  authorId?: number;

  /**
   * Assignee
   */
  @observable
  @Expose({ name: "assignee_id" })
  assigneeId!: number;

  @observable
  @Expose({ name: "author_name" })
  authorName?: string;

  @observable
  source!: number;

  @observable
  subject!: string;

  @observable
  note?: string;

  @observable
  @Expose({ name: "daktela_id" })
  daktelaId!: number;

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  /**
   * State changed at
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "state_changed_at" })
  stateChangedAt!: Date;

  /**
   * After deadline?
   */
  @observable
  @Expose({ name: "after_deadline" })
  afterDeadline!: boolean;

  static ValidationRules = {
    id: { required: true, number: true },
    date: { required: true },
    time: { required: true },
    type: { required: true, number: true },
    category: { required: true, number: true },
    state: { required: true, number: true },
    psId: { required: true, number: true },
    entityType: { required: true, number: true },
    authorId: { number: true },
    assigneeId: { required: true, number: true },
    source: { required: true, number: true },
    subject: { required: true },
    daktelaId: { required: true, number: true },
    createdAt: { required: true },
    updatedAt: { required: true },
    stateChangedAt: { required: true },
    afterDeadline: { required: true },
  };
}

export enum ActivityStatus {
  Planned = 0,
  Cancelled,
  Resolved,
  New,
  InProgress,
}
