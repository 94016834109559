import { Check, Select } from "@frui.ts/bootstrap";
import { registerView, ViewComponent } from "@frui.ts/views";
import LoadingOverlay from "controls/loadingOverlay";
import { ZipInput } from "controls/zipInput";
import { observer, Observer } from "mobx-react-lite";
import React from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import EditAddressesViewModel from "viewModels/shops/detail/edit/editAddressesViewModel";
import { AddressModel, IShopAddressesViewModel } from "viewModels/shops/detail/types";
import NewAddressViewModel from "viewModels/shops/newShop/newAddressesViewModel";
import unfocused from "utils/unfocused";
import { StringInput } from "controls/stringInput";

const AddressItem: React.SFC<{
  address: AddressModel;
  vm: IShopAddressesViewModel;
  ta: (attribute: string) => string;
  index: number;
}> = observer(({ address, vm, ta, index }) => {
  return (
    <Card className="my-4" body>
      <Row>
        <Form.Group as={Col} md={2} className="required">
          <Form.Label>{ta("type")}</Form.Label>
          <Select
            target={address}
            property="addressTypeId"
            items={vm.types(address)}
            mode="key"
            textProperty="name"
            allowEmpty
            isNumeric
            disabled={address.isMandatory}
          />
        </Form.Group>
        <Form.Group as={Col} className="required">
          <Form.Label>{ta("name")}</Form.Label>
          <StringInput trim={true} target={address} property="name" />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>{ta("subname")}</Form.Label>
          <StringInput trim={true} target={address} property="subname" />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} className="required">
          <Form.Label>{ta("street")}</Form.Label>
          <StringInput trim={true} target={address} property="street" />
        </Form.Group>
        <Form.Group as={Col} md={2} className="required">
          <Form.Label>{ta("zip")}</Form.Label>
          <ZipInput target={address} property="zip" type={"number"} />
        </Form.Group>
        <Form.Group as={Col} md={3} className="required">
          <Form.Label>{ta("city")}</Form.Label>
          <StringInput trim={true} target={address} property="city" />
        </Form.Group>
        <Form.Group as={Col} md={3} className="required">
          <Form.Label>{ta("country")}</Form.Label>
          <Select
            target={address}
            property="countryId"
            items={vm.countries || []}
            mode="key"
            textProperty="name"
            isNumeric
            allowEmpty
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} md={8}>
          <Form.Label>{ta("description")}</Form.Label>
          <StringInput trim={true} target={address} property="description" />
        </Form.Group>
        <Form.Group as={Col}>
          <Check
            custom
            target={address}
            property="default"
            label={ta("default")}
            id={`default${index}`}
            disabled={address.isMandatory}
          />
        </Form.Group>
      </Row>

      {!address.isMandatory && (
        <Button onClick={() => vm.removeAddress(address)} variant="outline-danger">
          &#215; {vm.localizationService.translateGeneral("new_parcel_shop.remove_address")}
        </Button>
      )}
    </Card>
  );
});

const addressView: ViewComponent<IShopAddressesViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("address", attribute);
  };

  return (
    <>
      {vm.items?.map((x, i) => (
        <AddressItem key={i} index={i} address={x} vm={vm} ta={ta} />
      ))}

      <Observer>
        {() => (vm.customErrorMessage ? <Alert variant="danger">{vm.customErrorMessage}</Alert> : <React.Fragment />)}
      </Observer>

      <Form.Row>
        <Form.Group as={Col}>
          <Button onClick={vm.addNewAddress} onMouseUp={unfocused} variant="light" size="lg" block>
            &#43; {tg("new_parcel_shop.add_address")}
          </Button>
        </Form.Group>
      </Form.Row>

      <LoadingOverlay loading={!vm.items} />
    </>
  );
});

registerView(addressView, NewAddressViewModel);
registerView(addressView, EditAddressesViewModel);
export default addressView;
