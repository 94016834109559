import { CollectionCheck, Input, Check } from "@frui.ts/bootstrap";
import { DataTable } from "@frui.ts/dataviews";
import { View, ViewComponent } from "@frui.ts/views";
import FilterButton from "controls/filterButton";
import HeaderRow from "controls/headerRow";
import ItemsSelector from "controls/itemsSelector";
import LoadingOverlay from "controls/loadingOverlay";
import PagingInfo from "controls/pagingInfo";
import { createColumnsContext, DataColumn } from "controls/tableHelpers";
import TablePager from "controls/tablePager";
import PaymentTerminal from "manualEntities/paymentTerminal";
import { observer, Observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import { PaymentTerminalImportType } from "viewModels/paymentTerminals/paymentTerminalsImportViewModel";
import PaymentTerminalsViewModel from "viewModels/paymentTerminals/paymentTerminalsViewModel";
import { formatDate } from "../../utils/helpers";
import "./style.scss";

const tableColumns: DataColumn<PaymentTerminal, PaymentTerminalsViewModel>[] = [
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("payment_terminal", "terminal_id")}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.terminal_id}
          onClear={() => vm.clearFilter("terminal_id")}>
          <Form.Group>
            <Input placeholder={tg("search")} size="sm" data-search target={vm.filter} property="terminal_id" />
          </Form.Group>
        </FilterButton>
      </>
    ),
    property: "terminal_id",
    sortable: true,
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("payment_terminal", "terminal_sn")}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.terminal_sn}
          onClear={() => vm.clearFilter("terminal_sn")}>
          <Form.Group>
            <Input placeholder={tg("search")} size="sm" data-search target={vm.filter} property="terminal_sn" />
          </Form.Group>
        </FilterButton>
      </>
    ),
    property: "terminal_sn",
    sortable: true,
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("payment_terminal", "status")}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.status?.length}
          onClear={() => vm.clearFilter("status")}>
          <Form.Group>
            <Form.Group className="check-list">
              {vm.statuses.map(x => (
                <CollectionCheck key={x.id} custom target={vm.filter} property="status" value={x.id} label={x.label} />
              ))}
            </Form.Group>
          </Form.Group>
        </FilterButton>
      </>
    ),
    property: "status",
    cellFormatter: ({ value, item, context }) => {
      const status = context.vm.getStatus(value);
      return (
        <td key={item.id} className="terminal_status">
          <div className={`badge ${status?.color}`}>{status?.name}</div>
          {item.quarantine_to && ` ${context.ta("payment_terminal", "date_to")} ${formatDate(item.quarantine_to)}`}
        </td>
      );
    },
    sortable: true,
  },
  {
    titleFactory: ({ vm, tg, tm, filterButtonProps }) => (
      <>
        {tm("parcel_shop", 1)}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.ps_ids?.length}
          onClear={() => vm.clearFilter("ps_ids")}>
          <ItemsSelector
            target={vm.filter}
            property="ps_ids"
            searchTextProperty="parcel_shop_name"
            items={vm.allowedParcelShops || []}
            searchPlaceholder={tg("search")}
            selectAllText={tg("select_all")}
          />
        </FilterButton>
      </>
    ),
    property: "ps_id",
    valueFormatter: ({ item }) => item.ps?.name,
    tag: "received",
    sortable: true,
  },
  {
    titleFactory: ({ vm, tm, tg, filterButtonProps }) => (
      <>
        {tm("depo", 1)}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.depo_ids?.length}
          onClear={() => vm.clearFilter("depo_ids")}>
          <ItemsSelector
            target={vm.filter}
            property="depo_ids"
            searchTextProperty="depo_name"
            items={vm.depos || []}
            searchPlaceholder={tg("search")}
            selectAllText={tg("select_all")}
          />
        </FilterButton>
      </>
    ),
    property: "ps",
    valueFormatter: ({ value, context }) => value && context.vm.getDepoName(value.depo_id),
    sortable: true,
  },
];

const PaymentTerminalList: ViewComponent<PaymentTerminalsViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateModel: tm } = vm.localizationService;

  const columnsContext = createColumnsContext(vm);

  return (
    <div className="messages-list">
      <HeaderRow title={tm("payment_terminal", 5)}>
        <div className="d-flex justify-content-end">
          <Check
            custom
            inline
            target={vm.filter}
            property="show_discarded_terminals"
            label={tg("payment_terminal.show_discarded_terminals")}
            onValueChanged={vm.applyFilterAndLoad}
          />
        </div>
      </HeaderRow>

      <ButtonGroup className="mr-2">
        <Button variant="outline-primary" onClick={() => vm.onImportClick(PaymentTerminalImportType.Default)}>
          {tg("payment_terminal.import.title")}
        </Button>
        <Button variant="outline-primary" onClick={() => vm.onImportClick(PaymentTerminalImportType.AfterService)}>
          {tg("payment_terminal.import.title_after_service")}
        </Button>
        <Button variant="outline-primary" onClick={() => vm.onImportClick(PaymentTerminalImportType.MassPsAssign)}>
          {tg("payment_terminal.import.title_mass_ps_assign")}
        </Button>
      </ButtonGroup>

      <PagingInfo paging={vm.currentPaging} modelName="result" localization={vm.localizationService} />

      <div className="react-bootstrap-table">
        <DataTable
          className="table custom-table messages table-striped"
          pagingFilter={vm.pagingFilter}
          itemKey="id"
          items={vm.items || []}
          columns={tableColumns}
          context={columnsContext}
          onColumnSort={vm.sortByColumn}
          rowProps={item => ({ onClick: () => vm.openDetail(item) })}
        />
      </div>
      <TablePager vm={vm} />
      <LoadingOverlay loading={vm.busyWatcher.isBusy} />
      <Observer>{() => <View vm={vm.paymentTerminalsImportViewModel} />}</Observer>
    </div>
  );
});

export default PaymentTerminalList;
