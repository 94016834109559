import { ScreenBase, watchBusy } from "@frui.ts/screens";
import ParcelBoxDto, { ParcelBoxFilter } from "entities/parcelBoxDto";
import { action, observable, runInAction, toJS } from "mobx";
import LocalizationService from "services/localizationService";
import ListViewModelBase from "viewModels/listViewModelBase";
import ParcelBoxRepository from "data/repositories/parcelBoxRepository";
import EnumService from "services/enum";
import { accessPointSelectOption, depoSelectOption, formatDepoName, parcelBoxStateSelectOption } from "utils/helpers";
import ParcelBoxDetailViewModel from "./parcelBoxDetailViewModel";
import EventBus, { Events } from "services/eventBus";
import { ISelectItem } from "@frui.ts/views";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import UserContext from "services/userContext";
import { bound } from "@frui.ts/helpers";
import { ParcelShopFilter } from "manualEntities/parcelShop";
import ParcelShopStatus from "../../manualEntities/parcelShopStatus";
import AccessPointPartners from "../../models/enumerations/accessPointPartners";

export default class ParcelBoxesViewModel extends ListViewModelBase<ParcelBoxDto, ParcelBoxFilter, ScreenBase> {
  @observable.shallow allowedParcelShops?: ISelectItem[];

  constructor(
    private repository: ParcelBoxRepository,
    public localizationService: LocalizationService,
    private enums: EnumService,
    private parcelBoxDetailViewModelFactory: ReturnType<typeof ParcelBoxDetailViewModel.Factory>,
    private eventBus: EventBus,
    private parcelShopsRepository: ParcelShopRepository,
    private userContext: UserContext
  ) {
    super();
    this.navigationName = "parcel_box";
    this.name = localizationService.translateModel("parcel_box_station", 1);
    this.pagingFilter.limit = 10;
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.ParcelBoxes.Changed, this.loadData));
  }

  onInitialize() {
    void this.loadEnums();
    void this.resetFilterAndLoad();
  }

  @bound
  private async loadEnums() {
    const filter = new ParcelShopFilter();
    filter.depo_ids = toJS(this.userContext.assignedDepos);
    const pb = this.enums.value("access_point_partners", AccessPointPartners.PARCEL_BOX, true);
    if (pb) {
      filter.type_ids = [pb.id];
    }
    filter.states = [ParcelShopStatus.Active, ParcelShopStatus.Unactive];

    const [parcelShops] = await this.parcelShopsRepository.getList({ offset: 0, limit: 1000, sortColumn: "name" }, filter);
    runInAction(() => {
      this.allowedParcelShops = parcelShops.map(x => ({ value: x.id, label: x.name } as ISelectItem));
    });
  }

  get depos() {
    return this.enums.values("depos").map(depoSelectOption);
  }

  get states() {
    return this.enums.values("parcel_box_status").map(parcelBoxStateSelectOption);
  }

  get types() {
    const parcelBoxTypes: string[] = [
      AccessPointPartners.PARCEL_BOX,
      AccessPointPartners.PARCEL_BOX_POTENTIAL,
      AccessPointPartners.ALZA_BOX,
    ];

    return this.enums
      .values("access_point_partners")
      .filter(item => parcelBoxTypes.includes(item.code))
      .map(accessPointSelectOption);
  }

  getDepoName(id: number) {
    const depo = this.enums.value("depos", id);
    return formatDepoName(depo);
  }

  getTypeName(id: number | undefined) {
    if (id !== undefined) {
      const type = this.enums.value("access_point_partners", id);
      return type?.name ?? "";
    }
    return "";
  }

  getState(id: number | undefined) {
    return id !== undefined ? this.enums.value("parcel_box_status", id) : undefined;
  }

  @action.bound
  @watchBusy
  async loadData() {
    return this.repository.getBoxes(this.pagingFilter, this.appliedFilter).then(data => {
      this.setData(data);
    });
  }

  openDetail(item: ParcelBoxDto): void | Promise<any> {
    return this.tryActivateChild(this.parcelBoxDetailViewModelFactory(item));
  }

  protected resetFilterValues(filter: ParcelBoxFilter) {
    filter.ps_ids = [];
    filter.ps_name = undefined;
    filter.depo_ids = [];
    filter.depo_name = undefined;
    filter.state = [];
    filter.state_name = undefined;
    filter.type_ids = [];
    filter.type_name = undefined;
  }

  // eslint-disable-next-line
  @action.bound clearFilter(section: keyof ParcelBoxFilter) {
    switch (section) {
      case "ps_ids":
      case "depo_ids":
      case "state":
        this.filter[section] = [];
        break;

      default:
        this.filter[section] = undefined as any;
        break;
    }

    void this.applyFilterAndLoad();
  }

  protected async findNavigationChild(navigationName: string) {
    if (navigationName) {
      const item = await this.repository.getBox(Number(navigationName));
      return this.parcelBoxDetailViewModelFactory(item);
    }

    return undefined;
  }
}
