import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class CreateAttachmentDto {
  @observable
  @Expose({ name: "entity_type" })
  entityType!: string;

  @observable
  @Expose({ name: "document_type_id" })
  documentTypeId!: string;

  @observable
  @Expose({ name: "ps_id" })
  psId!: string;

  @observable
  file!: Blob;

  static ValidationRules = {
    entityType: { required: true },
    documentTypeId: { required: true },
    psId: { required: true },
    file: { required: true },
  };
}

export default class CreateAttachment extends CreateAttachmentDto {
  getFormData() {
    const formData = new FormData();
    formData.append("entity_type", this.entityType);
    formData.append("document_type_id", this.documentTypeId);
    if (this.psId !== undefined) {
      formData.append("ps_id", this.psId);
    }
    formData.append("file", this.file);

    return formData;
  }
}
