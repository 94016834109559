import { registerView, ViewComponent } from "@frui.ts/views";
import { UserRole } from "manualEntities/user";
import { observer } from "mobx-react-lite";
import React, { MouseEventHandler, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { camelToSnakeCase, formatDate } from "utils/helpers";
import StatisticsViewModel from "viewModels/dashboard/statisticsViewModel";
import "./statisticsView.scss";

interface IData {
  name: string;
  value: string | number;
  onClick?: MouseEventHandler;
}

const portalColors: Record<string, string> = {
  new: "#004B93",
  stable: "#0077E6",
  endangered: "#339CFF",
  inTermination: "#80C1FF",
  cooperationTerminated: "#CCE6FF",
};

const opportunitiesColors: Record<string, string> = {
  analysis: "#CCE6FF",
  offer: "#80C1FF",
  negotiation: "#339CFF",
  implementation: "#0077E6",
  futureOpportunity: "#004B93",
  unableToGet: "#003161",
  complete: "#00172E",
};

const PieGraph: React.FC<{
  title: string;
  data: IData[];
  ta: (code: string) => string;
  colors: Record<string, string>;
}> = props => {
  const { ta, title, data, colors } = props;

  const CustomizedTooltip = React.memo((props: any) => {
    if (props.payload.length > 0) {
      const data: IData = props.payload[0];
      return (
        <div className="statistics-tooltip">
          <label>{ta(camelToSnakeCase(data.name))}</label>
          <span>{data.value}</span>
        </div>
      );
    }
    return null;
  });

  const allValuesEmpty = data.every(item => parseInt(`${item.value}`, 10) === 0);

  return (
    <>
      <label>{title}</label>
      <ResponsiveContainer width="100%" minWidth={300} maxHeight={200} aspect={1}>
        <PieChart>
          <Tooltip content={<CustomizedTooltip />} />
          <Pie cx="25%" dataKey="value" data={data} innerRadius={45} isAnimationActive={false} minAngle={3}>
            {data.map((item, index) => (
              <Cell
                onClick={item.onClick}
                fill={colors[item.name]}
                key={`cell-${index}`}
                className={item.onClick ? "clickable" : ""}
              />
            ))}
          </Pie>
          <Legend
            iconSize={24}
            align="right"
            verticalAlign="middle"
            wrapperStyle={{ right: 50, lineHeight: 1.8 }}
            formatter={(_value, entry) => (
              <span className="pl-2" style={{ color: "#000" }}>
                {(entry?.payload as any)?.value}
              </span>
            )}
          />
        </PieChart>
      </ResponsiveContainer>
      {allValuesEmpty && <div className="no-data">{ta("no_data")}</div>}
    </>
  );
};

const ColorsLegend: React.FC<{
  ta: (code: string) => string;
  colors: Record<string, string>;
}> = props => {
  const { ta, colors } = props;

  return (
    <div className="legend">
      {Object.keys(colors).map(key => (
        <div className="legend-item" key={key}>
          <span style={{ backgroundColor: colors[key] }}>&nbsp;</span>
          {ta(camelToSnakeCase(key))}
        </div>
      ))}
    </div>
  );
};

export const statisticsView: ViewComponent<StatisticsViewModel> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral } = vm.localizationService;
  const ta = (code: string) => translateAttribute("graph", code);
  const tg = (code: string) => translateGeneral(`statistics.${code}`);

  const parcelShopsData = useMemo(
    () =>
      vm.stats?.parcelShops ? Object.entries(vm.stats?.parcelShops).map(item => ({ name: item[0], value: Number(item[1]) })) : [],
    [vm.stats?.parcelShops]
  );

  const parcelBoxesData = useMemo(
    () =>
      vm.stats?.parcelBoxes ? Object.entries(vm.stats?.parcelBoxes).map(item => ({ name: item[0], value: Number(item[1]) })) : [],
    [vm.stats?.parcelBoxes]
  );

  const totalData = useMemo(
    () => (vm.stats?.totals ? Object.entries(vm.stats?.totals).map(item => ({ name: item[0], value: Number(item[1]) })) : []),
    [vm.stats?.totals]
  );

  const opportunitiesData = useMemo(
    () =>
      vm.stats?.opportunityStates
        ? Object.entries(vm.stats?.opportunityStates).map(item => ({
            name: item[0],
            value: Number(item[1]),
            onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) =>
              vm.clickHandler(event, vm.getOpportunityStatusByName(item[0])),
          }))
        : [],
    [vm.stats?.opportunityStates]
  );

  return (
    <div className="statistics">
      <div className="header">
        <h2>{tg("title")}</h2>
        <div>
          <h6>{vm.userName}</h6>
          <p>{formatDate(new Date())}</p>
        </div>
      </div>
      <Row>
        {[UserRole.PortalAdmin, UserRole.PortalUserPS, UserRole.PortalUser].includes(vm.userRole) && parcelShopsData.length > 0 && (
          <Col sm={12} md={4}>
            <PieGraph ta={ta} title="ParcelShop" data={parcelShopsData} colors={portalColors} />
          </Col>
        )}
        {[UserRole.PortalAdmin, UserRole.PortalUserPB, UserRole.PortalUser].includes(vm.userRole) && parcelBoxesData.length > 0 && (
          <Col sm={12} md={4}>
            <PieGraph ta={ta} title="ParcelBox" data={parcelBoxesData} colors={portalColors} />
          </Col>
        )}
        {[UserRole.PortalAdmin, UserRole.PortalUser].includes(vm.userRole) && totalData.length > 0 && (
          <Col sm={12} md={4}>
            <PieGraph ta={ta} title={tg("total")} data={totalData} colors={portalColors} />
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <ColorsLegend ta={ta} colors={portalColors} />
        </Col>
      </Row>
      <br />
      <Row>
        {opportunitiesData.length > 0 && (
          <Col sm={12} md={4}>
            <PieGraph ta={ta} title={tg("opportunities")} data={opportunitiesData} colors={opportunitiesColors} />
          </Col>
        )}
        <Row>
          <Col>
            <ColorsLegend ta={ta} colors={opportunitiesColors} />
          </Col>
        </Row>
      </Row>
    </div>
  );
});

registerView(statisticsView, StatisticsViewModel);
