import { CollectionCheck, Input } from "@frui.ts/bootstrap";
import { BindingProperty } from "@frui.ts/helpers";
import { getValue, IBindingProps, ISelectItem } from "@frui.ts/views";
import { isEqual, sortBy } from "lodash";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { Dropdown, Form } from "react-bootstrap";

export interface ItemsSelectorProps<TTarget, TProperty extends BindingProperty<TTarget>>
  extends IBindingProps<TTarget, TProperty, (number | string)[]> {
  id?: string;
  searchPlaceholder?: string;
  selectAllText?: string;
  searchTextProperty: BindingProperty<TTarget>;
  items: ISelectItem[];
}

// the function must be PascalCase in order to use hooks
function ItemsSelectorImpl<TTarget, TProperty extends BindingProperty<TTarget>>(props: ItemsSelectorProps<TTarget, TProperty>) {
  const search = getValue(props.target, props.searchTextProperty) as string;
  const lowerCaseSearch = search?.toLowerCase();

  const filteredItems = lowerCaseSearch ? props.items.filter(x => x.label.toLowerCase().includes(lowerCaseSearch)) : props.items;

  const selectedIds = getValue(props.target, props.property) as (number | string)[];
  if (!selectedIds) {
    throw new Error("ItemsSelectorProps must be bound to an array");
  }

  const isAllSelected =
    selectedIds.length && selectedIds.length === filteredItems.length ? true : selectedIds.length ? null : false;

  const toggleAll = () => {
    if (selectedIds.length === filteredItems.length && isEqual(sortBy(selectedIds), sortBy(filteredItems.map(x => x.value)))) {
      runInAction(() => (selectedIds.length = 0));
    } else {
      runInAction(() => {
        selectedIds.length = 0;
        selectedIds.push(...filteredItems.map(x => x.value));
      });
    }
  };

  const id = props.id ?? (props.property as string);

  return (
    <>
      <Form.Group>
        <Input
          target={props.target}
          property={props.searchTextProperty}
          size="sm"
          data-search
          placeholder={props.searchPlaceholder}
        />
      </Form.Group>
      <Dropdown.Divider />
      <Form.Group className="check-list mb-3">
        <Form.Check
          custom
          id={`${id}All`}
          checked={!!isAllSelected}
          ref={(el: HTMLInputElement) => el && (el.indeterminate = isAllSelected === null)}
          onChange={toggleAll}
          label={props.selectAllText}
        />
      </Form.Group>
      <Form.Group className="check-list">
        {filteredItems.map(x => (
          <CollectionCheck
            key={x.value}
            custom
            target={props.target}
            property={props.property as string}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value={x.value as any}
            label={x.label}
            id={`${id}${x.value}`}
          />
        ))}
      </Form.Group>
    </>
  );
}

const ItemsSelector = observer(ItemsSelectorImpl as any) as typeof ItemsSelectorImpl;
export default ItemsSelector;
