import { computed } from "mobx";
import AlertViewModel from "viewModels/alertViewModel";
import NotificationService from "services/notificationService";

export default class AlertsService {
  constructor(private notificationService: NotificationService) {}

  @computed
  get items() {
    const items: AlertViewModel[] = [];
    this.notificationService.notifications.forEach((notification, key) => {
      items.push(
        new AlertViewModel(notification.message, notification.severity, () => this.notificationService.removeNotification(key))
      );
    });

    return items;
  }
}
