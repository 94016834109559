import { attachAutomaticDirtyWatcher, isDirty } from "@frui.ts/dirtycheck";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors } from "@frui.ts/validation";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import OpeningHourDto from "entities/openingHourDto";
import { validateAll } from "helpers";
import { interfaces } from "inversify";
import NetworkStatusType from "manualEntities/networkStatusType";
import { observable, reaction, runInAction } from "mobx";
import { OpeningHoursModel, validationRulesWithDayPart } from "models/shops/newShop/openingHoursModel";
import ShopDetailContext from "models/shops/shopDetailContext";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import { scrollTop } from "utils/helpers";
import { EditShopNotificationScope, ISubDetailViewModel } from "../types";
import OpeningHoursViewModelBase from "./openingHoursViewModelBase";
import ParcelShop from "../../../../manualEntities/parcelShop";
import IAccessPointRepository from "../../../../data/repositories/IAccessPointRepository";
import PotentialRepository from "../../../../data/repositories/potentialRepository";
import PotentialPs from "../../../../entities/potentialPs";
import { entityToPlain } from "../../../../data/helpers";
import EventBus, { Events } from "../../../../services/eventBus";
import PotentialPsDetailDto from "../../../../entities/potentialPsDetailDto";

export default class EditOpeningHoursViewModel extends OpeningHoursViewModelBase implements ISubDetailViewModel {
  static defaultNavigationName = "openingHours";

  busyWatcher = new BusyWatcher();

  constructor(
    private context: ShopDetailContext<PotentialPsDetailDto | ParcelShop>,
    private isParcelBox: boolean,
    private isPotential: boolean,
    private repository: IAccessPointRepository<any>,
    private notificationService: NotificationService,
    private eventBus: EventBus,
    localizationService: LocalizationService
  ) {
    super(localizationService);
    this.name = localizationService.translateGeneral("parcel_shop.opening_hours");
    this.navigationName = EditOpeningHoursViewModel.defaultNavigationName;
  }

  @watchBusy
  async onInitialize() {
    await this.loadData();
    const shop = this.context.shop;
    this.reactionDisposers.push(
      reaction(
        () => shop.alwaysOpen,
        isAlwaysOpen => (this.alwaysOpen = isAlwaysOpen)
      )
    );
    this.eventBus.subscribe(Events.AccessPoints.Context.Updated, this.loadData);
    return super.onInitialize();
  }

  discardChanges() {
    return this.onInitialize();
  }

  @bound
  protected async loadData() {
    await runInAction(async () => {
      this.isAlwaysVisible = this.isParcelBox;
      this.alwaysOpen = this.shop.alwaysOpen;
      const hours = await this.repository.getOpeningHours(this.context.shop.id);
      this.days = this.generateDays(hours);
    });
  }

  get shop() {
    return this.context.shop;
  }

  get isDirty() {
    return this.days && this.days.some(d => d.hours.some(h => isDirty(h)));
  }

  get canSave() {
    return (
      (this.isDirty && this.openingHours.length > 0 && !this.days.some(d => d.hours.some(hasVisibleErrors))) ||
      (this.openingHours.length === 0 &&
        this.context.shop instanceof ParcelShop &&
        this.context.shop.network_partner === NetworkStatusType.Parent) ||
      this.context.shop instanceof PotentialPs
    );
  }

  @bound
  @watchBusy
  async save() {
    const isAllValid = this.days.reduce((acc: boolean, item) => validateAll(item.hours) && acc, true);
    if (!isAllValid) {
      return;
    }

    try {
      await Promise.all([
        this.repository.updateItem(this.context.shop.id, {
          ...entityToPlain(this.context.shop),
          always_open: this.alwaysOpen,
        }),
        this.repository.updateOpeningHours(this.context.shop.id, this.openingHours),
      ]);

      this.notificationService.addNotification(
        this.localizationService.translateGeneral("parcel_shop.edit.opening_hours.saved_message"),
        SeverityLevel.success,
        EditShopNotificationScope
      );

      this.registerReactions();
    } catch {
      // TODO: Add catch statement
    }

    scrollTop();
  }

  private generateDays(existingHours: OpeningHourDto[]) {
    return [
      this.generateDay(this.localizationService.translateGeneral("monday"), 2, existingHours, 0),
      this.generateDay(this.localizationService.translateGeneral("tuesday"), 3, existingHours, 1),
      this.generateDay(this.localizationService.translateGeneral("wednesday"), 4, existingHours, 2),
      this.generateDay(this.localizationService.translateGeneral("thursday"), 5, existingHours, 3),
      this.generateDay(this.localizationService.translateGeneral("friday"), 6, existingHours, 4),
      this.generateDay(this.localizationService.translateGeneral("saturday"), 7, existingHours, 5),
      this.generateDay(this.localizationService.translateGeneral("sunday"), 1, existingHours, 6),
    ];
  }

  private generateDay(name: string, dayNumber: number, existingHours: OpeningHourDto[], dayIndex?: number) {
    return {
      dayName: name,
      hours: [
        this.generateHour(
          dayNumber,
          existingHours.find(x => x.weekDay === dayNumber && x.dayPart === 0),
          0,
          dayIndex
        ),
        this.generateHour(
          dayNumber,
          existingHours.find(x => x.weekDay === dayNumber && x.dayPart === 1),
          1,
          dayIndex
        ),
      ] as [OpeningHoursModel, OpeningHoursModel],
    };
  }

  private generateHour(dayNumber: number, existing?: OpeningHourDto, dayPart?: number, dayIndex?: number): OpeningHoursModel {
    return attachAutomaticDirtyWatcher(
      attachAutomaticValidator(
        observable.object({
          openingHours: this,
          isActive: !!existing,
          day: dayNumber,
          openFrom: existing?.openFrom || "",
          openTo: existing?.openTo || "",
        }),
        validationRulesWithDayPart(dayPart, dayIndex)
      )
    );
  }

  static Factory({ container }: interfaces.Context) {
    return (context: ShopDetailContext<PotentialPsDetailDto | ParcelShop>, isParcelBox: boolean, isPotential: boolean) =>
      new EditOpeningHoursViewModel(
        context,
        isParcelBox,
        isPotential,
        isPotential ? container.get(PotentialRepository) : container.get(ParcelShopRepository),
        container.get(NotificationService),
        container.get(EventBus),
        container.get(LocalizationService)
      );
  }
}
