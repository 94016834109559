import { RepositoryBase } from "data/repositoryBase";
import SessionDto from "entities/sessionDto";
import User from "entities/user";

export default class LoginRepository extends RepositoryBase {
  login(payload: SessionDto): Promise<User> {
    return this.apiFactory().all("session").postEntity(payload, User);
  }

  async currentUser(): Promise<User | null> {
    try {
      return await this.apiFactory().all("session").getEntity(User);
    } catch (error) {
      return null;
    }
  }
}
