import { ReactComponent as FilterIcon } from "assets/icons/icon-filter.svg";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import ReactDOM from "react-dom";
import preventDefault from "views/preventDefault";
import "./filterButton.scss";

const stopPropagation: React.MouseEventHandler<any> = e => e.stopPropagation();

export interface FilterButtonProps {
  confirmLabel?: string;
  clearLabel?: string;

  isActive?: boolean;
  canClear?: boolean;
  onClear?: () => any;
  onConfirm?: () => any;
}

const FilterButton: React.FC<FilterButtonProps> = observer(props => {
  const [isOpened, setOpened] = React.useState(false);
  const onConfirm = () => {
    props.onConfirm?.();
    setOpened(!isOpened);
  };

  const canClear = props.canClear ?? props.isActive;

  const portalContainer = document.getElementById("root");

  const popperElement = (
    <Dropdown.Menu
      className="filter-button__popup"
      popperConfig={{
        modifiers: [
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              escapeWithReference: false,
              boundariesElement: "scrollParent",
            },
          },
        ],
      }}>
      <Form onSubmit={preventDefault(onConfirm)}>{props.children}</Form>
      <Dropdown.Divider />
      <div className="filter-button__popup__footer">
        <Button onClick={onConfirm}>{props.confirmLabel}</Button>
        {canClear && (
          <Button variant="link" className="text-danger" onClick={props.onClear}>
            {props.clearLabel}
          </Button>
        )}
      </div>
    </Dropdown.Menu>
  );

  return (
    <div className="filter-button" onClick={stopPropagation}>
      <Dropdown show={isOpened} onToggle={setOpened}>
        <Dropdown.Toggle variant="outline-primary" size="sm" active={props.isActive}>
          <FilterIcon />
        </Dropdown.Toggle>

        {portalContainer ? ReactDOM.createPortal(popperElement, portalContainer) : popperElement}
      </Dropdown>
    </div>
  );
});

export default FilterButton;
