import AttachmentsRepository from "data/repositories/attachmentsRepository";
import CompetitionsRepository from "data/repositories/competitionsRepository";
import EnumRepository from "data/repositories/enumRepository";
import UsersRepository from "data/repositories/usersRepository";
import { interfaces } from "inversify";
import AdditionalInformation from "models/shops/newShop/additionalInformation";
import EnumService from "services/enum";
import EventBus from "services/eventBus";
import LocalizationService from "services/localizationService";
import AdditionalInformationViewModelBase from "../detail/edit/additionalInformationViewModelBase";
import { IStepViewModel } from "./types";
import { hasVisibleErrors, validateAll } from "@frui.ts/validation";
import { computed } from "mobx";
import { watchBusy } from "@frui.ts/screens";
import CreateAttachmentDto from "../../../entities/createAttachmentDto";
import { plainToClass } from "class-transformer";
import Attachment from "entities/attachment";

export default class NewAdditionalInformationViewModel extends AdditionalInformationViewModelBase implements IStepViewModel {
  constructor(
    private additionalInformation: AdditionalInformation,
    isParcelBox: boolean,
    isAlzaBox: boolean,
    isPotential: boolean,
    userRepository: UsersRepository,
    public localizationService: LocalizationService,
    enums: EnumService,
    enumRepository: EnumRepository,
    competitionsRepository: CompetitionsRepository,
    attachmentsRepository: AttachmentsRepository,
    eventBus: EventBus
  ) {
    super(
      undefined,
      isParcelBox,
      isAlzaBox,
      isPotential,
      userRepository,
      localizationService,
      enums,
      enumRepository,
      competitionsRepository,
      attachmentsRepository,
      eventBus
    );
    this.name = localizationService.translateGeneral("parcel_shop.additional_information");
    this.navigationName = "additional";
  }

  onInitialize() {
    return super.onInitialize();
  }

  protected loadDetail(): Promise<AdditionalInformation> {
    return Promise.resolve(this.additionalInformation);
  }

  @watchBusy
  saveAttachments(): Promise<Attachment[]> {
    return Promise.all(
      this.files.map(item => {
        if (item.id) {
          const attachment = new Attachment();
          attachment.id = item.id!;
          attachment.documentTypeId = item.documentTypeId;
          attachment.entityType = 0;

          return Promise.resolve(attachment);
        }

        const attachment = new CreateAttachmentDto();
        if (this.context && this.context.shop.id) {
          attachment.psId = `${this.context.shop.id}`;
        }
        attachment.entityType = this.isPotential ? "1" : "0";
        attachment.documentTypeId = `${item.documentTypeId}`;
        attachment.file = item.file!;

        return this.attachmentsRepository
          .uploadAttachment(attachment)
          .then(response => plainToClass(Attachment, response.json()));
      })
    );
  }

  @computed
  get canContinue() {
    const hasSomeCompetitionsVisibleErrors = this.item?.competitions.some(competition => hasVisibleErrors(competition));
    return !hasVisibleErrors(this.item) && !hasSomeCompetitionsVisibleErrors;
  }

  validate() {
    return validateAll([this.item, ...this.item.competitions]);
  }

  static Factory({ container }: interfaces.Context) {
    return (additionalInformation: AdditionalInformation, isParcelBox: boolean, isAlzaBox: boolean, isPotential: boolean) =>
      new NewAdditionalInformationViewModel(
        additionalInformation,
        isParcelBox,
        isAlzaBox,
        isPotential,
        container.get(UsersRepository),
        container.get(LocalizationService),
        container.get(EnumService),
        container.get(EnumRepository),
        container.get(CompetitionsRepository),
        container.get(AttachmentsRepository),
        container.get(EventBus)
      );
  }
}
