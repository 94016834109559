import { values } from "lodash";

type Message = { constraints: Record<string, string> };
type ErrorType = {
  content?: { message: string | Message[]; error: string };
  response: { statusText: string };
};
export default function extractErrorMessage(error: ErrorType): string {
  const message = error.content?.message;
  if (message) {
    if (Array.isArray(message)) {
      const messagesObject = message[0].constraints;
      if (messagesObject) {
        return values(messagesObject)[0];
      }
    } else {
      return message;
    }
  }

  return error.content?.error || error.response?.statusText || "Server communication error";
}
