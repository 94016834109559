import ShopDetailViewModel from "viewModels/shops/detail/shopDetailViewModel";
import { observer } from "mobx-react-lite";
import Table from "controls/table";
import React, { FunctionComponent } from "react";
import BoxDetailViewModel from "viewModels/shops/detail/boxDetailViewModel";
import PotentialDetailViewModel from "viewModels/shops/detail/potentialDetailViewModel";
import { ViewProps } from "@frui.ts/views";
import HeaderRow from "../../../controls/headerRow";

const Addresses: FunctionComponent<ViewProps<ShopDetailViewModel | BoxDetailViewModel | PotentialDetailViewModel>> = observer(
  ({ vm }) => {
    const { translateGeneral: tg, translateAttribute } = vm.localizationService;
    const ta = (key: string) => translateAttribute("address", key);

    return (
      <>
        <HeaderRow title={tg("parcel_shop.address")} level={3} />
        <Table
          data={(vm.addresses || []).slice()}
          keyField="id"
          columns={[
            { dataField: "typeName", text: ta("type"), title: true },
            { dataField: "name", text: ta("name"), title: true },
            { dataField: "subname", text: ta("subname"), title: true },
            { dataField: "street", text: ta("street"), title: true },
            { dataField: "zip", text: ta("zip"), title: true },
            { dataField: "city", text: ta("city"), title: true },
            { dataField: "countryName", text: ta("country"), title: true },
            {
              dataField: "default",
              text: ta("default"),
              formatter: isDefault => (isDefault ? vm.localizationService.translateGeneral("yes") : null),
              title: isDefault => (isDefault ? vm.localizationService.translateGeneral("yes") : null),
            },
          ]}
        />
      </>
    );
  }
);

export default Addresses;
