import { appendAcceptHeader, ContentTypes, RestRequestBuilder } from "@frui.ts/apiclient";
import { IPagingFilter, PagedQueryResult, SortingDirection } from "@frui.ts/data";
import { ClassConstructor, deserialize, deserializeArray, plainToClass } from "class-transformer";
import { stringify, IStringifyOptions } from "qs";
import IListRequestParams, { IListRequestFilters } from "./listRequest";
import IListResponse from "./listResponse";

const stringifyOoptions: IStringifyOptions = {
  arrayFormat: "brackets",
};

// TODO remove this hack when Frui.ts uses qs instead of query-string
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
RestRequestBuilder.DefaultQueryStringOptions = stringifyOoptions as any;

export default class DeserializingRequestBuilder extends RestRequestBuilder {
  getEntity<T>(returnType: ClassConstructor<T>, queryParams?: Record<string, unknown>): Promise<T> {
    const requestUrl = this.appendQuery(this.url, queryParams);
    const params = appendAcceptHeader(this.params, ContentTypes.json);
    return this.apiConnector
      .get(requestUrl, params)
      .then(x => x.text())
      .then(x => deserialize(returnType, x));
  }

  getEntities<T>(returnType: ClassConstructor<T>, queryParams?: Record<string, unknown>): Promise<T[]> {
    const requestUrl = this.appendQuery(this.url, queryParams);
    const params = appendAcceptHeader(this.params, ContentTypes.json);
    return this.apiConnector
      .get(requestUrl, params)
      .then(x => x.text())
      .then(x => deserializeArray(returnType, x));
  }

  getPagedEntities<TEntity>(entityType: ClassConstructor<TEntity>, paging: IPagingFilter, inputFilter?: IListRequestFilters) {
    const query: IListRequestParams = {
      limit: paging.limit,
      offset: paging.offset,
      sort: paging.sortColumn
        ? `${paging.sortColumn}:${paging.sortDirection === SortingDirection.Descending ? "desc" : "asc"}`
        : undefined,
    };

    if (inputFilter) {
      query.filters = inputFilter;

      // remove empty strings
      for (const key in query.filters) {
        if (query.filters[key] === "") {
          query.filters[key] = undefined;
        }
      }
    }

    const requestUrl = this.appendQuery(this.url, query);
    const params = appendAcceptHeader(this.params, ContentTypes.json);

    return this.apiConnector
      .get(requestUrl, params)
      .then(x => x.json() as Promise<IListResponse<TEntity>>)
      .then(
        response =>
          [
            response.items.map(x => plainToClass(entityType, x)),
            {
              limit: query.limit,
              offset: query.offset,
              totalItems: response.total,
            },
          ] as PagedQueryResult<TEntity>
      );
  }

  postEntity<T>(content: any, returnType: ClassConstructor<T>): Promise<T>;
  postEntity(content: any): Promise<Response>;

  postEntity<T>(content: any, returnType?: ClassConstructor<T>): Promise<T | Response> {
    const params = appendAcceptHeader(this.params, ContentTypes.json);
    const promise = this.apiConnector.postJson(this.url, content, params);

    if (returnType) {
      return promise.then(x => x.text()).then(x => deserialize(returnType, x));
    } else {
      return promise;
    }
  }

  putEntity<T>(content: any, returnType: ClassConstructor<T>): Promise<T>;
  putEntity(content: any): Promise<Response>;

  putEntity<T>(content: any, returnType?: ClassConstructor<T>): Promise<T | Response> {
    const params = appendAcceptHeader(this.params, ContentTypes.json);
    const promise = this.apiConnector.putJson(this.url, content, params);

    if (returnType) {
      return promise.then(x => x.text()).then(x => deserialize(returnType, x));
    } else {
      return promise;
    }
  }

  patchEntity<T>(content: any, returnType: ClassConstructor<T>): Promise<T>;
  patchEntity(content: any): Promise<Response>;

  patchEntity<T>(content: any, returnType?: ClassConstructor<T>): Promise<T | Response> {
    const params = appendAcceptHeader(this.params, ContentTypes.json);
    const promise = this.apiConnector.patchJson(this.url, content, params);

    if (returnType) {
      return promise.then(x => x.text()).then(x => deserialize(returnType, x));
    } else {
      return promise;
    }
  }

  // TODO remove this hack when Frui.ts uses qs instead of query-string
  getQueryString(query: any, queryStringOptions?: any) {
    return stringify(
      query,
      queryStringOptions ??
        (this.queryStringOptions as IStringifyOptions) ??
        (RestRequestBuilder.DefaultQueryStringOptions as IStringifyOptions)
    );
  }
}
