import { action, observable } from "mobx";
import SettingsRepository from "../data/repositories/settingsRepository";
import Setting from "entities/setting";
import EventBus, { Events } from "services/eventBus";
import LocalizationService from "./localizationService";
import NotificationService, { SeverityLevel } from "./notificationService";

export const EditSettingsNotificationScope = "editSettings";

export class SettingsProperties {
  @observable bonusXmlGeneration: string;
  @observable orderXmlGeneration: string;
  @observable bonusLimitForAcquisition?: number;
  @observable bonusLimitForFlow?: number;
  @observable bonusLimitForLoyalty?: number;
  @observable bonusLimitForRetention?: number;
  @observable bonusLimitForSeasonal?: number;
  @observable bonusLimitForSupply?: number;
}

export default class SettingsService {
  @observable settings: SettingsProperties;

  constructor(
    private repository: SettingsRepository,
    private eventBus: EventBus,
    private localization: LocalizationService,
    private notifications: NotificationService
  ) {}

  @action.bound
  async initialize() {
    try {
      this.settings = new SettingsProperties();
      const result = await this.repository.getSettings();

      this.settings.bonusXmlGeneration = this.getValueOfKey(result, "bonusXmlGeneration") ?? "";
      this.settings.orderXmlGeneration = this.getValueOfKey(result, "orderXmlGeneration") ?? "";
      this.settings.bonusLimitForAcquisition = Number(this.getValueOfKey(result, "bonusLimitForAcquisition"));
      this.settings.bonusLimitForFlow = Number(this.getValueOfKey(result, "bonusLimitForFlow"));
      this.settings.bonusLimitForLoyalty = Number(this.getValueOfKey(result, "bonusLimitForLoyalty"));
      this.settings.bonusLimitForRetention = Number(this.getValueOfKey(result, "bonusLimitForRetention"));
      this.settings.bonusLimitForSeasonal = Number(this.getValueOfKey(result, "bonusLimitForSeasonal"));
      this.settings.bonusLimitForSupply = Number(this.getValueOfKey(result, "bonusLimitForSupply"));
    } catch (e) {
      this.eventBus.publish(Events.General.ServerError, this.localization.translateGeneral("message.errors.fetch_failed"));
      throw e;
    }
  }

  refreshSettings(): Promise<void> {
    return this.initialize();
  }

  updateSettings(settings: SettingsProperties): Promise<void> {
    return this.repository.updateSettings(settings).then(() => {
      this.notifications.addNotification(
        this.localization.translateGeneral("settings.updated"),
        SeverityLevel.success,
        EditSettingsNotificationScope
      );
      return this.refreshSettings();
    });
  }

  getValueOfKey(settings: Array<Setting>, key: string) {
    return settings.find(x => x.key === key)?.value ?? undefined;
  }
}
