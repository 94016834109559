import { SortingDirection } from "@frui.ts/data";
import { bound, nameof } from "@frui.ts/helpers";
import { watchBusy } from "@frui.ts/screens";
import { ISelectItem } from "@frui.ts/views";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import PaymentTerminalRepository from "data/repositories/paymentTerminalRepository";
import { ParcelShopFilter } from "manualEntities/parcelShop";
import PaymentTerminal, { PaymentTerminalFilter, PaymentTerminalStatus } from "manualEntities/paymentTerminal";
import { EventBus } from "light-event-bus";
import { action, observable, runInAction, toJS } from "mobx";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import UserContext from "services/userContext";
import { depoSelectOption, formatDepoName } from "utils/helpers";
import ListViewModelBase from "viewModels/listViewModelBase";
import PaymentTerminalDetailViewModel from "./paymentTerminalDetailViewModel";
import PaymentTerminalsImportViewModel, { PaymentTerminalImportType } from "./paymentTerminalsImportViewModel";
import AccessPointPartners from "models/enumerations/accessPointPartners";

export default class PaymentTerminalsViewModel extends ListViewModelBase<
  PaymentTerminal,
  PaymentTerminalFilter,
  PaymentTerminalDetailViewModel
> {
  static notificationScope = "subjects";

  @observable paymentTerminalsImportViewModel?: PaymentTerminalsImportViewModel;
  @observable.shallow allowedParcelShops?: ISelectItem[];

  constructor(
    private repository: PaymentTerminalRepository,
    private parcelShopsRepository: ParcelShopRepository,
    private eventBus: EventBus,
    private paymentTerminalDetailViewModelFactory: ReturnType<typeof PaymentTerminalDetailViewModel.Factory>,
    private paymentTerminalsImportViewModelFactory: ReturnType<typeof PaymentTerminalsImportViewModel.Factory>,
    public confirmationService: ConfirmationService,
    public localizationService: LocalizationService,
    private enums: EnumService,
    private userContext: UserContext
  ) {
    super();
    this.navigationName = "payment_terminal";
    this.name = localizationService.translateModel("payment_terminal", 5);
    this.pagingFilter.limit = 10; // just to show paging
    this.pagingFilter.sortColumn = nameof<PaymentTerminal>("updated_at");
    this.pagingFilter.sortDirection = SortingDirection.Descending;
  }

  onInitialize() {
    this.eventSubscriptions.push(
      this.eventBus.subscribe(Events.PaymentTerminals.Changed, this.loadData),
      this.eventBus.subscribe(Events.PaymentTerminals.Imported, this.loadData)
    );
    void this.loadEnums();
    void this.resetFilterAndLoad();
  }

  @bound
  onImportClick(type: PaymentTerminalImportType) {
    const onHide = () => (this.paymentTerminalsImportViewModel = undefined);
    this.paymentTerminalsImportViewModel = this.paymentTerminalsImportViewModelFactory(onHide, type);
  }

  @bound
  private async loadEnums() {
    const filter = new ParcelShopFilter();
    filter.depo_ids = toJS(this.userContext.assignedDepos);
    const ps = this.enums.value("access_point_partners", AccessPointPartners.PARCEL_SHOP, true);

    if (ps) {
      filter.type_ids = [ps.id];
    }
    const [parcelShops] = await this.parcelShopsRepository.getList({ offset: 0, limit: 1000, sortColumn: "name" }, filter);
    runInAction(() => {
      this.allowedParcelShops = parcelShops.map(x => ({ value: x.id, label: x.name } as ISelectItem));
    });
  }

  get depos() {
    return this.enums.values("depos").map(depoSelectOption);
  }

  getDepoName(code: number) {
    const depo = this.enums.value("depos", code);
    return formatDepoName(depo);
  }

  get statuses() {
    return this.enums
      .values("payment_terminal_status")
      .filter(x => this.filter.show_discarded_terminals || x.id !== PaymentTerminalStatus.Discarded)
      .map(item => ({ id: item.id, label: item.name }));
  }

  getStatus(id: number) {
    return this.enums.values("payment_terminal_status").find(item => item.id === id);
  }

  @action.bound
  @watchBusy
  async loadData() {
    const filter = toJS(this.appliedFilter, { recurseEverything: true });

    if (!filter.show_discarded_terminals) {
      if (filter.status?.length) {
        // remove Discarded status if present
        const index = filter.status.indexOf(PaymentTerminalStatus.Discarded);
        if (index >= 0) {
          filter.status.splice(index, 1);
        }
      }

      // cannot be else because previous `if` might cause this `if` to match
      if (!filter.status?.length) {
        // explicitly state all statuses except Discarded
        filter.status = this.enums
          .values("payment_terminal_status")
          .map(x => x.id)
          .filter(x => x !== PaymentTerminalStatus.Discarded);
      }
    }

    return this.repository.getPaymentTerminals(this.pagingFilter, filter).then(this.setData);
  }

  protected resetFilterValues(filter: PaymentTerminalFilter) {
    filter.terminal_id = undefined;
    filter.terminal_sn = undefined;
    filter.ps_ids = [];
    filter.status = [];
    filter.depo_ids = [];
    filter.depo_name = undefined;
    filter.parcel_shop_name = undefined;
    filter.status_name = undefined;
  }

  // eslint-disable-next-line
  @action.bound clearFilter(section: keyof PaymentTerminalFilter) {
    switch (section) {
      case "depo_ids":
      case "ps_ids":
      case "status":
        this.filter[section] = [];
        break;

      default:
        this.filter[section] = undefined as any;
        break;
    }

    void this.applyFilterAndLoad();
  }

  @action.bound openDetail(item: PaymentTerminal) {
    return this.tryActivateChild(this.paymentTerminalDetailViewModelFactory(item));
  }

  protected async findNavigationChild(navigationName: string) {
    if (+navigationName) {
      const paymentTerminal = await this.repository.getPaymentTerminal(+navigationName);
      return this.paymentTerminalDetailViewModelFactory(paymentTerminal);
    }
  }
}
