import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import { interfaces } from "inversify";
import NetworkStatusType from "manualEntities/networkStatusType";
import { observable, runInAction } from "mobx";
import { OpeningHoursModel, validationRulesWithDayPart } from "models/shops/newShop/openingHoursModel";
import LocalizationService from "services/localizationService";
import OpeningHoursViewModelBase from "viewModels/shops/detail/edit/openingHoursViewModelBase";
import NewShopConfigurationViewModel from "./newShopConfigurationViewModel";
import { IStepViewModel } from "./types";
import { IShopInformationViewModel } from "../detail/types";
import OpeningHourDto from "entities/openingHourDto";

export default class NewOpeningHoursViewModel extends OpeningHoursViewModelBase implements IStepViewModel {
  constructor(
    private isParcelBox: boolean,
    private informationVM: IShopInformationViewModel,
    private configurationVM: NewShopConfigurationViewModel,
    public localizationService: LocalizationService,
    openingHours?: OpeningHourDto[]
  ) {
    super(localizationService);
    this.name = localizationService.translateGeneral("parcel_shop.opening_hours");
    this.days = this.generateDays(openingHours);
    this.alwaysOpen = this.informationVM.item.alwaysOpen;
  }

  onInitialize() {
    runInAction(() => {
      this.isAlwaysVisible = this.isParcelBox;
    });
  }

  private generateDays(existingHours?: OpeningHourDto[]) {
    return [
      this.generateDay(this.localizationService.translateGeneral("monday"), 2, existingHours, 0),
      this.generateDay(this.localizationService.translateGeneral("tuesday"), 3, existingHours, 1),
      this.generateDay(this.localizationService.translateGeneral("wednesday"), 4, existingHours, 2),
      this.generateDay(this.localizationService.translateGeneral("thursday"), 5, existingHours, 3),
      this.generateDay(this.localizationService.translateGeneral("friday"), 6, existingHours, 4),
      this.generateDay(this.localizationService.translateGeneral("saturday"), 7, existingHours, 5),
      this.generateDay(this.localizationService.translateGeneral("sunday"), 1, existingHours, 6),
    ];
  }

  private generateDay(name: string, dayNumber: number, existingHours?: OpeningHourDto[], dayIndex?: number) {
    if (existingHours !== undefined) {
      return {
        dayName: name,
        hours: [
          this.generateHour(
            dayNumber,
            0,
            existingHours.find(x => x.weekDay === dayNumber && x.dayPart === 0),
            dayIndex
          ),
          this.generateHour(
            dayNumber,
            1,
            existingHours.find(x => x.weekDay === dayNumber && x.dayPart === 1),
            dayIndex
          ),
        ] as [OpeningHoursModel, OpeningHoursModel],
      };
    }

    return {
      dayName: name,
      hours: [this.generateHour(dayNumber, 0), this.generateHour(dayNumber, 1)] as [OpeningHoursModel, OpeningHoursModel],
    };
  }

  private generateHour(dayNumber: number, dayPart?: number, existing?: OpeningHourDto, dayIndex?: number): OpeningHoursModel {
    return attachAutomaticValidator(
      observable.object({
        openingHours: this,
        isActive: !!existing,
        day: dayNumber,
        openFrom: existing?.openFrom || "",
        openTo: existing?.openTo || "",
      }),
      validationRulesWithDayPart(dayPart, dayIndex)
    );
  }

  get canContinue() {
    return (
      (this.openingHours.length > 0 && this.days.every(d => d.hours.every(h => !hasVisibleErrors(h)))) ||
      (this.openingHours.length === 0 &&
        (this.configurationVM?.item?.network_partner === NetworkStatusType.Parent || this.informationVM.isPotential))
    );
  }

  validate() {
    let isValid = true;
    this.days.forEach(d => d.hours.forEach(h => (isValid = validate(h) && isValid)));

    return isValid;
  }

  static Factory({ container }: interfaces.Context) {
    return (
      isParcelBox: boolean,
      informationVM: IShopInformationViewModel,
      configurationVM: NewShopConfigurationViewModel,
      openingHours?: OpeningHourDto[]
    ) =>
      new NewOpeningHoursViewModel(isParcelBox, informationVM, configurationVM, container.get(LocalizationService), openingHours);
  }
}
