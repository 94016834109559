import { ValidationControlBase } from "@frui.ts/bootstrap";
import { CommonInputProps } from "@frui.ts/bootstrap/dist/controls/commonInputProps";
import { bound, ensureObservableProperty, PropertyName } from "@frui.ts/helpers";
import { IBindingProps } from "@frui.ts/views";
import dateFnsParse from "date-fns/parse";
import { get, isObservable, isObservableProp } from "mobx";
import React from "react";
import { FormControlProps } from "react-bootstrap";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, isDate } from "utils/helpers";
import CustomOverlay from "./customOverlay";
import "./dateInput.scss";

type Tuple<T, N extends number, A extends any[] = []> = A extends { length: N } ? A : Tuple<T, N, [...A, T]>;

export const formatDate2 = (date: Date, format: string): string => {
  // We are using just 1 format
  return formatDate(date, false);
};

export const parseDate = (str: string, format: string): Date | undefined => {
  // Remove leading zeros
  const parsed = dateFnsParse(str.replace(/\.\s*0*/g, ".").replace(/-\s*0*/g, "-"), format.replace(/\s*/g, ""), new Date());

  if (isDate(parsed)) {
    return parsed;
  }

  return undefined;
};

export default class DateInput<TTarget> extends ValidationControlBase<
  TTarget,
  FormControlProps & CommonInputProps & IBindingProps<TTarget>
> {
  private contentRef = React.createRef<HTMLDivElement>();

  protected get value() {
    const target = this.props.target as TTarget;
    const property = this.props.property as PropertyName<TTarget>;

    if (!target) {
      console.warn("'target' prop has not been set");
      return undefined;
    }
    if (!property) {
      throw new Error("'property' prop has not been set");
    }

    if (!isObservable(target) || !isObservableProp(target, property)) {
      ensureObservableProperty(target, property, target[property]);
    }

    return get(target, property) as Date;
  }

  @bound protected renderInner() {
    const validationError = this.getValidationError();

    const pickerProps = {
      showOutsideDays: true,
      months: [
        "Leden",
        "Únor",
        "Březen",
        "Duben",
        "Květen",
        "Červen",
        "Červenec",
        "Srpen",
        "Září",
        "Říjen",
        "Listopad",
        "Prosinec",
      ] as Tuple<string, 12>,
      weekdaysShort: ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"] as Tuple<string, 7>,
      firstDayOfWeek: 1,
    };

    return (
      <>
        <div ref={this.contentRef}>
          <DayPickerInput
            formatDate={formatDate2}
            value={this.value ? formatDate(this.value) : undefined}
            parseDate={parseDate}
            inputProps={{
              className: `form-control ${validationError ? "is-invalid" : ""}`,
              disabled: this.props.disabled,
              readOnly: this.props.disabled,
            }}
            format="dd. M. y"
            overlayComponent={(args: any) => {
              return <CustomOverlay containerRef={this.contentRef} {...args} />;
            }}
            onDayChange={this.handleValueChanged}
            placeholder=""
            dayPickerProps={pickerProps}
            keepFocus={false}
          />
        </div>
        {validationError && (
          <div className="invalid-feedback" style={{ display: "block" }}>
            {validationError}
          </div>
        )}
      </>
    );
  }

  @bound protected handleValueChanged(day: Date) {
    this.setValue(day);
  }
}
