import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ConductorSingleChild, Router, ScreenBase } from "@frui.ts/screens";
import { attachAutomaticValidator } from "@frui.ts/validation";
import Subject from "entities/subject";
import SubjectFindDto from "entities/subjectFindDto";
import { action, computed, observable, runInAction } from "mobx";
import type SubjectInfo from "models/shops/subjectInfo";
import EnumService from "services/enum";
import { default as Localization } from "services/localizationService";
import CheckSubjectViewModel from "./checkSubjectViewModel";
import SecurityService from "../../services/securityService";

export const PARCEL_SHOP_SUBJECT_CODE = "7";
export const PARCEL_BOX_SUBJECT_CODE = "9";
export type PickupPointCode = typeof PARCEL_SHOP_SUBJECT_CODE | typeof PARCEL_BOX_SUBJECT_CODE;
const navigationName = "newPickupPoint";

@Router.registerRoute({ name: Router.Self, route: navigationName, children: [CheckSubjectViewModel] })
export default class NewPickupPointViewModel extends ConductorSingleChild<ScreenBase> {
  busyWatcher = new BusyWatcher();
  @observable item: SubjectInfo;

  @observable selectedPickupPointCode?: PickupPointCode;

  @action.bound setItem(item: SubjectInfo) {
    this.item = item;
    attachAutomaticValidator(this.item, { ic: Subject.ValidationRules.ic });
  }

  @computed
  get allowToAddParcelShop() {
    return this.securityService.isAllowedToManage("parcelShop");
  }

  @computed
  get allowToAddParcelBox() {
    return this.securityService.isAllowedToManage("parcelBox");
  }

  constructor(
    public localizationService: Localization,
    private securityService: SecurityService,
    private enums: EnumService,
    private checkSubjectVMFactory: ReturnType<typeof CheckSubjectViewModel.Factory>
  ) {
    super();
    this.navigationName = navigationName;
    this.name = localizationService.translateGeneral("menu.new_pickup_point");

    this.setItem(new SubjectFindDto());
  }

  protected async onInitialize(): Promise<any> {
    super.onInitialize();
    if (this.allowToAddParcelShop && !this.allowToAddParcelBox) {
      await this.selectPickupPoint(PARCEL_SHOP_SUBJECT_CODE);
    } else if (!this.allowToAddParcelShop && this.allowToAddParcelBox) {
      await this.selectPickupPoint(PARCEL_BOX_SUBJECT_CODE);
    }
  }

  @action.bound
  async selectPickupPoint(code: PickupPointCode) {
    this.selectedPickupPointCode = code;
    const subjectTypeItem = this.enums.value("subject_types", code);
    if (subjectTypeItem) {
      runInAction(() => {
        this.item.subjectTypeId = subjectTypeItem.id;
      });

      await this.tryActivateChild(this.checkSubjectVMFactory(subjectTypeItem));
    }
  }

  protected findNavigationChild(navigationName: string) {
    if (!navigationName) {
      return undefined;
    }

    if (
      (navigationName === PARCEL_SHOP_SUBJECT_CODE && this.securityService.isAllowedToManage("parcelShop")) ||
      (navigationName === PARCEL_BOX_SUBJECT_CODE && this.securityService.isAllowedToManage("parcelBox"))
    ) {
      const subjectTypeItem = this.enums.value("subject_types", navigationName);
      if (subjectTypeItem) {
        return this.checkSubjectVMFactory(subjectTypeItem);
      }
    }

    return undefined;
  }

  @bound translate(key: string) {
    return this.localizationService.translateGeneral(`new_pickup_point.${key}`);
  }
}
