import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class SubjectFindDto {
  @observable
  @Expose({ name: "subject_id" })
  subjectId!: number;

  @observable
  ic!: string;

  @observable
  dic?: string;

  @observable
  @Expose({ name: "depo_code" })
  depoCode!: string;

  @observable
  name!: string;

  @observable
  @Expose({ name: "depo_id" })
  depoId!: number;

  @observable
  @Expose({ name: "subject_type_id" })
  subjectTypeId!: number;

  @observable
  @Expose({ name: "subject_type_code" })
  subjectTypeCode!: string;

  static ValidationRules = {
    subjectId: { required: true, number: true },
    ic: { required: true },
    depoCode: { required: true },
    name: { required: true },
    depoId: { required: true, number: true },
    subjectTypeId: { required: true, number: true },
    subjectTypeCode: { required: true },
  };
}
