import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CountsByState {
  @observable
  new!: number;

  @observable
  stable!: number;

  @observable
  endangered!: number;

  @observable
  @Expose({ name: "in_termination" })
  inTermination!: number;

  @observable
  @Expose({ name: "cooperation_terminated" })
  cooperationTerminated!: number;

  static ValidationRules = {
    new: { required: true, number: true },
    stable: { required: true, number: true },
    endangered: { required: true, number: true },
    inTermination: { required: true, number: true },
    cooperationTerminated: { required: true, number: true },
  };
}
