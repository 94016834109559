import { bound } from "@frui.ts/helpers";
import { ConductorOneChildActive, Router } from "@frui.ts/screens";
import { interfaces } from "inversify";
import ShopDetailContext from "models/shops/shopDetailContext";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import NotificationService from "services/notificationService";
import { EditShopNotificationScope, ISubDetailViewModel } from "../types";
import EditAddressesViewModel from "./editAddressesViewModel";
import EditContactsViewModel from "./editContactsViewModel";
import EditOpeningHoursViewModel from "./editOpeningHoursViewModel";
import EditShopConfigurationViewModel from "./editShopConfigurationViewModel";
import EditShopInformationViewModel from "./editShopInformationViewModel";
import AccessPointPartners from "../../../../models/enumerations/accessPointPartners";
import ParcelShop from "../../../../manualEntities/parcelShop";
import PotentialPsDetailDto from "../../../../entities/potentialPsDetailDto";
import EditAdditionalInformationViewModel from "./editAdditionalInformationViewModel";

@Router.registerRoute({
  name: Router.Self,
  route: "edit",
  children: [
    EditShopInformationViewModel,
    EditContactsViewModel,
    EditAddressesViewModel,
    EditShopConfigurationViewModel,
    EditOpeningHoursViewModel,
    EditAdditionalInformationViewModel,
  ],
})
export default class EditShopViewModel extends ConductorOneChildActive<ISubDetailViewModel> {
  static DefaultNavigationName = "edit";

  private informationVM: EditShopInformationViewModel;
  private contactVM: EditContactsViewModel;
  private addressVM: EditAddressesViewModel;
  private configurationVM: EditShopConfigurationViewModel;
  private openingHoursVM: EditOpeningHoursViewModel;
  private additionalInformationVM: EditAdditionalInformationViewModel;

  get parcelShop() {
    return this.context.shop;
  }

  constructor(
    private context: ShopDetailContext<PotentialPsDetailDto | ParcelShop>,
    public localization: LocalizationService,
    private confirmationService: ConfirmationService,
    private notifications: NotificationService,
    informationVMFactory: ReturnType<typeof EditShopInformationViewModel.Factory>,
    contactVMFactory: ReturnType<typeof EditContactsViewModel.Factory>,
    addressVMFactory: ReturnType<typeof EditAddressesViewModel.Factory>,
    configurationVMFactory: ReturnType<typeof EditShopConfigurationViewModel.Factory>,
    openingHoursVMFactory: ReturnType<typeof EditOpeningHoursViewModel.Factory>,
    additionalInformationVMFactory: ReturnType<typeof EditAdditionalInformationViewModel.Factory>,
    private enums: EnumService
  ) {
    super();
    this.name = localization.translateAttribute("parcel_shop", "edit");
    this.navigationName = EditShopViewModel.DefaultNavigationName;

    this.children.push(
      (this.informationVM = informationVMFactory(context.shop.id, this.isParcelBox, this.isAlzaBox, this.isPotential)),
      (this.contactVM = contactVMFactory(context.shop.id, this.isParcelBox, this.isPotential)),
      (this.addressVM = addressVMFactory(context, this.isPotential)),
      (this.configurationVM = configurationVMFactory(context.shop.id, this.isParcelBox, this.isPotential)),
      (this.openingHoursVM = openingHoursVMFactory(context, this.isParcelBox, this.isPotential)),
      (this.additionalInformationVM = additionalInformationVMFactory(context, this.isParcelBox, this.isAlzaBox, this.isPotential))
    );
  }

  onInitialize() {
    if (!this.activeChild && this.children.length) {
      return this.tryActivateChild(this.children[0]);
    }
  }

  @bound async openChild(navigationName: string | null) {
    if (!navigationName || this.activeChild?.navigationName === navigationName) {
      return;
    }

    if (this.activeChild) {
      const canClose = await this.canCloseChild(this.activeChild);
      if (!canClose) {
        return;
      }

      if (this.activeChild.isDirty) {
        await this.activeChild.discardChanges();
      }
    }

    this.navigate(navigationName, undefined);
  }

  get isParcelBox() {
    const shop = this.context.shop;
    if (shop instanceof PotentialPsDetailDto) {
      const item = this.enums.value("access_point_partners", AccessPointPartners.PARCEL_BOX_POTENTIAL, true);
      return item?.id === shop.typeId;
    } else {
      const item = this.enums.value("access_point_partners", AccessPointPartners.PARCEL_BOX, true);
      return item?.id === shop.type_id;
    }
  }

  get isAlzaBox() {
    const shop = this.context.shop;
    if (shop instanceof PotentialPsDetailDto) {
      return false;
    } else {
      const item = this.enums.value("access_point_partners", AccessPointPartners.ALZA_BOX, true);
      return item?.id === shop.type_id;
    }
  }

  get isPotential() {
    return this.context.shop instanceof PotentialPsDetailDto;
  }

  private async canCloseChild(child: ISubDetailViewModel) {
    if (child.isDirty) {
      return await this.confirmationService.showConfirmation(
        this.localization.translateGeneral("unsaved_changes_dialog.text"),
        this.localization.translateGeneral("unsaved_changes_dialog.title"),
        {
          text: this.localization.translateGeneral("unsaved_changes_dialog.exit"),
          variant: "outline-secondary",
        },
        {
          text: this.localization.translateGeneral("unsaved_changes_dialog.back"),
          variant: "primary",
        }
      );
    } else {
      return true;
    }
  }

  canDeactivate(isClosing: boolean) {
    const dirtyChild = this.children.find(x => x.isDirty);
    if (isClosing && dirtyChild) {
      return this.canCloseChild(dirtyChild);
    } else {
      return Promise.resolve(true);
    }
  }

  closeChild() {
    // children cannot be closed
    return false;
  }

  protected onDeactivate(close: boolean): Promise<any> {
    this.notifications.removeNotification(EditShopNotificationScope);
    return super.onDeactivate(close);
  }

  static Factory({ container }: interfaces.Context) {
    return (context: ShopDetailContext<PotentialPsDetailDto | ParcelShop>) =>
      new EditShopViewModel(
        context,
        container.get(LocalizationService),
        container.get(ConfirmationService),
        container.get(NotificationService),
        container.get(EditShopInformationViewModel.Factory),
        container.get(EditContactsViewModel.Factory),
        container.get(EditAddressesViewModel.Factory),
        container.get(EditShopConfigurationViewModel.Factory),
        container.get(EditOpeningHoursViewModel.Factory),
        container.get(EditAdditionalInformationViewModel.Factory),
        container.get(EnumService)
      );
  }
}
