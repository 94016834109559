import React from "react";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import { Button, Modal } from "react-bootstrap";
import Uploader from "controls/uploader";
import { ReactComponent as AttachmentIcon } from "assets/icons/icon-attachment.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-delete.svg";
import PaymentTerminalsImportViewModel from "viewModels/paymentTerminals/paymentTerminalsImportViewModel";
import ImportStatusDetail from "./importStatusDetail";

const PaymentTerminalsImportView: ViewComponent<PaymentTerminalsImportViewModel> = observer(({ vm }) => {
  if (vm.importStatus) {
    return (
      <Modal show={true} centered={true} size="lg" scrollable={true}>
        <Modal.Header onHide={vm.onHide} closeButton={true}>
          <Modal.Title>{vm.translate("importState")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <ImportStatusDetail vm={vm} />
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button onClick={vm.onHide}>{vm.translate("ok")}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal show={true} centered={true}>
      <Modal.Header onHide={vm.onHide} closeButton={true}>
        <Modal.Title>{vm.translate("title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Uploader maxFiles={1} onDrop={vm.onDrop} accept={vm.acceptedFormats} error={vm.errorMessage} />
        {vm.entity.file && (
          <div className="d-flex p-2">
            <AttachmentIcon className="mr-2" />
            <span style={{ flexGrow: 2 }}>{vm.entity.file.name}</span>
            <DeleteIcon
              width={20}
              height={20}
              className="align-self-end"
              style={{ cursor: "pointer" }}
              onClick={vm.onDeleteFileClick}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button onClick={vm.onSubmit} disabled={vm.submitDisabled}>
          {vm.translate("upload")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

registerView(PaymentTerminalsImportView, PaymentTerminalsImportViewModel);
