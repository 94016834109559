import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class PotentialAddressDto {
  @observable
  name!: string;

  /**
   * Value from enumeration
   */
  @observable
  @Expose({ name: "address_type_id" })
  addressTypeId!: number;

  @observable
  default?: boolean;

  @observable
  subname?: string;

  @observable
  street?: string;

  @observable
  zip?: number;

  @observable
  city?: string;

  /**
   * Value from enumeration (country)
   */
  @observable
  @Expose({ name: "country_id" })
  countryId?: number;

  @observable
  description?: string;

  static ValidationRules = {
    name: { required: true },
    addressTypeId: { required: true, number: true },
    zip: { number: true },
    countryId: { number: true },
  };
}
