// WARNING: This file has been generated. Do not edit it manually, your changes might get lost.
import { Container, interfaces } from "inversify";
import servicesAlertsService from "./services/alertsService";
import servicesConfirmationService from "./services/confirmationService";
import servicesEnum from "./services/enum";
import servicesInitializationService from "./services/initializationService";
import servicesLocalizationService from "./services/localizationService";
import servicesNotificationService from "./services/notificationService";
import servicesSecurityService from "./services/securityService";
import servicesSettingsService from "./services/settingsService";
import servicesUnreadMessageService from "./services/unreadMessageService";
import servicesValidationValidationService from "./services/validation/validationService";
import servicesWarehouseService from "./services/warehouseService";
import viewModelsAlertViewModel from "./viewModels/alertViewModel";
import viewModelsConfirmDialogViewModel from "./viewModels/confirmDialogViewModel";
import viewModelsDashboardActivitiesViewModel from "./viewModels/dashboard/activitiesViewModel";
import viewModelsDashboardDashboardViewModel from "./viewModels/dashboard/dashboardViewModel";
import viewModelsDashboardMemoDetailViewModel from "./viewModels/dashboard/memoDetailViewModel";
import viewModelsDashboardMemosViewModel from "./viewModels/dashboard/memosViewModel";
import viewModelsDashboardStatisticsViewModel from "./viewModels/dashboard/statisticsViewModel";
import viewModelsEnumerationsEnumerationDetailViewModel from "./viewModels/enumerations/enumerationDetailViewModel";
import viewModelsEnumerationsEnumerationScannerDetailViewModel from "./viewModels/enumerations/enumerationScannerDetailViewModel";
import viewModelsEnumerationsEnumerationsViewModel from "./viewModels/enumerations/enumerationsViewModel";
import viewModelsLoginViewModel from "./viewModels/loginViewModel";
import viewModelsMessageGroupsMessageGroupDetailViewModel from "./viewModels/messageGroups/messageGroupDetailViewModel";
import viewModelsMessageGroupsMessageGroupsViewModel from "./viewModels/messageGroups/messageGroupsViewModel";
import viewModelsMessagesMessageDetailViewModel from "./viewModels/messages/messageDetailViewModel";
import viewModelsMessagesMessagesViewModel from "./viewModels/messages/messagesViewModel";
import viewModelsOpportunitiesOpportunitiesViewModel from "./viewModels/opportunities/opportunitiesViewModel";
import viewModelsOpportunitiesOpportunityDetailViewModel from "./viewModels/opportunities/opportunityDetailViewModel";
import viewModelsParcelBoxParcelBoxDetailViewModel from "./viewModels/parcelBox/parcelBoxDetailViewModel";
import viewModelsParcelBoxParcelBoxesViewModel from "./viewModels/parcelBox/parcelBoxesViewModel";
import viewModelsPaymentTerminalsPaymentTerminalDetailViewModel from "./viewModels/paymentTerminals/paymentTerminalDetailViewModel";
import viewModelsPaymentTerminalsPaymentTerminalsImportViewModel from "./viewModels/paymentTerminals/paymentTerminalsImportViewModel";
import viewModelsPaymentTerminalsPaymentTerminalsViewModel from "./viewModels/paymentTerminals/paymentTerminalsViewModel";
import viewModelsPickupPointCheckSubjectViewModel from "./viewModels/pickupPoint/checkSubjectViewModel";
import viewModelsPickupPointNewPickupPointViewModel from "./viewModels/pickupPoint/newPickupPointViewModel";
import viewModelsRootViewModel from "./viewModels/rootViewModel";
import viewModelsScanDevicesScanDeviceDetailViewModel from "./viewModels/scanDevices/scanDeviceDetailViewModel";
import viewModelsScanDevicesScanDevicesImportViewModel from "./viewModels/scanDevices/scanDevicesImportViewModel";
import viewModelsScanDevicesScanDevicesViewModel from "./viewModels/scanDevices/scanDevicesViewModel";
import viewModelsSettingsSettingsViewModel from "./viewModels/settings/settingsViewModel";
import viewModelsShopsDetailAccessControlViewModel from "./viewModels/shops/detail/accessControlViewModel";
import viewModelsShopsDetailActivityActivityDetailViewModel from "./viewModels/shops/detail/activity/activityDetailViewModel";
import viewModelsShopsDetailBoxDetailViewModel from "./viewModels/shops/detail/boxDetailViewModel";
import viewModelsShopsDetailCommonDetailViewModel from "./viewModels/shops/detail/commonDetailViewModel";
import viewModelsShopsDetailEditEditAdditionalInformationViewModel from "./viewModels/shops/detail/edit/editAdditionalInformationViewModel";
import viewModelsShopsDetailEditEditAddressesViewModel from "./viewModels/shops/detail/edit/editAddressesViewModel";
import viewModelsShopsDetailEditEditContactsViewModel from "./viewModels/shops/detail/edit/editContactsViewModel";
import viewModelsShopsDetailEditEditOpeningHoursViewModel from "./viewModels/shops/detail/edit/editOpeningHoursViewModel";
import viewModelsShopsDetailEditEditShopConfigurationViewModel from "./viewModels/shops/detail/edit/editShopConfigurationViewModel";
import viewModelsShopsDetailEditEditShopInformationViewModel from "./viewModels/shops/detail/edit/editShopInformationViewModel";
import viewModelsShopsDetailEditEditShopViewModel from "./viewModels/shops/detail/edit/editShopViewModel";
import viewModelsShopsDetailInvoicingBonusDetailViewModel from "./viewModels/shops/detail/invoicing/bonusDetailViewModel";
import viewModelsShopsDetailInvoicingBonusesViewModel from "./viewModels/shops/detail/invoicing/bonusesViewModel";
import viewModelsShopsDetailInvoicingBonusNoteDetailViewModel from "./viewModels/shops/detail/invoicing/bonusNoteDetailViewModel";
import viewModelsShopsDetailInvoicingBoxInvoicingViewModel from "./viewModels/shops/detail/invoicing/boxInvoicingViewModel";
import viewModelsShopsDetailInvoicingInvoicesViewModel from "./viewModels/shops/detail/invoicing/invoicesViewModel";
import viewModelsShopsDetailInvoicingK2ExportViewModel from "./viewModels/shops/detail/invoicing/k2ExportViewModel";
import viewModelsShopsDetailInvoicingPaymentStoppageDetailViewModel from "./viewModels/shops/detail/invoicing/paymentStoppageDetailViewModel";
import viewModelsShopsDetailInvoicingPaymentStoppageViewModel from "./viewModels/shops/detail/invoicing/paymentStoppageViewModel";
import viewModelsShopsDetailInvoicingRecurringPaymentsViewModel from "./viewModels/shops/detail/invoicing/recurringPaymentsViewModel";
import viewModelsShopsDetailInvoicingRewardsEditViewModel from "./viewModels/shops/detail/invoicing/rewardsEditViewModel";
import viewModelsShopsDetailInvoicingRewardsViewModel from "./viewModels/shops/detail/invoicing/rewardsViewModel";
import viewModelsShopsDetailInvoicingShopInvoicingViewModel from "./viewModels/shops/detail/invoicing/shopInvoicingViewModel";
import viewModelsShopsDetailParcelShopUserDetailViewModel from "./viewModels/shops/detail/parcelShopUserDetailViewModel";
import viewModelsShopsDetailPotentialDetailViewModel from "./viewModels/shops/detail/potentialDetailViewModel";
import viewModelsShopsDetailShopDetailViewModel from "./viewModels/shops/detail/shopDetailViewModel";
import viewModelsShopsDetailWarehouseStockTakingViewModel from "./viewModels/shops/detail/warehouse/stockTakingViewModel";
import viewModelsShopsDetailWarehouseWarehouseViewModel from "./viewModels/shops/detail/warehouse/warehouseViewModel";
import viewModelsShopsNewShopNewAdditionalInformationViewModel from "./viewModels/shops/newShop/newAdditionalInformationViewModel";
import viewModelsShopsNewShopNewAddressesViewModel from "./viewModels/shops/newShop/newAddressesViewModel";
import viewModelsShopsNewShopNewContactsViewModel from "./viewModels/shops/newShop/newContactsViewModel";
import viewModelsShopsNewShopNewOpeningHoursViewModel from "./viewModels/shops/newShop/newOpeningHoursViewModel";
import viewModelsShopsNewShopNewParcelShopViewModel from "./viewModels/shops/newShop/newParcelShopViewModel";
import viewModelsShopsNewShopNewShopConfigurationViewModel from "./viewModels/shops/newShop/newShopConfigurationViewModel";
import viewModelsShopsNewShopNewShopInformationViewModel from "./viewModels/shops/newShop/newShopInformationViewModel";
import viewModelsShopsNewSubjectViewModel from "./viewModels/shops/newSubjectViewModel";
import viewModelsShopsShopsViewModel from "./viewModels/shops/shopsViewModel";
import viewModelsSubjectSubjectDetailViewModel from "./viewModels/subject/subjectDetailViewModel";
import viewModelsSubjectSubjectViewModel from "./viewModels/subject/subjectViewModel";
import viewModelsUsersUserDetailViewModel from "./viewModels/users/userDetailViewModel";
import viewModelsUsersUsersViewModel from "./viewModels/users/usersViewModel";

export default function registerServices(container: Container) {
  container.bind<servicesAlertsService>(servicesAlertsService).toSelf().inSingletonScope();
  container.bind<servicesConfirmationService>(servicesConfirmationService).toSelf().inSingletonScope();
  container.bind<servicesEnum>(servicesEnum).toSelf().inSingletonScope();
  container.bind<servicesInitializationService>(servicesInitializationService).toSelf().inSingletonScope();
  container.bind<servicesLocalizationService>(servicesLocalizationService).toSelf().inSingletonScope();
  container.bind<servicesNotificationService>(servicesNotificationService).toSelf().inSingletonScope();
  container.bind<servicesSecurityService>(servicesSecurityService).toSelf().inSingletonScope();
  container.bind<servicesSettingsService>(servicesSettingsService).toSelf().inSingletonScope();
  container.bind<servicesUnreadMessageService>(servicesUnreadMessageService).toSelf().inSingletonScope();
  container.bind<servicesWarehouseService>(servicesWarehouseService).toSelf().inSingletonScope();
  container.bind<viewModelsAlertViewModel>(viewModelsAlertViewModel).toSelf();
  container.bind<viewModelsConfirmDialogViewModel>(viewModelsConfirmDialogViewModel).toSelf();
  container.bind<viewModelsLoginViewModel>(viewModelsLoginViewModel).toSelf();
  container.bind<viewModelsRootViewModel>(viewModelsRootViewModel).toSelf();
  container.bind<servicesValidationValidationService>(servicesValidationValidationService).toSelf().inSingletonScope();
  container
    .bind<interfaces.Factory<viewModelsDashboardActivitiesViewModel>>(viewModelsDashboardActivitiesViewModel.Factory)
    .toFactory(viewModelsDashboardActivitiesViewModel.Factory);
  container.bind<viewModelsDashboardDashboardViewModel>(viewModelsDashboardDashboardViewModel).toSelf();
  container
    .bind<interfaces.Factory<viewModelsDashboardMemoDetailViewModel>>(viewModelsDashboardMemoDetailViewModel.Factory)
    .toFactory(viewModelsDashboardMemoDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsDashboardMemosViewModel>>(viewModelsDashboardMemosViewModel.Factory)
    .toFactory(viewModelsDashboardMemosViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsDashboardStatisticsViewModel>>(viewModelsDashboardStatisticsViewModel.Factory)
    .toFactory(viewModelsDashboardStatisticsViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsEnumerationsEnumerationDetailViewModel>>(viewModelsEnumerationsEnumerationDetailViewModel.Factory)
    .toFactory(viewModelsEnumerationsEnumerationDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsEnumerationsEnumerationScannerDetailViewModel>>(viewModelsEnumerationsEnumerationScannerDetailViewModel.Factory)
    .toFactory(viewModelsEnumerationsEnumerationScannerDetailViewModel.Factory);
  container.bind<viewModelsEnumerationsEnumerationsViewModel>(viewModelsEnumerationsEnumerationsViewModel).toSelf();
  container
    .bind<interfaces.Factory<viewModelsMessageGroupsMessageGroupDetailViewModel>>(viewModelsMessageGroupsMessageGroupDetailViewModel.Factory)
    .toFactory(viewModelsMessageGroupsMessageGroupDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsMessageGroupsMessageGroupsViewModel>>(viewModelsMessageGroupsMessageGroupsViewModel.Factory)
    .toFactory(viewModelsMessageGroupsMessageGroupsViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsMessagesMessageDetailViewModel>>(viewModelsMessagesMessageDetailViewModel.Factory)
    .toFactory(viewModelsMessagesMessageDetailViewModel.Factory);
  container.bind<viewModelsMessagesMessagesViewModel>(viewModelsMessagesMessagesViewModel).toSelf();
  container.bind<viewModelsOpportunitiesOpportunitiesViewModel>(viewModelsOpportunitiesOpportunitiesViewModel).toSelf();
  container
    .bind<interfaces.Factory<viewModelsOpportunitiesOpportunityDetailViewModel>>(viewModelsOpportunitiesOpportunityDetailViewModel.Factory)
    .toFactory(viewModelsOpportunitiesOpportunityDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsParcelBoxParcelBoxDetailViewModel>>(viewModelsParcelBoxParcelBoxDetailViewModel.Factory)
    .toFactory(viewModelsParcelBoxParcelBoxDetailViewModel.Factory);
  container.bind<viewModelsParcelBoxParcelBoxesViewModel>(viewModelsParcelBoxParcelBoxesViewModel).toSelf();
  container
    .bind<interfaces.Factory<viewModelsPaymentTerminalsPaymentTerminalDetailViewModel>>(viewModelsPaymentTerminalsPaymentTerminalDetailViewModel.Factory)
    .toFactory(viewModelsPaymentTerminalsPaymentTerminalDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsPaymentTerminalsPaymentTerminalsImportViewModel>>(viewModelsPaymentTerminalsPaymentTerminalsImportViewModel.Factory)
    .toFactory(viewModelsPaymentTerminalsPaymentTerminalsImportViewModel.Factory);
  container.bind<viewModelsPaymentTerminalsPaymentTerminalsViewModel>(viewModelsPaymentTerminalsPaymentTerminalsViewModel).toSelf();
  container
    .bind<interfaces.Factory<viewModelsPickupPointCheckSubjectViewModel>>(viewModelsPickupPointCheckSubjectViewModel.Factory)
    .toFactory(viewModelsPickupPointCheckSubjectViewModel.Factory);
  container.bind<viewModelsPickupPointNewPickupPointViewModel>(viewModelsPickupPointNewPickupPointViewModel).toSelf();
  container
    .bind<interfaces.Factory<viewModelsScanDevicesScanDeviceDetailViewModel>>(viewModelsScanDevicesScanDeviceDetailViewModel.Factory)
    .toFactory(viewModelsScanDevicesScanDeviceDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsScanDevicesScanDevicesImportViewModel>>(viewModelsScanDevicesScanDevicesImportViewModel.Factory)
    .toFactory(viewModelsScanDevicesScanDevicesImportViewModel.Factory);
  container.bind<viewModelsScanDevicesScanDevicesViewModel>(viewModelsScanDevicesScanDevicesViewModel).toSelf();
  container.bind<viewModelsSettingsSettingsViewModel>(viewModelsSettingsSettingsViewModel).toSelf();
  container
    .bind<interfaces.Factory<viewModelsShopsNewSubjectViewModel>>(viewModelsShopsNewSubjectViewModel.Factory)
    .toFactory(viewModelsShopsNewSubjectViewModel.Factory);
  container.bind<viewModelsShopsShopsViewModel>(viewModelsShopsShopsViewModel).toSelf();
  container
    .bind<interfaces.Factory<viewModelsSubjectSubjectDetailViewModel>>(viewModelsSubjectSubjectDetailViewModel.Factory)
    .toFactory(viewModelsSubjectSubjectDetailViewModel.Factory);
  container.bind<viewModelsSubjectSubjectViewModel>(viewModelsSubjectSubjectViewModel).toSelf();
  container
    .bind<interfaces.Factory<viewModelsUsersUserDetailViewModel>>(viewModelsUsersUserDetailViewModel.Factory)
    .toFactory(viewModelsUsersUserDetailViewModel.Factory);
  container.bind<viewModelsUsersUsersViewModel>(viewModelsUsersUsersViewModel).toSelf();
  container
    .bind<interfaces.Factory<viewModelsShopsDetailAccessControlViewModel>>(viewModelsShopsDetailAccessControlViewModel.Factory)
    .toFactory(viewModelsShopsDetailAccessControlViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailBoxDetailViewModel>>(viewModelsShopsDetailBoxDetailViewModel.Factory)
    .toFactory(viewModelsShopsDetailBoxDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailCommonDetailViewModel>>(viewModelsShopsDetailCommonDetailViewModel.Factory)
    .toFactory(viewModelsShopsDetailCommonDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailParcelShopUserDetailViewModel>>(viewModelsShopsDetailParcelShopUserDetailViewModel.Factory)
    .toFactory(viewModelsShopsDetailParcelShopUserDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailPotentialDetailViewModel>>(viewModelsShopsDetailPotentialDetailViewModel.Factory)
    .toFactory(viewModelsShopsDetailPotentialDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailShopDetailViewModel>>(viewModelsShopsDetailShopDetailViewModel.Factory)
    .toFactory(viewModelsShopsDetailShopDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsNewShopNewAdditionalInformationViewModel>>(viewModelsShopsNewShopNewAdditionalInformationViewModel.Factory)
    .toFactory(viewModelsShopsNewShopNewAdditionalInformationViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsNewShopNewAddressesViewModel>>(viewModelsShopsNewShopNewAddressesViewModel.Factory)
    .toFactory(viewModelsShopsNewShopNewAddressesViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsNewShopNewContactsViewModel>>(viewModelsShopsNewShopNewContactsViewModel.Factory)
    .toFactory(viewModelsShopsNewShopNewContactsViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsNewShopNewOpeningHoursViewModel>>(viewModelsShopsNewShopNewOpeningHoursViewModel.Factory)
    .toFactory(viewModelsShopsNewShopNewOpeningHoursViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsNewShopNewParcelShopViewModel>>(viewModelsShopsNewShopNewParcelShopViewModel.Factory)
    .toFactory(viewModelsShopsNewShopNewParcelShopViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsNewShopNewShopConfigurationViewModel>>(viewModelsShopsNewShopNewShopConfigurationViewModel.Factory)
    .toFactory(viewModelsShopsNewShopNewShopConfigurationViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsNewShopNewShopInformationViewModel>>(viewModelsShopsNewShopNewShopInformationViewModel.Factory)
    .toFactory(viewModelsShopsNewShopNewShopInformationViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailActivityActivityDetailViewModel>>(viewModelsShopsDetailActivityActivityDetailViewModel.Factory)
    .toFactory(viewModelsShopsDetailActivityActivityDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailEditEditAdditionalInformationViewModel>>(viewModelsShopsDetailEditEditAdditionalInformationViewModel.Factory)
    .toFactory(viewModelsShopsDetailEditEditAdditionalInformationViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailEditEditAddressesViewModel>>(viewModelsShopsDetailEditEditAddressesViewModel.Factory)
    .toFactory(viewModelsShopsDetailEditEditAddressesViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailEditEditContactsViewModel>>(viewModelsShopsDetailEditEditContactsViewModel.Factory)
    .toFactory(viewModelsShopsDetailEditEditContactsViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailEditEditOpeningHoursViewModel>>(viewModelsShopsDetailEditEditOpeningHoursViewModel.Factory)
    .toFactory(viewModelsShopsDetailEditEditOpeningHoursViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailEditEditShopConfigurationViewModel>>(viewModelsShopsDetailEditEditShopConfigurationViewModel.Factory)
    .toFactory(viewModelsShopsDetailEditEditShopConfigurationViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailEditEditShopInformationViewModel>>(viewModelsShopsDetailEditEditShopInformationViewModel.Factory)
    .toFactory(viewModelsShopsDetailEditEditShopInformationViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailEditEditShopViewModel>>(viewModelsShopsDetailEditEditShopViewModel.Factory)
    .toFactory(viewModelsShopsDetailEditEditShopViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailInvoicingBonusDetailViewModel>>(viewModelsShopsDetailInvoicingBonusDetailViewModel.Factory)
    .toFactory(viewModelsShopsDetailInvoicingBonusDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailInvoicingBonusesViewModel>>(viewModelsShopsDetailInvoicingBonusesViewModel.Factory)
    .toFactory(viewModelsShopsDetailInvoicingBonusesViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailInvoicingBonusNoteDetailViewModel>>(viewModelsShopsDetailInvoicingBonusNoteDetailViewModel.Factory)
    .toFactory(viewModelsShopsDetailInvoicingBonusNoteDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailInvoicingBoxInvoicingViewModel>>(viewModelsShopsDetailInvoicingBoxInvoicingViewModel.Factory)
    .toFactory(viewModelsShopsDetailInvoicingBoxInvoicingViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailInvoicingInvoicesViewModel>>(viewModelsShopsDetailInvoicingInvoicesViewModel.Factory)
    .toFactory(viewModelsShopsDetailInvoicingInvoicesViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailInvoicingK2ExportViewModel>>(viewModelsShopsDetailInvoicingK2ExportViewModel.Factory)
    .toFactory(viewModelsShopsDetailInvoicingK2ExportViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailInvoicingPaymentStoppageDetailViewModel>>(viewModelsShopsDetailInvoicingPaymentStoppageDetailViewModel.Factory)
    .toFactory(viewModelsShopsDetailInvoicingPaymentStoppageDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailInvoicingPaymentStoppageViewModel>>(viewModelsShopsDetailInvoicingPaymentStoppageViewModel.Factory)
    .toFactory(viewModelsShopsDetailInvoicingPaymentStoppageViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailInvoicingRecurringPaymentsViewModel>>(viewModelsShopsDetailInvoicingRecurringPaymentsViewModel.Factory)
    .toFactory(viewModelsShopsDetailInvoicingRecurringPaymentsViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailInvoicingRewardsEditViewModel>>(viewModelsShopsDetailInvoicingRewardsEditViewModel.Factory)
    .toFactory(viewModelsShopsDetailInvoicingRewardsEditViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailInvoicingRewardsViewModel>>(viewModelsShopsDetailInvoicingRewardsViewModel.Factory)
    .toFactory(viewModelsShopsDetailInvoicingRewardsViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailInvoicingShopInvoicingViewModel>>(viewModelsShopsDetailInvoicingShopInvoicingViewModel.Factory)
    .toFactory(viewModelsShopsDetailInvoicingShopInvoicingViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailWarehouseStockTakingViewModel>>(viewModelsShopsDetailWarehouseStockTakingViewModel.Factory)
    .toFactory(viewModelsShopsDetailWarehouseStockTakingViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsShopsDetailWarehouseWarehouseViewModel>>(viewModelsShopsDetailWarehouseWarehouseViewModel.Factory)
    .toFactory(viewModelsShopsDetailWarehouseWarehouseViewModel.Factory);
}
