import { Input, Select } from "@frui.ts/bootstrap";
import { registerView, ViewComponent } from "@frui.ts/views";
import LoadingOverlay from "controls/loadingOverlay";
import { StringInput } from "controls/stringInput";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Col, Form, InputGroup, Modal } from "react-bootstrap";
import BonusDetailViewModel from "viewModels/shops/detail/invoicing/bonusDetailViewModel";
import preventDefault from "../../../preventDefault";

import styles from "./bonusDetail.module.scss";

const bonusDetailView: ViewComponent<BonusDetailViewModel> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral: tg } = vm.localization;
  const ta = (name: string) => translateAttribute("bonus", name);
  const taByType = (name: string) => translateAttribute(vm.isParcelBox ? "bonus_box" : "bonus", name);

  const submitButtonName = vm.isCreating ? (vm.isParcelBox ? "enter" : "create") : "save";

  return (
    <Modal centered={true} show={true} onHide={vm.requestClose}>
      <Form onSubmit={preventDefault(vm.save)}>
        <Modal.Header closeButton>
          <Modal.Title>{vm.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {vm.item && (
            <>
              {!vm.isParcelBox && (
                <Form.Row>
                  <Form.Group as={Col} md={8}>
                    <Form.Label>{taByType("type")}</Form.Label>
                    <Select
                      target={vm.item}
                      property="type"
                      items={vm.types}
                      mode="key"
                      textProperty="name"
                      emptyText={tg("select_placeholder")}
                      allowEmpty
                      isNumeric
                    />
                  </Form.Group>
                </Form.Row>
              )}

              {vm.isParcelBox && (
                <Form.Row>
                  <Form.Group as={Col} md={8}>
                    <Form.Label>{taByType("name")}</Form.Label>
                    <StringInput trim={true} target={vm.item} property="name" type="text" />
                  </Form.Group>
                </Form.Row>
              )}

              <Form.Row>
                <Form.Group as={Col} md={6.5} className={styles.priceInput}>
                  <Form.Label>{taByType("price")}</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Input target={vm.item} property="price" type="number" placeholder={vm.pricePlaceholder} />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col}>
                  <div className="month-picker-container">
                    <Form.Label>{taByType("month")}</Form.Label>
                    <div className="month-picker">
                      <div>
                        <Select
                          target={vm.item}
                          property="month"
                          items={vm.months}
                          mode="key"
                          keyProperty="value"
                          emptyText={tg("parcel_shop.invoicing.bonus.month_placeholder")}
                          allowEmpty
                          isNumeric
                          className={styles.shortSelect}
                        />
                      </div>
                      <span>/</span>
                      <div>
                        <Select
                          target={vm.item}
                          property="year"
                          items={vm.years}
                          mode="key"
                          keyProperty="value"
                          emptyText={tg("parcel_shop.invoicing.bonus.year_placeholder")}
                          allowEmpty
                          isNumeric
                          className={styles.shortSelect}
                        />
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Form.Row>
              {!vm.isParcelBox && (
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{ta("note")}</Form.Label>
                    <StringInput trim={true} target={vm.item} property="note" type="text" />
                  </Form.Group>
                </Form.Row>
              )}
            </>
          )}
          <LoadingOverlay loading={vm.busyWatcher.isBusy} />
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button type="submit" disabled={!vm.canSave}>
            {ta(submitButtonName)}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});

export default registerView(bonusDetailView, BonusDetailViewModel);
