import { ValidationControlBase } from "@frui.ts/bootstrap";
import { CommonInputProps } from "@frui.ts/bootstrap/dist/controls/commonInputProps";
import { bound } from "@frui.ts/helpers";
import { IBindingProps } from "@frui.ts/views";
import React from "react";
import { Form, FormControlProps } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import { createAutoCorrectedDatePipe } from "text-mask-addons/dist/textMaskAddons";

export class TimeInput<TTarget> extends ValidationControlBase<
  TTarget,
  FormControlProps & CommonInputProps & IBindingProps<TTarget>
> {
  @bound protected renderInner() {
    const { disabled, placeholder } = this.inheritedProps as FormControlProps & CommonInputProps;
    const validationError = this.getValidationError();
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe("HH:MM");

    return (
      <>
        <MaskedInput
          mask={[/[0-9]/, /[0-9]/, ":", /[0-9]/, /[0-9]/]}
          className={`form-control ${validationError ? "is-invalid" : ""}`}
          placeholder={placeholder}
          disabled={disabled}
          guide={true}
          showMask={!disabled}
          pipe={autoCorrectedDatePipe}
          value={this.value ?? ""}
          onChange={this.handleValueChanged}
        />
        {validationError && <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>}
      </>
    );
  }

  @bound protected handleValueChanged(e: React.FormEvent<any>) {
    const target = e.target as HTMLInputElement;
    const valueWithoutMask = target.value.replace(/_/g, "");

    this.setValue(valueWithoutMask);
  }
}
