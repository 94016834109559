import React, { FunctionComponent } from "react";
import { ConfigurationSectionProps } from "./types";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import classnames from "classnames";
import { Check, Input } from "@frui.ts/bootstrap";
import { observer } from "mobx-react-lite";
import { CheckArray } from "controls/checkArray";

const WareHouseCapacity: FunctionComponent<ConfigurationSectionProps> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral: tg } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("parcel_shop", attribute);
  };

  return (
    <>
      <h3>{tg(!vm.isParcelBox ? "parcel_shop.store_capacity" : "parcel_box.box_capacity")}</h3>
      <Row>
        <Form.Group as={Col} md={3} className={classnames({ required: !vm.isPotential && !vm.isAlzaBox })}>
          <Form.Label>{ta("capacity")}</Form.Label>
          <Input target={vm.item} property="capacity" type="number" />
        </Form.Group>
        {!vm.isParcelBox && !vm.isAlzaBox && (
          <Form.Group as={Col} md={3} className={classnames({ required: !vm.isPotential && !vm.isAlzaBox })}>
            <Form.Label>{ta("max_daily_capacity")}</Form.Label>
            <Input target={vm.item} property="maxDailyCapacity" type="number" />
          </Form.Group>
        )}
      </Row>
      <Row>
        <Form.Group as={Col} md={3}>
          <Check
            custom
            target={vm.item}
            property="enforceSizes"
            label={`${ta("enforce_sizes")}:`}
            id="enforce_sizes"
            onValueChanged={vm.checkAllSizes}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md={12}>
          <InputGroup>
            {vm.allAcceptedSizes.map(size => (
              <CheckArray
                inline
                custom
                target={vm.item}
                key={size}
                property="acceptedSize"
                isNumeric={false}
                id={size.toString()}
                onValueChanged={vm.checkPrecedingSizes}
                label={size}
              />
            ))}
          </InputGroup>
        </Form.Group>
      </Row>
    </>
  );
});

export default WareHouseCapacity;
