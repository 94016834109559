import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class UserDetailDto {
  id!: number;

  @observable
  email!: string;

  static ValidationRules = {
    id: { required: true, number: true },
    email: { required: true },
  };
}
