import { RepositoryBase } from "../repositoryBase";
import { Events } from "../../services/eventBus";
import CreatePotentialPsDto from "../../entities/createPotentialPsDto";
import PotentialPs from "../../entities/potentialPs";
import Contact from "../../entities/contact";
import IAccessPointRepository from "./IAccessPointRepository";
import { IPagingFilter } from "@frui.ts/data";
import { ParcelShopFilter } from "../../manualEntities/parcelShop";
import IListResponse from "../listResponse";
import Address from "../../entities/address";
import OpeningHourDto from "../../entities/openingHourDto";
import { entityToPlain } from "../helpers";
import PotentialPsDetailDto from "../../entities/potentialPsDetailDto";
import ActivityDashboardUpdateDto from "entities/activityDashboardUpdateDto";

const unlimited = { offset: 0, limit: 999 };

export default class PotentialRepository
  extends RepositoryBase
  implements IAccessPointRepository<PotentialPs, PotentialPsDetailDto> {
  getList(paging: IPagingFilter, filter: ParcelShopFilter) {
    const params = RepositoryBase.createListRequestParams(filter, paging);
    return this.callApi(api =>
      api.all("potential_ps").get<IListResponse<PotentialPs>>(params).then(RepositoryBase.convertListResponse)
    );
  }

  createPotential(item: CreatePotentialPsDto) {
    return this.callApi(api => api.all("potential_ps").postEntity(item, PotentialPs), Events.AccessPoints.Created);
  }

  updateItem(id: number, item: PotentialPsDetailDto) {
    return this.callApi(api => api.one("potential_ps", id).putEntity(entityToPlain(item)), Events.AccessPoints.Changed);
  }

  getDetail(id: number) {
    return this.callApi(api => api.one("potential_ps", id).getEntity<PotentialPsDetailDto>(PotentialPsDetailDto));
  }

  getContacts(potentialId: number) {
    return this.callApi(api =>
      api
        .one("potential_ps", potentialId)
        .all("contacts")
        .getPagedEntities(Contact, unlimited)
        .then(x => x[0])
    );
  }

  addContact(potentialId: number, contact: Contact) {
    return this.callApi(
      api => api.one("potential_ps", potentialId).all("contacts").postEntity(contact),
      Events.AccessPoints.Contacts.Changed
    );
  }
  updateContact(potentialId: number, id: number, contact: Contact) {
    return this.callApi(
      api => api.one("potential_ps", potentialId).one("contacts", id).putEntity(contact),
      Events.AccessPoints.Contacts.Changed
    );
  }

  deleteContact(potentialId: number, id: number) {
    return this.callApi(
      api => api.one("potential_ps", potentialId).one("contacts", id).delete(),
      Events.AccessPoints.Contacts.Changed
    );
  }

  getAddresses(potentialId: number) {
    return this.callApi(api =>
      api
        .one("potential_ps", potentialId)
        .all("address")
        .getPagedEntities(Address, unlimited)
        .then(x => x[0])
    );
  }

  addAddress(potentialId: number, address: Address) {
    return this.callApi(
      api => api.one("potential_ps", potentialId).all("address").postEntity(address),
      Events.AccessPoints.Addresses.Changed
    );
  }

  updateAddress(potentialId: number, id: number, address: Address) {
    return this.callApi(
      api => api.one("potential_ps", potentialId).one("address", id).putEntity(address),
      Events.AccessPoints.Addresses.Changed
    );
  }

  deleteAddress(potentialId: number, id: number) {
    return this.callApi(
      api => api.one("potential_ps", potentialId).one("address", id).delete(),
      Events.AccessPoints.Addresses.Changed
    );
  }

  getOpeningHours(potentialId: number) {
    return this.callApi(api =>
      api
        .one("potential_ps", potentialId)
        .all("opening_hour")
        .getPagedEntities(OpeningHourDto, unlimited)
        .then(x => x[0])
    );
  }

  disableItem(id: number) {
    return this.callApi(api => api.one("potential_ps", id).all("discard").putEntity(undefined), Events.AccessPoints.Changed);
  }

  updateOpeningHours(potentialId: number, openingHours: OpeningHourDto[]) {
    return this.callApi(
      api =>
        api
          .one("potential_ps", potentialId)
          .all("opening_hour")
          .put<OpeningHourDto[]>({ opening_hours: openingHours.length > 0 ? openingHours : [] }),
      Events.ParcelShops.OpeningHours.Changed
    );
  }

  updateActivityStatus(id: number, payload: ActivityDashboardUpdateDto) {
    return this.callApi(api => api.one("activities/update_from_dashboard", id).putEntity(payload), Events.Activities.Changed);
  }
}
