import EnumRepository from "data/repositories/enumRepository";
import { action, computed, observable } from "mobx";
import EditableEnum from "../models/enumerations/editableEnum";
import { models } from "models/shops/interface";

type EnumType =
  | "access_point_partners"
  | "activity_categories"
  | "activity_entity_types"
  | "activity_states"
  | "activity_types"
  | "address_types"
  | "banks"
  | "bonus_status"
  | "bonus_types"
  | "contact_types"
  | "countries"
  | "crm_users"
  | "depos"
  | "memo_status"
  | "mobile_users"
  | "network_partners"
  | "parcel_box_status"
  | "parcel_statuses"
  | "payment_terminal_status"
  | "pb_invoicing_cycles"
  | "pb_opportunities"
  | "potential_phases"
  | "ps_categories"
  | "ps_filter_status"
  | "ps_opportunities"
  | "ps_status"
  | "salesmans"
  | "scan_device_status"
  | "subject_types";

const editableEnumNames = [
  "pb_competitions",
  "pb_opportunity_status_reasons",
  "pb_terminations",
  "pb_types",
  "ps_competitions",
  "ps_opportunity_status_reasons",
  "ps_terminations",
  "ps_types",
  "scanners",
];

export type EditableEnumType = typeof editableEnumNames[number];

const scannerEnumName = "scanners";

export type ScannerEnumType = typeof scannerEnumName;

/**
 * Enum service
 */
export default class EnumService {
  /**
   * Fetched enums.
   *
   * @type {EnumContainer}
   * @memberof EnumGeneralVM
   */
  @observable enums: models.EnumContainer = {};

  @observable editableEnums: Map<EditableEnumType, EditableEnum[]> = new Map();

  /**
   * Indicator of currently fetching enums, there is multiple sources for fetching enums.
   *
   * @type {boolean}
   * @memberof EnumGeneralVM
   */
  currentlyFetching = false;

  /**
   * Inline enum cache.
   *
   * @private
   * @type {({ [name: string]: { [key: string]: models.IEnumType | undefined }})}
   * @memberof EnumGeneralVM
   */
  private enumCache: { [name: string]: { [key: string]: models.Enum | undefined } };
  private enumArrayCache: { [name: string]: { [key: string]: models.Enum[] | undefined } };

  constructor(private repository: EnumRepository) {}

  initialize() {
    this.setEnums({});
    this.enumCache = {};
    this.enumArrayCache = {};
    return this.fetchEnums();
  }

  async fetchEnums() {
    if (!this.currentlyFetching) {
      this.currentlyFetching = true;
      await Promise.all([this.fetchInternalEnums(), this.fetchEditableEnums()]);
      this.currentlyFetching = false;
    }
  }

  async fetchInternalEnums() {
    const enums: models.EnumContainer = await this.repository.fetchEnums();
    this.setEnums(enums);
  }

  async fetchEditableEnums() {
    const paging = { offset: 0, limit: 1000 };
    const [
      pbTerminations,
      psTerminations,
      pbTypes,
      psTypes,
      pbCompetitions,
      psCompetitions,
      scanners,
      psOpportunityStatusReasons,
      pbOpportunityStatusReasons,
    ] = await Promise.all([
      this.repository.getAll("pb_terminations", paging, false),
      this.repository.getAll("ps_terminations", paging, false),
      this.repository.getAll("pb_types", paging, false),
      this.repository.getAll("ps_types", paging, false),
      this.repository.getAll("pb_competitions", paging, false),
      this.repository.getAll("ps_competitions", paging, false),
      this.repository.getAll("scanners", paging, false),
      this.repository.getAll("ps_opportunity_status_reasons", paging, false),
      this.repository.getAll("pb_opportunity_status_reasons", paging, false),
    ]);

    this.editableEnums.set("pb_terminations", pbTerminations[0]);
    this.editableEnums.set("ps_terminations", psTerminations[0]);
    this.editableEnums.set("pb_types", pbTypes[0]);
    this.editableEnums.set("ps_types", psTypes[0]);
    this.editableEnums.set("pb_competitions", pbCompetitions[0]);
    this.editableEnums.set("ps_competitions", psCompetitions[0]);
    this.editableEnums.set("scanners", scanners[0]);
    this.editableEnums.set("ps_opportunity_status_reasons", psOpportunityStatusReasons[0]);
    this.editableEnums.set("pb_opportunity_status_reasons", pbOpportunityStatusReasons[0]);
  }

  @computed
  get isLoaded(): boolean {
    return this.enums !== undefined && Object.keys(this.enums).length !== 0;
  }

  @action
  setEnums(enums: models.EnumContainer) {
    this.enums = enums;
  }

  /**
   * Return array of values for enum.
   *
   * @param name Enum name
   */
  values(name: "all_accepted_sizes"): models.SizesEnum[];
  values(name: "salesmans"): models.SalesmanEnum[];
  values(name: "crm_users"): models.UserEnum[];
  values(name: "potential_phases"): models.PotentialPhase[];
  values(name: "scanners"): models.ScannerEnum[];
  values(name: EnumType): models.Enum[];
  values(name: EditableEnumType, includeDeleted?: boolean): EditableEnum[];
  values(name: EnumType | EditableEnumType, includeDeleted?: boolean): models.Enum[] {
    if (!this.isLoaded) {
      void this.fetchEnums();
      return [];
    } else if (editableEnumNames.includes(name)) {
      let filter = (item: EditableEnum) => item.active === true;
      if (includeDeleted) {
        filter = (item: EditableEnum) => true;
      }
      return (this.editableEnums.get(name) ?? []).filter(filter);
    } else {
      return this.enums[name];
    }
  }

  /**
   * Return enum value.
   *
   * @param name Enum name
   * @param code Enum code
   */
  value(name: "salesmans", code: string | number, byCode?: boolean): models.SalesmanEnum | undefined;
  value(name: "crm_users", code: string | number, byCode?: boolean): models.UserEnum | undefined;
  value(name: "potential_phases", code: string | number, byCode?: boolean): models.PotentialPhase | undefined;
  value(name: "scanners", code: string | number, byCode?: boolean): models.ScannerEnum | undefined;
  value(name: EnumType | EditableEnumType, code: string | number, byCode?: boolean): models.Enum | undefined;
  value(name: EnumType | EditableEnumType, code: string | number, byCode = false) {
    if (!this.enumCache[name]) {
      this.enumCache[name] = {};
    }

    const key = code;

    if (this.enumCache[name] && this.enumCache[name][key]) {
      return this.enumCache[name][key];
    }

    const values = this.values(name, true) || [];

    if (values.length === 0) {
      return undefined;
    }

    let selectedItem: models.Enum | undefined;

    if (byCode) {
      values.forEach(item => {
        // eslint-disable-next-line
        if (item.code == code) {
          selectedItem = item;
        }
      });
    } else {
      values.forEach(item => {
        // eslint-disable-next-line
        if (item.id == code) {
          selectedItem = item;
        }
      });
    }

    if (selectedItem) {
      this.enumCache[name][key] = selectedItem;
      return selectedItem;
    } else {
      return undefined;
    }
  }

  /**
   * Return array of enum values.
   *
   * @param name Enum name
   * @param code Enum code
   */
  valueForArray(name: "salesmans", code: number[]): models.SalesmanEnum[] | undefined;
  valueForArray(name: "crm_users", code: number[]): models.UserEnum[] | undefined;
  valueForArray(name: "potential_phases", code: number[]): models.PotentialPhase[] | undefined;
  valueForArray(name: EnumType, code: number[]): models.Enum[] | undefined;
  valueForArray(name: EnumType, code: number[]) {
    if (!this.enumArrayCache[name]) {
      this.enumArrayCache[name] = {};
    }

    const key = code.join("");

    if (this.enumArrayCache[name] && this.enumArrayCache[name][key]) {
      return this.enumArrayCache[name][key];
    }

    const values = this.values(name) || [];

    if (values.length === 0) {
      return undefined;
    }

    const selectedItems: models.Enum[] = [];
    values.forEach(item => {
      if (code.indexOf(item.id) > -1) {
        selectedItems.push(item);
      }
    });

    if (selectedItems) {
      this.enumArrayCache[name][key] = selectedItems;
      return selectedItems;
    } else {
      return undefined;
    }
  }
}
