import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, getValidationMessage, ManualEntityValidator, validate } from "@frui.ts/validation";
import { interfaces } from "inversify";
import { computed, observable } from "mobx";
import { FileRejection } from "react-dropzone";
import PaymentTerminalRepository from "data/repositories/paymentTerminalRepository";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import PaymentTerminalsViewModel from "./paymentTerminalsViewModel";
import PaymentTerminalImportStatusResponseDto from "entities/paymentTerminalImportStatusResponseDto";
import PaymentTerminalImportStatusDto from "entities/paymentTerminalImportStatusDto";
import PaymentTerminalImportStatusDtoResult from "entities/paymentTerminalImportStatusDtoResult";

class PaymentTerminalsImport {
  @observable file?: File;
}

export enum PaymentTerminalImportType {
  Default = 1,
  AfterService,
  MassPsAssign,
}

export default class PaymentTerminalsImportViewModel extends ScreenBase {
  @observable busyWatcher = new BusyWatcher();
  @observable entity: PaymentTerminalsImport = new PaymentTerminalsImport();
  @observable manualFileValidator = new ManualEntityValidator<PaymentTerminalsImport>(true);
  @observable importStatus?: PaymentTerminalImportStatusResponseDto;

  @computed
  get errorMessage() {
    return getValidationMessage(this.entity, "file");
  }

  @computed
  get acceptedFormats() {
    return ["text/csv"];
  }

  @computed
  get submitDisabled() {
    return this.busyWatcher.isBusy || this.errorMessage !== undefined;
  }

  constructor(
    public onHide: () => void,
    private type: PaymentTerminalImportType,
    private repository: PaymentTerminalRepository,
    private localizationService: LocalizationService,
    private notificationService: NotificationService
  ) {
    super();
    attachAutomaticValidator(this.entity, {
      file: {
        required: true,
        isFileType: {
          // Type "text/csv" is on Windows recognized as application/vnd.ms-excel and without Office mime type is empty string...
          type: ["text/csv", "application/vnd.ms-excel", ""],
        },
        manualErrors: this.manualFileValidator,
      },
    });
  }

  @bound
  onDrop(acceptedFiles: File[], fileRejections?: FileRejection[]) {
    this.manualFileValidator.clearErrors();
    if (acceptedFiles.length) {
      this.entity.file = acceptedFiles[0];
    }

    if (fileRejections?.length) {
      this.entity.file = fileRejections[0].file;
      this.manualFileValidator.addError(
        "file",
        this.localizationService.translateGeneral("validators.isFileType.notDesiredFileType")
      );
    }

    validate(this.entity);
  }

  @bound
  onDeleteFileClick() {
    this.entity.file = undefined;
    this.manualFileValidator.clearErrors();
  }

  @bound
  @watchBusy
  async onSubmit() {
    if (validate(this.entity)) {
      try {
        this.importStatus = await this.repository.import(this.entity.file!, this.type);
        this.notificationService.addNotification(
          this.translate("imported"),
          SeverityLevel.success,
          PaymentTerminalsViewModel.notificationScope
        );
      } catch (e) {
        this.manualFileValidator.addError("file", this.translate("wrongCSVStructure"));
      }
    }
  }

  @bound
  translate(code: string) {
    return this.localizationService.translateGeneral(`payment_terminal.import.${code}`);
  }

  @bound
  translateAttribute(attribute: keyof PaymentTerminalImportStatusDto) {
    return this.localizationService.translateAttribute("payment_terminal_import_status", attribute);
  }

  @bound
  translateImportStatusResult(statusResult: PaymentTerminalImportStatusDtoResult) {
    return this.localizationService.translateAttribute("import_status_result", statusResult.toString());
  }

  static Factory({ container }: interfaces.Context) {
    return (onHide: () => void, type: PaymentTerminalImportType) =>
      new PaymentTerminalsImportViewModel(
        onHide,
        type,
        container.get(PaymentTerminalRepository),
        container.get(LocalizationService),
        container.get(NotificationService)
      );
  }
}
