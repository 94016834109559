import { DetailViewModel } from "@frui.ts/datascreens";
import { attachAutomaticDirtyWatcher, isDirty } from "@frui.ts/dirtycheck";
import { bound } from "@frui.ts/helpers";
import { watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import { ISelectItem } from "@frui.ts/views";
import MemosRepository from "data/repositories/memosRepository";
import CreateMemoDto from "entities/createMemoDto";
import MemoDetailDto from "entities/memoDetailDto";
import { interfaces } from "inversify";
import { User } from "manualEntities/user";
import { action, computed, observable, runInAction } from "mobx";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import UserContext from "services/userContext";

const SELECT_ALL_KEY = "_select_all_";

export default class MemoDetailViewModel extends DetailViewModel<CreateMemoDto> {
  @observable selectedUsers: TreeItem[] = [];

  constructor(
    public mode: "create" | "edit" | "show",
    private id: number | undefined,
    private users: User[],
    private repository: MemosRepository,
    private enums: EnumService,
    public localizationService: LocalizationService,
    private notificationService: NotificationService,
    private userContext: UserContext
  ) {
    super();
    this.name = mode;
  }

  @watchBusy
  // eslint-disable-next-line sonarjs/cognitive-complexity
  protected async loadDetail() {
    if (this.mode !== "create" && this.id) {
      const memo = await this.repository.getMemo(this.id);
      runInAction(() => {
        // Make assignees for admin case or for specialist.
        const assignees = this.userContext.isAdmin
          ? memo.assignees ?? []
          : this.userContext.userId
          ? [this.userContext.userId]
          : [];

        if (assignees.length === this.users.length) {
          // selected all
          this.selectedUsers = this.possibleUsersWithAll;
        } else {
          this.selectedUsers =
            (this.possibleUsersWithAll[0].children || []).filter(item => assignees.includes(Number(item.id))) ?? [];
        }
      });
      attachAutomaticDirtyWatcher(memo);
      attachAutomaticValidator(memo, CreateMemoDto.ValidationRules);
      return Promise.resolve(memo as CreateMemoDto);
    }
    const newMemo = new CreateMemoDto();
    attachAutomaticValidator(newMemo, CreateMemoDto.ValidationRules);
    return Promise.resolve(newMemo);
  }

  @computed
  get possibleUsersWithAll(): TreeItem[] {
    return [
      {
        id: SELECT_ALL_KEY,
        label: this.localizationService.translateGeneral("select_all"),
        isDefaultExpanded: true,
        children: this.possibleUsers,
      },
    ];
  }

  @computed
  get possibleUsers(): TreeItem[] {
    return this.users.map(x => ({
      id: `${x.id}`,
      label: x.first_name && x.last_name ? x.first_name + " " + x.last_name : x.email,
    }));
  }

  @computed
  get isReadOnly() {
    return this.mode === "show";
  }

  @computed
  get authorUser(): TreeItem | undefined {
    const dto = this.item as MemoDetailDto;

    if (dto.authorId) {
      return this.possibleUsers.find(item => item.id === `${dto.authorId}`);
    } else {
      return {
        id: "-1",
        label: `${dto.authorName}`,
      };
    }
  }

  @action.bound
  onSelectUser = (values: TreeItem[]) => {
    const isSelectAllSelected = values.map(item => item.id).includes(SELECT_ALL_KEY);

    runInAction(() => {
      if (isSelectAllSelected) {
        this.item.assignees = this.users.map(item => item.id);
      } else {
        this.item.assignees = values.map(item => Number(item.id));
      }
    });

    this.selectedUsers = values;
  };

  @bound
  @watchBusy
  async save() {
    if (validate(this.item)) {
      if (this.mode === "edit") {
        await this.repository.updateMemo(this.id!, this.item);
        this.requestClose();
      } else {
        await this.repository.createMemo(this.item);
        this.requestClose();
      }

      const msg = this.localizationService
        .translateGeneral(`memos.${this.mode === "create" ? "created" : "updated"}`)
        .replace("%SUBJECT%", this.item.subject);

      this.notificationService.addNotification(msg, SeverityLevel.success);
    }
  }

  @bound
  @watchBusy
  async delete() {
    await this.repository.deleteMemo(this.id!);
    this.requestClose();
    this.notificationService.addNotification(
      this.localizationService.translateGeneral("memos.deleted").replace("%SUBJECT%", this.item.subject),
      SeverityLevel.success
    );
  }

  get isDirty() {
    return this.mode === "create" || isDirty(this.item);
  }

  static Factory({ container }: interfaces.Context) {
    return (mode: "create" | "edit" | "show", id: number | undefined, users: User[]) =>
      new MemoDetailViewModel(
        mode,
        id,
        users,
        container.get(MemosRepository),
        container.get(EnumService),
        container.get(LocalizationService),
        container.get(NotificationService),
        container.get(UserContext)
      );
  }
}
