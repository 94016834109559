import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ConductorOneChildActive, Router, watchBusy } from "@frui.ts/screens";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import CreatePsDto from "entities/createPsDto";
import { RecurringPaymentTypes } from "entities/recurringPaymentType";
import { interfaces } from "inversify";
import ParcelShopStatus from "manualEntities/parcelShopStatus";
import { action, reaction, toJS } from "mobx";
import ShopConfiguration from "models/shops/newShop/shopConfiguration";
import ShopInformation from "models/shops/newShop/shopInformation";
import type SubjectInfo from "models/shops/subjectInfo";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import { scrollTop } from "utils/helpers";
import { PARCEL_BOX_SUBJECT_CODE } from "viewModels/pickupPoint/newPickupPointViewModel";
import CheckSubjectViewModel from "../../pickupPoint/checkSubjectViewModel";
import NewAddressesViewModel from "./newAddressesViewModel";
import NewContactsViewModel from "./newContactsViewModel";
import NewOpeningHoursViewModel from "./newOpeningHoursViewModel";
import NewShopConfigurationViewModel from "./newShopConfigurationViewModel";
import NewShopInformationViewModel from "./newShopInformationViewModel";
import { IStepViewModel } from "./types";
import CreatePotentialPsDto from "entities/createPotentialPsDto";
import isPotential from "utils/isPotential";
import PotentialRepository from "data/repositories/potentialRepository";
import AccessPointPartners from "models/enumerations/accessPointPartners";
import Contact from "entities/contact";
import Address from "entities/address";
import OpeningHourDto from "../../../entities/openingHourDto";
import PotentialDetailViewModel from "../detail/potentialDetailViewModel";
import BoxDetailViewModel from "../detail/boxDetailViewModel";
import ShopDetailViewModel from "../detail/shopDetailViewModel";
import NewAdditionalInformationViewModel from "./newAdditionalInformationViewModel";
import AdditionalInformation from "../../../models/shops/newShop/additionalInformation";
import NetworkStatusType from "manualEntities/networkStatusType";

@Router.registerRoute({ name: "newParcelShop", route: ":ico" })
export default class NewParcelShopViewModel extends ConductorOneChildActive<IStepViewModel> {
  private unsubscriptions: Array<() => void> = [];
  breadcrumbStop = true;
  creatingFromPotential = false;

  busyWatcher = new BusyWatcher();

  private informationVM: NewShopInformationViewModel;
  private contactVM: NewContactsViewModel;
  private addressVM: NewAddressesViewModel;
  private configurationVM: NewShopConfigurationViewModel;
  private openingHoursVM: NewOpeningHoursViewModel;
  private additionalInformationVM: NewAdditionalInformationViewModel;

  constructor(
    private subject: SubjectInfo,
    public localizationService: LocalizationService,
    private informationVMFactory: ReturnType<typeof NewShopInformationViewModel.Factory>,
    private contactVMFactory: ReturnType<typeof NewContactsViewModel.Factory>,
    private addressVMFactory: ReturnType<typeof NewAddressesViewModel.Factory>,
    private configurationVMFactory: ReturnType<typeof NewShopConfigurationViewModel.Factory>,
    private openingHoursVMFactory: ReturnType<typeof NewOpeningHoursViewModel.Factory>,
    private additionalInformationVMFactory: ReturnType<typeof NewAdditionalInformationViewModel.Factory>,
    private parcelShopRepository: ParcelShopRepository,
    private potentialParcelShopRepository: PotentialRepository,
    private notificationService: NotificationService,
    private enums: EnumService,
    private router: Router
  ) {
    super();
    this.childNavigationPathClosed = true;
    this.navigationName = subject.ic;
    this.name = localizationService.translateGeneral("new_parcel_shop.title");

    const info = new ShopInformation();
    info.subject = subject;
    if (!this.isParcelBox) {
      info.name = subject.name;
    }
    info.depoId = subject.depoId;

    const configuration = new ShopConfiguration();
    const additionalInformation = new AdditionalInformation();

    this.createChildrenViewModels(info, configuration, additionalInformation);
  }

  private createChildrenViewModels(
    info: ShopInformation,
    configuration: ShopConfiguration,
    additionalInformation: AdditionalInformation,
    contacts?: Contact[],
    addresses?: Address[],
    openingHours?: OpeningHourDto[]
  ) {
    this.children.clear();

    this.addressVM = this.addressVMFactory(info, addresses);
    this.configurationVM = this.configurationVMFactory(configuration, this.isParcelBox, info);

    // TODO: We can't use `configuration` here because configuration VM create it's own copy
    this.contactVM = this.contactVMFactory(this.isParcelBox, this.configurationVM, contacts);
    this.informationVM = this.informationVMFactory(info, this.isParcelBox, this.configurationVM);
    this.configurationVM.setContactVM(this.contactVM);
    this.openingHoursVM = this.openingHoursVMFactory(this.isParcelBox, this.informationVM, this.configurationVM, openingHours);
    this.additionalInformationVM = this.additionalInformationVMFactory(
      additionalInformation,
      this.isParcelBox,
      this.isParcelBox,
      this.isPotential
    );

    this.children.push(
      this.informationVM,
      this.contactVM,
      this.addressVM,
      this.configurationVM,
      this.openingHoursVM,
      this.additionalInformationVM
    );
  }

  navigate(subPath: string | undefined, params: any): Promise<void> {
    if (params.potentialId) {
      this.loadDataFromPotential(params.potentialId);
    }
    return super.navigate(subPath, params);
  }

  @bound
  private onIsPotentialTypeChange() {
    const information = this.informationVM.item;
    const additionalInformation = this.additionalInformationVM.item ?? new AdditionalInformation();
    const configuration = toJS(this.configurationVM.item) ?? this.configurationVM.originalItem;
    const contacts = this.contactVM.isInitialized ? this.contactVM.items : undefined;
    const addresses = this.addressVM.isInitialized ? this.addressVM.items : undefined;
    const openingHours = this.openingHoursVM.isInitialized ? this.openingHoursVM.openingHours : undefined;
    this.createChildrenViewModels(information, configuration, additionalInformation, contacts, addresses, openingHours);
    this.tryActivateChild(this.informationVM);
  }

  @watchBusy
  private async loadDataFromPotential(potentialId: number) {
    this.creatingFromPotential = true;
    const [potential, contacts, addresses, openingHours] = await Promise.all([
      this.potentialParcelShopRepository.getDetail(potentialId),
      this.potentialParcelShopRepository.getContacts(potentialId),
      this.potentialParcelShopRepository.getAddresses(potentialId),
      this.potentialParcelShopRepository.getOpeningHours(potentialId),
    ]);

    const configuration = ShopConfiguration.createFromPotential(potential);
    const info = ShopInformation.createFromPotential(potential);
    const additionalInformation = AdditionalInformation.createFromPotential(potential);

    // Assigns type based on potentials type
    const enumItem = this.enums.value("subject_types", potential.typeId);
    let typeCode;
    if (enumItem?.code === AccessPointPartners.PARCEL_SHOP_POTENTIAL) {
      typeCode = AccessPointPartners.PARCEL_SHOP;
    } else {
      typeCode = AccessPointPartners.PARCEL_BOX;
    }
    info.typeId = this.enums.value("subject_types", typeCode, true)?.id ?? potential.typeId;

    this.createChildrenViewModels(info, configuration, additionalInformation, contacts, addresses, openingHours);
    this.tryActivateChild(this.children[0]);
  }

  get isParcelBox() {
    const item = this.enums.value("subject_types", PARCEL_BOX_SUBJECT_CODE, true);
    return this.subject.subjectTypeId === item?.id;
  }

  get isPotential() {
    const typeId = this.enums.value("access_point_partners", this.informationVM.item.typeId)?.code;
    return isPotential(typeId ?? "");
  }

  get currentChildIndex() {
    return this.activeChild ? this.children.indexOf(this.activeChild) : -1;
  }

  get isLastStep() {
    return this.children[this.children.length - 1] === this.activeChild;
  }

  onInitialize() {
    return this.tryActivateChild(this.children[0]);
  }

  protected onActivate(): Promise<void> {
    this.unsubscriptions.push(reaction(() => this.isPotential, this.onIsPotentialTypeChange));
    return super.onActivate();
  }

  protected onDeactivate(isClosing: boolean): Promise<void> {
    this.unsubscriptions.forEach(unsubscribe => unsubscribe());
    this.unsubscriptions = [];
    return super.onDeactivate(isClosing);
  }

  closeChild() {
    // children cannot be closed
    return false;
  }

  @bound continue() {
    if (this.activeChild && this.activeChild.validate()) {
      const childToActivate = this.children[this.currentChildIndex + 1];
      if (childToActivate) {
        this.tryActivateChild(childToActivate);
      }
    }
  }

  get canGoBack() {
    return this.activeChild !== this.children[0];
  }

  @bound goBack() {
    const childToActivate = this.children[this.currentChildIndex - 1];
    if (childToActivate) {
      this.tryActivateChild(childToActivate);
    }
  }

  @bound onCancelClick() {
    if (!this.creatingFromPotential) {
      this.requestClose();
    } else {
      this.requestClose();
      this.router.navigate(PotentialDetailViewModel, { id: `${this.informationVM.item.potentialId}-potential` });
    }
  }

  get canFinish() {
    return (
      !this.busyWatcher.isBusy &&
      this.activeChild === this.children[this.children.length - 1] &&
      this.children.every(x => x.canContinue)
    );
  }

  @action.bound validaAll() {
    return this.children.reduce((accumulator, children) => children.validate() && accumulator, true);
  }

  @action.bound async finish() {
    const allChildrenValid = this.validaAll();

    if (allChildrenValid) {
      this.busyWatcher.watch(this.doFinishInner());
    }
  }

  private async doFinishInner() {
    const model = this.getCreateModel();
    try {
      const attachmentIds = (await this.additionalInformationVM.saveAttachments()).map(attachment => ({
        id: attachment.id,
      }));
      model.attachments = attachmentIds;
    } catch (e) {
      console.error(e);
    }

    try {
      if (model instanceof CreatePsDto) {
        const response = await this.parcelShopRepository.createShop(model);
        if (model.potentialPsId) {
          this.router.navigate(this.isParcelBox ? BoxDetailViewModel : ShopDetailViewModel, { id: response.id });
        }
      } else {
        await this.potentialParcelShopRepository.createPotential(model);
      }
      this.notificationService.addNotification(
        this.localizationService.translateGeneral("new_parcel_shop.created_message"),
        SeverityLevel.success,
        CheckSubjectViewModel.notificationScope
      );

      await this.requestClose();
    } catch {
      // TODO: Add missing catch statement
    }

    scrollTop();
  }

  @bound
  protected nullifyPayments(item: ShopConfiguration) {
    if (item.network_partner == NetworkStatusType.Parent && this.isParcelBox) {
      item.electricityPrice = item.rentPrice = item.capacity = item.maxDailyCapacity = item.pbInvoicingCycle = 0;
      item.enforceSizes = false;
      item.acceptedSize = [];
    }
  }

  private getCreateModel(): CreatePsDto | CreatePotentialPsDto {
    const info = this.informationVM.item;
    const config = this.configurationVM.item;
    const additionalInformation = this.additionalInformationVM.item;

    this.nullifyPayments(config);

    const status = config.isActive ? ParcelShopStatus.Active : ParcelShopStatus.Unactive;
    const networkPartner = this.configurationVM.isNetworkPartner ? config.network_partner : 0;
    const parentId = networkPartner === 2 ? config.parent_id : undefined;

    let model;
    if (this.isPotential) {
      model = new CreatePotentialPsDto();
      model.phase = additionalInformation.potential_phase;
      model.phaseNote = additionalInformation.potential_phase_note;
      model.rentPrice = config.rentPrice;
      model.electricityPrice = config.electricityPrice;
      model.pbInvoicingCycle = config.pbInvoicingCycle;
    } else {
      model = new CreatePsDto();
      model.potentialPsId = info.potentialId!;
      model.dhlExpress = config.dhl_express;
      model.facility = config.facility;
      model.routeCode = config.route_code;
      model.serviceArea = config.service_area;
      model.servicePointId = config.service_point_id;
      model.networkPartner = config.network_partner;
      model.activeFinder = config.active_finder;
      model.cardPayment = config.card_payment;
      model.capacityWatcher = config.capacity_watcher;
      model.vacationWatcher = config.vacation_watcher;
      model.tid = config.tid;

      if (config.selectedCategory !== undefined) {
        model.categoryId = config.selectedCategory;
      }

      model.status = status;
      model.networkPartner = networkPartner;
      model.parentId = parentId;

      model.vacations = config.vacations;

      if (this.isParcelBox && config.network_partner != NetworkStatusType.Parent && config.rentPrice !== undefined) {
        model.recurringPayments = [
          { type: RecurringPaymentTypes.DEPOSIT, price: config.electricityPrice ?? 0, pbInvoicingCycle: config.pbInvoicingCycle },
          { type: RecurringPaymentTypes.RENT, price: config.rentPrice ?? 0, pbInvoicingCycle: config.pbInvoicingCycle },
        ];
      }

      model.contractSignDate = this.additionalInformationVM.item.contract_sign_date;
      model.businessState = this.additionalInformationVM.item.business_state;
      model.contractEndsAt = this.additionalInformationVM.item.contract_ends_at;
    }

    model.subjectId = this.subject.subjectId;

    model.name = info.name;
    model.subname = info.subname;
    model.typeId = info.typeId;
    model.salesmanId = info.salesmanId;
    model.depoId = info.depoId;

    model.longitude = config.longitude;
    model.latitude = config.latitude;

    model.capacity = config.capacity;
    model.maxDailyCapacity = config.maxDailyCapacity;
    model.enforceSizes = config.enforceSizes;
    model.acceptedSize = config.acceptedSize;

    model.description = config.description;
    model.descriptionCrm = config.descriptionCrm;

    model.addresses = this.addressVM.items;
    model.contacts = this.contactVM.items;
    model.alwaysOpen = this.openingHoursVM.alwaysOpen;
    model.openingHours = this.openingHoursVM.openingHours.length ? this.openingHoursVM.openingHours : [];

    model.competitions = this.additionalInformationVM.item.competitions;
    model.businessTypeId = this.additionalInformationVM.item.business_type_id;

    return model;
  }

  static Factory({ container }: interfaces.Context) {
    return (subject: SubjectInfo) =>
      new NewParcelShopViewModel(
        subject,
        container.get(LocalizationService),
        container.get(NewShopInformationViewModel.Factory),
        container.get(NewContactsViewModel.Factory),
        container.get(NewAddressesViewModel.Factory),
        container.get(NewShopConfigurationViewModel.Factory),
        container.get(NewOpeningHoursViewModel.Factory),
        container.get(NewAdditionalInformationViewModel.Factory),
        container.get(ParcelShopRepository),
        container.get(PotentialRepository),
        container.get(NotificationService),
        container.get(EnumService),
        container.get(Router)
      );
  }
}
