import ParcelShop from "manualEntities/parcelShop";
import React from "react";
import LocalizationService from "services/localizationService";
import { formatBankAccount } from "../../../../utils/helpers";

const ParcelShopSubheader: React.SFC<{ shop: ParcelShop; localization: LocalizationService }> = ({ shop, localization }) => {
  if (!shop) {
    return null;
  }

  const ta = (key: string) => localization.translateAttribute("parcel_shop", key);

  return (
    <dl className="list-inline mb-0">
      <dt>{ta("CustId")}</dt>
      <dd>{shop.cust_id}</dd>

      <dt>{ta("dhl_ps_id")}</dt>
      <dd>{shop.dhl_ps_id}</dd>

      <dt>{ta("dhl_ps_id_zip")}</dt>
      <dd>{shop.dhl_ps_id_zip}</dd>

      <dt>{ta("bank_account")}</dt>
      <dd>{formatBankAccount(shop.subject.bankAccountPrefix, shop.subject.bankAccountNumber, shop.subject.bankAccountCode)}</dd>
    </dl>
  );
};

export default ParcelShopSubheader;
