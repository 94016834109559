import { observable } from "mobx";
import PaymentTerminalImportStatusDto from "./paymentTerminalImportStatusDto";
import { Type } from "class-transformer";

export default class PaymentTerminalImportStatusResponseDto {
  /**
   * List of results, each line represents one terminal to import
   */
  @observable
  @Type(() => PaymentTerminalImportStatusDto)
  statuses!: PaymentTerminalImportStatusDto[];

  static ValidationRules = {
    statuses: { required: true },
  };
}
