import React from "react";
import { registerView, ViewComponent } from "@frui.ts/views";
import EnumerationScannerDetailViewModel from "../../viewModels/enumerations/enumerationScannerDetailViewModel";
import { observer } from "mobx-react-lite";
import { Button, Modal } from "react-bootstrap";
import { Input } from "@frui.ts/bootstrap";
import classnames from "classnames";
import { ReactComponent as IconEdit } from "assets/icons/icon-edit.svg";
import { ReactComponent as IconDelete } from "assets/icons/icon-delete.svg";
import LocalLoadingOverlay from "../../controls/localLoadingOverlay";
import { ReactComponent as IconPlus } from "assets/icons/icon-plus.svg";
import Scanner from "entities/scanner";
import ScannerDto from "entities/scannerDto";

const EnumerationScannerDetailView: ViewComponent<EnumerationScannerDetailViewModel> = observer(({ vm }) => {
  const itemRowRender = (item: Scanner | ScannerDto) => {
    let dailyAmortizationValue;
    let deviceType;
    let editButton;
    let deleteButton;

    if (item instanceof ScannerDto) {
      dailyAmortizationValue = <Input target={item} type="number" property="dailyAmortizationValue" />;
      deviceType = <Input target={item} property="deviceType" />;
      deleteButton = <IconDelete className="clickable" onClick={() => vm.onRemoveNewClick(item)} />;
    } else {
      const editedItem = vm.editingItems.get(item);

      if (editedItem) {
        dailyAmortizationValue = <Input target={editedItem} type="number" property="dailyAmortizationValue" />;
        deviceType = <Input target={editedItem} property="deviceType" />;
        deleteButton = <IconDelete className="clickable" onClick={() => vm.onCancelEditItemClick(item)} />;
      } else {
        dailyAmortizationValue = item.dailyAmortizationValue;
        deviceType = item.deviceType;
        editButton = <IconEdit className="clickable" onClick={() => vm.onEditItemClick(item)} />;
        deleteButton = <IconDelete className="clickable" onClick={() => vm.onDeleteItemClick(item)} />;
      }
    }

    return (
      <>
        <tr key={item.id}>
          <td
            className={classnames("w-50 align-middle text-wrap", {
              "p-1": deviceType !== item.deviceType,
            })}>
            {deviceType}
          </td>
          <td
            className={classnames("w-50 align-middle text-wrap", {
              "p-1": dailyAmortizationValue !== item.dailyAmortizationValue,
            })}>
            {dailyAmortizationValue}
          </td>
          <td style={{ width: 30 }}>{editButton}</td>
          <td style={{ width: 30 }}>{deleteButton}</td>
        </tr>
      </>
    );
  };

  return (
    <Modal show={true} centered={true}>
      <Modal.Header onHide={vm.onHide} closeButton={true}>
        <Modal.Title>{vm.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LocalLoadingOverlay loading={vm.busyWatcher.isBusy}>
          {vm.noItems && <div className="p-2">{vm.translate("no_items")}</div>}
          <table className="table table-borderless w-100">
            <tbody>
              {!vm.noItems && (
                <tr>
                  <th>{vm.translate("device_type")}</th>
                  <th>{vm.translate("daily_amortization_value")}</th>
                </tr>
              )}
              {vm.items.map(itemRowRender)}
              {vm.itemsToAdd.map(itemRowRender)}
            </tbody>
          </table>
          <div className="px-2">
            <Button onClick={vm.onItemAdd} block={true} variant="light" className="d-flex align-items-center">
              <IconPlus width={19} height={19} />
              {vm.translate("add")}
            </Button>
          </div>
        </LocalLoadingOverlay>
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button onClick={vm.onSubmit} disabled={vm.busyWatcher.isBusy || !vm.hasChanges}>
          {vm.translate("save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

registerView(EnumerationScannerDetailView, EnumerationScannerDetailViewModel);
