import { View, ViewComponent } from "@frui.ts/views";
import BoxDetailViewModel from "viewModels/shops/detail/boxDetailViewModel";
import { Observer, observer } from "mobx-react-lite";
import Table from "controls/table";
import React from "react";
import ShopDetailViewModel from "viewModels/shops/detail/shopDetailViewModel";
import PotentialDetailViewModel from "../../../viewModels/shops/detail/potentialDetailViewModel";
import HeaderRow from "../../../controls/headerRow";
import { Button, Dropdown } from "react-bootstrap";
import iconEdit from "../../../assets/icons/icon-edit.svg";
import iconExternalLink from "../../../assets/icons/icon-external-link.svg";
import { activityStatusIcon, formatDate } from "utils/helpers";
import Activity from "entities/activity";
import ActivityDetailDto from "entities/activityDetailDto";
import "../../dashboard/memoDetailView.scss";
import ListActivityDto from "entities/listActivityDto";

export const DAKTELA_URL = " https://ppl.daktela.com/tickets/update";

const Activities: ViewComponent<ShopDetailViewModel | BoxDetailViewModel | PotentialDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute } = vm.localizationService;
  const ta = (key: string) => translateAttribute("activity", key);

  return (
    <>
      <HeaderRow title={tg("parcel_shop.activities")} level={3}>
        <div className="d-flex justify-content-end align-items-center">
          <Button onClick={() => vm.openActivity(undefined)} disabled={!vm.canEdit}>
            {tg("activity.add")}
          </Button>
        </div>
      </HeaderRow>

      <div className="mineMemosTableWrapper">
        <Table
          data={(vm.activities || []).slice()}
          keyField="id"
          rowClasses={(row: Activity) => (row.afterDeadline ? "after-deadline" : "")}
          columns={[
            {
              dataField: "status",
              text: ta("state"),
              classes: "memoStatus",
              headerStyle: {
                width: "180px",
                textAlign: "left",
              },
              formatter: (_cell, row: ActivityDetailDto) => {
                return vm.isAllowedToChangeActivityState(row.assignees) ? (
                  <Dropdown id="status">
                    <Dropdown.Toggle variant="link">
                      <img src={activityStatusIcon(row.state)} className="pr-2" />
                      {vm.getActivityState(row.state)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {vm.activityStates
                        .filter(item => item.value !== row.state)
                        .map(item => (
                          <Dropdown.Item onSelect={() => vm.changeActivityState(row.id, item.value as number)} key={item.value}>
                            {item.label}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  vm.getActivityState(row.state)
                );
              },
            },
            {
              dataField: "type",
              isDummyField: true,
              text: ta("type"),
              formatter: (_cell, row: Activity) => vm.getActivityType(row.type),
            },
            {
              dataField: "assignees",
              text: ta("assignees"),
              formatter: (_cell, row: ListActivityDto) => {
                const assignees = row.assignees.map(assignee_id => vm.getUserName(assignee_id)).join(", ");
                return <span title={assignees}>{assignees}</span>;
              },
            },
            {
              dataField: "date",
              text: ta("date"),
              formatter: (_cell, row: Activity) => formatDate(row.date),
            },
            {
              dataField: "time",
              text: ta("time"),
            },
            {
              dataField: "category",
              text: ta("category_id"),
              formatter: (_cell, row: Activity) => vm.getActivityCategory(row.category),
            },
            {
              dataField: "note",
              isDummyField: true,
              text: ta("note"),
              headerStyle: {
                width: "100px",
                textAlign: "center",
              },
              align: "center",
              formatter: (_cell: any, row: ActivityDetailDto) => {
                return (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    className="noRowClick button-icon"
                    onClick={() => vm.openActivity(row)}>
                    <img src={iconEdit} alt="show" />
                  </Button>
                );
              },
            },
            {
              dataField: "datkelaId",
              isDummyField: true,
              text: ta("daktela_id"),
              headerStyle: {
                width: "100px",
                textAlign: "center",
              },
              align: "center",
              formatter: (_cell: any, row: Activity) => {
                if (row.daktelaId)
                  return (
                    <a
                      className="noRowClick button-icon btn btn-sm btn-outline-secondary"
                      key="daktelaId"
                      href={`${DAKTELA_URL}/${row.daktelaId}`}
                      target="_new">
                      <img src={iconExternalLink} alt="edit" />
                    </a>
                  );
              },
            },
          ]}
        />
      </div>
      <Button onClick={vm.toggleActivitiesHistory} variant="outline-secondary" className="align-center">
        {vm.showActivitiesHistory ? ta("hide_history") : ta("show_history")}
      </Button>
    </>
  );
});

export default Activities;
