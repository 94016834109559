import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import MessageGroupResponseDtoParents from "./messageGroupResponseDtoParents";
import RecipientResponseDto from "./recipientResponseDto";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class MessageGroupResponseDto {
  id!: number;

  @observable
  name!: string;

  @observable
  @Expose({ name: "user_id" })
  userId!: number;

  @observable
  @Type(() => MessageGroupResponseDtoParents)
  parents!: MessageGroupResponseDtoParents;

  @observable
  @Type(() => RecipientResponseDto)
  recipients!: RecipientResponseDto[];

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    name: { required: true },
    userId: { required: true, number: true },
    parents: { required: true },
    recipients: { required: true },
    createdAt: { required: true },
    updatedAt: { required: true },
  };
}
