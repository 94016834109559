import { IPagingFilter, PagedQueryResult } from "@frui.ts/data";
import MessageDetailResponseDto from "entities/messageDetailResponseDto";
import MessagePortalDto from "entities/messagePortalDto";
import MessageListResponseDto from "entities/messageListResponseDto";
import { Events } from "services/eventBus";
import { RepositoryBase } from "../repositoryBase";

export default class MessagesRepository extends RepositoryBase {
  // Messages

  getMessages(paging: IPagingFilter, filter: any): Promise<PagedQueryResult<MessageListResponseDto>> {
    return this.callApi(api => api.all("messages").getPagedEntities(MessageListResponseDto, paging, filter));
  }

  // Message

  getMessage(id: number): Promise<MessageDetailResponseDto> {
    return this.callApi(api => api.one("messages", id).getEntity(MessageDetailResponseDto));
  }

  createMessage(payload: MessagePortalDto) {
    return this.callApi(api => api.all("messages").postEntity(payload), Events.Messages.Changed);
  }

  unreadMessages(): Promise<number> {
    return this.callApi(api => api.all("messages/unread").get());
  }

  readMessage(id: number): Promise<any> {
    return this.callApi(api => api.one("messages", id).all("read").putOnly({}), Events.Messages.Changed);
  }

  resolveMessage(id: number) {
    return this.callApi(api => api.one("messages", id).all("resolve").patchOnly({}));
  }

  unresolveMessage(id: number) {
    return this.callApi(api => api.one("messages", id).all("unresolve").patchOnly({}));
  }
}
