import React, { FunctionComponent } from "react";
import { ConfigurationSectionProps } from "../types";
import { observer } from "mobx-react-lite";
import { Col, Form, Row } from "react-bootstrap";
import { Check, Select } from "@frui.ts/bootstrap";

const NetworkPartner: FunctionComponent<ConfigurationSectionProps> = observer(({ vm }) => {
  const { translateAttribute } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("parcel_shop", attribute);
  };

  return (
    <>
      <Form.Group>
        <Check custom target={vm} property="isNetworkPartner" label={ta("isNetworkPartner")} id="network_partner" />
      </Form.Group>

      <Row>
        <Col md={1} />
        <Form.Group as={Col} md={3}>
          <Form.Label>{ta("network_partner")}</Form.Label>
          <Select
            target={vm.item}
            property="network_partner"
            items={vm.networkPartners || []}
            mode="key"
            textProperty="name"
            isNumeric={true}
            disabled={!vm.isNetworkPartner}
          />
        </Form.Group>
        {vm.canChooseParent && (
          <Form.Group as={Col} md={3}>
            <Form.Label>{ta("parent_id").replace("%type%", vm.isParcelBox ? "PB" : "PS")}</Form.Label>
            <Select
              target={vm.item}
              property="parent_id"
              items={vm.possibleParentsByType || []}
              mode="key"
              textProperty="name"
              isNumeric={true}
              disabled={!vm.isNetworkPartner}
            />
          </Form.Group>
        )}
        {vm.isCategoryAvailable && (
          <Form.Group as={Col} md={3}>
            <Form.Label>{ta("category_id")}</Form.Label>
            <Select
              target={vm.item}
              property="selectedCategory"
              items={vm.categories || []}
              mode="key"
              textProperty="label"
              keyProperty="value"
              isNumeric={true}
              disabled={vm.isCategoryDisabled}
            />
          </Form.Group>
        )}
      </Row>
    </>
  );
});

export default NetworkPartner;
