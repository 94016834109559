import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ListActivityDto {
  /**
   * Id
   */
  id!: number;

  /**
   * Activity date
   */
  @observable
  @Type(() => Date)
  date!: Date;

  /**
   * Activity time
   */
  @observable
  time!: string;

  /**
   * Activity category
   */
  @observable
  category!: number;

  /**
   * Activity state
   */
  @observable
  state!: number;

  /**
   * Activity type
   */
  @observable
  type!: number;

  /**
   * Entity type
   */
  @observable
  @Expose({ name: "entity_type" })
  entityType!: number;

  /**
   * Comment
   */
  @observable
  comment!: string;

  /**
   * Assignee
   */
  @observable
  @Expose({ name: "author_id" })
  authorId!: number;

  /**
   * Author name
   */
  @observable
  @Expose({ name: "author_name" })
  authorName?: string;

  /**
   * Source. Value from enum ActivitySource
   */
  @observable
  source!: number;

  /**
   * Subject
   */
  @observable
  subject!: string;

  /**
   * Note
   */
  @observable
  note?: string;

  /**
   * Daktela identifier
   */
  @observable
  @Expose({ name: "daktela_id" })
  daktelaId?: number;

  /**
   * Assignees
   */
  @observable
  assignees!: number[];

  /**
   * Ps id
   */
  @observable
  @Expose({ name: "ps_id" })
  psId!: number;

  /**
   * After deadline
   */
  @observable
  @Expose({ name: "after_deadline" })
  afterDeadline?: boolean;

  static ValidationRules = {
    id: { required: true, number: true },
    date: { required: true },
    time: { required: true },
    category: { required: true, number: true },
    state: { required: true, number: true },
    type: { required: true, number: true },
    entityType: { required: true, number: true },
    comment: { required: true },
    authorId: { required: true, number: true },
    source: { required: true, number: true },
    subject: { required: true },
    daktelaId: { number: true },
    assignees: { required: true },
    psId: { required: true, number: true },
  };
}
