import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import iconCheckGray from "assets/icons/icon-check-gray.svg";
import iconCheckGreen from "assets/icons/icon-check-green.svg";
import HeaderRow from "controls/headerRow";
import LoadingOverlay from "controls/loadingOverlay";
import { StringInput } from "controls/stringInput";
import { TextInput } from "controls/textInput";
import TreeList from "controls/treeList";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonToolbar, Col, Form, FormLabel, Row } from "react-bootstrap";
import MessageDetailViewModel from "viewModels/messages/messageDetailViewModel";
import "./style.scss";

// workaround until Input control supports 'as' props
const CustomInput = StringInput as any;

// eslint-disable-next-line sonarjs/cognitive-complexity
const messageFormView: ViewComponent<MessageDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute: ta, translateModel: tm, formatDateTime } = vm.localizationService;

  // Form
  let body: any = "Detail";
  const formatAuthorName = (author_name: string | undefined) =>
    author_name ?? <span className="info">{tg("message.user_missing")}</span>;

  if (vm.isCreating && vm.item && vm.item.recipients) {
    body = (
      <Form onSubmit={preventDefault(vm.create)}>
        <Form.Row>
          <Col md={6}>
            <Form.Group>
              <FormLabel column={false}>{ta("message", "to")}</FormLabel>
              <TreeList
                options={toJS(vm.treeData)}
                value={toJS(vm.selectedItems)}
                multiple={true}
                loadOptions={vm.loadData}
                onChange={vm.onChange as any}
              />
            </Form.Group>
            <Form.Group>
              <FormLabel column={false}>{ta("message", "subject")}</FormLabel>
              <StringInput trim={true} target={vm.item} property="subject" disabled={!vm.isCreating} />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={12}>
            <Form.Group>
              <FormLabel column={false}>{ta("message", "text")}</FormLabel>
              <TextInput target={vm.item} property="text" disabled={!vm.isCreating} />
            </Form.Group>
            <ButtonToolbar>
              <Button type="submit" disabled={!vm.canSave} size="lg">
                {tg("message.submit")}
              </Button>
              <Button variant="outline-secondary" onClick={vm.requestClose} size="lg">
                {tg("cancel")}
              </Button>
            </ButtonToolbar>
          </Col>
        </Form.Row>
      </Form>
    );
  } else if (!vm.isCreating && vm.item) {
    body = (
      <div className="row">
        <div className="col-md-8">
          <div className="card message-body-card">
            <Row>
              <Col>
                <b>{formatAuthorName(vm.item.author?.name)}</b>
                &nbsp;&nbsp;
                <span className="info">{formatDateTime(new Date(vm.item.createdAt))}</span>
              </Col>
            </Row>
            <div className="message-text">{vm.item.text}</div>
          </div>
          <br />

          {vm.item.threadMessages.length > 1 && !vm.expandThread && (
            <>
              <Button onClick={vm.toggleThread} variant="outline-primary" className="full-width">
                {tg("message.show_old_messages").replace("%COUNT%", String(vm.item.threadMessages.length - 1))}
              </Button>
              <br />
              <br />
            </>
          )}

          {vm.threadMessages.map(message => (
            <>
              <div key={`message-${message.id}`} className="card message-body-card">
                <Row>
                  <Col>
                    <b>{formatAuthorName(message.author?.name)}</b>
                    &nbsp;&nbsp;
                    <span className="info">{formatDateTime(new Date(message.createdAt))}</span>
                  </Col>
                </Row>
                <div className="message-text">{message.text}</div>
              </div>

              <br />
            </>
          ))}

          {vm.canReply && (
            <>
              <h3>{tg("message.reply")}</h3>
              <CustomInput trim={true} as="textarea" target={vm.replyItem} property="text" rows="7" />
              <ButtonToolbar>
                <Button onClick={vm.sendReply} size="lg">
                  {tg("message.reply")}
                </Button>
                <Button variant="outline-secondary" onClick={vm.requestClose} size="lg">
                  {tg("cancel")}
                </Button>
              </ButtonToolbar>
            </>
          )}
        </div>
        <div className="col-md-4">
          <strong>{tg("details")}</strong>

          <br />
          <br />

          <table className="table-form">
            <tbody>
              {vm.item.parcelShops && (
                <tr>
                  <th>{ta("parcel_shop", "cust_id")}:</th>
                  <td>{vm.item.parcelShops.map(x => x.custId).join(", ")}</td>
                </tr>
              )}
              {vm.item.parcelShops && (
                <tr>
                  <th>{tm("parcel_shop", 1)}:</th>
                  <td>{vm.item.parcelShops.map(x => x.name).join(", ")}</td>
                </tr>
              )}
              {vm.item.author && (
                <tr>
                  <th>{ta("message", "author")}:</th>
                  <td>{formatAuthorName(vm.item.author?.name)}</td>
                </tr>
              )}
              <tr>
                <th>{ta("message", "status")}:</th>
                <td>{tg(`message.${vm.item.resolved ? "resolved" : "unresolved"}`)}</td>
              </tr>
            </tbody>
          </table>

          <br />

          {/* compare length with 0, otherwise 0 will be returned to UI and shown */}
          {(vm.item.type === "received" || vm.item.threadMessages.length > 0) && vm.item.resolved && (
            <Button variant="outline-success" onClick={vm.toggleMessageResolved}>
              <img src={iconCheckGreen} className="mr-2" alt="" />
              {tg("message.resolved")}
            </Button>
          )}
          {(vm.item.type === "received" || vm.item.threadMessages.length > 0) && !vm.item.resolved && (
            <Button variant="outline-secondary" onClick={vm.toggleMessageResolved}>
              <img src={iconCheckGray} className="mr-2" alt="" />
              {tg("message.mark_as_resolved")}
            </Button>
          )}
        </div>
      </div>
    );
  }

  // Detail

  return (
    <>
      <HeaderRow title={vm.isCreating ? tg("message.add") : vm.item?.subject} />
      {body}
      <LoadingOverlay loading={vm.busyWatcher.isBusy} />
    </>
  );
});

export default registerView(messageFormView, MessageDetailViewModel);
