import { ITypedEntityValidationRules } from "@frui.ts/validation";

export type EntityValidationRules<TTarget> = ITypedEntityValidationRules<TTarget, ValidationRules<TTarget>>;

export interface EntityCondition<TEntity> {
  condition: (entity: TEntity) => boolean;
}

export function isCondition(item: any): item is EntityCondition<any> {
  const typedItem = item as EntityCondition<any>;
  return !!typedItem.condition && typeof typedItem.condition === "function";
}

export interface ValidationRules<TEntity = any> {
  required: boolean | "onEdit" | "onCreate";
  requiredIf: EntityCondition<TEntity>;
  atLeastOneOf: EntityCondition<TEntity> & { key: keyof TEntity; modelName: string };
  lowerThan: { key: keyof TEntity; modelName: string };
  inFuture: EntityCondition<TEntity>;
  number: boolean;
  range: { min?: number; minExclusive?: number; max?: number; maxExclusive?: number };
  equalWith: string; // TODO
  minLength: number;
  maxLength: number;
  isIn: any[];
  isEmail: boolean;
  isPhone: boolean;
  isPostalCode: boolean;
  isTime: boolean | EntityCondition<TEntity>;
  isIMEI: boolean;
  isLatitude: boolean;
  isLongitude: boolean;
  isIc: boolean;
  isDic: boolean;
  manualErrors: any;
  isGreaterThan: number;
  isLimitedBonus: { messageCode: string; cb: () => number };
  isParcelBoxName: boolean;
  isTimeBefore: { key: string; dayPart?: number; dayIndex?: number };
  isTimeAfter: { key: string; dayPart?: number; dayIndex?: number };
  isFileType: { type?: string | string[] };
  isBankAccountPrefix: boolean;
  isBankAccountNumber: boolean;
}
