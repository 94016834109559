import { bound } from "@frui.ts/helpers";
import { watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import { ISelectItem } from "@frui.ts/views";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import { addMonths, getMonth, getYear } from "date-fns";
import Bonus, { BonusDisplayModel } from "entities/bonus";
import BonusDto from "entities/bonusDto";
import { interfaces } from "inversify";
import { range } from "lodash";
import { computed, IReactionDisposer, observable, reaction, runInAction } from "mobx";
import EnumService from "services/enum";
import EventBus from "services/eventBus";
import LocalizationService from "services/localizationService";
import EditableDetailViewModelBase from "viewModels/editableDetailViewModelBase";
import SettingsService from "services/settingsService";
import merge from "lodash/merge";
import BonusListDto from "entities/bonusListDto";

const BONUS_OTHER_TYPE_ID = 6;
const DEFAULT_LIMIT = 20000;
const BONUS_REMOVED = 5;
const EXTRA_ITEM_LIMIT = 30000;

const months: ISelectItem[] = range(1, 13).map(x => ({
  value: x,
  label: x.toString(),
}));

const years = range(getYear(new Date()), getYear(new Date()) + 10).map(x => ({
  value: x,
  label: x.toString(),
}));

export default class BonusDetailViewModel extends EditableDetailViewModelBase<Bonus, BonusDto> {
  protected reactionDisposers = [] as IReactionDisposer[];

  @observable selectedBonusLimit = DEFAULT_LIMIT;
  @observable allBonuses: BonusDisplayModel[] = [];

  constructor(
    private shopId: number,
    public isParcelBox: boolean,
    bonus: Bonus | undefined,
    private repository: ParcelShopRepository,
    public localization: LocalizationService,
    private enums: EnumService,
    private eventBus: EventBus,
    private settingsService: SettingsService
  ) {
    super(bonus);
    this.name = localization.translateGeneral(`parcel_${isParcelBox ? "box" : "shop"}.invoicing.${bonus ? "edit" : "add"}_bonus`);
    this.navigationName = bonus?.id.toString() ?? "new";
  }

  // All bonus types
  get allTypes() {
    return this.enums.values("bonus_types");
  }

  // Filtered bonus types for ParcelShop selection
  get types() {
    return this.enums.values("bonus_types").filter(item => item.id !== BONUS_OTHER_TYPE_ID);
  }

  onInitialize() {
    this.registerReactions();
    return super.onInitialize();
  }

  private registerReactions() {
    this.reactionDisposers.push(
      reaction(
        () => this.item && this.item.year,
        year => this.onYearChange(year)
      )
    );
  }

  protected onDeactivate(close: boolean) {
    if (close) {
      const reactionDisposers = this.reactionDisposers;
      this.reactionDisposers = [];
      reactionDisposers.forEach(disposer => disposer());
    }

    return super.onDeactivate(close);
  }

  @bound private getBonusDisplayModel(input: BonusListDto) {
    const result = new BonusDisplayModel();
    Object.assign(result, input);
    result.typeName = this.enums.value("bonus_types", input.type)?.name ?? "";
    result.statusName = this.enums.value("bonus_status", input.status)?.name ?? "";

    return result;
  }

  protected async loadBonuses() {
    const data = await this.repository
      .getBonuses(this.shopId, {
        offset: 0,
        limit: 9999,
      })
      .then(x => x[0]);

    runInAction(() => {
      this.allBonuses = data.map(x => this.getBonusDisplayModel(x)) ?? [];
    });
  }

  protected async loadDetail() {
    const source = this.originalItem ?? new Bonus();
    const editedItem = new BonusDto();
    // allowedDate - user can't set bonus to past date or current month
    const allowedDate = addMonths(new Date(), 1);
    editedItem.year = getYear(allowedDate);
    // getMonth return monthIndex but BonusDto stored human readable month value, for this reason needs +1
    editedItem.month = getMonth(allowedDate) + 1;

    if (this.isParcelBox) {
      editedItem.type = BONUS_OTHER_TYPE_ID;
    } else {
      await this.loadBonuses();
    }

    Object.assign(editedItem, source);

    attachAutomaticValidator(
      editedItem,
      merge({}, BonusDto.ValidationRules, {
        price: {
          isLimitedBonus: { messageCode: this.isParcelBox ? "box" : "shop", cb: () => this.totalBonusLimit, isGreaterThan: 0 },
        },
        name: { required: this.isParcelBox },
      }),
      !this.isCreating
    );

    return Promise.resolve(editedItem);
  }

  getBonusLimitByType(type: number) {
    const selectedBonusName = this.allTypes.find(x => x.id === type)?.name.toLowerCase();
    const settings = this.settingsService.settings;
    let totalBonusLimit;

    switch (selectedBonusName) {
      case "acquisition":
        totalBonusLimit = settings.bonusLimitForAcquisition;
        break;
      case "flow":
        totalBonusLimit = settings.bonusLimitForFlow;
        break;
      case "loyalty":
        totalBonusLimit = settings.bonusLimitForLoyalty;
        break;
      case "retention":
        totalBonusLimit = settings.bonusLimitForRetention;
        break;
      case "seasonal":
        totalBonusLimit = settings.bonusLimitForSeasonal;
        break;
      case "supply":
        totalBonusLimit = settings.bonusLimitForSupply;
        break;
      case "extra item":
        totalBonusLimit = EXTRA_ITEM_LIMIT;
        break;
      default:
        totalBonusLimit = DEFAULT_LIMIT;
        break;
    }

    return totalBonusLimit;
  }

  calculateNewBonusLimit(totalBonusLimit: number) {
    const { type } = this.item;
    let sumOfBonusesWithSelectedType = 0;
    if (type !== undefined) {
      const relevantBonusesPrices = this.allBonuses
        .filter(x => x.type === type && this.item.year === x.year && this.item.month === x.month && x.status !== BONUS_REMOVED)
        .map(x => x.price);
      if (relevantBonusesPrices.length > 0) {
        sumOfBonusesWithSelectedType = relevantBonusesPrices.reduce((a, b) => a + b);
      }
    }

    const result = totalBonusLimit - sumOfBonusesWithSelectedType;
    return result < 0 ? 0 : result;
  }

  @computed
  get totalBonusLimit() {
    if (this.item.type !== undefined) {
      const bonusLimit = this.getBonusLimitByType(this.item.type);
      if (this.item.type !== BONUS_OTHER_TYPE_ID && bonusLimit) {
        return this.calculateNewBonusLimit(bonusLimit);
      }

      return bonusLimit;
    }

    return DEFAULT_LIMIT;
  }

  get pricePlaceholder() {
    return this.totalBonusLimit ? `Max ${this.totalBonusLimit}` : "";
  }

  get canSave() {
    return !hasVisibleErrors(this.item);
  }

  @computed
  get months() {
    return months.filter(month => {
      const now = new Date();
      // months has range 1..12, but getMonth return month index. For correct comparison added +1.
      if (this.item.year === getYear(now) && month.value <= getMonth(now) + 1) {
        return false;
      }
      return true;
    });
  }

  get years() {
    return years;
  }

  onYearChange(year: number) {
    const now = new Date();
    const currentYear = getYear(now);
    // getMonth return month index, transform to human readable format.
    const currentMonth = getMonth(now) + 1;
    // Avoid to fill past date
    if (year === currentYear && this.item?.month <= currentMonth) {
      this.item.month = Number(this.months[0].value);
    }
  }

  @bound
  @watchBusy
  async save() {
    if (!validate(this.item)) {
      return;
    }

    if (this.isCreating) {
      await this.repository.addBonus(this.shopId, this.item);
    } else {
      this.originalItem && (await this.repository.updateBonus(this.shopId, this.originalItem.id, this.item));
    }

    await this.requestClose();
  }

  static Factory({ container }: interfaces.Context) {
    return (shopId: number, isParcelBox: boolean, bonus: Bonus | undefined) =>
      new BonusDetailViewModel(
        shopId,
        isParcelBox,
        bonus,
        container.get(ParcelShopRepository),
        container.get(LocalizationService),
        container.get(EnumService),
        container.get(EventBus),
        container.get(SettingsService)
      );
  }
}
