import { ReactComponent as IconUpload } from "assets/icons/icon-upload.svg";
import cn from "classnames";
import toArray from "lodash/toArray";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Dropzone, { DropzoneInputProps, DropzoneRootProps, FileRejection } from "react-dropzone";

const TYPES_MAP: Record<string, string> = {
  "application/pdf": "pdf",
  "application/msword": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docs",
  "application/vnd.oasis.opendocument.text": "odt",
  "image/jpeg": "jpeg",
  "image/png": "png",
  "application/vnd.ms-excel": "xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/vnd.oasis.opendocument.spreadsheet": "ods",
  "text/csv": "csv",
};

export interface UploaderLocalization {
  drag: string;
  select: string;
}

export interface UploaderProps {
  onDrop: (acceptedFiles: File[], fileRejections?: FileRejection[]) => void;
  label?: string;
  error?: string | null;
  accept?: string | string[];
  maxFiles?: number;
  minSize?: number;
  maxSize?: number;
}

const uploader = (props: UploaderProps) => {
  const className = cn(
    "border card border-semi-thick border-dashed p-4 oupt justify-content-center align-items-center d-flex text-center",
    {
      "is-invalid border-danger border": props.error,
    }
  );

  const localization: UploaderLocalization = {
    select: "Vyberte soubor",
    drag: "Nebo soubory přetáhněte sem",
  };

  return (
    <section>
      {props.label && (
        <Col>
          <Row>{props.label}</Row>
        </Col>
      )}
      <div className="mb-4 mt-3">
        <Dropzone
          onDrop={props.onDrop}
          accept={props.accept}
          maxSize={props.maxSize}
          minSize={props.minSize}
          maxFiles={props.maxFiles}>
          {({
            getRootProps,
            getInputProps,
          }: {
            getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
            getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
          }) => (
            <>
              <div {...getRootProps()} className={className} style={{ width: "100%", height: "100%" }}>
                <input {...getInputProps()} />
                <Col>
                  <Button variant="light" className="mb-4">
                    <IconUpload className="pr-2" width={25} height={25} />
                    {localization.select}
                  </Button>
                  <div className="mb-2">{localization.drag}</div>
                  {props.accept && (
                    <div className="text-secondary">
                      {toArray(props.accept)
                        .map(t => TYPES_MAP[t] ?? t)
                        .join(", ")}
                    </div>
                  )}
                </Col>
              </div>
              {props.error && <span className="invalid-feedback">{props.error}</span>}
            </>
          )}
        </Dropzone>
      </div>
    </section>
  );
};

export default uploader;
