import { registerView, ViewComponent, preventDefault } from "@frui.ts/views";
import { StringInput } from "controls/stringInput";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonToolbar, Col, Form } from "react-bootstrap";
import ParcelBoxDetailViewModel from "viewModels/parcelBox/parcelBoxDetailViewModel";

import "./style.scss";

const parcelBoxDetailEditView: ViewComponent<ParcelBoxDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute: ta } = vm.localizationService;

  if (!vm.isEditFormVisible) {
    return null;
  }

  return (
    <Form onSubmit={preventDefault(vm.save)}>
      <Form.Row>
        <Col md={3}>
          <Form.Group>
            <Form.Label>{ta("parcel_box", "icp")}</Form.Label>
            <StringInput trim={true} target={vm.updateItem} property="icp" type="text" />
          </Form.Group>
        </Col>
        <Col md={12}>
          <p className="icpDescription">{tg("parcel_box.icpDescription")}</p>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col md={3}>
          <Form.Group>
            <Form.Label>{ta("parcel_box", "terminal_tid")}</Form.Label>
            <StringInput trim={true} target={vm.updateItem} property="terminalTid" type="text" />
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Form.Label>{ta("parcel_box", "terminal_sn")}</Form.Label>
            <StringInput trim={true} target={vm.updateItem} property="terminalSn" type="text" />
          </Form.Group>
        </Col>
      </Form.Row>

      <ButtonToolbar>
        <Button type="submit">{tg("save")}</Button>
        <Button variant="outline-secondary" onClick={vm.closeEditForm}>
          {tg("cancel")}
        </Button>
      </ButtonToolbar>
    </Form>
  );
});

export default registerView(parcelBoxDetailEditView, ParcelBoxDetailViewModel, "edit");
