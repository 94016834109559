import { bound } from "@frui.ts/helpers";
import { watchBusy } from "@frui.ts/screens";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import { interfaces } from "inversify";
import { IReactionDisposer } from "mobx";
import ShopDetailContext from "models/shops/shopDetailContext";
import LocalizationService from "services/localizationService";
import EditableDetailViewModelBase from "viewModels/editableDetailViewModelBase";
import UpdatePsRewardsDto from "entities/updatePsRewardsDto";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import ConfirmationService from "services/confirmationService";
import { attachAutomaticDirtyWatcher, isDirty } from "@frui.ts/dirtycheck";
import ParcelShop from "../../../../manualEntities/parcelShop";

export default class BonusDetailViewModel extends EditableDetailViewModelBase<ShopDetailContext<ParcelShop>, UpdatePsRewardsDto> {
  protected reactionDisposers = [] as IReactionDisposer[];

  constructor(
    private shopContext: ShopDetailContext<ParcelShop>,
    private repository: ParcelShopRepository,
    public localization: LocalizationService,
    private confirmationService: ConfirmationService
  ) {
    super(shopContext);
    this.name = localization.translateGeneral("parcel_shop.rewards.edit.title");
    this.navigationName = "rewards/edit";
  }

  protected loadDetail() {
    const item = new UpdatePsRewardsDto();
    item.fmReward = this.shopContext.shop.fm_reward;
    item.lmReward = this.shopContext.shop.lm_reward;
    item.lmcodReward = this.shopContext.shop.lmcod_reward;

    attachAutomaticDirtyWatcher(item);
    attachAutomaticValidator(item, UpdatePsRewardsDto.ValidationRules);
    return item;
  }

  get isDirty() {
    return this.item && isDirty(this.item);
  }

  @bound
  @watchBusy
  async save() {
    if (validate(this.item)) {
      void this.requestClose();
      const result = await this.confirmationService.showConfirmation(
        this.localization.translateGeneral("parcel_shop.rewards.edit.confirm.info"),
        this.localization.translateGeneral("parcel_shop.rewards.edit.confirm.title"),
        this.localization.translateGeneral("parcel_shop.rewards.edit.confirm.save"),
        this.localization.translateGeneral("unsaved_changes_dialog.exit")
      );
      if (result) {
        void this.repository.updateRewards(this.shopContext.shop.id, this.item);
      }
    }
  }

  static Factory({ container }: interfaces.Context) {
    return (context: ShopDetailContext<ParcelShop>) =>
      new BonusDetailViewModel(
        context,
        container.get(ParcelShopRepository),
        container.get(LocalizationService),
        container.get(ConfirmationService)
      );
  }
}
