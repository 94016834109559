import { registerView, ViewComponent } from "@frui.ts/views";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React from "react";
import EditAdditionalInformationViewModel from "viewModels/shops/detail/edit/editAdditionalInformationViewModel";
import { IShopAdditionalInformationViewModel } from "viewModels/shops/detail/types";
import NewAdditionalInformationViewModel from "viewModels/shops/newShop/newAdditionalInformationViewModel";
import Attachments from "./attachments";
import BusinessState from "./businessState";
import BusinessType from "./businessType";
import Competitions from "./competitions";
import ContractSign from "./contractSign";
import PotentialPhase from "./potentialPhase";

const additionalInformationView: ViewComponent<IShopAdditionalInformationViewModel> = observer(({ vm }) => {
  return (
    <>
      {vm.item && (
        <>
          <Competitions vm={vm} />
          {vm.isContractSignVisible && <ContractSign vm={vm} />}
          {vm.isBusinessStateVisible && <BusinessState vm={vm} />}
          <Attachments vm={vm} />
          {vm.isBusinessTypeVisible && <BusinessType vm={vm} />}
          {vm.isPotential && <PotentialPhase vm={vm} />}
        </>
      )}
      <LoadingOverlay loading={!vm.item || vm.isBusy} />
    </>
  );
});

registerView(additionalInformationView, NewAdditionalInformationViewModel);
registerView(additionalInformationView, EditAdditionalInformationViewModel);
export default additionalInformationView;
