import { injectable } from "inversify";
import Cookies from "js-cookie";
import { action } from "mobx";

@injectable()
export default class LocaleProvider {
  static DEFAULT_LOCALE = "cs";
  static COOKIE_NAME = "locale";
  locale: string;

  constructor() {
    this.locale = Cookies.get(LocaleProvider.COOKIE_NAME) || LocaleProvider.DEFAULT_LOCALE;
    document.documentElement.lang = this.locale;
    Cookies.set(LocaleProvider.COOKIE_NAME, this.locale);
  }

  @action.bound
  changeLocale(code: string) {
    if (this.locale !== code) {
      this.locale = code;
      Cookies.set(LocaleProvider.COOKIE_NAME, code);
      document.documentElement.lang = code;
    }
  }
}
