import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class SimpleSubjectDto {
  id!: number;

  @observable
  @Expose({ name: "subject_id" })
  subjectId!: number;

  @observable
  ic!: string;

  @observable
  dic?: string;

  @observable
  @Expose({ name: "subject_type_id" })
  subjectTypeId!: number;

  @observable
  @Expose({ name: "bank_account_prefix" })
  bankAccountPrefix?: string;

  @observable
  @Expose({ name: "bank_account_number" })
  bankAccountNumber?: string;

  @observable
  @Expose({ name: "bank_account_code" })
  bankAccountCode?: string;

  static ValidationRules = {
    id: { required: true, number: true },
    subjectId: { required: true, number: true },
    ic: { required: true },
    subjectTypeId: { required: true, number: true },
  };
}
