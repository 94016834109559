import ListViewModelBase from "../listViewModelBase";
import EnumRepository from "data/repositories/enumRepository";
import LocalizationService from "services/localizationService";
import { bound } from "@frui.ts/helpers";
import EnumerationDetailViewModel from "./enumerationDetailViewModel";
import EventBus, { Events } from "services/eventBus";
import EditableEnumCountDto from "entities/editableEnumCountDto";
import EnumerationScannerDetailViewModel from "./enumerationScannerDetailViewModel";

export default class EnumerationsViewModel extends ListViewModelBase<
  EditableEnumCountDto,
  Record<string, never>,
  EnumerationDetailViewModel | EnumerationScannerDetailViewModel
> {
  static notificationScope = "enumerations";

  constructor(
    private repository: EnumRepository,
    private localizationService: LocalizationService,
    private eventBus: EventBus,
    private enumerationDetailViewModelFactory: ReturnType<typeof EnumerationDetailViewModel.Factory>,
    private enumerationScannerDetailViewModelFactory: ReturnType<typeof EnumerationScannerDetailViewModel.Factory>
  ) {
    super();
    this.navigationName = "enumerations";
    this.name = this.localizationService.translateGeneral("menu.enumerations");
  }

  protected async onInitialize() {
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.Enumerations.Changed, this.loadData));

    await this.resetFilterAndLoad();
  }

  protected async findNavigationChild(navigationName: string) {
    if (navigationName) {
      if (navigationName === "scanners") {
        return this.enumerationScannerDetailViewModelFactory(navigationName);
      }

      return this.enumerationDetailViewModelFactory(navigationName);
    }
  }

  @bound
  async loadData(): Promise<void> {
    return this.repository.listEditableEnums().then(items => {
      this.setData([items, { totalItems: items.length, limit: items.length, offset: 0 }]);
    });
  }

  @bound
  openDetail(item: EditableEnumCountDto): void | Promise<any> {
    if (item.code === "scanners") {
      this.tryActivateChild(this.enumerationScannerDetailViewModelFactory(item.code));
    } else {
      this.tryActivateChild(this.enumerationDetailViewModelFactory(item.code));
    }
  }

  translate(code: string) {
    return this.localizationService.translateGeneral(`enumerations.${code}`);
  }
}
