import ScanDevice, { ScanDeviceEvent } from "manualEntities/scanDevice";
import ScanDeviceDetailDto from "entities/scanDeviceDetailDto";
import EditableDetailViewModelBase from "viewModels/editableDetailViewModelBase";
import { attachAutomaticDirtyWatcher, resetDirty } from "@frui.ts/dirtycheck";
import { watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import { interfaces } from "inversify";
import { action, computed, IReactionDisposer, observable, reaction } from "mobx";
import EnumService from "services/enum";
import EventBus, { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import UserContext from "services/userContext";
import { formatDepoName, scrollTop } from "utils/helpers";
import ScanDevicesRepository from "data/repositories/scanDevicesRepository";
import ScanDevicesViewModel from "./scanDevicesViewModel";
import extractErrorMessage from "data/extractErrorMessage";
import ConfirmationService from "services/confirmationService";

export default class ScanDeviceDetailViewModel extends EditableDetailViewModelBase<ScanDevice, ScanDevice> {
  // Target cust id to assign scan device
  @observable custId?: number;

  constructor(
    originalItem: ScanDevice | undefined,
    public userContext: UserContext,
    private repository: ScanDevicesRepository,
    private psRepository: ParcelShopRepository,
    private eventBus: EventBus,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService,
    public localizationService: LocalizationService,
    protected enums: EnumService
  ) {
    super(originalItem);

    if (originalItem) {
      this.name = this.localizationService.translateGeneral("detail");
      this.navigationName = originalItem.id.toString();
    } else {
      this.name = this.localizationService.translateGeneral("add");
      this.navigationName = "new";
    }
  }

  protected async loadDetail() {
    let editedItem: ScanDevice;

    if (this.isCreating || !this.originalItem) {
      editedItem = new ScanDevice();
    } else {
      try {
        editedItem = await this.repository.getScanDevice(this.originalItem.id);
      } catch (e) {
        this.eventBus.publish(
          Events.General.ServerError,
          this.localizationService.translateGeneral("message_groups.errors.fetch_failed")
        );
        void this.requestClose();
        throw e;
      }
    }

    attachAutomaticDirtyWatcher(editedItem);
    attachAutomaticValidator(editedItem, ScanDeviceDetailDto.ValidationRules, !this.isCreating);
    return editedItem;
  }

  @computed get canSave() {
    return !hasVisibleErrors(this.item);
  }

  @action.bound
  @watchBusy
  async statusConfirmation(state: ScanDeviceEvent) {
    // Confirmation window
    const tg = this.localizationService.translateGeneral;

    const confirm = await this.confirmationService.showConfirmation(
      tg(`scan_device.events.${state}.body`).replace("%IMEI%", this.item.imei),
      tg(`scan_device.events.${state}.button`),
      { variant: state === ScanDeviceEvent.Discard ? "danger" : "primary", text: tg("confirm_dialog.confirm") },
      tg("confirm_dialog.cancel")
    );

    if (!confirm) {
      return;
    }

    try {
      await this.repository.changeStatus(this.item.id, state);
      this.setItem(await this.loadDetail());
      this.notificationService.addNotification(
        this.localizationService.translateGeneral("scan_device.change_status_success"),
        SeverityLevel.success,
        ScanDevicesViewModel.notificationScope
      );
    } catch (e) {
      return extractErrorMessage(e);
    }

    scrollTop();
  }

  @action.bound
  @watchBusy
  async assignPs(): Promise<string | undefined> {
    try {
      await this.repository.changeStatus(this.item.id, ScanDeviceEvent.AssignToPs, this.custId);
      this.setItem(await this.loadDetail());
      this.notificationService.addNotification(
        this.localizationService.translateGeneral("scan_device.change_status_success"),
        SeverityLevel.success,
        ScanDevicesViewModel.notificationScope
      );
      scrollTop();
      return undefined;
    } catch (e) {
      return extractErrorMessage(e);
    }
  }

  getDepoName(code: number) {
    const depo = this.enums.value("depos", code);
    return formatDepoName(depo);
  }

  get deviceTypeName(): string {
    return this.enums.value("scanners", this.item.device_type_id)?.device_type ?? "";
  }

  getStatus(id: number) {
    return this.enums.values("scan_device_status").find(item => item.id === id);
  }

  static Factory({ container }: interfaces.Context) {
    return (item?: any) => {
      return new ScanDeviceDetailViewModel(
        item,
        container.get(UserContext),
        container.get(ScanDevicesRepository),
        container.get(ParcelShopRepository),
        container.get(EventBus),
        container.get(ConfirmationService),
        container.get(NotificationService),
        container.get(LocalizationService),
        container.get(EnumService)
      );
    };
  }
}
