import { watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import extractErrorMessage from "data/extractErrorMessage";
import ParcelShopUsersRepository from "data/repositories/parcelShopUsersRepository";
import PsUserDto from "entities/psUserDto";
import ParcelShopUser from "entities/user";
import { interfaces } from "inversify";
import { action, observable, runInAction } from "mobx";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import EditableDetailViewModelBase from "viewModels/editableDetailViewModelBase";
import AccessControlViewModel from "./accessControlViewModel";

export default class ParcelShopUserDetailViewModel extends EditableDetailViewModelBase<number, PsUserDto> {
  @observable errorMessage?: string;

  constructor(
    private shopId: number,
    userId: number | undefined,
    private repository: ParcelShopUsersRepository,
    public localization: LocalizationService,
    private enums: EnumService,
    private notificationService: NotificationService
  ) {
    super(userId);
    this.name = localization.translateGeneral(userId ? "parcel_shop.edit_access.edit" : "parcel_shop.edit_access.add");
    this.navigationName = userId?.toString() ?? "new";
  }

  get roles() {
    return this.enums.values("mobile_users");
  }

  protected async loadDetail() {
    const editedItem = this.originalItem
      ? await this.repository.getUserDetail(this.shopId, this.originalItem)
      : new ParcelShopUser();

    attachAutomaticValidator(editedItem, PsUserDto.ValidationRules, !this.isCreating);
    return editedItem;
  }

  get canSave() {
    return !hasVisibleErrors(this.item);
  }

  @action.bound
  @watchBusy
  async save() {
    this.errorMessage = undefined;
    if (!validate(this.item)) {
      return;
    }

    try {
      if (this.isCreating || !this.originalItem) {
        await this.repository.createUser(this.shopId, this.item);
      } else {
        await this.repository.updateUser(this.shopId, this.originalItem, this.item);
      }

      this.notificationService.addNotification(
        this.localization
          .translateGeneral(`parcel_shop.edit_access.${this.isCreating ? "created" : "edited"}`)
          .replace("%email%", this.item.email),
        SeverityLevel.success,
        AccessControlViewModel.notificationScope
      );
    } catch (error) {
      runInAction(() => (this.errorMessage = extractErrorMessage(error)));
      return;
    }

    await this.requestClose();
  }

  static Factory({ container }: interfaces.Context) {
    return (shopId: number, userId?: number) => {
      return new ParcelShopUserDetailViewModel(
        shopId,
        userId,
        container.get(ParcelShopUsersRepository),
        container.get(LocalizationService),
        container.get(EnumService),
        container.get(NotificationService)
      );
    };
  }
}
