import downloadjs from "downloadjs";
import CreateAttachmentDto from "entities/createAttachmentDto";
import { Events } from "services/eventBus";
import { RepositoryBase } from "../repositoryBase";

export default class AttachmentsRepository extends RepositoryBase {
  downloadAttachment(id: number, fileName: string) {
    return this.callApi(api =>
      api
        .one("attachments", id)
        .getRaw()
        .then(response => response.blob())
        .then(blob => downloadjs(blob, fileName))
    );
  }

  uploadAttachment(payload: CreateAttachmentDto) {
    return this.callApi(api => api.all("attachments/file").postData(payload.getFormData()), Events.Attachments.Changed);
  }

  deleteAttachment(id: number) {
    return this.callApi(api => api.one("attachments", id).delete(), Events.Attachments.Changed);
  }
}
