import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class RecurringPaymentStoppage {
  id!: number;

  @observable
  @Expose({ name: "starts_at_month" })
  startsAtMonth!: number;

  @observable
  @Expose({ name: "starts_at_year" })
  startsAtYear!: number;

  @observable
  @Expose({ name: "ends_at_month" })
  endsAtMonth?: number;

  @observable
  @Expose({ name: "ends_at_year" })
  endsAtYear?: number;

  @Expose({ name: "modified_by" })
  readonly modifiedBy!: number;

  @observable
  infinite!: boolean;

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    startsAtMonth: { required: true, number: true },
    startsAtYear: { required: true, number: true },
    endsAtMonth: { number: true },
    endsAtYear: { number: true },
    modifiedBy: { readOnly: true, required: true, number: true },
    infinite: { required: true },
    createdAt: { required: true },
    updatedAt: { required: true },
  };
}
