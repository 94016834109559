import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class UpdateExportToK2Dto {
  @observable
  @Expose({ name: "export_to_k2" })
  exportToK2!: boolean;

  static ValidationRules = {
    exportToK2: { required: true },
  };
}
