// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
type PaymentTerminalImportStatusDtoResult = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12";

enum PaymentTerminalImportStatusResult {
  Imported = 0,
  ErrorPsDoesNotExist = 1,
  ErrorTidExists = 2,
  ErrorSnExists = 3,
  ErrorPsAlreadyHasTerminal = 4,
  ErrorEpsFail = 5,
  ErrorConflictingTID = 9,
  ErrorMissingTID = 10,
  ErrorCantUseParcelBoxCustId = 11,
}

export default PaymentTerminalImportStatusResult;
