import { Expose, Type } from "class-transformer";
import { merge } from "lodash";
import { observable } from "mobx";
import RecipientDto from "./recipientDto";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class MessagePortalDto {
  @observable
  subject!: string;

  @observable
  text!: string;

  @observable
  @Type(() => RecipientDto)
  recipients?: RecipientDto[];

  @observable
  @Expose({ name: "parent_id" })
  parentId?: number;

  static ValidationRules = {
    subject: { required: true },
    text: { required: true },
    parentId: { number: true },
  };
}

merge(MessagePortalDto.ValidationRules, {
  subject: {
    maxLength: 200,
  },
  text: {
    maxLength: 1000,
  },
});
