import { BusyWatcher, ScreenBase } from "@frui.ts/screens";
import { attachAutomaticValidator } from "@frui.ts/validation";
import Contact from "entities/contact";
import ContactDto from "entities/contactDto";
import { action, observable } from "mobx";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import { ContactModel, IShopContactsViewModel } from "../types";

export default abstract class ContactsViewModelBase extends ScreenBase implements IShopContactsViewModel {
  busyWatcher = new BusyWatcher();
  @observable items: ContactModel[];
  abstract get isPotential(): boolean;

  constructor(public isParcelBox: boolean, public localizationService: LocalizationService, protected enums: EnumService) {
    super();
  }

  get types() {
    return this.enums.values("contact_types");
  }

  get networkParentTypes() {
    return this.types.filter(x => x.network_parent);
  }

  @action.bound addNewContact() {
    this.addContact(new Contact());
  }

  abstract get isNetworkParent(): boolean;

  protected addContact(contact: Contact) {
    attachAutomaticValidator(contact, ContactDto.ValidationRules);
    this.items.push(contact);
  }

  abstract removeContact(contact: ContactModel): void;
}
