import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ListCompetitionDto {
  /**
   * id
   */
  id!: number;

  /**
   * ps_id
   */
  @observable
  @Expose({ name: "ps_id" })
  psId!: number;

  /**
   * entity_type
   */
  @observable
  @Expose({ name: "entity_type" })
  entityType!: number;

  /**
   * enum competition id
   */
  @observable
  @Expose({ name: "enum_competition_id" })
  enumCompetitionId!: number;

  /**
   * capacity
   */
  @observable
  capacity?: number;

  @observable
  @Expose({ name: "cod_rate" })
  codRate?: number;

  @observable
  @Expose({ name: "without_cod_rate" })
  withoutCodRate?: number;

  @observable
  @Expose({ name: "returned_rate" })
  returnedRate?: number;

  /**
   * capacity
   */
  @observable
  @Expose({ name: "monthly_rent" })
  monthlyRent?: number;

  /**
   * Comment
   */
  @observable
  comment?: string;

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    psId: { required: true, number: true },
    entityType: { required: true, number: true },
    enumCompetitionId: { required: true, number: true },
    capacity: { number: true },
    codRate: { number: true },
    withoutCodRate: { number: true },
    returnedRate: { number: true },
    monthlyRent: { number: true },
    createdAt: { required: true },
    updatedAt: { required: true },
  };
}
