import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import ConfirmDialogViewModel from "viewModels/confirmDialogViewModel";

const confirmDialogView: ViewComponent<ConfirmDialogViewModel> = observer(({ vm }) => {
  return (
    <Modal centered={true} show={vm.isOpened} onHide={vm.settings.closeButton && vm.cancel} onEscapeKeyDown={vm.cancel}>
      {vm.title && (
        <Modal.Header closeButton={vm.settings.closeButton}>
          <Modal.Title as="div">{vm.title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: vm.message }} />
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button onClick={vm.confirm} variant={vm.confirmButtonParams.variant}>
          {vm.confirmButtonParams.text}
        </Button>
        {!vm.settings.hideCancelButton && (
          <Button onClick={vm.cancel} variant={vm.cancelButtonParams.variant}>
            {vm.cancelButtonParams.text}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
});

export default registerView(confirmDialogView, ConfirmDialogViewModel);
