import { IPagingFilter } from "@frui.ts/data";
import ParcelBoxDto, { ParcelBoxFilter } from "entities/parcelBoxDto";
import ParcelBoxUpdateDto from "entities/parcelBoxUpdateDto";
import { Events } from "services/eventBus";
import { ParcelBoxEvent } from "viewModels/parcelBox/parcelBoxDetailViewModel";
import { RepositoryBase } from "../repositoryBase";

export default class ParcelBoxRepository extends RepositoryBase {
  getBoxes(paging: IPagingFilter, filter: ParcelBoxFilter) {
    return this.callApi(api => api.all("parcel_boxes").getPagedEntities(ParcelBoxDto, paging, filter));
  }

  getBox(id: number) {
    return this.callApi(api => api.one("parcel_boxes", id).getEntity(ParcelBoxDto));
  }

  updateBoxState(id: number, event: ParcelBoxEvent, custId?: number) {
    return this.callApi(
      api => api.one("parcel_boxes", id).all("change_status").postOnly({ event, cust_id: custId }),
      Events.ParcelBoxes.Changed
    );
  }

  updateBox(id: number, item: ParcelBoxUpdateDto) {
    const { icp, terminalTid: terminal_tid, terminalSn: terminal_sn } = item;
    return this.callApi(
      api => api.one("parcel_boxes", id).putEntity({ icp, terminal_tid, terminal_sn }, ParcelBoxDto),
      Events.ParcelBoxes.Changed
    );
  }
}
