import { IPagingFilter, SortingDirection } from "@frui.ts/data";
import { bound, nameof } from "@frui.ts/helpers";
import { Router, watchBusy } from "@frui.ts/screens";
import { ISelectItem } from "@frui.ts/views";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import OpportunityRepository from "data/repositories/opportunitiesRepository";
import { ParcelShopFilter } from "manualEntities/parcelShop";
import { EventBus } from "light-event-bus";
import { action, observable, runInAction, toJS } from "mobx";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import UserContext from "services/userContext";
import ListViewModelBase from "viewModels/listViewModelBase";
import OpportunityDetailViewModel from "./opportunityDetailViewModel";
import Opportunity, { OpportunityFilter, OpportunityStatus } from "entities/opportunity";
import ListOpportunityDto from "entities/listOpportunityDto";
import OpportunityDetailDto from "entities/opportunityDetailDto";
import { User } from "manualEntities/user";
import UsersRepository from "data/repositories/usersRepository";
import CreateOpportunityDto from "entities/createOpportunityDto";
import AccessPointPartners from "models/enumerations/accessPointPartners";

const PAGING_FILTER: IPagingFilter = {
  limit: 1000,
  offset: 0,
};

const inactiveFilters = [OpportunityStatus.Complete, OpportunityStatus.UnableToGet];
const potentialTypes: number[] = [+AccessPointPartners.PARCEL_BOX_POTENTIAL, +AccessPointPartners.PARCEL_SHOP_POTENTIAL];

const route = "opportunities";

@Router.registerRoute({ name: Router.Self, route, children: [OpportunityDetailViewModel] })
export default class OpportunitiesViewModel extends ListViewModelBase<
  OpportunityDetailDto,
  OpportunityFilter,
  OpportunityDetailViewModel
> {
  static notificationScope = "subjects";

  @observable users: User[];
  @observable.shallow allowedParcelShops?: ISelectItem[];

  constructor(
    private repository: OpportunityRepository,
    private parcelShopsRepository: ParcelShopRepository,
    private eventBus: EventBus,
    private opportunityDetailViewModelFactory: ReturnType<typeof OpportunityDetailViewModel.Factory>,
    public confirmationService: ConfirmationService,
    public localizationService: LocalizationService,
    private enums: EnumService,
    private userContext: UserContext,
    private usersRepository: UsersRepository
  ) {
    super();
    this.navigationName = "opportunities";
    this.name = localizationService.translateModel("opportunity", 3);
    this.pagingFilter.limit = 10; // just to show paging
    this.pagingFilter.sortColumn = nameof<Opportunity>("date");
    this.pagingFilter.sortDirection = SortingDirection.Descending;
  }

  onInitialize() {
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.Opportunities.Changed, this.loadData));
    void this.loadEnums();
    void this.loadUsers();
    if (!this.filter.enum_status_ids?.length) {
      void this.resetFilterAndLoad();
    }
  }

  protected onDeactivate(close: boolean) {
    if (this.activeChild) {
      void this.closeChild(this.activeChild);
    }

    return super.onDeactivate(close);
  }

  @bound
  private async loadEnums() {
    const filter = new ParcelShopFilter();
    filter.depo_ids = toJS(this.userContext.assignedDepos);

    const [parcelShops] = await this.parcelShopsRepository.getList({ offset: 0, limit: 1000, sortColumn: "name" }, filter);
    runInAction(() => {
      this.allowedParcelShops = parcelShops.map(
        x => ({ value: potentialTypes.includes(x.type_id) ? -x.id : x.id, label: x.name } as ISelectItem)
      );
    });
  }

  @action.bound
  setFilterStatus(status: OpportunityStatus) {
    void this.resetFilter();
    this.filter.enum_status_ids = [status];
    this.filter.show_complete_opportunites = inactiveFilters.includes(status);
    void this.applyFilterAndLoad();
  }

  @bound
  @watchBusy
  async loadUsers() {
    const roles = this.enums.values("crm_users").map(item => item.id);
    const response = await this.usersRepository.getUsers(PAGING_FILTER, { roles });
    runInAction(() => {
      this.users = response[0] ?? [];
    });
  }

  getStatus(id: number) {
    return this.enums.value("ps_opportunities_status_reasons", id);
  }

  get opportunityTypes(): ISelectItem[] {
    return this.enumsForFilter("opportunities");
  }

  get opportunityStatuses(): ISelectItem[] {
    const statuses = this.enumsForFilter("opportunity_states");
    return statuses.filter(
      status => this.filter.show_complete_opportunites || !inactiveFilters.includes(status.value as OpportunityStatus)
    );
  }

  get competitions(): ISelectItem[] {
    return this.enumsForFilter("competitions");
  }

  get opportunityStatusesReasons(): ISelectItem[] {
    return this.enumsForFilter("opportunity_status_reasons");
  }

  get usersForFilter(): ISelectItem[] {
    return this.users?.map(user => {
      return { value: user.id, label: this.formatUserName(user) };
    });
  }

  get isShowCompleteOpportunitiesDisabled() {
    let isDisabled = false;
    this.filter.enum_status_ids?.forEach(status => {
      isDisabled = isDisabled || inactiveFilters.includes(status);
    });
    return isDisabled;
  }

  @observable
  custId(item: OpportunityDetailDto): number | undefined {
    return item.entityType === 0 ? item.ps?.custId : undefined;
  }

  @observable
  psName(item: OpportunityDetailDto): string | undefined {
    if (item.psId) {
      return item.entityType === 0 ? item.ps?.name : this.allowedParcelShops?.find(x => x.value === -item.psId!)?.label;
    }
  }

  @observable
  enumsForFilter(name: string): ISelectItem[] {
    const duplicates = this.enums
      .values(`pb_${name}`)
      .map(item => ({ value: item.id, label: item.name }))
      .concat(this.enums.values(`ps_${name}`).map(item => ({ value: item.id, label: item.name })));

    return duplicates.filter((value, index, self) => self.map(x => x.value).indexOf(value.value) == index);
  }

  @bound
  getOpportunityType(id: number, isParcelBox: boolean) {
    return this.enumNameForTable("opportunities", id, isParcelBox);
  }

  @bound
  getOpportunityStatus(id: number, isParcelBox: boolean) {
    return this.enumNameForTable("opportunity_states", id, isParcelBox);
  }

  enumNameForTable(name: string, id: number, isParcelBox?: boolean) {
    if (isParcelBox) {
      return this.enums.value(`pb_${name}`, id)?.name;
    } else {
      return this.enums.value(`ps_${name}`, id)?.name;
    }
  }

  @bound
  getUserName(id: number) {
    const user = this.users?.find(user => id == user.id);
    if (!user) return "";
    return this.formatUserName(user);
  }

  formatUserName(user: User) {
    return user.first_name && user.last_name ? `${user.first_name}  ${user.last_name}` : user.email;
  }

  @action.bound
  @watchBusy
  async loadData() {
    const filter = toJS(this.appliedFilter, { recurseEverything: true });

    if (!filter.enum_status_ids?.length && !this.filter.show_complete_opportunites) {
      filter.enum_status_ids = this.opportunityStatuses.map(status => status.value as number);
    }

    return this.repository.getOpportunities(this.pagingFilter, filter).then(this.setData);
  }

  protected resetFilterValues(filter: OpportunityFilter) {
    filter.ps_ids = [];
    filter.enum_status_ids = [];
    filter.enum_opportunity_ids = [];
    filter.author_ids = [];
    filter.date_to = undefined;
    filter.date_from = undefined;
    filter.cust_id = undefined;
    filter.parcel_shop_name = undefined;
    filter.status_name = undefined;
    filter.type_name = undefined;
    filter.author_name = undefined;
  }

  @action.bound clearFilter(section: keyof OpportunityFilter) {
    switch (section) {
      case "ps_ids":
      case "enum_status_ids":
      case "enum_opportunity_ids":
      case "author_ids":
        this.filter[section] = [];
        break;

      default:
        this.filter[section] = undefined;
        break;
    }

    void this.applyFilterAndLoad();
  }

  @bound
  onItemAdd() {
    void this.openDetail();
  }

  @action.bound openDetail(item?: ListOpportunityDto | CreateOpportunityDto, isVMSelectionDisabled?: boolean) {
    return this.tryActivateChild(this.opportunityDetailViewModelFactory(item, isVMSelectionDisabled));
  }

  protected async findNavigationChild(navigationName: string) {
    if (+navigationName) {
      const opportunity = await this.repository.getOpportunity(+navigationName);
      return this.opportunityDetailViewModelFactory(opportunity);
    }
  }
}
