import { Expose, Type } from "class-transformer";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Bonus {
  /**
   * id
   */
  id!: number;

  /**
   * Parcel shop foreign key
   */
  @observable
  @Expose({ name: "ps_id" })
  psId!: number;

  /**
   * User (author) foreign key
   */
  @observable
  @Expose({ name: "user_id" })
  userId!: number;

  /**
   * Bonus type
   */
  @observable
  type!: number;

  /**
   * Bonus status
   */
  @observable
  status!: number;

  /**
   * Month of bonus
   */
  @observable
  month!: number;

  /**
   * Year of bonus
   */
  @observable
  year!: number;

  /**
   * Invoice foreign key
   */
  @observable
  @Expose({ name: "invoice_id" })
  invoiceId!: number;

  /**
   * Must be provided if BonusType.Other
   */
  @observable
  name!: string;

  /**
   * Bonus note
   */
  @observable
  note!: string;

  /**
   * Bonus rejection note (must be provided if bonus has been rejected)
   */
  @observable
  @Expose({ name: "rejection_note" })
  rejectionNote!: string;

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  /**
   * deleted_at
   */
  @Type(() => Date)
  @Expose({ name: "deleted_at" })
  readonly deletedAt!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    psId: { required: true, number: true },
    userId: { required: true, number: true },
    type: { required: true, number: true },
    status: { required: true, number: true },
    month: { required: true, number: true },
    year: { required: true, number: true },
    invoiceId: { required: true, number: true },
    name: { required: true },
    note: { required: true },
    rejectionNote: { required: true },
    createdAt: { required: true },
    updatedAt: { required: true },
    deletedAt: { readOnly: true, required: true },
  };
}

export class BonusDisplayModel extends Bonus {
  typeName: string;
  statusName: string;
  price: number;
}
