import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import UpdateExportToK2Dto from "entities/updateExportToK2Dto";
import { interfaces } from "inversify";
import NetworkStatusType from "manualEntities/networkStatusType";
import { observable, reaction, runInAction } from "mobx";

import ShopDetailContext from "models/shops/shopDetailContext";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import SecurityService from "services/securityService";
import ParcelShop from "../../../../manualEntities/parcelShop";
import AccessPointPartners from "models/enumerations/accessPointPartners";

export default class K2ExportViewModel extends ScreenBase {
  busyWatcher = new BusyWatcher();
  @observable item = new UpdateExportToK2Dto();
  @observable isBlocked = false;

  constructor(
    private shopContext: ShopDetailContext<ParcelShop>,
    private repository: ParcelShopRepository,
    public localization: LocalizationService,
    private notificationService: NotificationService,
    private security: SecurityService
  ) {
    super();
    this.name = localization.translateGeneral("parcel_shop.k2_export.title");
  }

  onInitialize() {
    runInAction(() => (this.item.exportToK2 = this.shopContext.shop.export_to_k2 || false));
    reaction(
      () => this.item.exportToK2,
      () => this.update()
    );
  }

  get canEdit() {
    return this.security.isAllowed("edit", "export_k2");
  }

  get isVisible() {
    return !!1;
  }

  get isDisabled() {
    return (
      this.shopContext.shop.network_partner === NetworkStatusType.Child ||
      this.busyWatcher.isBusy ||
      this.shopContext.shop.typeId === +AccessPointPartners.ALZA_BOX
    );
  }

  @bound
  @watchBusy
  async update() {
    try {
      const response = await this.repository.updateK2Export(this.shopContext.shop.id, this.item);
      if (response.status) {
        this.notificationService.addNotification(
          this.localization.translateGeneral("parcel_shop.invoicing.export_k2_ok"),
          SeverityLevel.success
        );
      } else {
        this.item.exportToK2 = this.shopContext.shop.export_to_k2;
        this.notificationService.addNotification(
          this.localization.translateGeneral("parcel_shop.invoicing.export_k2_bad"),
          SeverityLevel.critical
        );
      }
    } catch {
      // No internet
      this.item.exportToK2 = this.shopContext.shop.export_to_k2;
    }
  }

  static Factory({ container }: interfaces.Context) {
    return (context: ShopDetailContext<ParcelShop>) =>
      new K2ExportViewModel(
        context,
        container.get(ParcelShopRepository),
        container.get(LocalizationService),
        container.get(NotificationService),
        container.get(SecurityService)
      );
  }
}
