/* eslint-disable sonarjs/cognitive-complexity */
import { registerView, ViewComponent, preventDefault } from "@frui.ts/views";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Button, ButtonToolbar, Col, Modal, Form, Alert, Card, Nav } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import OpportunityDetailViewModel from "viewModels/opportunities/opportunityDetailViewModel";
import { Input, Select } from "@frui.ts/bootstrap";
import DateInput from "controls/dateInput";
import { TimeInput } from "controls/timeInput";
import { StringInput } from "controls/stringInput";
import iconCheck from "assets/icons/icon-check.svg";

import "./style.scss";
import OpportunityDetailDto from "entities/opportunityDetailDto";
import { OpportunityStatus } from "entities/opportunity";

const opportunityDetailView: ViewComponent<OpportunityDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute: ta, translateModel: tm } = vm.localizationService;

  if (!vm.item) {
    return (
      <>
        <LoadingOverlay loading={vm.busyWatcher.isBusy} />
      </>
    );
  }

  const FormatedButtons = (): JSX.Element => {
    const buttons: JSX.Element[] = [];
    let inFuture = false;

    vm.opportunityStatuses.forEach(status => {
      if (!inFuture && vm.item.enumStatusId == status.value) {
        inFuture = true;
        buttons.push(
          <Nav.Item key={status.value}>
            <Nav.Link active={true}>{status.label}</Nav.Link>
          </Nav.Item>
        );
      } else {
        buttons.push(
          <Nav.Item key={status.value}>
            <Nav.Link active={false} className={inFuture ? "" : "light-blue"}>
              {!inFuture && <img src={iconCheck} className="check-rectangle" alt="" />}
              {status.label}
            </Nav.Link>
          </Nav.Item>
        );
      }
    });

    return <>{buttons}</>;
  };

  return (
    <>
      <div className="header-row row">
        <div className="col-md">
          <h1>{vm.name}</h1>
        </div>
      </div>
      {!vm.isCreating && vm.parcelShop && (
        <>
          <span className="info">{tm("parcel_shop", 1)}:</span> {vm.parcelShop?.name}
          {vm.parcelShop.depoId && (
            <>
              <span className="dot" />
              <span className="info">{tm("depo", 1)}:</span> {vm.getDepoName(vm.parcelShop.depoId)}
            </>
          )}
          <br />
          <Nav variant="pills" fill className="wizard-steps opportunity-statuses">
            <FormatedButtons />
          </Nav>
        </>
      )}
      <Form onSubmit={preventDefault(vm.save)}>
        <fieldset disabled={vm.isDisabled}>
          {vm.errorMessage && <Alert variant="danger">{vm.errorMessage}</Alert>}
          <Card className="my-4" body>
            <Row>
              {vm.isCreating && (
                <Form.Group as={Col} md={8} className="required">
                  <Form.Label>{ta("opportunity", "ps_id")}</Form.Label>
                  <Select
                    target={vm.item}
                    property="psId"
                    items={vm.allowedShops || []}
                    mode="key"
                    textProperty="label"
                    keyProperty="value"
                    isNumeric={true}
                    disabled={vm.isVMSelectionDisabled}
                  />
                </Form.Group>
              )}
              <Form.Group as={Col} md={4} className="required">
                <Form.Label>{ta("opportunity", "enum_opportunity_id")}</Form.Label>
                <Select
                  target={vm.item}
                  property="enumOpportunityId"
                  items={vm.opportunityTypes || []}
                  mode="key"
                  textProperty="label"
                  keyProperty="value"
                  isNumeric={true}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md={4} className="required">
                <Form.Label>{ta("opportunity", "date")}</Form.Label>
                <DateInput target={vm.item} property="date" />
              </Form.Group>
              <Form.Group as={Col} md={2} className="required">
                <Form.Label>{ta("opportunity", "time")}</Form.Label>
                <TimeInput target={vm.item} property="time" />
              </Form.Group>
              <Form.Group as={Col} md={3} className="required">
                <Form.Label>{ta("opportunity", "enum_status_id")}</Form.Label>
                <Select
                  target={vm.item}
                  property="enumStatusId"
                  items={vm.opportunityStatuses || []}
                  mode="key"
                  textProperty="label"
                  keyProperty="value"
                  isNumeric={true}
                />
              </Form.Group>
              {vm.isReasonRequired && (
                <Form.Group as={Col} md={3} className="required">
                  <Form.Label>{ta("opportunity", "enum_status_reason_id")}</Form.Label>
                  <Select
                    target={vm.item}
                    property="enumStatusReasonId"
                    items={vm.opportunityStatusesReasons || []}
                    mode="key"
                    textProperty="label"
                    keyProperty="value"
                    isNumeric={true}
                  />
                </Form.Group>
              )}
            </Row>
            <Row>
              <Form.Group as={Col} md={3} className="required">
                <Form.Label>{ta("opportunity", "enum_competition_id")}</Form.Label>
                <Select
                  target={vm.item}
                  property="enumCompetitionId"
                  items={vm.competitions || []}
                  mode="key"
                  textProperty="label"
                  keyProperty="value"
                  isNumeric={true}
                />
              </Form.Group>
              <Form.Group as={Col} md={4}>
                <Form.Label>{ta("opportunity", "capacity")}</Form.Label>
                <Input type="number" target={vm.item} property="capacity" />
              </Form.Group>
              <Form.Group as={Col} md={4}>
                <Form.Label>{ta("opportunity", "note")}</Form.Label>
                <StringInput target={vm.item} property="note" />
              </Form.Group>
            </Row>
          </Card>
          <ButtonToolbar className="justify-content-between">
            <Button variant="outline-secondary" onClick={vm.requestClose} size="lg">
              {tg("cancel")}
            </Button>
            <Button type="submit" size="lg" disabled={!vm.canSave}>
              {vm.isCreating ? tg("create") : tg("save")}
            </Button>
          </ButtonToolbar>
        </fieldset>
      </Form>
    </>
  );
});

export default registerView(opportunityDetailView, OpportunityDetailViewModel);
