import { interfaces } from "inversify";
import LocalizationService from "services/localizationService";
import EditableDetailViewModelBase from "viewModels/editableDetailViewModelBase";
import getYear from "date-fns/getYear";
import { action, computed, observable, reaction, runInAction } from "mobx";
import RecurringPaymentStoppageDto from "entities/recurringPaymentStoppageDto";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import { getMonth } from "date-fns";

const getYears = () => {
  const actualYear = getYear(new Date());
  const years: number[] = [];
  for (let i = actualYear; i <= actualYear + 2; i++) {
    years.push(i);
  }

  return years;
};

export default class PaymentStoppageDetailViewModel extends EditableDetailViewModelBase<any, RecurringPaymentStoppageDto> {
  public months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public years = getYears();

  @observable type: 1 | 2 = 1;

  get localization() {
    return this.localizationService;
  }

  constructor(
    private shopId: number,
    original: any,
    public localizationService: LocalizationService,
    private repository: ParcelShopRepository
  ) {
    super(original);
    this.name = localizationService.translateGeneral("parcel_box.invoicing.payment_stoppage.set_period.title");
    this.navigationName = "stoppage";
  }

  @computed
  get startsMonths() {
    const now = new Date();

    if (this.item && this.item.startsAtYear === getYear(now)) {
      return this.months.filter(month => month >= getMonth(now) + 1);
    }

    return this.months;
  }

  @computed
  get endsMonths() {
    if (this.item.endsAtYear === undefined || this.item.startsAtYear === this.item.endsAtYear) {
      return this.months.filter(month => month > this.item.startsAtMonth);
    }

    return this.months;
  }

  @computed
  get endsYears() {
    if (this.item.startsAtYear !== undefined) {
      if (this.item.startsAtMonth <= 11) {
        return this.years.filter(year => year >= this.item.startsAtYear);
      } else {
        return this.years.filter(year => year > this.item.startsAtYear);
      }
    }

    return this.years;
  }

  @computed
  get canSave() {
    if (this.item) {
      const cond1 = this.type === 1 && this.item.startsAtMonth !== undefined && this.item.startsAtYear !== undefined;
      const cond2 =
        this.type === 2 &&
        this.item.startsAtMonth !== undefined &&
        this.item.startsAtYear !== undefined &&
        this.item.endsAtMonth !== undefined &&
        this.item.endsAtYear !== undefined;
      return cond1 || cond2;
    }

    return false;
  }

  async onInitialize() {
    await super.onInitialize();
    reaction(
      () => this.type,
      type => this.typeChanged(type)
    );

    reaction(
      () => this.item.startsAtMonth,
      month => this.startsMonthChanged(month)
    );

    reaction(
      () => this.item.startsAtYear,
      year => this.startsYearChanged(year)
    );
  }

  protected loadDetail() {
    const now = new Date();
    const item = new RecurringPaymentStoppageDto();
    const month = getMonth(now) + 1;
    item.startsAtMonth = month;
    item.startsAtYear = month <= 12 ? getYear(now) : getYear(now) + 1;
    return item;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  startsMonthChanged(month: number) {
    if (this.type === 2 && this.item.endsAtMonth !== undefined && this.item.endsAtYear !== undefined) {
      if (this.item.startsAtYear === this.item.endsAtYear) {
        if (month >= this.item.endsAtMonth) {
          runInAction(() => {
            if (month + 1 <= 12) {
              this.item.endsAtMonth = month + 1;
            } else {
              this.item.endsAtMonth = undefined;
              this.item.endsAtYear = undefined;
            }
          });
        }
      } else if (this.item.startsAtYear > this.item.endsAtYear) {
        runInAction(() => {
          if (month + 1 <= 12) {
            this.item.endsAtYear = this.item.startsAtYear;
            this.item.endsAtMonth = month + 1;
          } else {
            this.item.endsAtYear = undefined;
            this.item.endsAtMonth = undefined;
          }
        });
      }
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  startsYearChanged(year: number) {
    if (this.type === 2 && this.item.endsAtMonth !== undefined && this.item.endsAtYear !== undefined) {
      if (year === this.item.endsAtYear) {
        if (this.item.startsAtMonth >= this.item.endsAtMonth) {
          runInAction(() => {
            if (this.item.startsAtMonth + 1 <= 12) {
              this.item.endsAtMonth = this.item.startsAtMonth + 1;
            } else {
              this.item.endsAtMonth = undefined;
            }
          });
        }
      } else if (year > this.item.endsAtYear) {
        runInAction(() => {
          if (this.item.startsAtMonth + 1 <= 12) {
            this.item.endsAtYear = this.item.startsAtYear;
            this.item.endsAtMonth = this.item.startsAtMonth + 1;
          } else {
            this.item.endsAtYear = undefined;
            this.item.endsAtMonth = undefined;
          }
        });
      }
    }
  }

  @action.bound
  async save() {
    if (this.type === 1) {
      const resp = await this.repository.createPaymentStoppage(this.shopId, {
        ...this.item,
        infinite: false,
        endsAtMonth: this.item.startsAtMonth,
        endsAtYear: this.item.startsAtYear,
      });

      if (resp.status) {
        void this.requestClose();
      }
    } else {
      const resp = await this.repository.createPaymentStoppage(this.shopId, {
        ...this.item,
        infinite: false,
      });

      if (resp.status) {
        void this.requestClose();
      }
    }
  }

  typeChanged(type: 1 | 2) {
    if (type === 1) {
      runInAction(() => {
        this.item.endsAtMonth = undefined;
        this.item.endsAtYear = undefined;
      });
    }
  }

  static Factory({ container }: interfaces.Context) {
    return (shopId: number, original: any) =>
      new PaymentStoppageDetailViewModel(
        shopId,
        original,
        container.get(LocalizationService),
        container.get(ParcelShopRepository)
      );
  }
}
