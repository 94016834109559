import { validatorsRepository } from "@frui.ts/validation";
import { action, observable, runInAction } from "mobx";
import EnumService from "./enum";
import LocalizationService from "./localizationService";
import UserContext from "./userContext";
import ValidationService from "./validation/validationService";
import SettingsService from "./settingsService";

export default class InitializationService {
  @observable isPreLoginInitialized = false;
  @observable isPostLoginInitialized = false;

  constructor(
    private userContext: UserContext,
    private localization: LocalizationService,
    private enums: EnumService,
    private validation: ValidationService,
    private settingsService: SettingsService
  ) {}

  async preLoginInitialize() {
    await Promise.all([this.localization.fetchLocalizations()]);
    this.validation.initialize(validatorsRepository as any);

    runInAction(() => (this.isPreLoginInitialized = true));
  }

  async postLoginInitialize() {
    await Promise.all([this.enums.initialize(), this.settingsService.initialize()]);

    if (this.userContext.userRoleId) {
      const currentUserRole = this.enums.value("crm_users", this.userContext.userRoleId);
      if (currentUserRole) {
        this.userContext.setCurrentUserRole(currentUserRole.name, currentUserRole.admin);
      }
    }

    runInAction(() => (this.isPostLoginInitialized = true));
  }

  @action
  postLogoutCleanup() {
    this.isPostLoginInitialized = false;
  }
}
