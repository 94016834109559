import { observable } from "mobx";
import PaymentTerminalImportStatusDtoResult from "./paymentTerminalImportStatusDtoResult";

export default class PaymentTerminalImportStatusDto {
  /**
   * Status of the import
   */
  @observable
  result!: PaymentTerminalImportStatusDtoResult;

  /**
   * TID of terminal
   */
  @observable
  tid!: string;

  /**
   * SN of terminal
   */
  @observable
  sn!: string;

  /**
   * PS ID to be assigned to terminal
   */
  @observable
  psId!: number;

  /**
   * PS name to be assigned to terminal
   */
  @observable
  psName!: string;

  static ValidationRules = {
    result: { required: true },
    tid: { required: true },
    sn: { required: true },
    psId: { required: true, number: true },
    psName: { required: true },
  };
}
