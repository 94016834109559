import { BusyWatcher, ScreenBase } from "@frui.ts/screens";
import Address from "entities/address";
import Contact from "entities/contact";
import { OpeningHoursDayModel } from "models/shops/newShop/openingHoursModel";
import ShopConfiguration from "models/shops/newShop/shopConfiguration";
import ShopInformation from "models/shops/newShop/shopInformation";
import LocalizationService from "services/localizationService";
import ParcelShop from "manualEntities/parcelShop";
import Vacation from "entities/vacation";
import { ISelectItem } from "@frui.ts/views";
import AdditionalInformation from "models/shops/newShop/additionalInformation";
import { UploadItem } from "./edit/additionalInformationViewModelBase";
import Competition from "entities/competition";
import { FileRejection } from "react-dropzone";
import ShopDetailContext from "models/shops/shopDetailContext";
import PotentialPsDetailDto from "entities/potentialPsDetailDto";
import { models } from "models/shops/interface";

export const EditShopNotificationScope = "editShop";

export interface ISubDetailViewModel extends ScreenBase {
  busyWatcher: BusyWatcher;
  canSave: boolean;
  isDirty: boolean;
  save(): Promise<any>;
  discardChanges(): Promise<any> | void;
}

export interface IShopInformationViewModel {
  localizationService: LocalizationService;
  item: ShopInformation;
  accessPointPartners: models.Enum[];
  salesmans: ISelectItem[];
  depos: ISelectItem[];
  readonly isParcelBox: boolean;
  readonly isAlzaBox: boolean;
  readonly isPotential: boolean;
  readonly isEditing: boolean;
}

export interface IShopConfigurationViewModel {
  readonly localizationService: LocalizationService;
  readonly item: ShopConfiguration;
  readonly networkPartners: models.Enum[];
  readonly possibleParents: ParcelShop[];
  readonly canChooseParent: boolean;
  readonly isEditing: boolean;
  readonly cardWarningShow: boolean;
  readonly boxStationWarningShow: boolean;
  readonly isParcelBox: boolean;
  readonly isAlzaBox: boolean;
  readonly isNetworkParent: boolean;
  readonly isWarehouseHidden: boolean;
  readonly isCategoryAvailable: boolean;
  readonly isCategoryDisabled: boolean;
  readonly isRecurringPaymentsRequired: boolean;
  readonly isPotential: boolean;
  readonly categories: ISelectItem[];
  readonly allAcceptedSizes: string[];
  readonly pbInvoicingCycles: ISelectItem[];
  readonly possibleParentsByType: ParcelShop[];

  selectedParent?: ParcelShop;
  boxStation: boolean;
  isNetworkPartner: boolean;
  canChooseActive: boolean;
  canChooseVisibility: boolean;
  canChooseDhlExpress: boolean;
  canChooseDevice: boolean;
  canEditRecurringPayments: boolean;
  canChooseNetworkPartner: boolean;
  canChoosePaymentByCard: boolean;
  canChooseBoxStation: boolean;
  canEditPaymentTerminalsSection: boolean;
  canEditPaymentTerminal: boolean;
  canEditAccessPoint: boolean;
  canEditTerminalId: boolean;
  addVacation: () => void;
  removeVacation: (vacation: Vacation) => void;
  hideCardWarning: () => void;
  hideBoxStationWarning: () => void;
  checkAllSizes: () => void;
  checkPrecedingSizes: () => void;
  onCapacityWatcherChanged: (capacityWatcher: boolean) => void;
  getDeviceTypeName: (id: number) => string;
}

export type AddressModel = Address & { isMandatory?: boolean };

export interface IShopAddressesViewModel {
  localizationService: LocalizationService;
  countries: models.Enum[];
  items: Address[];
  customErrorMessage?: string;

  types(address: AddressModel): models.Enum[];
  addNewAddress(): void;
  removeAddress(address: Address): void;
}

export type ContactModel = Contact & { isMandatory?: boolean };

export interface IShopContactsViewModel {
  readonly isParcelBox: boolean;
  localizationService: LocalizationService;
  types: models.Enum[];
  networkParentTypes: models.Enum[];
  items: ContactModel[];
  isNetworkParent: boolean;
  isPotential: boolean;

  addNewContact(): void;
  removeContact(contact: Contact): void;
}

export interface IOpeningHoursViewModel {
  isAlwaysVisible: boolean;
  alwaysOpen: boolean;
  localizationService: LocalizationService;
  toggleAlwaysOpen: (value: boolean) => void;
  toggleAllAmActive: () => void;
  toggleAllPmActive: () => void;
  days: OpeningHoursDayModel[];
  isAllAmActive: boolean;
  isAmIndeterminate: boolean;
  isAllPmActive: boolean;
  isPmIndeterminate: boolean;
}

export interface IShopAdditionalInformationViewModel {
  isParcelBox: boolean;
  isAlzaBox: boolean;
  isPotential: boolean;
  item: AdditionalInformation;
  localizationService: LocalizationService;
  itemTypes: ISelectItem[];
  activityTypes: ISelectItem[];
  activityCategories: ISelectItem[];
  activityStates: ISelectItem[];
  possibleUsers: TreeItem[];
  terminations: ISelectItem[];
  businessStates: ISelectItem[];
  isTerminationsVisible: boolean;
  contractTypes: ISelectItem[];
  isContractTypesVisible: boolean;
  selectedContractType?: ISelectItem;
  isContractEndVisible: boolean;
  competitionIds: ISelectItem[];
  businessTypes: ISelectItem[];
  potentialPhases: ISelectItem[];
  isContractSignVisible: boolean;
  isBusinessStateVisible: boolean;
  isBusinessTypeVisible: boolean;
  requiredNoteForPhaseIds: number[];

  contractFiles: UploadItem[];
  otherFiles: UploadItem[];
  isBusy: boolean;

  addCompetition: () => void;
  removeCompetition: (activity: Competition) => void;
  addSignDate: () => void;
  removeSignDate: () => void;
  onAttach: (documentTypeId: number, acceptedFiles: File[], fileRejections?: FileRejection[]) => void;
  onRemoveFile: (file: UploadItem) => void;

  context: ShopDetailContext<PotentialPsDetailDto | ParcelShop> | undefined;
}
