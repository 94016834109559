import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import { merge } from "lodash";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CreateActivityDto {
  /**
   * Activity date
   */
  @observable
  @Type(() => Date)
  date!: Date;

  /**
   * Activity time
   */
  @observable
  time!: string;

  /**
   * Activity category
   */
  @observable
  category!: number;

  /**
   * Activity type
   */
  @observable
  type!: number;

  /**
   * Entity type
   */
  @observable
  @Expose({ name: "entity_type" })
  entityType!: number;

  /**
   * Comment
   */
  @observable
  comment?: string;

  /**
   * Assignees
   */
  @observable
  assignees!: number[];

  /**
   * Ps id
   */
  @observable
  @Expose({ name: "ps_id" })
  psId!: number;

  static ValidationRules = {
    date: { required: true },
    time: { required: true },
    category: { required: true, number: true },
    type: { required: true, number: true },
    entityType: { required: true, number: true },
    assignees: { required: true },
    psId: { required: true, number: true },
  };
}

merge(CreateActivityDto.ValidationRules, {
  time: { required: true, isTime: true },
});
