// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
type RecurringPaymentType = 0 | 1 | 2 | 3;
export default RecurringPaymentType;

export enum RecurringPaymentTypes {
  DEPOSIT = 0,
  RENT = 1,
  ELECTRICITY_STOPPED = 2,
  RENT_STOPPED = 3,
}
