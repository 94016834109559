import React from "react";
import { Col, Row } from "react-bootstrap";

export interface HeaderRowProps {
  title: string;
  children?: React.ReactNode;
  level?: number;
}

const HeaderRow: React.FunctionComponent<HeaderRowProps> = (props: HeaderRowProps) => {
  const { children, title } = props;
  const level = props.level || 1;
  const headingElement = React.createElement(`h${level}`, {}, title);

  return (
    <Row className="header-row">
      <Col md>{headingElement}</Col>
      {children && <Col md="auto">{children}</Col>}
    </Row>
  );
};

export default HeaderRow;
