import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class PotentialContactDto {
  @observable
  name!: string;

  @observable
  @Expose({ name: "contact_type_id" })
  contactTypeId!: number;

  @observable
  phone?: string;

  @observable
  mobile?: string;

  @observable
  email?: string;

  @observable
  description?: string;

  static ValidationRules = {
    name: { required: true },
    contactTypeId: { required: true, number: true },
  };
}
