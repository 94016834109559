import { IPagingFilter, SortingDirection } from "@frui.ts/data";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import { ISelectItem } from "@frui.ts/views";
import ActivitiesRepository from "data/repositories/activitiesRepository";
import ActivityDashboardUpdateDto from "entities/activityDashboardUpdateDto";
import ActivityDetailDto from "entities/activityDetailDto";
import { interfaces } from "inversify";
import { User } from "manualEntities/user";
import { observable, runInAction } from "mobx";
import EnumService from "services/enum";
import EventBus, { Events, EventSubscription } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import UserContext from "services/userContext";
import ActivityDetailViewModel from "viewModels/shops/detail/activity/activityDetailViewModel";
import BoxDetailViewModel from "viewModels/shops/detail/boxDetailViewModel";
import PotentialDetailViewModel from "viewModels/shops/detail/potentialDetailViewModel";
import ShopDetailViewModel from "viewModels/shops/detail/shopDetailViewModel";

const PAGING_FILTER: IPagingFilter = {
  limit: 1000,
  offset: 0,
};

export default class ActivitiesViewModel extends ScreenBase {
  busyWatcher = new BusyWatcher();
  @observable items: ActivityDetailDto[];
  protected eventSubscriptions = [] as EventSubscription[];

  constructor(
    private users: User[],
    protected activityVMFactory: ReturnType<typeof ActivityDetailViewModel.Factory>,
    private userContext: UserContext,
    private repository: ActivitiesRepository,
    private enums: EnumService,
    private eventBus: EventBus,
    public localizationService: LocalizationService,
    private notificationService: NotificationService,
    private router: Router
  ) {
    super();
    this.name = "activities";
    this.navigationName = "activities";
  }

  protected async onInitialize() {
    void this.loadData();
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.Activities.Changed, this.loadData));
    return super.onInitialize();
  }

  protected onDeactivate(close: boolean) {
    if (close) {
      const toUnsubscribe = this.eventSubscriptions;
      this.eventSubscriptions = [];
      toUnsubscribe.forEach(x => x.unsubscribe());
    }

    return super.onDeactivate(close);
  }

  @bound
  private async loadData() {
    const response = await this.repository.getActivities(PAGING_FILTER, { dashboard_filter: true });
    runInAction(() => {
      this.items = response[0] ?? [];
    });
  }

  getState(id: number) {
    const enumItem = this.enums.value("memo_status", id);
    if (enumItem) {
      return enumItem.name;
    }
    return " - ";
  }

  @bound
  getUserName(id: number) {
    const user = this.users?.find(user => id == user.id);
    if (!user) return "";
    return user.first_name && user.last_name ? `${user.first_name}  ${user.last_name}` : user.email;
  }

  @bound
  getActivityType(id: number) {
    return this.enums.value("activity_types", id)?.name;
  }

  @bound
  getActivityState(id: number) {
    return this.enums.value("activity_states", id)?.name;
  }

  @bound
  getActivityCategory(id: number) {
    return this.enums.value("activity_categories", id)?.name;
  }

  @bound
  openActivity(item: ActivityDetailDto | undefined) {
    const vm = this.activityVMFactory(this.users, undefined, undefined, item);
    void this.parent.tryActivateChild(vm);
  }

  get activityStates(): ISelectItem[] {
    return this.enums.values("activity_states").map(item => ({ value: item.id, label: item.name }));
  }

  get currentUser(): number {
    return this.userContext.userId ?? -1;
  }

  @bound
  isAllowedToChangeActivityState(assignees: number[]) {
    return assignees.includes(this.userContext.userId!) || this.userContext.isAdmin;
  }

  @bound
  openVM(activity: ActivityDetailDto) {
    if (activity.entityType === 0) {
      try {
        void this.router.navigate(ShopDetailViewModel, { id: activity.psId });
      } catch {
        void this.router.navigate(BoxDetailViewModel, { id: activity.psId });
      }
    } else {
      void this.router.navigate(PotentialDetailViewModel, { id: `${Math.abs(activity.psId)}-potential` });
    }
  }

  @bound
  @watchBusy
  async changeActivityState(id: number, newState: number) {
    const item: ActivityDashboardUpdateDto = { state: newState };
    await this.repository.updateActivityStatus(id, item);

    const msg = this.localizationService.translateGeneral("activities.updated");
    this.notificationService.addNotification(msg, SeverityLevel.success);
  }

  static Factory({ container }: interfaces.Context) {
    return (users: User[]) =>
      new ActivitiesViewModel(
        users,
        container.get(ActivityDetailViewModel.Factory),
        container.get(UserContext),
        container.get(ActivitiesRepository),
        container.get(EnumService),
        container.get(EventBus),
        container.get(LocalizationService),
        container.get(NotificationService),
        container.get(Router)
      );
  }
}
