import { IPagingFilter } from "@frui.ts/data";
import IListResponse from "data/listResponse";
import downloadjs from "downloadjs";
import Address from "entities/address";
import BonusDto from "entities/bonusDto";
import PsItemDto from "entities/psItemDto";
import BonusListDto from "entities/bonusListDto";
import Contact from "entities/contact";
import CreatePsDto from "entities/createPsDto";
import { InvoiceWithAttachment } from "entities/invoice";
import OpeningHourDto from "entities/openingHourDto";
import Parcel from "entities/parcel";
import RecurringPayment from "entities/recurringPayment";
import Vacation from "entities/vacation";
import NetworkStatusType from "manualEntities/networkStatusType";
import ParcelShop, { ParcelShopFilter } from "manualEntities/parcelShop";
import { Events } from "services/eventBus";
import { ParcelPaymentMethod } from "../../services/warehouseService";
import { RepositoryBase } from "../repositoryBase";
import UpdatePsRewardsDto from "entities/updatePsRewardsDto";
import RecurringPaymentType from "entities/recurringPaymentType";
import RecurringPaymentStoppageDto from "entities/recurringPaymentStoppageDto";
import UpdateExportToK2Dto from "entities/updateExportToK2Dto";
import StockTakingDto from "../../entities/stockTakingDto";
import IAccessPointRepository from "./IAccessPointRepository";
import ActivityDashboardUpdateDto from "entities/activityDashboardUpdateDto";

const unlimited = { offset: 0, limit: 999 };

export default class ParcelShopRepository extends RepositoryBase implements IAccessPointRepository<ParcelShop> {
  getList(paging: IPagingFilter, filter: ParcelShopFilter) {
    const params = RepositoryBase.createListRequestParams(filter, paging);
    return this.callApi(api => api.all("ps").get<IListResponse<ParcelShop>>(params).then(RepositoryBase.convertListResponse));
  }

  getParentShops() {
    const params = RepositoryBase.createListRequestParams(
      { network_partner: NetworkStatusType.Parent, isInactive: true },
      unlimited
    );
    return this.callApi(api =>
      api
        .all("ps")
        .get<IListResponse<ParcelShop>>(params)
        .then(x => x.items)
    );
  }

  getDetail(id: number) {
    return this.callApi(api => api.one("ps", id).getEntity<ParcelShop>(ParcelShop));
  }

  updateItem(id: number, shop: ParcelShop & { vacations?: unknown }) {
    const { vacations, ...payload } = RepositoryBase.OmitValidationAndIdProperty(shop);
    return this.callApi(
      api => api.one("ps", id).putEntity({ ...payload, category_id: payload.category_id ? payload.category_id : null }),
      Events.AccessPoints.Changed
    );
  }

  createShop(item: CreatePsDto) {
    return this.callApi(api => api.all("ps").postEntity(item, ParcelShop), Events.AccessPoints.Created);
  }

  disableItem(id: number) {
    return this.callApi(api => api.one("ps", id).all("discard").putEntity(undefined), Events.AccessPoints.Changed);
  }

  getContacts(shopId: number) {
    return this.callApi(api =>
      api
        .one("ps", shopId)
        .all("contacts")
        .getPagedEntities(Contact, unlimited)
        .then(x => x[0])
    );
  }

  addContact(shopId: number, contact: Contact) {
    return this.callApi(api => api.one("ps", shopId).all("contacts").postEntity(contact), Events.AccessPoints.Contacts.Changed);
  }
  updateContact(shopId: number, id: number, contact: Contact) {
    return this.callApi(
      api => api.one("ps", shopId).one("contacts", id).putEntity(contact),
      Events.AccessPoints.Contacts.Changed
    );
  }

  deleteContact(shopId: number, id: number) {
    return this.callApi(api => api.one("ps", shopId).one("contacts", id).delete(), Events.AccessPoints.Contacts.Changed);
  }

  getAddresses(shopId: number) {
    return this.callApi(api =>
      api
        .one("ps", shopId)
        .all("address")
        .getPagedEntities(Address, unlimited)
        .then(x => x[0])
    );
  }

  addAddress(shopId: number, address: Address) {
    return this.callApi(api => api.one("ps", shopId).all("address").postEntity(address), Events.AccessPoints.Addresses.Changed);
  }

  updateAddress(shopId: number, id: number, address: Address) {
    return this.callApi(
      api => api.one("ps", shopId).one("address", id).putEntity(address),
      Events.AccessPoints.Addresses.Changed
    );
  }

  deleteAddress(shopId: number, id: number) {
    return this.callApi(api => api.one("ps", shopId).one("address", id).delete(), Events.AccessPoints.Addresses.Changed);
  }

  getVacations(shopId: number) {
    return this.callApi(api =>
      api
        .one("ps", shopId)
        .all("vacations")
        .getPagedEntities(Vacation, unlimited)
        .then(x => x[0])
    );
  }

  updateVacations(shopId: number, vacations: Vacation[]) {
    return this.callApi(
      api => api.one("ps", shopId).all("vacations").putEntity({ vacations }),
      Events.ParcelShops.OpeningHours.Changed
    );
  }

  getOpeningHours(shopId: number) {
    return this.callApi(api =>
      api
        .one("ps", shopId)
        .all("opening_hour")
        .getPagedEntities(OpeningHourDto, unlimited)
        .then(x => x[0])
    );
  }

  updateOpeningHours(shopId: number, openingHours: OpeningHourDto[]) {
    return this.callApi(
      api =>
        api
          .one("ps", shopId)
          .all("opening_hour")
          .put<OpeningHourDto[]>({ opening_hours: openingHours.length > 0 ? openingHours : [] }),
      Events.ParcelShops.OpeningHours.Changed
    );
  }

  getInvoices(shopId: number, paging: IPagingFilter) {
    return this.callApi(api => api.one("ps", shopId).all("invoice").getPagedEntities(InvoiceWithAttachment, paging));
  }

  downloadInvoice(shopId: number, id: number, fileName: string) {
    return this.callApi(api =>
      api
        .one("ps", shopId)
        .one("invoice", id)
        .all("file")
        .getRaw()
        .then(response => response.blob())
        .then(blob => downloadjs(blob, fileName))
    );
  }

  getBonuses(shopId: number, paging: IPagingFilter) {
    return this.callApi(api => api.one("ps", shopId).all("bonus").getPagedEntities(BonusListDto, paging));
  }

  addBonus(shopId: number, bonus: BonusDto) {
    return this.callApi(api => api.one("ps", shopId).all("bonus").post(bonus), Events.ParcelShops.Bonuses.Changed);
  }

  updateBonus(shopId: number, id: number, bonus: BonusDto) {
    return this.callApi(api => api.one("ps", shopId).one("bonus", id).put(bonus), Events.ParcelShops.Bonuses.Changed);
  }

  deleteBonus(shopId: number, id: number) {
    return this.callApi(api => api.one("ps", shopId).one("bonus", id).delete(), Events.ParcelShops.Bonuses.Changed);
  }

  getCash(shopId: number) {
    return this.callApi(api =>
      api
        .one("ps", shopId)
        .all("parcels/cash")
        .getPagedEntities(Parcel, unlimited)
        .then(x => x[0])
    );
  }

  // tslint:disable-next-line:no-identical-functions
  getWarehouse(shopId: number) {
    return this.callApi(api =>
      api
        .one("ps", shopId)
        .all("parcels/warehouse")
        .getPagedEntities(Parcel, unlimited)
        .then(x => x[0])
    );
  }

  getRecurringPayments(id: number) {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    return this.callApi(api => api.one("recurring_payments/ps", id).getEntities(RecurringPayment));
  }

  updateRecurringPayment(id: number, type: RecurringPaymentType, price: number, pbInvoicingCycle: number) {
    return this.callApi(api =>
      api
        .one("recurring_payments/ps", id)
        .one("type", type)
        .putEntity({ price, pb_invoicing_cycle: pbInvoicingCycle }, RecurringPayment)
    );
  }

  deleteRecurringPayment(id: number) {
    return this.callApi(api => api.one("recurring_payments/ps", id).delete({ id }));
  }

  changeParcelStatus(
    shopId: number,
    id: number,
    status: number,
    paymentMethod: ParcelPaymentMethod = ParcelPaymentMethod.BY_CASH
  ) {
    return this.callApi(
      api => api.one("ps", shopId).one("parcels", id).all("change_status").put({ status, payment_method: paymentMethod }),
      Events.ParcelShops.Parcels.Changed
    );
  }

  updateRewards(shopId: number, rewards: UpdatePsRewardsDto) {
    return this.callApi(api => api.one("ps", shopId).all("rewards").putEntity(rewards, PsItemDto), Events.AccessPoints.Changed);
  }

  createPaymentStoppage(id: number, item: RecurringPaymentStoppageDto) {
    return this.callApi(
      api => api.one("recurring_payments/ps", id).one("payment_stoppage").postEntity(item),
      Events.AccessPoints.Changed
    );
  }

  deletePaymentStoppage(id: number, stoppageId: number) {
    return this.callApi(
      api => api.one("recurring_payments/ps", id).one("payment_stoppage", stoppageId).delete(),
      Events.AccessPoints.Changed
    );
  }

  updateK2Export(shopId: number, item: UpdateExportToK2Dto) {
    return this.callApi(api => api.one("ps", shopId).all("k2_export").putEntity(item), Events.AccessPoints.Changed);
  }

  stockTaking(shopId: number) {
    return this.callApi(api => api.one("ps", shopId).all("stocktaking").getEntity(StockTakingDto));
  }

  updateActivityStatus(id: number, payload: ActivityDashboardUpdateDto) {
    return this.callApi(api => api.one("activities/update_from_dashboard", id).putEntity(payload), Events.Activities.Changed);
  }
}
