import { Check } from "@frui.ts/bootstrap";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Form } from "react-bootstrap";
import K2ExportViewModel from "viewModels/shops/detail/invoicing/k2ExportViewModel";

const k2ExportView: ViewComponent<K2ExportViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localization;

  if (!vm.isVisible) {
    return <div />;
  }

  return (
    <>
      <br />
      <Form.Group controlId="check">
        <Check
          custom
          target={vm.item}
          property="exportToK2"
          label={tg("parcel_shop.invoicing.export_k2")}
          id="check"
          disabled={!vm.canEdit || vm.isDisabled}
        />
      </Form.Group>
    </>
  );
});

export default registerView(k2ExportView, K2ExportViewModel);
