import { registerView, View, ViewComponent } from "@frui.ts/views";
import HeaderRow from "controls/headerRow";
import LoadingOverlay from "controls/loadingOverlay";
import Table from "controls/table";
import BonusListDto from "entities/bonusListDto";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonToolbar, Row } from "react-bootstrap";
import { formatMonthYear } from "utils/helpers";
import BonusesViewModel from "viewModels/shops/detail/invoicing/bonusesViewModel";
import iconDelete from "assets/icons/icon-delete.svg";
import iconNote from "assets/icons/icon-note.svg";
import { Column } from "react-bootstrap-table-next";

const getColumns = (vm: BonusesViewModel): Column<string>[] => {
  const { translateAttribute, translateGeneral: tg, formatNumber, translateModel: tm } = vm.localization;
  const ta = (name: string) => translateAttribute("bonus", name);
  const taByType = (name: string) => translateAttribute(vm.isParcelBox ? "bonus_box" : "bonus", name);

  return [
    { dataField: "name", text: taByType("name"), title: true, hidden: !vm.isParcelBox },
    { dataField: "statusName", text: ta("status"), title: true },
    {
      hidden: vm.isParcelBox,
      dataField: "typeName",
      text: taByType("type"),
      title: true,
      headerStyle: {
        maxWidth: "100px",
      },
    },
    {
      dataField: "price",
      text: taByType("price"),
      align: "right",
      headerAlign: "right",
      formatter: (value: number) => (
        <>
          {formatNumber(value)} {tg("currency_code")}
        </>
      ),
      title: (value: number) => `${formatNumber(value)} ${tg("currency_code")}`,
    },
    {
      dataField: "month",
      text: taByType("month"),
      formatter: (cell: any, row: BonusListDto) => formatMonthYear(row.month, row.year),
      title: (cell: any, row: BonusListDto) => formatMonthYear(row.month, row.year),
    },
    {
      dataField: "user",
      text: ta("user_id"),
      formatter: (cell: any, row: BonusListDto) => row.user?.email,
      title: (cell: any, row: BonusListDto) => row.user?.email,
    },
    {
      hidden: vm.isParcelBox,
      dataField: "note",
      text: tm("note", 5),
      headerStyle: {
        width: "100px",
        textAlign: "center",
      },
      align: "center",
      formatter: (cell: any, row: BonusListDto) =>
        row.note || row.rejectionNote ? (
          <Button size="sm" className="button-icon" variant="outline-secondary" onClick={() => vm.openNoteDetail(row)}>
            <img src={iconNote} className="my-n3" alt={tg("user.note")} />
          </Button>
        ) : null,
    },
    {
      hidden: vm.isParcelBox,
      dataField: "delete",
      isDummyField: true,
      text: ta("delete"),
      headerStyle: {
        width: "100px",
        textAlign: "center",
      },
      align: "center",
      formatter: (cell: any, row: BonusListDto) => (
        <Button
          size="sm"
          variant="outline-danger"
          className={`noRowClick button-icon ${vm.canBeDeleted(row) ? "" : "invisible"}`}
          onClick={() => vm.deleteBonus(row.id)}>
          <img src={iconDelete} className="my-n3" alt={tg("user.delete")} />
        </Button>
      ),
    },
  ];
};

const bonusesView: ViewComponent<BonusesViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localization;
  const columns = getColumns(vm);

  return (
    <>
      <HeaderRow level={3} title={vm.name}>
        <ButtonToolbar>
          {vm.canAddBonus && (
            <Button onClick={vm.add}>{tg(`parcel_${vm.isParcelBox ? "box" : "shop"}.invoicing.add_bonus`)}</Button>
          )}
        </ButtonToolbar>
      </HeaderRow>

      <Table data={(vm.items || []).slice()} keyField="id" columns={columns} />

      {vm.canLoadMore && (
        <Button variant="outline-secondary" className="align-center" onClick={vm.loadMore}>
          {tg("load_older_records")}
        </Button>
      )}

      <View vm={vm.activeChild} />
      <LoadingOverlay loading={vm.busyWatcher.isBusy} />
    </>
  );
});

export default registerView(bonusesView, BonusesViewModel);
