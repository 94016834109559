import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class OpeningHourDto {
  /**
   * Week day - 1 Sunday, 2 Monday, ...
   */
  @observable
  @Expose({ name: "week_day" })
  weekDay!: number;

  /**
   * 0 - dopoledne, 1 - odpoledne
   */
  @observable
  @Expose({ name: "day_part" })
  dayPart!: number;

  /**
   * HH:MM
   */
  @observable
  @Expose({ name: "open_from" })
  openFrom!: string;

  /**
   * HH:MM
   */
  @observable
  @Expose({ name: "open_to" })
  openTo!: string;

  static ValidationRules = {
    weekDay: { required: true, number: true },
    dayPart: { required: true, number: true },
    openFrom: { required: true },
    openTo: { required: true },
  };
}

export default class ExtendedOpeningHourDto extends OpeningHourDto {
  static fromModel(
    dayPart: number,
    model: {
      isActive: boolean;
      day: number;
      openFrom: string;
      openTo: string;
    }
  ) {
    const entity = new ExtendedOpeningHourDto();
    entity.weekDay = model.day;
    entity.dayPart = dayPart;
    entity.openFrom = model.openFrom;
    entity.openTo = model.openTo;

    return entity;
  }
}

export class OpeningHoursDisplayModel {
  dayName: string;
  morning: string;
  afternoon: string;
}
