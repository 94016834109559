import { bound } from "@frui.ts/helpers";
import { watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import SubjectRepository from "data/repositories/subjectRepository";
import CreateSubjectDto from "entities/createSubjectDto";
import { interfaces } from "inversify";
import { computed } from "mobx";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import SecurityService from "services/securityService";
import { bankSelectOption, depoSelectOption } from "utils/helpers";
import EditableDetailViewModelBase from "../editableDetailViewModelBase";
import NewParcelShopViewModel from "./newShop/newParcelShopViewModel";
import { validationRulesWithBankAccount } from "../../entities/subject";
import { ViewModelWithBankAccount } from "../types";

export default class NewSubjectViewModel
  extends EditableDetailViewModelBase<CreateSubjectDto, CreateSubjectDto>
  implements ViewModelWithBankAccount {
  get bankAccountCodes() {
    return this.enums.values("banks").map(bankSelectOption);
  }

  get depots() {
    return this.enums.values("depos").map(depoSelectOption);
  }

  @computed get canSave() {
    return !hasVisibleErrors(this.item);
  }

  constructor(
    item: CreateSubjectDto,
    private subjectRepository: SubjectRepository,
    public localizationService: LocalizationService,
    private security: SecurityService,
    private enums: EnumService,
    private newShopVMFactory: ReturnType<typeof NewParcelShopViewModel.Factory>
  ) {
    super(item);
    this.navigationName = item.ic || "newSubject";
    this.name = this.localizationService.translateGeneral("new_subject.title");
  }

  onInitialize() {
    return super.onInitialize();
  }

  protected loadDetail() {
    const editedItem = new CreateSubjectDto();
    Object.assign(editedItem, this.originalItem);

    const validationRules = {
      ...CreateSubjectDto.ValidationRules,
      ...validationRulesWithBankAccount(true),
    };
    attachAutomaticValidator(editedItem, validationRules, false);
    return Promise.resolve(editedItem);
  }

  @bound
  @watchBusy
  async create() {
    if (!validate(this.item)) {
      return;
    }

    // @TODO Error handling
    const newSubject = await this.subjectRepository.createSubject(this.item);
    this.setItem(newSubject);

    if (this.parent) {
      void this.parent.tryActivateChild(this.newShopVMFactory(newSubject));
    }
  }

  static Factory({ container }: interfaces.Context) {
    return (subject: CreateSubjectDto) =>
      new NewSubjectViewModel(
        subject,
        container.get(SubjectRepository),
        container.get(LocalizationService),
        container.get(SecurityService),
        container.get(EnumService),
        container.get(NewParcelShopViewModel.Factory)
      );
  }
}
