import { Expose, Type } from "class-transformer";
import { merge } from "lodash";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class VacationDto {
  /**
   * Original ID (when update record)
   */
  id?: number;

  /**
   * From
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "date_from" })
  dateFrom!: Date;

  /**
   * To
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "date_to" })
  dateTo!: Date;

  static ValidationRules = {
    id: { number: true },
    dateFrom: { required: true },
    dateTo: { required: true },
  };
}

merge(VacationDto.ValidationRules, {
  dateFrom: {
    /* eslint-disable-next-line @typescript-eslint/prefer-as-const */
    lowerThan: { key: "dateTo", modelName: "vacation" },
    // inFuture: { condition: (vacation: Vacation) => !!vacation.id },
  },
});
