import { Select } from "@frui.ts/bootstrap";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import DataTable from "controls/dataTable";
import EmptySearchResults from "controls/emptySearchResults";
import HeaderRow from "controls/headerRow";
import PagingInfo from "controls/pagingInfo";
import TreeList from "controls/treeList";
import ParcelShop from "manualEntities/parcelShop";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonToolbar, Card, Col, Form } from "react-bootstrap";
import ShopsViewModel from "viewModels/shops/shopsViewModel";
import preventDefault from "../preventDefault";
import { formatCategory } from "utils/helpers";
import { StringInput } from "controls/stringInput";

const ListView: ViewComponent<ShopsViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("parcel_shop", attribute);
  };

  return (
    <>
      <HeaderRow title={vm.name} />

      <Card body className="mb-4">
        <Form onSubmit={preventDefault(vm.applyFilterAndLoad)} onReset={vm.resetFilterAndLoad}>
          <Form.Row>
            <Form.Group as={Col} md={2}>
              <Form.Label>{ta("cust_id")}</Form.Label>
              <StringInput trim={true} target={vm.filter} property="cust_id" />
            </Form.Group>

            <Form.Group as={Col} md={2}>
              <Form.Label>{ta("dhl_ps_id_zip")}</Form.Label>
              <StringInput trim={true} target={vm.filter} property="dhl_ps_id_zip" />
            </Form.Group>

            <Form.Group as={Col} md={2}>
              <Form.Label>{ta("ic")}</Form.Label>
              <StringInput trim={true} target={vm.filter} property="ic" />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>{ta("name")}</Form.Label>
              <StringInput trim={true} target={vm.filter} property="name" />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={2}>
              <Form.Label>{ta("contact_phone")}</Form.Label>
              <StringInput trim={true} target={vm.filter} property="contact_phone" />
            </Form.Group>

            <Form.Group as={Col} md={2}>
              <Form.Label>{ta("contact_email")}</Form.Label>
              <StringInput trim={true} target={vm.filter} property="contact_email" />
            </Form.Group>

            <Form.Group as={Col} md={2}>
              <Form.Label>{ta("contact_name")}</Form.Label>
              <StringInput trim={true} target={vm.filter} property="contact_name" />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={3}>
              <Form.Label>{ta("salesman_id")}</Form.Label>
              <Select target={vm.filter} property="salesman_id" items={vm.salesmans} mode="key" keyProperty="value" allowEmpty />
            </Form.Group>

            <Form.Group as={Col} md={3}>
              <Form.Label>{ta("depo_id")}</Form.Label>
              <Select target={vm.filter} property="depo_id" items={vm.depos} mode="key" keyProperty="value" allowEmpty />
            </Form.Group>

            <Form.Group as={Col} md={3}>
              <Form.Label>{ta("type_id")}</Form.Label>
              <TreeList options={vm.accessesPoints} value={vm.selectedTypes} onChange={vm.onSelectType as any} multiple={true} />
            </Form.Group>

            <Form.Group as={Col} md={3}>
              <Form.Label>{ta("status")}</Form.Label>
              <TreeList
                options={vm.psFilterStatuses}
                value={vm.selectedStatuses}
                onChange={vm.onSelectStatus as any}
                multiple={true}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={3}>
              <Form.Label>{ta("category_id")}</Form.Label>
              <TreeList
                options={vm.psCategories}
                value={vm.selectedCategories}
                onChange={vm.onSelectCategory as any}
                multiple={true}
              />
            </Form.Group>
          </Form.Row>

          <ButtonToolbar>
            <Button type="submit" size="lg">
              {tg("search")}
            </Button>

            {vm.isFilterChanged && (
              <Button type="reset" variant="outline-danger">
                &#215; {tg("reset_filter")}
              </Button>
            )}
          </ButtonToolbar>
        </Form>
      </Card>

      <PagingInfo paging={vm.currentPaging} modelName="result" localization={vm.localizationService} />

      {vm.items?.length ? (
        <DataTable
          columns={[
            { dataField: "cust_id", text: ta("cust_id"), title: true },
            {
              dataField: "type_id",
              text: ta("type_id"),
              formatter: value => vm.typeName(value),
              title: value => vm.typeName(value),
            },
            { dataField: "dhl_ps_id_zip", text: ta("dhl_ps_id_zip"), title: true },
            {
              dataField: "subject",
              text: ta("ic"),
              formatter: (cell: ParcelShop["subject"]) => cell.ic,
              title: (cell: ParcelShop["subject"]) => cell.ic,
            },
            { dataField: "name", text: ta("name"), classes: "overflow-visible" }, // Don't change to title: true, @PPL wants full name of this
            {
              dataField: "salesman_id",
              text: ta("salesman_id"),
              formatter: value => vm.getSalesmanName(value),
              title: value => vm.getSalesmanName(value),
              classes: "overflow-visible", // Don't change to title: true, @PPL wants full name of this
            },
            {
              dataField: "depo_id",
              text: ta("depo_id"),
              headerStyle: {
                width: "220px",
              },
              formatter: value => vm.getDepoName(value),
              title: value => vm.getDepoName(value),
            },
            { dataField: "status", text: ta("status"), formatter: vm.getStatusName, title: vm.getStatusName },
            {
              dataField: "category_id",
              text: ta("category_id"),
              headerStyle: {
                width: "90px",
              },
              formatter: category => {
                if (category) {
                  const item = vm.enums.value("ps_categories", category);
                  return formatCategory(item);
                }
              },
              title: vm.getCategoryName,
            },
          ]}
          vm={vm}
        />
      ) : (
        <EmptySearchResults empty={tg("search_parcel_shop.no_results")} />
      )}
    </>
  );
});

const shopsView: ViewComponent<ShopsViewModel> = observer(({ vm }) => {
  return vm.activeChild ? <View vm={vm.activeChild} /> : <ListView vm={vm} />;
});

export default registerView(shopsView, ShopsViewModel);
