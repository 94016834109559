import { attachAutomaticDirtyWatcher, isDirty } from "@frui.ts/dirtycheck";
import { bound } from "@frui.ts/helpers";
import { watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import Contact from "entities/contact";
import RecurringPayment from "entities/recurringPayment";
import RecurringPaymentType, { RecurringPaymentTypes } from "entities/recurringPaymentType";
import VacationDto from "entities/vacationDto";
import { validateAll } from "helpers";
import { interfaces } from "inversify";
import NetworkStatusType from "manualEntities/networkStatusType";
import ParcelShop from "manualEntities/parcelShop";
import ParcelShopStatus from "manualEntities/parcelShopStatus";
import { action, computed, IReactionDisposer, observable, reaction, runInAction } from "mobx";
import ShopConfiguration, { configurationValidationRulesByAccessPointType } from "models/shops/newShop/shopConfiguration";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import EventBus, { Events, EventSubscription } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import SecurityService from "services/securityService";
import UserContext from "services/userContext";
import { getSortedPaymentsByType, scrollTop } from "utils/helpers";
import { EditShopNotificationScope, ISubDetailViewModel } from "../types";
import ConfigurationViewModelBase from "./configurationViewModelBase";
import IAccessPointRepository from "data/repositories/IAccessPointRepository";
import PotentialRepository from "data/repositories/potentialRepository";
import AccessPointPartners from "../../../../models/enumerations/accessPointPartners";
import PotentialPs from "../../../../entities/potentialPs";

// this ensures that ShopConfiguration model used for validation matches ParcelShop entity
export type Model = ParcelShop & ShopConfiguration;

export default class EditShopConfigurationViewModel extends ConfigurationViewModelBase<Model> implements ISubDetailViewModel {
  static defaultNavigationName = "configuration";
  private oldSettings?: Model;
  private reaction?: IReactionDisposer;
  private contactChangedSubscription?: EventSubscription;
  private contacts?: Contact[];

  private oldElectricityPrice = 0;
  private oldRentPrice = 0;
  private oldPBInvoicingCycle = 0;

  constructor(
    private shopId: number,
    public isParcelBox: boolean,
    public isPotential: boolean,
    repository: IAccessPointRepository<any>,
    localizationService: LocalizationService,
    protected enums: EnumService,
    security: SecurityService,
    private notificationService: NotificationService,
    protected confirmationService: ConfirmationService,
    private eventBus: EventBus,
    protected userContext: UserContext
  ) {
    super(shopId, undefined, repository, localizationService, enums, security, confirmationService, userContext);
    this.name = localizationService.translateGeneral("parcel_shop.configuration");
    this.navigationName = EditShopConfigurationViewModel.defaultNavigationName;
  }

  discardChanges() {
    const reactionDisposers = this.reactionDisposers;
    this.reactionDisposers = [];
    reactionDisposers.forEach(disposer => disposer());

    return this.onInitialize();
  }

  async onInitialize() {
    this.contactChangedSubscription = this.eventBus.subscribe(Events.AccessPoints.Contacts.Changed, this.loadDetail);
    return super.onInitialize();
  }

  @computed get isParcelShopType() {
    const typeId = this.item?.typeId;

    if (!typeId) {
      throw new Error(`Unexpected error we don't have information about parcel shop type!`);
    }

    if (this.isPotential) {
      return this.enums.value("access_point_partners", typeId)?.code === AccessPointPartners.PARCEL_SHOP_POTENTIAL;
    } else {
      return this.enums.value("access_point_partners", typeId)?.code === AccessPointPartners.PARCEL_SHOP;
    }
  }

  get contactTypeIds() {
    return this.contacts?.map(x => x.contactTypeId) ?? [];
  }

  @bound
  @watchBusy
  protected async loadDetail() {
    const [shop, contacts, recurringPayments] = await Promise.all([
      this.repository.getDetail(this.shopId),
      this.repository.getContacts(this.shopId),
      this.repository instanceof ParcelShopRepository ? this.repository.getRecurringPayments(this.shopId) : Promise.resolve([]),
    ]);

    /* This is for validation change network partner */
    this.contacts = contacts;
    this.originalNetworkStatus = shop.network_partner;

    const item = shop as Model;
    item.isActive = item.status === ParcelShopStatus.Active;
    item.subject = observable(item.subject);

    // Create history backup to check items
    // TODO: Remove this after DirtyWatcher has access to previous values
    this.oldSettings = { ...item } as Model;

    item.electricityPrice =
      shop instanceof PotentialPs
        ? shop.electricityPrice!
        : this.findLastPaymentByType(recurringPayments, RecurringPaymentTypes.DEPOSIT)?.price ?? 0;
    this.oldElectricityPrice = item.electricityPrice;

    item.rentPrice =
      shop instanceof PotentialPs
        ? shop.rentPrice!
        : this.findLastPaymentByType(recurringPayments, RecurringPaymentTypes.RENT)?.price ?? 0;
    this.oldRentPrice = item.rentPrice;

    item.pbInvoicingCycle =
      shop instanceof PotentialPs
        ? shop.pbInvoicingCycle!
        : this.findLastPaymentByType(recurringPayments, RecurringPaymentTypes.DEPOSIT)?.pbInvoicingCycle ?? 0;
    this.oldPBInvoicingCycle = item.pbInvoicingCycle;

    // Load vacations
    item.vacations = observable([]);

    if (this.repository instanceof ParcelShopRepository) {
      await this.repository.getVacations(this.shopId).then(items =>
        items.forEach(vacation => {
          attachAutomaticDirtyWatcher(vacation);
          attachAutomaticValidator(vacation, VacationDto.ValidationRules);
          item.vacations.push(vacation);
        })
      );
    }

    item.payment_terminals = observable(item.payment_terminals || []);
    item.parcelBox = item.parcelBox ? observable(item.parcelBox) : undefined;
    item.active_recurring_payment_stoppages = observable(item.active_recurring_payment_stoppages || []);

    runInAction(() => {
      this.isNetworkPartner = item.network_partner !== NetworkStatusType.None;
      this.vacationsIsDirty = false;
      this.boxStation = !!item.parcelBox;
    });

    // TODO: Tady je gusto problem, uz je vsechno nastaveno vcetne observable
    // jakmile na dalsi radce (kde je automaticValidator) tak tam uz je to dirty = true
    item.selectedCategory = item.category_id;
    attachAutomaticDirtyWatcher(item);
    attachAutomaticValidator(item, configurationValidationRulesByAccessPointType(this));

    // Reaction to check and prompt changes for active & inactive
    this.bindReaction(item);
    return item;
  }

  findLastPaymentByType(payments: RecurringPayment[], type: RecurringPaymentType) {
    const sorted = getSortedPaymentsByType(payments, [type]);
    return sorted ? sorted[0] : undefined;
  }

  bindReaction(item: Model) {
    this.reaction = reaction(
      () => [item.status, item.active_finder, item.isActive],
      ([_status, _activeFinder, isActive]) => this.informAutomaticChanges(isActive as boolean)
    );
  }

  @action.bound
  async informAutomaticChanges(isActive: boolean) {
    // Guard if
    if (!this.item || !this.oldSettings) {
      return;
    }

    // You could do whatever you want if vacation and capacity watcher
    // is disabled...
    if (
      (!this.isParcelBox && !this.item.capacity_watcher && !this.item.vacation_watcher) ||
      (this.isParcelBox && !this.item.capacity_watcher) ||
      (this.isParcelBox && this.item.capacity_watcher && isActive)
    ) {
      return;
    }

    const result = await this.confirmationService.showConfirmation(
      this.localizationService
        .translateGeneral("parcel_shop.watcher_dialog.text")
        .replace("%type%", this.isParcelBox ? "ParcelBoxu" : "ParcelShopu"),
      this.localizationService.translateGeneral("parcel_shop.watcher_dialog.title"),
      {
        text: this.localizationService.translateGeneral("parcel_shop.watcher_dialog.cancel"),
        variant: "outline-secondary",
      },
      {
        text: this.localizationService.translateGeneral("parcel_shop.watcher_dialog.disable"),
        variant: "primary",
      },
      { closeButton: false }
    );

    if (!result) {
      this.item.capacity_watcher = false;
      this.item.vacation_watcher = false;
    } else {
      this.reaction?.();
      this.item.status = this.oldSettings.status;
      this.item.active_finder = this.oldSettings.active_finder;
      this.item.isActive = this.oldSettings.status === ParcelShopStatus.Active;
      this.bindReaction(this.item);
    }
  }

  protected onDeactivate(close: boolean) {
    if (close) {
      this.reaction?.();
      this.contactChangedSubscription?.unsubscribe();
      this.contactChangedSubscription = undefined;
    }

    return super.onDeactivate(close);
  }

  get isDirty() {
    return this.item && (isDirty(this.item) || this.vacationsIsDirty || this.item.vacations.some(x => isDirty(x)));
  }

  get canSave() {
    return this.isDirty && !hasVisibleErrors(this.item) && !this.item.vacations.some(hasVisibleErrors);
  }

  @bound
  protected nullifyPayments() {
    if (this.item.network_partner == NetworkStatusType.Parent && this.isParcelBox) {
      this.item.electricityPrice = this.item.rentPrice = this.item.capacity = this.item.maxDailyCapacity = this.item.pbInvoicingCycle = 0;
      this.item.enforceSizes = false;
      this.item.acceptedSize = [];
    }
  }

  @bound
  @watchBusy
  // eslint-disable-next-line sonarjs/cognitive-complexity
  async save() {
    if (!validate(this.item) || !validateAll(this.item.vacations)) {
      return;
    }

    if (this.item.status !== ParcelShopStatus.Disabled) {
      this.item.status = this.item.isActive ? ParcelShopStatus.Active : ParcelShopStatus.Unactive;
    }

    this.item.category_id = this.item.selectedCategory;

    this.nullifyPayments();

    try {
      if (this.repository instanceof ParcelShopRepository) {
        if (this.isAlzaBox && this.oldPBInvoicingCycle !== this.item.pbInvoicingCycle && this.item.pbInvoicingCycle === null) {
          await this.repository.deleteRecurringPayment(this.shopId);
        } else {
          if (
            this.oldElectricityPrice !== this.item.electricityPrice ||
            this.oldPBInvoicingCycle !== this.item.pbInvoicingCycle
          ) {
            await this.repository.updateRecurringPayment(this.shopId, 0, this.item.electricityPrice, this.item.pbInvoicingCycle);
          }

          if (this.oldRentPrice !== this.item.rentPrice || this.oldPBInvoicingCycle !== this.item.pbInvoicingCycle) {
            await this.repository.updateRecurringPayment(this.shopId, 1, this.item.rentPrice, this.item.pbInvoicingCycle);
          }
        }
      }

      await this.repository.updateItem(this.shopId, this.item);

      if (this.repository instanceof ParcelShopRepository) {
        await this.repository.updateVacations(this.shopId, this.item.vacations);
      }

      this.notificationService.addNotification(
        this.localizationService.translateGeneral("parcel_shop.edit.configuration.saved_message"),
        SeverityLevel.success,
        EditShopNotificationScope
      );

      await this.loadDetail().then(this.setItem);
    } catch (e) {
      console.error(e);
    }

    scrollTop();
  }

  static Factory({ container }: interfaces.Context) {
    return (shopId: number, isParcelBox: boolean, isPotential: boolean) =>
      new EditShopConfigurationViewModel(
        shopId,
        isParcelBox,
        isPotential,
        isPotential ? container.get(PotentialRepository) : container.get(ParcelShopRepository),
        container.get(LocalizationService),
        container.get(EnumService),
        container.get(SecurityService),
        container.get(NotificationService),
        container.get(ConfirmationService),
        container.get(EventBus),
        container.get(UserContext)
      );
  }
}
