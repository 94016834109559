import { observable } from "mobx";
import { Type } from "class-transformer";
import ScanDeviceImportStatusDto from "./scanDeviceImportStatusDto";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ScanDeviceImportStatusResponseDto {
  /**
   * List of results, each line represents one scan device to import
   */
  @observable
  @Type(() => ScanDeviceImportStatusDto)
  statuses!: ScanDeviceImportStatusDto[];

  static ValidationRules = {
    statuses: { required: true },
  };
}
