import { combinePathString, Router, watchBusy } from "@frui.ts/screens";
import type IAccessPointRepository from "data/repositories/IAccessPointRepository";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import PotentialRepository from "data/repositories/potentialRepository";
import PotentialPs from "entities/potentialPs";
import { interfaces } from "inversify";
import ParcelShop from "manualEntities/parcelShop";
import EnumService from "services/enum";
import DetailWithSingleChildViewModelBase from "viewModels/detailWithSingleChildViewModelBase";
import PotentialPsDetailDto from "../../../entities/potentialPsDetailDto";
import BoxDetailViewModel from "./boxDetailViewModel";
import PotentialDetailViewModel from "./potentialDetailViewModel";
import ShopDetailViewModel from "./shopDetailViewModel";
import { PARCEL_BOX_SUBJECT_CODE } from "viewModels/pickupPoint/newPickupPointViewModel";

@Router.registerRoute({
  name: Router.Self,
  route: "",
  children: [ShopDetailViewModel, BoxDetailViewModel, PotentialDetailViewModel],
})
export default class CommonDetailViewModel extends DetailWithSingleChildViewModelBase<
  ParcelShop | PotentialPsDetailDto,
  ShopDetailViewModel | BoxDetailViewModel | PotentialDetailViewModel
> {
  constructor(
    private shopId: number,
    private isPotential: boolean,
    private repository: IAccessPointRepository<ParcelShop | PotentialPs, ParcelShop | PotentialPsDetailDto>,
    private shopDetailVMFactory: ReturnType<typeof ShopDetailViewModel.Factory>,
    private boxDetailVMFactory: ReturnType<typeof BoxDetailViewModel.Factory>,
    private potentialDetailVMFactory: ReturnType<typeof PotentialDetailViewModel.Factory>,
    private enums: EnumService
  ) {
    super();
    this.name = ""; // Name is lazy loaded later after fetch PS data
    this.navigationName = "";
  }

  protected async onInitialize() {
    await super.onInitialize();

    if (this.item) {
      const accessPointEnumItem = this.enums.value("access_point_partners", this.item.typeId);
      let vm: ShopDetailViewModel | BoxDetailViewModel | PotentialDetailViewModel;

      if (this.isPotential) {
        vm = this.potentialDetailVMFactory(this.shopId);
      } else if (accessPointEnumItem?.subject_type_code !== PARCEL_BOX_SUBJECT_CODE) {
        vm = this.shopDetailVMFactory(this.shopId);
      } else {
        vm = this.boxDetailVMFactory(this.shopId);
      }
      await this.tryActivateChild(vm);
    }
  }

  @watchBusy
  loadDetail() {
    return this.repository.getDetail(this.shopId);
  }

  navigate(path: string, params: any) {
    return super.navigate(combinePathString("skip", path), params);
  }

  protected findNavigationChild(navigationName: string | undefined) {
    return this.activeChild;
  }

  static Factory({ container }: interfaces.Context) {
    return (id: number, isPotential: boolean) => {
      return new CommonDetailViewModel(
        id,
        isPotential,
        isPotential ? container.get(PotentialRepository) : container.get(ParcelShopRepository),
        container.get(ShopDetailViewModel.Factory),
        container.get(BoxDetailViewModel.Factory),
        container.get(PotentialDetailViewModel.Factory),
        container.get(EnumService)
      );
    };
  }
}
