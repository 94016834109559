import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import ParcelBoxPsDto from "./parcelBoxPsDto";
import ParcelBoxDtoState from "./parcelBoxDtoState";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ParcelBoxDto {
  id!: number;

  @observable
  @Expose({ name: "ps_id" })
  psId?: number;

  @observable
  @Type(() => ParcelBoxPsDto)
  ps?: ParcelBoxPsDto;

  /**
   * 0=Available, 1=Assigned, 2=Serviced, 3=Discarded, Available=0, Assigned=1, Serviced=2, Discarded=3
   */
  @observable
  state!: ParcelBoxDtoState;

  /**
   * Scanner TID
   */
  @observable
  @Expose({ name: "terminal_tid" })
  terminalTid?: string;

  /**
   * Scanner S/N
   */
  @observable
  @Expose({ name: "terminal_sn" })
  terminalSn?: string;

  /**
   * Machine S/N
   */
  @observable
  @Expose({ name: "machine_sn" })
  machineSn!: string;

  /**
   * IČP
   */
  @observable
  icp?: string;

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    psId: { number: true },
    state: { required: true },
    machineSn: { required: true },
    createdAt: { required: true },
    updatedAt: { required: true },
  };
}

export class ParcelBoxFilter {
  machine_sn?: string;
  icp?: string;
  state?: number[];
  state_name?: string;
  ps_ids?: number[];
  ps_name?: string;
  depo_ids?: number[];
  depo_name?: string;
  type_ids?: number[];
  type_name?: string;
}
