import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import BonusNoteDetailViewModel from "viewModels/shops/detail/invoicing/bonusNoteDetailViewModel";

const bonusNoteDetailView: ViewComponent<BonusNoteDetailViewModel> = observer(({ vm }) => {
  const { item, translate } = vm;

  return (
    <Modal centered={true} show={true} onHide={vm.requestClose}>
      <Modal.Header closeButton>
        <Modal.Title>{vm.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {item.note && (
          <>
            <b>{translate("note")}:</b>
            <p>{item.note}</p>
          </>
        )}
        {item.rejectionNote && (
          <>
            <b>{translate("rejected_note")}:</b>
            <p>{item.rejectionNote}</p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button onClick={vm.requestClose}>{translate("ok")}</Button>
      </Modal.Footer>
    </Modal>
  );
});

export default registerView(bonusNoteDetailView, BonusNoteDetailViewModel);
