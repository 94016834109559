import { IPagingFilter } from "@frui.ts/data";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import Invoice, { InvoiceWithAttachment } from "entities/invoice";
import { interfaces } from "inversify";
import LocalizationService from "services/localizationService";
import LazyListViewModelBase from "viewModels/lazyListViewModelBase";

export default class InvoicesViewModel extends LazyListViewModelBase<Invoice, any> {
  constructor(
    private shopId: number,
    public isParcelBox: boolean,
    public localization: LocalizationService,
    private repository: ParcelShopRepository
  ) {
    super();

    this.name = localization.translateGeneral(`parcel_${isParcelBox ? "box" : "shop"}.invoicing.invoice_history`);
  }

  loadData(paging: IPagingFilter) {
    return this.repository.getInvoices(this.shopId, paging).then(x => x[0].map(this.appendFileName));
  }

  appendFileName(invoice: InvoiceWithAttachment) {
    if (!invoice.file) {
      invoice.file = `${invoice.month.toString().padStart(2, "0")}_${invoice.year}.pdf`;
    }
    return invoice;
  }

  downloadFile(invoice: InvoiceWithAttachment) {
    return this.repository.downloadInvoice(this.shopId, invoice.id, invoice.file);
  }

  static Factory({ container }: interfaces.Context) {
    return (shopId: number, isParcelBox: boolean) =>
      new InvoicesViewModel(shopId, isParcelBox, container.get(LocalizationService), container.get(ParcelShopRepository));
  }
}
