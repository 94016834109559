import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CreateSubjectDto {
  /**
   * @example
   * 88028801
   */
  @observable
  ic!: string;

  /**
   * @example
   * CZ88028801
   */
  @observable
  dic?: string;

  @observable
  name!: string;

  @observable
  subname?: string;

  @observable
  @Expose({ name: "depo_id" })
  depoId!: number;

  @observable
  @Expose({ name: "subject_type_id" })
  subjectTypeId!: number;

  /**
   * Bank account prefix
   */
  @observable
  @Expose({ name: "bank_account_prefix" })
  bankAccountPrefix?: string;

  /**
   * Bank account number
   */
  @observable
  @Expose({ name: "bank_account_number" })
  bankAccountNumber?: string;

  /**
   * Bank account code
   */
  @observable
  @Expose({ name: "bank_account_code" })
  bankAccountCode?: string;

  static ValidationRules = {
    ic: { required: true },
    name: { maxLength: 250, required: true },
    subname: { maxLength: 250 },
    depoId: { required: true, number: true },
    subjectTypeId: { required: true, number: true },
  };
}
