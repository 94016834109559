import React, { FunctionComponent } from "react";
import { observer } from "mobx-react-lite";
import ScanDeviceImportStatusResult from "entities/scanDeviceImportStatusDtoResult";
import ScanDevicesImportViewModel from "viewModels/scanDevices/scanDevicesImportViewModel";

interface ImportStatusDetailProps {
  vm: ScanDevicesImportViewModel;
}

const ImportStatusDetail: FunctionComponent<ImportStatusDetailProps> = observer(({ vm }) => {
  if (!vm.importStatus) {
    return null;
  }

  return (
    <table className="w-100 table table-striped">
      <thead>
        <tr>
          <th>{vm.translateModel("device_type_id")}</th>
          <th className="pl-4">{vm.translateAttribute("imei")}</th>
          <th>{vm.translateAttribute("price")}</th>
          <th className="text-right pr-4">{vm.translateAttribute("result")}</th>
        </tr>
      </thead>
      <tbody>
        {vm.importStatus.statuses.map(status => (
          <tr key={status.imei} className={status.result !== ScanDeviceImportStatusResult.Imported ? "table-invalid" : ""}>
            <td>{vm.getDeviceTypeName(status.deviceTypeId)}</td>
            <td className="pl-4">{status.imei}</td>
            <td>{status.price}</td>
            <td className="text-right pr-4">{vm.translateImportStatusResult(status.result)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
});

export default ImportStatusDetail;
