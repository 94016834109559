import { observable } from "mobx";
import { Expose } from "class-transformer";
import { merge } from "lodash";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class AddressDto {
  @observable
  name!: string;

  /**
   * Value from enumeration
   */
  @observable
  @Expose({ name: "address_type_id" })
  addressTypeId!: number;

  @observable
  default?: boolean;

  @observable
  subname?: string;

  @observable
  street!: string;

  @observable
  zip!: number;

  @observable
  city!: string;

  /**
   * Value from enumeration (country)
   */
  @observable
  @Expose({ name: "country_id" })
  countryId!: number;

  @observable
  description?: string;

  static ValidationRules = {
    name: { required: true },
    addressTypeId: { required: true, number: true },
    street: { required: true },
    zip: { required: true, number: true },
    city: { required: true },
    countryId: { required: true, number: true },
  };
}

merge(AddressDto.ValidationRules, {
  subname: { maxLength: 35 },
  name: { minLength: 4, maxLength: 35 },
  street: { maxLength: 35 },
  zip: { isPostalCode: true },
  city: { maxLength: 35 },
  description: { maxLength: 1000 },
});
