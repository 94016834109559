import { ISelectItem, registerView, View, ViewComponent } from "@frui.ts/views";
import { DataTable } from "@frui.ts/dataviews";
import HeaderRow from "controls/headerRow";
import PagingInfo from "controls/pagingInfo";
import { createColumnsContext, DataColumn } from "controls/tableHelpers";
import ParcelBoxDto, { ParcelBoxFilter } from "entities/parcelBoxDto";
import { observer } from "mobx-react-lite";
import React from "react";
import ParcelBoxesListViewModel from "viewModels/parcelBox/parcelBoxesViewModel";
import FilterButton, { FilterButtonProps } from "controls/filterButton";
import { Form } from "react-bootstrap";
import { Input } from "@frui.ts/bootstrap";
import TablePager from "controls/tablePager";
import LoadingOverlay from "controls/loadingOverlay";
import ItemsSelector from "controls/itemsSelector";

import "./style.scss";

const createInputFilter = (
  propertyName: keyof ParcelBoxFilter,
  vm: ParcelBoxesListViewModel,
  filterButtonProps: FilterButtonProps,
  tg: (code: string) => string
) => (
  <FilterButton {...filterButtonProps} isActive={!!vm.appliedFilter?.[propertyName]} onClear={() => vm.clearFilter(propertyName)}>
    <Form.Group>
      <Input placeholder={tg("search")} size="sm" data-search target={vm.filter} property={propertyName} />
    </Form.Group>
  </FilterButton>
);

const createSelectFilter = (
  propertyName: keyof ParcelBoxFilter,
  vm: ParcelBoxesListViewModel,
  filterButtonProps: FilterButtonProps,
  tg: (code: string) => string,
  items: ISelectItem[],
  searchTextProperty: keyof ParcelBoxFilter,
  isActive: boolean
) => (
  <FilterButton {...filterButtonProps} isActive={isActive} onClear={() => vm.clearFilter(propertyName)}>
    <ItemsSelector
      target={vm.filter}
      property={propertyName}
      searchTextProperty={searchTextProperty}
      items={items || []}
      searchPlaceholder={tg("search")}
      selectAllText={tg("select_all")}
    />
  </FilterButton>
);

const tableColumns: DataColumn<ParcelBoxDto, ParcelBoxesListViewModel>[] = [
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("parcel_box", "machine_sn")}
        {createInputFilter("machine_sn", vm, filterButtonProps, tg)}
      </>
    ),
    property: "machine_sn" as any,
    sortable: true,
    valueFormatter: ({ item, context }) => item.machineSn,
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("parcel_box", "icp")}
        {createInputFilter("icp", vm, filterButtonProps, tg)}
      </>
    ),
    property: "icp",
    sortable: true,
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("parcel_box", "type_id")}
        {createSelectFilter("type_ids", vm, filterButtonProps, tg, vm.types, "type_name", !!vm.appliedFilter?.state?.length)}
      </>
    ),
    property: "type_id" as any,
    valueFormatter: ({ item, context }) => context.vm.getTypeName(item?.ps?.typeId),
    sortable: true,
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("parcel_box", "state")}
        {createSelectFilter("state", vm, filterButtonProps, tg, vm.states, "state_name", !!vm.appliedFilter?.state?.length)}
      </>
    ),
    headerClassName: "parcelBoxState",
    property: "state",
    sortable: true,
    valueFormatter: ({ item, context }) => {
      const state = context.vm.getState(item?.state);
      return <div className={`badge ${state?.color}`}>{state?.name}</div>;
    },
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("parcel_box", "ps_name")}
        {createSelectFilter(
          "ps_ids",
          vm,
          filterButtonProps,
          tg,
          vm.allowedParcelShops || [],
          "ps_name",
          !!vm.appliedFilter?.ps_ids?.length
        )}
      </>
    ),
    headerClassName: "parcelBoxPsName",
    property: "ps_name" as any,
    sortable: true,
    valueFormatter: ({ item }) => item?.ps?.name,
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("parcel_box", "depo_id")}
        {createSelectFilter("depo_ids", vm, filterButtonProps, tg, vm.depos, "depo_name", !!vm.appliedFilter?.depo_ids?.length)}
      </>
    ),
    property: "ps",
    valueFormatter: ({ item, context }) => (item?.ps?.depoId ? context.vm.getDepoName(item?.ps?.depoId) : ""),
  },
];

const parcelBoxesListView: ViewComponent<ParcelBoxesListViewModel> = observer(({ vm }) => {
  if (vm.activeChild) {
    return <View vm={vm.activeChild} />;
  }

  const columnsContext = createColumnsContext(vm);

  return (
    <>
      <HeaderRow title={vm.localizationService.translateModel("parcel_box_station", 1)} />

      <PagingInfo paging={vm.currentPaging} modelName="result" localization={vm.localizationService} />

      <div className="react-bootstrap-table">
        <DataTable
          className="table custom-table messages table-striped"
          pagingFilter={vm.pagingFilter}
          itemKey="id"
          items={vm.items || []}
          columns={tableColumns as any}
          context={columnsContext}
          onColumnSort={vm.sortByColumn}
          rowProps={item => ({ onClick: () => vm.openDetail(item) })}
        />
      </div>

      <TablePager vm={vm} />
      <LoadingOverlay loading={vm.busyWatcher.isBusy} />
    </>
  );
});

export default registerView(parcelBoxesListView, ParcelBoxesListViewModel);
