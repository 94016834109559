import { watchBusy } from "@frui.ts/screens";
import ParcelBoxRepository from "data/repositories/parcelBoxRepository";
import ParcelBoxDto from "entities/parcelBoxDto";
import ParcelBoxUpdateDto from "entities/parcelBoxUpdateDto";
import { interfaces } from "inversify";
import { action, observable, runInAction } from "mobx";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import { DetailViewModel } from "@frui.ts/datascreens";
import { formatDepoName } from "utils/helpers";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";

export enum ParcelBoxEvent {
  ToService = "to_service",
  Discard = "discard",
  RemoveFromPs = "remove_from_ps",
  ReturnedFromService = "returned_from_service",
  AssignToPs = "assign_to_ps",
}

export default class ParcelBoxDetailViewModel extends DetailViewModel<ParcelBoxDto> {
  @observable isEditFormVisible = false;
  @observable isAssignFormVisible = false;
  @observable isOrderServiceFormVisible = false;
  @observable updateItem = new ParcelBoxUpdateDto();
  @observable custId?: number;

  constructor(
    public originalItem: ParcelBoxDto,
    private repository: ParcelBoxRepository,
    private enums: EnumService,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService,
    public localizationService: LocalizationService
  ) {
    super();
    if (originalItem instanceof ParcelBoxDto) {
      this.navigationName = String(originalItem.id);
      this.name = `${localizationService.translateGeneral("parcel_box.title")} ${originalItem.machineSn}`;

      attachAutomaticValidator(this.updateItem, ParcelBoxUpdateDto.ValidationRules, false);
    }
  }

  @action.bound
  openEditForm() {
    this.isEditFormVisible = true;
  }

  @action.bound
  closeEditForm() {
    this.isEditFormVisible = false;
    this.makeUpdateEntity(this.item);
  }

  @action.bound
  openAssignForm() {
    const tg = this.localizationService.translateGeneral;
    const message = tg(`parcel_box.events.${ParcelBoxEvent.AssignToPs}.denied`);
    if (!this.item.icp || !this.item.terminalTid || !this.item.terminalSn) {
      this.notificationService.addNotification(message, SeverityLevel.critical);
      return;
    }

    this.isAssignFormVisible = true;
  }

  @action.bound
  closeAssignForm() {
    this.isAssignFormVisible = false;
    this.makeUpdateEntity(this.item);
  }

  @action.bound
  openOrderServiceForm() {
    this.isOrderServiceFormVisible = true;
  }

  @action.bound
  closeOrderServiceForm() {
    this.isOrderServiceFormVisible = false;
  }

  @action.bound
  @watchBusy
  async statusConfirmation(eventName: ParcelBoxEvent) {
    const tg = this.localizationService.translateGeneral;
    const okText = tg(eventName === ParcelBoxEvent.Discard ? "confirm_dialog.discard" : "confirm_dialog.confirm");
    const okType: "primary" | "danger" = eventName === ParcelBoxEvent.Discard ? "danger" : "primary";
    const noText = tg(eventName === ParcelBoxEvent.Discard ? "confirm_dialog.keep" : "confirm_dialog.cancel");

    const confirm = await this.confirmationService.showConfirmation(
      tg(`parcel_box.events.${eventName}.body`).replace("%MACHINE_SN%", this.originalItem.machineSn),
      tg(`payment_terminal.events.${eventName}.button`),
      { variant: okType, text: okText },
      noText
    );

    if (confirm) {
      void this.changeState(eventName);
    }
  }

  @action.bound
  async unassign() {
    const tg = this.localizationService.translateGeneral;

    const confirm = await this.confirmationService.showConfirmation(
      tg("parcel_box.events.remove_from_ps.body").replace("%MACHINE_SN%", this.originalItem.machineSn),
      tg("parcel_box.events.remove_from_ps.button"),
      { variant: "primary", text: tg("confirm_dialog.confirm") },
      tg("confirm_dialog.cancel")
    );

    if (confirm) {
      runInAction(() => {
        this.custId = undefined;
        void this.updateBoxCustomer();
      });
    }
  }

  get stateEnumItem() {
    return this.enums.value("parcel_box_status", this.item.state);
  }

  @action.bound
  @watchBusy
  protected async loadDetail() {
    if (this.originalItem instanceof ParcelBoxDto) {
      const result = await this.repository.getBox(this.originalItem?.id);
      this.setItem(result);
      this.makeUpdateEntity(result);
      return result;
    }

    return undefined;
  }

  @action.bound
  makeUpdateEntity(item: ParcelBoxDto) {
    runInAction(() => {
      if (item.terminalSn) {
        this.updateItem.terminalSn = item.terminalSn;
      }
      if (item.terminalTid) {
        this.updateItem.terminalTid = item.terminalTid;
      }
      if (item.icp) {
        this.updateItem.icp = item.icp;
      }
      this.custId = item.ps?.custId;
    });
  }

  getDepoName(code: number) {
    const depo = this.enums.value("depos", code);
    return formatDepoName(depo);
  }

  getTypeName(id: number | undefined) {
    if (id !== undefined) {
      const type = this.enums.value("access_point_partners", id);
      return type?.name ?? "";
    }
    return "";
  }

  @action.bound
  @watchBusy
  async changeState(eventName: ParcelBoxEvent) {
    const tg = this.localizationService.translateGeneral;
    try {
      await this.repository.updateBoxState(this.originalItem.id, eventName);
      void this.loadDetail();
      this.notificationService.addNotification(tg(`parcel_box.events.${eventName}.success`), SeverityLevel.success);
    } catch (e) {
      // do nothing
    }
  }

  @action.bound
  @watchBusy
  async updateBoxCustomer() {
    try {
      const tg = this.localizationService.translateGeneral;
      const event = this.custId ? ParcelBoxEvent.AssignToPs : ParcelBoxEvent.RemoveFromPs;
      const message = `parcel_box.events.${event}.success`;

      await this.repository.updateBoxState(this.originalItem.id, event, this.custId);

      if (event === ParcelBoxEvent.AssignToPs) {
        await this.loadDetail();
        this.notificationService.addNotification(
          tg(message).replace("%PS_NAME%", this.item.ps?.name ?? ""),
          SeverityLevel.success
        );
        runInAction(() => {
          this.isAssignFormVisible = false;
        });
      } else {
        this.notificationService.addNotification(
          tg(message).replace("%PS_NAME%", this.item.ps?.name ?? ""),
          SeverityLevel.success
        );
        void this.loadDetail();
      }
    } catch (e) {
      this.makeUpdateEntity(this.item);
    }
  }

  @action.bound
  @watchBusy
  async save() {
    if (validate(this.updateItem)) {
      const tg = this.localizationService.translateGeneral;
      const updatedItem = await this.repository.updateBox(this.originalItem.id, this.updateItem);
      if (updatedItem) {
        this.setItem(updatedItem);
        this.notificationService.addNotification(tg("parcel_box.events.edit.success"), SeverityLevel.success);
        runInAction(() => {
          this.isEditFormVisible = false;
        });
      }
    }
  }

  static Factory({ container }: interfaces.Context) {
    return (item: ParcelBoxDto) => {
      return new ParcelBoxDetailViewModel(
        item,
        container.get(ParcelBoxRepository),
        container.get(EnumService),
        container.get(ConfirmationService),
        container.get(NotificationService),
        container.get(LocalizationService)
      );
    };
  }
}
