import { Select } from "@frui.ts/bootstrap";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { AdditionalInformationSectionProps } from "./types";

const BusinessType: FunctionComponent<AdditionalInformationSectionProps> = observer(({ vm }) => {
  const ta = (code: string) => vm.localizationService.translateAttribute("additional_information", code);

  return (
    <>
      <h3>
        {ta("business_type")} {vm.isParcelBox || vm.isAlzaBox ? "parcelboxu" : "parcelshopu"}
      </h3>
      <Row>
        <Form.Group as={Col} lg={3} md={6} xs={12}>
          <Form.Label>{ta("business_type")}</Form.Label>
          <Select
            target={vm.item}
            allowEmpty
            property="business_type_id"
            items={vm.businessTypes || []}
            mode="key"
            textProperty="label"
            keyProperty="value"
            isNumeric={true}
          />
        </Form.Group>
      </Row>
    </>
  );
});

export default BusinessType;
