import { observable } from "mobx";
import uniqueId from "lodash/uniqueId";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class CustomEnumDto {
  @observable
  value!: string;

  static ValidationRules = {
    value: { required: true },
  };
}

export default class CustomEnum extends CustomEnumDto {
  @observable
  id: string;

  constructor() {
    super();
    this.id = uniqueId();
  }
}
