import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import { interfaces } from "inversify";
import { computed, observable } from "mobx";
import ShopConfiguration, { configurationValidationRulesByAccessPointType } from "models/shops/newShop/shopConfiguration";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import ConfigurationViewModelBase from "../detail/edit/configurationViewModelBase";
import { IStepViewModel } from "./types";
import SecurityService from "services/securityService";
import AccessPointPartners from "models/enumerations/accessPointPartners";
import ShopInformation from "models/shops/newShop/shopInformation";
import NewContactsViewModel from "./newContactsViewModel";
import ConfirmationService from "services/confirmationService";
import UserContext from "services/userContext";
import isPotential from "../../../utils/isPotential";

export default class NewShopConfigurationViewModel
  extends ConfigurationViewModelBase<ShopConfiguration>
  implements IStepViewModel {
  protected newContactVM?: NewContactsViewModel;

  constructor(
    public originalItem: ShopConfiguration,
    public isParcelBox: boolean,
    shopInformation: ShopInformation,
    repository: ParcelShopRepository,
    localizationService: LocalizationService,
    protected enums: EnumService,
    security: SecurityService,
    protected confirmationService: ConfirmationService,
    protected userContext: UserContext
  ) {
    super(undefined, shopInformation, repository, localizationService, enums, security, confirmationService, userContext);
    this.name = localizationService.translateGeneral("parcel_shop.configuration");
  }

  protected async loadDetail() {
    const configuration = observable.object(this.originalItem);
    attachAutomaticValidator(configuration, configurationValidationRulesByAccessPointType(this));
    return configuration;
  }

  protected onIsActiveChange(isActive: boolean) {
    super.onIsActiveChange(isActive);

    if (!this.isAlzaBox) {
      this.item.capacity_watcher = isActive;
      this.item.vacation_watcher = isActive;
    }
  }

  @computed
  get isPotential() {
    return isPotential(this.accessPointPartner?.code ?? "");
  }

  @computed
  get accessPointPartner() {
    const typeId = this.shopInformation?.typeId;

    if (!typeId) {
      throw new Error(`Unexpected error we don't have information about parcel shop type!`);
    }

    return this.enums.value("access_point_partners", typeId);
  }

  @computed
  get isParcelShopType() {
    if (this.isPotential) {
      return this.accessPointPartner?.code === AccessPointPartners.PARCEL_SHOP_POTENTIAL;
    } else {
      return this.accessPointPartner?.code === AccessPointPartners.PARCEL_SHOP;
    }
  }

  get canContinue() {
    return !hasVisibleErrors(this.item);
  }

  validate() {
    return validate(this.item);
  }

  setContactVM(vm: NewContactsViewModel) {
    this.newContactVM = vm;
  }

  get contactTypeIds() {
    return this.newContactVM?.items.map(x => x.contactTypeId) ?? [];
  }

  static Factory({ container }: interfaces.Context) {
    return (item: ShopConfiguration, isParcelBox: boolean, info: ShopInformation) =>
      new NewShopConfigurationViewModel(
        item,
        isParcelBox,
        info,
        container.get(ParcelShopRepository),
        container.get(LocalizationService),
        container.get(EnumService),
        container.get(SecurityService),
        container.get(ConfirmationService),
        container.get(UserContext)
      );
  }
}
