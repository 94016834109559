import { registerView, ViewComponent } from "@frui.ts/views";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React from "react";
import EditShopConfigurationViewModel from "viewModels/shops/detail/edit/editShopConfigurationViewModel";
import { IShopConfigurationViewModel } from "viewModels/shops/detail/types";
import AddShopConfigurationViewModel from "viewModels/shops/newShop/newShopConfigurationViewModel";
import Vacations from "./vacations";
import WareHouseCapacity from "./wareHouseCapacity";
import AdditionalInfo from "./additionalInfo";
import GeneralInfo from "./generalInfo/index";
import RecurringPayments from "./recurringPayments";
import Device from "./device";
import "./configurationView.scss";

const ShopConfigurationView: ViewComponent<IShopConfigurationViewModel> = observer(({ vm }) => {
  return (
    <>
      {vm.item && (
        <div className="configuration">
          <GeneralInfo vm={vm} />
          {vm.canChooseDevice && <Device vm={vm} />}
          {vm.canEditRecurringPayments && <RecurringPayments vm={vm} />}
          {!vm.isWarehouseHidden && <WareHouseCapacity vm={vm} />}
          {!vm.isParcelBox && !vm.isAlzaBox && !vm.isPotential && <Vacations vm={vm} />}
          <br />
          <AdditionalInfo vm={vm} />
        </div>
      )}

      <LoadingOverlay loading={!vm.item} />
    </>
  );
});

registerView(ShopConfigurationView, AddShopConfigurationViewModel);
registerView(ShopConfigurationView, EditShopConfigurationViewModel);
export default ShopConfigurationView;
