import { bound } from "@frui.ts/helpers";
import LocalizationRepository from "data/repositories/localizationRepository";
import { action, computed, observable } from "mobx";
import LocaleProvider from "./localeProvider";
import { models } from "models/shops/interface";

export type pluralForms = "zero" | "one" | "few" | "many" | "other";

/**
 * Localization service
 *
 * @author Jan Strnádek
 * @version 0.2
 */
export default class LocalizationService implements Services.Localization {
  @observable locs: models.LocalizeContainer;
  @observable private locCache: { [key: string]: string } = {};
  private loadingLocalization = false;

  constructor(private localeProvider: LocaleProvider, private repository: LocalizationRepository) {}

  async fetchLocalizations() {
    if (!this.loadingLocalization) {
      this.loadingLocalization = true;
      const locs = await this.repository.fetchLocalization();
      this.setLocs(locs);
    }
  }

  @computed
  get isLoaded(): boolean {
    return this.locs && Object.keys(this.locs).length !== 0;
  }

  @action
  setLocs(locs: models.LocalizeContainer) {
    this.locs = locs;
    this.locCache = {};
  }

  @action.bound
  changeLocale() {
    this.loadingLocalization = false;
    return this.fetchLocalizations();
  }

  /**
   * Translate general phrase.
   *
   * @example
   *   translateGeneral('buttons.new') // Returns 'Nový'
   *
   * @param {string} code
   * @returns {string}
   */
  @action.bound
  translateGeneral(code: string): string {
    if (!this.isLoaded) {
      void this.fetchLocalizations();
      return "";
    }

    if (this.locCache[code]) {
      return this.locCache[code];
    }

    const arr = code.split(".");
    if (arr.length > 0) {
      let scope: models.LocalizationNodeValue = this.locs.general;

      for (const key of arr) {
        if (Object.prototype.hasOwnProperty.call(scope, key)) {
          scope = scope[key];
        } else {
          // tslint:disable-next-line:no-console
          console.warn(`[Localization] Problem with key ${code} missing ${key}.`);
          scope = code;
          break;
        }
      }

      this.locCache[code] = scope as string;
      return scope as string;
    } else {
      return this.locs.general[code] as string;
    }
  }

  /**
   * Translate model name.
   *
   * @example
   *    translateModel('company', 5) // Returns "Společnosti"
   *    translateModel('company') // Returns "Společnost"
   *
   * @param name Model name
   * @param size Number of it
   */
  @action.bound
  translateModel(name: string, size = 1): string {
    if (!this.isLoaded) {
      void this.fetchLocalizations();
      return "";
    }

    let key: pluralForms;

    if (size === 0) {
      key = "zero";
    } else if (size === 1) {
      key = "one";
    } else if ([2, 3, 4].indexOf(size % 10) !== -1 && [12, 13, 14].indexOf(size % 100) === -1) {
      key = "few";
    } else if ([0, 1, 5, 6, 7, 8, 9].indexOf(size % 10) !== -1 || [12, 13, 14].indexOf(size % 100) !== -1) {
      key = "many";
    } else {
      key = "other";
    }

    const value = this.locs.models.models[name];
    if (value && Object.prototype.hasOwnProperty.call(value, key)) {
      return value[key];
    } else {
      return name;
    }
  }

  /**
   * Translate single attribute for model.
   *
   * @example
   *    translateAttribute('company', 'ic') // Returns "ič"
   *
   * @param {string} model
   * @param {string} name
   * @returns {string}
   * @memberof GeneralLocalization
   */
  @action.bound
  translateAttribute(model: string, name: string): string {
    if (!this.isLoaded) {
      void this.fetchLocalizations();
      return "";
    }

    let translation: string | undefined;

    if (this.locs.models.attributes[model]) {
      translation = this.locs.models.attributes[model][name];
    }

    if (!translation && Object.prototype.hasOwnProperty.call(this.locs.attributes, name)) {
      translation = this.locs.attributes[name];
    }

    return translation || name;
  }

  /**
   * Return date in proper format according to localization
   *
   * @param {Date} date
   * @returns {string}
   */
  @bound
  formatDate(date?: Date): string {
    if (!date) {
      return "";
    }

    return date.toLocaleDateString(this.locs.language_code);
  }

  /**
   * Return time in proper format according to localization
   *
   * @param {Date | string} time
   * @returns {string}
   */
  @bound
  formatTime(time?: Date): string {
    if (!time) {
      return "";
    }

    return time.toLocaleTimeString(this.locs.language_code, { hour: "2-digit", minute: "2-digit" });
  }

  /**
   * Return date and time in proper format according to localization
   * @param date
   * @returns {string}
   */
  @bound
  formatDateTime(date?: Date): string {
    if (!date) {
      return "";
    }

    return date.toLocaleString(this.locs.language_code);
  }

  /**
   * Return number in proper format according to localization.
   *
   * @param {number} num
   * @returns {string}
   */
  @bound
  formatNumber(num: number): string {
    if (Number(num) !== num) {
      return "";
    }

    return num.toLocaleString(this.locs.language_code);
  }

  /**
   * Return number in proper currency format according to localization.
   *
   * @param {number} num
   * @returns {string}
   */
  @bound
  formatCurrency(num: number): string {
    if (Number(num) !== num) {
      return "";
    }

    return num.toLocaleString(this.locs.language_code, {
      style: "currency",
      currency: "CZK",
    });
  }
}
