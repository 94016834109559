import React, { FunctionComponent } from "react";
import { ConfigurationSectionProps } from "../types";
import { observer } from "mobx-react-lite";
import { Col, Form, Row } from "react-bootstrap";
import { Check } from "@frui.ts/bootstrap";

const BoxStation: FunctionComponent<ConfigurationSectionProps> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral: tg } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("parcel_shop", attribute);
  };

  const taBox = (attribute: string) => translateAttribute("parcel_box", attribute);

  return (
    <>
      <Form.Group>
        <Check
          custom
          target={vm}
          property="boxStation"
          label={ta("access_point")}
          id="access_point"
          disabled={!!vm.item.parcelBox}
        />
      </Form.Group>

      <Row>
        <Col md={1} />
        <Form.Group as={Col} md={3}>
          <Form.Label>{taBox("terminal_tid")}</Form.Label>
          <input className="form-control" disabled={true} value={vm.item.parcelBox?.terminal_tid} />
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>{taBox("terminal_sn")}</Form.Label>
          <input className="form-control" disabled={true} value={vm.item.parcelBox?.terminal_sn} />
        </Form.Group>
        {vm.item.parcelBox?.id && vm.canEditAccessPoint && (
          <Form.Group as={Col} md={3}>
            <a
              href={`#/parcel_box/${vm.item.parcelBox?.id}`}
              style={{ marginTop: "24px" }}
              target="_new"
              className="btn btn-outline-secondary">
              {tg("parcel_box.manage_station")}
            </a>
          </Form.Group>
        )}
      </Row>
      <Row>
        <Col md={1} />
        <Form.Group as={Col} md={3}>
          <Form.Label>{taBox("machine_sn")}</Form.Label>
          <input className="form-control" disabled={true} value={vm.item.parcelBox?.machine_sn} />
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>{taBox("icp")}</Form.Label>
          <input className="form-control" disabled={true} value={vm.item.parcelBox?.icp} />
        </Form.Group>
      </Row>
    </>
  );
});

export default BoxStation;
