import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class MessageGroupListDto {
  id!: number;

  @observable
  name!: string;

  static ValidationRules = {
    id: { required: true, number: true },
    name: { required: true },
  };
}

export class MessageGroupFilter {}
