import { BusyWatcher, ConductorSingleChild, Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import SubjectRepository from "data/repositories/subjectRepository";
import Subject from "entities/subject";
import CreateSubjectDto from "entities/createSubjectDto";
import { interfaces } from "inversify";
import { action, observable, runInAction } from "mobx";
import { default as Localization } from "services/localizationService";
import NotificationService from "services/notificationService";
import { scrollTop } from "utils/helpers";
import NewParcelShopViewModel from "../shops/newShop/newParcelShopViewModel";
import NewSubjectViewModel from "../shops/newSubjectViewModel";
import { bound } from "@frui.ts/helpers";
import { PARCEL_SHOP_SUBJECT_CODE } from "./newPickupPointViewModel";
import { models } from "models/shops/interface";

@Router.registerRoute({ name: Router.Self, route: ":subjectId", children: [NewParcelShopViewModel] })
export default class CheckSubjectViewModel extends ConductorSingleChild<ScreenBase> {
  busyWatcher = new BusyWatcher();
  @observable item: CreateSubjectDto;

  static notificationScope = "new_parcel_shop";

  @action.bound setItem(item: CreateSubjectDto) {
    this.item = item;
    attachAutomaticValidator(this.item, { ic: Subject.ValidationRules.ic });
  }

  constructor(
    public subjectTypeItem: models.Enum,
    private repository: SubjectRepository,
    private newShopVMFactory: ReturnType<typeof NewParcelShopViewModel.Factory>,
    private newSubjectVMFactory: ReturnType<typeof NewSubjectViewModel.Factory>,
    private notificationService: NotificationService,
    public localizationService: Localization
  ) {
    super();
    this.navigationName = subjectTypeItem.code;
    this.name = this.translate(`add_parcel_${subjectTypeItem.code === PARCEL_SHOP_SUBJECT_CODE ? "shop" : "box"}`);

    const subjectFindItem = new CreateSubjectDto();
    subjectFindItem.subjectTypeId = subjectTypeItem.id;

    this.setItem(subjectFindItem);
  }

  @action.bound
  @watchBusy
  async continue() {
    if (!validate(this.item)) {
      return;
    }

    scrollTop();
    const subject = await this.findSubject(this.subjectTypeItem.code, this.item.ic);
    if (subject) {
      this.setItem(subject);
      void this.tryActivateChild(this.newShopVMFactory(subject));
    } else {
      runInAction(() => {
        this.item.subjectTypeId = this.subjectTypeItem.id;
        void this.tryActivateChild(this.newSubjectVMFactory(this.item));
      });
    }
  }

  private async findSubject(subjectTypeCode: string, ic: string) {
    try {
      return await this.repository.getSubjectBySubjectTypeCodeAndIco(subjectTypeCode, ic);
    } catch {
      return undefined;
    }
  }

  protected async findNavigationChild(navigationName: string) {
    if (!navigationName) {
      return undefined;
    }

    const ico = navigationName;

    try {
      const entity = await this.repository.getSubjectBySubjectTypeCodeAndIco(this.subjectTypeItem.code, ico);
      return this.newShopVMFactory(entity);
    } catch {
      runInAction(() => {
        this.item.ic = ico;
        this.item.subjectTypeId = this.subjectTypeItem.id;
      });
      if (validate(this.item)) {
        return this.newSubjectVMFactory(this.item);
      }
    }

    return undefined;
  }

  async deactivateChild(child: ScreenBase, close: boolean) {
    super.deactivateChild(child, close);
    this.setItem(new Subject());
  }

  protected onDeactivate(close: boolean): Promise<any> {
    this.notificationService.removeNotification(CheckSubjectViewModel.notificationScope);
    return super.onDeactivate(close);
  }

  @bound translate(key: string) {
    return this.localizationService.translateGeneral(`check_subject.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (subjectTypeItem: models.Enum) =>
      new CheckSubjectViewModel(
        subjectTypeItem,
        container.get(SubjectRepository),
        container.get(NewParcelShopViewModel.Factory),
        container.get(NewSubjectViewModel.Factory),
        container.get(NotificationService),
        container.get(Localization)
      );
  }
}
