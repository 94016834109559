import { ConductorOneChildActive } from "@frui.ts/screens";
import { interfaces } from "inversify";
import LocalizationService from "services/localizationService";
import ShopDetailContext from "models/shops/shopDetailContext";
import RewardsEditViewModel from "./rewardsEditViewModel";
import { bound } from "@frui.ts/helpers";
import SecurityService from "services/securityService";
import ParcelShop from "../../../../manualEntities/parcelShop";

export default class RewardsViewModel extends ConductorOneChildActive<RewardsEditViewModel> {
  constructor(
    private shopContext: ShopDetailContext<ParcelShop>,
    public localization: LocalizationService,
    private rewardsEditVMFactory: ReturnType<typeof RewardsEditViewModel.Factory>,
    private security: SecurityService
  ) {
    super();
    this.name = localization.translateGeneral("parcel_shop.rewards.title");
  }

  get item() {
    return {
      fm_reward: this.shopContext.shop.fm_reward,
      lm_reward: this.shopContext.shop.lm_reward,
      lmcod_reward: this.shopContext.shop.lmcod_reward,
    };
  }

  get canEdit() {
    return this.security.isAllowed("edit", "rewards");
  }

  @bound
  openEdit() {
    const vm = this.rewardsEditVMFactory(this.shopContext);
    this.tryActivateChild(vm);
  }

  static Factory({ container }: interfaces.Context) {
    return (context: ShopDetailContext<ParcelShop>) =>
      new RewardsViewModel(
        context,
        container.get(LocalizationService),
        container.get(RewardsEditViewModel.Factory),
        container.get(SecurityService)
      );
  }
}
