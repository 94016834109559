import { ValidationControlBase } from "@frui.ts/bootstrap";
import { CommonInputProps } from "@frui.ts/bootstrap/dist/controls/commonInputProps";
import { bound, ensureObservableProperty, PropertyName } from "@frui.ts/helpers";
import { IBindingProps } from "@frui.ts/views";
import { get, isObservable, isObservableProp } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { Form, FormCheckProps } from "react-bootstrap";

export interface RadioArrayProps {
  isNumeric?: boolean;
  className?: string;
}

export class RadioArray<TTarget> extends ValidationControlBase<
  TTarget,
  RadioArrayProps & FormCheckProps & CommonInputProps & IBindingProps<TTarget>
> {
  protected get inheritedProps() {
    const { id, noValidation, errorMessage, className, target, property, onValueChanged, isNumeric, ...otherProps } = this.props;

    return otherProps;
  }

  @bound protected renderInner() {
    const { className, id, property } = this.props;
    const validationError = this.getValidationError();

    return (
      <Observer>
        {() => (
          <Form.Check
            {...this.inheritedProps}
            id={`${property}-${id}`}
            className={`radio-array ${className || ""}`}
            checked={!!this.value}
            type="radio"
            onChange={this.handleValueChanged}
            isInvalid={!!validationError}
          />
        )}
      </Observer>
    );
  }

  protected get value() {
    const { target, id, isNumeric } = this.props;
    const property = this.props.property as PropertyName<TTarget>;

    if (!target) {
      //// throw new Error("'target' prop has not been set");
      // tslint:disable-next-line: no-console
      console.warn("'target' prop has not been set");
      return undefined;
    }
    if (!property) {
      throw new Error("'property' prop has not been set");
    }

    if (!isObservable(target) || !isObservableProp(target, property)) {
      ensureObservableProperty(target, property, target[property as keyof TTarget]);
    }

    if (!id) {
      throw new Error("'id' prop is required for RadioArray!");
    }

    let value: string | number | undefined = id;

    if (isNumeric) {
      value = parseInt(id, 10);
    }

    return get(target, property) === value;
  }

  @bound protected handleValueChanged(e: React.FormEvent<any>) {
    const { id, isNumeric } = this.props;
    let value: undefined | string | number;

    if (isNumeric && id) {
      value = parseInt(id, 10);
    } else {
      value = id;
    }

    if (value === undefined) {
      return;
    }

    this.setValue(value);
  }
}
