import React, { FunctionComponent } from "react";
import { ConfigurationSectionProps } from "../types";
import { observer } from "mobx-react-lite";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Check } from "@frui.ts/bootstrap";
import { StringInput } from "controls/stringInput";

const PaymentTerminal: FunctionComponent<ConfigurationSectionProps> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral: tg } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("parcel_shop", attribute);
  };
  const paymentTerminalId = vm.item?.payment_terminals?.length > 0 && vm.item?.payment_terminals[0].id;

  return (
    <>
      <h3>{tg("parcel_shop.payment_terminal")}</h3>

      {!paymentTerminalId && (
        <Row>
          <Form.Group as={Col} md={3}>
            <Form.Label>{ta("terminal_id")}</Form.Label>
            <StringInput trim={true} target={vm.item} property="tid" disabled={!vm.canEditTerminalId} />
          </Form.Group>
        </Row>
      )}
      {vm.canChoosePaymentByCard && (
        <Form.Group>
          <Check custom target={vm.item} property="card_payment" label={ta("card_payment")} id="card_payment" />
        </Form.Group>
      )}

      {paymentTerminalId && (
        <Row>
          <Col md={1} />
          <Form.Group as={Col} md={3}>
            <Form.Label>{ta("terminal_id")}</Form.Label>
            <StringInput trim={true} target={vm.item} property="tid" disabled={!vm.canEditTerminalId} />
          </Form.Group>
          <Form.Group as={Col} md={3}>
            <Form.Label>{ta("terminal_sn")}</Form.Label>
            <input className="form-control" disabled={true} value={vm.item.payment_terminals[0].terminal_sn} />
          </Form.Group>
          {vm.canEditPaymentTerminal && (
            <Form.Group as={Col} md={3}>
              <a
                href={`#/payment_terminal/${paymentTerminalId}`}
                style={{ marginTop: "24px" }}
                target="_new"
                className="btn btn-outline-secondary">
                {tg("payment_terminal.manage_terminal")}
              </a>
            </Form.Group>
          )}
        </Row>
      )}

      <Modal centered={true} show={vm.cardWarningShow} onHide={vm.hideCardWarning}>
        <Modal.Header closeButton>
          <Modal.Title>{tg("payment_terminal.warning_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {vm.canEditPaymentTerminal ? (
            <>
              {tg("payment_terminal.warning")}
              <a href={`#/payment_terminal`}>{vm.localizationService.translateModel("payment_terminal", 2)}</a>
            </>
          ) : (
            tg("payment_terminal.warning_user")
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button onClick={vm.hideCardWarning}>{tg("confirm_dialog.ok")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default PaymentTerminal;
