import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import AddressDto from "./addressDto";
import ContactDto from "./contactDto";
import OpeningHourDto from "./openingHourDto";
import VacationDto from "./vacationDto";
import RecurringPaymentDto from "./recurringPaymentDto";
import AttachToPsDto from "./attachToPsDto";
import CreateCompetitionInPsDto from "./createCompetitionInPsDto";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CreatePsDto {
  /**
   * Potential ID from which is this PS created. This needs to be set for proper potential data cleanup.
   */
  @observable
  @Expose({ name: "potential_ps_id" })
  potentialPsId?: number;

  /**
   * Subject id from Create / GET subject request
   */
  @observable
  @Expose({ name: "subject_id" })
  subjectId!: number;

  @observable
  name!: string;

  @observable
  subname?: string;

  /**
   * Description for EPS
   */
  @observable
  description?: string;

  @observable
  @Expose({ name: "description_crm" })
  descriptionCrm?: string;

  @observable
  @Expose({ name: "phone_type" })
  phoneType?: string;

  /**
   * PS Status (enum value `ps_status`)
   */
  @observable
  status!: number;

  /**
   * PS Type (enum value `ps_types`)
   */
  @observable
  @Expose({ name: "type_id" })
  typeId!: number;

  /**
   * PS Type (enum value `ps/pb_types`)
   */
  @observable
  @Expose({ name: "business_type_id" })
  businessTypeId?: number;

  /**
   * Salesman partners enumeration
   */
  @observable
  @Expose({ name: "salesman_id" })
  salesmanId!: number;

  /**
   * Depo reference key
   */
  @observable
  @Expose({ name: "depo_id" })
  depoId!: number;

  /**
   * Category reference key
   */
  @observable
  @Expose({ name: "category_id" })
  categoryId!: number;

  /**
   * Device IMEI
   */
  @observable
  imei?: string;

  /**
   * Card payment
   */
  @observable
  @Expose({ name: "card_payment" })
  cardPayment!: boolean;

  /**
   * Depo reference key
   */
  @observable
  @Expose({ name: "network_partner" })
  networkPartner!: number;

  /**
   * Parent id of parcel shop network partner
   */
  @observable
  @Expose({ name: "parent_id" })
  parentId?: number;

  @observable
  latitude!: number;

  @observable
  longitude!: number;

  /**
   * DHL express flag
   */
  @observable
  @Expose({ name: "dhl_express" })
  dhlExpress!: boolean;

  @observable
  facility?: string;

  @observable
  @Expose({ name: "route_code" })
  routeCode?: string;

  @observable
  @Expose({ name: "service_area" })
  serviceArea?: string;

  @observable
  @Expose({ name: "service_point_id" })
  servicePointId?: string;

  @observable
  capacity!: number;

  @observable
  @Expose({ name: "max_daily_capacity" })
  maxDailyCapacity!: number;

  @observable
  @Expose({ name: "capacity_watcher" })
  capacityWatcher!: boolean;

  @observable
  @Expose({ name: "vacation_watcher" })
  vacationWatcher!: boolean;

  /**
   * Is active on finder and in eshops?
   */
  @observable
  @Expose({ name: "active_finder" })
  activeFinder!: boolean;

  /**
   * Opening hours - always open flag
   */
  @observable
  @Expose({ name: "always_open" })
  alwaysOpen?: boolean;

  /**
   * Business state
   */
  @observable
  @Expose({ name: "business_state" })
  businessState?: number;

  /**
   * Contract ends at
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "contract_ends_at" })
  contractEndsAt?: Date;

  /**
   * Contract sign date
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "contract_sign_date" })
  contractSignDate?: Date;

  /**
   * Contract termination reason
   */
  @observable
  @Expose({ name: "termination_reason" })
  terminationReason?: number;

  /**
   * Capacity management
   */
  @observable
  @Expose({ name: "enforce_sizes" })
  enforceSizes!: boolean;

  /**
   * Accepted size
   */
  @observable
  @Expose({ name: "accepted_size" })
  acceptedSize!: string[];

  @observable
  @Type(() => AddressDto)
  addresses!: AddressDto[];

  @observable
  @Type(() => ContactDto)
  contacts!: ContactDto[];

  @observable
  @Type(() => OpeningHourDto)
  @Expose({ name: "opening_hours" })
  openingHours!: OpeningHourDto[];

  @observable
  @Type(() => VacationDto)
  vacations!: VacationDto[];

  /**
   * Tribal service point indicator
   */
  @observable
  @Expose({ name: "tribal_service_point" })
  tribalServicePoint!: boolean;

  @observable
  @Type(() => RecurringPaymentDto)
  @Expose({ name: "recurring_payments" })
  recurringPayments?: RecurringPaymentDto[];

  @observable
  @Type(() => CreateCompetitionInPsDto)
  competitions?: CreateCompetitionInPsDto[];

  @observable
  @Type(() => AttachToPsDto)
  attachments?: AttachToPsDto[];

  /**
   * Terminal ID
   */
  @observable
  tid?: string;

  static ValidationRules = {
    potentialPsId: { number: true },
    subjectId: { required: true, number: true },
    name: { maxLength: 250, required: true },
    subname: { maxLength: 250 },
    status: { required: true, number: true },
    typeId: { required: true, number: true },
    businessTypeId: { number: true },
    salesmanId: { required: true, number: true },
    depoId: { required: true, number: true },
    categoryId: { required: true, number: true },
    cardPayment: { required: true },
    networkPartner: { required: true, number: true },
    parentId: { number: true },
    latitude: { required: true, number: true },
    longitude: { required: true, number: true },
    dhlExpress: { required: true },
    capacity: { required: true, number: true },
    maxDailyCapacity: { required: true, number: true },
    capacityWatcher: { required: true },
    vacationWatcher: { required: true },
    activeFinder: { required: true },
    businessState: { number: true },
    terminationReason: { number: true },
    enforceSizes: { required: true },
    acceptedSize: { required: true },
    addresses: { required: true },
    contacts: { required: true },
    openingHours: { required: true },
    vacations: { required: true },
    tribalServicePoint: { required: true },
  };
}
