import { registerView, View, ViewComponent } from "@frui.ts/views";
import HeaderRow from "controls/headerRow";
import { observer } from "mobx-react-lite";
import React from "react";
import ShopInvoicingViewModel from "viewModels/shops/detail/invoicing/shopInvoicingViewModel";
import BoxInvoicingViewModel from "viewModels/shops/detail/invoicing/boxInvoicingViewModel";
import ParcelShopSubheader from "../partials/parcelShopSubheader";

const invoicingView: ViewComponent<ShopInvoicingViewModel | BoxInvoicingViewModel> = observer(({ vm }) => {
  return (
    <>
      <HeaderRow title={vm.name} />

      <ParcelShopSubheader shop={vm.parcelShop} localization={vm.localization} />

      {vm.children.map(x => (
        <View key={x.name} vm={x} />
      ))}
    </>
  );
});

export default registerView(invoicingView, ShopInvoicingViewModel);
registerView(invoicingView, BoxInvoicingViewModel);
