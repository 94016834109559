import { Router } from "@frui.ts/screens";
import BackendConnector from "data/backendConnector";
import ActivitiesRepository from "data/repositories/activitiesRepository";
import AttachmentsRepository from "data/repositories/attachmentsRepository";
import CompetitionsRepository from "data/repositories/competitionsRepository";
import OpportunitiesRepository from "data/repositories/opportunitiesRepository";
import LoginRepository from "data/repositories/loginRepository";
import LogoutRepository from "data/repositories/logoutRepository";
import MessageGroupsRepository from "data/repositories/messageGroupsRepository";
import MessagesRepository from "data/repositories/messagesRepository";
import ParcelShopUsersRepository from "data/repositories/parcelShopUsersRepository";
import PaymentTerminalRepository from "data/repositories/paymentTerminalRepository";
import StatisticsRepository from "data/repositories/statisticsRepository";
import SubjectRepository from "data/repositories/subjectRepository";
import UsersRepository from "data/repositories/usersRepository";
import { Container } from "inversify";
import EventBus from "services/eventBus";
import LocaleProvider from "services/localeProvider";
import UserContext from "services/userContext";
import EnumRepository from "./data/repositories/enumRepository";
import LocalizationRepository from "./data/repositories/localizationRepository";
import MemosRepository from "./data/repositories/memosRepository";
import ParcelBoxRepository from "./data/repositories/parcelBoxRepository";
import ParcelShopRepository from "./data/repositories/parcelShopRepository";
import PotentialRepository from "./data/repositories/potentialRepository";
import SettingsRepository from "./data/repositories/settingsRepository";
import "./di.decorators";
import registerServices from "./di.registry";
import ScanDevicesRepository from "data/repositories/scanDevicesRepository";

export default function createContainer(apiBaseUrl: string) {
  const container = new Container({ skipBaseClassChecks: true });

  const userContext = new UserContext();
  container.bind<UserContext>(UserContext).toConstantValue(userContext);

  const eventBus = new EventBus();
  container.bind<EventBus>(EventBus).toConstantValue(eventBus);
  container.bind(Router).toConstantValue(new Router());

  const backendConnector = new BackendConnector(apiBaseUrl, eventBus);
  container.bind(BackendConnector).toConstantValue(backendConnector);

  // repositories
  container
    .bind<ActivitiesRepository>(ActivitiesRepository)
    .toDynamicValue(() => new ActivitiesRepository(backendConnector.getRequestBuilder, eventBus));
  container
    .bind<LoginRepository>(LoginRepository)
    .toDynamicValue(() => new LoginRepository(backendConnector.getRequestBuilder, eventBus));
  container
    .bind<LogoutRepository>(LogoutRepository)
    .toDynamicValue(() => new LogoutRepository(backendConnector.getRequestBuilder, eventBus));
  container
    .bind<LocalizationRepository>(LocalizationRepository)
    .toDynamicValue(() => new LocalizationRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<UsersRepository>(UsersRepository)
    .toDynamicValue(() => new UsersRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<ParcelShopRepository>(ParcelShopRepository)
    .toDynamicValue(() => new ParcelShopRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<ParcelBoxRepository>(ParcelBoxRepository)
    .toDynamicValue(() => new ParcelBoxRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<PotentialRepository>(PotentialRepository)
    .toDynamicValue(() => new PotentialRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<EnumRepository>(EnumRepository)
    .toDynamicValue(() => new EnumRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<ParcelShopUsersRepository>(ParcelShopUsersRepository)
    .toDynamicValue(() => new ParcelShopUsersRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<MessagesRepository>(MessagesRepository)
    .toDynamicValue(() => new MessagesRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<SubjectRepository>(SubjectRepository)
    .toDynamicValue(() => new SubjectRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<PaymentTerminalRepository>(PaymentTerminalRepository)
    .toDynamicValue(() => new PaymentTerminalRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<ScanDevicesRepository>(ScanDevicesRepository)
    .toDynamicValue(() => new ScanDevicesRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<MessageGroupsRepository>(MessageGroupsRepository)
    .toDynamicValue(() => new MessageGroupsRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<SettingsRepository>(SettingsRepository)
    .toDynamicValue(() => new SettingsRepository(backendConnector.getRequestBuilder, eventBus));
  container
    .bind<StatisticsRepository>(StatisticsRepository)
    .toDynamicValue(() => new StatisticsRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<MemosRepository>(MemosRepository)
    .toDynamicValue(() => new MemosRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<CompetitionsRepository>(CompetitionsRepository)
    .toDynamicValue(() => new CompetitionsRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<AttachmentsRepository>(AttachmentsRepository)
    .toDynamicValue(() => new AttachmentsRepository(backendConnector.getRequestBuilder, eventBus));

  container
    .bind<OpportunitiesRepository>(OpportunitiesRepository)
    .toDynamicValue(() => new OpportunitiesRepository(backendConnector.getRequestBuilder, eventBus));

  // Services
  container.bind<LocaleProvider>(LocaleProvider).to(LocaleProvider).inSingletonScope();

  registerServices(container);

  return container;
}
