import { registerView, View, ViewComponent } from "@frui.ts/views";
import CollapseSection from "controls/collapseSection";
import HeaderRow from "controls/headerRow";
import LoadingOverlay from "controls/loadingOverlay";
import SimpleTable from "controls/simpleTable";
import ReactDOM from "react-dom";
import Table from "controls/table";
import Parcel, { ParcelProduct } from "entities/parcel";
import { observer } from "mobx-react-lite";
import React from "react";
import { Col, Row, Dropdown, NavDropdown } from "react-bootstrap";
import WarehouseViewModel from "viewModels/shops/detail/warehouse/warehouseViewModel";
import ParcelShopSubheader from "../partials/parcelShopSubheader";
import ParcelNumber from "../partials/parcelNumber";
import { ReactComponent as MoreIcon } from "assets/icons/icon-more.svg";
import { ParcelStatus, ParcelPaymentMethod } from "services/warehouseService";

import "./warehouseView.scss";
import { DataTable } from "@frui.ts/dataviews";
import { createColumnsContext, DataColumn } from "controls/tableHelpers";

const getNestedMenuItems = (vm: WarehouseViewModel, parcel: Parcel): React.ReactNode => {
  const { translateGeneral: tg } = vm.localizationService;
  const { cod } = parcel;

  if (cod === null) {
    return (
      <Dropdown.Item key="delivered" onClick={() => vm.confirmChange(parcel, "delivered", ParcelStatus.HANDED_OUT_TO_CUSTOMER)}>
        {tg("parcel_shop.warehouse.events.delivered.action")}
      </Dropdown.Item>
    );
  } else {
    return (
      <NavDropdown
        key="delivered"
        className="nested-nav-items"
        id="for-driver-nav-dropdown"
        title={tg("parcel_shop.warehouse.events.delivered.action")}
        drop="right">
        <NavDropdown.Item
          onClick={() =>
            vm.confirmChange(parcel, "card_payment_method", ParcelStatus.HANDED_OUT_TO_CUSTOMER, ParcelPaymentMethod.BY_CARD)
          }>
          {tg("parcel_shop.warehouse.events.card_payment_method.title")}
        </NavDropdown.Item>
        <NavDropdown.Item
          onClick={() =>
            vm.confirmChange(parcel, "cash_payment_method", ParcelStatus.HANDED_OUT_TO_CUSTOMER, ParcelPaymentMethod.BY_CASH)
          }>
          {tg("parcel_shop.warehouse.events.cash_payment_method.title")}
        </NavDropdown.Item>
      </NavDropdown>
    );
  }
};

const changeStatusButton = (_: any, parcel: Parcel, vm: WarehouseViewModel) => {
  const { translateGeneral: tg } = vm.localizationService;
  // Get sections
  const items: React.ReactNodeArray = [];
  if (parcel.missing) {
    items.push(
      <Dropdown.Item key="found" onClick={() => vm.confirmChange(parcel, "found", ParcelStatus.PARCEL_FOUND)}>
        {tg("parcel_shop.warehouse.events.found.action")}
      </Dropdown.Item>
    );
  } else if (parcel.status === ParcelStatus.DELIVERED_BY_DRIVER) {
    // Delivery
    items.push(
      <Dropdown.Item key="for_driver" onClick={() => vm.confirmChange(parcel, "for_driver", ParcelStatus.REFUSED_DAMAGED)}>
        {tg("parcel_shop.warehouse.events.for_driver.action")}
      </Dropdown.Item>,
      getNestedMenuItems(vm, parcel)
    );
  } else {
    items.push(
      <Dropdown.Item key="via_driver" onClick={() => vm.confirmChange(parcel, "via_driver", ParcelStatus.PICKED_UP_BY_DRIVER)}>
        {tg("parcel_shop.warehouse.events.via_driver.action")}
      </Dropdown.Item>,
      getNestedMenuItems(vm, parcel)
    );
  }

  const portalContainer = document.getElementById("root");

  const popperElement = (
    <Dropdown.Menu
      className="filter-button__popup"
      alignRight={true}
      popperConfig={{
        modifiers: [
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              escapeWithReference: false,
              boundariesElement: "scrollParent",
            },
          },
        ],
      }}>
      {items}
    </Dropdown.Menu>
  );

  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline-secondary" size="sm">
        <MoreIcon />
      </Dropdown.Toggle>

      {portalContainer ? ReactDOM.createPortal(popperElement, portalContainer) : popperElement}
    </Dropdown>
  );
};

const formatParcelNumber = (value: string, tg: (code: string) => string) => (
  <ParcelNumber parcelIdentifier={value} copyString={tg("copy_string")} copiedString={tg("copied_string")} />
);

const getProductName = (value: ParcelProduct, ta: (model: string, name: string) => string) =>
  ta("parcel_product", value === ParcelProduct.DHL ? "DHL" : "PPL");

const buildColumns = (vm: WarehouseViewModel) => {
  const result: DataColumn<Parcel, WarehouseViewModel>[] = [
    {
      property: "extId",
      titleFactory: ({ ta }) => ta("parcel", "ext_id"),
      valueFormatter: x => formatParcelNumber(x.item.extId, x.context.tg),
    },
    {
      property: "parentParcelId",
      cellClassName: "groupedParcel",
      headerClassName: "groupedParcel",
      titleFactory: ({ tg }) => tg("parcel_shop.warehouse.parcel_type"),
      valueFormatter: x => {
        if (x.item.isGrouped) {
          return (
            <>
              {x.context.tg("parcel_shop.warehouse.grouped_parcel")}
              {formatParcelNumber(x.item.parentParcelId!, x.context.tg)}
            </>
          );
        }
        return null;
      },
    },
    {
      property: "product",
      titleFactory: ({ ta }) => ta("parcel", "product"),
      valueFormatter: x => getProductName(x.item.product as ParcelProduct, x.context.ta),
      cellClassName: "product",
      headerClassName: "product",
    },
  ];

  if (vm.canChangeParcelStatus) {
    result.push({
      property: "change_status" as any,
      titleFactory: ({ tg }) => tg("options"),
      valueFormatter: x => changeStatusButton({}, x.item, vm),
      cellClassName: "options",
      headerClassName: "options",
    });
  }

  return result;
};

/* eslint-disable-next-line sonarjs/cognitive-complexity */
const warehouseView: ViewComponent<WarehouseViewModel> = observer(({ vm }) => {
  const { translateAttribute: ta, translateGeneral: tg } = vm.localizationService;
  const accessPointName = tg(`parcel_shop.warehouse.${vm.isParcelBox ? "box" : "shop"}`);
  const columns = buildColumns(vm);
  const columnsContext = createColumnsContext(vm);

  return (
    <>
      <HeaderRow title={vm.name} />

      <ParcelShopSubheader shop={vm.parcelShop} localization={vm.localizationService} />

      <HeaderRow title={`${tg("parcel_shop.warehouse.utilization")} ${accessPointName}`} level={3} />

      <Row>
        <Col md={vm.isParcelBox ? 6 : 12}>
          <SimpleTable>
            <thead>
              <tr>
                <th className="text-center">{ta("parcel_shop", "accepted_size")}</th>
                <th className="text-center">{tg("parcel_shop.warehouse.current_utilization")}</th>
                {!vm.isParcelBox && <th className="text-center">{ta("parcel_shop", "max_daily_capacity")}</th>}
                <th className="text-center">{ta("parcel_shop", "capacity")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">{vm.parcelShop.acceptedSize?.join(", ")}</td>
                <td className="text-center">{vm.warehouse?.total}</td>
                {!vm.isParcelBox && <td className="text-center">{vm.parcelShop.max_daily_capacity}</td>}
                <td className="text-center">{vm.parcelShop.capacity}</td>
              </tr>
            </tbody>
          </SimpleTable>
        </Col>
      </Row>

      <Row>
        {!vm.isParcelBox && (
          <Col lg={{ order: 2 }}>
            <HeaderRow title={tg("parcel_shop.warehouse.dispatched_cod")} level={3} />

            <dl className="list-large-value">
              <dt>{tg("parcel_shop.warehouse.total_in_cash")}</dt>
              {vm.cashSums.map(x => (
                <dd key={x.currency}>
                  {vm.localizationService.formatNumber(x.total)} {x.currency}
                </dd>
              ))}
            </dl>

            <Table
              data={(vm.cash || []).slice()}
              keyField="id"
              classes="parcels"
              columns={[
                {
                  dataField: "extId",
                  text: ta("parcel", "ext_id"),
                  formatter: v => formatParcelNumber(v, tg),
                  title: v => formatParcelNumber(v, tg),
                },
                {
                  dataField: "cod",
                  text: ta("parcel", "cod"),
                  align: "right",
                  headerAlign: "right",
                  formatter: (value, entity) => `${vm.localizationService.formatNumber(value)} ${entity.currency}`,
                  title: (value, entity) => `${vm.localizationService.formatNumber(value)} ${entity.currency}`,
                },
              ]}
            />
          </Col>
        )}
        <Col lg={vm.isParcelBox ? 12 : 8}>
          <HeaderRow title={`${tg("parcel_shop.warehouse.warehouse_content")} ${accessPointName}`} level={3} />

          <CollapseSection title={`${tg("parcel_shop.warehouse.for_customer")} (${vm.warehouse?.forCustomer?.length || 0})`}>
            <div className="react-bootstrap-table">
              <DataTable
                className="table custom-table parcels table-striped"
                itemKey="id"
                items={vm.warehouse?.forCustomer || []}
                columns={columns}
                context={columnsContext}
              />
            </div>
          </CollapseSection>

          <CollapseSection title={`${tg("parcel_shop.warehouse.for_driver")} (${vm.warehouse?.forDriver?.length || 0})`}>
            <div className="react-bootstrap-table">
              <DataTable
                className="table custom-table parcels table-striped"
                itemKey="id"
                items={vm.warehouse?.forDriver || []}
                columns={columns}
                context={columnsContext}
              />
            </div>
          </CollapseSection>

          {vm.isParcelBox && (
            <CollapseSection title={`${tg("parcel_shop.warehouse.stuck")} (${vm.warehouse?.stuck?.length || 0})`}>
              <div className="react-bootstrap-table">
                <DataTable
                  className="table custom-table parcels table-striped"
                  itemKey="id"
                  items={vm.warehouse?.stuck || []}
                  columns={columns}
                  context={columnsContext}
                />
              </div>
            </CollapseSection>
          )}

          {!vm.isParcelBox && (
            <CollapseSection title={`${tg("parcel_shop.warehouse.missing")} (${vm.warehouse?.missing?.length || 0})`}>
              <div className="react-bootstrap-table">
                <DataTable
                  className="table custom-table parcels table-striped"
                  itemKey="id"
                  items={(vm.warehouse?.missing || []).slice()}
                  columns={columns}
                  context={columnsContext}
                />
              </div>
            </CollapseSection>
          )}
        </Col>
      </Row>
      <View vm={vm.stockTakingViewModel} />
      <LoadingOverlay loading={vm.busyWatcher.isBusy} />
    </>
  );
});

export default registerView(warehouseView, WarehouseViewModel);
