import { ViewComponent } from "@frui.ts/views";
import BoxDetailViewModel from "../../../viewModels/shops/detail/boxDetailViewModel";
import { observer } from "mobx-react-lite";
import React from "react";
import ShopDetailViewModel from "../../../viewModels/shops/detail/shopDetailViewModel";
import PotentialDetailViewModel from "../../../viewModels/shops/detail/potentialDetailViewModel";

const OpeningHours: ViewComponent<ShopDetailViewModel | BoxDetailViewModel | PotentialDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute: ta } = vm.localizationService;
  return (
    <>
      <h3>{tg("parcel_shop.opening_hours")}</h3>
      {vm.item.shop.alwaysOpen ? (
        <span>{ta("parcel_box", "always_open")}</span>
      ) : (
        <>
          <table className="table-form">
            <thead>
              <tr>
                <th></th>
                <th>{tg("morning")}</th>
                <th>{tg("afternoon")}</th>
              </tr>
            </thead>
            <tbody>
              {vm.openingHours.map(h => (
                <tr key={h.dayName}>
                  <th>{h.dayName}</th>
                  <td>{h.morning}</td>
                  <td>{h.afternoon}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
});

export default OpeningHours;
