import { DetailViewModel } from "@frui.ts/datascreens";

export default abstract class EditableDetailViewModelBase<TOrignalEntity, TEditedItem> extends DetailViewModel<TEditedItem> {
  get isCreating() {
    return this.originalItem === undefined;
  }

  // originalItem might be a simplified type than the actually edited detail item
  constructor(protected originalItem: TOrignalEntity | undefined) {
    super();
  }
}
