import { registerView, ViewComponent } from "@frui.ts/views";
import HeaderRow from "controls/headerRow";
import SimpleTable from "controls/simpleTable";
import { observer } from "mobx-react-lite";
import React from "react";
import RecurringPaymentsViewModel from "viewModels/shops/detail/invoicing/recurringPaymentsViewModel";

import styles from "./recurringPayments.module.scss";

const recurringPaymentsView: ViewComponent<RecurringPaymentsViewModel> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral: tg, formatDate } = vm.localization;
  const taBox = (code: string) => translateAttribute("parcel_box", code);
  const taPayment = (code: string) => translateAttribute("recurring_payment", code);

  return (
    <>
      <SimpleTable>
        <thead className={styles.paymentTableHeader}>
          <tr>
            <th>{tg("parcel_box.recurring_payments.payout_cycle")}</th>
            <th>{taPayment("rent_price")}</th>
            <th>{taPayment("modified_by")}</th>
            <th>{taPayment("month")}</th>
            <th>{taPayment("created_at")}</th>
          </tr>
        </thead>
        <tbody>
          {vm.rentHistory.map(item => (
            <tr key={`rent-${item.id}`}>
              <td>{vm.getInvoicingCycleName(item)}</td>
              <td className={styles.price}>{`${item.price} ${tg("currency_code")}`}</td>
              <td>{(item as any)?.modifier?.email}</td>
              <td>{vm.getValidityName(item)}</td>
              <td>{formatDate(item.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </SimpleTable>

      <HeaderRow title={taBox("deposit_amount")} level={3} />
      <SimpleTable>
        <thead className={styles.paymentTableHeader}>
          <tr>
            <th>{tg("parcel_box.recurring_payments.payout_cycle")}</th>
            <th>{taPayment("deposit_amount")}</th>
            <th>{taPayment("modified_by")}</th>
            <th>{taPayment("month")}</th>
            <th>{taPayment("created_at")}</th>
          </tr>
        </thead>
        <tbody>
          {vm.depositHistory.map(item => (
            <tr key={`deposit-${item.id}`}>
              <td>{vm.getInvoicingCycleName(item)}</td>
              <td className={styles.price}>{`${item.price} ${tg("currency_code")}`}</td>
              <td>{(item as any)?.modifier?.email}</td>
              <td>{vm.getValidityName(item)}</td>
              <td>{formatDate(item.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </SimpleTable>
    </>
  );
});

export default registerView(recurringPaymentsView, RecurringPaymentsViewModel);
