import { IPagingFilter, PagedQueryResult, SortingDirection } from "@frui.ts/data";
import { IHasDirtyWatcher } from "@frui.ts/dirtycheck";
import { IHasValidation } from "@frui.ts/validation";
import EventBus, { Events } from "services/eventBus";
import DeserializingRequestBuilder from "./deserializingRequestBuilder";
import extractErrorMessage from "./extractErrorMessage";
import IListRequestParams, { IListRequestFilters } from "./listRequest";
import IListResponse from "./listResponse";

export abstract class RepositoryBase {
  constructor(protected apiFactory: () => DeserializingRequestBuilder, protected eventBus: EventBus) {}

  /**
   * Provides automated handling of errors and event publishing when calling API
   * @param action Action to be called on the API
   * @param eventName Name of the event to be published on succesfull API call
   * @param args Arguments of the event to be published on succesfull API call
   */
  protected callApi<T>(action: (api: DeserializingRequestBuilder) => Promise<T>, eventName?: string, args?: any) {
    const promise = action(this.apiFactory());

    promise.catch(error => {
      const message = extractErrorMessage(error);
      this.eventBus.publish(Events.General.ServerError, message);
    });

    if (eventName) {
      promise.then(
        () => void this.eventBus.publish(eventName, args),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      );
    }

    return promise;
  }

  // TODO remove when all entities are generated
  /** @deprecated */
  protected static createListRequestParams(
    inputFilter: IListRequestFilters | undefined,
    paging: IPagingFilter
  ): IListRequestParams {
    const result: IListRequestParams = {
      limit: paging.limit,
      offset: paging.offset || 0,
      sort: paging.sortColumn
        ? `${paging.sortColumn}:${paging.sortDirection === SortingDirection.Descending ? "desc" : "asc"}`
        : undefined,
    };

    if (inputFilter) {
      result.filters = {};

      for (const key in inputFilter) {
        if (Object.prototype.hasOwnProperty.call(inputFilter, key)) {
          const value = inputFilter[key] as unknown;
          // @TODO Deal better with empty values
          if (value !== undefined && value !== null && value !== "") {
            result.filters[`${key}`] = value;
          }
        }
      }
    }

    return result;
  }

  // TODO remove when all entities are generated
  /** @deprecated */
  protected static convertListResponse<T>(response: IListResponse<T>): PagedQueryResult<T> {
    return [response.items, { limit: response.limit, offset: response.offset, totalItems: response.total }];
  }

  // TODO remove when all entities are generated
  /** @deprecated */
  protected static OmitValidationProperty<T>(input: T): Omit<T, "__validation" | "__dirtycheck"> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __validation, __dirtycheck, ...rest } = input as IHasDirtyWatcher<T> & IHasValidation<T> & T;
    return rest;
  }

  // TODO remove when all entities are generated
  /** @deprecated */
  protected static OmitValidationAndIdProperty<T>(input: T): Omit<T, "__validation" | "__dirtycheck" | "id"> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __validation, __dirtycheck, id, ...rest } = input as IHasDirtyWatcher<T> & IHasValidation<T> & { id: unknown } & T;
    return rest;
  }
}
