import { FetchApiConnector, handleErrorStatusMiddleware } from "@frui.ts/apiclient";
import { bound } from "@frui.ts/helpers";
import EventBus, { Events } from "services/eventBus";
import DeserializingRequestBuilder from "./deserializingRequestBuilder";
import { serializeEntity } from "./helpers";

export default class BackendConnector {
  constructor(private baseUrl: string, private eventBus: EventBus) {}

  @bound
  getRequestBuilder() {
    const apiConnector = new FetchApiConnector({
      jsonSerializer: serializeEntity,
      middleware: this.middleware,
    });

    // Prepare request headers
    const requestHeaders: HeadersInit = {};

    if (process.env.REACT_APP_VERSION) {
      requestHeaders["Accept-Version"] = process.env.REACT_APP_VERSION;
    }

    const params: RequestInit = {
      headers: requestHeaders,
    };

    return new DeserializingRequestBuilder(apiConnector, this.baseUrl, params);
  }

  @bound
  private middleware(response: Response) {
    return handleErrorStatusMiddleware(this.loggedOutMiddleware(response));
  }

  private loggedOutMiddleware(response: Response) {
    if (response.status === 401) {
      this.eventBus.publish(Events.General.Unauthorized);
    }

    return response;
  }
}
