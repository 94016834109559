import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import UsersViewModel from "viewModels/users/usersViewModel";
import UserList from "./list";

const usersView: ViewComponent<UsersViewModel> = observer(({ vm }) => {
  return vm.activeChild ? <View vm={vm.activeChild} /> : <UserList vm={vm} />;
});

export default registerView(usersView, UsersViewModel);
