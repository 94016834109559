import { DropdownSelect } from "@frui.ts/bootstrap";
import { FilteredListViewModel } from "@frui.ts/datascreens";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Pager from "./pager";
import { IPagingFilter, IPagingInfo } from "@frui.ts/data";
import { action } from "mobx";

export interface TablePagerProps {
  vm?: FilteredListViewModel<any, any, any>;
  appliedPaging?: IPagingInfo;
  pagingFilter?: IPagingFilter;
  onChanged?: () => any;
}

export default function TablePager({ vm, appliedPaging, pagingFilter, onChanged }: TablePagerProps) {
  const onPageSizeHandler = action(() => {
    const filter = pagingFilter ?? vm?.pagingFilter;
    if (filter) {
      filter.offset = 0;
    }
    (onChanged ?? vm?.loadData)?.();
  });

  return (
    <Row>
      <Col md={4}>
        <DropdownSelect
          target={pagingFilter ?? vm?.pagingFilter}
          property="limit"
          items={[10, 30, 50]}
          keyProperty=""
          textProperty=""
          variant="outline-secondary"
          onChanged={onPageSizeHandler}
        />
      </Col>
      <Col md={8}>
        <Pager
          paging={appliedPaging ?? vm?.currentPaging}
          filter={pagingFilter ?? vm?.pagingFilter}
          onPageChanged={onChanged ?? vm?.loadData}
        />
      </Col>
    </Row>
  );
}
