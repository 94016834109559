import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import { merge } from "lodash";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class User {
  /**
   * id
   */
  id!: number;

  @observable
  email!: string;

  /**
   * First name
   */
  @observable
  @Expose({ name: "first_name" })
  firstName?: string;

  /**
   * Last name
   */
  @observable
  @Expose({ name: "last_name" })
  lastName?: string;

  /**
   * IDs of depos
   */
  @observable
  @Expose({ name: "depo_ids" })
  depoIds?: number[];

  /**
   * MyPs Version
   */
  @observable
  @Expose({ name: "myps_version" })
  mypsVersion!: string;

  /**
   * IDs of categories
   */
  @observable
  @Expose({ name: "ps_category_ids" })
  psCategoryIds?: number[];

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  /**
   * Is user deleted?
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "deleted_at" })
  deletedAt?: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  /**
   * Role
   */
  @observable
  role!: number;

  /**
   * Parcel shop id
   */
  @observable
  @Expose({ name: "ps_id" })
  psId?: number;

  /**
   * Firebase token
   */
  @observable
  @Expose({ name: "firebase_token" })
  firebaseToken?: string;

  static ValidationRules = {
    id: { required: true, number: true },
    email: { required: true },
    mypsVersion: { required: true },
    createdAt: { required: true },
    updatedAt: { required: true },
    role: { required: true, number: true },
    psId: { number: true },
  };
}

merge(User.ValidationRules, {
  email: {
    isEmail: true,
  },
});
