import { Select, ValidationError } from "@frui.ts/bootstrap";
import { getValidationMessage } from "@frui.ts/validation";
import DateInput from "controls/dateInput";
import { TimeInput } from "controls/timeInput";
import TreeList from "controls/treeList";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { StringInput } from "controls/stringInput";
import ActivityDetailViewModel from "viewModels/shops/detail/activity/activityDetailViewModel";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";

const activitiesDetailView: ViewComponent<ActivityDetailViewModel> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral } = vm.localizationService;
  const ta = (code: string) => translateAttribute("activity", code);

  return (
    <Modal centered={true} show={true} onHide={vm.requestClose}>
      <Form onSubmit={preventDefault(vm.save)}>
        <Modal.Header closeButton>
          <Modal.Title>{translateAttribute("additional_information", vm.isCreating ? "new_activity" : "activity")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} md={8} className="required">
              <Form.Label>{translateAttribute("opportunity", "ps_id")}</Form.Label>
              <Select
                target={vm.item}
                property="psId"
                items={vm.allowedShops || []}
                mode="key"
                textProperty="label"
                keyProperty="value"
                isNumeric={true}
                disabled={!vm.shopSelectionActive}
              />
            </Form.Group>
          </Row>
          <Row>
            {!vm.isCreating && (
              <Form.Group as={Col} md={4} className="required">
                <Form.Label>{ta("state")}</Form.Label>
                <Select
                  target={vm.item}
                  property="state"
                  items={vm.activityStatuses || []}
                  mode="key"
                  textProperty="label"
                  keyProperty="value"
                  isNumeric={true}
                  disabled={!vm.allowedToModifyActivity}
                />
              </Form.Group>
            )}
            <Form.Group as={Col} md={6} className="required">
              <Form.Label>{ta("assignees")}</Form.Label>
              <TreeList
                options={toJS(vm.possibleUsers)}
                value={vm.selectedUsers}
                onChange={value => vm.onSelectUser(value as any)}
                multiple={true}
                error={!!getValidationMessage(vm.item, "assignees")}
                disabled={!vm.isCreating || vm.isUserSelectDisabled}
              />
              <ValidationError target={vm.item} property="assignees" />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={4} className="required">
              <Form.Label>{ta("date")}</Form.Label>
              <DateInput target={vm.item} property="date" disabled={!vm.allowedToModifyActivity} />
            </Form.Group>
            <Form.Group as={Col} md={4} className="required">
              <Form.Label>{ta("time")}</Form.Label>
              <TimeInput target={vm.item} property="time" disabled={!vm.allowedToModifyActivity} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={4} className="required">
              <Form.Label>{ta("type")}</Form.Label>
              <Select
                target={vm.item}
                property="type"
                items={vm.activityTypes || []}
                mode="key"
                textProperty="label"
                keyProperty="value"
                isNumeric={true}
                disabled={!vm.isCreating}
              />
            </Form.Group>
            <Form.Group as={Col} md={4} className="required">
              <Form.Label>{ta("category_id")}</Form.Label>
              <Select
                target={vm.item}
                property="category"
                items={vm.activityCategories || []}
                mode="key"
                textProperty="label"
                keyProperty="value"
                isNumeric={true}
                disabled={!vm.isCreating}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={12}>
              <Form.Label>{ta("note")}</Form.Label>
              <StringInput trim={true} target={vm.item} property="comment" disabled={!vm.allowedToChangeNote} />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button type="submit" disabled={vm.busyWatcher.isBusy || !vm.canSave}>
            {translateGeneral("confirm_dialog.confirm")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});
export default registerView(activitiesDetailView, ActivityDetailViewModel);
