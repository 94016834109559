import { RepositoryBase } from "../repositoryBase";
import { IPagingFilter } from "@frui.ts/data";
import EditableEnum from "models/enumerations/editableEnum";
import CustomEnumDto from "entities/customEnumDto";
import EditableEnumCountDto from "entities/editableEnumCountDto";
import { EditableEnumType } from "../../services/enum";
import Scanner from "entities/scanner";
import ScannerDto from "entities/scannerDto";
import { models } from "models/shops/interface";

export default class EnumRepository extends RepositoryBase {
  fetchEnums() {
    return this.callApi(api => {
      return api.all("enums").get<models.EnumContainer>();
    });
  }

  listEditableEnums() {
    return this.callApi(api => {
      return api.all("enums").all("editable").get<EditableEnumCountDto[]>();
    });
  }

  getAll(enumType: EditableEnumType, paging: IPagingFilter, excludeDeleted?: boolean) {
    return this.callApi(api =>
      api.all("enums").all(enumType).getPagedEntities<EditableEnum>(EditableEnum, paging, { exclude_deleted: excludeDeleted })
    );
  }

  getAllScanners(enumType: EditableEnumType, paging: IPagingFilter, excludeDeleted?: boolean) {
    return this.callApi(api =>
      api.all("enums").all(enumType).getPagedEntities<Scanner>(Scanner, paging, { exclude_deleted: excludeDeleted })
    );
  }

  create(enumType: string, item: CustomEnumDto) {
    return this.callApi(api => api.all("enums").all(enumType).postEntity<EditableEnum>(item, EditableEnum));
  }

  createScanner(enumType: string, item: ScannerDto) {
    return this.callApi(api => api.all("enums").all(enumType).postEntity<Scanner>(item, Scanner));
  }

  save(enumType: string, item: EditableEnum) {
    return this.callApi(api =>
      api.all("enums").one(enumType, item.id).putEntity<EditableEnum>({ value: item.value }, EditableEnum)
    );
  }

  saveScanner(enumType: string, item: Scanner) {
    return this.callApi(api =>
      api
        .all("enums")
        .one(enumType, item.id)
        .putEntity<Scanner>(
          { daily_amortization_value: item.dailyAmortizationValue, device_type: item.deviceType, active: item.active },
          Scanner
        )
    );
  }

  delete(enumType: string, item: EditableEnum | Scanner) {
    return this.callApi(api => api.all("enums").one(enumType, item.id).delete({}));
  }
}
