import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase } from "@frui.ts/screens";
import StatisticsRepository from "data/repositories/statisticsRepository";
import GraphsDto from "entities/graphsDto";
import { OpportunityStatus } from "entities/opportunity";
import { interfaces } from "inversify";
import { observable, runInAction } from "mobx";
import LocalizationService from "services/localizationService";
import UserContext from "services/userContext";
import { snakeCaseToPascalCase } from "utils/helpers";
import OpportunitiesViewModel from "viewModels/opportunities/opportunitiesViewModel";
import RootViewModel from "viewModels/rootViewModel";
import DashboardViewModel from "./dashboardViewModel";

export default class StatisticsViewModel extends ScreenBase {
  busyWatcher = new BusyWatcher();
  @observable stats: GraphsDto;

  constructor(
    private userContext: UserContext,
    private repository: StatisticsRepository,
    public localizationService: LocalizationService
  ) {
    super();
    this.name = "statistics";
    this.navigationName = "statistics";
  }

  protected async onInitialize() {
    void this.loadData();
    return super.onInitialize();
  }

  get userRole() {
    return this.userContext.userRoleId!;
  }

  get userName() {
    return this.userContext.name ?? this.userContext.userEmail!;
  }

  @bound
  private async loadData() {
    const response = await this.repository.getStatistics();
    if (response) {
      runInAction(() => {
        this.stats = response;
      });
    }
  }

  async openOpportunities(status: OpportunityStatus) {
    const dashboardVM = this.parent as DashboardViewModel;
    const rootVM = dashboardVM.parent as RootViewModel;
    const opportunitiesVM = rootVM.children?.find(
      (vm: ScreenBase) => vm.navigationName == "opportunities"
    ) as OpportunitiesViewModel;

    opportunitiesVM.setFilterStatus(status);
    await rootVM.tryActivateChild(opportunitiesVM);
  }

  clickHandler = (_event: React.MouseEvent<HTMLElement>, status: OpportunityStatus) => {
    void this.openOpportunities(status);
  };

  getOpportunityStatusByName(statusName: string) {
    return OpportunityStatus[snakeCaseToPascalCase(statusName) as keyof typeof OpportunityStatus];
  }

  static Factory({ container }: interfaces.Context) {
    return () =>
      new StatisticsViewModel(
        container.get(UserContext),
        container.get(StatisticsRepository),
        container.get(LocalizationService)
      );
  }
}
