import { Type } from "class-transformer";
import ListAttachmentDto from "entities/listAttachmentDto";
import RecurringPaymentStoppage from "entities/recurringPaymentStoppage";
import { computed, observable } from "mobx";
import { EntityValidationRules } from "services/validation";
import SimpleSubjectDto from "entities/simpleSubjectDto";
import NetworkStatusType from "./networkStatusType";
import ParcelShopStatus from "./parcelShopStatus";
import ListActivityDto from "entities/listActivityDto";
import ListCompetitionDto from "entities/listCompetitionDto";
import Opportunity from "entities/opportunity";

export default class ParcelShop {
  id = -1;

  @observable
  @Type(() => SimpleSubjectDto)
  subject: SimpleSubjectDto;

  name: string;
  subname: string;
  description: string;
  description_crm: string;
  type_id: number;
  salesman_id: number;
  depo_id: number;
  status: ParcelShopStatus;
  latitude: number;
  longitude: number;
  cust_id: string;
  ext_id: number;

  network_partner: NetworkStatusType;
  parent_id: number;
  child_count: number;

  dhl_ps_id: number;
  dhl_ps_id_zip: string;
  dhl_express: boolean;
  facility: string;
  route_code: string;
  service_area: string;
  service_point_id: string;
  capacity: number;
  max_daily_capacity: number;
  enforce_sizes: boolean;
  active_finder: boolean;

  card_payment: boolean;
  tid?: string;

  @observable
  payment_terminals?: {
    id: number;
    terminal_id: string;
    terminal_sn: string;
    status: number;
  }[];

  @observable
  scan_devices?: {
    id: number;
    imei: string;
    device_type_id: number;
    price: number;
  }[];

  capacity_watcher: boolean;
  vacation_watcher: boolean;
  capacity_watcher_active: boolean;
  vacation_watcher_active: boolean;
  tribal_service_point: boolean;

  always_open: boolean;
  fm_reward: number;
  lm_reward: number;
  lmcod_reward: number;

  @observable
  parcelBox?: {
    id: number;
    state: number;
    terminal_tid?: string;
    terminal_sn?: string;
    machine_sn: string;
    icp?: string;
  };

  category_id?: number;
  inherit?: number;
  active_recurring_payment_stoppages: RecurringPaymentStoppage[];
  export_to_k2: boolean;

  @observable
  @Type(() => String)
  accepted_size: string[];

  @observable
  @Type(() => Opportunity)
  opportunities!: Opportunity[];

  @observable
  @Type(() => ListActivityDto)
  activities?: ListActivityDto[];

  @observable
  @Type(() => ListCompetitionDto)
  competitions?: ListCompetitionDto[];

  @observable
  @Type(() => ListAttachmentDto)
  attachments?: ListAttachmentDto[];

  @observable
  @Type(() => Date)
  contract_sign_date?: Date;

  @observable
  @Type(() => Date)
  contract_ends_at?: Date;

  @observable business_state?: number;

  @observable termination_reason?: number;

  @observable business_type_id?: number;

  @computed
  get businessTypeId() {
    return this.business_type_id;
  }

  @computed
  get terminationReason() {
    return this.termination_reason;
  }

  @computed
  get businessState() {
    return this.business_state;
  }

  @computed
  get contractEndsAt() {
    return this.contract_ends_at;
  }

  @computed
  get contractSignDate() {
    return this.contract_sign_date;
  }

  @computed
  get alwaysOpen() {
    return this.always_open;
  }

  set alwaysOpen(alwaysOpen) {
    this.always_open = alwaysOpen;
  }

  @computed
  get descriptionCrm() {
    return this.description_crm;
  }

  set descriptionCrm(descriptionCrm) {
    this.description_crm = descriptionCrm;
  }

  @computed
  get typeId() {
    return this.type_id;
  }

  set typeId(typeId) {
    this.type_id = typeId;
  }

  @computed
  get salesmanId() {
    return this.salesman_id;
  }

  set salesmanId(salesmanId) {
    this.salesman_id = salesmanId;
  }

  @computed
  get depoId() {
    return this.depo_id;
  }

  set depoId(depoId) {
    this.depo_id = depoId;
  }

  @computed
  get maxDailyCapacity() {
    return this.max_daily_capacity;
  }

  set maxDailyCapacity(maxDailyCapacity) {
    this.max_daily_capacity = maxDailyCapacity;
  }

  @computed
  get enforceSizes() {
    return this.enforce_sizes;
  }

  set enforceSizes(enforceSizes) {
    this.enforce_sizes = enforceSizes;
  }

  @computed
  get acceptedSize() {
    return this.accepted_size;
  }

  set acceptedSize(acceptedSize) {
    this.accepted_size = acceptedSize;
  }
}

export class ParcelShopFilter {
  cust_id: string;
  dhl_ps_id_zip: string;
  ic: string;
  name: string;
  salesman_id?: number;
  depo_id?: number;
  depo_ids?: number[];

  type_ids?: number[];
  status?: number[];
  states?: number[];

  contact_phone: string;
  contact_email: string;
  contact_name: string;

  ps_category_ids?: number[];

  static ValidationRules: EntityValidationRules<ParcelShopFilter> = {};
}
