import { IPagingFilter } from "@frui.ts/data";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ConductorSingleChild, IChild, IScreen, watchBusy } from "@frui.ts/screens";
import { action, observable, runInAction } from "mobx";
import { EventSubscription } from "services/eventBus";

export default abstract class LazyListViewModelBase<
  TEntity,
  TDetail extends IChild<any> & IScreen
> extends ConductorSingleChild<TDetail> {
  busyWatcher = new BusyWatcher();
  protected eventSubscriptions = [] as EventSubscription[];

  protected pageSize = 20;
  @observable.shallow items: TEntity[] = [];
  @observable canLoadMore = true;

  onInitialize() {
    return this.loadMore();
  }

  @bound
  @watchBusy
  async loadMore() {
    const paging = {
      offset: this.items.length,
      limit: this.pageSize,
    } as IPagingFilter;

    const data = await this.loadData(paging);

    runInAction(() => {
      if (this.items.length > paging.offset) {
        this.items.splice(paging.offset);
      }

      this.items.push(...data);
      this.canLoadMore = data.length === paging.limit;
    });
  }

  @action.bound
  @watchBusy
  reload() {
    this.items.length = 0;
    return this.loadMore();
  }

  protected abstract loadData(paging: IPagingFilter): Promise<TEntity[]>;

  protected onDeactivate(close: boolean) {
    if (close) {
      const toUnsubscribe = this.eventSubscriptions;
      this.eventSubscriptions = [];
      toUnsubscribe.forEach(x => x.unsubscribe());
    }

    return super.onDeactivate(close);
  }

  protected findNavigationChild(navigationName: string | undefined) {
    return undefined;
  }
}
