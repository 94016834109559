import { ViewComponent } from "@frui.ts/views";
import DataTable from "controls/dataTable";
import HeaderRow from "controls/headerRow";
import PagingInfo from "controls/pagingInfo";
import { observer } from "mobx-react-lite";
import React from "react";
import iconDelete from "assets/icons/icon-delete.svg";
import iconEdit from "assets/icons/icon-edit.svg";
import { Button, ButtonToolbar } from "react-bootstrap";
import MessageGroupsViewModel from "viewModels/messageGroups/messageGroupsViewModel";

const MessageGroupList: ViewComponent<MessageGroupsViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute, translateModel } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("message_group", attribute);
  };

  return (
    <>
      <HeaderRow title={translateModel("message_group", 3)}>
        <ButtonToolbar className="justify-content-end">
          <Button variant="primary" onClick={vm.add}>
            {tg("message_group.add")}
          </Button>
        </ButtonToolbar>
      </HeaderRow>

      <PagingInfo paging={vm.currentPaging} modelName="result" localization={vm.localizationService} />

      <DataTable
        columns={[
          {
            dataField: "name",
            text: ta("name"),
            sort: true,
            title: true,
          },
          {
            dataField: "id",
            isDummyField: true,
            text: ta("edit"),
            headerStyle: {
              width: "100px",
              textAlign: "center",
            },
            align: "center",
            formatter: (cell, row) => (
              <Button size="sm" variant="outline-secondary" className="noRowClick button-icon" onClick={() => vm.openDetail(row)}>
                <img src={iconEdit} alt={tg("user.update")} />
              </Button>
            ),
          },
          {
            dataField: "delete" as any,
            isDummyField: true,
            text: ta("delete"),
            headerStyle: {
              width: "100px",
              textAlign: "center",
            },
            align: "center",
            formatter: (cell, row) => (
              <Button size="sm" variant="outline-danger" className="noRowClick button-icon" onClick={() => vm.remove(row)}>
                <img src={iconDelete} alt={tg("user.delete")} />
              </Button>
            ),
          },
        ]}
        vm={vm}
        clickable={false}
      />
    </>
  );
});

export default MessageGroupList;
