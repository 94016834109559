import { Expose, Type } from "class-transformer";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class MemoDetailDto {
  /**
   * ID
   */
  id!: number;

  /**
   * Due date
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "due_date" })
  dueDate!: Date;

  /**
   * Status. Presence is conditional.
   */
  @observable
  status?: number;

  /**
   * Source. Value from enum MemoSource
   */
  @observable
  source!: number;

  /**
   * Resolution date
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "resolved_at" })
  resolvedAt?: Date;

  /**
   * Subject
   */
  @observable
  subject!: string;

  /**
   * Body
   */
  @observable
  body?: string;

  /**
   * Author ID (either ID or Name is present)
   */
  @observable
  @Expose({ name: "author_id" })
  authorId?: number;

  /**
   * Author Name (either ID or Name is present)
   */
  @observable
  @Expose({ name: "author_name" })
  authorName?: string;

  /**
   * Assignees. Only admins see it.
   */
  @observable
  assignees?: number[];

  /**
   * Creation date
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  /**
   * Update date
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    dueDate: { required: true },
    status: { number: true },
    source: { required: true, number: true },
    subject: { required: true },
    authorId: { number: true },
    createdAt: { required: true },
    updatedAt: { required: true },
  };
}
