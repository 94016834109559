import { Router, watchBusy } from "@frui.ts/screens";
import ActivitiesRepository from "data/repositories/activitiesRepository";
import { interfaces } from "inversify";
import { action, computed, observable, runInAction, toJS } from "mobx";
import EnumService from "services/enum";
import EventBus from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import EditableDetailViewModelBase from "viewModels/editableDetailViewModelBase";
import UpdateActivityDto from "entities/updateActivityDto";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import extractErrorMessage from "data/extractErrorMessage";
import AccessControlViewModel from "viewModels/shops/detail/accessControlViewModel";
import { attachAutomaticDirtyWatcher } from "@frui.ts/dirtycheck";
import { ISelectItem } from "@frui.ts/views";
import UserContext from "services/userContext";
import CreateActivityDto from "entities/createActivityDto";
import { User } from "manualEntities/user";
import ActivityDetailDto from "entities/activityDetailDto";
import { bound } from "@frui.ts/helpers";
import { ParcelShopFilter } from "manualEntities/parcelShop";
import ParcelShopStatus from "manualEntities/parcelShopStatus";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import AccessPointPartners from "models/enumerations/accessPointPartners";
import PotentialRepository from "data/repositories/potentialRepository";
import { PagedQueryResult } from "@frui.ts/data/dist/types";

export interface PortalSelectItem extends ISelectItem {
  isPotential: boolean;
}

const navigationName = "activity";
@Router.registerRoute({ name: Router.Self, route: navigationName })
export default class ActivityDetailViewModel extends EditableDetailViewModelBase<ActivityDetailDto, ActivityDetailDto> {
  // Target cust id to assign terminal
  @observable custId?: number;
  @observable updateItem: ActivityDetailDto;
  @observable errorMessage?: string;
  @observable.shallow allowedShops?: PortalSelectItem[];
  @observable shopSelectionActive: boolean;

  constructor(
    private users: User[],
    private psId: number | undefined,
    private entityType: number | undefined,
    public originalItem: ActivityDetailDto | undefined,
    private repository: ActivitiesRepository,
    private parcelShopsRepository: ParcelShopRepository,
    private potentialRepository: PotentialRepository,
    private eventBus: EventBus,
    private enums: EnumService,
    private notificationService: NotificationService,
    public localizationService: LocalizationService,
    private userContext: UserContext
  ) {
    super(originalItem);

    if (originalItem && originalItem.id) {
      this.name = this.localizationService.translateGeneral("activity.detail");
      this.navigationName = `activity/${originalItem.id.toString()}`;
    } else {
      this.name = this.localizationService.translateGeneral("activity.add");
      this.navigationName = "activity/new";
    }
  }

  async onInitialize() {
    await super.onInitialize();
    await this.loadPortals();
  }

  @action
  @watchBusy
  protected loadDetail() {
    const editedItem: ActivityDetailDto = this.originalItem ?? new ActivityDetailDto();

    if (this.isCreating || !this.originalItem?.id) {
      if (this.psId !== undefined && this.entityType !== undefined) {
        editedItem.entityType = this.entityType;
        editedItem.psId = editedItem.entityType === 1 ? -this.psId : this.psId;
      }
      this.shopSelectionActive = !this.psId;

      editedItem.assignees = this.userContext.isAdmin ? [] : [this.currentUserId];

      attachAutomaticValidator(editedItem, CreateActivityDto.ValidationRules);
    } else {
      editedItem.psId = editedItem.entityType === 1 ? -editedItem.psId : editedItem.psId;
      attachAutomaticValidator(editedItem, UpdateActivityDto.ValidationRules);
    }

    attachAutomaticDirtyWatcher(editedItem);
    return editedItem;
  }

  @bound
  private async loadPortals() {
    type PsForSelect = {
      id: number;
      name: string;
      type_id: number;
    };

    const filter = new ParcelShopFilter();
    if (this.isCreating || this.shopSelectionActive) {
      filter.depo_ids = toJS(this.userContext.assignedDepos);
    }
    filter.states = [ParcelShopStatus.Active, ParcelShopStatus.Unactive];

    const potentialTypes: number[] = [+AccessPointPartners.PARCEL_BOX_POTENTIAL, +AccessPointPartners.PARCEL_SHOP_POTENTIAL];

    const [parcelShops]: PagedQueryResult<PsForSelect> = await this.parcelShopsRepository.getList(
      { offset: 0, limit: 1000, sortColumn: "name" },
      filter
    );

    runInAction(() => {
      this.allowedShops = parcelShops.map(ps => {
        const typeId = ps.type_id;
        const isPotential = potentialTypes.indexOf(typeId) != -1;
        return {
          value: isPotential ? -ps.id : ps.id,
          label: ps.name,
          isPotential: isPotential,
        } as PortalSelectItem;
      });
    });
  }

  @action.bound
  assignEntityType() {
    if (!this.shopSelectionActive) {
      this.item.psId = this.item.entityType === 1 ? -this.item.psId : this.item.psId;
      return;
    }

    const portal: PortalSelectItem | undefined = this.allowedShops?.find(item => item.value === this.item.psId);
    // if 1 selected PS/PB is potential, 0 for otherwise
    this.item.entityType = +!!portal?.isPotential;
    this.item.psId = this.item.entityType === 1 ? -this.item.psId : this.item.psId;
  }

  @computed
  get selectedUsers(): TreeItem[] | undefined {
    if (!this.item) {
      return undefined;
    }

    return this.possibleUsers.filter(user => this.item.assignees.includes(+user.id));
  }

  @action.bound
  onSelectUser = (values: TreeItem[]) => {
    runInAction(() => {
      if (values !== undefined) {
        this.item.assignees = values.map(assignee => Number(assignee.id));
      } else {
        this.item.assignees = [];
      }
    });
  };

  get allowedToModifyActivity() {
    if (!this.item) {
      return false;
    }

    return (this.item.assignees?.includes(this.currentUserId) || this.userContext.isAdmin) && !this.item.daktelaId;
  }

  get allowedToChangeNote() {
    if (!this.item) {
      return false;
    }

    return (
      this.isCreating || (!this.item.daktelaId && (this.item.assignees?.includes(this.currentUserId) || this.userContext.isAdmin))
    );
  }

  get isCreating() {
    return !this.originalItem?.id;
  }

  get canSave() {
    return !hasVisibleErrors(this.item) && (this.allowedToModifyActivity || this.allowedToChangeNote || this.isCreating);
  }

  get activityStatuses(): ISelectItem[] {
    return this.enums.values("activity_states").map(item => ({ value: item.id, label: item.name }));
  }

  get isUserSelectDisabled() {
    return this.possibleUsers.length == 1 && +this.possibleUsers[0].id == this.currentUserId;
  }

  get possibleUsers(): TreeItem[] {
    return this.users
      .filter(user => {
        return this.userContext.isAdmin ? true : user.id == this.currentUserId;
      })
      .map(x => ({
        id: `${x.id}`,
        label: x.first_name && x.last_name ? x.first_name + " " + x.last_name : x.email,
      }));
  }

  get activityTypes(): ISelectItem[] {
    return this.enums.values("activity_types").map(item => ({ value: item.id, label: item.name }));
  }

  get activityCategories(): ISelectItem[] {
    return this.enums.values("activity_categories").map(item => ({ value: item.id, label: item.name }));
  }

  getState(id: number) {
    const enumItem = this.enums.value("activity_states", id);
    if (enumItem) {
      return enumItem.name;
    }
    return " - ";
  }

  getAuthor(id: number) {
    const user = this.users.find(item => item.id === id);
    if (user) {
      return user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.email;
    }
    return " - ";
  }

  get currentUserId(): number {
    return this.userContext.userId ?? -1;
  }

  @action.bound
  @watchBusy
  async save() {
    this.errorMessage = undefined;

    this.assignEntityType();

    if (!validate(this.item)) {
      return;
    }

    try {
      if (this.isCreating || !this.originalItem?.id) {
        await this.repository.createActivities(this.item as CreateActivityDto);
      } else {
        await this.repository.updateActivities(this.originalItem.id, this.item as UpdateActivityDto);
      }

      this.notificationService.addNotification(
        this.localizationService.translateGeneral(`activity.${this.isCreating ? "created" : "edited"}`),
        SeverityLevel.success,
        AccessControlViewModel.notificationScope
      );
    } catch (error: any) {
      runInAction(() => (this.errorMessage = extractErrorMessage(error)));
      return;
    }

    await this.requestClose();
  }

  static Factory({ container }: interfaces.Context) {
    return (users: User[], psId?: number, entityType?: number, item?: ActivityDetailDto) => {
      return new ActivityDetailViewModel(
        users,
        psId,
        entityType,
        item,
        container.get(ActivitiesRepository),
        container.get(ParcelShopRepository),
        container.get(PotentialRepository),
        container.get(EventBus),
        container.get(EnumService),
        container.get(NotificationService),
        container.get(LocalizationService),
        container.get(UserContext)
      );
    };
  }
}
