import { Expose, Type } from "class-transformer";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Contact {
  /**
   * id
   */
  id!: number;

  /**
   * Parcel shop foreign key
   */
  @observable
  @Expose({ name: "ps_id" })
  psId!: number;

  /**
   * Contact type
   */
  @observable
  @Expose({ name: "contact_type_id" })
  contactTypeId!: number;

  @observable
  name!: string;

  @observable
  phone!: string;

  @observable
  mobile!: string;

  @observable
  email!: string;

  @observable
  description!: string;

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    psId: { required: true, number: true },
    contactTypeId: { required: true, number: true },
    name: { required: true },
    phone: { required: true },
    mobile: { required: true },
    email: { required: true },
    description: { required: true },
    createdAt: { required: true },
    updatedAt: { required: true },
  };
}

export class ContactDisplayModel extends Contact {
  typeName: string;
}
