import { Check } from "@frui.ts/bootstrap";
import { registerView, ViewComponent } from "@frui.ts/views";
import { TimeInput } from "controls/timeInput";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React from "react";
import { Form } from "react-bootstrap";
import EditOpeningHoursViewModel from "viewModels/shops/detail/edit/editOpeningHoursViewModel";
import { IOpeningHoursViewModel } from "viewModels/shops/detail/types";
import AddOpeningHoursViewModel from "viewModels/shops/newShop/newOpeningHoursViewModel";
import "./openingHoursView.scss";

const openingHoursView: ViewComponent<IOpeningHoursViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("opening_hours", attribute);
  };

  const closedPlaceholder = tg("parcel_shop.hours_closed");

  return (
    <>
      {vm.isAlwaysVisible && vm.days && (
        <Form.Check
          className="mt-3 mb-5"
          custom
          onChange={() => vm.toggleAlwaysOpen(!vm.alwaysOpen)}
          checked={vm.alwaysOpen}
          label={translateAttribute("parcel_box", "always_open")}
          id="always_open"
        />
      )}
      {vm.days && (
        <table className="table table-borderless table-opening-hours">
          <thead>
            <tr>
              <th colSpan={2} />
              <th colSpan={4}>
                <Form.Check
                  custom
                  onChange={vm.toggleAllAmActive}
                  checked={vm.isAllAmActive || vm.isAmIndeterminate}
                  label={tg("morning")}
                  id="morning"
                  ref={(el: any) => el && (el.indeterminate = vm.isAmIndeterminate)}
                  disabled={vm.alwaysOpen}
                />
              </th>
              <th colSpan={3}>
                <Form.Check
                  custom
                  onChange={vm.toggleAllPmActive}
                  checked={vm.isAllPmActive || vm.isPmIndeterminate}
                  label={tg("afternoon")}
                  id="afternoon"
                  ref={(el: any) => el && (el.indeterminate = vm.isPmIndeterminate)}
                  disabled={vm.alwaysOpen}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {vm.days?.map(day => (
              <tr key={day.dayName}>
                <th>
                  <div className="form-control-no-label">{day.dayName}</div>
                </th>
                {day.hours.map((h, i) => (
                  <React.Fragment key={i}>
                    <td />
                    <td>
                      <div className="form-control-no-label">
                        <Check
                          custom
                          target={h}
                          property="isActive"
                          label=""
                          id={`${day.dayName}${i}`}
                          disabled={vm.alwaysOpen}
                        />
                      </div>
                    </td>
                    <td>
                      <Form.Label>{ta("open_from")}</Form.Label>
                      <TimeInput
                        target={h}
                        property="openFrom"
                        disabled={!h.isActive || vm.alwaysOpen}
                        placeholder={h.isActive ? "" : closedPlaceholder}
                      />
                    </td>
                    <td>
                      <Form.Label>{ta("open_to")}</Form.Label>
                      <TimeInput
                        target={h}
                        property="openTo"
                        disabled={!h.isActive || vm.alwaysOpen}
                        placeholder={h.isActive ? "" : closedPlaceholder}
                      />
                    </td>
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>{vm.days[0].hours[0].isActive}</td>
            </tr>
          </tfoot>
        </table>
      )}
      <LoadingOverlay loading={!vm.days} />
    </>
  );
});

registerView(openingHoursView, AddOpeningHoursViewModel);
registerView(openingHoursView, EditOpeningHoursViewModel);
export default openingHoursView;
