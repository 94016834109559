import { observer } from "mobx-react-lite";
import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingOverlay: React.SFC<{ loading?: boolean }> = observer(({ loading }) => {
  return loading ? (
    <div className="loading-overlay">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  ) : null;
});

export default LoadingOverlay;
