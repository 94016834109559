import { CommonInputProps } from "@frui.ts/bootstrap/dist/controls/commonInputProps";
import { PropertyName } from "@frui.ts/helpers";
import { getValidationMessage } from "@frui.ts/validation";
import { IBindingProps } from "@frui.ts/views";
import { Observer } from "mobx-react-lite";
import React from "react";

export class ValidationError<TTarget> extends React.Component<CommonInputProps & IBindingProps<TTarget>> {
  render() {
    const validationError = this.getValidationError();

    return (
      <Observer>
        {() => (
          <div className="invalid-feedback" style={{ display: validationError ? "block" : "none" }}>
            {validationError}
          </div>
        )}
      </Observer>
    );
  }

  protected getValidationError() {
    const { noValidation, errorMessage } = this.props;

    if (noValidation === true) {
      return undefined;
    }

    if (errorMessage) {
      return errorMessage;
    }

    const target = this.props.target as TTarget;
    const property = this.props.property as PropertyName<TTarget>;

    if (target && property) {
      return getValidationMessage(target, property);
    }

    return undefined;
  }
}
