import { EntityValidationRules } from "services/validation";

export enum UserRole {
  PortalAdmin = 1,
  PortalUserPS = 2,
  PortalUserPB = 4,
  PortalUser = 6,
}

export enum RoleType {
  ADMIN = "admin",
  USER = "user",
}

export class User {
  id = -1;
  email = "";
  password = "";
  password_repeated = "";
  depo_ids: number[] = [];
  role?: UserRole = UserRole.PortalUser;
  deleted_at?: Date;
  ps_category_ids: number[] = [];
  first_name?: string;
  last_name?: string;
  myps_version?: string;

  static ValidationRules: EntityValidationRules<User> = {
    email: {
      required: true,
      isEmail: true,
    },
    role: {
      required: true,
      isIn: [UserRole.PortalUserPS, UserRole.PortalAdmin, UserRole.PortalUserPB, UserRole.PortalUser],
    },
    password: {
      required: "onCreate" as const,
      minLength: 6,
    },
    password_repeated: {
      required: "onCreate" as const,
      equalWith: "password",
    },
    depo_ids: {
      requiredIf: { condition: (x: User) => x.role !== UserRole.PortalAdmin },
    },
    ps_category_ids: {
      requiredIf: { condition: (x: User) => x.role !== UserRole.PortalAdmin && x.role !== UserRole.PortalUserPB },
    },
  };
}

export class UsersFilter {
  email?: string;
  depo_id?: number;
  roles?: number | number[];
}
