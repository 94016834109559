import { hasVisibleErrors } from "@frui.ts/validation";
import Contact from "entities/contact";
import NetworkStatusType from "manualEntities/networkStatusType";
import { validateAll } from "helpers";
import { interfaces } from "inversify";
import { observable, action, computed } from "mobx";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import ContactsViewModelBase from "../detail/edit/contactsViewModelBase";
import { ContactModel } from "../detail/types";
import NewShopConfigurationViewModel from "./newShopConfigurationViewModel";
import { IStepViewModel } from "./types";
import { ensureObservableProperty } from "@frui.ts/helpers";

export default class NewContactsViewModel extends ContactsViewModelBase implements IStepViewModel {
  @observable items: ContactModel[] = [];

  @computed
  get isPotential() {
    return this.newShopConfigurationViewModel.isPotential;
  }

  constructor(
    public isParcelBox: boolean,
    protected newShopConfigurationViewModel: NewShopConfigurationViewModel,
    contacts: Contact[],
    localizationService: LocalizationService,
    enums: EnumService
  ) {
    super(isParcelBox, localizationService, enums);
    this.name = localizationService.translateGeneral("parcel_shop.contact");
    contacts.forEach(contact => ensureObservableProperty(contact, "isMandatory", false));
    this.items.push(...contacts);
  }

  onInitialize() {
    this.enums.values("contact_types").forEach(item => {
      if (item.required && !this.isPotential) {
        const existingContact = this.items.find(contact => contact.contactTypeId === item.id);

        if (existingContact) {
          existingContact.isMandatory = true;
        } else {
          this.addContact(this.createDefaultContact(item.id));
        }
      }
    });
    super.onInitialize();
  }

  @action removeContact(contact: ContactModel) {
    if (contact.isMandatory) {
      return;
    }

    const index = this.items.indexOf(contact);
    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }

  get canContinue() {
    return this.items.every(x => !hasVisibleErrors(x));
  }

  validate() {
    return validateAll(this.items);
  }

  private createDefaultContact(typeId: number) {
    const defaultContact = new Contact() as ContactModel;
    defaultContact.contactTypeId = typeId;
    defaultContact.isMandatory = true;
    return defaultContact;
  }

  get isNetworkParent() {
    if (this.newShopConfigurationViewModel.isPotential) {
      return false;
    }
    return this.newShopConfigurationViewModel.item?.network_partner === NetworkStatusType.Parent;
  }

  static Factory({ container }: interfaces.Context) {
    return (isParcelBox: boolean, configurationVM: NewShopConfigurationViewModel, contacts: Contact[] = []) =>
      new NewContactsViewModel(
        isParcelBox,
        configurationVM,
        contacts,
        container.get(LocalizationService),
        container.get(EnumService)
      );
  }
}
