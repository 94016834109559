import { ScreenBase } from "@frui.ts/screens";
import { action, observable } from "mobx";

export interface ButtonParams {
  text?: string;
  variant?: "primary" | "danger" | "outline-secondary";
}

export default class ConfirmDialogViewModel extends ScreenBase {
  @observable isOpened = false;
  private onResult: (value: boolean) => void;

  get canBeNavigationActiveScreen() {
    return false;
  }

  constructor(
    public message: string,
    public title: string | undefined,
    public confirmButtonParams: ButtonParams,
    public cancelButtonParams: ButtonParams,
    public settings: { closeButton?: boolean; hideCancelButton?: boolean } = { closeButton: true, hideCancelButton: false }
  ) {
    super();
  }

  @action.bound public showConfirmation() {
    this.isOpened = true;
    return new Promise<boolean>(resolve => {
      this.onResult = resolve;
    });
  }

  @action.bound confirm() {
    this.isOpened = false;
    this.onResult(true);
  }

  @action.bound cancel() {
    this.isOpened = false;
    this.onResult(false);
  }
}
