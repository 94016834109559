import { attachAutomaticDirtyWatcher, resetDirty } from "@frui.ts/dirtycheck";
import { watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import SubjectsRepository from "data/repositories/subjectRepository";
import Subject, { validationRulesWithBankAccount } from "entities/subject";
import { interfaces } from "inversify";
import { action, computed, observable, toJS } from "mobx";
import EnumService from "services/enum";
import EventBus, { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import {
  depoSelectOption,
  scrollTop,
  formatDepoName,
  subjectTypeSelectOption,
  formatBankAccount,
  bankSelectOption,
} from "utils/helpers";
import EditableDetailViewModelBase from "viewModels/editableDetailViewModelBase";
import SubjectsViewModel from "./subjectViewModel";
import SecurityService from "services/securityService";
import { ViewModelWithBankAccount } from "../types";

export default class SubjectDetailViewModel
  extends EditableDetailViewModelBase<Subject, Subject>
  implements ViewModelWithBankAccount {
  @observable private editMode = false;

  constructor(
    originalItem: Subject,
    private repository: SubjectsRepository,
    private eventBus: EventBus,
    private enums: EnumService,
    private security: SecurityService,
    private notificationService: NotificationService,
    public localizationService: LocalizationService
  ) {
    super(originalItem);

    this.name = originalItem.name;
    this.navigationName = originalItem.id.toString();
  }

  @action.bound
  @watchBusy
  async toggleEdit() {
    this.setItem(await this.loadDetail());

    if (this.editMode) {
      this.editMode = false;
      this.name = this.item.name;
      this.navigationName = this.item.id.toString();
    } else {
      this.editMode = true;
      this.navigationName = this.item.id.toString() + "/edit";
      this.name = this.localizationService.translateGeneral("subject.edit");
    }

    this.notifyNavigationChanged(); // save filter to history
  }

  get canEditSubject() {
    return this.security.isAllowed("edit", "subject", this.item);
  }

  get isEditing() {
    return this.editMode;
  }

  protected async loadDetail() {
    let editedItem: Subject = new Subject();

    try {
      editedItem = await this.repository.getSubject(this.originalItem!.id);
    } catch (e) {
      this.eventBus.publish(Events.General.ServerError, this.localizationService.translateGeneral("subject.errors.fetch_failed"));
      this.requestClose();
      throw e;
    }

    attachAutomaticDirtyWatcher(editedItem);

    const validationRules = {
      ...Subject.ValidationRules,
      ...validationRulesWithBankAccount(true),
    };
    attachAutomaticValidator(editedItem, validationRules, !this.isCreating);

    return editedItem;
  }

  get subjectTypes() {
    return this.enums
      .values("subject_types")
      .filter(type => type.code === "7" || type.code === "9") // FIX after 120408 will be merged
      .map(subjectTypeSelectOption);
  }

  get depos() {
    return this.enums.values("depos").map(depoSelectOption);
  }

  get bankAccountCodes() {
    return this.enums.values("banks").map(bankSelectOption);
  }

  getSubjectType(code: number) {
    const subjectType = this.enums.value("subject_types", code);
    return subjectType?.name ?? " - ";
  }

  getDepoName(code: number) {
    const depo = this.enums.value("depos", code);
    return formatDepoName(depo);
  }

  getBankAccount(subject: Subject): string {
    return formatBankAccount(subject.bankAccountPrefix, subject.bankAccountNumber, subject.bankAccountCode);
  }

  // 1 view for detail & edit
  @action
  navigate(subPath: string, params: any) {
    this.editMode = subPath === "edit";
    this.navigationName = this.navigationName + "/edit";
  }

  @computed get canSave() {
    return !hasVisibleErrors(this.item);
  }

  @action.bound
  @watchBusy
  async update() {
    if (!validate(this.item)) {
      return;
    }

    try {
      await this.repository.updateSubject(this.item);
      resetDirty(this.item);
      await this.requestClose();
      this.notificationService.addNotification(
        this.localizationService.translateGeneral("subject.updated"),
        SeverityLevel.success,
        SubjectsViewModel.notificationScope
      );
    } catch (e) {
      console.error(e);
    }

    scrollTop();
  }

  static Factory({ container }: interfaces.Context) {
    return (item?: any) => {
      return new SubjectDetailViewModel(
        item,
        container.get(SubjectsRepository),
        container.get(EventBus),
        container.get(EnumService),
        container.get(SecurityService),
        container.get(NotificationService),
        container.get(LocalizationService)
      );
    };
  }
}
