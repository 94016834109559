import { computed, observable } from "mobx";
import { Type } from "class-transformer";

export default class EditableEnum {
  @observable
  id: number;

  @observable
  value: string;

  @observable
  active: boolean;

  @observable
  @Type(() => Date)
  updated_at: Date;

  @observable
  @Type(() => Date)
  created_at: Date;

  @computed
  get code() {
    return this.id.toString();
  }

  @computed
  get name() {
    return this.value;
  }
}
