import { Select } from "@frui.ts/bootstrap";
import { registerView, ViewComponent } from "@frui.ts/views";
import { RadioArray } from "controls/radioArray";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import PaymentStoppageDetailViewModel from "viewModels/shops/detail/invoicing/paymentStoppageDetailViewModel";
import preventDefault from "views/preventDefault";

import styles from "./paymentStoppageDetail.module.scss";

const paymentStoppageDetailView: ViewComponent<PaymentStoppageDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localization;
  return (
    <Modal centered={true} show={true} onHide={vm.requestClose}>
      <Form onSubmit={preventDefault(vm.save)}>
        <Modal.Header closeButton>
          <Modal.Title>{vm.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className={styles.info}>{tg("parcel_box.invoicing.payment_stoppage.set_period.description")}</p>
          <p className={styles.title}>{tg("parcel_box.invoicing.payment_stoppage.set_period.type")}</p>
          <div className={styles.radios}>
            {[
              {
                label: tg("parcel_box.invoicing.payment_stoppage.set_period.one_month"),
                value: 1,
              },
              { label: tg("parcel_box.invoicing.payment_stoppage.set_period.few_months"), value: 2 },
            ].map(item => (
              <RadioArray
                key={item.value}
                custom
                target={vm}
                property="type"
                isNumeric={true}
                id={item.value.toString()}
                label={item.label}
              />
            ))}
          </div>

          <div className={styles.dates}>
            <div className={styles.dateSelect}>
              <div>
                <Select
                  emptyText={tg("parcel_box.invoicing.payment_stoppage.set_period.month")}
                  target={vm.item}
                  property="startsAtMonth"
                  items={vm.startsMonths.map(i => ({ label: i.toString().padStart(2, "0"), value: i }))}
                  mode="key"
                  keyProperty="value"
                  isNumeric={true}
                />
              </div>
              <span>/</span>
              <div>
                <Select
                  emptyText={tg("parcel_box.invoicing.payment_stoppage.set_period.year")}
                  target={vm.item}
                  property="startsAtYear"
                  items={vm.years.map(i => ({ label: `${i}`, value: i }))}
                  mode="key"
                  keyProperty="value"
                  isNumeric={true}
                />
              </div>
            </div>

            {vm.type === 2 && (
              <>
                až
                <div className={styles.dateSelect}>
                  <div>
                    <Select
                      emptyText={tg("parcel_box.invoicing.payment_stoppage.set_period.month")}
                      target={vm.item}
                      property="endsAtMonth"
                      items={vm.endsMonths.map(i => ({ label: i.toString().padStart(2, "0"), value: i }))}
                      mode="key"
                      keyProperty="value"
                      isNumeric={true}
                      disabled={!vm.item.startsAtMonth || !vm.item.startsAtYear}
                    />
                  </div>
                  <span>/</span>
                  <div>
                    <Select
                      emptyText={tg("parcel_box.invoicing.payment_stoppage.set_period.year")}
                      target={vm.item}
                      property="endsAtYear"
                      items={vm.endsYears.map(i => ({ label: `${i}`, value: i }))}
                      mode="key"
                      keyProperty="value"
                      isNumeric={true}
                      disabled={!vm.item.startsAtMonth || !vm.item.startsAtYear}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button disabled={!vm.canSave} type="submit">
            {tg("save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});

export default registerView(paymentStoppageDetailView, PaymentStoppageDetailViewModel);
