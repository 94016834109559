import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CountsByOpportunityState {
  @observable
  analysis!: number;

  @observable
  offer!: number;

  @observable
  negotiation!: number;

  @observable
  implementation!: number;

  @observable
  @Expose({ name: "future_opportunity" })
  futureOpportunity!: number;

  @observable
  @Expose({ name: "unable_to_get" })
  unableToGet!: number;

  @observable
  complete!: number;

  static ValidationRules = {
    analysis: { required: true, number: true },
    offer: { required: true, number: true },
    negotiation: { required: true, number: true },
    implementation: { required: true, number: true },
    futureOpportunity: { required: true, number: true },
    unableToGet: { required: true, number: true },
    complete: { required: true, number: true },
  };
}
