import { registerView, View, ViewComponent } from "@frui.ts/views";
import { IcoInput } from "controls/icoInput";
import HeaderRow from "controls/headerRow";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Card, Col, Form, FormLabel, Row } from "react-bootstrap";
import CheckSubjectViewModel from "viewModels/pickupPoint/checkSubjectViewModel";
import preventDefault from "../preventDefault";

const checkSubjectView: ViewComponent<CheckSubjectViewModel> = observer(({ vm }) => {
  const { translate } = vm;
  const { translateAttribute: ta } = vm.localizationService;

  if (vm.activeChild) {
    return <View vm={vm.activeChild} />;
  }

  return (
    <>
      <HeaderRow title={vm.name} />
      <Row>
        <Col md={6} lg={5} className="order-md-2 order-lg-2">
          <h3 className="font-weight-normal">{translate("description.part1")}</h3>
          <p>{translate("description.part2")}</p>
          <p>{translate("description.part3")}</p>
        </Col>
        <Col md={6} lg={4} className="order-md-1 order-lg-1">
          <Card>
            <Card.Body>
              <Form onSubmit={preventDefault(vm.continue)}>
                <Form.Group>
                  <FormLabel column={false}>{ta("subject", "ic")}</FormLabel>
                  <IcoInput target={vm.item} property="ic" />
                </Form.Group>
                <Button type="submit" block={true} size="lg">
                  {translate("continue")}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <LoadingOverlay loading={vm.busyWatcher.isBusy} />
    </>
  );
});
export default registerView(checkSubjectView, CheckSubjectViewModel);
