import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class RecipientDto {
  /**
   * Type of recipient (depo, user, ps)
   */
  @observable
  type!: string;

  id!: number;

  static ValidationRules = {
    type: { required: true },
    id: { required: true, number: true },
  };
}
