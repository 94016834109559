export default function (entity: Record<string, any>): any {
  for (const property in entity) {
    const value = entity[property];
    if (value === undefined) {
      entity[property] = null;
    }
  }

  return entity;
}
