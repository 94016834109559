import { computed, observable } from "mobx";
import { Exclude, Type } from "class-transformer";
import { Expose } from "class-transformer";
import { ParcelStatus } from "services/warehouseService";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class Parcel {
  /**
   * Internal CRM ID
   */
  id!: number;

  /**
   * Parcel Identifier in PPL systems
   */
  @observable
  @Expose({ name: "ext_id" })
  extId!: string;

  /**
   * Parcel status
   */
  @observable
  status!: number;

  /**
   * Parcel timestamp
   */
  @observable
  @Type(() => Date)
  timestamp!: Date;

  /**
   * Is parcel missing?
   */
  @observable
  missing!: boolean;

  /**
   * COD
   */
  @observable
  cod?: number;

  /**
   * Parcel cod currency
   */
  @observable
  currency?: string;

  /**
   * Parcel reference
   */
  @observable
  reference?: string;

  /**
   * Confirmation number from EDX event
   */
  @observable
  @Expose({ name: "confirmation_number" })
  confirmationNumber?: string;

  /**
   * Parcel placement (set via mobile)
   */
  @observable
  placement?: string;

  /**
   * Parcel product
   */
  @observable
  product?: string;

  @observable
  @Expose({ name: "business_type" })
  businessType?: string;

  @observable
  @Expose({ name: "parcel_shop_id" })
  parcelShopId!: number;

  @observable
  @Expose({ name: "external_partner" })
  externalPartner?: string;

  /**
   * Payment method 0 - none, 1 - by card
   */
  @observable
  @Expose({ name: "payment_method" })
  paymentMethod?: number;

  @observable
  pin?: string;

  @observable
  @Expose({ name: "slot_id" })
  slotId?: string;

  @observable
  @Expose({ name: "parent_parcel_id" })
  parentParcelId?: string;

  @observable
  labelless?: boolean;

  @observable
  @Expose({ name: "children_parcel_ids" })
  childrenParcelIds?: string[];

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    extId: { required: true },
    status: { required: true, number: true },
    timestamp: { required: true },
    missing: { required: true },
    cod: { number: true },
    parcelShopId: { required: true, number: true },
    paymentMethod: { number: true },
    createdAt: { required: true },
    updatedAt: { required: true },
  };
}

export const numberToChunks = (value: string): string[][] => {
  const length = value.length;
  const chunkSize = 4;
  const firstChunkSize = length % chunkSize;
  let chunk: string[] = [];
  const chunks = [chunk];

  for (let i = 0; i < length; i++) {
    if (i % chunkSize === firstChunkSize && i !== 0) {
      chunk = [];
      chunks.push(chunk);
    }
    chunk.push(value[i]);
  }

  return chunks;
};

export enum ParcelProduct {
  PPL = "ParcelEurope.parcelconnect",
  DHL = "ParcelEurope.europaket",
}

export default class ParcelExtended extends Parcel {
  @Exclude()
  @computed
  get isStuck() {
    return this.status === ParcelStatus.PARCEL_STUCK;
  }

  @Exclude()
  @computed
  get isGrouped() {
    return this.parentParcelId && this.childrenParcelIds && this.childrenParcelIds.length > 0;
  }

  @Exclude()
  @computed
  get groupedParcelIds() {
    if (this.parentParcelId === this.extId) {
      return this.childrenParcelIds || [];
    } else if (this.childrenParcelIds && this.childrenParcelIds.length) {
      const ids = this.childrenParcelIds.filter(childrenId => childrenId !== this.extId);
      if (this.parentParcelId) {
        ids.push(this.parentParcelId);
      }
      return ids;
    } else {
      return [];
    }
  }
}
