import { Select } from "@frui.ts/bootstrap";
import { registerView, ViewComponent } from "@frui.ts/views";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import EditContactsViewModel from "viewModels/shops/detail/edit/editContactsViewModel";
import { ContactModel, IShopContactsViewModel } from "viewModels/shops/detail/types";
import AddContactViewModel from "viewModels/shops/newShop/newContactsViewModel";
import { PhoneInput } from "controls/phoneInput";
import unfocused from "../../../utils/unfocused";
import { StringInput } from "controls/stringInput";

const ContactItem: React.SFC<{
  contact: ContactModel;
  vm: IShopContactsViewModel;
  ta: (attribute: string) => string;
}> = observer(({ contact, vm, ta }) => {
  return (
    <Card className="my-4" body>
      <Row>
        <Form.Group as={Col} md={2} className="required">
          <Form.Label>{ta("type")}</Form.Label>
          <Select
            target={contact}
            property="contactTypeId"
            items={vm.isNetworkParent ? vm.networkParentTypes : vm.types}
            mode="key"
            textProperty="name"
            allowEmpty
            isNumeric
            disabled={contact.isMandatory}
          />
        </Form.Group>
        <Form.Group as={Col} className="required">
          <Form.Label>{ta("name")}</Form.Label>
          <StringInput trim={true} target={contact} property="name" />
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>{ta("phone")}</Form.Label>
          <PhoneInput target={contact} property="phone" />
        </Form.Group>
        <Form.Group as={Col} md={3}>
          <Form.Label>{ta("mobile")}</Form.Label>
          <PhoneInput target={contact} property="mobile" />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} md={4} className="required">
          <Form.Label>{ta("email")}</Form.Label>
          <StringInput trim={true} target={contact} property="email" />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>{ta("description")}</Form.Label>
          <StringInput trim={true} target={contact} property="description" />
        </Form.Group>
      </Row>

      {!contact.isMandatory && (
        <Button onClick={() => vm.removeContact(contact)} variant="outline-danger">
          &#215; {vm.localizationService.translateGeneral("new_parcel_shop.remove_contact")}
        </Button>
      )}
    </Card>
  );
});

const contactView: ViewComponent<IShopContactsViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("contact", attribute);
  };

  return (
    <>
      {!vm.isPotential && (
        <Alert variant="info">
          {tg("change_network_partner_parent.info")
            .replace("%POINT_TYPE%", vm.isParcelBox ? "ParcelBoxu" : "ParcelShopu")
            .replace("%TYPES%", vm.networkParentTypes.map(x => x.name).join(", "))}
        </Alert>
      )}

      {vm.items?.map((x, i) => (
        <ContactItem key={i} contact={x} ta={ta} vm={vm} />
      ))}

      <Form.Row>
        <Form.Group as={Col}>
          <Button onClick={vm.addNewContact} onMouseUp={unfocused} variant="light" size="lg" block>
            &#43; {tg("new_parcel_shop.add_contact")}
          </Button>
        </Form.Group>
      </Form.Row>

      <LoadingOverlay loading={!vm.items} />
    </>
  );
});

registerView(contactView, AddContactViewModel);
registerView(contactView, EditContactsViewModel);
export default contactView;
