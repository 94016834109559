import React, { FunctionComponent } from "react";
import { ViewProps } from "@frui.ts/views";
import ShopDetailViewModel from "../../../viewModels/shops/detail/shopDetailViewModel";
import BoxDetailViewModel from "../../../viewModels/shops/detail/boxDetailViewModel";
import PotentialDetailViewModel from "../../../viewModels/shops/detail/potentialDetailViewModel";
import { observer } from "mobx-react-lite";
import { Col, Row } from "react-bootstrap";
import NetworkStatusType from "../../../manualEntities/networkStatusType";
import { formatCategory } from "../../../utils/helpers";
import HeaderRow from "../../../controls/headerRow";
import ParcelShop from "manualEntities/parcelShop";

const Informations: FunctionComponent<ViewProps<ShopDetailViewModel | BoxDetailViewModel | PotentialDetailViewModel>> = observer(
  ({ vm }) => {
    const { translateGeneral: tg, translateAttribute } = vm.localizationService;

    const ta = (key: string) => translateAttribute("parcel_shop", key);

    return (
      <>
        <HeaderRow title={tg("parcel_shop.information")} level={3} />
        <Row>
          <Col>
            <table className="table-form">
              <tbody>
                <tr>
                  <th>{ta("ic")}:</th>
                  <td>{vm.parcelShop.subject.ic}</td>
                </tr>
                <tr>
                  <th>{ta("dic")}:</th>
                  <td>{vm.parcelShop.subject.dic}</td>
                </tr>
                <tr>
                  <th>{ta("name")}:</th>
                  <td>{vm.parcelShop.name}</td>
                </tr>
                <tr>
                  <th>{ta("subname")}:</th>
                  <td>{vm.parcelShop.subname}</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col>
            <table className="table-form">
              <tbody>
                <tr>
                  <th>{ta("type_id")}:</th>
                  <td>{vm.typeName}</td>
                </tr>
                {vm.isAlzaBox && vm.parcelShop instanceof ParcelShop && (
                  <tr>
                    <th>{ta("ext_id")}:</th>
                    <td>{vm.parcelShop?.ext_id ?? "-"}</td>
                  </tr>
                )}
                <tr>
                  <th>{ta("salesman_id")}:</th>
                  <td>{vm.salesmanName}</td>
                </tr>
                <tr>
                  <th>{ta("depo_id")}:</th>
                  <td>{vm.depoName}</td>
                </tr>
                {vm instanceof ShopDetailViewModel && vm.parcelShop.network_partner !== NetworkStatusType.Parent && (
                  <tr>
                    <th>{ta("category_id")}:</th>
                    <td>{formatCategory(vm.categoryItem)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </>
    );
  }
);

export default Informations;
