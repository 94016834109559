import { registerView, View, ViewComponent } from "@frui.ts/views";
import HeaderRow from "controls/headerRow";
import { observer } from "mobx-react-lite";
import React from "react";
import { Row, Col, Button, FormCheck } from "react-bootstrap";
import iconDelete from "assets/icons/icon-delete.svg";
import PaymentStoppageViewModel from "viewModels/shops/detail/invoicing/paymentStoppageViewModel";

import styles from "./paymentStoppage.module.scss";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import { formatMonthYear } from "../../../../utils/helpers";

const paymentStoppageView: ViewComponent<PaymentStoppageViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localization;

  const start =
    !!vm.periodItem?.startsAtMonth && !!vm.periodItem?.startsAtYear
      ? formatMonthYear(vm.periodItem?.startsAtMonth ?? 0, vm.periodItem?.startsAtYear ?? 0)
      : "";
  const end =
    !!vm.periodItem?.endsAtMonth && !!vm.periodItem?.endsAtYear
      ? formatMonthYear(vm.periodItem?.endsAtMonth ?? 0, vm.periodItem?.endsAtYear ?? 0)
      : "";

  const period = start === end ? `${start}` : `${start} – ${end}`;

  const handleDeletePeriod = () => {
    if (!vm.busyWatcher.isBusy) {
      void vm.deletePeriodStoppage();
    }
  };

  return (
    <>
      <Row>
        <Col xs={12} md={4}>
          <HeaderRow title={tg("parcel_box.recurring_payments.rent_price")} level={3} />
        </Col>
        <Col xs={12} md={8}>
          <div className={styles.paymentStoppage}>
            <div className={styles.topRow}>
              <div>
                <FormCheck>
                  <FormCheckInput id={"check"} checked={vm.isChecked} onChange={vm.onCheckboxChanged} disabled={vm.isAlzaBox} />
                  <FormCheckLabel htmlFor={"check"}>{tg("parcel_box.invoicing.payment_stoppage.infinite")}</FormCheckLabel>
                </FormCheck>
              </div>
              {vm.canEdit && !vm.periodItem && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
              {vm.canEdit && !vm.periodItem && !vm.isAlzaBox && (
                <Button disabled={vm.busyWatcher.isBusy} onClick={vm.openForm}>
                  {tg("parcel_box.invoicing.payment_stoppage.set_period.title")}
                </Button>
              )}
            </div>
            {vm.periodItem && (
              <div className={styles.bottomRow}>
                <span>{tg("parcel_box.invoicing.payment_stoppage.period")}:</span>&nbsp;{period}
                {vm.canEdit && <img onClick={handleDeletePeriod} width={24} height={24} src={iconDelete} alt={"delete-period"} />}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <View vm={vm.activeChild} />
    </>
  );
});

export default registerView(paymentStoppageView, PaymentStoppageViewModel);
