import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import SubjectListDto from "./subjectListDto";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class PotentialPs {
  /**
   * Internal generated ID
   */
  id!: number;

  /**
   * ID of subject from EPS
   */
  @observable
  @Expose({ name: "subject_id" })
  subjectId!: number;

  /**
   * Type ID (enum value `enum_access_point_partners`)
   */
  @observable
  @Expose({ name: "type_id" })
  typeId!: number;

  /**
   * Business Type ID (enum value `enum_ps/pb_types`)
   */
  @observable
  @Expose({ name: "business_type_id" })
  businessTypeId!: number;

  /**
   * Name
   */
  @observable
  name!: string;

  /**
   * Secondary name
   */
  @observable
  subname?: string;

  /**
   * Description
   */
  @observable
  description?: string;

  /**
   * CRM description
   */
  @observable
  @Expose({ name: "description_crm" })
  descriptionCrm?: string;

  /**
   * Device IMEI
   */
  @observable
  imei?: string;

  /**
   * Phone type
   */
  @observable
  @Expose({ name: "phone_type" })
  phoneType?: string;

  /**
   * Salesman ID aka OBCHZ (enum value `salesmans`)
   */
  @observable
  @Expose({ name: "salesman_id" })
  salesmanId?: number;

  /**
   * Depo ID (enum value `depos`)
   */
  @observable
  @Expose({ name: "depo_id" })
  depoId!: number;

  @observable
  latitude?: number;

  @observable
  longitude?: number;

  /**
   * Opening hours - always open flag
   */
  @observable
  @Expose({ name: "always_open" })
  alwaysOpen!: boolean;

  /**
   * Capacity
   */
  @observable
  capacity?: number;

  /**
   * Max daily capacity
   */
  @observable
  @Expose({ name: "max_daily_capacity" })
  maxDailyCapacity?: number;

  /**
   * PBox electricity price
   */
  @observable
  @Expose({ name: "electricity_price" })
  electricityPrice?: number;

  /**
   * PBox rent price
   */
  @observable
  @Expose({ name: "rent_price" })
  rentPrice?: number;

  /**
   * PBox invoicing cycle
   */
  @observable
  @Expose({ name: "pb_invoicing_cycle" })
  pbInvoicingCycle?: number;

  /**
   * Potential phase
   */
  @observable
  phase!: number;

  /**
   * Potential phase note (only Lost and FutureOpportunity)
   */
  @observable
  @Expose({ name: "phase_note" })
  phaseNote?: string;

  /**
   * Potential phase updated date
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "phase_updated_at" })
  phaseUpdatedAt!: Date;

  /**
   * Business state
   */
  @observable
  @Expose({ name: "business_state" })
  businessState!: number;

  /**
   * Contract ends at
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "contract_ends_at" })
  contractEndsAt!: Date;

  /**
   * Contract termination reason
   */
  @observable
  @Expose({ name: "termination_reason" })
  terminationReason!: number;

  /**
   * State changed at
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "state_changed_at" })
  stateChangedAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  @observable
  @Type(() => SubjectListDto)
  subject!: SubjectListDto;

  static ValidationRules = {
    id: { required: true, number: true },
    subjectId: { required: true, number: true },
    typeId: { required: true, number: true },
    businessTypeId: { required: true, number: true },
    name: { required: true },
    salesmanId: { number: true },
    depoId: { required: true, number: true },
    latitude: { number: true },
    longitude: { number: true },
    alwaysOpen: { required: true },
    capacity: { number: true },
    maxDailyCapacity: { number: true },
    electricityPrice: { number: true },
    rentPrice: { number: true },
    pbInvoicingCycle: { number: true },
    phase: { required: true, number: true },
    phaseUpdatedAt: { required: true },
    businessState: { required: true, number: true },
    contractEndsAt: { required: true },
    terminationReason: { required: true, number: true },
    stateChangedAt: { required: true },
    createdAt: { required: true },
    updatedAt: { required: true },
    subject: { required: true },
  };
}
