import { observable, runInAction } from "mobx";
import ConfirmDialogViewModel, { ButtonParams } from "viewModels/confirmDialogViewModel";
import LocalizationService from "./localizationService";
import { asString } from "helpers";

export default class ConfirmationService {
  @observable.shallow dialogs = [] as ConfirmDialogViewModel[];

  constructor(private localization: LocalizationService) {}

  showConfirmation(
    message: string,
    title?: string,
    confirmButton?: string | ButtonParams,
    cancelButton?: string | ButtonParams,
    settings: { closeButton?: boolean; hideCancelButton?: boolean } = { closeButton: true, hideCancelButton: false }
  ) {
    const confirmButtonParams = {
      text:
        (confirmButton as ButtonParams)?.text ??
        asString(confirmButton) ??
        this.localization.translateGeneral("confirm_dialog.confirm"),
      variant: (confirmButton as ButtonParams)?.variant || "primary",
    } as ButtonParams;

    const cancelButtonParams = {
      text:
        (cancelButton as ButtonParams)?.text ??
        asString(cancelButton) ??
        this.localization.translateGeneral("confirm_dialog.cancel"),
      variant: (cancelButton as ButtonParams)?.variant || "outline-secondary",
    } as ButtonParams;

    const viewModel = new ConfirmDialogViewModel(message, title || undefined, confirmButtonParams, cancelButtonParams, settings);
    this.dialogs.push(viewModel);

    const result = viewModel.showConfirmation();
    void result.then(() =>
      runInAction(() => {
        const index = this.dialogs.indexOf(viewModel);
        this.dialogs.splice(index, 1);
      })
    );

    return result;
  }
}
