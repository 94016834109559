import { attachAutomaticDirtyWatcher, resetDirty } from "@frui.ts/dirtycheck";
import { watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import MessageGroupsRepository from "data/repositories/messageGroupsRepository";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import ParcelShopUsersRepository from "data/repositories/parcelShopUsersRepository";
import MessageGroupListDto from "entities/messageGroupListDto";
import MessageGroupRecipientCreateDto from "entities/messageGroupRecipientCreateDto";
import MessageGroupResponseDto from "entities/messageGroupResponseDto";
import { interfaces } from "inversify";
import { action, computed, IReactionDisposer, observable, reaction } from "mobx";
import EnumService from "services/enum";
import EventBus, { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import UserContext from "services/userContext";
import { scrollTop } from "utils/helpers";
import EditableDetailViewModelBase from "viewModels/editableDetailViewModelBase";
import MessageGroupsViewModel from "./messageGroupsViewModel";

export default class MessageGroupDetailViewModel extends EditableDetailViewModelBase<
  MessageGroupListDto,
  MessageGroupResponseDto
> {
  // Observable tree data for recipient filter
  @observable treeData: TreeItem[] = [];
  @observable selectedRecipients: TreeItem[] = [];
  @observable keyToData: { [key: string]: TreeItem } = {};

  private openDetailTree = false;

  constructor(
    originalItem: MessageGroupListDto | undefined,
    public userContext: UserContext,
    private repository: MessageGroupsRepository,
    private psRepository: ParcelShopRepository,
    private psUserRepository: ParcelShopUsersRepository,
    private eventBus: EventBus,
    private notificationService: NotificationService,
    public localizationService: LocalizationService,
    protected enums: EnumService
  ) {
    super(originalItem);

    if (originalItem) {
      this.name = this.localizationService.translateGeneral("detail");
      this.navigationName = originalItem.id.toString();
    } else {
      this.name = this.localizationService.translateGeneral("add");
      this.navigationName = "new";
    }

    // Fetch and sync tree data
    void this.initializeTreeData();
  }

  private disposers: IReactionDisposer[] = [];

  async onInitialize() {
    await super.onInitialize();
    this.disposers.push(
      reaction(
        () => this.selectedRecipients,
        () => this.applySelectedRecipients()
      )
    );
  }

  protected onDeactivate(close: boolean) {
    if (close) {
      this.disposers.forEach(x => x());
    }
  }

  protected async loadDetail() {
    let editedItem: MessageGroupResponseDto;

    if (this.isCreating || !this.originalItem) {
      editedItem = new MessageGroupResponseDto();
    } else {
      try {
        editedItem = await this.repository.getMessageGroup(this.originalItem.id);
      } catch (e) {
        this.eventBus.publish(
          Events.General.ServerError,
          this.localizationService.translateGeneral("message_groups.errors.fetch_failed")
        );
        void this.requestClose();
        throw e;
      }

      // Set key to item from recipients to be sure
      editedItem.recipients.forEach(item => {
        const treeItem = observable({
          id: `${item.type}-${item.id}`,
          label: item.label,
        });

        // Transform recipient to proper object
        this.keyToData[item.id] = treeItem;

        // Transform loaded keys to `type-id` as selected
        this.selectedRecipients.push(treeItem);
      });

      await this.checkOpenTreeItems(editedItem);
    }

    attachAutomaticDirtyWatcher(editedItem);
    attachAutomaticValidator(editedItem, MessageGroupRecipientCreateDto.ValidationRules, !this.isCreating);
    return editedItem;
  }

  @action
  protected async initializeTreeData() {
    // Set depos
    this.treeData = [];

    this.enums.values("depos").forEach(i => {
      if (!this.userContext.isAdmin && !this.userContext.assignedDepos?.includes(i.id)) {
        return;
      }

      const item: TreeItem = observable({
        label: i.name,
        id: `depo-${i.id}`,
        children: null,
      });

      this.keyToData[item.id] = item;
      this.treeData.push(item);
    });

    await this.checkOpenTreeItems(this.item);
  }

  /**
   * This ensures for editation to open up tree select
   * in proper way.
   */
  async checkOpenTreeItems(item: MessageGroupResponseDto) {
    if (!item || !this.treeData || this.treeData.length === 0 || this.openDetailTree) {
      return; // nothing to do, not loaded or new group
    }

    for (const depoId of item.parents?.depoIds || []) {
      await this.loadData({ parentNode: { id: `depo-${depoId}` } });
    }

    for (const psId of item.parents?.psIds || []) {
      await this.loadData({ parentNode: { id: `ps-${psId}` } });
    }

    this.openDetailTree = true;
  }

  @action.bound
  onChange = (values: TreeItem[]) => {
    this.selectedRecipients = values;
  };

  @action.bound
  async loadData(item: { parentNode: { id: string } }): Promise<any> {
    const parentId = item.parentNode.id;
    const [type, id] = parentId.split("-");

    if (type === "depo") {
      // Load parcel shops
      const [ps] = await this.psRepository.getList({ limit: 1000, offset: 0 }, { depo_id: parseInt(id, 10) } as any);

      // find node & append childs
      const node = this.keyToData[parentId];
      node.children = ps.map(x => {
        const newItem: TreeItem = observable({
          label: `${x.name} (${x.cust_id})`,
          id: `ps-${x.id}`,
          children: null,
        });

        this.keyToData[newItem.id] = newItem;

        return newItem;
      });
    } else if (type === "ps") {
      const [users] = await this.psUserRepository.getUsers(parseInt(id, 10), { limit: 1000, offset: 0 });

      // find node & append childs
      const node = this.keyToData[parentId];
      node.children = users.map(x => {
        const newItem = observable({
          label: `${x.email}`,
          id: `user-${x.id}`,
          value: `user-${x.id}`,
        });

        this.keyToData[newItem.id] = newItem;

        return newItem;
      });
    }

    return true;
  }

  @computed get canSave() {
    return !hasVisibleErrors(this.item);
  }

  @action.bound
  @watchBusy
  async create() {
    if (!validate(this.item)) {
      return;
    }

    try {
      await this.repository.createMessageGroup(this.item);
      resetDirty(this.item);
      await this.requestClose();
      this.notificationService.addNotification(
        this.localizationService.translateGeneral("message_group.created"),
        SeverityLevel.success,
        MessageGroupsViewModel.notificationScope
      );
    } catch {
      // Nohting here
    }

    scrollTop();
  }

  @action.bound
  @watchBusy
  async update() {
    if (!validate(this.item)) {
      return;
    }

    try {
      await this.repository.updateMessageGroup(this.item.id, this.item);
      resetDirty(this.item);
      await this.requestClose();
      this.notificationService.addNotification(
        this.localizationService.translateGeneral("message_group.updated"),
        SeverityLevel.success,
        MessageGroupsViewModel.notificationScope
      );
    } catch {
      // Nothing here
    }

    scrollTop();
  }

  private applySelectedRecipients() {
    this.item.recipients = this.selectedRecipients.map(x => {
      const [type, id] = x.id.split("-");

      return {
        type,
        label: x.label,
        id: parseInt(id, 10),
      };
    });
  }

  static Factory({ container }: interfaces.Context) {
    return (item?: any) => {
      return new MessageGroupDetailViewModel(
        item,
        container.get(UserContext),
        container.get(MessageGroupsRepository),
        container.get(ParcelShopRepository),
        container.get(ParcelShopUsersRepository),
        container.get(EventBus),
        container.get(NotificationService),
        container.get(LocalizationService),
        container.get(EnumService)
      );
    };
  }
}
