import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import RecurringPaymentType from "./recurringPaymentType";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class RecurringPayment {
  id!: number;

  @observable
  @Expose({ name: "ps_id" })
  psId!: number;

  /**
   * 0=Electricity, 1=Rent, Electricity=0, Rent=1
   */
  @observable
  type!: RecurringPaymentType;

  @observable
  price!: number;

  @Expose({ name: "modified_by" })
  readonly modifiedBy!: number;

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  @Expose({ name: "pb_invoicing_cycle" })
  pbInvoicingCycle: number;

  @observable
  year?: number;

  @observable
  month?: number;

  static ValidationRules = {
    id: { required: true, number: true },
    psId: { required: true, number: true },
    type: { required: true },
    price: { required: true, number: true },
    modifiedBy: { readOnly: true, required: true, number: true },
    createdAt: { required: true },
    updatedAt: { required: true },
  };
}
