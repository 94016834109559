import { DetailViewModel } from "@frui.ts/datascreens";
import { bound } from "@frui.ts/helpers";
import BonusListDto from "entities/bonusListDto";
import { interfaces } from "inversify";
import LocalizationService from "services/localizationService";

export default class BonusNoteDetailViewModel extends DetailViewModel<BonusListDto> {
  constructor(item: BonusListDto, public localization: LocalizationService) {
    super();
    this.setItem(item);
    this.name = this.translate("title");
    this.navigationName = `bonus/${item.id}/note`;
  }

  protected loadDetail(): BonusListDto | Promise<BonusListDto | undefined> | undefined {
    return this.item;
  }

  @bound
  translate(key: string) {
    return this.localization.translateGeneral("parcel_shop.invoicing.bonus.note_detail." + key);
  }

  static Factory({ container }: interfaces.Context) {
    return (item: BonusListDto) => new BonusNoteDetailViewModel(item, container.get(LocalizationService));
  }
}
