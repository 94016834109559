import { Input } from "@frui.ts/bootstrap/dist/controls/input";
import { bound } from "@frui.ts/helpers";
import React from "react";
import { Form } from "react-bootstrap";

export interface TextInputProps {
  rows?: number;
  cols?: number;
}

export class TextInput<TTarget> extends Input<TTarget, TextInputProps> {
  @bound protected renderInner() {
    const validationError = this.getValidationError();

    return (
      <>
        <Form.Control
          rows={10}
          {...this.inheritedProps}
          as="textarea"
          value={this.value === undefined || this.value === null ? "" : this.value}
          onChange={this.handleValueChanged}
          isInvalid={!!validationError}
        />
        {validationError && <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>}
      </>
    );
  }
}
