import { registerView, View, ViewComponent } from "@frui.ts/views";
import HeaderRow from "controls/headerRow";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonToolbar, Form } from "react-bootstrap";
import NewParcelShopViewModel from "viewModels/shops/newShop/newParcelShopViewModel";
import { IStepViewModel } from "viewModels/shops/newShop/types";
import preventDefault from "../../preventDefault";

function getMenuItemClass(isActive: boolean, isHistory: boolean, item: IStepViewModel) {
  switch (true) {
    case isActive:
      return "list-group-item list-group-item-primary";
    case isHistory && !item.canContinue:
      return "list-group-item list-group-item-danger";
    case isHistory:
      return "list-group-item list-group-item-success";
    default:
      return "list-group-item";
  }
}

const newParcelShopView: ViewComponent<NewParcelShopViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localizationService;

  return (
    <>
      <HeaderRow title={tg(`new_parcel_shop.${vm.creatingFromPotential ? "from_potential" : "title"}`)} />

      <ul className="list-group list-group-horizontal wizard-steps">
        {vm.children.map((x, index) => (
          <li key={index} className={getMenuItemClass(vm.currentChildIndex === index, vm.currentChildIndex > index, x)}>
            <i step-index={index + 1}></i> {x.name}
          </li>
        ))}
      </ul>
      <Form onSubmit={preventDefault(vm.isLastStep ? vm.finish : vm.continue)}>
        <View vm={vm.activeChild} />

        <ButtonToolbar className="d-flex justify-content-between mt-4">
          <div>
            {!vm.isLastStep && (
              <Button type="submit" size="lg" disabled={!vm.activeChild || !vm.activeChild.canContinue}>
                {tg("new_subject.continue")}
              </Button>
            )}
            {vm.isLastStep && (
              <Button type="submit" size="lg" disabled={!vm.canFinish}>
                {tg("new_parcel_shop.create")}
              </Button>
            )}
            {vm.canGoBack && (
              <Button type="button" size="lg" variant="outline-primary" onClick={vm.goBack}>
                {tg("new_parcel_shop.back")}
              </Button>
            )}
          </div>

          <Button size="lg" variant="outline-secondary" onClick={vm.onCancelClick}>
            {tg("new_parcel_shop.cancel")}
          </Button>
        </ButtonToolbar>
      </Form>
    </>
  );
});

export default registerView(newParcelShopView, NewParcelShopViewModel);
