import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class OpportunityPsDetailDto {
  id!: number;

  @observable
  name!: string;

  @observable
  @Expose({ name: "depo_id" })
  depoId!: number;

  @observable
  @Expose({ name: "parcel_box" })
  parcelBox!: boolean;

  @observable
  @Expose({ name: "cust_id" })
  custId!: number;

  static ValidationRules = {
    id: { required: true, number: true },
    name: { required: true },
    depoId: { required: true, number: true },
    parcelBox: { required: true },
    custId: { required: true, number: true },
  };
}
