import { registerView, ViewComponent } from "@frui.ts/views";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Alert, Button, ButtonToolbar, Col, Form, Modal, Row } from "react-bootstrap";
import ScanDeviceDetailViewModel from "viewModels/scanDevices/scanDeviceDetailViewModel";
import preventDefault from "../preventDefault";
import "../messages/style.scss";
import { ScanDeviceEvent, ScanDeviceStatus } from "manualEntities/scanDevice";
import { Input } from "@frui.ts/bootstrap";
import { formatDate } from "utils/helpers";

const scanDeviceDetailView: ViewComponent<ScanDeviceDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute: ta, translateModel: tm, formatCurrency } = vm.localizationService;

  // Hooks must be defined before first return, otherwise React complains
  // Error: Rendered more hooks than during the previous render.
  const [assignModal, setModalState] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  if (!vm.item) {
    return (
      <>
        <LoadingOverlay loading={vm.busyWatcher.isBusy} />
      </>
    );
  }

  const statusId = vm.item.state;
  const status = vm.getStatus(statusId);
  // Available actions
  const actions = [];

  // To Service action
  if (statusId !== ScanDeviceStatus.Discarded && statusId !== ScanDeviceStatus.Serviced) {
    actions.push(
      <Button
        variant="outline-secondary"
        key="to_service_action"
        onClick={() => vm.statusConfirmation(ScanDeviceEvent.ToService)}>
        {tg("scan_device.events.to_service.button")}
      </Button>
    );
  }

  // Service actions
  if (statusId === ScanDeviceStatus.Serviced) {
    actions.push(
      <Button
        variant="outline-secondary"
        key="returned_from_service"
        onClick={() => vm.statusConfirmation(ScanDeviceEvent.ReturnedFromService)}>
        {tg("scan_device.events.returned_from_service.button")}
      </Button>
    );
  }

  if (statusId === ScanDeviceStatus.Available) {
    actions.push(
      <Button variant="outline-secondary" key="assign_to_ps" onClick={() => setModalState(true)}>
        {tg("scan_device.events.assign_to_ps.button")}
      </Button>
    );
  }

  // Available actions
  if (statusId === ScanDeviceStatus.Assigned || (statusId === ScanDeviceStatus.Serviced && !!vm.item.ps_id)) {
    actions.push(
      <Button
        variant="outline-secondary"
        key="remove_from_ps"
        onClick={() => vm.statusConfirmation(ScanDeviceEvent.RemoveFromPs)}>
        {tg("scan_device.events.remove_from_ps.button")}
      </Button>
    );
  }

  // Discard PS
  if (statusId !== ScanDeviceStatus.Discarded) {
    actions.push(
      <Button variant="outline-danger" key="discard_action" onClick={() => vm.statusConfirmation(ScanDeviceEvent.Discard)}>
        {tg("scan_device.events.discard.button")}
      </Button>
    );
  }

  // Parcel shop modal
  const assignTerminal = async () => {
    const result = await vm.assignPs();
    setModalState(result !== undefined);
    setErrorMessage(result);
  };

  return (
    <>
      <div className="header-row row">
        <div className="col-md">
          <h1>
            {vm.deviceTypeName}
            <div className={`badge ${status!.color}`}>{status!.name}</div>
          </h1>
        </div>
      </div>
      <span className="info">{ta("scan_device", "imei")}:</span> {vm.item.imei}
      {vm.item.ps && (
        <>
          <span className="dot"></span>
          <span className="info">{tm("parcel_shop", 1)}:</span> {vm.item.ps.name}
          <span className="dot"></span>
          <span className="info">{tm("depo", 1)}:</span> {vm.getDepoName(vm.item.ps.depo_id)}
        </>
      )}
      <span className="dot"></span>
      <span className="info">{ta("scan_device", "price")}:</span> {formatCurrency(vm.item.price)}
      <br />
      <ButtonToolbar>{actions}</ButtonToolbar>
      <br />
      {vm.item.history && vm.item.history.length > 0 && (
        <>
          <h3>{tg("scan_device.history")}</h3>

          <div className="react-bootstrap-table">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>{ta("scan_device", "date_from")}</th>
                  <th>{ta("scan_device", "date_to")}</th>
                  <th>{ta("scan_device", "state")}</th>
                  <th>{tm("parcel_shop", 1)}</th>
                </tr>
              </thead>
              <tbody>
                {vm.item.history.map(item => {
                  const itemStatus = vm.getStatus(item.state);

                  return (
                    <tr key={`journalasd-${item.id}`}>
                      <td>{formatDate(item.from)}</td>
                      <td>{item.to ? formatDate(item.to) : "Nyní"}</td>
                      <td>
                        <div className={`badge ${itemStatus!.color}`}>{itemStatus!.name}</div>
                      </td>
                      <td>{item.ps?.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
      <Modal centered={true} show={assignModal} onHide={() => setModalState(false)}>
        <Form onSubmit={preventDefault(assignTerminal)}>
          <Modal.Header closeButton>
            <Modal.Title>{tg("scan_device.events.assign_to_ps.button")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {tg("scan_device.events.assign_to_ps.body")}
            <br />
            <br />
            <Form.Row>
              <Form.Group as={Col} md={8} className="required">
                <Form.Label>{ta("parcel_shop", "cust_id")}</Form.Label>
                <Input target={vm} property="custId" type="number" />
              </Form.Group>
            </Form.Row>

            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <LoadingOverlay loading={vm.busyWatcher.isBusy} />
          </Modal.Body>
          <Modal.Footer className="justify-content-start">
            <Button type="submit">{tg("confirm_dialog.confirm")}</Button>
            <Button onClick={() => setModalState(false)} variant="outline-secondary">
              {tg("confirm_dialog.cancel")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
});

export default registerView(scanDeviceDetailView, ScanDeviceDetailViewModel);
