import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ParcelBoxUpdateDto {
  /**
   * Scanner TID
   */
  @observable
  @Expose({ name: "terminal_tid" })
  terminalTid!: string;

  /**
   * Scanner S/N
   */
  @observable
  @Expose({ name: "terminal_sn" })
  terminalSn!: string;

  /**
   * IČP
   */
  @observable
  icp!: string;

  static ValidationRules = {
    terminalTid: { required: true },
    terminalSn: { required: true },
    icp: { required: true },
  };
}
