import { SortingDirection } from "@frui.ts/data";
import { bound } from "@frui.ts/helpers";
import { watchBusy } from "@frui.ts/screens";
import { ISelectItem } from "@frui.ts/views";
import MessagesRepository from "data/repositories/messagesRepository";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import { MessageFilter } from "entities/messageDetailResponseDto";
import MessageDetailResponseDtoType from "entities/messageDetailResponseDtoType";
import MessageListResponseDto from "entities/messageListResponseDto";
import { EventBus } from "light-event-bus";
import { ParcelShopFilter } from "manualEntities/parcelShop";
import { action, observable, runInAction, toJS } from "mobx";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import UnreadMessageService from "services/unreadMessageService";
import UserContext from "services/userContext";
import { formatDepoCode, formatDepoName, formatSalesmanName, salesmanSelectOption, psCategorySelectOption } from "utils/helpers";
import ListViewModelBase from "viewModels/listViewModelBase";
import MessageGroupsViewModel from "viewModels/messageGroups/messageGroupsViewModel";
import MessageDetailViewModel from "./messageDetailViewModel";

export default class MessagesViewModel extends ListViewModelBase<
  MessageListResponseDto,
  MessageFilter,
  MessageDetailViewModel | MessageGroupsViewModel
> {
  static notificationScope = "messages";
  @observable messageFilter: MessageDetailResponseDtoType = "received";
  @observable.shallow allowedParcelShops?: ISelectItem[];

  constructor(
    private repository: MessagesRepository,
    private parcelShopsRepository: ParcelShopRepository,
    private eventBus: EventBus,
    public enums: EnumService,

    private messageDetailViewModelFactory: ReturnType<typeof MessageDetailViewModel.Factory>,
    public confirmationService: ConfirmationService,
    public localizationService: LocalizationService,
    public unreadMessageService: UnreadMessageService,
    private messageGroupVMFactory: ReturnType<typeof MessageGroupsViewModel.Factory>,
    private userContext: UserContext
  ) {
    super();
    this.navigationName = "messages";
    this.name = localizationService.translateGeneral("menu.messages");
    this.pagingFilter.limit = 10; // just to show paging
    this.pagingFilter.sortColumn = "updated_at";
    this.pagingFilter.sortDirection = SortingDirection.Descending;
  }

  @action.bound changeFilter(value: MessageDetailResponseDtoType) {
    if (this.messageFilter !== value) {
      this.messageFilter = value;
      void this.resetFilterAndLoad();
    }
  }

  onInitialize() {
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.Messages.Changed, this.loadData));
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.AccessPoints.Changed, this.loadEnums));
    void this.loadEnums();
    void this.resetFilterAndLoad();
  }

  protected resetFilterValues(filter: MessageFilter): void {
    filter.type = this.messageFilter;
    filter.parcel_shop_ids = [];
    filter.parcel_shop_name = "";
    filter.depo_ids = [];
    filter.depo_name = undefined;
    filter.salesman_ids = [];
    filter.salesman_name = "";
    filter.resolved = undefined;

    filter.ps_category_ids = [];
    filter.ps_category_name = "";
  }

  @action.bound
  @watchBusy
  async loadData() {
    this.notifyNavigationChanged(); // save filter to history

    // Always override with current tab
    this.appliedFilter.type = this.messageFilter;

    return this.repository
      .getMessages(this.pagingFilter, toJS(this.appliedFilter, { recurseEverything: true }))
      .then(([items, paging]) => {
        this.setData([items, paging]);
      });
  }

  @bound
  private async loadEnums() {
    const filter = new ParcelShopFilter();
    filter.depo_ids = toJS(this.userContext.assignedDepos);

    const [parcelShops] = await this.parcelShopsRepository.getList({ offset: 0, limit: 1000, sortColumn: "name" }, filter);

    runInAction(() => {
      this.allowedParcelShops = parcelShops.map(x => ({ value: x.id, label: x.name } as ISelectItem));
    });
  }

  get depos() {
    return this.enums.values("depos").map(
      x =>
        ({
          value: x.id,
          label: formatDepoName(x),
        } as ISelectItem)
    );
  }

  getDepoName(id: number) {
    const depo = this.enums.value("depos", id);
    return formatDepoCode(depo);
  }

  getDeposName(id: number[]) {
    return this.enums
      .valueForArray("depos", id)
      ?.map(x => formatDepoCode(x))
      .join(", ");
  }

  get salesmans() {
    return this.enums.values("salesmans").map(salesmanSelectOption);
  }

  getSalesmanName(id: number) {
    const item = this.enums.value("salesmans", id);
    return formatSalesmanName(item);
  }

  getSalesmansName(id: number[]) {
    return this.enums
      .valueForArray("salesmans", id)
      ?.map(x => formatSalesmanName(x))
      .join(", ");
  }

  @bound getCategoryName(status: number) {
    return this.enums.value("ps_categories", status)?.code;
  }

  get categories() {
    return this.enums.values("ps_categories").map(psCategorySelectOption);
  }

  setResolvedFilter(value: string | undefined) {
    switch (value) {
      case "resolved":
        this.filter.resolved = true;
        break;
      case "unresolved":
        this.filter.resolved = false;
        break;
      default:
        this.filter.resolved = undefined;
    }
  }

  @action.bound openDetail(item: MessageListResponseDto) {
    return this.tryActivateChild(this.messageDetailViewModelFactory(item));
  }

  @action.bound add() {
    return this.tryActivateChild(this.messageDetailViewModelFactory());
  }

  @action.bound openMessageGroups() {
    return this.tryActivateChild(this.messageGroupVMFactory());
  }

  protected async findNavigationChild(navigationName: string) {
    if (navigationName === "new") {
      return this.messageDetailViewModelFactory();
    } else if (navigationName === MessageGroupsViewModel.DefaultNavigationName) {
      return this.messageGroupVMFactory();
    } else if (+navigationName) {
      const message = await this.repository.getMessage(+navigationName);
      return this.messageDetailViewModelFactory(message);
    }
  }

  @action.bound clearFilter(section: keyof MessageFilter) {
    // eslint-disable-next-line
    switch (section) {
      case "parcel_shop_ids":
        this.filter.parcel_shop_ids = [];
        this.filter.parcel_shop_name = "";
        break;
      case "salesman_ids":
        this.filter.salesman_ids = [];
        this.filter.salesman_name = "";
        break;
      case "depo_ids":
        this.filter.depo_ids = [];
        this.filter.depo_name = "";
        break;
      case "resolved":
        this.filter.resolved = undefined;
        break;
      case "ps_category_ids":
        this.filter.ps_category_ids = [];
        this.filter.ps_category_name = "";
        break;
      default:
        this.filter[section] = undefined as any;
        break;
    }

    void this.applyFilterAndLoad();
  }
}
