import React from "react";
import classNames from "classnames";
import "./localLoadingOverlay.scss";
import { Spinner } from "react-bootstrap";

export type OverlayVariant = "primary" | "light" | "dark" | "transparent";

export interface OverlayProps extends React.HTMLProps<HTMLDivElement> {
  variant?: OverlayVariant;
  loader?: React.ReactNode;
  loading?: boolean;
}

export default (props: OverlayProps) => {
  const { className, variant = "transparent", children, loader, loading, ...rest } = props;

  return (
    <div className={classNames("local-loading-overlay", className)} {...rest}>
      {loading && (
        <div className={classNames("local-loading-overlay__overlay", { [variant]: true })}>
          <div className={classNames("local-loading-overlay__loader")}>
            {loader ? (
              loader
            ) : (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};
