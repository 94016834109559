import { IPagingFilter, PagedQueryResult } from "@frui.ts/data";
import Competition from "entities/competition";
import CreateCompetitionDto from "entities/createCompetitionDto";
import { Events } from "services/eventBus";
import { RepositoryBase } from "../repositoryBase";

export default class CompetitionsRepository extends RepositoryBase {
  getCompetitions(paging: IPagingFilter, filter: any): Promise<PagedQueryResult<Competition>> {
    return this.callApi(api => api.all("competitions").getPagedEntities(Competition, paging, filter));
  }

  createCompetitions(payload: CreateCompetitionDto) {
    return this.callApi(api => api.all("competitions").postEntity(payload), Events.Competitions.Changed);
  }

  updateCompetitions(id: number, payload: CreateCompetitionDto) {
    return this.callApi(api => api.one("competitions", id).putEntity(payload), Events.Competitions.Changed);
  }

  deleteCompetitions(id: number) {
    return this.callApi(api => api.one("competitions", id).delete(), Events.Competitions.Changed);
  }
}
