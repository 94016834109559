import { IPagingFilter } from "@frui.ts/data";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ConductorOneChildActive, watchBusy } from "@frui.ts/screens";
import MemosRepository from "data/repositories/memosRepository";
import MemoDetailDto from "entities/memoDetailDto";
import UpdateMemoDto from "entities/updateMemoDto";
import { interfaces } from "inversify";
import { User } from "manualEntities/user";
import { observable, runInAction } from "mobx";
import EnumService from "services/enum";
import EventBus, { Events, EventSubscription } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import SecurityService from "services/securityService";
import MemoDetailViewModel from "./memoDetailViewModel";

const PAGING_FILTER: IPagingFilter = {
  limit: 1000,
  offset: 0,
};

export default class MemosViewModel extends ConductorOneChildActive<MemoDetailViewModel> {
  busyWatcher = new BusyWatcher();
  @observable myMemos: MemoDetailDto[] = [];
  @observable teamMemos: MemoDetailDto[] = [];
  protected eventSubscriptions = [] as EventSubscription[];

  constructor(
    private users: User[],
    private repository: MemosRepository,
    private enums: EnumService,
    private eventBus: EventBus,
    private security: SecurityService,
    private memoDetailFactory: ReturnType<typeof MemoDetailViewModel.Factory>,
    public localizationService: LocalizationService,
    private notificationService: NotificationService
  ) {
    super();
    this.name = "memos";
    this.navigationName = "memos";
  }

  protected async onInitialize() {
    void this.loadData();
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.Memos.Changed, this.loadData));
    return super.onInitialize();
  }

  protected onDeactivate(close: boolean) {
    if (close) {
      const toUnsubscribe = this.eventSubscriptions;
      this.eventSubscriptions = [];
      toUnsubscribe.forEach(x => x.unsubscribe());
    }

    return super.onDeactivate(close);
  }

  @bound
  private async loadData() {
    const collection = [this.repository.getMyMemos(PAGING_FILTER, {})];

    if (this.isTeamSectionAllowed) {
      collection.push(this.repository.getTeamMemos(PAGING_FILTER, {}));
    }

    const [myMemosResponse, teamMemosResponse] = await Promise.all(collection);

    if (myMemosResponse[0]) {
      runInAction(() => {
        this.myMemos = myMemosResponse[0];
      });
    }

    if (this.isTeamSectionAllowed && teamMemosResponse[0]) {
      runInAction(() => {
        this.teamMemos = teamMemosResponse[0];
      });
    }
  }

  getUsersNames(ids: number[]) {
    return this.users
      .filter(user => ids.includes(user.id))
      .map(user => (user.first_name && user.last_name ? `${user.first_name}  ${user.last_name}` : user.email))
      .join(", ");
  }

  get memoStatuses() {
    return this.enums.values("memo_status");
  }

  getMemoStatus(status: number) {
    const item = this.enums.value("memo_status", status);
    return item?.name ?? " - ";
  }

  get isTeamSectionAllowed() {
    return this.security.isAllowed("edit", "memos");
  }

  @bound
  @watchBusy
  async changeMemoStatus(id: number, item: MemoDetailDto, newStatus: number) {
    const updateItem: UpdateMemoDto = { status: newStatus };
    await this.repository.updateMemo(id, updateItem);

    const msg = this.localizationService.translateGeneral("memos.updated").replace("%SUBJECT%", item.subject);
    this.notificationService.addNotification(msg, SeverityLevel.success);
  }

  @bound
  openMemo(mode: "create" | "edit" | "show", id: number | undefined) {
    const vm = this.memoDetailFactory(mode, id, this.users);
    void this.tryActivateChild(vm);
  }

  static Factory({ container }: interfaces.Context) {
    return (users: User[]) =>
      new MemosViewModel(
        users,
        container.get(MemosRepository),
        container.get(EnumService),
        container.get(EventBus),
        container.get(SecurityService),
        container.get(MemoDetailViewModel.Factory),
        container.get(LocalizationService),
        container.get(NotificationService)
      );
  }
}
