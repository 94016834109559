import NetworkStatusType from "manualEntities/networkStatusType";
import { EntityValidationRules } from "services/validation";
import Vacation from "entities/vacation";
import { Exclude } from "class-transformer";
import PotentialPsDetailDto from "../../../entities/potentialPsDetailDto";
import Subject from "entities/subject";
import AccessPointPartners from "models/enumerations/accessPointPartners";

export default class ShopConfiguration {
  constructor() {
    this.dhl_express = false;
    this.active_finder = false;
    this.capacity_watcher = false;
    this.vacation_watcher = false;
    this.vacations = [];
  }

  @Exclude()
  rentPrice: number;

  @Exclude()
  electricityPrice: number;

  id = -1;
  type_id: number;

  longitude: number;
  latitude: number;
  isActive: boolean;

  dhl_express: boolean;
  facility?: string;
  route_code?: string;
  service_area?: string;
  service_point_id?: string;

  capacity: number;
  maxDailyCapacity: number;
  enforceSizes: boolean;
  acceptedSize: string[];

  network_partner: NetworkStatusType = NetworkStatusType.None;
  parent_id?: number;

  description: string;
  descriptionCrm: string;

  active_finder: boolean;

  card_payment: boolean;

  payment_terminals: {
    id: number;
    terminal_id: string;
    terminal_sn: string;
    status: number;
  }[];

  scan_devices: {
    id: number;
    device_type_id: number;
    imei: number;
    price: number;
  }[];

  @Exclude()
  parcelBox?: {
    id: number;
    state: number;
    terminal_tid?: string;
    terminal_sn?: string;
    machine_sn: string;
    icp?: string;
  };

  capacity_watcher: boolean;
  vacation_watcher: boolean;

  capacity_watcher_active: boolean;
  vacation_watcher_active: boolean;

  tribal_service_point: boolean;

  vacations: Vacation[];

  category_id?: number;

  subject: Subject;
  tid?: string;

  @Exclude()
  selectedCategory?: number;

  @Exclude()
  pbInvoicingCycle: number;

  static createFromPotential(potential: PotentialPsDetailDto) {
    const configuration = new ShopConfiguration();
    configuration.latitude = potential.latitude!;
    configuration.longitude = potential.longitude!;
    configuration.capacity = potential.capacity!;
    configuration.maxDailyCapacity = potential.maxDailyCapacity!;
    configuration.enforceSizes = potential.enforceSizes;
    configuration.acceptedSize = potential.acceptedSize;
    configuration.description = potential.description!;
    configuration.descriptionCrm = potential.descriptionCrm!;
    configuration.electricityPrice = potential.electricityPrice!;
    configuration.pbInvoicingCycle = potential.pbInvoicingCycle!;
    configuration.rentPrice = potential.rentPrice!;

    return configuration;
  }

  static ValidationRules: EntityValidationRules<ShopConfiguration> = {
    longitude: { required: true, number: true, isLongitude: true },
    latitude: { required: true, number: true, isLatitude: true },
    capacity: {
      requiredIf: {
        condition: (x: ShopConfiguration) =>
          x.network_partner !== NetworkStatusType.Parent && x.type_id !== +AccessPointPartners.ALZA_BOX,
      },
      number: true,
      range: { min: 0, max: 10000 },
    },
    maxDailyCapacity: {
      requiredIf: {
        condition: (x: ShopConfiguration) =>
          x.network_partner !== NetworkStatusType.Parent && x.type_id !== +AccessPointPartners.ALZA_BOX,
      },
      /* eslint-disable-next-line @typescript-eslint/prefer-as-const */
      lowerThan: { key: "capacity" as "capacity", modelName: "parcel_shop" },
      number: true,
      range: { min: 0, max: 10000 },
    },
    facility: {
      requiredIf: { condition: (x: ShopConfiguration) => x.dhl_express },
      maxLength: 30,
    },
    route_code: {
      requiredIf: { condition: (x: ShopConfiguration) => x.dhl_express },
      maxLength: 30,
    },
    service_area: {
      requiredIf: { condition: (x: ShopConfiguration) => x.dhl_express },
      maxLength: 30,
    },
    service_point_id: {
      requiredIf: { condition: (x: ShopConfiguration) => x.dhl_express },
      maxLength: 30,
    },
    description: { maxLength: 1000 },
    acceptedSize: {
      requiredIf: { condition: (x: ShopConfiguration) => x.enforceSizes },
    },
  };
}

/**
 * @param isParcelShopType For zabka this values are not required
 * @param isPotential For potentials none field is required
 * @param isParcelBox For parcel boxes validation differ as well
 */
export const configurationValidationRulesByAccessPointType = (item: {
  isParcelShopType: boolean;
  isPotential: boolean;
  isParcelBox: boolean;
  isAlzaBox: boolean;
  isRecurringPaymentsRequired: boolean;
}) => {
  return {
    ...ShopConfiguration.ValidationRules,
    longitude: {
      required: !item.isPotential,
    },
    latitude: {
      required: !item.isPotential,
    },
    capacity: {
      ...ShopConfiguration.ValidationRules.capacity,
      requiredIf: {
        condition: (x: ShopConfiguration) =>
          !item.isPotential && x.network_partner != NetworkStatusType.Parent && !item.isAlzaBox,
      },
    },
    maxDailyCapacity: {
      ...ShopConfiguration.ValidationRules.maxDailyCapacity,
      requiredIf: {
        condition: (x: ShopConfiguration) =>
          !item.isParcelBox && !item.isPotential && x.network_partner != NetworkStatusType.Parent && !item.isAlzaBox,
      },
    },
    facility: {
      ...ShopConfiguration.ValidationRules.facility,
      requiredIf: { condition: (x: ShopConfiguration) => !item.isPotential && x.dhl_express },
    },
    route_code: {
      ...ShopConfiguration.ValidationRules.route_code,
      requiredIf: { condition: (x: ShopConfiguration) => !item.isPotential && x.dhl_express },
    },
    service_area: {
      ...ShopConfiguration.ValidationRules.service_area,
      requiredIf: { condition: (x: ShopConfiguration) => !item.isPotential && x.dhl_express },
    },
    service_point_id: {
      ...ShopConfiguration.ValidationRules.service_point_id,
      requiredIf: { condition: (x: ShopConfiguration) => !item.isPotential && x.dhl_express },
    },
    rentPrice: {
      requiredIf: {
        condition: (x: ShopConfiguration) => item.isRecurringPaymentsRequired,
      },
    },
    electricityPrice: {
      requiredIf: {
        condition: (x: ShopConfiguration) => item.isRecurringPaymentsRequired,
      },
    },
    selectedCategory: {
      requiredIf: {
        condition: (x: ShopConfiguration) =>
          !item.isPotential && x.network_partner !== NetworkStatusType.Parent && item.isParcelShopType,
      },
    },
    pbInvoicingCycle: {
      requiredIf: {
        condition: (x: ShopConfiguration) => item.isRecurringPaymentsRequired,
      },
    },
  };
};
