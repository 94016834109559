import { Expose } from "class-transformer";
import { observable } from "mobx";
import RecurringPaymentDtoType from "./recurringPaymentType";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class RecurringPaymentDto {
  /**
   * 0=Electricity, 1=Rent, 2=ElectricityStopped, 3=RentStopped, Electricity=0, Rent=1, ElectricityStopped=2, RentStopped=3
   */
  @observable
  type!: RecurringPaymentDtoType;

  @observable
  price!: number;

  /**
   * ParcelBox invoicing cycle
   */
  @observable
  @Expose({ name: "pb_invoicing_cycle" })
  pbInvoicingCycle!: number;

  static ValidationRules = {
    type: { required: true },
    price: { required: true, number: true },
    pbInvoicingCycle: { required: true, number: true },
  };
}
