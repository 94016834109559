export enum ScanDeviceStatus {
  Available = 0,
  Assigned = 1,
  Serviced = 2,
  Discarded = 3,
}

export enum ScanDeviceEvent {
  ToService = "to_service",
  Discard = "discard",
  RemoveFromPs = "remove_from_ps",
  ReturnedFromService = "returned_from_service",
  AssignToPs = "assign_to_ps",
}

export default class ScanDevice {
  id: number;
  imei: string;
  ps_id: number;
  device_type_id: number;
  price: number;
  state: number;
  ps: {
    id: number;
    depo_id: number;
    name: string;
  };
  history?: {
    id: number;
    from: Date;
    to?: Date;
    state: number;
    ps_id?: number;
    ps?: {
      id: number;
      name: string;
    };
  }[];
}

export class ScanDeviceFilter {
  device_type_ids?: number[];
  device_name?: string;
  imei?: string;
  statuses?: number[];
  state_name?: string;
  ps_ids?: number[];
  parcel_shop_name?: string;
  depo_ids?: number[];
  depo_name?: string;
  price?: number;
}
