import { IPagingFilter, PagedQueryResult } from "@frui.ts/data";
import MessageGroupListDto from "entities/messageGroupListDto";
import MessageGroupRecipientCreateDto from "entities/messageGroupRecipientCreateDto";
import MessageGroupResponseDto from "entities/messageGroupResponseDto";
import { Events } from "services/eventBus";
import { RepositoryBase } from "../repositoryBase";

export default class MessagesGroupRepository extends RepositoryBase {
  getMessageGroups(paging: IPagingFilter, filter: any): Promise<PagedQueryResult<MessageGroupListDto>> {
    return this.callApi(api => api.all("message_groups").getPagedEntities(MessageGroupListDto, paging, filter));
  }

  getMessageGroup(id: number): Promise<MessageGroupResponseDto> {
    return this.callApi(api => api.one("message_groups", id).getEntity(MessageGroupResponseDto));
  }

  createMessageGroup(payload: MessageGroupRecipientCreateDto) {
    return this.callApi(api => api.all("message_groups").postEntity(payload), Events.MessageGroups.Changed);
  }

  updateMessageGroup(id: number, payload: MessageGroupRecipientCreateDto) {
    return this.callApi(api => api.one("message_groups", id).putEntity(payload), Events.MessageGroups.Changed);
  }

  deleteMessageGroup(id: number) {
    return this.callApi(api => api.one("message_groups", id).delete(), Events.MessageGroups.Changed);
  }
}
