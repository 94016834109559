enum AccessPointPartners {
  PARCEL_SHOP = "1",
  ZABKA_POINT = "2",
  PARCEL_BOX = "3",
  PARCEL_SHOP_POTENTIAL = "4",
  PARCEL_BOX_POTENTIAL = "5",
  ALZA_BOX = "6",
}

export default AccessPointPartners;
