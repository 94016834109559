import { bound, nameof } from "@frui.ts/helpers";
import { watchBusy } from "@frui.ts/screens";
import UsersRepository from "data/repositories/usersRepository";
import { User, UserRole, UsersFilter } from "manualEntities/user";
import { EventBus } from "light-event-bus";
import { action, computed, toJS } from "mobx";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import { Events } from "services/eventBus";
import SecurityService from "services/securityService";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import ListViewModelBase from "viewModels/listViewModelBase";
import UserDetailViewModel from "./userDetailViewModel";
import { depoSelectOption, crmRoleSelectOption, scrollTop } from "utils/helpers";

export default class UsersViewModel extends ListViewModelBase<User, UsersFilter, UserDetailViewModel> {
  static notificationScope = "users";

  constructor(
    private repository: UsersRepository,
    private eventBus: EventBus,
    private userDetailViewModelFactory: ReturnType<typeof UserDetailViewModel.Factory>,
    private enumService: EnumService,
    private security: SecurityService,
    private notificationService: NotificationService,
    public confirmationService: ConfirmationService,
    public localizationService: LocalizationService
  ) {
    super();
    this.navigationName = "users";
    this.name = localizationService.translateGeneral("menu.users");

    this.pagingFilter.limit = 10; // just to show paging
    this.pagingFilter.sortColumn = nameof<User>("email");
  }

  protected async onInitialize() {
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.Users.Changed, this.loadData));

    await this.resetFilterAndLoad();
  }

  protected resetFilterValues(filter: UsersFilter) {
    filter.email = undefined;
    filter.depo_id = undefined;
    filter.roles = undefined;
  }

  get depos() {
    return this.enumService.values("depos").map(depoSelectOption);
  }

  get roles() {
    return this.enumService
      .values("crm_users")
      .filter(item => item.id !== UserRole.PortalUser)
      .map(crmRoleSelectOption);
  }

  @action.bound
  @watchBusy
  async loadData() {
    this.notifyNavigationChanged(); // save filter to history

    return this.repository
      .getUsers(this.pagingFilter, toJS(this.appliedFilter, { recurseEverything: true }))
      .then(([items, paging]) => {
        items = items.map(item => ({
          role_enum: this.enumService.value("crm_users", item.role!),
          depo_enums: item.depo_ids.map(depo_id => this.enumService.value("depos", depo_id)),
          ...item,
        }));
        this.setData([items, paging]);
      });
  }

  @action.bound openDetail(item: User) {
    return this.tryActivateChild(this.userDetailViewModelFactory(item));
  }

  @action.bound add() {
    return this.tryActivateChild(this.userDetailViewModelFactory());
  }

  @action.bound async remove(item: User) {
    if (this.canBeDeleted(item)) {
      const status = await this.confirmationService.showConfirmation(
        this.localizationService.translateGeneral("user.delete_confirm.message").replace("%username%", item.email),
        this.localizationService.translateGeneral("user.delete_confirm.title"),
        { variant: "danger", text: this.localizationService.translateGeneral("user.delete_confirm.confirm") },
        { text: this.localizationService.translateGeneral("user.delete_confirm.cancel") }
      );

      if (status) {
        try {
          await this.repository.deleteUser(item);
          this.notificationService.addNotification(
            this.localizationService.translateGeneral("user.deleted").replace("%email%", item.email),
            SeverityLevel.success,
            UsersViewModel.notificationScope
          );
        } catch {
          // TODO: Add missing catch statement
        }
      }
    } else {
      this.notificationService.addNotification(
        this.localizationService.translateGeneral("user.cannot_be_deleted"),
        SeverityLevel.information,
        UsersViewModel.notificationScope
      );
    }

    scrollTop();
  }

  protected async findNavigationChild(navigationName: string) {
    if (navigationName === "new") {
      return this.userDetailViewModelFactory();
    } else if (+navigationName) {
      const user = await this.repository.getUser(+navigationName);
      return this.userDetailViewModelFactory(user);
    }
  }

  get navigationParams() {
    return this.activeChild
      ? undefined
      : {
          offset: this.pagingFilter.offset,
        };
  }

  async navigate(subPath: string | undefined, params: any) {
    if (params.offset !== undefined) {
      this.pagingFilter.offset = +params.offset;
      await this.loadData();
    }
  }

  protected onDeactivate(close: boolean): Promise<any> {
    this.notificationService.removeNotification(UsersViewModel.notificationScope);
    return super.onDeactivate(close);
  }

  @computed
  get depoCounts() {
    return this.enumService.values("depos").length;
  }

  @bound
  canBeDeleted(item: User) {
    return this.security.isAllowed("edit", "user", item);
  }

  allDepos(user: User) {
    if ((this.enumService.value("crm_users", user.role!) ?? {}).admin) {
      return true;
    }
    return user.depo_ids.length === this.depoCounts;
  }
}
