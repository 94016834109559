import { ConductorAllChildrenActive, ScreenBase } from "@frui.ts/screens";
import { interfaces } from "inversify";
import ShopDetailContext from "models/shops/shopDetailContext";
import LocalizationService from "services/localizationService";
import BonusesViewModel from "./bonusesViewModel";
import InvoicesViewModel from "./invoicesViewModel";
import K2ExportViewModel from "./k2ExportViewModel";
import RewardsViewModel from "./rewardsViewModel";
import ParcelShop from "../../../../manualEntities/parcelShop";

export default class InvoicingViewModel extends ConductorAllChildrenActive<ScreenBase> {
  static DefaultNavigationName = "invoicing";

  get parcelShop() {
    return this.context.shop;
  }

  constructor(
    private context: ShopDetailContext<ParcelShop>,
    public localization: LocalizationService,
    k2ExportVMFactory: ReturnType<typeof K2ExportViewModel.Factory>,
    invoicesVMFactory: ReturnType<typeof InvoicesViewModel.Factory>,
    bonusesVMFactory: ReturnType<typeof BonusesViewModel.Factory>,
    rewardsVMFactory: ReturnType<typeof RewardsViewModel.Factory>
  ) {
    super();
    this.name = localization.translateGeneral("parcel_shop.invoicing.title");
    this.navigationName = InvoicingViewModel.DefaultNavigationName;
    this.childNavigationPathClosed = true;

    this.children.push(
      k2ExportVMFactory(context),
      invoicesVMFactory(context.shop.id, false),
      bonusesVMFactory(context.shop.id, false),
      rewardsVMFactory(context)
    );
  }

  static Factory({ container }: interfaces.Context) {
    return (context: ShopDetailContext<ParcelShop>) =>
      new InvoicingViewModel(
        context,
        container.get(LocalizationService),
        container.get(K2ExportViewModel.Factory),
        container.get(InvoicesViewModel.Factory),
        container.get(BonusesViewModel.Factory),
        container.get(RewardsViewModel.Factory)
      );
  }
}
