import { ViewComponent } from "@frui.ts/views";
import BoxDetailViewModel from "viewModels/shops/detail/boxDetailViewModel";
import { observer } from "mobx-react-lite";
import Table from "controls/table";
import React from "react";
import ShopDetailViewModel from "viewModels/shops/detail/shopDetailViewModel";
import PotentialDetailViewModel from "../../../viewModels/shops/detail/potentialDetailViewModel";
import HeaderRow from "../../../controls/headerRow";

const Contacts: ViewComponent<ShopDetailViewModel | BoxDetailViewModel | PotentialDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute } = vm.localizationService;
  const ta = (key: string) => translateAttribute("contact", key);

  return (
    <>
      <HeaderRow title={tg("parcel_shop.contact")} level={3} />
      <Table
        data={(vm.contacts || []).slice()}
        keyField="id"
        classes="contacts"
        columns={[
          { dataField: "typeName", text: ta("type"), title: true },
          { dataField: "name", text: ta("name"), title: true },
          { dataField: "phone", text: ta("phone"), title: true },
          { dataField: "mobile", text: ta("mobile"), title: true },
          { dataField: "email", text: ta("email"), title: true },
          { dataField: "description", text: ta("description"), title: true },
        ]}
      />
    </>
  );
});

export default Contacts;
