import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class RecurringPaymentStoppageDto {
  @observable
  startsAtMonth!: number;

  @observable
  startsAtYear!: number;

  @observable
  endsAtMonth?: number;

  @observable
  endsAtYear?: number;

  @observable
  infinite!: boolean;

  static ValidationRules = {
    startsAtMonth: { required: true, number: true },
    startsAtYear: { required: true, number: true },
    endsAtMonth: { number: true },
    endsAtYear: { number: true },
    infinite: { required: true },
  };
}
