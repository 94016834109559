import React from "react";
import { ViewComponent } from "@frui.ts/views";
import iconDelete from "assets/icons/icon-delete.svg";
import iconEdit from "assets/icons/icon-edit.svg";
import DataTable from "controls/dataTable";
import HeaderRow from "controls/headerRow";
import { CheckArray } from "controls/checkArray";
import PagingInfo from "controls/pagingInfo";
import { User } from "manualEntities/user";
import { observer } from "mobx-react-lite";
import { Button, ButtonToolbar, Card, Col, Form } from "react-bootstrap";
import { formatDepoName } from "utils/helpers";
import UsersViewModel from "viewModels/users/usersViewModel";
import preventDefault from "views/preventDefault";
import { Select } from "@frui.ts/bootstrap";
import { StringInput } from "controls/stringInput";
import { models } from "models/shops/interface";

const UserList: ViewComponent<UsersViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("user", attribute);
  };

  return (
    <>
      <HeaderRow title={tg("user.title")}>
        <ButtonToolbar className="justify-content-end">
          <Button variant="primary" onClick={vm.add}>
            {tg("user.add")}
          </Button>
        </ButtonToolbar>
      </HeaderRow>

      <Card body className="mb-4">
        <Form onSubmit={preventDefault(vm.applyFilterAndLoad)} onReset={vm.resetFilterAndLoad}>
          <Form.Row>
            <Form.Group as={Col} md={3}>
              <Form.Label>{ta("login")}</Form.Label>
              <StringInput trim={true} target={vm.filter} property="email" />
            </Form.Group>

            <Form.Group as={Col} md={3}>
              <Form.Label>{ta("depo_ids")}</Form.Label>
              <Select target={vm.filter} property="depo_id" items={vm.depos} mode="key" keyProperty="value" allowEmpty />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>{ta("role")}</Form.Label>
              <div>
                {vm.roles.map(item => (
                  <CheckArray
                    inline
                    custom
                    target={vm.filter}
                    property="roles"
                    isNumeric={true}
                    key={item.value}
                    id={item.value.toString()}
                    label={item.label}
                  />
                ))}
              </div>
            </Form.Group>
          </Form.Row>

          <ButtonToolbar>
            <Button type="submit" size="lg">
              {tg("search")}
            </Button>

            {vm.isFilterChanged && (
              <Button type="reset" variant="outline-danger">
                &#215; {tg("reset_filter")}
              </Button>
            )}
          </ButtonToolbar>
        </Form>
      </Card>

      <PagingInfo paging={vm.currentPaging} modelName="result" localization={vm.localizationService} />

      <DataTable
        columns={[
          {
            dataField: "email",
            text: ta("login"),
            sort: true,
            title: true,
          },
          {
            dataField: "role",
            text: ta("role"),
            formatter: (cell, row) => row.role_enum.name,
            sort: true,
            title: (cell, row) => row.role_enum.name,
          },
          {
            dataField: "depo_ids",
            text: ta("depo_ids"),
            formatter: (cell, row) => {
              if (vm.allDepos(row as User)) {
                return tg("user.all_depots");
              } else {
                return (
                  <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                    {row.depo_enums.map((depo: models.Enum) => (
                      <li key={depo.id}>{formatDepoName(depo)}</li>
                    ))}
                  </ul>
                );
              }
            },
            title: (cell, row) => {
              if (vm.allDepos(row as User)) {
                return tg("user.all_depots");
              } else {
                return row.depo_enums.map((depo: models.Enum) => formatDepoName(depo)).join(", ");
              }
            },
          },
          {
            dataField: "id",
            text: ta("status"),
            align: "center",
            headerStyle: {
              width: "100px",
              textAlign: "center",
            },
            formatter: (cell, row: User) => {
              return tg(`user.status.${row.deleted_at ? "deleted" : "active"}`);
            },
            title: (cell, row: User) => {
              return tg(`user.status.${row.deleted_at ? "deleted" : "active"}`);
            },
          },
          {
            dataField: "edit" as any,
            isDummyField: true,
            text: ta("edit"),
            headerStyle: {
              width: "100px",
              textAlign: "center",
            },
            align: "center",
            formatter: (cell, row: User) => {
              if (row.deleted_at) {
                return "";
              }

              return (
                <Button
                  size="sm"
                  variant="outline-secondary"
                  className="noRowClick button-icon"
                  onClick={() => vm.openDetail(row)}>
                  <img src={iconEdit} alt={tg("user.update")} />
                </Button>
              );
            },
          },
          {
            dataField: "deleted_at",
            isDummyField: true,
            text: ta("delete"),
            headerStyle: {
              width: "100px",
              textAlign: "center",
            },
            align: "center",
            formatter: (cell, row: User) => {
              if (row.deleted_at) {
                return "";
              }

              return (
                <Button
                  size="sm"
                  variant="outline-danger"
                  className={`noRowClick button-icon ${vm.canBeDeleted(row) ? "" : "disabled"}`}
                  onClick={() => vm.remove(row)}>
                  <img src={iconDelete} alt={tg("user.delete")} />
                </Button>
              );
            },
          },
        ]}
        vm={vm}
        clickable={false}
      />
    </>
  );
});

export default UserList;
