import React, { useState } from "react";
import copy from "copy-to-clipboard";
import { Tooltip } from "react-tippy";
import { numberToChunks } from "entities/parcel";
import "react-tippy/dist/tippy.css";
import "./parcelNumber.scss";

interface ParcelNumberProps {
  parcelIdentifier: string;
  copyString: string;
  copiedString: string;
}

const formatExtId = (value: string) => {
  const chunks = numberToChunks(value);

  return chunks.map((chunk, index) => (
    <span key={index} className={index === chunks.length - 1 ? "font-weight-bold" : "pr-1"}>
      {chunk.concat()}
    </span>
  ));
};

function ParcelNumber(props: ParcelNumberProps) {
  const { parcelIdentifier, copyString, copiedString } = props;
  const [text, setText] = useState(copyString);

  const onClick = () => {
    copy(props.parcelIdentifier);
    setText(copiedString);
  };

  const onHidden = () => {
    setText(copyString);
  };

  return (
    <div className="parcel_number">
      <div className="number">{formatExtId(parcelIdentifier)}</div>
      <Tooltip
        title={text}
        trigger={"mouseenter"}
        arrow={true}
        animation="shift"
        position="bottom"
        size="small"
        hideOnClick={false}
        onHidden={onHidden}
        sticky={true}
        style={{ display: "inline-block" }}>
        <div className="copy_icon" onClick={onClick}></div>
      </Tooltip>
    </div>
  );
}

export default ParcelNumber;
