import { Select } from "@frui.ts/bootstrap";
import { StringInput } from "controls/stringInput";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { AdditionalInformationSectionProps } from "./types";

const PotentialPhase: FunctionComponent<AdditionalInformationSectionProps> = observer(({ vm }) => {
  const ta = (code: string) => vm.localizationService.translateAttribute("additional_information", code);

  return (
    <>
      <h3>{ta("potential_phase")}</h3>
      <Row>
        <Form.Group as={Col} lg={3} md={6} xs={12}>
          <Form.Label>{ta("potential_phase")}</Form.Label>
          <Select
            target={vm.item}
            property="potential_phase"
            items={vm.potentialPhases || []}
            mode="key"
            allowEmpty={true}
            textProperty="label"
            keyProperty="value"
            isNumeric={true}
          />
        </Form.Group>
      </Row>
      {vm.requiredNoteForPhaseIds.includes(vm.item.potential_phase ?? -1) && (
        <Row>
          <Form.Group as={Col} md={6} xs={12}>
            <Form.Label>{ta("potential_phase_note")}</Form.Label>
            <StringInput trim={true} as="textarea" target={vm.item} property="potential_phase_note" rows={3} />
          </Form.Group>
        </Row>
      )}
    </>
  );
});

export default PotentialPhase;
