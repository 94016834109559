import ParcelShop from "manualEntities/parcelShop";
import ParcelBoxDto from "entities/parcelBoxDto";
import { User, UserRole } from "manualEntities/user";
import EnumService from "./enum";
import UserContext from "./userContext";
import Subject from "entities/subject";
import PotentialPs from "../entities/potentialPs";
import PotentialPsDetailDto from "../entities/potentialPsDetailDto";

type ActionType = "read" | "edit" | "create";
type ResourceType =
  | "user"
  | "parcelShop"
  | "parcelBox"
  | "potential"
  | "invoicing"
  | "access_control"
  | "access_point"
  | "parcel"
  | "message"
  | "subject"
  | "paymentTerminal"
  | "rewards"
  | "settings"
  | "payment_stoppage"
  | "export_k2"
  | "memos"
  | "scanDevices"
  | "opportunities";

export default class SecurityService {
  constructor(private userContext: UserContext, private enums: EnumService) {}

  isAllowedToManage(action: "parcelShop" | "parcelBox") {
    if (this.userContext.userRoleId && action === "parcelBox") {
      return [UserRole.PortalAdmin, UserRole.PortalUserPB, UserRole.PortalUser].includes(this.userContext.userRoleId);
    }

    if (this.userContext.userRoleId && action === "parcelShop") {
      return [UserRole.PortalAdmin, UserRole.PortalUserPS, UserRole.PortalUser].includes(this.userContext.userRoleId);
    }

    return false;
  }

  isAllowed(action: ActionType, resourceType: "user", resource: User): boolean;
  isAllowed(action: ActionType, resourceType: "parcelShop", resource: ParcelShop): boolean;
  isAllowed(action: ActionType, resourceType: "parcelBox", resource: ParcelBoxDto): boolean;
  isAllowed(action: ActionType, resourtyType: "potential", resource: PotentialPsDetailDto): boolean;
  isAllowed(action: ActionType, resourceType: "subject", resource: Subject): boolean;
  isAllowed(action: ActionType, resourceType: "invoicing", resource: ParcelShop): boolean;
  isAllowed(action: ActionType, resourceType: "message", resource: ParcelShop): boolean;
  isAllowed(action: ActionType, resourceType: "access_control", resource: ParcelShop): boolean;
  isAllowed(action: ActionType, resourceType: "payment_stoppage", resource: ParcelShop): boolean;
  isAllowed(action: ActionType, resourceType: "export_k2", resource: ParcelShop): boolean;
  isAllowed(action: ActionType, resourceType: "memos", resource: ParcelShop): boolean;
  isAllowed(action: ActionType, resourceType: ResourceType): boolean;
  isAllowed(action: ActionType, resourceType: ResourceType, resource?: any) {
    const { isAdmin, userRoleId } = this.userContext;

    switch (resourceType) {
      case "user":
        return this.isAllowedForUser(action, resource);

      case "parcelShop":
      case "potential":
        return this.isAllowedWithDepoAndCategory(action, resource);

      case "paymentTerminal":
      case "access_point":
        return isAdmin;
      case "parcelBox":
        return isAdmin;

      case "rewards":
        return (!isAdmin && action === "read") || (isAdmin && action !== "read");

      case "invoicing":
      case "access_control":
      case "message":
        if (resource) {
          return this.isAllowedWithDepoAndCategory("edit", resource);
        } else {
          return isAdmin;
        }

      case "parcel":
      case "subject":
        return action === "read" || isAdmin;

      case "settings":
        return isAdmin;
      case "payment_stoppage":
        return isAdmin;

      case "export_k2":
        return isAdmin;

      case "memos":
        return action === "read" || isAdmin;

      case "scanDevices":
        return isAdmin || userRoleId !== UserRole.PortalUserPB;

      default:
        return true;
    }
  }

  isAllowedForUser(action: ActionType, user?: User): boolean {
    const { isAdmin } = this.userContext;

    if (user) {
      return isAdmin && this.userContext.userId !== user.id;
    } else {
      return isAdmin;
    }
  }

  isAllowedWithDepoAndCategory(action: ActionType, item?: ParcelShop | Subject | PotentialPs): boolean {
    const { isAdmin } = this.userContext;

    switch (action) {
      case "edit":
        if (item) {
          const allowedDepo = this.userContext.assignedDepos?.includes(item.depoId) ?? false;

          let allowedCategory = true;
          if (item instanceof ParcelShop && item.category_id) {
            allowedCategory = this.userContext.assignedCategories?.includes(item.category_id) ?? false;
          }
          return isAdmin || (allowedDepo && allowedCategory);
        } else {
          return isAdmin;
        }

      case "read":
      default:
        return true;
    }
  }

  getAllowedDepos() {
    const depos = this.enums.values("depos");

    if (this.userContext.isAdmin) {
      return depos;
    } else {
      return depos.filter(x => this.userContext.assignedDepos?.includes(x.id));
    }
  }
}
