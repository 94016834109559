import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Modal } from "react-bootstrap";
import ParcelBoxDetailViewModel from "viewModels/parcelBox/parcelBoxDetailViewModel";

export const BOX_SERVICE_URL = "https://www.ppl.cz";
export const TERMINAL_SERVICE_URL = "https://mobilniterminal.cz/ppl-parcelshop";

const parcelBoxDetailOrderServiceView: ViewComponent<ParcelBoxDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateModel: tm } = vm.localizationService;

  if (!vm.isOrderServiceFormVisible) {
    return null;
  }

  return (
    <Modal centered={true} show={true} onHide={vm.closeOrderServiceForm}>
      <Modal.Header closeButton>
        <Modal.Title>{tg("parcel_box.events.order_service.button")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center mt-3 mb-3">
          <a
            className="btn btn-outline-secondary"
            key="order_service_pb"
            href={BOX_SERVICE_URL}
            target="_new"
            onClick={vm.closeOrderServiceForm}>
            {tm("parcel_box_station", 1)}
          </a>
          &nbsp;
          <a
            className="btn btn-outline-secondary"
            key="order_service_ter"
            href={TERMINAL_SERVICE_URL}
            target="_new"
            onClick={vm.closeOrderServiceForm}>
            {tm("payment_terminal", 1)}
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default registerView(parcelBoxDetailOrderServiceView, ParcelBoxDetailViewModel, "orderService");
