import { bound } from "@frui.ts/helpers";
import { Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import Address, { AddressDisplayModel } from "entities/address";
import Contact, { ContactDisplayModel } from "entities/contact";
import OpeningHourDto, { OpeningHoursDisplayModel } from "entities/openingHourDto";
import NetworkStatusType from "manualEntities/networkStatusType";
import ParcelShopStatus from "manualEntities/parcelShopStatus";
import { action, computed, observable, runInAction } from "mobx";
import ShopDetailContext from "models/shops/shopDetailContext";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import EventBus, { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import SecurityService from "services/securityService";
import { formatDate, formatDepoName, formatSalesmanName, formatTime } from "utils/helpers";
import DetailWithSingleChildViewModelBase from "viewModels/detailWithSingleChildViewModelBase";
import EditShopViewModel from "./edit/editShopViewModel";
import { EditShopNotificationScope } from "./types";
import WarehouseViewModel from "./warehouse/warehouseViewModel";
import IAccessPointRepository from "../../../data/repositories/IAccessPointRepository";
import ParcelShop from "../../../manualEntities/parcelShop";
import AccessPointPartners from "../../../models/enumerations/accessPointPartners";
import PotentialPsDetailDto from "../../../entities/potentialPsDetailDto";
import ListActivityDto from "entities/listActivityDto";
import Competition from "entities/competition";
import ListOpportunityDto from "entities/listOpportunityDto";
import ListAttachmentDto from "entities/listAttachmentDto";
import AttachmentsRepository from "data/repositories/attachmentsRepository";
import EnumRepository from "data/repositories/enumRepository";
import { OpportunityStatus } from "entities/opportunity";
import UsersRepository from "data/repositories/usersRepository";
import { User } from "manualEntities/user";
import { IPagingFilter } from "@frui.ts/data";
import { ActivityStatus } from "entities/activity";
import { DAKTELA_URL } from "views/shops/detail/activities";
import ActivityDetailViewModel from "./activity/activityDetailViewModel";
import ActivityDetailDto from "entities/activityDetailDto";
import { ISelectItem } from "@frui.ts/views";
import ActivityDashboardUpdateDto from "entities/activityDashboardUpdateDto";
import UserContext from "services/userContext";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import CreateOpportunityDto from "entities/createOpportunityDto";
import CommonDetailViewModel from "./commonDetailViewModel";
import ShopsViewModel from "../shopsViewModel";
import RootViewModel from "viewModels/rootViewModel";
import OpportunitiesViewModel from "viewModels/opportunities/opportunitiesViewModel";

const PAGING_FILTER: IPagingFilter = {
  limit: 1000,
  offset: 0,
};

export default abstract class AccessPointViewModel<
  ContextType extends PotentialPsDetailDto | ParcelShop
> extends DetailWithSingleChildViewModelBase<ShopDetailContext<ContextType>, ScreenBase> {
  @observable contacts: ContactDisplayModel[];
  @observable addresses: AddressDisplayModel[];
  @observable openingHours: OpeningHoursDisplayModel[];
  @observable users: User[];
  @observable showOpportunityHistory = false;
  @observable showActivitiesHistory = false;
  @observable childDialog?: ScreenBase;

  constructor(
    protected shopId: number,
    protected warehouseVMFactory: ReturnType<typeof WarehouseViewModel.Factory>,
    protected editVMFactory: ReturnType<typeof EditShopViewModel.Factory>,
    protected activityVMFactory: ReturnType<typeof ActivityDetailViewModel.Factory>,
    protected repository: IAccessPointRepository<any>,
    public localizationService: LocalizationService,
    protected enumService: EnumService,
    protected security: SecurityService,
    protected confirmationService: ConfirmationService,
    protected notifications: NotificationService,
    protected eventBus: EventBus,
    protected attachmentsRepository: AttachmentsRepository,
    protected enumRepository: EnumRepository,
    private usersRepository: UsersRepository,
    private userContext: UserContext,
    protected router: Router
  ) {
    super();
    this.name = ""; // Name is lazy loaded later after fetch PS data
    this.navigationName = shopId.toString();
  }

  get parcelShop() {
    return this.item?.shop;
  }

  // TODO: @gusta Tyhle TODO se mi vůbec nelíbí, je to něco co má řešit view a ne VM..
  get typeName() {
    if (this.parcelShop) {
      return this.enumService.value("access_point_partners", this.parcelShop.typeId)?.name;
    } else {
      return undefined;
    }
  }

  get salesmanName() {
    if (this.parcelShop) {
      const service = this.enumService.value("salesmans", this.parcelShop.salesmanId ?? "0");
      return formatSalesmanName(service);
    } else {
      return undefined;
    }
  }

  get depoName() {
    if (this.parcelShop) {
      const depo = this.enumService.value("depos", this.parcelShop.depoId);
      return formatDepoName(depo);
    } else {
      return undefined;
    }
  }

  get networkPartnerName() {
    if (this.parcelShop && this.parcelShop instanceof ParcelShop) {
      return this.translateYesNo(this.parcelShop.network_partner !== NetworkStatusType.None);
    } else {
      return undefined;
    }
  }

  get activeName() {
    if (this.parcelShop && this.parcelShop instanceof ParcelShop) {
      return this.translateYesNo(this.parcelShop.status === ParcelShopStatus.Active);
    } else {
      return undefined;
    }
  }

  get potentialPhase() {
    if (this.parcelShop instanceof PotentialPsDetailDto && this.parcelShop.phase !== undefined) {
      return this.enumService.value("potential_phases", this.parcelShop.phase)?.name;
    } else {
      return undefined;
    }
  }

  @computed get isParcelShopType() {
    if (this.parcelShop instanceof ParcelShop) {
      return this.enumService.value("access_point_partners", this.parcelShop.type_id)?.code == AccessPointPartners.PARCEL_SHOP;
    } else {
      return (
        this.enumService.value("access_point_partners", this.parcelShop.typeId)?.code == AccessPointPartners.PARCEL_SHOP_POTENTIAL
      );
    }
  }

  get opportunities() {
    return this.parcelShop?.opportunities?.filter(opportunity => {
      return this.showOpportunityHistory ? true : opportunity.enumStatusId != OpportunityStatus.Complete;
    });
  }

  get isAlzaBox() {
    return this.parcelShop?.typeId === +AccessPointPartners.ALZA_BOX;
  }

  get activities() {
    const activities = this.parcelShop?.activities ?? [];
    return activities
      .filter(activity => {
        return this.showActivitiesHistory ? true : ![ActivityStatus.Resolved, ActivityStatus.Cancelled].includes(activity.state);
      })
      .sort((a, b) =>
        Date.parse(`${a.date.toDateString()} ${a.time}`) < Date.parse(`${b.date.toDateString()} ${b.time}`) ? -1 : 1
      );
  }

  onInitialize() {
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.AccessPoints.Changed, this.loadData));
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.AccessPoints.Addresses.Changed, this.loadData));
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.AccessPoints.Contacts.Changed, this.loadData));
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.ParcelShops.OpeningHours.Changed, this.loadData));
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.ParcelShops.Vacations.Changed, this.loadData));
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.PaymentTerminals.Changed, this.loadData));
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.Opportunities.Changed, this.loadData));
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.Activities.Changed, this.loadData));

    return super.onInitialize();
  }

  @bound
  @watchBusy
  async loadUsers() {
    const roles = this.enumService.values("crm_users").map(item => item.id);
    const response = await this.usersRepository.getUsers(PAGING_FILTER, { roles });
    runInAction(() => {
      this.users = response[0] ?? [];
    });
  }

  @bound
  @watchBusy
  async loadData() {
    const data = await this.loadDetail();
    if (this.item) {
      runInAction(() => {
        // we want to update the shop within the same instance of context so that is gets updated in all clients having a reference
        this.item.shop = data.shop;
        this.eventBus.publish(Events.AccessPoints.Context.Updated);
      });
    } else {
      this.setItem(data);
    }
  }

  @watchBusy
  async loadDetail() {
    if (this.shopId) {
      await this.loadUsers();

      const [detail, contacts, addresses, openingHours] = await Promise.all([
        this.repository.getDetail(this.shopId),
        this.repository.getContacts(this.shopId),
        this.repository.getAddresses(this.shopId),
        this.repository.getOpeningHours(this.shopId),
      ]);

      runInAction(() => {
        this.name = detail.name;
        this.contacts = contacts.map(this.getContactDisplayModel);
        this.addresses = addresses.map(this.getAddressDisplayModel);
        this.openingHours = this.getOpeningHoursDisplayModels(openingHours);
      });

      const context = new ShopDetailContext<ContextType>();
      context.shop = detail;
      return context;
    } else {
      throw new Error("View model has not been initialized (missing originalItem).");
    }
  }

  @bound private getContactDisplayModel(input: Contact) {
    return {
      ...input,
      typeName: this.enumService.value("contact_types", input.contactTypeId)?.name,
    } as ContactDisplayModel;
  }

  @bound private getAddressDisplayModel(input: Address) {
    return {
      ...input,
      typeName: this.enumService.value("address_types", input.addressTypeId)?.name,
      countryName: this.enumService.value("countries", input.countryId)?.name,
    } as AddressDisplayModel;
  }

  private getOpeningHoursDisplayModels(hours: OpeningHourDto[]) {
    return [
      this.getDayOpeningHours(2, this.localizationService.translateGeneral("monday"), hours),
      this.getDayOpeningHours(3, this.localizationService.translateGeneral("tuesday"), hours),
      this.getDayOpeningHours(4, this.localizationService.translateGeneral("wednesday"), hours),
      this.getDayOpeningHours(5, this.localizationService.translateGeneral("thursday"), hours),
      this.getDayOpeningHours(6, this.localizationService.translateGeneral("friday"), hours),
      this.getDayOpeningHours(7, this.localizationService.translateGeneral("saturday"), hours),
      this.getDayOpeningHours(1, this.localizationService.translateGeneral("sunday"), hours),
    ];
  }

  private getDayOpeningHours(day: number, dayName: string, hours: OpeningHourDto[]) {
    const morning = hours.find(x => x.weekDay === day && x.dayPart === 0);
    const afternoon = hours.find(x => x.weekDay === day && x.dayPart === 1);

    return {
      dayName: dayName,
      morning: morning
        ? `${formatTime(morning.openFrom)}-${formatTime(morning.openTo)}`
        : this.localizationService.translateGeneral("parcel_shop.hours_closed"),
      afternoon: afternoon
        ? `${formatTime(afternoon.openFrom)}-${formatTime(afternoon.openTo)}`
        : this.localizationService.translateGeneral("parcel_shop.hours_closed"),
    } as OpeningHoursDisplayModel;
  }

  get canDisableShop() {
    if (this.parcelShop instanceof ParcelShop) {
      return (
        this.parcelShop.status === ParcelShopStatus.Unactive && this.security.isAllowed("edit", "parcelShop", this.parcelShop)
      );
    } else {
      return false;
    }
  }

  @bound async disableShop(typeName: "ParcelShop" | "ParcelBox") {
    const tg = this.localizationService.translateGeneral;

    const canDisable = await this.confirmationService.showConfirmation(
      tg("parcel_shop.disable_shop.text").replace("%name%", this.parcelShop.name).replace("%type%", typeName),
      tg("parcel_shop.disable_shop.title"),
      { variant: "danger", text: tg("parcel_shop.disable_shop.confirm") },
      tg("parcel_shop.disable_shop.cancel")
    );

    if (canDisable) {
      try {
        await this.repository.disableItem(this.parcelShop.id);

        this.notifications.addNotification(
          this.localizationService.translateGeneral("parcel_shop.disable_shop.disabled_message"),
          SeverityLevel.success,
          EditShopNotificationScope
        );
      } catch (e) {
        // Nothing to do
      }
    }
  }

  get canOpenWarehouse() {
    if (this.parcelShop instanceof ParcelShop) {
      return this.parcelShop.status !== ParcelShopStatus.Disabled;
    } else {
      return false;
    }
  }

  get canEdit() {
    if (this.parcelShop instanceof ParcelShop) {
      return (
        this.parcelShop.status !== ParcelShopStatus.Disabled && this.security.isAllowed("edit", "parcelShop", this.parcelShop)
      );
    } else {
      return this.security.isAllowed("edit", "potential", this.parcelShop);
    }
  }

  get isPotential() {
    const potentialTypes: number[] = [+AccessPointPartners.PARCEL_BOX_POTENTIAL, +AccessPointPartners.PARCEL_SHOP_POTENTIAL];
    return potentialTypes.includes(this.item.shop.typeId);
  }

  get activityStates(): ISelectItem[] {
    return this.enumService.values("activity_states").map(item => ({ value: item.id, label: item.name }));
  }

  @bound openEdit() {
    if (!this.canEdit) {
      return;
    }

    const viewModel = this.editVMFactory(this.item);
    this.tryActivateChild(viewModel);
  }

  @bound
  openNewOpportunity() {
    // I'm so sorry, they wanted the navigation url to match the child.
    const commonDetailVM = this.parent as CommonDetailViewModel;
    const dashboardVM = commonDetailVM.parent as ShopsViewModel;
    const rootVM = dashboardVM.parent as RootViewModel;
    const opportunitiesVM = rootVM.children?.find(
      (vm: ScreenBase) => vm.navigationName == "opportunities"
    ) as OpportunitiesViewModel;
    const oppportunity = new CreateOpportunityDto();
    oppportunity.psId = this.item.shop.id;
    oppportunity.entityType = +this.isPotential;
    void rootVM.tryActivateChild(opportunitiesVM);
    void opportunitiesVM.openDetail(oppportunity, true);
  }

  @bound
  printOpportunity(opportunity: ListOpportunityDto) {
    const { enumOpportunityId } = opportunity;

    const opportunityName =
      this.enumService.value(this.isParcelShopType ? "ps_opportunities" : "pb_opportunities", enumOpportunityId)?.name ?? "";

    return `${opportunityName}`;
  }

  @bound
  printActivity(activity: ListActivityDto) {
    const { date, time, type, category, state, comment, subject, daktelaId } = activity;

    const stateName = this.enumService.value("activity_states", state)?.name ?? "";
    let row = "";

    if (daktelaId) {
      row = `${formatDate(date)}, ${stateName}`;

      if (subject) {
        row += `, ${subject}`;
      }
    } else {
      const typeName = this.enumService.value("activity_types", type)?.name ?? "";
      const categoryName = this.enumService.value("activity_categories", category)?.name ?? "";

      row = `${formatDate(date)}, ${time}, ${typeName}, ${categoryName}, ${stateName}`;
    }

    if (comment) {
      row += `, ${comment}`;
    }

    return row;
  }

  @bound
  printCompetition(competition: Competition) {
    const { enumCompetitionId, capacity, codRate, withoutCodRate, returnedRate, monthlyRent, comment } = competition;
    const competitionName =
      this.enumService.value(this.isParcelShopType ? "ps_competitions" : "pb_competitions", enumCompetitionId ?? 0)?.name ?? "";

    const ta = (code: string) => this.localizationService.translateAttribute("competition", code);
    const currencyCode = this.localizationService.translateGeneral("currency_crown");

    const nameText = `${ta("enum_competition_id")}: ${competitionName}`;
    let capacityText;
    if (capacity) {
      capacityText = `${ta("capacity")}: ${capacity}`;
    }
    let codRateText;
    if (codRate) {
      codRateText = `${ta("cod_rate")}: ${codRate} ${currencyCode}`;
    }
    let withoutCodRateText;
    if (withoutCodRate) {
      withoutCodRateText = `${ta("without_cod_rate")}: ${withoutCodRate} ${currencyCode}`;
    }
    let returnedRateText;
    if (returnedRate) {
      returnedRateText = `${ta("returned_rate")}: ${returnedRate} ${currencyCode}`;
    }
    let monthlyRentText;
    if (monthlyRent) {
      monthlyRentText = `${ta("monthly_rent")}: ${monthlyRent} ${currencyCode}`;
    }
    let commentText;
    if (comment) {
      commentText = `${ta("comment")}: ${comment}`;
    }

    return [nameText, capacityText, codRateText, withoutCodRateText, returnedRateText, monthlyRentText, commentText]
      .filter(item => item !== undefined)
      .join("; ");
  }

  @bound
  printBusinessState() {
    const { businessState, contractEndsAt, terminationReason } = this.parcelShop as ParcelShop;
    if (businessState !== undefined && businessState !== null) {
      const businessStateName = this.enumService.value("business_states", businessState)?.name ?? "";
      let info = "";
      if (businessState === 1 && !this.isParcelShopType) {
        info = contractEndsAt ? `,  Doba určitá, ${formatDate(contractEndsAt)}` : ", Doba neurčitá";
      } else if (businessState === 4) {
        const terminationReasonName =
          this.enumService.value(this.isParcelShopType ? "ps_terminations" : "pb_terminations", terminationReason || 0)?.name ??
          "";
        info = `, ${terminationReasonName}`;
      }

      return `${businessStateName}${info}`;
    }

    return "";
  }

  @bound
  printBusinessType() {
    const { businessTypeId } = this.parcelShop;
    if (businessTypeId !== undefined && businessTypeId !== null) {
      return this.enumService.value(this.isParcelShopType ? "ps_types" : "pb_types", businessTypeId ?? 0)?.name ?? "";
    }

    return "";
  }

  @bound
  downloadFile(file: ListAttachmentDto) {
    void this.attachmentsRepository.downloadAttachment(file.id, file.originalFilename);
  }

  @bound
  daktelaLink(daktelaId: number) {
    return `${DAKTELA_URL}/${daktelaId}`;
  }

  translateYesNo(value: boolean) {
    return this.localizationService.translateGeneral(value ? "yes" : "no");
  }

  @bound
  getDeviceTypeName(id: number) {
    return this.enumService.value("scanners", id)?.device_type;
  }

  @bound
  getOpportunityType(id: number) {
    return this.enumsForSelect("opportunities", id);
  }

  @bound
  getOpportunityStatus(id: number) {
    return this.enumsForSelect("opportunity_states", id);
  }

  @bound
  getActivityState(id: number) {
    return this.enumService.value("activity_states", id)?.name;
  }

  @bound
  getActivityType(id: number) {
    return this.enumService.value("activity_types", id)?.name;
  }

  @bound
  getActivityCategory(id: number) {
    return this.enumService.value("activity_categories", id)?.name;
  }

  enumsForSelect(name: string, id: number) {
    const parcelBoxTypes: number[] = [
      +AccessPointPartners.PARCEL_BOX,
      +AccessPointPartners.PARCEL_BOX_POTENTIAL,
      +AccessPointPartners.ALZA_BOX,
    ];

    if (parcelBoxTypes.indexOf(this.item.shop.typeId) != -1) {
      return this.enumService.value(`pb_${name}`, id)?.name;
    } else {
      return this.enumService.value(`ps_${name}`, id)?.name;
    }
  }

  @bound
  getUserName(id: number) {
    const user = this.users?.find(user => id == user.id);
    if (!user) return "";
    return user.first_name && user.last_name ? `${user.first_name}  ${user.last_name}` : user.email;
  }

  @bound toggleOpportunityHistory() {
    this.showOpportunityHistory = !this.showOpportunityHistory;
  }

  @bound toggleActivitiesHistory() {
    this.showActivitiesHistory = !this.showActivitiesHistory;
  }

  @bound
  isAllowedToChangeActivityState(assignees: number[]) {
    return assignees.includes(this.userContext.userId!) || this.userContext.isAdmin;
  }

  @action.bound
  async openActivity(item: ActivityDetailDto | undefined) {
    const vm = this.activityVMFactory(this.users, this.shopId, +this.isPotential, item);
    vm.parent = this;

    await vm.activate();
    this.childDialog = vm;
  }

  @bound
  @watchBusy
  async changeActivityState(id: number, newState: number) {
    const item: ActivityDashboardUpdateDto = { state: newState };
    await (this.repository as ParcelShopRepository).updateActivityStatus(id, item);

    const msg = this.localizationService.translateGeneral("activities.updated");
    this.notifications.addNotification(msg, SeverityLevel.success);
  }

  protected async findNavigationChild(name: string): Promise<ScreenBase | undefined> {
    if (!this.item) {
      await this.onInitialize();
    }

    if (name === EditShopViewModel.DefaultNavigationName && this.canEdit) {
      return this.editVMFactory(this.item);
    }

    return undefined;
  }

  closeChild(child: ScreenBase, forceClose = false): Promise<boolean> | boolean {
    if (child === this.childDialog) {
      runInAction(() => (this.childDialog = undefined));
      return true;
    } else {
      return forceClose ? this.deactivateChild(child, true).then(() => true) : this.tryDeactivateChild(child, true);
    }
  }
}
