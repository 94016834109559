import React from "react";

import "./emptySearchResults.scss";

export interface EmptySearchResultsProps {
  empty: string;
}

export default (props: EmptySearchResultsProps) => {
  const baseClassName = "empty-search-results";

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__icon`} />
      <div className={`${baseClassName}__text`}>{props.empty}</div>
    </div>
  );
};
