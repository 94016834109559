import { models } from "models/shops/interface";
import { RepositoryBase } from "../repositoryBase";

export default class LocalizationRepository extends RepositoryBase {
  fetchLocalization() {
    return this.callApi(api => {
      return api.all("config/locale").get<models.LocalizeContainer>();
    });
  }
}
