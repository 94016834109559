import iconExpand from "assets/icons/icon-expand.svg";
import React, { useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import "./collapseSection.scss";

const CollapseSection: React.SFC<{ title?: string; opened?: boolean }> = ({ title, opened, children }) => {
  const [open, setOpen] = useState(opened === undefined || opened);

  return (
    <>
      <h5 className="collapse-header">
        <Button onClick={() => setOpen(!open)} aria-expanded={open} className="btn-collapse">
          <img src={iconExpand} className={open ? "icon-collapse" : "icon-expand"} alt="toggle" />
        </Button>
        {title}
      </h5>
      <Collapse in={open}>
        <section>{children}</section>
      </Collapse>
    </>
  );
};

export default CollapseSection;
