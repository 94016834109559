// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
type ScanDeviceImportStatusDtoResult = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12";
enum ScanDeviceImportStatusResult {
  Imported = 0,
  ErrorPsDoesNotExist = 1,
  ErrorTidExists = 2,
  ErrorSnExists = 3,
  ErrorPsAlreadyHasTerminal = 4,
  ErrorEpsFail = 5,
}

export default ScanDeviceImportStatusResult;
