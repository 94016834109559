import { registerView, ViewComponent } from "@frui.ts/views";
import HeaderRow from "controls/headerRow";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonToolbar, Col, Form } from "react-bootstrap";
import FormLabel from "react-bootstrap/FormLabel";
import { toJS } from "mobx";
import MessageGroupDetailViewModel from "viewModels/messageGroups/messageGroupDetailViewModel";
import preventDefault from "../preventDefault";
import TreeList from "../../controls/treeList";
import "../messages/style.scss";
import { StringInput } from "controls/stringInput";

const messageGroupDetailView: ViewComponent<MessageGroupDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute: ta } = vm.localizationService;
  if (!vm.item) {
    return (
      <>
        <HeaderRow title={vm.isCreating ? tg("message_group.create.title") : tg("message_group.edit.title")} />
        <LoadingOverlay loading={vm.busyWatcher.isBusy} />
      </>
    );
  }

  return (
    <>
      <HeaderRow title={vm.isCreating ? tg("message_group.create.title") : tg("message_group.edit.title")} />
      <Form onSubmit={preventDefault(vm.isCreating ? vm.create : vm.update)}>
        <Form.Row>
          <Col md="6">
            <Form.Group>
              <FormLabel column={false}>{ta("message_group", "name")}</FormLabel>
              <StringInput trim={true} type="text" target={vm.item} property="name" />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Group>
          <FormLabel column={false}>{ta("message", "recipient")}</FormLabel>
          <TreeList
            options={toJS(vm.treeData)}
            value={toJS(vm.selectedRecipients)}
            multiple={true}
            loadOptions={vm.loadData}
            onChange={vm.onChange as any}
          />
        </Form.Group>

        <ButtonToolbar>
          <Button type="submit" disabled={!vm.canSave} size="lg">
            {vm.isCreating ? tg("create") : tg("save")}
          </Button>
          <Button variant="outline-secondary" onClick={vm.requestClose} size="lg">
            {tg("cancel")}
          </Button>
        </ButtonToolbar>
      </Form>
      <LoadingOverlay loading={vm.busyWatcher.isBusy} />
    </>
  );
});
export default registerView(messageGroupDetailView, MessageGroupDetailViewModel);
