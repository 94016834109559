import { ScreenBase } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import { ISelectItem } from "@frui.ts/views";
import { interfaces } from "inversify";
import { action, computed, IReactionDisposer, observable, reaction, runInAction } from "mobx";
import ShopInformation from "models/shops/newShop/shopInformation";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import SecurityService from "services/securityService";
import { depoSelectOption, salesmanSelectOption } from "../../../utils/helpers";
import { IShopInformationViewModel } from "../detail/types";
import NewShopConfigurationViewModel from "./newShopConfigurationViewModel";
import { IStepViewModel } from "./types";
import AccessPointPartners from "../../../models/enumerations/accessPointPartners";
import isPotential from "../../../utils/isPotential";

export default class NewShopInformationViewModel extends ScreenBase implements IStepViewModel, IShopInformationViewModel {
  @observable item: ShopInformation;
  @observable.shallow depos: ISelectItem[];
  protected reactionDisposers = [] as IReactionDisposer[];

  public readonly isEditing = false;

  @computed
  get potentialTypeIds() {
    return this.enums
      .values("access_point_partners")
      .filter(item =>
        ([AccessPointPartners.PARCEL_BOX_POTENTIAL, AccessPointPartners.PARCEL_SHOP_POTENTIAL] as string[]).includes(item.code)
      )
      .map(item => item.id);
  }

  constructor(
    item: ShopInformation,
    public isParcelBox: boolean,
    private configurationVM: NewShopConfigurationViewModel, // Current shop configuration
    public localizationService: LocalizationService,
    private enums: EnumService,
    private security: SecurityService,
    private confirmationService: ConfirmationService
  ) {
    super();
    this.name = localizationService.translateGeneral("parcel_shop.information");

    attachAutomaticValidator(item, ShopInformation.ValidationRulesByType(this.isParcelBox, this.potentialTypeIds));

    // Reset salesmanId when is not presented in current enums values
    if (this.salesmans.find(salesman => salesman.value === item.salesmanId) === undefined) {
      item.salesmanId = undefined;
    }

    this.item = item;
  }

  @action
  onInitialize() {
    this.depos = this.security.getAllowedDepos().map(depoSelectOption);
    this.registerReactions();
  }

  get salesmans() {
    return this.enums
      .values("salesmans")
      .filter(item => (this.isParcelBox ? item.pb_specialist : item.ps_specialist))
      .map(salesmanSelectOption);
  }

  @computed
  get isPotential() {
    const partnerCode = this.enums.value("access_point_partners", this.item.typeId)?.code;
    return isPotential(partnerCode ?? "");
  }

  get isAlzaBox() {
    return this.isParcelBox;
  }

  get accessPointPartners() {
    const parcelBoxTypes: string[] = [
      AccessPointPartners.PARCEL_BOX,
      AccessPointPartners.PARCEL_BOX_POTENTIAL,
      AccessPointPartners.ALZA_BOX,
    ];

    return this.enums
      .values("access_point_partners")
      .filter(item => (this.isParcelBox ? parcelBoxTypes.includes(item.code) : !parcelBoxTypes.includes(item.code)));
  }

  get canContinue() {
    return !hasVisibleErrors(this.item);
  }

  validate() {
    return validate(this.item);
  }

  private registerReactions() {
    this.reactionDisposers.push(
      reaction(
        () => this.item.typeId,
        typeId => void this.onAccessPointPartnerChange(typeId)
      )
    );
  }

  private async onAccessPointPartnerChange(typeId?: number) {
    if (!typeId) return; // Null selected

    const zabkaPoint = this.enums.value("access_point_partners", typeId)?.code === "2";
    if (!zabkaPoint) return;

    // Check filled information imei / dhl express - show modal for confirmation
    const configuration = this.configurationVM.item;
    if (configuration?.dhl_express) {
      const confirmation = await this.confirmationService.showConfirmation(
        this.localizationService.translateGeneral("change_zabka_point.text"),
        this.localizationService.translateGeneral("change_zabka_point.title"),
        {
          text: this.localizationService.translateGeneral("change_zabka_point.confirm"),
          variant: "primary",
        },
        {
          text: this.localizationService.translateGeneral("change_zabka_point.back"),
          variant: "outline-secondary",
        }
      );

      if (confirmation) {
        // Cleean values
        runInAction(() => {
          configuration.dhl_express = false;
          configuration.facility = undefined;
          configuration.route_code = undefined;
          configuration.service_area = undefined;
          configuration.service_point_id = undefined;
        });
      } else {
        // Set back
        runInAction(() => {
          this.item.typeId = this.enums.value("access_point_partners", "1", true)!.id;
        });
      }
    }
  }

  protected onDeactivate(close: boolean) {
    if (close) {
      const reactionDisposers = this.reactionDisposers;
      this.reactionDisposers = [];
      reactionDisposers.forEach(disposer => disposer());
    }

    return super.onDeactivate(close);
  }

  static Factory({ container }: interfaces.Context) {
    return (item: ShopInformation, isParcelBox: boolean, configurationVM: NewShopConfigurationViewModel) =>
      new NewShopInformationViewModel(
        item,
        isParcelBox,
        configurationVM,
        container.get(LocalizationService),
        container.get(EnumService),
        container.get(SecurityService),
        container.get(ConfirmationService)
      );
  }
}
