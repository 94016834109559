import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import OpportunityDetailDtoPs from "./opportunityDetailDtoPs";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class OpportunityDetailDto {
  /**
   * id
   */
  id!: number;

  @observable
  @Type(() => OpportunityDetailDtoPs)
  ps?: OpportunityDetailDtoPs;

  /**
   * Ps id
   */
  @observable
  @Expose({ name: "ps_id" })
  psId?: number;

  /**
   * Entity type
   */
  @observable
  @Expose({ name: "entity_type" })
  entityType!: number;

  /**
   * enum_opportunity_id
   */
  @observable
  @Expose({ name: "enum_opportunity_id" })
  enumOpportunityId!: number;

  /**
   * fulfillment date
   */
  @observable
  @Type(() => Date)
  date!: Date;

  /**
   * fulfillment time
   */
  @observable
  time!: string;

  /**
   * enum_opportunity_status_id
   */
  @observable
  @Expose({ name: "enum_status_id" })
  enumStatusId!: number;

  /**
   * enum_opportunity_status_reason_id
   */
  @observable
  @Expose({ name: "enum_status_reason_id" })
  enumStatusReasonId!: number;

  /**
   * enum_competition_id
   */
  @observable
  @Expose({ name: "enum_competition_id" })
  enumCompetitionId!: number;

  /**
   * Solved capacity
   */
  @observable
  capacity!: number;

  /**
   * Note
   */
  @observable
  note!: string;

  /**
   * State changed at
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "state_changed_at" })
  stateChangedAt!: Date;

  /**
   * Author
   */
  @observable
  @Expose({ name: "author_id" })
  authorId!: number;

  /**
   * Created at
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    psId: { number: true },
    entityType: { required: true, number: true },
    enumOpportunityId: { required: true, number: true },
    date: { required: true },
    time: { required: true },
    enumStatusId: { required: true, number: true },
    enumStatusReasonId: { required: true, number: true },
    enumCompetitionId: { required: true, number: true },
    capacity: { required: true, number: true },
    note: { required: true },
    stateChangedAt: { required: true },
    authorId: { required: true, number: true },
    createdAt: { required: true },
  };
}
