import { BusyWatcher, ScreenBase } from "@frui.ts/screens";
import { attachAutomaticValidator } from "@frui.ts/validation";
import Address from "entities/address";
import AddressDto from "entities/addressDto";
import { chain } from "lodash";
import { action, observable } from "mobx";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import { AddressModel, IShopAddressesViewModel } from "../types";

export default abstract class AddressesViewModelBase extends ScreenBase implements IShopAddressesViewModel {
  busyWatcher = new BusyWatcher();
  @observable items: AddressModel[] = [];

  constructor(private defaultAddressName: string, public localizationService: LocalizationService, protected enums: EnumService) {
    super();
  }

  types(address: AddressModel) {
    // In address types there are unique flag, with this type only 1 address could exists
    return this.enums.values("address_types").filter(type => {
      // Not unique as many as we want
      if (!type.unique) {
        return true;
      }

      // There is an address with this type
      if (this.items.findIndex(x => x.addressTypeId === type.id && x !== address) >= 0) {
        return false;
      }

      // any other cases...
      return true;
    });
  }

  get countries() {
    return this.enums.values("countries");
  }

  get customErrorMessage() {
    const duplicateTypes = chain(this.items)
      .filter(x => x.default)
      .groupBy(x => x.addressTypeId)
      .toPairs()
      .filter(x => x[1].length > 1)
      .map(x => x[0])
      .value();

    if (duplicateTypes.length) {
      const type = this.enums.value("address_types", duplicateTypes[0])?.name ?? duplicateTypes[0];
      return this.localizationService.translateGeneral("validators.uniqueDefaultAddressType").replace("%params%", type);
    } else {
      return undefined;
    }
  }

  @action.bound addNewAddress() {
    this.addAddress(new Address());
  }

  protected addAddress(address: Address) {
    address.name = address.name || this.defaultAddressName;
    attachAutomaticValidator(address, AddressDto.ValidationRules);
    this.items.push(address);
  }

  abstract removeAddress(address: AddressModel): void;
}
