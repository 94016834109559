import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Alert } from "react-bootstrap";
import AlertViewModel from "viewModels/alertViewModel";

const alertView: ViewComponent<AlertViewModel> = observer(({ vm }) => {
  return (
    <Alert variant={vm.variant} dismissible={true} closeLabel="&times;" onClose={vm.onClose}>
      {vm.message}
    </Alert>
  );
});

export default registerView(alertView, AlertViewModel);
