import { observable } from "mobx";
import { Expose, Type } from "class-transformer";
import OpeningHourDto from "./openingHourDto";
import PotentialAddressDto from "./potentialAddressDto";
import PotentialContactDto from "./potentialContactDto";
import CreateActivityInPsDto from "./createActivityInPsDto";
import AttachToPsDto from "./attachToPsDto";
import CreateCompetitionInPsDto from "./createCompetitionInPsDto";
import CreateOpportunityInPsDto from "./createOpportunityInPsDto";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CreatePotentialPsDto {
  /**
   * Subject id from Create / GET subject request
   */
  @observable
  @Expose({ name: "subject_id" })
  subjectId!: number;

  /**
   * PS Type (enum value `enum_access_point_partners`)
   */
  @observable
  @Expose({ name: "type_id" })
  typeId!: number;

  /**
   * Potential PS Type (enum value `ps/pb_types`)
   */
  @observable
  @Expose({ name: "business_type_id" })
  businessTypeId?: number;

  @observable
  name!: string;

  @observable
  subname?: string;

  /**
   * Description for EPS
   */
  @observable
  description?: string;

  @observable
  @Expose({ name: "description_crm" })
  descriptionCrm?: string;

  /**
   * Device IMEI
   */
  @observable
  imei?: string;

  @observable
  @Expose({ name: "phone_type" })
  phoneType?: string;

  /**
   * Salesman partners enumeration
   */
  @observable
  @Expose({ name: "salesman_id" })
  salesmanId?: number;

  /**
   * Depo reference key
   */
  @observable
  @Expose({ name: "depo_id" })
  depoId!: number;

  @observable
  latitude?: number;

  @observable
  longitude?: number;

  @observable
  capacity?: number;

  @observable
  @Expose({ name: "max_daily_capacity" })
  maxDailyCapacity?: number;

  /**
   * Opening hours - always open flag
   */
  @observable
  @Expose({ name: "always_open" })
  alwaysOpen?: boolean;

  /**
   * PBox electricity price
   */
  @observable
  @Expose({ name: "electricity_price" })
  electricityPrice?: number;

  /**
   * PBox rent price
   */
  @observable
  @Expose({ name: "rent_price" })
  rentPrice?: number;

  /**
   * PBox invoicing cycle
   */
  @observable
  @Expose({ name: "pb_invoicing_cycle" })
  pbInvoicingCycle?: number;

  /**
   * Potential phase
   */
  @observable
  phase?: number;

  /**
   * Potential phase note (only Lost and FutureOpportunity)
   */
  @observable
  @Expose({ name: "phase_note" })
  phaseNote?: string;

  /**
   * Capacity management
   */
  @observable
  @Expose({ name: "enforce_sizes" })
  enforceSizes!: boolean;

  /**
   * Accepted size
   */
  @observable
  @Expose({ name: "accepted_size" })
  acceptedSize!: string[];

  @observable
  @Type(() => PotentialContactDto)
  contacts?: PotentialContactDto[];

  @observable
  @Type(() => PotentialAddressDto)
  addresses?: PotentialAddressDto[];

  @observable
  @Type(() => OpeningHourDto)
  @Expose({ name: "opening_hours" })
  openingHours?: OpeningHourDto[];

  @observable
  @Type(() => CreateCompetitionInPsDto)
  competitions?: CreateCompetitionInPsDto[];

  @observable
  @Type(() => AttachToPsDto)
  attachments?: AttachToPsDto[];

  static ValidationRules = {
    subjectId: { required: true, number: true },
    typeId: { required: true, number: true },
    businessTypeId: { number: true },
    name: { maxLength: 250, required: true },
    subname: { maxLength: 250 },
    salesmanId: { number: true },
    depoId: { required: true, number: true },
    latitude: { number: true },
    longitude: { number: true },
    capacity: { number: true },
    maxDailyCapacity: { number: true },
    electricityPrice: { number: true },
    rentPrice: { number: true },
    pbInvoicingCycle: { number: true },
    phase: { number: true },
    enforceSizes: { required: true },
    acceptedSize: { required: true },
  };
}
