import { ValidationControlBase } from "@frui.ts/bootstrap";
import { CommonInputProps } from "@frui.ts/bootstrap/dist/controls/commonInputProps";
import { bound } from "@frui.ts/helpers";
import { IBindingProps } from "@frui.ts/views";
import React from "react";
import { Form, FormControlProps } from "react-bootstrap";
import MaskedInput from "react-text-mask";

export class ZipInput<TTarget> extends ValidationControlBase<
  TTarget,
  FormControlProps & CommonInputProps & IBindingProps<TTarget>
> {
  @bound protected renderInner() {
    const validationError = this.getValidationError();

    let stringValue: string | undefined;
    if (this.props.type === "number" && typeof this.value === "number") {
      stringValue = this.value.toString();
    } else {
      stringValue = this.value as string;
    }

    return (
      <>
        <MaskedInput
          mask={[/\d/, /\d/, /\d/, " ", /\d/, /\d/]}
          className={`form-control ${validationError ? "is-invalid" : ""}`}
          guide={true}
          showMask={true}
          value={stringValue}
          onChange={this.handleValueChanged}
        />
        {validationError && <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>}
      </>
    );
  }

  @bound protected handleValueChanged(e: React.FormEvent<any>) {
    const target = e.target as HTMLInputElement;
    const valueWithoutMask = target.value.replace(/_/g, "").replace(" ", "");

    if (this.props.type === "number") {
      this.setNumber(valueWithoutMask);
    } else {
      this.setValue(valueWithoutMask);
    }
  }

  private setNumber(value: string) {
    if (value) {
      this.setValue(parseInt(value, 10));
    } else {
      this.setValue(undefined);
    }
  }
}
