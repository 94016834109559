import { IPagingFilter, PagedQueryResult } from "@frui.ts/data";
import CreateMemoDto from "entities/createMemoDto";
import MemoDetailDto from "entities/memoDetailDto";
import UpdateMemoDto from "entities/updateMemoDto";
import { Events } from "services/eventBus";
import { RepositoryBase } from "../repositoryBase";

export default class MemosRepository extends RepositoryBase {
  getMyMemos(paging: IPagingFilter, filter: any): Promise<PagedQueryResult<MemoDetailDto>> {
    return this.callApi(api => api.all("memos/mine").getPagedEntities(MemoDetailDto, paging, filter));
  }

  getTeamMemos(paging: IPagingFilter, filter: any): Promise<PagedQueryResult<MemoDetailDto>> {
    return this.callApi(api => api.all("memos/team").getPagedEntities(MemoDetailDto, paging, filter));
  }

  getMemo(id: number): Promise<MemoDetailDto> {
    return this.callApi(api => api.one("memos", id).getEntity(MemoDetailDto));
  }

  createMemo(payload: CreateMemoDto) {
    return this.callApi(api => api.all("memos").postEntity(payload), Events.Memos.Changed);
  }

  updateMemo(id: number, payload: UpdateMemoDto) {
    return this.callApi(api => api.one("memos", id).patchEntity(payload), Events.Memos.Changed);
  }

  deleteMemo(id: number) {
    return this.callApi(api => api.one("memos", id).delete(), Events.Memos.Changed);
  }
}
