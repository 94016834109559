import { Select } from "@frui.ts/bootstrap";
import { registerView, ViewComponent } from "@frui.ts/views";
import LoadingOverlay from "controls/loadingOverlay";
import { StringInput } from "controls/stringInput";
import { observer } from "mobx-react-lite";
import React from "react";
import { Alert, Button, Col, Form, Modal } from "react-bootstrap";
import ParcelShopUserDetailViewModel from "viewModels/shops/detail/parcelShopUserDetailViewModel";
import preventDefault from "../../preventDefault";

const parcelShopUserDetailView: ViewComponent<ParcelShopUserDetailViewModel> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral: tg } = vm.localization;
  const ta = (name: string) => translateAttribute("parcel_shop_user", name);

  return (
    <Modal centered={true} show={true} onHide={vm.requestClose}>
      <Form onSubmit={preventDefault(vm.save)}>
        <Modal.Header closeButton>
          <Modal.Title>{vm.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {vm.item && (
            <>
              <Form.Row>
                <Form.Group as={Col} md={8}>
                  <Form.Label>{ta("email")}</Form.Label>
                  <StringInput trim={true} target={vm.item} property="email" />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md={8}>
                  <Form.Label>{ta("role")}</Form.Label>
                  <Select
                    target={vm.item}
                    property="role"
                    items={vm.roles}
                    mode="key"
                    textProperty="name"
                    isNumeric={true}
                    allowEmpty
                  />
                </Form.Group>
              </Form.Row>

              {vm.errorMessage && <Alert variant="danger">{vm.errorMessage}</Alert>}
            </>
          )}

          <LoadingOverlay loading={vm.busyWatcher.isBusy} />
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button type="submit" disabled={!vm.canSave}>
            {tg(vm.isCreating ? "parcel_shop.edit_access.create" : "parcel_shop.edit_access.save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});

export default registerView(parcelShopUserDetailView, ParcelShopUserDetailViewModel);
