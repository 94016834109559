import { IPagingInfo } from "@frui.ts/data";
import LocalizationService from "services/localizationService";
import { observer } from "mobx-react-lite";
import React from "react";

const PagingInfo: React.SFC<{ paging: IPagingInfo; modelName: string; localization: LocalizationService }> = observer(
  ({ paging, modelName, localization }) => {
    if (!paging) {
      return null;
    }

    if (paging.totalItems === 0) {
      return <div className="paging-info text-right mt-0">0 {localization.translateModel(modelName, 0).toLowerCase()}</div>;
    }

    const displayed = Math.min(paging.limit, paging.totalItems - paging.offset);

    return (
      <div className="paging-info text-right mt-0">
        {paging.offset + 1} - {paging.offset + displayed} {localization.translateModel(modelName, displayed).toLowerCase()}{" "}
        {localization.translateGeneral("items_of")} {paging.totalItems}
      </div>
    );
  }
);

export default PagingInfo;
