import { Input } from "@frui.ts/bootstrap/dist/controls/input";
import { bound } from "@frui.ts/helpers";
import React from "react";

export interface StringInputProps {
  trim?: boolean;
}

export class StringInput<TTarget, TOtherProps = unknown> extends Input<TTarget, TOtherProps & StringInputProps> {
  @bound protected handleValueTrim() {
    if (this.props.trim && typeof this.value == "string") {
      const trimmed: string = this.value.trim();
      this.setValue(trimmed === "" ? null : trimmed);
    }
  }

  render() {
    return <Input {...this.props} onBlur={this.handleValueTrim} {...this.inheritedProps} />;
  }
}
