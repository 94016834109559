import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class MessageGroupParentDto {
  @observable
  @Expose({ name: "depo_ids" })
  depoIds!: number[];

  @observable
  @Expose({ name: "ps_ids" })
  psIds!: number[];

  static ValidationRules = {
    depoIds: { required: true },
    psIds: { required: true },
  };
}
