import { Expose, Type } from "class-transformer";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Address {
  /**
   * id
   */
  id!: number;

  /**
   * Parcel shop foreign key
   */
  @observable
  @Expose({ name: "ps_id" })
  psId!: number;

  /**
   * Address type
   */
  @observable
  @Expose({ name: "address_type_id" })
  addressTypeId!: number;

  @observable
  default!: boolean;

  @observable
  name!: string;

  @observable
  subname?: string;

  @observable
  street!: string;

  @observable
  zip!: number;

  @observable
  city!: string;

  /**
   * Country id (from enums)
   */
  @observable
  @Expose({ name: "country_id" })
  countryId!: number;

  @observable
  description!: string;

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    psId: { required: true, number: true },
    addressTypeId: { required: true, number: true },
    default: { required: true },
    name: { required: true },
    street: { required: true },
    zip: { required: true, number: true },
    city: { required: true },
    countryId: { required: true, number: true },
    description: { required: true },
    createdAt: { required: true },
    updatedAt: { required: true },
  };
}

export class AddressDisplayModel extends Address {
  typeName: string;
  countryName: string;
}
