import { EntityValidationRules } from "services/validation";
import OpeningHoursViewModelBase from "viewModels/shops/detail/edit/openingHoursViewModelBase";

export class OpeningHoursModel {
  openingHours: OpeningHoursViewModelBase;
  isActive: boolean;
  day: number;
  openFrom?: string;
  openTo?: string;

  static ValidationRules: EntityValidationRules<OpeningHoursModel> = {
    openFrom: {
      requiredIf: {
        condition: (x: OpeningHoursModel) => x.isActive,
      },
      isTime: { condition: (x: OpeningHoursModel) => x.isActive },
      isTimeBefore: { key: "openTo" },
    },
    openTo: {
      requiredIf: { condition: (x: OpeningHoursModel) => x.isActive },
      isTime: { condition: (x: OpeningHoursModel) => x.isActive },
      isTimeAfter: { key: "openFrom" },
    },
  };
}

export const validationRulesWithDayPart = (dayPart?: number, dayIndex?: number) => {
  return {
    openFrom: {
      requiredIf: {
        condition: (x: OpeningHoursModel) => x.isActive,
      },
      isTime: { condition: (x: OpeningHoursModel) => x.isActive },
      isTimeBefore: { key: "openTo", dayPart, dayIndex },
    },
    openTo: {
      requiredIf: { condition: (x: OpeningHoursModel) => x.isActive },
      isTime: { condition: (x: OpeningHoursModel) => x.isActive },
      isTimeAfter: { key: "openFrom", dayPart, dayIndex },
    },
  };
};

export interface OpeningHoursDayModel {
  dayName: string;
  hours: [OpeningHoursModel, OpeningHoursModel];
}
