import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Invoice {
  /**
   * id
   */
  id!: number;

  /**
   * Parcel shop foreign key
   */
  @observable
  @Expose({ name: "ps_id" })
  psId!: number;

  /**
   * Price for one PS (for network parent too just 1 ps)
   */
  @observable
  price!: number;

  /**
   * Month of invoice
   */
  @observable
  month!: number;

  /**
   * Year of invoice
   */
  @observable
  year!: number;

  /**
   * Order number from K2
   */
  @observable
  @Expose({ name: "order_number" })
  orderNumber!: string;

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    psId: { required: true, number: true },
    price: { required: true, number: true },
    month: { required: true, number: true },
    year: { required: true, number: true },
    orderNumber: { required: true },
    createdAt: { required: true },
    updatedAt: { required: true },
  };
}

export class InvoiceWithAttachment extends Invoice {
  file: string;
}
