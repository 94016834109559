import { Input } from "@frui.ts/bootstrap";
import { DataTable } from "@frui.ts/dataviews";
import { ViewComponent } from "@frui.ts/views";
import FilterButton from "controls/filterButton";
import HeaderRow from "controls/headerRow";
import ItemsSelector from "controls/itemsSelector";
import LoadingOverlay from "controls/loadingOverlay";
import PagingInfo from "controls/pagingInfo";
import { ColumnsContext, createColumnsContext, DataColumn } from "controls/tableHelpers";
import TablePager from "controls/tablePager";
import MessageListResponseDto from "entities/messageListResponseDto";
import { observer } from "mobx-react-lite";
import React from "react";
import { Badge, Button, ButtonToolbar, Form } from "react-bootstrap";
import MessagesViewModel from "viewModels/messages/messagesViewModel";
import { formatCategory, formatDate } from "../../utils/helpers";
import "./style.scss";

const tableColumns: DataColumn<MessageListResponseDto, MessagesViewModel>[] = [
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <span className="column-container">
        {ta("message", "id")}
        <FilterButton {...filterButtonProps} isActive={!!vm.appliedFilter?.id} onClear={() => vm.clearFilter("id")}>
          <Form.Group>
            <Input placeholder={tg("search")} size="sm" data-search target={vm.filter} property="id" />
          </Form.Group>
        </FilterButton>
      </span>
    ),
    property: "id",
    sortable: true,
    headerClassName: "column-id",
  },
  {
    titleFactory: ({ vm, tm, tg, filterButtonProps }) => (
      <span className="column-container">
        {tm("depo", 1)}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.depo_ids?.length}
          onClear={() => vm.clearFilter("depo_ids")}>
          <ItemsSelector
            target={vm.filter}
            property="depo_ids"
            searchTextProperty="depo_name"
            items={vm.depos || []}
            searchPlaceholder={tg("search")}
            selectAllText={tg("select_all")}
          />
        </FilterButton>
      </span>
    ),
    property: "depoIds",
    sortable: false,
    valueFormatter: ({ item, context }) => {
      return context.vm.getDeposName(item.depoIds ?? []);
    },
    headerClassName: "column-depo",
  },
  {
    titleFactory: ({ vm, tg, tm, filterButtonProps }) => (
      <span className="column-container">
        {tm("parcel_shop", 1)}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.parcel_shop_ids?.length}
          onClear={() => vm.clearFilter("parcel_shop_ids")}>
          <ItemsSelector
            target={vm.filter}
            property="parcel_shop_ids"
            searchTextProperty="parcel_shop_name"
            items={vm.allowedParcelShops || []}
            searchPlaceholder={tg("search")}
            selectAllText={tg("select_all")}
          />
        </FilterButton>
      </span>
    ),
    property: "parcelShops",
    valueFormatter: ({ item }) => {
      return item.parcelShops?.length === 1 ? item.parcelShops[0].name : "";
    },
    tag: "received",
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <span className="column-container">
        {ta("message", "author")}
        <FilterButton {...filterButtonProps} isActive={!!vm.appliedFilter?.author} onClear={() => vm.clearFilter("author")}>
          <Form.Group>
            <Input placeholder={tg("search")} size="sm" data-search target={vm.filter} property="author" />
          </Form.Group>
        </FilterButton>
      </span>
    ),
    property: "author_id" as any,
    sortable: true,
    valueFormatter: ({ item, context }) => {
      return item.author?.name ?? <span className="info">{context.tg("message.user_missing")}</span>;
    },
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <span className="column-container">
        {ta("message", "subject")}
        <FilterButton {...filterButtonProps} isActive={!!vm.appliedFilter?.subject} onClear={() => vm.clearFilter("subject")}>
          <Form.Group>
            <Input placeholder={tg("search")} size="sm" data-search target={vm.filter} property="subject" />
          </Form.Group>
        </FilterButton>
      </span>
    ),
    property: "subject",
    sortable: true,
  },
  {
    titleFactory: ({ ta }) => ta("message", "recipient"),
    property: "recipientsList",
    valueFormatter: ({ item, context }) => {
      const list = item.recipientsList?.join(", ");
      return list === "" ? <span className="info">{context.tg("message.user_missing")}</span> : list;
    },
    tag: "sent",
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <span className="column-container">
        {ta("parcel_shop", "salesman_id")}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.salesman_ids?.length}
          onClear={() => vm.clearFilter("salesman_ids")}>
          <ItemsSelector
            target={vm.filter}
            property="salesman_ids"
            searchTextProperty="salesman_name"
            items={vm.salesmans || []}
            searchPlaceholder={tg("search")}
            selectAllText={tg("select_all")}
          />
        </FilterButton>
      </span>
    ),
    property: "salesmanIds",
    sortable: false,
    valueFormatter: ({ item, context }) => {
      return context.vm.getSalesmansName(item.salesmanIds ?? []);
    },
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <span className="column-container">
        {ta("message", "status")}
        <FilterButton
          {...filterButtonProps}
          isActive={vm.filter.resolved !== undefined}
          onClear={() => vm.clearFilter("resolved")}>
          <div className="mt-2 mb-3 mx-3">
            <Form.Check
              type="radio"
              custom
              label={tg("select_all")}
              id="allStatuses"
              checked={vm.filter.resolved === undefined}
              onChange={() => vm.setResolvedFilter(undefined)}
              className="mb-2"
            />
            <Form.Check
              type="radio"
              custom
              label={tg("message.resolved")}
              id="isResolved"
              checked={vm.filter.resolved === true}
              onChange={() => vm.setResolvedFilter("resolved")}
              className="mb-2"
            />
            <Form.Check
              type="radio"
              custom
              label={tg("message.unresolved")}
              id="isUnresolved"
              checked={vm.filter.resolved === false}
              onChange={() => vm.setResolvedFilter("unresolved")}
            />
          </div>
        </FilterButton>
      </span>
    ),
    property: "resolved",
    valueFormatter: ({ value, context }) => (value ? context.tg("message.resolved") : context.tg("message.unresolved")),
    sortable: true,
    tag: "received",
  },
  {
    titleFactory: ({ vm, tg, ta, filterButtonProps }) => (
      <span className="column-container">
        {ta("parcel_shop", "category_id")}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.ps_category_ids?.length}
          onClear={() => vm.clearFilter("ps_category_ids")}>
          <ItemsSelector
            target={vm.filter}
            property="ps_category_ids"
            searchTextProperty="ps_category_name"
            items={vm.categories || []}
            searchPlaceholder={tg("search")}
            selectAllText={tg("select_all")}
          />
        </FilterButton>
      </span>
    ),
    property: "ps_category_ids" as any,
    valueFormatter: ({ item, context }) => {
      if (item.psCategoryIds) {
        return item.psCategoryIds.map(catId => {
          const enumItem = context.vm.enums.value("ps_categories", catId);
          return formatCategory(enumItem);
        });
      }
    },
    sortable: true,
  },
  {
    titleFactory: ({ ta }) => ta("message", "created_at"),
    property: "updated_at" as any,
    valueFormatter: ({ item }) => formatDate(item.updatedAt),
    sortable: true,
    headerClassName: "column-date",
  },
];

const MessageList: ViewComponent<MessagesViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localizationService;

  const messageCountBadge =
    vm.unreadMessageService.unreadMessages > 0 ? (
      <Badge variant="danger" className="messages">
        {vm.unreadMessageService.unreadMessages}
      </Badge>
    ) : undefined;

  const columnsContext = createColumnsContext(vm);

  return (
    <div className="messages-list">
      <HeaderRow title={tg("message.title")}>
        <ButtonToolbar className="justify-content-end">
          <Button variant="outline-primary" onClick={vm.openMessageGroups}>
            {tg("message_group.name")}
          </Button>
          <Button variant="primary" onClick={vm.add}>
            {tg("message.add")}
          </Button>
        </ButtonToolbar>
      </HeaderRow>

      <div className="wrapper-message-switcher">
        <button onClick={() => vm.changeFilter("received")} className={vm.messageFilter === "received" ? "active" : ""}>
          {tg("message.recieved")} {messageCountBadge}
        </button>
        <button onClick={() => vm.changeFilter("sent")} className={vm.messageFilter === "sent" ? "active" : ""}>
          {tg("message.sent")}
        </button>
      </div>

      <PagingInfo paging={vm.currentPaging} modelName="result" localization={vm.localizationService} />

      <div className="react-bootstrap-table">
        {vm.messageFilter === "received" && (
          <style
            scoped
            dangerouslySetInnerHTML={{
              __html: `
            .column-id { width: 8em; }
            .column-depo { width: 8.5em; }
            .column-date { width: 9em; }
            `,
            }}
          />
        )}

        <DataTable<MessageListResponseDto, ColumnsContext<MessagesViewModel>>
          className="table custom-table messages table-striped"
          pagingFilter={vm.pagingFilter}
          itemKey="id"
          items={vm.items || []}
          columns={tableColumns.filter(x => !x.tag || x.tag === vm.messageFilter)}
          context={columnsContext}
          onColumnSort={vm.sortByColumn}
          rowProps={item => ({ className: item.readByPpl ? undefined : "unread", onClick: () => vm.openDetail(item) })}
        />
      </div>
      <TablePager vm={vm} />
      <LoadingOverlay loading={vm.busyWatcher.isBusy} />
    </div>
  );
});

export default MessageList;
