/* eslint-disable @typescript-eslint/no-unused-vars */
import { bound } from "@frui.ts/helpers";
import { IValidatorsRepository } from "@frui.ts/validation";
import { isArray, isNumber } from "util";
import castArray from "lodash/castArray";
import validator from "validator";
import LocalizationService from "../localizationService";
import { isCondition, ValidationRules } from "./types";
import { get } from "mobx";

export default class ValidationService {
  constructor(private localizationService: LocalizationService) {}

  initialize(validators: IValidatorsRepository<keyof ValidationRules>) {
    validators.set("required", this.validateRequired);
    validators.set("requiredIf", this.validateRequiredIf);
    validators.set("number", this.validateNumber);
    validators.set("range", this.validateRange);
    validators.set("equalWith", this.validateEqualWith);
    validators.set("minLength", this.validateMinLength);
    validators.set("maxLength", this.validateMaxLength);
    validators.set("isIn", this.validateIsIn);
    validators.set("isEmail", this.validateEmail);
    validators.set("isPhone", this.validatePhoneNumber);
    validators.set("isPostalCode", this.validatePostalCode);
    validators.set("isTime", this.validateTime);
    validators.set("isIMEI", this.validateIMEI);
    validators.set("isIc", this.validateIc);
    validators.set("isDic", this.validateDic);
    validators.set("isLatitude", this.validateLatitude);
    validators.set("isLongitude", this.validateLongitude);
    validators.set("manualErrors", this.manualValidation);
    validators.set("lowerThan", this.validateLowerThan);
    validators.set("atLeastOneOf", this.atLeastOneOf);
    validators.set("inFuture", this.inFuture);
    validators.set("isGreaterThan", this.validateGreaterThan);
    validators.set("isLimitedBonus", this.validateBonusEqualOrLowerThan);
    validators.set("isParcelBoxName", this.validateParcelBoxName);
    validators.set("isTimeBefore", this.isTimeBefore);
    validators.set("isTimeAfter", this.isTimeAfter);
    validators.set("isFileType", this.validateFileType);
    validators.set("isBankAccountPrefix", this.validateBankAccountPrefix);
    validators.set("isBankAccountNumber", this.validateBankAccountNumber);
  }

  paramsRegex = /%param(\d+)%/g;

  getLocalizedMessage(code: string, params?: any) {
    let message = this.localizationService.translateGeneral(code);

    if (params !== undefined) {
      if (message.includes("%params%")) {
        message = message.replace("%params%", params);
      } else if (isArray(params)) {
        message = message.replace(this.paramsRegex, (match, number) => {
          const paramValue = params[number - 1];
          return paramValue ? this.localizationService.translateAttribute("", paramValue) : match;
        });
      }
    }

    return message;
  }

  private getValidationMessage(code: string, isValid: boolean, params?: any) {
    return isValid ? undefined : this.getLocalizedMessage(code, params);
  }

  @bound
  isTimeBefore(value: string, propertyName: string, entity: any, params: ValidationRules["isTimeBefore"]) {
    let fromToError = false;
    let midnightError = false;
    let boundsError = false;

    if (!!value && !!entity[params.key]) {
      const datesCompare = ValidationService.compareTimes(value, entity[params.key] as string);
      fromToError = datesCompare > 0;
      if (params.dayPart !== undefined && params.dayPart === 1) {
        const compareWithMidnight = ValidationService.compareTimes(value, "24:00");
        midnightError = compareWithMidnight > 0;

        if (params.dayIndex !== undefined) {
          const oppositeHour = entity.openingHours.days[params.dayIndex]?.hours[0]?.openTo;
          if (oppositeHour) {
            const compareWithOppositeBound = ValidationService.compareTimes(value, oppositeHour);
            boundsError = compareWithOppositeBound < 0;
          }
        }
      }
    }

    const hasError = fromToError || boundsError || midnightError;
    // eslint-disable-next-line sonarjs/no-duplicate-string
    return this.getValidationMessage("validators.isTime", !hasError);
  }

  @bound
  isTimeAfter(value: string, propertyName: string, entity: any, params: ValidationRules["isTimeAfter"]) {
    let fromToError = false;
    let midnightError = false;
    let boundsError = false;

    if (!!value && !!entity[params.key]) {
      const datesCompare = ValidationService.compareTimes(value, entity[params.key] as string);
      fromToError = datesCompare !== 1;
      if (params.dayPart !== undefined) {
        if (params.dayPart === 1) {
          const compareWithMidnight = ValidationService.compareTimes(value, "24:00");
          midnightError = compareWithMidnight > 0;
        } else if (params.dayIndex !== undefined) {
          const oppositeHour = entity.openingHours.days[params.dayIndex]?.hours[1]?.openFrom;
          if (oppositeHour) {
            boundsError = ValidationService.compareTimes(oppositeHour, value) < 0;
          }
        }
      }
    }

    const hasError = fromToError || boundsError || midnightError;
    return this.getValidationMessage("validators.isTime", !hasError);
  }

  @bound
  inFuture(value: any, propertyName: string, entity: any, params: ValidationRules["inFuture"]) {
    if (!value || !value.getTime) {
      return undefined; // Not a date or empty
    }

    const hasError = value.getTime() <= new Date().getTime();
    return this.getValidationMessage("validators.in_future", !hasError);
  }

  @bound
  validateRequired(value: any, propertyName: string, entity: any, params: ValidationRules["required"]) {
    if (!params) {
      return undefined;
    }

    // TODO remove the onEdit/onCreate modifier and use requiredIf instead
    if (params === "onEdit" && entity.id === -1) {
      return undefined;
    }
    if (params === "onCreate" && entity.id !== -1) {
      return undefined;
    }

    const hasError = value == null || value === "" || value.length === 0;
    return this.getValidationMessage("validators.required", !hasError);
  }

  @bound
  validateRequiredIf(value: any, propertyName: string, entity: any, params: ValidationRules["requiredIf"]) {
    if (!isCondition(params) || !params.condition(entity)) {
      return undefined;
    }

    const hasError = value == null || value === "" || value.length === 0;
    return this.getValidationMessage("validators.requiredIf", !hasError);
  }

  @bound
  validateLowerThan(value: any, propertyName: string, entity: any, params: ValidationRules["lowerThan"]) {
    if (value == null || value === "") {
      return undefined;
    }

    let currentValue = parseInt(value, 10);
    let compareValue = parseInt(entity[params.key], 10);

    // Allow to compare dates via milliseconds
    if (value.getTime) {
      currentValue = new Date(value.toDateString()).getTime();
    }

    if (entity[params.key]?.getTime) {
      compareValue = new Date(entity[params.key].toDateString()).getTime();
    }

    if (currentValue <= compareValue) {
      return undefined;
    }

    // TODO Az si toho Gusto vsimnes vzpomen si na moje slova o konfigurovatelnych hlaskach
    // bohuzel pro preklad atributu je potreba jak model tak klic...
    return this.getValidationMessage(
      `validators.lowerThan`,
      false,
      this.localizationService.translateAttribute(params.modelName, params.key as string)
    );
  }

  @bound
  atLeastOneOf(value: any, propertyName: string, entity: any, params: ValidationRules["atLeastOneOf"]) {
    // TODO Opet pro Gustu - podle mne vsechny validace by mely byt { condition: .. } & xyz
    if (!isCondition(params) || !params.condition(entity)) {
      return undefined;
    }

    // If current value is empty and property value from params is also empty
    // we have a problem
    if (!value && !entity[params.key]) {
      return this.getValidationMessage(`validators.atLeastOneOf`, false, [
        this.localizationService.translateAttribute(params.modelName, propertyName).toLocaleLowerCase(),
        this.localizationService.translateAttribute(params.modelName, params.key as string).toLocaleLowerCase(),
      ]);
    } else {
      return undefined;
    }
  }

  @bound
  validateNumber(value: any, propertyName: string, entity: any, params: ValidationRules["number"]) {
    if (value == null || value === "") {
      return undefined;
    }

    const number = parseInt(value, 10);
    const isValid = !isNaN(number);
    return this.getValidationMessage("validators.number", isValid);
  }

  @bound
  validateRange(value: any, propertyName: string, entity: any, params: ValidationRules["range"]) {
    if (value == null || value === "") {
      return undefined;
    }

    const number = isNumber(value) ? value : parseFloat(value);

    if (params.min !== undefined && params.min > number) {
      return this.getLocalizedMessage("validators.min", params.min);
    }
    if (params.minExclusive !== undefined && params.minExclusive >= number) {
      return this.getLocalizedMessage("validators.minExclusive", params.minExclusive);
    }

    if (params.max !== undefined && params.max < number) {
      return this.getLocalizedMessage("validators.max", params.max);
    }
    if (params.maxExclusive !== undefined && params.maxExclusive <= number) {
      return this.getLocalizedMessage("validators.maxExclusive", params.maxExclusive);
    }

    return undefined;
  }

  @bound
  validateEqualWith(value: any, propertyName: string, entity: any, params: ValidationRules["equalWith"]) {
    const isValid = value === entity[params];
    return this.getValidationMessage("validators.equalWith", isValid, [propertyName, params]);
  }

  @bound
  validateMinLength(value: any, propertyName: string, entity: any, params: ValidationRules["minLength"]) {
    const hasError = value !== null && value !== undefined && value !== "" && value.toString().length < params;
    return this.getValidationMessage("validators.minLength", !hasError, [propertyName, params]);
  }

  @bound
  validateMaxLength(value: any, propertyName: string, entity: any, params: ValidationRules["maxLength"]) {
    const hasError = value !== null && value !== undefined && value !== "" && value.length > params;
    return this.getValidationMessage("validators.maxLength", !hasError, [propertyName, params]);
  }

  @bound
  validateIsIn(value: any, propertyName: string, entity: any, params: ValidationRules["isIn"]) {
    const isValid = validator.isIn(`${value}`, params);
    return this.getValidationMessage("validators.isIn", isValid, [params.toString()]);
  }

  @bound
  validateEmail(value: any, propertyName: string, entity: any, params: ValidationRules["isEmail"]) {
    const isValid = !value || validator.isEmail(value);
    return this.getValidationMessage("validators.isEmail", isValid);
  }

  @bound
  validatePhoneNumber(value: any, propertyName: string, entity: any, params: ValidationRules["isPhone"]) {
    const isValid = !value || validator.isMobilePhone(value);
    return this.getValidationMessage("validators.isPhone", isValid);
  }

  @bound
  validatePostalCode(value: any, propertyName: string, entity: any, params: ValidationRules["isPostalCode"]) {
    const isValid = !value || validator.isPostalCode(value.toString(), "CZ");
    return this.getValidationMessage("validators.isPostalCode", isValid);
  }

  @bound
  validateIc(value: any, propertyName: string, entity: any, params: ValidationRules["isIc"]) {
    const isValidLength = value && value.match(ValidationService.icoRegex);
    if (!isValidLength) {
      return this.getLocalizedMessage("validators.isIC.minLength");
    }

    const isValidChecksum = ValidationService.validateIco(value);
    return this.getValidationMessage("validators.isIC.invalid", isValidChecksum);
  }

  @bound
  validateDic(value: any, propertyName: string, entity: any, params: ValidationRules["isDic"]) {
    const isValid = !value || value.match(ValidationService.dicRegex);
    return this.getValidationMessage("validators.isDIC", isValid);
  }

  @bound
  validateTime(value: any, propertyName: string, entity: any, params: ValidationRules["isTime"]) {
    const isValid = ValidationService.validateTime(value);

    if (params === true || (isCondition(params) && params.condition(entity))) {
      return this.getValidationMessage("validators.isTime", isValid);
    }
  }

  @bound
  validateIMEI(value: any, propertyName: string, entity: any, params: ValidationRules["isIMEI"]) {
    const isValid = ValidationService.validateIMEI(value);

    return this.getValidationMessage("validators.isIMEI", isValid);
  }

  @bound
  validateLatitude(value: any, propertyName: string, entity: any, params: ValidationRules["isLatitude"]) {
    const isValid = (typeof value === "number" || ValidationService.validateString(value)) && validator.isLatLong(`0,${value}`);

    return this.getValidationMessage("validators.isLatitude", isValid);
  }

  @bound
  validateLongitude(value: any, propertyName: string, entity: any, params: ValidationRules["isLongitude"]) {
    const isValid = (typeof value === "number" || ValidationService.validateString(value)) && validator.isLatLong(`${value},0`);

    return this.getValidationMessage("validators.isLongitude", isValid);
  }

  @bound
  validateGreaterThan(value: any, propertyName: string, entity: any, params: ValidationRules["isGreaterThan"]) {
    const isValid = (value !== 0 && !value) || value > params;
    return this.getValidationMessage("validators.isGreaterThan", isValid, [params]);
  }

  @bound
  validateBonusEqualOrLowerThan(value: any, propertyName: string, entity: any, params: ValidationRules["isLimitedBonus"]) {
    const { messageCode, cb } = params;
    const limit = cb();
    let isValid = true;

    if (value !== undefined && limit !== undefined) {
      isValid = value <= limit;
    }

    return this.getValidationMessage(`validators.isLimitedBonus.${messageCode}`, isValid, [limit]);
  }

  @bound
  validateFileType(value: any, propertyName: string, entity: any, params: ValidationRules["isFileType"]) {
    if (value) {
      if (!(value instanceof File)) {
        return this.getLocalizedMessage("validators.isFileType.isNotFile");
      }

      if (params.type) {
        const types = castArray(params.type);

        if (!types.includes(value.type)) {
          return this.getLocalizedMessage("validators.isFileType.notDesiredFileType", types.join(","));
        }
      }
    }

    return undefined;
  }

  // pass observable object with custom errors for the whole entity as params
  manualValidation(value: any, propertyName: string, entity: any, params: ValidationRules["manualErrors"]) {
    return get(params.errors, propertyName);
  }

  @bound
  validateParcelBoxName(value: string, propertyName: string, entity: any, params: ValidationRules["isParcelBoxName"]) {
    let isValid = true;
    if (params) {
      // eslint-disable-next-line no-useless-escape
      const regex = /^PBOX\ [A-Z]{1,3}\ [a-zA-Zá-žÁ-Ž0-9_\-\.\ ]{1,15}(\ [()][a-zA-Zá-žÁ-Ž0-9_\-\.\ ]{1,8}[)])?$/;
      isValid = regex.test(value);
    }

    return this.getValidationMessage("validators.isParcelBoxName", isValid);
  }

  @bound
  validateBankAccountPrefix(value: string, propertyName: string, entity: any, params: ValidationRules["isBankAccountPrefix"]) {
    let isValid = true;
    if (params) {
      isValid = !value || ValidationService.isValidBankAccountPrefix(value);
    }

    return this.getValidationMessage("validators.isBankAccountPrefix", isValid);
  }

  @bound
  validateBankAccountNumber(value: string, propertyName: string, entity: any, params: ValidationRules["isBankAccountNumber"]) {
    let isValid = true;
    if (params) {
      isValid = !value || ValidationService.isValidBankAccountNumber(value);
    }

    return this.getValidationMessage("validators.isBankAccountNumber", isValid);
  }

  // static helpers

  private static icoRegex = /^\d{8}$/;
  private static dicRegex = /^(CZ\d{8,10})$/;

  private static validateIco(value: string) {
    if (Number(value) >= 99990001 && Number(value) <= 99999999) {
      return true;
    }

    let controlCount = 0;
    for (let i = 0; i < 7; i++) {
      controlCount += parseInt(value[i], 10) * (8 - i);
    }

    controlCount = controlCount % 11;
    let c: number;

    if (controlCount === 0) {
      c = 1;
    } else if (controlCount === 1) {
      c = 0;
    } else {
      c = 11 - controlCount;
    }

    return parseInt(value[7]) === c;
  }

  private static validateTime(value: string) {
    if (!/^\d{2}:\d{2}$/.test(value)) {
      return false;
    }

    const [hours, minutes] = value.split(":");

    const hoursI = parseInt(hours, 10);
    const minutesI = parseInt(minutes, 10);

    // Allow 24:00
    if (hoursI === 24 && minutesI === 0) {
      return true;
    }

    return hoursI >= 0 && hoursI <= 23 && minutesI >= 0 && minutesI <= 59;
  }

  private static validateIMEI(value: string) {
    if (!value) {
      return true;
    }

    if (!/^[0-9]{15}$/.test(value)) {
      return false;
    }

    let sum = 0;
    let mul = 2;
    const l = 14;

    // Luhn check
    for (let i = 0; i < l; i++) {
      const digit = value.substring(l - i - 1, l - i);
      const tp = parseInt(digit, 10) * mul;

      if (tp >= 10) {
        sum += (tp % 10) + 1;
      } else {
        sum += tp;
      }

      if (mul === 1) {
        mul++;
      } else {
        mul--;
      }
    }

    const chk = (10 - (sum % 10)) % 10;
    return chk === parseInt(value.substring(14, 15), 10);
  }

  private static validateString(value: any) {
    return value instanceof String || typeof value === "string";
  }

  private static compareTimes = (from: string, to: string) => {
    const [fromHours, fromMinutes] = from.split(":");
    const [toHoures, toMinutes] = to.split(":");
    if (Number(fromHours) < Number(toHoures)) {
      return -1;
    } else if (Number(fromHours) === Number(toHoures)) {
      if (Number(fromMinutes) < Number(toMinutes)) {
        return -1;
      } else if (Number(fromMinutes) === Number(toMinutes)) {
        return 0;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  };

  private static isValidBankAccountPrefix = (prefix: string): boolean => {
    return ValidationService.isValidBankSomething(prefix, /^\d{1,6}$/, [10, 5, 8, 4, 2, 1]);
  };

  private static isValidBankAccountNumber = (number: string): boolean => {
    return ValidationService.isValidBankSomething(number, /^\d{1,10}$/, [6, 3, 7, 9, 10, 5, 8, 4, 2, 1]);
  };

  private static isValidBankSomething = (something: string, regexp: RegExp, weights: number[]): boolean => {
    if (!regexp.exec(something)) {
      return false;
    }

    const sum = something
      .split("")
      .map((n, i) => Number(n) * weights[i])
      .reduce((a, b) => a + b);

    return sum % 11 == 0;
  };
}
