import { Input } from "@frui.ts/bootstrap";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import FormLabel from "react-bootstrap/FormLabel";
import HeaderRow from "../../controls/headerRow";
import LoadingOverlay from "../../controls/loadingOverlay";
import SettingsViewModel from "../../viewModels/settings/settingsViewModel";

const settingsView: ViewComponent<SettingsViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localization;
  return (
    <>
      <HeaderRow title={tg("settings.title")} />
      <h3>{tg("settings.generation_parameters")}</h3>
      <Form onSubmit={preventDefault(vm.save)}>
        <Form.Row>
          <Form.Group as={Col} md={6}>
            <div className="mb-3">
              <strong>{tg("settings.parameters_with_bonuses")}</strong>
            </div>
            <Row>
              <Col md={3}>
                <FormLabel column={false}>{tg("settings.day")}</FormLabel>
                <Input target={vm.bonusXml} property="day" placeholder={tg("settings.day_placeholder")} />
              </Col>
              <Col md={3}>
                <FormLabel column={false}>{tg("settings.time")}</FormLabel>
                <Input target={vm.bonusXml} property="time" placeholder={tg("settings.time_placeholder")} />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <div className="mb-3">
              <strong>{tg("settings.parameters_with_approved_bonuses")}</strong>
            </div>
            <Row>
              <Col md={3}>
                <FormLabel column={false}>{tg("settings.day")}</FormLabel>
                <Input target={vm.orderXml} property="day" placeholder={tg("settings.day_placeholder")} />
              </Col>
              <Col md={3}>
                <FormLabel column={false}>{tg("settings.time")}</FormLabel>
                <Input target={vm.orderXml} property="time" placeholder={tg("settings.time_placeholder")} />
              </Col>
            </Row>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <h3>{tg("settings.bonus_limits")}</h3>
        </Form.Row>
        <Form.Row className="mb-2">
          <h6>
            <strong>{tg("settings.max_bonus_value")}</strong>
          </h6>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={2} className="mr-3">
            <Form.Label>{tg("settings.bonus_types.loyalty")}</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
              </InputGroup.Prepend>
              <Input target={vm.settings} property="bonusLimitForLoyalty" type="number" />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md={2} className="mr-3">
            <Form.Label>{tg("settings.bonus_types.flow")}</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
              </InputGroup.Prepend>
              <Input target={vm.settings} property="bonusLimitForFlow" type="number" />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Label>{tg("settings.bonus_types.retention")}</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
              </InputGroup.Prepend>
              <Input target={vm.settings} property="bonusLimitForRetention" type="number" />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={2} className="mr-3">
            <Form.Label>{tg("settings.bonus_types.supply")}</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
              </InputGroup.Prepend>
              <Input target={vm.settings} property="bonusLimitForSupply" type="number" />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md={2} className="mr-3">
            <Form.Label>{tg("settings.bonus_types.acquisition")}</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
              </InputGroup.Prepend>
              <Input target={vm.settings} property="bonusLimitForAcquisition" type="number" />
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} md={2}>
            <Form.Label>{tg("settings.bonus_types.seasonal")}</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
              </InputGroup.Prepend>
              <Input target={vm.settings} property="bonusLimitForSeasonal" type="number" />
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Button type="submit" disabled={!vm.canSave} size="lg">
          {tg("save")}
        </Button>
      </Form>

      <LoadingOverlay loading={vm.busyWatcher.isBusy} />
    </>
  );
});

export default registerView(settingsView, SettingsViewModel);
