import { Router, ScreenBase, UrlNavigationAdapter } from "@frui.ts/screens";
import { View } from "@frui.ts/views";
import { Container } from "inversify";
import { reaction } from "mobx";
import React from "react";
import ReactDOM from "react-dom";
import InitializationService from "services/initializationService";
import UserContext from "services/userContext";
import LoginViewModel from "viewModels/loginViewModel";
import RootViewModel from "viewModels/rootViewModel";

let urlAdapter: UrlNavigationAdapter;
let currentRootViewModel: ScreenBase;

function renderViewModel(viewModel: unknown) {
  ReactDOM.render(<View vm={viewModel} useLifecycle />, document.getElementById("root"));
}

async function renderRoot(newRootViewModel: ScreenBase, router?: Router) {
  await currentRootViewModel?.deactivate(true);
  urlAdapter?.stop();

  urlAdapter = new UrlNavigationAdapter();
  router?.start(newRootViewModel, urlAdapter);
  await urlAdapter.start(newRootViewModel);

  await newRootViewModel.activate();
  currentRootViewModel = newRootViewModel;
  renderViewModel(newRootViewModel);
}

async function bootApp(isLogged: boolean, container: Container, initializationService: InitializationService) {
  urlAdapter?.stop();

  if (!initializationService.isPreLoginInitialized) {
    void initializationService.preLoginInitialize();
    renderViewModel(initializationService);
    return;
  }

  const router = container.get(Router);

  if (!isLogged) {
    if (initializationService.isPostLoginInitialized) {
      initializationService.postLogoutCleanup();
    }

    const loginViewModel = container.get(LoginViewModel);
    await loginViewModel.activate();
    renderViewModel(loginViewModel);
    return;
  }

  if (!initializationService.isPostLoginInitialized) {
    void initializationService.postLoginInitialize();
    renderViewModel(initializationService);
    return;
  }

  const rootViewModel = container.get(RootViewModel);
  await renderRoot(rootViewModel, router);
}

export function runApp(container: Container): void {
  const userContext = container.get<UserContext>(UserContext);
  const initializationService = container.get<InitializationService>(InitializationService);

  reaction(
    () => [userContext.isLogged, initializationService.isPostLoginInitialized, initializationService.isPreLoginInitialized],
    ([isLogged]) => void bootApp(isLogged, container, initializationService),
    { fireImmediately: true }
  );
}
