import { Select } from "@frui.ts/bootstrap";
import { registerView, ViewComponent } from "@frui.ts/views";
import HeaderRow from "controls/headerRow";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonGroup, ButtonToolbar, Col, Form, Row } from "react-bootstrap";
import FormLabel from "react-bootstrap/FormLabel";
import NewSubjectViewModel from "viewModels/shops/newSubjectViewModel";
import preventDefault from "../preventDefault";
import BankAccount from "../common/bankAccount";
import { StringInput } from "controls/stringInput";

export const newSubjectView: ViewComponent<NewSubjectViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("subject", attribute);
  };

  return (
    <>
      <HeaderRow title={tg("new_subject.title")} />
      <Form onSubmit={preventDefault(vm.create)}>
        {vm.item && (
          <>
            <Row>
              <Col md={3}>
                <Form.Group>
                  <FormLabel column={false}>{ta("ic")}</FormLabel>
                  <StringInput trim={true} disabled={true} target={vm.item} property="ic" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Form.Group>
                  <FormLabel column={false}>{ta("dic")}</FormLabel>
                  <StringInput trim={true} target={vm.item} property="dic" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="required">
                  <FormLabel column={false}>{ta("name")}</FormLabel>
                  <StringInput trim={true} target={vm.item} property="name" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <FormLabel column={false}>{ta("subname")}</FormLabel>
                  <StringInput trim={true} target={vm.item} property="subname" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="required">
                  <FormLabel column={false}>{ta("depo")}</FormLabel>
                  <Select
                    target={vm.item}
                    property="depoId"
                    items={vm.depots}
                    allowEmpty={true}
                    mode="key"
                    keyProperty="value"
                    isNumeric={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <BankAccount vm={vm} />
          </>
        )}

        <ButtonToolbar>
          <ButtonGroup className="mr-2">
            <Button type="submit" size="lg" disabled={!vm.canSave || vm.busyWatcher.isBusy}>
              {tg("new_subject.continue")}
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button size="lg" disabled={vm.busyWatcher.isBusy} variant="outline-secondary" onClick={vm.requestClose}>
              {tg("cancel")}
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </Form>
      <LoadingOverlay loading={vm.busyWatcher.isBusy} />
    </>
  );
});

registerView(newSubjectView, NewSubjectViewModel);
