import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import NetworkStatusType from "manualEntities/networkStatusType";
import ParcelShop from "manualEntities/parcelShop";
import ParcelShopStatus from "manualEntities/parcelShopStatus";
import { action, IReactionDisposer, observable, reaction, runInAction, computed } from "mobx";
import ShopConfiguration from "models/shops/newShop/shopConfiguration";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import { IShopConfigurationViewModel } from "../types";
import Vacation from "entities/vacation";
import { attachAutomaticValidator } from "@frui.ts/validation";
import SecurityService from "services/securityService";
import ShopInformation from "models/shops/newShop/shopInformation";
import difference from "lodash/difference";
import ConfirmationService from "services/confirmationService";
import VacationDto from "entities/vacationDto";
import { psCategorySelectOption, pbIncomingCycleOption } from "utils/helpers";
import UserContext from "services/userContext";
import IAccessPointRepository from "data/repositories/IAccessPointRepository";
import { isDirty } from "@frui.ts/dirtycheck";
import AccessPointPartners from "models/enumerations/accessPointPartners";

export default abstract class ConfigurationViewModelBase<TModel extends ShopConfiguration>
  extends ScreenBase
  implements IShopConfigurationViewModel {
  busyWatcher = new BusyWatcher();
  protected reactionDisposers = [] as IReactionDisposer[];
  @observable item: TModel;
  @observable isNetworkPartner = false;
  @observable.shallow possibleParents: ParcelShop[];
  @observable vacationsIsDirty = false;
  @observable cardWarningShow = false;
  @observable boxStationWarningShow = false;
  @observable boxStation = false;
  @observable initialNetworkPartnerType?: NetworkStatusType;
  previousSizes: string[] = [];
  isParcelBox = false;
  abstract get isPotential(): boolean;

  constructor(
    private currentShopId: number | undefined,
    public shopInformation: ShopInformation | undefined,
    protected repository: IAccessPointRepository<any>,
    public localizationService: LocalizationService,
    protected enums: EnumService,
    private security: SecurityService,
    protected confirmationService: ConfirmationService,
    protected userContext: UserContext
  ) {
    super();
  }

  async onInitialize() {
    await Promise.all([this.loadCodebooks(), this.loadDetail().then(this.setItem)]);
    this.registerReactions();
  }

  get networkPartners() {
    return this.enums.values("network_partners");
  }

  get canEditPaymentTerminal() {
    return this.security.isAllowed("edit", "paymentTerminal");
  }

  get canEditAccessPoint() {
    return this.security.isAllowed("edit", "access_point");
  }

  get canEditTerminalId() {
    return this.userContext.isAdmin && !this.item?.payment_terminals?.length;
  }

  @computed get canChooseVisibility() {
    return !this.isPotential;
  }

  @computed get canChooseDhlExpress() {
    return (this.isParcelShopType || this.isParcelBox || this.isAlzaBox) && !this.isPotential;
  }

  @computed get canChooseDevice() {
    return this.isParcelShopType;
  }

  @computed get canEditRecurringPayments() {
    return (this.isParcelBox || this.isAlzaBox) && !this.isNetworkParent;
  }

  @computed get canChoosePaymentByCard() {
    return this.isEditing;
  }

  @computed get canEditPaymentTerminalsSection() {
    return !this.isParcelBox && !this.isPotential && !this.isAlzaBox;
  }

  protected abstract get isParcelShopType(): boolean;

  @computed get canChooseParent() {
    return this.item.network_partner === NetworkStatusType.Child;
  }

  @computed get canChooseNetworkPartner() {
    return !this.isPotential;
  }

  @computed get canChooseBoxStation() {
    return this.isEditing && this.isParcelBox && !this.isPotential && !this.isNetworkParent;
  }

  @computed get canChooseActive() {
    return !this.isNetworkParent;
  }

  @computed get isEditing() {
    return this.item.id > 0;
  }

  @computed get selectedParent() {
    return this.item?.parent_id ? this.possibleParents?.find(item => item.id === this.item.parent_id) : undefined;
  }

  @computed get isCategoryAvailable() {
    return !this.isNetworkParent && this.isParcelShopType;
  }

  @computed get isNetworkParent() {
    return this.item.network_partner === NetworkStatusType.Parent;
  }

  @computed get isWarehouseHidden() {
    return this.isNetworkParent && (this.isParcelBox || this.isAlzaBox);
  }

  @computed get isCategoryDisabled() {
    const isEditing = this.isEditing && !isDirty(this.item, "parent_id");
    return !!this.selectedParent?.inherit && !(isEditing && this.selectedParent?.child_count === 1);
  }

  @computed get isAlzaBox() {
    const type_id = this.shopInformation?.typeId || this.item.type_id;

    return type_id === +AccessPointPartners.ALZA_BOX;
  }

  @computed get isRecurringPaymentsRequired() {
    return (
      (this.isParcelBox && !this.isNetworkParent && !this.isPotential && !this.isAlzaBox) ||
      (this.isAlzaBox &&
        (this.item.rentPrice !== undefined ||
          this.item.electricityPrice !== undefined ||
          ![null, undefined].includes(this.item.pbInvoicingCycle as any)))
    );
  }

  get allAcceptedSizes() {
    const type_id = this.shopInformation?.typeId || this.item.type_id;

    return this.enums
      .values("all_accepted_sizes")
      .filter(size => +size.access_point_partner_type_code === type_id)
      .map(size => size.name);
  }

  get possibleParentsByType() {
    const type_id = this.shopInformation?.typeId || this.item.type_id;
    const currentIc = this.shopInformation?.subject.ic || this.item.subject.ic;

    return this.possibleParents?.filter(ps => {
      return ps.type_id === type_id && ps.subject.ic === currentIc;
    });
  }

  get categories() {
    const values = this.enums.values("ps_categories").map(psCategorySelectOption);

    if (
      this.isEditing &&
      !this.userContext.isAdmin &&
      this.userContext.assignedCategories &&
      this.userContext.assignedCategories?.length > 0
    ) {
      return values.filter(
        item =>
          this.userContext.assignedCategories?.includes(Number(item.value)) ||
          this.selectedParent?.inherit ||
          item.value === this.item.category_id
      );
    }

    return values;
  }

  get pbInvoicingCycles() {
    return this.enums.values("pb_invoicing_cycles").map(pbIncomingCycleOption);
  }

  getDeviceTypeName(id: number): string {
    return this.enums.value("scanners", id)?.device_type ?? "";
  }

  @action.bound setItem(item: TModel) {
    if (item.category_id !== undefined) {
      item.selectedCategory = item.category_id;
    }
    this.item = item;
    this.initialNetworkPartnerType = item.network_partner;
  }

  @action.bound addVacation() {
    const vacation = new Vacation();
    attachAutomaticValidator(vacation, VacationDto.ValidationRules);
    this.item.vacations.push(vacation);
    this.vacationsIsDirty = true;
  }

  @action.bound removeVacation(vacation: Vacation) {
    const index = this.item.vacations.indexOf(vacation);

    if (index >= 0) {
      this.item.vacations.splice(index, 1);
      this.vacationsIsDirty = true;
    }
  }

  @action.bound hideBoxStationWarning() {
    this.boxStationWarningShow = false;
  }

  @action.bound hideCardWarning() {
    this.cardWarningShow = false;
  }

  @action.bound checkAllSizes() {
    if (this.item.enforceSizes) {
      this.item.acceptedSize = this.allAcceptedSizes;
    }
  }

  @action.bound checkPrecedingSizes() {
    // only run when new size has been added
    if (this.item.acceptedSize.length > this.previousSizes.length) {
      const newSizeName = this.item.acceptedSize.find(i => !this.previousSizes.includes(i));
      const index = this.allAcceptedSizes.indexOf(newSizeName!);

      for (let i = 0; i < index; i++) {
        const size = this.allAcceptedSizes[i];

        if (this.item.acceptedSize.indexOf(size) === -1) {
          this.item.acceptedSize.push(size);
        }
      }
    }

    // ensure the ordering is by size
    const orderedSizes = this.allAcceptedSizes.filter(size => this.item.acceptedSize.indexOf(size) !== -1);

    this.previousSizes = this.item.acceptedSize = orderedSizes;
  }

  @watchBusy
  private async loadCodebooks() {
    let shopsExceptCurrent: ParcelShop[] = [];
    if (this.repository instanceof ParcelShopRepository) {
      const shops = await this.repository.getParentShops();
      shopsExceptCurrent = shops.filter(
        x =>
          x.id !== this.currentShopId && x.network_partner === NetworkStatusType.Parent && x.status === ParcelShopStatus.Unactive
      );
    }
    runInAction(() => (this.possibleParents = shopsExceptCurrent));
  }

  private registerReactions() {
    this.reactionDisposers.push(
      reaction(
        () => this.item.isActive,
        isActive => this.onIsActiveChange(isActive)
      ),
      reaction(
        () => this.isNetworkPartner,
        isNetworkPartner => this.onIsNetworkPartnerChange(isNetworkPartner)
      ),
      reaction(
        () => this.item.network_partner,
        networkPartner => this.onNetworkPartnerChange(networkPartner)
      ),
      reaction(
        () => this.item.card_payment,
        cardPayment => this.onCardPaymentChange(cardPayment)
      ),
      reaction(
        () => this.boxStation,
        boxStation => this.onBoxStationChange(boxStation)
      ),
      reaction(
        () => this.item.network_partner,
        networkPartner => this.onNetworkPartnerChangeCheckContact(networkPartner)
      ),
      reaction(
        () => this.item.parent_id,
        _networkPartner => this.changeSelectedCategory(this.item.network_partner)
      ),
      reaction(
        () => this.item.capacity_watcher,
        capacity_watcher => this.onCapacityWatcherChanged(capacity_watcher)
      )
    );
  }

  @action.bound
  onCapacityWatcherChanged(capacityWatcher: boolean) {
    if (this.isParcelBox && capacityWatcher && !this.isAlzaBox) {
      this.item.isActive = true;
    }
  }

  @action.bound
  onBoxStationChange(active: boolean) {
    if (active && !this.item.parcelBox) {
      this.boxStation = false;
      this.boxStationWarningShow = true;
    }
  }

  onCardPaymentChange(active: boolean) {
    if (active && (!this.item.payment_terminals || this.item.payment_terminals.length === 0)) {
      this.item.card_payment = false;
      this.cardWarningShow = true;
    }
  }

  protected onIsActiveChange(isActive: boolean) {
    if (!isActive) {
      this.item.dhl_express = false;
      this.item.active_finder = false;
    } else if (
      this.item.dhl_express === false &&
      [this.item.facility, this.item.route_code, this.item.service_area, this.item.service_point_id].some(x => x)
    ) {
      this.item.dhl_express = true;
    }
  }

  onIsNetworkPartnerChange(isNetworkPartner: boolean) {
    if (!isNetworkPartner) {
      this.item.network_partner = NetworkStatusType.None;
    }
  }

  onNetworkPartnerChange(networkPartner: NetworkStatusType) {
    if (networkPartner !== NetworkStatusType.Child) {
      this.item.parent_id = undefined;

      if (networkPartner === NetworkStatusType.Parent) {
        this.item.isActive = this.item.active_finder = this.item.capacity_watcher_active = false;
        this.item.capacity_watcher = this.item.vacation_watcher = this.item.vacation_watcher_active = false;
      }
    } else if (this.possibleParents.length) {
      this.item.parent_id = this.possibleParents[0].id;
    }

    this.changeSelectedCategory(networkPartner);
  }

  changeSelectedCategory(networkPartner: NetworkStatusType) {
    if (this.isParcelShopType) {
      switch (networkPartner) {
        case NetworkStatusType.None:
          if (this.initialNetworkPartnerType === NetworkStatusType.Parent) {
            this.item.selectedCategory = null as any;
          } else {
            // this.selectedCategory = this.selectedParent?.inherit || this.item.category_id;
            this.item.selectedCategory = this.item.category_id;
          }
          break;
        case NetworkStatusType.Parent:
          this.item.selectedCategory = null as any;
          this.item.isActive = false;
          this.item.active_finder = false;
          break;
        case NetworkStatusType.Child:
          this.item.selectedCategory = this.selectedParent?.inherit || this.item.category_id;
          break;
      }
    }
  }

  protected originalNetworkStatus: NetworkStatusType = NetworkStatusType.None;
  abstract get contactTypeIds(): number[];

  async onNetworkPartnerChangeCheckContact(networkPartner: number) {
    if (networkPartner !== NetworkStatusType.Parent) {
      this.originalNetworkStatus = networkPartner;
      return;
    }

    // Check conactTypeIds & required enums
    const requiredContactTypes = this.enums.values("contact_types").filter(x => x.network_parent);
    const contactTypeDiff = difference(
      this.contactTypeIds ?? [],
      requiredContactTypes.map(i => i.id)
    );

    if (contactTypeDiff.length === 0) return;

    await this.confirmationService.showConfirmation(
      this.localizationService
        .translateGeneral("change_network_partner_parent.text")
        .replace("%TYPES%", requiredContactTypes.map(x => x.name).join(", ")),
      this.localizationService.translateGeneral("change_network_partner_parent.title"),
      {
        text: this.localizationService.translateGeneral("change_network_partner_parent.confirm"),
        variant: "primary",
      },
      undefined,
      { hideCancelButton: true }
    );

    runInAction(() => {
      this.item.network_partner = this.originalNetworkStatus;
    });
  }

  protected onDeactivate(close: boolean) {
    if (close) {
      const reactionDisposers = this.reactionDisposers;
      this.reactionDisposers = [];
      reactionDisposers.forEach(disposer => disposer());
    }

    return super.onDeactivate(close);
  }

  protected abstract loadDetail(): Promise<TModel>;
}
