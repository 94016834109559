import { BusyWatcher, ConductorSingleChild, watchBusy } from "@frui.ts/screens";
import RecurringPaymentStoppage from "entities/recurringPaymentStoppage";
import { interfaces } from "inversify";
import { action, computed } from "mobx";
import ShopDetailContext from "models/shops/shopDetailContext";
import LocalizationService from "services/localizationService";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import PaymentStoppageDetailViewModel from "./paymentStoppageDetailViewModel";
import { getMonth, getYear } from "date-fns";
import { plainToClass } from "class-transformer";
import ConfirmationService from "services/confirmationService";
import SecurityService from "services/securityService";
import ParcelShop from "../../../../manualEntities/parcelShop";
import AccessPointPartners from "models/enumerations/accessPointPartners";

export default class PaymentStoppageViewModel extends ConductorSingleChild<PaymentStoppageDetailViewModel> {
  busyWatcher = new BusyWatcher();

  constructor(
    public context: ShopDetailContext<ParcelShop>,
    public localization: LocalizationService,
    private repository: ParcelShopRepository,
    private paymentStoppageDetailVM: ReturnType<typeof PaymentStoppageDetailViewModel.Factory>,
    private confirmationService: ConfirmationService,
    private security: SecurityService
  ) {
    super();
    this.name = localization.translateGeneral("parcel_box.invoicing.payment_stoppage");
  }

  get canEdit() {
    return this.security.isAllowed("edit", "payment_stoppage");
  }

  protected findNavigationChild(navigationName: string | undefined) {
    return undefined;
  }

  @computed
  get item() {
    return this.context.shop.active_recurring_payment_stoppages.map(item => plainToClass(RecurringPaymentStoppage, item));
  }

  @computed
  get infiniteItem() {
    return this.item.find(item => item.infinite);
  }

  @computed
  get periodItem() {
    return this.item.find(item => !item.infinite);
  }

  @computed
  get infiniteExists(): boolean {
    return !!this.infiniteItem;
  }

  @computed
  get isPeriodCurrent(): boolean {
    const period = this.periodItem;

    if (!period) {
      return false;
    }

    const now = new Date();
    return (
      now >= new Date(period.startsAtYear, period.startsAtMonth - 1) && now <= new Date(period.endsAtYear!, period.endsAtMonth!)
    );
  }

  @computed
  get isChecked(): boolean {
    return this.infiniteExists || this.isPeriodCurrent || this.isAlzaBox;
  }

  get isAlzaBox() {
    return this.context.shop.typeId === +AccessPointPartners.ALZA_BOX;
  }

  @action.bound
  @watchBusy
  async onCheckboxChanged() {
    if (this.isChecked) {
      if (this.isPeriodCurrent) {
        await this.deletePeriodStoppage();
      } else {
        await this.deleteInfiniteStoppage();
      }
    } else {
      await this.createInfiniteStoppage();
    }
  }

  @action.bound
  @watchBusy
  async createInfiniteStoppage() {
    const now = new Date();
    await this.repository.createPaymentStoppage(this.context.shop.id, {
      startsAtMonth: getMonth(now) + 1,
      startsAtYear: getYear(now),
      infinite: true,
    });
  }

  @action.bound
  @watchBusy
  async deleteInfiniteStoppage() {
    await this.repository.deletePaymentStoppage(this.context.shop.id, this.infiniteItem!.id);
  }

  @action.bound
  @watchBusy
  async deletePeriodStoppage() {
    if (!this.periodItem) {
      return;
    }

    if (this.isPeriodCurrent) {
      const { translateGeneral: tg } = this.localization;
      const confirm = await this.confirmationService.showConfirmation(
        tg("parcel_box.invoicing.payment_stoppage.delete_actual_period.description"),
        tg("parcel_box.invoicing.payment_stoppage.delete_actual_period.title"),
        { variant: "danger", text: tg("confirm_dialog.remove") },
        tg("confirm_dialog.keep")
      );

      if (confirm) {
        await this.repository.deletePaymentStoppage(this.context.shop.id, this.periodItem.id);
        if (this.infiniteExists) {
          await this.deleteInfiniteStoppage();
        }
      }
    } else {
      await this.repository.deletePaymentStoppage(this.context.shop.id, this.periodItem.id);
    }
  }

  @action.bound
  openForm() {
    const vm = this.paymentStoppageDetailVM(this.context.shop.id, this.item);
    void this.tryActivateChild(vm);
  }

  static Factory({ container }: interfaces.Context) {
    return (context: ShopDetailContext<ParcelShop>) =>
      new PaymentStoppageViewModel(
        context,
        container.get(LocalizationService),
        container.get(ParcelShopRepository),
        container.get(PaymentStoppageDetailViewModel.Factory),
        container.get(ConfirmationService),
        container.get(SecurityService)
      );
  }
}
