import User from "entities/user";
import { injectable } from "inversify";
import { action, computed, observable } from "mobx";
import { UserRole } from "../manualEntities/user";

@injectable()
export default class UserContext {
  @observable userId?: number;
  @observable name?: string;
  @observable userEmail?: string;
  userRoleId?: UserRole;
  @observable userRole?: string;
  @observable isAdmin = false;
  @observable.shallow assignedDepos?: number[] = [];
  @observable.shallow assignedCategories?: number[];

  @computed get isLogged() {
    return !!this.userId;
  }

  @action setCurrentUser(userInfo: User) {
    this.userId = userInfo.id;
    this.userEmail = userInfo.email;
    this.assignedDepos = userInfo.depoIds;
    this.assignedCategories = userInfo.psCategoryIds;
    this.userRoleId = userInfo.role;
    this.userRole = undefined;
    this.isAdmin = false;
    this.name = userInfo.firstName && userInfo.lastName ? `${userInfo.firstName} ${userInfo.lastName}` : undefined;
  }

  @action setCurrentUserRole(roleName: string, isAdmin: boolean) {
    this.userRole = roleName;
    this.isAdmin = isAdmin;
  }

  @action.bound logout() {
    this.userId = undefined;
    this.userEmail = undefined;
    this.assignedDepos = undefined;
    this.userRoleId = undefined;
    this.userRole = undefined;
    this.isAdmin = false;
  }
}
