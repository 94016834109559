import { Expose } from "class-transformer";
import { merge } from "lodash";
import { observable } from "mobx";
import { ContactModel } from "viewModels/shops/detail/types";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ContactDto {
  @observable
  name!: string;

  @observable
  @Expose({ name: "contact_type_id" })
  contactTypeId!: number;

  @observable
  phone?: string;

  @observable
  mobile?: string;

  @observable
  email!: string;

  @observable
  description?: string;

  static ValidationRules = {
    name: { required: true },
    contactTypeId: { required: true, number: true },
    email: { required: true },
  };
}

merge(ContactDto.ValidationRules, {
  name: { minLength: 4, maxLength: 35 },
  email: { isEmail: true, maxLength: 300 },
  phone: { isPhone: true },
  mobile: {
    isPhone: true,
    atLeastOneOf: {
      condition: (contact: ContactModel) => contact.isMandatory === true,
      key: "phone",
      modelName: "contact",
    },
  },
  description: {
    maxLength: 1000,
  },
});
