import { RepositoryBase } from "../repositoryBase";
import Setting from "../../entities/setting";

export default class SettingsRepository extends RepositoryBase {
  getSettings() {
    return this.callApi(api => api.all("settings").getEntities(Setting));
  }

  updateSettings(settings: any) {
    return this.callApi(api => api.all("settings").patchEntity(settings));
  }
}
