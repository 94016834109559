import { IPagingFilter } from "@frui.ts/data";
import { attachAutomaticDirtyWatcher, isDirty, resetDirty } from "@frui.ts/dirtycheck";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator } from "@frui.ts/validation";
import type { ISelectItem } from "@frui.ts/views";
import AttachmentsRepository from "data/repositories/attachmentsRepository";
import CompetitionsRepository from "data/repositories/competitionsRepository";
import EnumRepository from "data/repositories/enumRepository";
import UsersRepository from "data/repositories/usersRepository";
import Attachment from "entities/attachment";
import Competition from "entities/competition";
import CreateCompetitionInPsDto from "entities/createCompetitionInPsDto";
import PotentialPsDetailDto from "entities/potentialPsDetailDto";
import ParcelShop from "manualEntities/parcelShop";
import { User } from "manualEntities/user";
import NetworkStatusType from "manualEntities/networkStatusType";
import { action, computed, IReactionDisposer, observable, reaction, runInAction, toJS } from "mobx";
import EditableEnum from "models/enumerations/editableEnum";
import AdditionalInformation, { AdditionalInformationPotentialValidationRules } from "models/shops/newShop/additionalInformation";
import ShopDetailContext from "models/shops/shopDetailContext";
import { FileRejection } from "react-dropzone";
import EnumService from "services/enum";
import EventBus, { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import { IShopAdditionalInformationViewModel } from "../types";
import ListAttachmentDto from "../../../../entities/listAttachmentDto";
import undefinedToNull from "../../../../utils/undefinedToNull";

const PAGING_FILTER: IPagingFilter = {
  limit: 1000,
  offset: 0,
};

export class UploadItem {
  @observable id?: number;
  @observable originalFilename?: string;
  @observable mimeType?: string;
  @observable documentTypeId: number;
  @observable file?: File;

  @computed
  get name() {
    return this.file?.name ?? this.originalFilename ?? " - ";
  }

  static createFromAttachment(attachment: Attachment | ListAttachmentDto) {
    const uploadItem = new UploadItem();
    uploadItem.id = attachment.id;
    uploadItem.documentTypeId = attachment.documentTypeId;
    uploadItem.originalFilename = attachment.originalFilename;
    if (attachment instanceof ListAttachmentDto) {
      uploadItem.mimeType = attachment.mimeType;
    }

    return uploadItem;
  }
}

export default abstract class AdditionalInformationViewModelBase
  extends ScreenBase
  implements IShopAdditionalInformationViewModel {
  busyWatcher = new BusyWatcher();
  protected reactionDisposers = [] as IReactionDisposer[];

  @observable item: AdditionalInformation;
  @observable users: User[] = [];
  @observable enumItemTypes: EditableEnum[] = [];
  @observable enumTerminations: EditableEnum[] = [];
  @observable enumCompetitionIds: EditableEnum[] = [];
  @observable enumBusinessTypes: EditableEnum[] = [];

  @observable selectedContractType?: ISelectItem;
  @observable files: UploadItem[] = [];

  @observable oldCompetitions: Competition[] = [];
  @observable oldAttachments: UploadItem[] = [];

  constructor(
    public context: ShopDetailContext<PotentialPsDetailDto | ParcelShop> | undefined,
    public isParcelBox: boolean,
    public isAlzaBox: boolean,
    public isPotential: boolean,
    private usersRepository: UsersRepository,
    public localizationService: LocalizationService,
    protected enums: EnumService,
    private enumRepository: EnumRepository,
    private competitionsRepository: CompetitionsRepository,
    protected attachmentsRepository: AttachmentsRepository,
    public eventBus: EventBus
  ) {
    super();
  }

  async onInitialize() {
    const [item] = await Promise.all([
      this.loadDetail(),
      this.loadUsers(),
      this.loadItemTypes(),
      this.loadTerminations(),
      this.loadCompetitionIds(),
      this.loadBusinessTypes(),
    ]);

    this.prepareItem(item);
  }

  get isBusy() {
    return this.busyWatcher.isBusy;
  }

  get requiredNoteForPhaseIds() {
    return this.enums
      .values("potential_phases")
      .filter(phase => phase.required_reason)
      .map(phase => phase.id);
  }

  @bound
  // eslint-disable-next-line sonarjs/cognitive-complexity
  prepareItem(inputItem?: AdditionalInformation) {
    const item = inputItem ?? this.item;

    // eslint-disable-next-line sonarjs/cognitive-complexity
    runInAction(() => {
      if (this.context) {
        this.prepareCompetitions(item);
        this.prepareAttachments(item);

        if (!this.isPotential) {
          item.contract_sign_date = (this.context.shop as ParcelShop).contractSignDate;
          item.contract_ends_at = (this.context.shop as ParcelShop).contractEndsAt;
          item.business_state = (this.context.shop as ParcelShop).businessState;
          item.termination_reason = (this.context.shop as ParcelShop).terminationReason;

          if ((this.context.shop as ParcelShop).businessState === 1) {
            this.selectedContractType = this.contractTypes[(this.context.shop as ParcelShop).contractEndsAt ? 1 : 0];
          }
        } else {
          item.potential_phase_note = (this.context.shop as PotentialPsDetailDto).phaseNote;
          item.potential_phase = (this.context.shop as PotentialPsDetailDto).phase;
        }

        item.business_type_id = this.context.shop.businessTypeId;
      } else {
        this.files = (item?.attachments ?? []).map(attachment => UploadItem.createFromAttachment(attachment));
      }

      if (inputItem) {
        attachAutomaticDirtyWatcher(item);
        attachAutomaticValidator(
          item,
          this.isPotential ? AdditionalInformationPotentialValidationRules(this.requiredNoteForPhaseIds) : {}
        );
        this.setItem(item);
      } else {
        this.disposeReactions();
        resetDirty(item);
      }
      this.registerReactions();
    });
  }

  private prepareCompetitions(item: AdditionalInformation) {
    const receivedCompetitions = this.context!.shop.competitions?.map(x => Object.assign(new Competition(), toJS(x))) ?? [];
    this.oldCompetitions = receivedCompetitions;
    item.competitions = receivedCompetitions.map(item => {
      attachAutomaticValidator(item, CreateCompetitionInPsDto.ValidationRules);
      attachAutomaticDirtyWatcher(item);
      return item;
    });
  }

  private prepareAttachments(item: AdditionalInformation) {
    this.files = [];
    this.oldAttachments =
      this.context!.shop.attachments?.map(x => {
        const uploadItem = UploadItem.createFromAttachment(x);
        this.files.push(uploadItem);
        return uploadItem;
      }) ?? [];
  }

  private registerReactions() {
    this.reactionDisposers.push(
      reaction(
        () => this.item.business_state,
        value => this.businessStateChanged(value)
      ),
      reaction(
        () => this.selectedContractType,
        value => this.contractTypeChanged(Number(value?.value) ?? undefined)
      )
    );

    this.eventBus.subscribe(Events.AccessPoints.Context.Updated, this.prepareItem);
  }

  @action.bound
  businessStateChanged(value?: number) {
    if (value === 4) {
      this.item.termination_reason = Number(this.terminations[0]?.value) ?? undefined;
      this.selectedContractType = undefined;
      this.item.contract_ends_at = undefined;
    } else if (value === 1) {
      this.item.termination_reason = undefined;
      this.selectedContractType = this.contractTypes[0] ?? undefined;
      this.item.contract_ends_at = undefined;
    } else {
      this.item.termination_reason = undefined;
      this.selectedContractType = undefined;
      this.item.contract_ends_at = undefined;
    }
  }

  @action.bound
  contractTypeChanged(value?: number) {
    if (value !== 2) {
      this.item.contract_ends_at = undefined;
    } else {
      this.item.contract_ends_at = new Date();
    }
  }

  @watchBusy
  async loadUsers() {
    const response = await this.usersRepository.getUsers(PAGING_FILTER, {});
    runInAction(() => {
      this.users = response[0] ?? [];
    });
  }

  @watchBusy
  async loadItemTypes() {
    const result = await this.enumRepository.getAll(this.isParcelBox ? "pb_types" : "ps_types", PAGING_FILTER, true);
    if (result) {
      runInAction(() => {
        this.enumItemTypes = result[0] ?? [];
      });
    }
  }

  @watchBusy
  async loadTerminations() {
    const result = await this.enumRepository.getAll(
      this.isParcelBox ? "pb_terminations" : "ps_terminations",
      PAGING_FILTER,
      true
    );
    if (result) {
      runInAction(() => {
        this.enumTerminations = result[0] ?? [];
      });
    }
  }

  @watchBusy
  async loadCompetitionIds() {
    const result = await this.enumRepository.getAll(
      this.isParcelBox ? "pb_competitions" : "ps_competitions",
      PAGING_FILTER,
      true
    );
    if (result) {
      runInAction(() => {
        this.enumCompetitionIds = result[0] ?? [];
      });
    }
  }

  @watchBusy
  async loadBusinessTypes() {
    const result = await this.enumRepository.getAll(this.isParcelBox ? "pb_types" : "ps_types", PAGING_FILTER, true);
    if (result) {
      runInAction(() => {
        this.enumBusinessTypes = result[0] ?? [];
      });
    }
  }

  @watchBusy
  saveCompetitions() {
    const { hasCreate, hasUpdate, hasDelete } = this.calculateCRUD<Competition>(this.item.competitions, this.oldCompetitions);
    return Promise.all([
      ...hasCreate.map(item => this.competitionsRepository.createCompetitions(item)),
      ...hasUpdate.map(item => this.competitionsRepository.updateCompetitions(item.id, undefinedToNull(item))),
      ...hasDelete.map(item => this.competitionsRepository.deleteCompetitions(item.id)),
    ]);
  }

  @action.bound addCompetition() {
    const competition = new Competition();

    competition.entityType = this.isPotential ? 1 : 0;
    if (this.context && this.context.shop.id) {
      // If edit flow, selected psID
      competition.psId = this.context.shop.id;
    }

    attachAutomaticValidator(competition, CreateCompetitionInPsDto.ValidationRules);
    attachAutomaticDirtyWatcher(competition);

    this.item.competitions.push(competition);
  }

  @action.bound removeCompetition(competition: Competition) {
    const index = this.item.competitions.indexOf(competition);

    if (index >= 0) {
      this.item.competitions.splice(index, 1);
    }
  }

  @action.bound addSignDate() {
    this.item.contract_sign_date = new Date();
  }

  @action.bound removeSignDate() {
    this.item.contract_sign_date = undefined;
  }

  @action.bound setItem(item: AdditionalInformation) {
    this.item = item;
  }

  @action.bound
  onAttach(documentTypeId: number, acceptedFiles: File[], fileRejections?: FileRejection[]) {
    if (acceptedFiles.length) {
      const items = acceptedFiles.map(f => {
        const item = new UploadItem();
        item.documentTypeId = documentTypeId;
        item.file = f;
        return item;
      });

      this.files.push(...items);
    }
  }

  @action.bound
  onRemoveFile(file: UploadItem) {
    const index = this.files.indexOf(file);

    if (index >= 0) {
      this.files.splice(index, 1);
    }
  }

  @computed
  get contractFiles() {
    return this.files.filter(item => item.documentTypeId == 0);
  }

  @computed
  get otherFiles() {
    return this.files.filter(item => item.documentTypeId == 1);
  }

  get possibleUsers(): TreeItem[] {
    return this.users.map(x => ({
      id: `${x.id}`,
      label: x.first_name && x.last_name ? x.first_name + " " + x.last_name : x.email,
    }));
  }

  get activityTypes(): ISelectItem[] {
    return this.enums.values("activity_types").map(item => ({ value: item.id, label: item.name }));
  }

  get activityCategories(): ISelectItem[] {
    return this.enums.values("activity_categories").map(item => ({ value: item.id, label: item.name }));
  }

  get activityStates(): ISelectItem[] {
    return this.enums.values("activity_states").map(item => ({ value: item.id, label: item.name }));
  }

  get itemTypes(): ISelectItem[] {
    return this.enumItemTypes.map(item => ({ value: item.id, label: item.value }));
  }

  get businessStates(): ISelectItem[] {
    return this.enums.values("business_states").map(item => ({ value: item.id, label: item.name }));
  }

  get terminations(): ISelectItem[] {
    return this.enumTerminations.map(item => ({ value: item.id, label: item.value }));
  }

  get competitionIds(): ISelectItem[] {
    return this.enumCompetitionIds.map(item => ({ value: item.id, label: item.value }));
  }

  get businessTypes(): ISelectItem[] {
    return this.enumBusinessTypes.map(item => ({ value: item.id, label: item.value }));
  }

  get potentialPhases(): ISelectItem[] {
    return this.enums.values("potential_phases").map(item => ({ value: item.id, label: item.name }));
  }

  get isTerminationsVisible() {
    return this.item.business_state === 4;
  }

  get contractTypes(): ISelectItem[] {
    return [
      { value: 1, label: "Doba neurčitá" },
      { value: 2, label: "Doba určitá" },
    ];
  }

  get isContractTypesVisible() {
    return this.isParcelBox && this.item.business_state === 1;
  }

  get isContractEndVisible() {
    return this.isContractTypesVisible && this.selectedContractType?.value === 2;
  }

  get isContractSignVisible() {
    const shop = this.context?.shop;
    if (shop instanceof ParcelShop) {
      return !this.isPotential && shop.network_partner !== NetworkStatusType.Child;
    }

    return !this.isPotential;
  }

  get isBusinessStateVisible() {
    return !this.isPotential;
  }

  get isBusinessTypeVisible() {
    const shop = this.context?.shop;
    if (shop instanceof ParcelShop) {
      return shop.network_partner !== NetworkStatusType.Parent;
    }

    return true;
  }

  protected onDeactivate(close: boolean) {
    if (close) {
      this.disposeReactions();
    }

    return super.onDeactivate(close);
  }

  private disposeReactions() {
    const reactionDisposers = this.reactionDisposers;
    this.reactionDisposers = [];
    reactionDisposers.forEach(disposer => disposer());
  }

  @bound
  calculateCRUD<TModel extends Competition | UploadItem>(items: TModel[], oldItems: TModel[]) {
    const hasCreate = items.filter(x => !x.id);
    const hasUpdate = items.filter(x => x.id && isDirty(x));
    const hasDelete = oldItems.filter(x => !items.map(a => a.id).includes(x.id));

    return { hasCreate, hasUpdate, hasDelete };
  }

  @bound
  isItemArrayDirty<TModel extends Competition | UploadItem>(items: TModel[], oldItems: TModel[]) {
    const { hasCreate, hasUpdate, hasDelete } = this.calculateCRUD<TModel>(items, oldItems);

    return hasCreate.length > 0 || hasUpdate.length > 0 || hasDelete.length > 0;
  }

  @computed
  get isDirty() {
    if (this.isInitialized) {
      const isCompetitionsDirty = this.isItemArrayDirty<Competition>(this.item.competitions, this.oldCompetitions);
      const isAttachmentsDirty = this.isItemArrayDirty<UploadItem>(this.files, this.oldAttachments);

      return isDirty(this.item) || isCompetitionsDirty || isAttachmentsDirty;
    }

    return false;
  }

  protected abstract loadDetail(): Promise<AdditionalInformation>;
}
