import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import OpportunityViewModel from "viewModels/opportunities/opportunitiesViewModel";
import List from "./list";

const opportunitiesView: ViewComponent<OpportunityViewModel> = observer(({ vm }) => {
  return vm.activeChild ? <View vm={vm.activeChild} /> : <List vm={vm} />;
});

export default registerView(opportunitiesView, OpportunityViewModel);
