import { merge } from "lodash";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class PsUserDto {
  @observable
  email!: string;

  @observable
  role!: number;

  static ValidationRules = {
    email: { required: true },
    role: { required: true, number: true },
  };
}

merge(PsUserDto.ValidationRules, {
  email: {
    isEmail: true,
  },
});
