import { IPagingFilter } from "@frui.ts/data";
import { bound } from "@frui.ts/helpers";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import { BonusDisplayModel } from "entities/bonus";
import BonusListDto from "entities/bonusListDto";
import { interfaces } from "inversify";
import ParcelShop from "manualEntities/parcelShop";
import ParcelShopStatus from "manualEntities/parcelShopStatus";
import EnumService from "services/enum";
import EventBus, { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import LazyListViewModelBase from "viewModels/lazyListViewModelBase";
import BonusDetailViewModel from "./bonusDetailViewModel";
import BonusNoteDetailViewModel from "./bonusNoteDetailViewModel";
import ConfirmationService from "services/confirmationService";
import UserContext from "services/userContext";

export default class BonusesViewModel extends LazyListViewModelBase<
  BonusDisplayModel,
  BonusDetailViewModel | BonusNoteDetailViewModel
> {
  private shop: ParcelShop;

  constructor(
    private shopId: number,
    public isParcelBox: boolean,
    private repository: ParcelShopRepository,
    public localization: LocalizationService,
    private enums: EnumService,
    private eventBus: EventBus,
    private detailVMFactory: ReturnType<typeof BonusDetailViewModel.Factory>,
    private noteDetailVMFactory: ReturnType<typeof BonusNoteDetailViewModel.Factory>,
    private confirmationService: ConfirmationService,
    private userContext: UserContext
  ) {
    super();

    this.name = localization.translateGeneral(`parcel_${isParcelBox ? "box" : "shop"}.invoicing.bonus_history`);
  }

  onInitialize() {
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.ParcelShops.Bonuses.Changed, this.reload));

    return super.onInitialize();
  }

  async loadData(paging: IPagingFilter) {
    this.shop = await this.repository.getDetail(this.shopId);
    const data = await this.repository.getBonuses(this.shopId, paging).then(x => x[0]);

    return data.map(x => this.getBonusDisplayModel(x));
  }

  @bound private getBonusDisplayModel(input: BonusListDto) {
    const result = new BonusDisplayModel();
    Object.assign(result, input);
    result.typeName = this.enums.value("bonus_types", input.type)?.name ?? "";
    result.statusName = this.enums.value("bonus_status", input.status)?.name ?? "";

    return result;
  }

  async deleteBonus(bonusId: number) {
    const confirm = await this.confirmationService.showConfirmation(
      this.translate("delete_confirm.message"),
      this.translate("delete_confirm.title"),
      { variant: "danger", text: this.translate("delete_confirm.delete") },
      { text: this.translate("delete_confirm.cancel") }
    );

    if (!confirm) {
      return;
    }

    await this.repository.deleteBonus(this.shopId, bonusId);
    this.reload();
  }

  @bound
  canBeDeleted(bonus: BonusListDto) {
    return this.userContext.isAdmin && this.enums.value("bonus_status", bonus.status)?.name === "Čekající na export do K2";
  }

  @bound
  add() {
    const viewModel = this.detailVMFactory(this.shopId, this.isParcelBox, undefined);
    return this.tryActivateChild(viewModel);
  }

  @bound
  openNoteDetail(bonus: BonusListDto) {
    const viewModel = this.noteDetailVMFactory(bonus);
    return this.tryActivateChild(viewModel);
  }

  get canAddBonus() {
    return this.shop && this.shop.status !== ParcelShopStatus.Disabled;
  }

  @bound
  translate(key: string) {
    return this.localization.translateGeneral("parcel_shop.invoicing.bonus." + key);
  }

  static Factory({ container }: interfaces.Context) {
    return (shopId: number, isParcelBox: boolean) =>
      new BonusesViewModel(
        shopId,
        isParcelBox,
        container.get(ParcelShopRepository),
        container.get(LocalizationService),
        container.get(EnumService),
        container.get(EventBus),
        container.get(BonusDetailViewModel.Factory),
        container.get(BonusNoteDetailViewModel.Factory),
        container.get(ConfirmationService),
        container.get(UserContext)
      );
  }
}
