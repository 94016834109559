import { SortingDirection } from "@frui.ts/data";
import { nameof } from "@frui.ts/helpers";
import { watchBusy } from "@frui.ts/screens";
import Subject, { SubjectFilter } from "entities/subject";
import { EventBus } from "light-event-bus";
import { action } from "mobx";
import ConfirmationService from "services/confirmationService";
import { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import ListViewModelBase from "viewModels/listViewModelBase";
import SubjectDetailViewModel from "./subjectDetailViewModel";
import SubjectRepository from "data/repositories/subjectRepository";
import { depoSelectOption, formatDepoName, subjectTypeSelectOption } from "utils/helpers";
import EnumService from "services/enum";

export default class SubjectViewModel extends ListViewModelBase<Subject, SubjectFilter, SubjectDetailViewModel> {
  static notificationScope = "subjects";

  constructor(
    private repository: SubjectRepository,
    private eventBus: EventBus,
    private subjectDetailViewModelFactory: ReturnType<typeof SubjectDetailViewModel.Factory>,
    public confirmationService: ConfirmationService,
    public localizationService: LocalizationService,
    private enums: EnumService
  ) {
    super();
    this.navigationName = "subject";
    this.name = localizationService.translateGeneral("menu.subjects");
    this.pagingFilter.limit = 10; // just to show paging
    this.pagingFilter.sortColumn = nameof<Subject>("subjectId");
    this.pagingFilter.sortDirection = SortingDirection.Descending;
  }

  onInitialize() {
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.Subjects.Changed, this.loadData));
    this.resetFilterAndLoad();
  }

  protected resetFilterValues(filter: SubjectFilter) {
    filter.ic = undefined;
    filter.name = undefined;
    filter.subject_id = undefined;
    filter.subject_type_id = undefined;
    filter.depo_id = undefined;
    filter.isActive = true;
    filter.isDisabled = false;
  }

  get subjectTypes() {
    return this.enums
      .values("subject_types")
      .filter(type => type.code === "7" || type.code === "9") // FIX after 120408 will be merged
      .map(subjectTypeSelectOption);
  }

  get depos() {
    return this.enums.values("depos").map(depoSelectOption);
  }

  getSubjectType(code: number) {
    const subjectType = this.enums.value("subject_types", code);
    return subjectType?.name ?? " - ";
  }

  getDepoName(code: number) {
    const depo = this.enums.value("depos", code);
    return formatDepoName(depo);
  }

  @action.bound
  @watchBusy
  async loadData() {
    return this.repository.getSubjects(this.pagingFilter, this.appliedFilter).then(this.setData);
  }

  @action.bound openDetail(item: Subject) {
    return this.tryActivateChild(this.subjectDetailViewModelFactory(item));
  }

  protected async findNavigationChild(navigationName: string) {
    if (+navigationName) {
      const subject = await this.repository.getSubject(+navigationName);
      return this.subjectDetailViewModelFactory(subject);
    }
  }
}
