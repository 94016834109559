import { Expose, Type } from "class-transformer";
import { observable } from "mobx";
import AuthorResponseDto from "./authorResponseDto";
import MessageDetailResponseDtoType from "./messageDetailResponseDtoType";
import ParcelShopResponseDto from "./parcelShopResponseDto";
import RecipientDto from "./recipientDto";
import { merge } from "lodash";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class MessageDetailResponseDto {
  id!: number;

  @observable
  subject!: string;

  @observable
  @Type(() => AuthorResponseDto)
  author?: AuthorResponseDto;

  @observable
  @Type(() => ParcelShopResponseDto)
  @Expose({ name: "parcel_shops" })
  parcelShops?: ParcelShopResponseDto[];

  /**
   * For received messages, true if was already read by someone from PPL
   */
  @observable
  @Expose({ name: "read_by_ppl" })
  readByPpl!: boolean;

  /**
   * Readable list of recipients
   */
  @observable
  @Expose({ name: "recipients_list" })
  recipientsList!: string[];

  /**
   * Readable list of depo_ids of recipients
   */
  @observable
  @Expose({ name: "depo_ids" })
  depoIds?: number[];

  /**
   * Readable list of ps_category_ids of recipients
   */
  @observable
  @Expose({ name: "ps_category_ids" })
  psCategoryIds?: number[];

  /**
   * Readable list of salesman_ids of recipients
   */
  @observable
  @Expose({ name: "salesman_ids" })
  salesmanIds?: number[];

  /**
   * Message type Received / Sent
   */
  @observable
  type!: MessageDetailResponseDtoType;

  @observable
  resolved!: boolean;

  @observable
  @Expose({ name: "parent_id" })
  parentId!: number;

  @observable
  @Expose({ name: "reply_counter" })
  replyCounter!: number;

  /**
   * Could be reply done?
   */
  @observable
  @Expose({ name: "group_message" })
  groupMessage!: boolean;

  @observable
  @Expose({ name: "created_at" })
  createdAt!: string;

  @observable
  @Expose({ name: "updated_at" })
  updatedAt!: string;

  @observable
  text!: string;

  @observable
  @Type(() => RecipientDto)
  recipients!: RecipientDto[];

  @observable
  @Type(() => MessageDetailResponseDto)
  @Expose({ name: "thread_messages" })
  threadMessages!: MessageDetailResponseDto[];

  static ValidationRules = {
    id: { required: true, number: true },
    subject: { required: true },
    readByPpl: { required: true },
    recipientsList: { required: true },
    type: { required: true },
    resolved: { required: true },
    parentId: { required: true, number: true },
    replyCounter: { required: true, number: true },
    groupMessage: { required: true },
    createdAt: { required: true },
    updatedAt: { required: true },
    text: { required: true },
    recipients: { required: true },
    threadMessages: { required: true },
  };
}

merge(MessageDetailResponseDto.ValidationRules, {
  subject: {
    maxLength: 200,
  },
  text: {
    maxLength: 1000,
  },
});

// TODO this was in the original message:
//  recipients: TreeItemNode[];

export class MessageFilter {
  type: MessageDetailResponseDtoType;

  id?: string;

  author?: string;
  recipient?: string;
  subject?: string;

  parcel_shop_name?: string;
  parcel_shop_ids?: number[];

  date_from?: Date;
  date_to?: Date;

  depo_ids?: number[];
  depo_name?: string;

  salesman_ids: number[];
  salesman_name: string;

  resolved?: boolean;

  ps_category_ids?: number[];
  ps_category_name: string;
}
