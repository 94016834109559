import { validate } from "@frui.ts/validation";

export function asString(input: any) {
  return typeof input === "string" ? input : undefined;
}

export function validateAll(items: any[]) {
  return items.reduce((acc: boolean, item) => validate(item) && acc, true);
}

export function mergeArrays<T>(arrays: T[][]) {
  const result = [] as T[];
  for (const arr of arrays) {
    if (arr && arr.length) {
      result.push(...arr);
    }
  }

  return result;
}
