import { registerView, ViewComponent } from "@frui.ts/views";
import Table from "controls/table";
import ActivityDetailDto from "entities/activityDetailDto";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { activityStatusIcon, formatDate } from "utils/helpers";
import ActivitiesViewModel from "viewModels/dashboard/activitiesViewModel";
import iconExternalLink from "../../assets/icons/icon-external-link.svg";
import iconEdit from "assets/icons/icon-edit.svg";
import "./memoDetailView.scss";
import { DAKTELA_URL } from "views/shops/detail/activities";
import Activity from "entities/activity";
import HeaderRow from "controls/headerRow";
import ListActivityDto from "entities/listActivityDto";

const activitiesView: ViewComponent<ActivitiesViewModel> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral } = vm.localizationService;
  const ta = (code: string) => translateAttribute("activity", code);
  const tg = (code: string) => translateGeneral(`activities.${code}`);

  return (
    <>
      <HeaderRow title={tg("title")} level={2}>
        <div className="d-flex justify-content-end align-items-center">
          <Button onClick={() => vm.openActivity(undefined)}>{translateGeneral("activity.add")}</Button>
        </div>
      </HeaderRow>
      <div className="mineMemosTableWrapper">
        <Table
          data={(vm.items ?? []).slice()}
          keyField="id"
          rowClasses={(row: Activity) => (row.afterDeadline ? "after-deadline" : "")}
          columns={[
            {
              dataField: "status",
              text: ta("state"),
              classes: "memoStatus",
              headerStyle: {
                width: "180px",
              },
              formatter: (_cell, row: ListActivityDto) => {
                return vm.isAllowedToChangeActivityState(row.assignees) ? (
                  <Dropdown id="status">
                    <Dropdown.Toggle variant="link">
                      <img src={activityStatusIcon(row.state)} className="pr-2" />
                      {vm.getActivityState(row.state)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {vm.activityStates
                        .filter(item => item.value !== row.state)
                        .map(item => (
                          <Dropdown.Item onSelect={() => vm.changeActivityState(row.id, item.value as number)} key={item.value}>
                            {item.label}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  vm.getActivityState(row.state)
                );
              },
            },
            {
              dataField: "type",
              isDummyField: true,
              text: ta("type"),
              formatter: (_cell, row: Activity) => vm.getActivityType(row.type),
            },
            {
              dataField: "assignees",
              text: ta("assignees"),
              headerStyle: {
                width: "180px",
              },
              formatter: (_cell, row: ListActivityDto) => {
                const assignees = row.assignees.map(assignee_id => vm.getUserName(assignee_id)).join(", ");
                return <span title={assignees}>{assignees}</span>;
              },
            },
            {
              dataField: "date",
              text: ta("date"),
              formatter: (_cell, row: Activity) => formatDate(row.date),
            },
            {
              dataField: "time",
              text: ta("time"),
            },
            {
              dataField: "category",
              text: ta("category_id"),
              formatter: (_cell, row: Activity) => vm.getActivityCategory(row.category),
            },
            {
              dataField: "note",
              isDummyField: true,
              text: ta("note"),
              headerStyle: {
                width: "100px",
                textAlign: "center",
              },
              align: "center",
              formatter: (_cell: any, row: ActivityDetailDto) => {
                return (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    className="noRowClick button-icon"
                    onClick={() => vm.openActivity(row)}>
                    <img src={iconEdit} alt="show" />
                  </Button>
                );
              },
            },
            {
              dataField: "vm",
              isDummyField: true,
              text: ta("vm"),
              headerStyle: {
                width: "100px",
                textAlign: "center",
              },
              align: "center",
              formatter: (_cell: any, row: ActivityDetailDto) => {
                if (row.psId != -1)
                  return (
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      className="noRowClick button-icon"
                      onClick={() => vm.openVM(row)}>
                      <img src={iconExternalLink} alt="show" />
                    </Button>
                  );
              },
            },
            {
              dataField: "datkelaId",
              isDummyField: true,
              text: ta("daktela_id"),
              headerStyle: {
                width: "100px",
                textAlign: "center",
              },
              align: "center",
              formatter: (_cell: any, row: Activity) => {
                if (row.daktelaId)
                  return (
                    <a
                      className="noRowClick button-icon btn btn-sm btn-outline-secondary"
                      key="daktelaId"
                      href={`${DAKTELA_URL}/${row.daktelaId}`}
                      target="_new">
                      <img src={iconExternalLink} alt="edit" />
                    </a>
                  );
              },
            },
          ]}
        />
      </div>
    </>
  );
});
export default registerView(activitiesView, ActivitiesViewModel);
