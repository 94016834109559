import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ParcelBoxPsDto {
  id!: number;

  @observable
  name!: string;

  @observable
  @Expose({ name: "cust_id" })
  custId!: number;

  @observable
  @Expose({ name: "depo_id" })
  depoId!: number;

  /**
   * ID of access_point_partner enum
   */
  @observable
  @Expose({ name: "type_id" })
  typeId!: number;

  static ValidationRules = {
    id: { required: true, number: true },
    name: { required: true },
    custId: { required: true, number: true },
    depoId: { required: true, number: true },
    typeId: { required: true, number: true },
  };
}
