import React, { FunctionComponent } from "react";
import { ConfigurationSectionProps } from "../types";
import { observer } from "mobx-react-lite";
import { Col, Form, Row } from "react-bootstrap";
import { Check } from "@frui.ts/bootstrap";
import { StringInput } from "controls/stringInput";

const DHLExpress: FunctionComponent<ConfigurationSectionProps> = observer(({ vm }) => {
  const { translateAttribute } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("parcel_shop", attribute);
  };

  return (
    <>
      <Form.Group>
        <Check custom target={vm.item} property="dhl_express" label={ta("dhl_express")} id="dhl_express" />
      </Form.Group>

      <Row>
        <Col md={1} />
        <Form.Group as={Col} md={3} className={vm.item.dhl_express ? "required" : undefined}>
          <Form.Label>{ta("facility")}</Form.Label>
          <StringInput trim={true} target={vm.item} property="facility" disabled={!vm.item.dhl_express} />
        </Form.Group>
        <Form.Group as={Col} md={3} className={vm.item.dhl_express ? "required" : undefined}>
          <Form.Label>{ta("route_code")}</Form.Label>
          <StringInput trim={true} target={vm.item} property="route_code" disabled={!vm.item.dhl_express} />
        </Form.Group>
      </Row>
      <Row>
        <Col md={1} />
        <Form.Group as={Col} md={3} className={vm.item.dhl_express ? "required" : undefined}>
          <Form.Label>{ta("service_area")}</Form.Label>
          <StringInput trim={true} target={vm.item} property="service_area" disabled={!vm.item.dhl_express} />
        </Form.Group>
        <Form.Group as={Col} md={3} className={vm.item.dhl_express ? "required" : undefined}>
          <Form.Label>{ta("service_point_id")}</Form.Label>
          <StringInput trim={true} target={vm.item} property="service_point_id" disabled={!vm.item.dhl_express} />
        </Form.Group>
      </Row>
    </>
  );
});

export default DHLExpress;
