import { observable } from "mobx";
import { Type } from "class-transformer";
import StockTakingRecordDto from "./stockTakingRecordDto";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class StockTakingDto {
  @observable
  @Type(() => StockTakingRecordDto)
  items!: StockTakingRecordDto[];

  static ValidationRules = {
    items: { required: true },
  };
}
