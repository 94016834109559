import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import { merge } from "lodash";
import { OpeningHoursModel } from "../models/shops/newShop/openingHoursModel";

export class SubjectFilter {
  name?: string;
  subject_id?: string;
  subject_type_id?: number;
  ic?: string;
  depo_id?: number;
  isActive?: boolean;
  isDisabled?: boolean;
}

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Subject {
  /**
   * Internal generated ID
   */
  id!: number;

  /**
   * ID of subject from EPS
   */
  @observable
  @Expose({ name: "subject_id" })
  subjectId!: number;

  /**
   * Name
   */
  @observable
  name!: string;

  /**
   * Secondary name
   */
  @observable
  subname?: string;

  /**
   * IČO
   */
  @observable
  ic!: string;

  /**
   * DIČ
   */
  @observable
  dic?: string;

  /**
   * Depo ID (enum value `depos`)
   */
  @observable
  @Expose({ name: "depo_id" })
  depoId!: number;

  /**
   * Subject type ID (enum value `subject_types`)
   */
  @observable
  @Expose({ name: "subject_type_id" })
  subjectTypeId!: number;

  /**
   * Active or discarded
   */
  @observable
  active!: boolean;

  /**
   * Bank account prefix
   */
  @observable
  @Expose({ name: "bank_account_prefix" })
  bankAccountPrefix?: string;

  /**
   * Bank account number
   */
  @observable
  @Expose({ name: "bank_account_number" })
  bankAccountNumber?: string;

  /**
   * Bank account code
   */
  @observable
  @Expose({ name: "bank_account_code" })
  bankAccountCode?: string;

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    subjectId: { required: true, number: true },
    name: { required: true },
    ic: { required: true },
    depoId: { required: true, number: true },
    subjectTypeId: { required: true, number: true },
    active: { required: true },
    createdAt: { required: true },
    updatedAt: { required: true },
  };
}

merge(Subject.ValidationRules, {
  name: { maxLength: 250 },
  ic: { isIc: true },
  dic: { isDic: true },
});

export const validationRulesWithBankAccount = (forcePresence: boolean) => {
  return {
    bankAccountPrefix: {
      isBankAccountPrefix: true,
    },
    bankAccountNumber: {
      isBankAccountNumber: true,
      requiredIf: { condition: (x: Subject) => x.bankAccountPrefix || x.bankAccountCode || forcePresence },
    },
    bankAccountCode: {
      requiredIf: { condition: (x: Subject) => x.bankAccountPrefix || x.bankAccountNumber || forcePresence },
    },
  };
};
