import { observable } from "mobx";
import { Expose, Type } from "class-transformer";
import { merge } from "lodash";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CreateOpportunityDto {
  @observable
  @Expose({ name: "enum_opportunity_id" })
  enumOpportunityId!: number;

  @observable
  @Expose({ name: "entity_type" })
  entityType!: number;

  /**
   * fulfillment date
   */
  @observable
  @Type(() => Date)
  date!: Date;

  /**
   * fulfillment time
   */
  @observable
  time!: string;

  /**
   * enum_opportunity_status_id
   */
  @observable
  @Expose({ name: "enum_status_id" })
  enumStatusId!: number;

  /**
   * enum_opportunity_status_reason_id
   */
  @observable
  @Expose({ name: "enum_status_reason_id" })
  enumStatusReasonId?: number;

  /**
   * enum_competition_id
   */
  @observable
  @Expose({ name: "enum_competition_id" })
  enumCompetitionId!: number;

  /**
   * Solved capacity
   */
  @observable
  capacity?: number;

  /**
   * Note
   */
  @observable
  note?: string;

  @observable
  @Expose({ name: "ps_id" })
  psId!: number;

  static ValidationRules = {
    enumOpportunityId: { required: true, number: true },
    entityType: { required: true, number: true },
    date: { required: true },
    time: { required: true },
    enumStatusId: { required: true, number: true },
    enumStatusReasonId: { number: true },
    enumCompetitionId: { required: true, number: true },
    capacity: { number: true },
    psId: { required: true, number: true },
  };
}

merge(CreateOpportunityDto.ValidationRules, {
  time: { required: true, isTime: true },
});
