import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import SimpleSubjectDto from "./simpleSubjectDto";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class PsItemDto {
  /**
   * Internal generated ID
   */
  id!: number;

  /**
   * ID of subject from EPS
   */
  @observable
  @Expose({ name: "subject_id" })
  subjectId!: number;

  /**
   * Name
   */
  @observable
  name!: string;

  /**
   * Secondary name
   */
  @observable
  subname?: string;

  /**
   * Description
   */
  @observable
  description?: string;

  /**
   * CRM description
   */
  @observable
  @Expose({ name: "description_crm" })
  descriptionCrm?: string;

  /**
   * Phone type
   */
  @observable
  @Expose({ name: "phone_type" })
  phoneType?: string;

  /**
   * Salesman ID aka OBCHZ (enum value `salesmans`)
   */
  @observable
  @Expose({ name: "salesman_id" })
  salesmanId!: number;

  /**
   * Depo ID (enum value `depos`)
   */
  @observable
  @Expose({ name: "depo_id" })
  depoId!: number;

  /**
   * Type ID (enum value `ps_types`)
   */
  @observable
  @Expose({ name: "type_id" })
  typeId!: number;

  /**
   * Status (enum value `ps_status`)
   */
  @observable
  status!: number;

  @observable
  latitude!: number;

  @observable
  longitude!: number;

  /**
   * Cust ID - this is used for all comunications HIPP
   */
  @observable
  @Expose({ name: "cust_id" })
  custId!: number;

  /**
   * ID from EBS - this is not unique
   */
  @observable
  @Expose({ name: "dhl_ps_id" })
  dhlPsId!: number;

  /**
   * DHL PS ID
   */
  @observable
  @Expose({ name: "dhl_ps_id_zip" })
  dhlPsIdZip!: string;

  /**
   * Device IMEI
   */
  @observable
  imei!: string;

  /**
   * Payment by card allowed?
   */
  @observable
  @Expose({ name: "card_payment" })
  cardPayment!: boolean;

  /**
   * Network partner - type of partner 0 - none, 1 - parent, 2 - child
   */
  @observable
  @Expose({ name: "network_partner" })
  networkPartner!: number;

  /**
   * Network Partner - parent id
   */
  @observable
  @Expose({ name: "parent_id" })
  parentId!: number;

  @observable
  @Expose({ name: "dhl_express" })
  dhlExpress!: boolean;

  @observable
  facility!: string;

  @observable
  @Expose({ name: "route_code" })
  routeCode!: string;

  @observable
  @Expose({ name: "service_area" })
  serviceArea!: string;

  @observable
  @Expose({ name: "service_point_id" })
  servicePointId!: string;

  @observable
  @Expose({ name: "fm_reward" })
  fmReward!: number;

  @observable
  @Expose({ name: "lm_reward" })
  lmReward!: number;

  @observable
  @Expose({ name: "lmcod_reward" })
  lmcodReward!: number;

  /**
   * Capacity management
   */
  @observable
  @Expose({ name: "enforce_sizes" })
  enforceSizes!: boolean;

  /**
   * Accepted size
   */
  @observable
  @Expose({ name: "accepted_size" })
  acceptedSize!: string[];

  /**
   * Active on finder and eshops
   */
  @observable
  @Expose({ name: "active_finder" })
  activeFinder!: boolean;

  /**
   * Opening hours - always open flag
   */
  @observable
  @Expose({ name: "always_open" })
  alwaysOpen!: boolean;

  /**
   * Capacity
   */
  @observable
  capacity?: number;

  /**
   * Max daily capacity
   */
  @observable
  @Expose({ name: "max_daily_capacity" })
  maxDailyCapacity?: number;

  /**
   * Is capacity watcher enabled?
   */
  @observable
  @Expose({ name: "capacity_watcher" })
  capacityWatcher!: boolean;

  /**
   * Is capacity watcher active right now?
   */
  @observable
  @Expose({ name: "capacity_watcher_active" })
  capacityWatcherActive!: boolean;

  /**
   * Is vacation watcher enabled?
   */
  @observable
  @Expose({ name: "vacation_watcher" })
  vacationWatcher!: boolean;

  /**
   * Is vacation watcher enabled?
   */
  @observable
  @Expose({ name: "vacation_watcher_active" })
  vacationWatcherActive!: boolean;

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  @observable
  @Type(() => SimpleSubjectDto)
  subject!: SimpleSubjectDto;

  @observable
  inherit!: number;

  @observable
  @Expose({ name: "child_count" })
  childCount!: number;

  @observable
  @Expose({ name: "export_to_k2" })
  exportToK2!: boolean;

  @observable
  @Expose({ name: "active_recurring_payment_stoppages" })
  activeRecurringPaymentStoppages!: string[];

  static ValidationRules = {
    id: { required: true, number: true },
    subjectId: { required: true, number: true },
    name: { required: true },
    salesmanId: { required: true, number: true },
    depoId: { required: true, number: true },
    typeId: { required: true, number: true },
    status: { required: true, number: true },
    latitude: { required: true, number: true },
    longitude: { required: true, number: true },
    custId: { required: true, number: true },
    dhlPsId: { required: true, number: true },
    dhlPsIdZip: { required: true },
    imei: { required: true },
    cardPayment: { required: true },
    networkPartner: { required: true, number: true },
    parentId: { required: true, number: true },
    dhlExpress: { required: true },
    facility: { required: true },
    routeCode: { required: true },
    serviceArea: { required: true },
    servicePointId: { required: true },
    fmReward: { required: true, number: true },
    lmReward: { required: true, number: true },
    lmcodReward: { required: true, number: true },
    enforceSizes: { required: true },
    acceptedSize: { required: true },
    activeFinder: { required: true },
    alwaysOpen: { required: true },
    capacity: { number: true },
    maxDailyCapacity: { number: true },
    capacityWatcher: { required: true },
    capacityWatcherActive: { required: true },
    vacationWatcher: { required: true },
    vacationWatcherActive: { required: true },
    createdAt: { required: true },
    updatedAt: { required: true },
    subject: { required: true },
    inherit: { required: true, number: true },
    childCount: { required: true, number: true },
    exportToK2: { required: true },
    activeRecurringPaymentStoppages: { required: true },
  };
}
