import { attachAutomaticDirtyWatcher, isDirty } from "@frui.ts/dirtycheck";
import { bound } from "@frui.ts/helpers";
import { watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors } from "@frui.ts/validation";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import Address from "entities/address";
import AddressDto from "entities/addressDto";
import { validateAll } from "helpers";
import { interfaces } from "inversify";
import { action, computed, observable, runInAction } from "mobx";
import ShopDetailContext from "models/shops/shopDetailContext";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import { scrollTop } from "utils/helpers";
import { AddressModel, EditShopNotificationScope, ISubDetailViewModel } from "../types";
import AddressesViewModelBase from "./addressesViewModelBase";
import PotentialPsDetailDto from "entities/potentialPsDetailDto";
import ParcelShop from "manualEntities/parcelShop";
import IAccessPointRepository from "data/repositories/IAccessPointRepository";
import PotentialRepository from "data/repositories/potentialRepository";
import PotentialPs from "../../../../entities/potentialPs";

export default class EditAddressesViewModel extends AddressesViewModelBase implements ISubDetailViewModel {
  static defaultNavigationName = "addresses";

  private shopId: number;
  @observable.shallow itemsToDelete: Address[] = [];

  @computed
  get mandatoryAddressTypes() {
    if (this.isPotential) {
      return this.enums.values("address_types").filter(x => x.required_potential_ps);
    } else {
      return this.enums.values("address_types").filter(x => x.required);
    }
  }

  constructor(
    context: ShopDetailContext<PotentialPsDetailDto | ParcelShop>,
    private isPotential: boolean,
    private repository: IAccessPointRepository<PotentialPs | ParcelShop, PotentialPsDetailDto | ParcelShop>,
    localizationService: LocalizationService,
    private notifications: NotificationService,
    enums: EnumService
  ) {
    super(context.shop.name, localizationService, enums);

    this.shopId = context.shop.id;
    this.name = localizationService.translateGeneral("parcel_shop.address");
    this.navigationName = EditAddressesViewModel.defaultNavigationName;
  }

  onInitialize() {
    return this.loadData();
  }

  discardChanges() {
    return this.onInitialize();
  }

  @watchBusy
  async loadData() {
    const source = await this.repository.getAddresses(this.shopId);
    const models = source.map(x => {
      const item = observable(x);
      attachAutomaticDirtyWatcher(item);
      attachAutomaticValidator(item, AddressDto.ValidationRules);
      return item as AddressModel;
    });

    // Iterate over models and set mandatory to required address types
    // when there is none of them, create it.
    for (const addressType of this.mandatoryAddressTypes) {
      let address = models.find(x => x.addressTypeId === addressType.id);

      if (!address) {
        address = new Address();
        address.addressTypeId = addressType.id;
        attachAutomaticValidator(address, AddressDto.ValidationRules);
        models.push(address);
      }

      address.isMandatory = true;
    }

    runInAction(() => {
      this.itemsToDelete.length = 0;
      this.items = models;
    });
  }

  @action removeAddress(address: AddressModel) {
    if (address.isMandatory) {
      return;
    }

    const index = this.items.indexOf(address);
    if (index >= 0) {
      this.items.splice(index, 1);

      if (address.id) {
        this.itemsToDelete.push(address);
      }
    }
  }

  get isDirty() {
    return (this.items && (!!this.itemsToAdd.length || this.items.some(x => isDirty(x)))) || !!this.itemsToDelete.length;
  }

  get canSave() {
    return this.isDirty && !this.items.some(hasVisibleErrors) && !this.customErrorMessage;
  }

  get itemsToAdd() {
    return this.items.filter(x => !x.id);
  }

  get itemsToUpdate() {
    return this.items.filter(x => x.id && isDirty(x));
  }

  @bound
  @watchBusy
  async save() {
    if (!validateAll(this.items) || this.customErrorMessage) {
      return;
    }

    const added = this.itemsToAdd.map(x => this.repository.addAddress(this.shopId, x));
    const deleted = this.itemsToDelete.map(x => this.repository.deleteAddress(this.shopId, x.id));
    const updated = this.itemsToUpdate.map(x => this.repository.updateAddress(this.shopId, x.id, x));

    try {
      await Promise.all([...added, ...deleted, ...updated]);

      this.notifications.addNotification(
        this.localizationService.translateGeneral("parcel_shop.edit.addresses.saved_message"),
        SeverityLevel.success,
        EditShopNotificationScope
      );

      await this.loadData();
    } catch {
      // TODO: Add catch statement
    }

    scrollTop();
  }

  static Factory({ container }: interfaces.Context) {
    return (context: ShopDetailContext<PotentialPsDetailDto | ParcelShop>, isPotential: boolean) =>
      new EditAddressesViewModel(
        context,
        isPotential,
        isPotential ? container.get(PotentialRepository) : container.get(ParcelShopRepository),
        container.get(LocalizationService),
        container.get(NotificationService),
        container.get(EnumService)
      );
  }
}
