import { ConductorBase, ScreenBase, ConductorBaseWithActiveItem } from "@frui.ts/screens";
import { observer } from "mobx-react-lite";
import React, { ReactElement } from "react";
import { Breadcrumb as BreadcrumbControl } from "react-bootstrap";

function getBreadcrumb(viewModel: ScreenBase | undefined): ReactElement[] {
  if (!viewModel) {
    return [];
  }

  const viewModelConductor = viewModel as { breadcrumbStop?: boolean } & ConductorBaseWithActiveItem<ScreenBase>;
  const child = viewModelConductor.breadcrumbStop ? undefined : viewModelConductor.activeChild;
  const childBreadcrumb = getBreadcrumb(child);

  const currentTitle = viewModel.name;
  if (currentTitle) {
    const onClick = child ? () => (viewModel as ConductorBase<any>).closeChild(child) : undefined;
    const current = (
      <BreadcrumbControl.Item key={childBreadcrumb.length} active={!child} onClick={onClick}>
        {viewModel.name}
      </BreadcrumbControl.Item>
    );
    childBreadcrumb.unshift(current);
  }

  return childBreadcrumb;
}

const Breadcrumb: React.FunctionComponent<{ rootVM: ScreenBase; className?: string }> = observer(({ rootVM, className }) => {
  const data = getBreadcrumb(rootVM);

  if (data.length < 2) {
    return null;
  }

  return (
    <nav className="breadcrumb-container">
      <BreadcrumbControl className={className}>{data}</BreadcrumbControl>
    </nav>
  );
});

export default Breadcrumb;
