import { observable } from "mobx";
import uniqueId from "lodash/uniqueId";
import EventBus, { Events } from "./eventBus";

export enum SeverityLevel {
  critical = "critical",
  important = "important",
  success = "success",
  information = "information",
}

export interface Notification {
  message: string;
  severity: SeverityLevel;
  birth: Date;
}

export default class NotificationService {
  @observable notifications: Map<string, Notification> = new Map<string, Notification>();

  constructor(private eventBus: EventBus) {
    this.subscribeEventBus();
  }

  subscribeEventBus() {
    this.eventBus.subscribe(Events.General.ServerError, message => {
      this.addNotification(message, SeverityLevel.critical, "server_error");
    });
  }

  addNotification(message: string, severity: SeverityLevel = SeverityLevel.information, scope?: string) {
    this.notifications.set(scope || uniqueId(), {
      message,
      severity,
      birth: new Date(),
    });
  }

  removeNotification(key: string) {
    this.notifications.delete(key);
  }

  clearNotifications() {
    this.notifications = new Map<string, Notification>();
  }
}
