import { Input, Select } from "@frui.ts/bootstrap";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { AdditionalInformationSectionProps } from "./types";
import unfocused from "../../../../utils/unfocused";
import { StringInput } from "controls/stringInput";

const Competitions: FunctionComponent<AdditionalInformationSectionProps> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral: tg } = vm.localizationService;

  return (
    <>
      <h3>{translateAttribute("additional_information", "competition")}</h3>
      {vm.item.competitions.map((item, index) => {
        return (
          <Card key={`competition-${index}`} className="my-4" body>
            <Row>
              <Form.Group as={Col} md={3} className="required">
                <Form.Label>{translateAttribute("competition", "name")}</Form.Label>
                <Select
                  target={item}
                  property="enumCompetitionId"
                  items={vm.competitionIds ?? []}
                  mode="key"
                  textProperty="label"
                  keyProperty="value"
                  isNumeric={true}
                />
              </Form.Group>
              <Form.Group as={Col} md={3}>
                <Form.Label>{translateAttribute("competition", "capacity")}</Form.Label>
                <Input target={item} property="capacity" type="number" />
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>{translateAttribute("competition", "comment")}</Form.Label>
                <StringInput trim={true} target={item} property="comment" />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md={3}>
                <Form.Label>{translateAttribute("competition", "cod_rate")}</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Input target={item} property="codRate" type="number" />
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label>{translateAttribute("competition", "without_cod_rate")}</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Input target={item} property="withoutCodRate" type="number" />
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label>{translateAttribute("competition", "returned_rate")}</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Input target={item} property="returnedRate" type="number" />
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label>{translateAttribute("competition", "monthly_rent")}</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Input target={item} property="monthlyRent" type="number" />
                </InputGroup>
              </Form.Group>
            </Row>
            <Button onClick={() => vm.removeCompetition(item)} variant="outline-danger">
              &#215; {tg("new_parcel_shop.remove_competition")}
            </Button>
          </Card>
        );
      })}
      <Form.Row>
        <Form.Group as={Col}>
          <Button onClick={vm.addCompetition} onMouseUp={unfocused} variant="light" size="lg" block>
            &#43; {tg("new_parcel_shop.add_competition")}
          </Button>
        </Form.Group>
      </Form.Row>
    </>
  );
});

export default Competitions;
