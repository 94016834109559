import { ValidationControlBase } from "@frui.ts/bootstrap";
import { CommonInputProps } from "@frui.ts/bootstrap/dist/controls/commonInputProps";
import { bound } from "@frui.ts/helpers";
import { IBindingProps } from "@frui.ts/views";
import React from "react";
import { Form, FormControlProps } from "react-bootstrap";
import MaskedInput from "react-text-mask";

export class IcoInput<TTarget> extends ValidationControlBase<
  TTarget,
  FormControlProps & CommonInputProps & IBindingProps<TTarget>
> {
  @bound protected renderInner() {
    const validationError = this.getValidationError();

    return (
      <>
        <MaskedInput
          mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
          className={`form-control ${validationError ? "is-invalid" : ""}`}
          guide={false}
          value={this.value}
          onChange={this.handleValueChanged}
        />
        {validationError && <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>}
      </>
    );
  }

  @bound protected handleValueChanged(e: React.FormEvent<any>) {
    const value = (e.target as HTMLInputElement).value;
    this.setValue(value);
  }
}
