import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import extractErrorMessage from "data/extractErrorMessage";
import LoginRepository from "data/repositories/loginRepository";
import SessionDto from "entities/sessionDto";
import User from "entities/user";
import { action, computed, observable } from "mobx";
import LocalizationService from "services/localizationService";
import UnreadMessageService from "services/unreadMessageService";
import UserContext from "services/userContext";

export default class LoginViewModel extends ScreenBase {
  busyWatcher = new BusyWatcher();

  credentials = attachAutomaticValidator(new SessionDto(), SessionDto.ValidationRules, false);

  @observable errorMessage?: string = undefined;
  @observable remember = true;

  constructor(
    private context: UserContext,
    private loginRepository: LoginRepository,
    public localizationService: LocalizationService,
    private unreadMessageService: UnreadMessageService
  ) {
    super();
  }

  onInitialize() {
    return this.checkSession();
  }

  @computed get canDoLogin() {
    return !this.busyWatcher.isBusy && !hasVisibleErrors(this.credentials);
  }

  @bound
  @watchBusy
  async doLogin() {
    if (!this.canDoLogin) {
      return;
    }

    if (validate(this.credentials)) {
      this.setErrorMessage(undefined);

      try {
        const loginResult = await this.loginRepository.login(this.credentials);
        this.clearPassword();
        this.processLoginResult(loginResult);
      } catch (error) {
        this.setErrorMessage(extractErrorMessage(error));
      }
    }
  }

  private processLoginResult(result: User) {
    this.context.setCurrentUser(result);
    this.unreadMessageService.enableChecking();
  }

  private async checkSession() {
    const loginResult = await this.loginRepository.currentUser();

    if (loginResult) {
      this.processLoginResult(loginResult);
    }
  }

  @action
  private clearPassword() {
    this.credentials.__validation.isErrorsVisible = false;
    this.credentials.password = "";
  }

  @action
  setErrorMessage(message: string | undefined) {
    this.errorMessage = message;
  }
}
