import React from "react";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import MessageGroupsList from "./list";
import MessageGroupsViewModel from "viewModels/messageGroups/messageGroupsViewModel";

const messageGroupsView: ViewComponent<MessageGroupsViewModel> = observer(({ vm }) => {
  return vm.activeChild ? <View vm={vm.activeChild} /> : <MessageGroupsList vm={vm} />;
});

export default registerView(messageGroupsView, MessageGroupsViewModel);
