import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class StockTakingRecordDto {
  @observable
  ok!: boolean;

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  static ValidationRules = {
    ok: { required: true },
    createdAt: { required: true },
  };
}
