import { IPagingFilter } from "@frui.ts/data";
import { ConductorAllChildrenActive, ScreenBase } from "@frui.ts/screens";
import UsersRepository from "data/repositories/usersRepository";
import { User } from "manualEntities/user";
import { observable, runInAction } from "mobx";
import EnumService from "services/enum";
import StatisticsViewModel from "./statisticsViewModel";
import ActivitiesViewModel from "./activitiesViewModel";
import MemosViewModel from "./memosViewModel";

const PAGING_FILTER: IPagingFilter = {
  limit: 1000,
  offset: 0,
};

export default class DashboardViewModel extends ConductorAllChildrenActive<ScreenBase> {
  @observable users: User[] = [];

  constructor(
    private statisticsFactory: ReturnType<typeof StatisticsViewModel.Factory>,
    private memosFactory: ReturnType<typeof MemosViewModel.Factory>,
    private activitiesFactory: ReturnType<typeof ActivitiesViewModel.Factory>,
    private enums: EnumService,
    private usersRepository: UsersRepository
  ) {
    super();
    this.name = "Domů";
    this.navigationName = "dashboard";
  }

  async onInitialize() {
    await this.loadUsers();
    this.children.push(this.statisticsFactory());
    this.children.push(this.memosFactory(this.users));
    this.children.push(this.activitiesFactory(this.users));
    return super.onInitialize();
  }

  async loadUsers() {
    const roles = this.enums.values("crm_users").map(item => item.id);
    const response = await this.usersRepository.getUsers(PAGING_FILTER, { roles });
    runInAction(() => {
      this.users = response[0] ?? [];
    });
  }

  canDeactivate(isClosing: boolean) {
    return Promise.resolve(true);
  }
}
