import { IPagingFilter, PagedQueryResult } from "@frui.ts/data";
import IListResponse from "data/listResponse";
import { User, UserRole, UsersFilter } from "manualEntities/user";
import { Events } from "services/eventBus";
import { RepositoryBase } from "../repositoryBase";

export default class UsersRepository extends RepositoryBase {
  getCurrentUser() {
    return this.callApi(api => api.one("users", "current").get<User>());
  }

  // Users

  getUsers(paging: IPagingFilter, filter: UsersFilter): Promise<PagedQueryResult<User>> {
    const params = RepositoryBase.createListRequestParams(filter, paging);
    return this.callApi(api => api.all("users").get<IListResponse<User>>(params).then(RepositoryBase.convertListResponse));
  }

  getUser(id: number): Promise<User> {
    return this.callApi(api => api.one("users", id).get());
  }

  createUser(user: User) {
    const payload = RepositoryBase.OmitValidationAndIdProperty(user);
    return this.callApi(api => api.all("users").postOnly(this.omitPropertiesByRole(payload)), Events.Users.Changed);
  }

  updateUser(user: User) {
    const payload = RepositoryBase.OmitValidationAndIdProperty(user);
    return this.callApi(api => api.one("users", user.id).putOnly(this.omitPropertiesByRole(payload)), Events.Users.Changed);
  }

  deleteUser(user: User) {
    return this.callApi(api => api.one("users", user.id).delete(), Events.Users.Changed);
  }

  omitPropertiesByRole(user: Partial<User>) {
    if (user.role === UserRole.PortalAdmin) {
      const { depo_ids, ps_category_ids, ...rest } = user;
      return rest;
    } else if (user.role === UserRole.PortalUserPB) {
      const { ps_category_ids, ...rest } = user;
      return rest;
    }
    return user;
  }
}
