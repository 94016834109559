import { DetailViewModel } from "@frui.ts/datascreens";
import { attachAutomaticDirtyWatcher, isDirty } from "@frui.ts/dirtycheck";
import { watchBusy } from "@frui.ts/screens/dist";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import { ISelectItem } from "@frui.ts/views";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import { interfaces } from "inversify";
import ParcelShop from "manualEntities/parcelShop";
import { action, computed, observable, runInAction } from "mobx";
import ShopInformation from "models/shops/newShop/shopInformation";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import SecurityService from "services/securityService";
import { depoSelectOption, salesmanSelectOption, scrollTop } from "utils/helpers";
import { EditShopNotificationScope, IShopInformationViewModel, ISubDetailViewModel } from "../types";
import IAccessPointRepository from "../../../../data/repositories/IAccessPointRepository";
import PotentialRepository from "../../../../data/repositories/potentialRepository";
import AccessPointPartners from "../../../../models/enumerations/accessPointPartners";
import PotentialPsDetailDto from "../../../../entities/potentialPsDetailDto";

// this ensures that ShopInformation model used for validation matches ParcelShop entity
type Model = (ParcelShop | PotentialPsDetailDto) & ShopInformation;

export default class EditShopInformationViewModel
  extends DetailViewModel<Model>
  implements ISubDetailViewModel, IShopInformationViewModel {
  static defaultNavigationName = "information";
  public readonly isEditing = true;

  @observable.shallow depos: ISelectItem[];

  @computed
  get potentialTypeIds() {
    return this.enums
      .values("access_point_partners")
      .filter(item =>
        ([AccessPointPartners.PARCEL_BOX_POTENTIAL, AccessPointPartners.PARCEL_SHOP_POTENTIAL] as string[]).includes(item.code)
      )
      .map(item => item.id);
  }

  constructor(
    private shopId: number,
    public isParcelBox: boolean,
    public isAlzaBox: boolean,
    public isPotential: boolean,
    private repository: IAccessPointRepository<any, Model>,
    public localizationService: LocalizationService,
    private enums: EnumService,
    private security: SecurityService,
    private notificationService: NotificationService
  ) {
    super();
    this.name = localizationService.translateGeneral("parcel_shop.information");
    this.navigationName = EditShopInformationViewModel.defaultNavigationName;
  }

  @action
  onInitialize() {
    runInAction(() => {
      this.depos = this.security.getAllowedDepos().map(depoSelectOption);
    });

    return super.onInitialize();
  }

  discardChanges() {
    return this.onInitialize();
  }

  protected async loadDetail() {
    const item = await this.repository.getDetail(this.shopId);

    if (!(item instanceof PotentialPsDetailDto)) {
      item.payment_terminals = item.payment_terminals ?? [];
      item.active_recurring_payment_stoppages = observable(item.active_recurring_payment_stoppages || []);
    }

    // Reset salesmanId when is not presented in current enum
    if (this.salesmans.find(salesman => salesman.value === item.salesmanId) === undefined) {
      item.salesmanId = undefined;
    }

    attachAutomaticDirtyWatcher(item);
    attachAutomaticValidator(item, ShopInformation.ValidationRulesByType(this.isParcelBox, this.potentialTypeIds));
    return item;
  }

  get salesmans() {
    return this.enums
      .values("salesmans")
      .filter(item => (this.isParcelBox ? item.pb_specialist : item.ps_specialist))
      .map(salesmanSelectOption);
  }

  get accessPointPartners() {
    return this.enums.values("access_point_partners");
  }

  get isDirty() {
    return this.item && isDirty(this.item);
  }

  get canSave() {
    return this.isDirty && !hasVisibleErrors(this.item);
  }

  @action.bound
  @watchBusy
  async save() {
    if (!validate(this.item)) {
      return;
    }

    try {
      await this.repository.updateItem(this.shopId, this.item);

      this.notificationService.addNotification(
        this.localizationService.translateGeneral("parcel_shop.edit.information.saved_message"),
        SeverityLevel.success,
        EditShopNotificationScope
      );

      await this.loadDetail().then(this.setItem);
    } catch {
      // TODO: Add catch statement
    }

    scrollTop();
  }

  static Factory({ container }: interfaces.Context) {
    return (shopId: number, isParcelBox: boolean, isAlzaBox: boolean, isPotential: boolean) =>
      new EditShopInformationViewModel(
        shopId,
        isParcelBox,
        isAlzaBox,
        isPotential,
        isPotential ? container.get(PotentialRepository) : container.get(ParcelShopRepository),
        container.get(LocalizationService),
        container.get(EnumService),
        container.get(SecurityService),
        container.get(NotificationService)
      );
  }
}
