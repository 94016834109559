import React from "react";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import ScanDevicesList from "./list";
import ScanDevicesViewModel from "viewModels/scanDevices/scanDevicesViewModel";

const scanDevicesView: ViewComponent<ScanDevicesViewModel> = observer(({ vm }) => {
  return vm.activeChild ? <View vm={vm.activeChild} /> : <ScanDevicesList vm={vm} />;
});

export default registerView(scanDevicesView, ScanDevicesViewModel);
