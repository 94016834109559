import { registerView, View, ViewComponent } from "@frui.ts/views";
import HeaderRow from "controls/headerRow";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import { formatDate } from "utils/helpers";
import ShopDetailViewModel from "viewModels/shops/detail/shopDetailViewModel";
import Opportunities from "./opportunities";
import AdditionalInformation from "./additionalInformation";
import Addresses from "./addresses";
import Configuration from "./configuration";
import Contacts from "./contacts";
import Informations from "./informations";
import OpeningHours from "./openingHours";
import ParcelShopSubheader from "./partials/parcelShopSubheader";
import "./shopDetailView.scss";
import Activities from "./activities";

const shopDetailView: ViewComponent<ShopDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute } = vm.localizationService;

  const ta = (key: string) => translateAttribute("parcel_shop", key);

  if (!vm.parcelShop) {
    return <LoadingOverlay loading />;
  } else if (vm.activeChild) {
    return <View vm={vm.activeChild} fallbackMode="message" />;
  } else {
    // TODO: There is hack with buttons - @gustas - change to navigate to use button...

    return (
      <>
        <HeaderRow title={vm.parcelShop.name} />

        <ParcelShopSubheader shop={vm.parcelShop} localization={vm.localizationService} />

        <ButtonToolbar>
          <Button variant="outline-secondary" onClick={vm.openInvoicing} disabled={!vm.canOpenInvoicing}>
            {tg("parcel_shop.invoicing.title")}
          </Button>
          <Button variant="outline-secondary" onClick={vm.openWarehouse} disabled={!vm.canOpenWarehouse}>
            {tg("parcel_shop.warehouse.title")}
          </Button>
          {vm.canCreateMessage && (
            <a
              href={`#/messages/new?recipientPsId=${vm.parcelShop.id}&recipientPsName=${vm.parcelShop.name} (${vm.parcelShop.cust_id})&openDepoId=${vm.parcelShop.depo_id}`}
              className="btn btn-outline-secondary">
              {tg("menu.messages")}
            </a>
          )}
          {!vm.canCreateMessage && (
            <Button variant="outline-secondary" disabled={true}>
              {tg("menu.messages")}
            </Button>
          )}
          <Button variant="outline-secondary" onClick={vm.openEdit} disabled={!vm.canEdit}>
            {ta("edit")}
          </Button>
          <Button variant="outline-secondary" onClick={vm.openAccessControl} disabled={!vm.canOpenAccessControl}>
            {tg("parcel_shop.edit_access.title")}
          </Button>
          <Button variant="outline-danger" onClick={() => vm.disableShop("ParcelShop")} disabled={!vm.canDisableShop}>
            {tg("parcel_shop.disable_shop.button")}
          </Button>
        </ButtonToolbar>

        <Informations vm={vm} />
        <Contacts vm={vm} />
        <Addresses vm={vm} />

        <Row>
          <Col>
            <Configuration vm={vm} />
            {vm.vacations.length > 0 && <h3>{tg("parcel_shop.vacation")}</h3>}
            {vm.vacations.map(item => {
              return (
                <div key={`vacation-${item.id}`} className="vacation-show-item">
                  {formatDate(item.dateFrom)} - {formatDate(item.dateTo)}
                </div>
              );
            })}
          </Col>
          <Col>
            <OpeningHours vm={vm} />
          </Col>
        </Row>
        <AdditionalInformation vm={vm} />
        <Activities vm={vm} />
        <Opportunities vm={vm} />
        <View vm={vm.childDialog} />
      </>
    );
  }
});

export default registerView(shopDetailView, ShopDetailViewModel);
