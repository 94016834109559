import React, { FunctionComponent } from "react";
import { observer } from "mobx-react-lite";
import PaymentTerminalsImportViewModel from "viewModels/paymentTerminals/paymentTerminalsImportViewModel";
import PaymentTerminalImportStatusResult from "../../entities/paymentTerminalImportStatusDtoResult";

interface ImportStatusDetailProps {
  vm: PaymentTerminalsImportViewModel;
}

const ImportStatusDetail: FunctionComponent<ImportStatusDetailProps> = observer(({ vm }) => {
  if (!vm.importStatus) {
    return null;
  }

  return (
    <table className="w-100 table table-striped">
      <thead>
        <tr>
          <th className="pl-4">{vm.translateAttribute("tid")}</th>
          <th>{vm.translateAttribute("sn")}</th>
          <th>{vm.translateAttribute("psName")}</th>
          <th className="text-right pr-4">{vm.translateAttribute("result")}</th>
        </tr>
      </thead>
      <tbody>
        {vm.importStatus.statuses.map(status => (
          <tr key={status.tid} className={status.result !== PaymentTerminalImportStatusResult.Imported ? "table-invalid" : ""}>
            <td className="pl-4">{status.tid}</td>
            <td>{status.sn}</td>
            <td>{status.psName}</td>
            <td className="text-right pr-4">{vm.translateImportStatusResult(status.result)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
});

export default ImportStatusDetail;
