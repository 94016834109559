import { registerView, View, ViewComponent } from "@frui.ts/views";
import iconDelete from "assets/icons/icon-delete.svg";
import DataTable from "controls/dataTable";
import HeaderRow from "controls/headerRow";
import PagingInfo from "controls/pagingInfo";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import AccessControlViewModel from "viewModels/shops/detail/accessControlViewModel";
import ParcelShopSubheader from "./partials/parcelShopSubheader";

const accessControlView: ViewComponent<AccessControlViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute } = vm.localization;
  const ta = (name: string) => translateAttribute("parcel_shop_user", name);

  return (
    <>
      <HeaderRow title={vm.name}>
        <ButtonToolbar className="justify-content-end">
          <Button variant="primary" onClick={vm.add}>
            {tg("parcel_shop.edit_access.add")}
          </Button>
        </ButtonToolbar>
      </HeaderRow>

      <ParcelShopSubheader shop={vm.parcelShop} localization={vm.localization} />

      <Row className="align-items-end">
        <Col>
          <PagingInfo paging={vm.currentPaging} modelName="access" localization={vm.localization} />
        </Col>
      </Row>

      <DataTable
        columns={[
          { dataField: "email", text: ta("email"), title: true },
          {
            dataField: "role",
            text: ta("role"),
            formatter: value => vm.getRoleName(value),
            title: value => vm.getRoleName(value),
          },
          {
            dataField: "mypsVersion",
            text: ta("myps_version"),
            sort: true,
            title: true,
          },
          {
            dataField: "id",
            text: ta("password_reset"),
            align: "center",
            headerStyle: {
              width: "120px",
              textAlign: "center",
            },
            isDummyField: true,
            formatter: (cell, row) => (
              <Button size="sm" variant="link" className="noRowClick" onClick={() => vm.resetPassword(row)}>
                {ta("password_reset")}
              </Button>
            ),
          },
          {
            dataField: "id",
            isDummyField: true,
            text: ta("delete"),
            headerStyle: {
              width: "80px",
              textAlign: "center",
            },
            align: "center",
            formatter: (cell, row) => (
              <Button size="sm" variant="outline-danger" className="noRowClick button-icon" onClick={() => vm.delete(row)}>
                <img src={iconDelete} alt={ta("delete")} />
              </Button>
            ),
          },
        ]}
        vm={vm}
      />

      <View vm={vm.activeChild} />
    </>
  );
});

export default registerView(accessControlView, AccessControlViewModel);
