import { EventBus } from "light-event-bus";

export default EventBus;

export interface EventSubscription {
  unsubscribe(): void;
}

const Events = {
  Users: {
    Changed: "Users.Changed",
  },

  ParcelShopUsers: {
    Changed: "ParcelShopUsers.Changed",
  },

  AccessPoints: {
    Created: "ParcelShops.Created",
    Changed: "ParcelShops.Changed",
    Addresses: {
      Changed: "ParcelShops.Addresses.Changed",
    },
    Contacts: {
      Changed: "ParcelShops.Contacts.Changed",
    },
    Context: {
      Updated: "ParcelShops.Context.Updated",
    },
  },

  ParcelShops: {
    OpeningHours: {
      Changed: "ParcelShops.OpeningHours.Changed",
    },
    Bonuses: {
      Changed: "ParcelShops.Bonuses.Changed",
    },
    Parcels: {
      Changed: "ParcelShops.Parcels.Changed",
    },
    Vacations: {
      Changed: "ParcelShops.Vacations.Changed",
    },
    AdditionalInformation: {
      Changed: "ParcelShops.AdditionalInformation.Changed",
    },
  },

  PaymentTerminals: {
    Changed: "PaymentTerminals.Changed",
    Imported: "PaymentTerminals.Imported",
  },

  ParcelBoxes: {
    Changed: "ParcelBoxes.Changed",
  },

  Subjects: {
    Changed: "Subjects.Changed",
  },

  General: {
    ServerError: "General.ServerError",
    Unauthorized: "General.Unauthorized",
  },

  Localization: {
    Fetched: "LocalizationFetched",
  },

  Messages: {
    Changed: "Messages.Changed",
  },
  MessageGroups: {
    Changed: "MessageGroups.Changed",
  },

  Enumerations: {
    Changed: "Enumerations.Changed",
  },

  Memos: {
    Changed: "Memos.Changed",
  },

  Activities: {
    Changed: "Activities.Changed",
  },

  Opportunities: {
    Changed: "Opportunities.Changed",
  },

  Competitions: {
    Changed: "Competitions.Changed",
  },

  Attachments: {
    Changed: "Attachments.Changed",
  },

  ScanDevices: {
    Changed: "ScanDevices.Changed",
    Imported: "ScanDevices.Imported",
  },
};
export { Events };
