/* eslint-disable @typescript-eslint/no-this-alias */
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { toJS } from "mobx";
import React from "react";
import Vue, { CreateElement, VNode } from "vue";
import "./treeList.scss";

interface TreeListProps {
  options: TreeItem[];
  value: Omit<TreeItem, "children">[] | TreeItem | undefined;
  multiple?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  error?: boolean;
  loadOptions?: ({
    action,
    parentNode,
    callback,
    instanceId,
  }: {
    action: any;
    parentNode: any;
    callback: any;
    instanceId: any;
  }) => void;
  onChange?: (values: (TreeItemNode | string)[]) => void;
}

export default class TreeList extends React.Component<TreeListProps> {
  private divRef = React.createRef<HTMLDivElement>();
  private vueInstance: any;

  componentDidMount() {
    if (this.divRef.current) {
      this.createVueInstance();
    }
  }

  componentWillUnmount() {
    this.vueInstance?.$destroy();
  }

  UNSAFE_componentWillReceiveProps(nextProps: TreeListProps) {
    const vueProps = { ...nextProps, value: toJS(nextProps.value) };
    this.vueInstance.$emit("newProps", vueProps);
  }

  onChange = (value: (TreeItemNode | string)[]) => {
    this.props.onChange?.(value);
  };

  createVueInstance() {
    const that = this;

    this.vueInstance = new Vue({
      el: this.divRef.current!,
      data: { ...this.props, "load-options": this.props.loadOptions, placeholder: "", valueFormat: "object" },
      render(createElement: CreateElement): VNode {
        return createElement("treeselect", {
          props: this.$data,
          on: {
            input: that.onChange,
          },
        });
      },
      components: {
        treeselect: Treeselect,
      },
      mounted() {
        const self = this;
        self.$on("newProps", (props: TreeListProps) => {
          self.options = props.options;
          self.value = props.value;
          self.disabled = props.disabled;
        });

        self.$on("select", (values: any) => {
          console.log(values);
        });
      },
    });
  }

  render() {
    return (
      <div className={this.props.error ? "treeList-error-container" : undefined}>
        <div className="test" ref={this.divRef} />
      </div>
    );
  }
}
