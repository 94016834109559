import { registerView, ViewComponent } from "@frui.ts/views";
import logo from "assets/logo.svg";
import { observer } from "mobx-react-lite";
import React from "react";
import InitializationService from "services/initializationService";
import "./style.scss";

const initializationView: ViewComponent<InitializationService> = observer(({ vm }) => {
  return (
    <div className="wrapper-spinner">
      <img className="mb-4 mx-auto d-block logo" src={logo} alt="PPL" />
      <div className="justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
});
export default registerView(initializationView, InitializationService);
