import { AutomaticDirtyWatcher } from "@frui.ts/dirtycheck";
import { bound } from "@frui.ts/helpers";
import { Router, watchBusy } from "@frui.ts/screens";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import ParcelShop, { ParcelShopFilter } from "manualEntities/parcelShop";
import { action, observable, runInAction } from "mobx";
import EnumService from "services/enum";
import EventBus, { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import {
  accessPointTreeItem,
  depoSelectOption,
  formatDepoName,
  formatSalesmanName,
  psCategoryTreeItem,
  psFilterStatusTreeItem,
  salesmanSelectOption,
} from "utils/helpers";
import ListViewModelBase from "viewModels/listViewModelBase";
import CommonDetailViewModel from "./detail/commonDetailViewModel";
import isPotential from "../../utils/isPotential";
import SecurityService from "../../services/securityService";
import AccessPointPartners from "../../models/enumerations/accessPointPartners";

const navigationName = "shops";

@Router.registerRoute({ name: Router.Self, route: navigationName, children: [CommonDetailViewModel] })
export default class ShopsViewModel extends ListViewModelBase<ParcelShop, ParcelShopFilter, CommonDetailViewModel> {
  @observable selectedTypes: TreeItem[] = [];
  @observable selectedStatuses: TreeItem[] = [];
  @observable selectedCategories: TreeItem[] = [];

  constructor(
    private repository: ParcelShopRepository,
    public enums: EnumService,
    private eventBus: EventBus,
    public localizationService: LocalizationService,
    private securityService: SecurityService,
    private shopDetailVMFactory: ReturnType<typeof CommonDetailViewModel.Factory>
  ) {
    super();

    this.navigationName = navigationName;
    this.name = localizationService.translateGeneral("menu.shops");

    this.filterChangedWatcher = new AutomaticDirtyWatcher(this.filter, false, { exclude: ["type_ids", "status"] });
  }

  onInitialize() {
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.AccessPoints.Changed, this.loadData));
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.AccessPoints.Created, this.loadData));
  }

  typeName(id: number) {
    return this.enums.value("access_point_partners", id)?.name ?? " - ";
  }

  @bound
  @watchBusy
  loadData() {
    return this.repository.getList(this.pagingFilter, this.appliedFilter).then(this.setData);
  }

  get isFilterChanged() {
    return (
      this.filterChangedWatcher.isDirty ||
      (!!this.filter?.type_ids && this.filter?.type_ids.length > 0) ||
      (!!this.filter?.status && this.filter?.status.length > 0) ||
      (!!this.filter?.ps_category_ids && this.filter?.ps_category_ids.length > 0)
    );
  }

  protected resetFilterValues(filter: ParcelShopFilter) {
    filter.cust_id = "";
    filter.dhl_ps_id_zip = "";
    filter.ic = "";
    filter.name = "";
    filter.salesman_id = undefined;
    filter.depo_id = undefined;

    filter.type_ids = undefined;
    filter.ps_category_ids = undefined;
    filter.status = undefined;
    runInAction(() => {
      this.selectedTypes = [];
      this.selectedStatuses = [];
      this.selectedCategories = [];
    });

    filter.contact_phone = "";
    filter.contact_email = "";
    filter.contact_name = "";
  }

  openDetail(item: ParcelShop) {
    const typeCode = this.enums.value("access_point_partners", item.type_id)?.code;
    const detail = this.shopDetailVMFactory(item.id, isPotential(typeCode ?? ""));
    return this.tryActivateChild(detail);
  }

  get accessesPoints(): TreeItem[] {
    const parcelBoxTypes: string[] = [
      AccessPointPartners.PARCEL_BOX,
      AccessPointPartners.PARCEL_BOX_POTENTIAL,
      AccessPointPartners.ALZA_BOX,
    ];

    return this.enums
      .values("access_point_partners")
      .filter(item => {
        if (parcelBoxTypes.includes(item.code)) {
          return this.securityService.isAllowedToManage("parcelBox");
        } else {
          return this.securityService.isAllowedToManage("parcelShop");
        }
      })
      .map(accessPointTreeItem);
  }

  get psFilterStatuses(): TreeItem[] {
    return this.enums.values("ps_filter_status").map(psFilterStatusTreeItem);
  }

  get psCategories(): TreeItem[] {
    return this.enums.values("ps_categories").map(psCategoryTreeItem);
  }

  get depos() {
    return this.enums.values("depos").map(depoSelectOption);
  }

  getDepoName(id: number) {
    const depo = this.enums.value("depos", id);
    return formatDepoName(depo);
  }

  get salesmans() {
    return this.enums
      .values("salesmans")
      .filter(salesman => {
        if (this.securityService.isAllowedToManage("parcelBox") && salesman.pb_specialist) {
          return true;
        } else if (this.securityService.isAllowedToManage("parcelShop") && salesman.ps_specialist) {
          return true;
        } else {
          return false;
        }
      })
      .map(salesmanSelectOption);
  }

  getSalesmanName(id: number) {
    const item = this.enums.value("salesmans", id);
    return formatSalesmanName(item);
  }

  @bound getStatusName(status: number) {
    return this.enums.value("ps_status", status)?.name;
  }

  @bound getCategoryName(status: number) {
    return this.enums.value("ps_categories", status)?.code;
  }

  @action.bound
  onSelectType = (values: TreeItem[]) => {
    runInAction(() => {
      this.filter.type_ids = values.map(item => Number(item.id));
    });
    this.selectedTypes = values;
  };

  @action.bound
  onSelectStatus = (values: TreeItem[]) => {
    runInAction(() => {
      this.filter.status = values.map(item => Number(item.id));
    });
    this.selectedStatuses = values;
  };

  @action.bound
  onSelectCategory = (values: TreeItem[]) => {
    runInAction(() => {
      this.filter.ps_category_ids = values.map(item => Number(item.id));
    });
    this.selectedCategories = values;
  };

  protected async findNavigationChild(navigationName: string) {
    if (!navigationName) {
      return undefined;
    }

    const id = parseInt(navigationName, 10);
    if (this.activeChild?.item?.id === id) {
      return this.activeChild;
    } else if (id > 0) {
      const isPotential = navigationName.includes("-potential");
      return this.shopDetailVMFactory(id, isPotential);
    } else {
      return undefined;
    }
  }
}
