import { BusyWatcher, ScreenBase } from "@frui.ts/screens";
import LocalizationService from "../../services/localizationService";
import { action, observable } from "mobx";
import { watchBusy } from "@frui.ts/screens/dist";
import { attachAutomaticDirtyWatcher, isDirty, resetDirty } from "@frui.ts/dirtycheck";
import EventBus from "services/eventBus";
import { attachAutomaticValidator, hasVisibleErrors, validateAll } from "@frui.ts/validation";
import ConfirmationService from "services/confirmationService";
import SettingsService, { SettingsProperties } from "services/settingsService";
import { cloneDeep } from "lodash";
import NotificationService from "services/notificationService";
import { bound } from "@frui.ts/helpers";
import format from "date-fns/format";
import isValid from "date-fns/isValid";

class XmlProperties {
  @observable day: number;
  @observable time: string;
}

export default class SettingsViewModel extends ScreenBase {
  @observable settings: SettingsProperties;
  @observable bonusXml: XmlProperties;
  @observable orderXml: XmlProperties;

  busyWatcher = new BusyWatcher();

  constructor(
    public localization: LocalizationService,
    private eventBus: EventBus,
    private confirmationService: ConfirmationService,
    private settingsService: SettingsService,
    private notifications: NotificationService
  ) {
    super();
    this.name = localization.translateGeneral("menu.settings");
    this.navigationName = "settings";

    this.settings = new SettingsProperties();
    this.initXmlProperties();

    attachAutomaticValidator(this.bonusXml, {
      day: { required: true, number: true, range: { min: 1, max: 31 } },
      time: { required: true, isTime: true },
    });
    attachAutomaticValidator(this.orderXml, {
      day: { required: true, number: true, range: { min: 1, max: 31 } },
      time: { required: true, isTime: true },
    });
  }

  protected onInitialize(): Promise<any> | void {
    return this.loadData();
  }

  @action.bound
  initXmlProperties() {
    this.bonusXml = new XmlProperties();
    this.orderXml = new XmlProperties();
  }

  @action.bound
  @watchBusy
  loadData() {
    if (this.settingsService.settings) {
      this.settings = cloneDeep(this.settingsService.settings);

      attachAutomaticDirtyWatcher(this.settings);
      attachAutomaticValidator(this.settings, {
        bonusLimitForAcquisition: { required: true, number: true, isGreaterThan: 0 },
        bonusLimitForFlow: { required: true, number: true, isGreaterThan: 0 },
        bonusLimitForLoyalty: { required: true, number: true, isGreaterThan: 0 },
        bonusLimitForRetention: { required: true, number: true, isGreaterThan: 0 },
        bonusLimitForSeasonal: { required: true, number: true, isGreaterThan: 0 },
        bonusLimitForSupply: { required: true, number: true, isGreaterThan: 0 },
      });

      const bonusXml = new Date(this.settingsService.settings.bonusXmlGeneration);

      if (isValid(bonusXml)) {
        this.bonusXml.day = bonusXml?.getDate();
        this.bonusXml.time = format(bonusXml, "HH:mm");
      }

      attachAutomaticDirtyWatcher(this.bonusXml);

      const orderXml = new Date(this.settingsService.settings.orderXmlGeneration);

      if (isValid(orderXml)) {
        this.orderXml.day = orderXml?.getDate();
        this.orderXml.time = format(orderXml, "HH:mm");
      }

      attachAutomaticDirtyWatcher(this.orderXml);
    }
  }

  @action.bound
  @watchBusy
  async save() {
    if (!validateAll([this.settings, this.bonusXml, this.orderXml])) {
      return;
    }

    this.settings.bonusXmlGeneration = this.getISOStringFromXmlProperties(this.bonusXml);
    this.settings.orderXmlGeneration = this.getISOStringFromXmlProperties(this.orderXml);

    const confirm = await this.confirmationService.showConfirmation(
      this.localization.translateGeneral("settings.confirm.text"),
      this.localization.translateGeneral("settings.confirm.title")
    );

    if (confirm) {
      await this.settingsService.updateSettings(this.settings);
      this.loadData();
      this.resetDirtiesFlags();
    }
  }

  @bound
  getISOStringFromXmlProperties(xml: XmlProperties) {
    const hour = Number(xml.time.substr(0, 2));
    const minute = Number(xml.time.substr(3, 2));

    const date = new Date();

    date.setFullYear(2021);
    date.setMonth(0);
    date.setDate(xml.day);
    date.setHours(hour);
    date.setMinutes(minute);

    return date.toISOString();
  }

  canDeactivate(isClosing: boolean): Promise<boolean> | boolean {
    if (this.isDirty) {
      return this.confirmationService
        .showConfirmation(
          this.localization.translateGeneral("settings.stay_confirm.text"),
          this.localization.translateGeneral("settings.stay_confirm.title"),
          this.localization.translateGeneral("settings.stay_confirm.stay"),
          this.localization.translateGeneral("settings.stay_confirm.leave")
        )
        .then(confirmStay => {
          if (!confirmStay) {
            this.initXmlProperties();
            this.loadData();
          }
          return !confirmStay;
        });
    } else {
      return super.canDeactivate(isClosing);
    }
  }

  protected onDeactivate(isClosing: boolean): Promise<any> | void {
    this.notifications.clearNotifications();
    return super.onDeactivate(isClosing);
  }

  resetDirtiesFlags() {
    resetDirty(this.settings);
    resetDirty(this.bonusXml);
    resetDirty(this.orderXml);
  }

  get isDirty() {
    return (
      this.settings &&
      this.bonusXml &&
      this.orderXml &&
      (isDirty(this.settings) || isDirty(this.bonusXml) || isDirty(this.orderXml))
    );
  }

  get canSave() {
    return this.isDirty && !hasVisibleErrors(this.settings);
  }
}
