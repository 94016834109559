import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import DashboardViewModel from "viewModels/dashboard/dashboardViewModel";

const dashboardView: ViewComponent<DashboardViewModel> = observer(({ vm }) => {
  return (
    <>
      {vm.children.map((childrenVM, index) => (
        <div key={`section-${index++}`} className={index !== vm.children.length - 1 ? "mb-5" : ""}>
          <View vm={childrenVM} />
        </div>
      ))}
    </>
  );
});
export default registerView(dashboardView, DashboardViewModel);
