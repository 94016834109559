import React, { FunctionComponent } from "react";
import { ConfigurationSectionProps } from "./types";
import { Col, Form, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { StringInput } from "controls/stringInput";

const AdditionalInfo: FunctionComponent<ConfigurationSectionProps> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral: tg } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("parcel_shop", attribute);
  };

  // workaround until Input control supports 'as' props
  const CustomInput = StringInput as any;

  return (
    <>
      <h3>{tg("parcel_shop.additional_info")}</h3>
      <Row>
        <Form.Group as={Col} md={6}>
          <Form.Label>{ta("description")}</Form.Label>
          <CustomInput trim={true} as="textarea" target={vm.item} property="description" rows="7" />
        </Form.Group>
        <Form.Group as={Col} md={6}>
          <Form.Label>{ta("description_crm")}</Form.Label>
          <CustomInput trim={true} as="textarea" target={vm.item} property="descriptionCrm" rows="7" />
        </Form.Group>
      </Row>
    </>
  );
});

export default AdditionalInfo;
