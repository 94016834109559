import { attachAutomaticDirtyWatcher, resetDirty } from "@frui.ts/dirtycheck";
import { watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import MessageGroupsRepository from "data/repositories/messageGroupsRepository";
import MessagesRepository from "data/repositories/messagesRepository";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import ParcelShopUsersRepository from "data/repositories/parcelShopUsersRepository";
import MessageDetailResponseDto from "entities/messageDetailResponseDto";
import MessagePortalDto from "entities/messagePortalDto";
import MessageListResponseDto from "entities/messageListResponseDto";
import { interfaces } from "inversify";
import { action, computed, IReactionDisposer, observable, reaction, runInAction } from "mobx";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import EventBus, { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import UserContext from "services/userContext";
import { scrollTop } from "utils/helpers";
import EditableDetailViewModelBase from "viewModels/editableDetailViewModelBase";
import MessagesViewModel from "./messagesViewModel";
import { bound } from "@frui.ts/helpers";
import RecipientDto from "entities/recipientDto";

export default class MessageDetailViewModel extends EditableDetailViewModelBase<
  MessageListResponseDto,
  MessageDetailResponseDto
> {
  // Observable tree data for recipient filter
  @observable treeData: TreeItem[] = [];

  @observable selectedItems: TreeItem[] = [];
  @observable keyToData: { [key: string]: TreeItem } = {};

  @observable expandThread = false;

  @observable replyItem: MessagePortalDto = new MessagePortalDto();

  constructor(
    originalItem: MessageListResponseDto | undefined,
    public userContext: UserContext,
    private repository: MessagesRepository,
    private psRepository: ParcelShopRepository,
    private psUserRepository: ParcelShopUsersRepository,
    private messageGroupsRepository: MessageGroupsRepository,
    private eventBus: EventBus,
    private notificationService: NotificationService,
    public localizationService: LocalizationService,
    protected enums: EnumService,
    private confirmationService: ConfirmationService
  ) {
    super(originalItem);

    if (originalItem) {
      this.name = this.localizationService.translateGeneral(`message.detail_${originalItem.type}`);
      this.navigationName = originalItem.id.toString();
    } else {
      this.name = this.localizationService.translateGeneral("message.add");
      this.navigationName = "new";
    }

    this.expandThread = false;

    // Fetch and sync tree data
    void this.initializeTreeData();
  }

  private disposers: IReactionDisposer[] = [];

  async onInitialize() {
    await super.onInitialize();
    this.disposers.push(
      reaction(
        () => this.selectedItems,
        () => this.applySelectedRecipients()
      )
    );
  }

  protected onDeactivate(close: boolean) {
    if (close) {
      this.disposers.forEach(x => x());
    }
  }

  protected async loadDetail() {
    let editedItem: MessageDetailResponseDto;

    if (this.isCreating || !this.originalItem) {
      editedItem = new MessageDetailResponseDto();

      // TODO: This is hack because after last update navigate is not called when there are no params
      // eslint-disable-next-line no-restricted-globals
      if (location.href.indexOf("?") < 0) {
        editedItem.recipients = [];
      }
    } else {
      try {
        editedItem = await this.repository.getMessage(this.originalItem.id);

        if (!editedItem.readByPpl) {
          await this.repository.readMessage(this.originalItem.id);
        }

        runInAction(() => {
          this.replyItem.parentId = editedItem.id;
          this.replyItem.subject = `[RE]: ${editedItem.subject}`;
        });

        attachAutomaticValidator(this.replyItem, MessagePortalDto.ValidationRules);
      } catch (e) {
        this.eventBus.publish(
          Events.General.ServerError,
          this.localizationService.translateGeneral("message.errors.fetch_failed")
        );
        void this.requestClose();
        throw e;
      }
    }

    attachAutomaticDirtyWatcher(editedItem);
    attachAutomaticValidator(editedItem, MessagePortalDto.ValidationRules, !this.isCreating);
    return editedItem;
  }

  @bound
  @watchBusy
  async toggleMessageResolved() {
    const tg = this.localizationService.translateGeneral;
    const infix = this.item.resolved ? "confirm_unresolve" : "confirm_resolve";

    const confirm = await this.confirmationService.showConfirmation(
      tg(`message.${infix}.text`),
      tg(`message.${infix}.title`),
      { variant: "primary", text: tg("message.change_status") },
      tg("message.keep_status")
    );

    if (!confirm) {
      return;
    }

    const response = this.item.resolved
      ? await this.repository.unresolveMessage(this.item.id)
      : await this.repository.resolveMessage(this.item.id);

    if (response.status === 204) {
      this.eventBus.publish(Events.Messages.Changed);
      runInAction(() => (this.item.resolved = !this.item.resolved));
    }
  }

  @action
  @watchBusy
  protected async initializeTreeData() {
    // Set depos
    const depoItems: TreeItem[] = [];

    this.enums.values("depos").forEach(i => {
      if (!this.userContext.isAdmin && !this.userContext.assignedDepos?.includes(i.id)) {
        return;
      }

      const item: TreeItem = observable({
        label: i.name,
        id: `depo-${i.id}`,
        children: null,
      });

      this.keyToData[item.id] = item;
      depoItems.push(item);
    });

    // Get named groups
    const [groups] = await this.messageGroupsRepository.getMessageGroups({ limit: 10000, offset: 0 }, {});
    const groupItems = groups.map(group => {
      const item: TreeItem = observable({
        label: group.name,
        id: `group-${group.id}`,
      });

      this.keyToData[item.id] = item;

      return item;
    });

    // Create two groups
    this.treeData = [
      ...groupItems,
      observable({
        isDisabled: true,
        id: "disabled",
        label: "---",
      }),
      ...depoItems,
    ];
  }

  @action
  navigate(subPath: string, params: any) {
    // TODO: This is hack because navigate is called after loadItem.
    this.item.recipients = [];

    if (params.recipientPsId && params.recipientPsName) {
      const item = {
        id: `ps-${parseInt(params.recipientPsId, 10)}`,
        label: params.recipientPsName,
      };

      // TODO check this code - why ANY
      this.selectedItems.push(item);
      this.keyToData[item.id] = item;
    }

    if (params.recipientUserId && params.recipientUserName) {
      const item = {
        id: `user-${parseInt(params.recipientUserId, 10)}`,
        label: params.recipientUserName,
      };

      // TODO check this code - why ANY
      this.selectedItems.push(item);
      this.keyToData[item.id] = item;
    }

    this.applySelectedRecipients();

    // This is "hacky" how to preload and open proper filters to show items in
    // tree select
    if (params.openDepoId) {
      this.loadData({ parentNode: { id: `depo-${params.openDepoId}` } }).then(() => {
        if (params.openPsId) {
          return this.loadData({ parentNode: { id: `ps-${params.openPsId}` } });
        }
      });
    }
  }

  @action.bound
  onChange = (values: TreeItem[]) => {
    this.selectedItems = values;
  };

  @action.bound
  @watchBusy
  async loadData(item: { parentNode: { id: string } }): Promise<any> {
    const parentId = item.parentNode.id;
    const [type, id] = parentId.split("-");

    if (type === "depo") {
      // Load parcel shops
      const [ps] = await this.psRepository.getList({ limit: 1000, offset: 0 }, {
        depo_id: parseInt(id, 10),
        type_ids: [1], // Fetch only ParcelShops
      } as any);

      // find node & append childs
      const node = this.keyToData[parentId];
      if (node) {
        node.children = ps.map(x => {
          const newItem: TreeItem = observable({
            label: `${x.name} (${x.cust_id})`,
            id: `ps-${x.id}`,
            children: null,
          });

          this.keyToData[newItem.id] = newItem;

          return newItem;
        });
      }
    } else if (type === "ps") {
      const [users] = await this.psUserRepository.getUsers(parseInt(id, 10), { limit: 1000, offset: 0 });

      // find node & append childs
      const node = this.keyToData[parentId];
      if (node) {
        node.children = users.map(x => {
          const newItem = observable({
            label: `${x.email}`,
            id: `user-${x.id}`,
            value: `user-${x.id}`,
          });

          this.keyToData[newItem.id] = newItem;

          return newItem;
        });
      }
    }

    return true;
  }

  @computed get canSave() {
    return !hasVisibleErrors(this.item);
  }

  @computed get canReply() {
    if (!this.item.groupMessage) {
      return this.item.author?.id === undefined ? this.activePS : true;
    }

    return false;
  }

  @computed get activePS() {
    if (this.item.parcelShops) {
      return this.item.parcelShops.filter(ps => ps.active).length > 0;
    }

    return false;
  }

  @action.bound
  @watchBusy
  async create() {
    if (!validate(this.item)) {
      return;
    }

    // Confirmation window
    const tg = this.localizationService.translateGeneral;

    const canSend = await this.confirmationService.showConfirmation(
      tg("message.confirm.text"),
      tg("message.confirm.title"),
      { variant: "primary", text: tg("message.submit") },
      tg("unsaved_changes_dialog.back")
    );

    if (!canSend) {
      return;
    }

    try {
      await this.repository.createMessage(this.item);
      resetDirty(this.item);
      await this.requestClose();
      this.notificationService.addNotification(tg("message.created"), SeverityLevel.success, MessagesViewModel.notificationScope);
    } catch {
      // TODO: Add catch statement
    }

    scrollTop();
  }

  @action.bound
  toggleThread() {
    this.expandThread = !this.expandThread;
  }

  @computed get threadMessages(): MessageDetailResponseDto[] {
    if (this.expandThread) {
      return this.item.threadMessages;
    } else if (this.item.threadMessages.length) {
      return [this.item.threadMessages[this.item.threadMessages.length - 1]];
    } else {
      return [];
    }
  }

  @action.bound
  @watchBusy
  async sendReply() {
    const tg = this.localizationService.translateGeneral;

    if (!this.replyItem.recipients) {
      this.setParcelShopsAsRecipients();
    }

    if (validate(this.replyItem)) {
      await this.repository.createMessage(this.replyItem);
      runInAction(async () => {
        this.replyItem.text = "";
        this.item = await this.loadDetail();
      });
      this.notificationService.addNotification(tg("message.created"), SeverityLevel.success, MessagesViewModel.notificationScope);
    }
  }

  @action
  private setParcelShopsAsRecipients() {
    this.replyItem.recipients = [];
    this.item.parcelShops?.forEach(ps => {
      const recipient = new RecipientDto();
      recipient.type = "ps";
      recipient.id = ps.id;
      this.replyItem.recipients?.push(recipient);
    });
  }

  private applySelectedRecipients() {
    this.item.recipients = this.selectedItems.map(x => {
      const [type, id] = x.id.split("-");

      return {
        type,
        id: parseInt(id, 10),
      };
    });
  }

  static Factory({ container }: interfaces.Context) {
    return (item?: any) => {
      return new MessageDetailViewModel(
        item,
        container.get(UserContext),
        container.get(MessagesRepository),
        container.get(ParcelShopRepository),
        container.get(ParcelShopUsersRepository),
        container.get(MessageGroupsRepository),
        container.get(EventBus),
        container.get(NotificationService),
        container.get(LocalizationService),
        container.get(EnumService),
        container.get(ConfirmationService)
      );
    };
  }
}
