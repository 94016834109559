import { EntityValidationRules } from "services/validation";
import PotentialPs from "entities/potentialPs";
import PotentialPsDetailDto from "../../../entities/potentialPsDetailDto";

export default class ShopInformation {
  potentialId?: number;
  alwaysOpen: boolean;

  subject: {
    ic: string;
    dic?: string;
  };

  name: string;
  subname?: string;

  typeId: number;
  salesmanId?: number;
  depoId: number;

  static createFromPotential(potential: PotentialPs | PotentialPsDetailDto) {
    const info = new ShopInformation();
    info.potentialId = potential.id;
    info.alwaysOpen = potential.alwaysOpen;
    info.subject = potential.subject;
    info.name = potential.name;
    info.subname = potential.subname;
    info.salesmanId = potential.salesmanId;
    info.depoId = potential.depoId;

    return info;
  }

  static ValidationRules: EntityValidationRules<ShopInformation> = {
    name: { required: true, minLength: 4, maxLength: 250 },
    subname: { maxLength: 250 },
    salesmanId: { required: true },
    typeId: { required: true },
    depoId: { required: true },
  };

  static ValidationRulesByType = (isParcelBox: boolean, potentialIds: number[]) => {
    return {
      ...ShopInformation.ValidationRules,
      name: {
        ...ShopInformation.ValidationRules.name,
        isParcelBoxName: isParcelBox,
      },
      salesmanId: {
        required: false,
        requiredIf: {
          condition: (entity: ShopInformation) => !potentialIds.includes(entity.typeId),
        },
      },
    };
  };
}
