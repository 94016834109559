import React from "react";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import EnumerationsViewModel from "../../viewModels/enumerations/enumerationsViewModel";
import { observer, Observer } from "mobx-react-lite";
import { Button } from "react-bootstrap";
import iconEdit from "../../assets/icons/icon-edit.svg";
import Table from "../../controls/table";
import EditableEnumCountDto from "../../entities/editableEnumCountDto";

const EnumerationsView: ViewComponent<EnumerationsViewModel> = observer(({ vm }) => {
  return (
    <>
      <h1>{vm.translate("title")}</h1>
      <Table
        data={vm.items ?? []}
        keyField="code"
        columns={[
          {
            dataField: "code",
            text: vm.translate("code"),
            formatter: (cell, row: EditableEnumCountDto) => vm.translate(row.code),
          },
          {
            dataField: "count",
            headerAlign: "right",
            align: "right",
            text: vm.translate("count"),
          },
          {
            dataField: "edit",
            isDummyField: true,
            text: vm.translate("detail"),
            headerStyle: {
              width: "100px",
              textAlign: "center",
            },
            align: "center",
            formatter: (cell, row: any) => {
              return (
                <Button
                  size="sm"
                  variant="outline-secondary"
                  className="noRowClick button-icon"
                  onClick={() => vm.openDetail(row)}>
                  <img src={iconEdit} alt={vm.translate("detail")} />
                </Button>
              );
            },
          },
        ]}
      />
      <Observer>{() => <View vm={vm.activeChild} />}</Observer>
    </>
  );
});

registerView(EnumerationsView, EnumerationsViewModel);
