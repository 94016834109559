import { Input } from "@frui.ts/bootstrap";
import { registerView, ViewComponent } from "@frui.ts/views";
import { CheckArray } from "controls/checkArray";
import HeaderRow from "controls/headerRow";
import LoadingOverlay from "controls/loadingOverlay";
import { RadioArray } from "controls/radioArray";
import { StringInput } from "controls/stringInput";
import { ValidationError } from "controls/validationError";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonToolbar, Col, Form, InputGroup } from "react-bootstrap";
import FormLabel from "react-bootstrap/FormLabel";
import Row from "react-bootstrap/Row";
import UserDetailViewModel from "viewModels/users/userDetailViewModel";
import preventDefault from "../preventDefault";

const userDetailView: ViewComponent<UserDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute: ta } = vm.localizationService;
  if (!vm.item) {
    return (
      <>
        <HeaderRow title={vm.isCreating ? tg("user.create.title") : tg("user.edit.title")} />
        <LoadingOverlay loading={vm.busyWatcher.isBusy} />
      </>
    );
  }

  return (
    <>
      <HeaderRow title={vm.isCreating ? tg("user.create.title") : tg("user.edit.title")} />
      <Form onSubmit={preventDefault(vm.isCreating ? vm.create : vm.update)}>
        <Form.Row>
          <Col md={6}>
            <Form.Group>
              <FormLabel column={false}>{ta("user", "first_name")}</FormLabel>
              <StringInput trim={true} target={vm.item} property="first_name" />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <FormLabel column={false}>{ta("user", "last_name")}</FormLabel>
              <StringInput trim={true} target={vm.item} property="last_name" />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <Form.Group className="required">
              <FormLabel column={false}>{ta("user", "login")}</FormLabel>
              <StringInput trim={true} target={vm.item} property="email" disabled={!vm.isCreating} />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md="6">
            <Form.Group className="required">
              <FormLabel column={false}>{ta("user", "password")}</FormLabel>
              <Input type="password" target={vm.item} property="password" />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="required">
              <FormLabel column={false}>{ta("user", "password_repeated")}</FormLabel>
              <Input type="password" target={vm.item} property="password_repeated" />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <Form.Group className="required">
              <FormLabel column={false}>{ta("user", "role")}</FormLabel>
              <InputGroup>
                <Row>
                  {vm.roles.map(item => (
                    <Col md={6} key={item.value} className="mb-2">
                      <RadioArray
                        custom
                        target={vm}
                        property="role"
                        id={item.value}
                        label={item.label}
                        disabled={!vm.canChangeRole}
                      />
                    </Col>
                  ))}
                </Row>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <FormLabel column={false}>{tg("role.user")}</FormLabel>
              <InputGroup>
                <Row>
                  {vm.specialistTypes.map(item => (
                    <Col md={6} key={item.value} className="mb-2">
                      <CheckArray
                        custom
                        target={vm}
                        property="specialistType"
                        isNumeric={true}
                        isInvalid={vm.specialistTypeIsInvalid}
                        id={item.value.toString()}
                        label={item.label}
                        disabled={!vm.canChangeSpecialistType}
                      />
                    </Col>
                  ))}
                  <Col md={12}>
                    <ValidationError target={vm.item} property="role" />
                  </Col>
                </Row>
              </InputGroup>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <Form.Group>
              <FormLabel column={false}>{ta("parcel_shop", "category_id")}</FormLabel>
              <InputGroup>
                <Form.Check
                  id="select_all_categories"
                  type={"checkbox"}
                  custom
                  onChange={vm.toggleAllCategories}
                  checked={vm.allCategoriesChecked || vm.categoriesIndeterminate}
                  disabled={!vm.allowedCategorySettings}
                  label={tg("select_all")}
                  ref={(el: any) => el && (el.indeterminate = vm.categoriesIndeterminate)}
                />
                &nbsp;
                {vm.allowedCategorySettings &&
                  vm.categories.map(item => (
                    <CheckArray
                      key={item.value}
                      style={{ margin: "0px 8px" }}
                      custom
                      target={vm.item}
                      property="ps_category_ids"
                      isNumeric={true}
                      id={item.value.toString()}
                      label={item.label}
                    />
                  ))}
                <ValidationError target={vm.item} property="ps_category_ids" />
              </InputGroup>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md="12">
            <Form.Group>
              <FormLabel column={false}>{ta("user", "depo")}</FormLabel>
              <Form.Check
                id="select_all"
                type={"checkbox"}
                custom
                className="mb-3"
                onChange={vm.toggleAllDepots}
                checked={vm.allChecked || vm.indeterminate}
                disabled={!vm.allowedDepoSettings}
                label={tg("select_all")}
                ref={(el: any) => el && (el.indeterminate = vm.indeterminate)}
              />
              {vm.allowedDepoSettings && (
                <Row>
                  <InputGroup>
                    {vm.depots.map(item => (
                      <Col md={6} lg={4} key={item.value} className="mb-2">
                        <CheckArray
                          custom
                          target={vm.item}
                          property="depo_ids"
                          isNumeric={true}
                          id={item.value.toString()}
                          label={item.label}
                        />
                      </Col>
                    ))}
                    <Col md={12}>
                      <ValidationError target={vm.item} property="depo_ids" />
                    </Col>
                  </InputGroup>
                </Row>
              )}
            </Form.Group>
          </Col>
        </Form.Row>

        <ButtonToolbar>
          <Button type="submit" disabled={!vm.canSave} size="lg">
            {vm.isCreating ? tg("user.create.save") : tg("user.edit.save")}
          </Button>
          <Button variant="outline-secondary" onClick={vm.requestClose} size="lg">
            {tg("cancel")}
          </Button>
        </ButtonToolbar>
      </Form>
      <LoadingOverlay loading={vm.busyWatcher.isBusy} />
    </>
  );
});
export default registerView(userDetailView, UserDetailViewModel);
