import { RepositoryBase } from "data/repositoryBase";

export default class LogoutRepository extends RepositoryBase {
  logout(): Promise<unknown> {
    return this.apiFactory()
      .all("session")
      .delete()
      .then(resp => resp.json);
  }
}
