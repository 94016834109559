// WARNING: This file has been generated. Do not edit it manually, your changes might get lost.
import { decorate, inject, injectable } from "inversify";
import dataRepositoriesEnumRepository from "./data/repositories/enumRepository";
import dataRepositoriesLocalizationRepository from "./data/repositories/localizationRepository";
import dataRepositoriesLoginRepository from "./data/repositories/loginRepository";
import dataRepositoriesLogoutRepository from "./data/repositories/logoutRepository";
import dataRepositoriesMessagesRepository from "./data/repositories/messagesRepository";
import dataRepositoriesOpportunitiesRepository from "./data/repositories/opportunitiesRepository";
import dataRepositoriesParcelBoxRepository from "./data/repositories/parcelBoxRepository";
import dataRepositoriesParcelShopRepository from "./data/repositories/parcelShopRepository";
import dataRepositoriesPaymentTerminalRepository from "./data/repositories/paymentTerminalRepository";
import dataRepositoriesScanDevicesRepository from "./data/repositories/scanDevicesRepository";
import dataRepositoriesSettingsRepository from "./data/repositories/settingsRepository";
import dataRepositoriesSubjectRepository from "./data/repositories/subjectRepository";
import dataRepositoriesUsersRepository from "./data/repositories/usersRepository";
import servicesAlertsService from "./services/alertsService";
import servicesConfirmationService from "./services/confirmationService";
import servicesEnum from "./services/enum";
import servicesInitializationService from "./services/initializationService";
import servicesLightEventBusEventBus from "services/eventBus";
import servicesLocaleProvider from "./services/localeProvider";
import servicesLocalizationService from "./services/localizationService";
import servicesNotificationService from "./services/notificationService";
import servicesSecurityService from "./services/securityService";
import servicesSettingsService from "./services/settingsService";
import servicesUnreadMessageService from "./services/unreadMessageService";
import servicesUserContext from "./services/userContext";
import servicesValidationValidationService from "./services/validation/validationService";
import servicesWarehouseService from "./services/warehouseService";
import viewModelsAlertViewModel from "./viewModels/alertViewModel";
import viewModelsConfirmDialogViewModel from "./viewModels/confirmDialogViewModel";
import viewModelsDashboardActivitiesViewModel from "./viewModels/dashboard/activitiesViewModel";
import viewModelsDashboardDashboardViewModel from "./viewModels/dashboard/dashboardViewModel";
import viewModelsDashboardMemoDetailViewModel from "./viewModels/dashboard/memoDetailViewModel";
import viewModelsDashboardMemosViewModel from "./viewModels/dashboard/memosViewModel";
import viewModelsDashboardStatisticsViewModel from "./viewModels/dashboard/statisticsViewModel";
import viewModelsEnumerationsEnumerationDetailViewModel from "./viewModels/enumerations/enumerationDetailViewModel";
import viewModelsEnumerationsEnumerationScannerDetailViewModel from "./viewModels/enumerations/enumerationScannerDetailViewModel";
import viewModelsEnumerationsEnumerationsViewModel from "./viewModels/enumerations/enumerationsViewModel";
import viewModelsLoginViewModel from "./viewModels/loginViewModel";
import viewModelsMessageGroupsMessageGroupDetailViewModel from "./viewModels/messageGroups/messageGroupDetailViewModel";
import viewModelsMessageGroupsMessageGroupsViewModel from "./viewModels/messageGroups/messageGroupsViewModel";
import viewModelsMessagesMessageDetailViewModel from "./viewModels/messages/messageDetailViewModel";
import viewModelsMessagesMessagesViewModel from "./viewModels/messages/messagesViewModel";
import viewModelsOpportunitiesOpportunitiesViewModel from "./viewModels/opportunities/opportunitiesViewModel";
import viewModelsOpportunitiesOpportunityDetailViewModel from "./viewModels/opportunities/opportunityDetailViewModel";
import viewModelsParcelBoxParcelBoxDetailViewModel from "./viewModels/parcelBox/parcelBoxDetailViewModel";
import viewModelsParcelBoxParcelBoxesViewModel from "./viewModels/parcelBox/parcelBoxesViewModel";
import viewModelsPaymentTerminalsPaymentTerminalDetailViewModel from "./viewModels/paymentTerminals/paymentTerminalDetailViewModel";
import viewModelsPaymentTerminalsPaymentTerminalsImportViewModel from "./viewModels/paymentTerminals/paymentTerminalsImportViewModel";
import viewModelsPaymentTerminalsPaymentTerminalsViewModel from "./viewModels/paymentTerminals/paymentTerminalsViewModel";
import viewModelsPickupPointCheckSubjectViewModel from "./viewModels/pickupPoint/checkSubjectViewModel";
import viewModelsPickupPointNewPickupPointViewModel from "./viewModels/pickupPoint/newPickupPointViewModel";
import viewModelsRootViewModel from "./viewModels/rootViewModel";
import viewModelsScanDevicesScanDeviceDetailViewModel from "./viewModels/scanDevices/scanDeviceDetailViewModel";
import viewModelsScanDevicesScanDevicesImportViewModel from "./viewModels/scanDevices/scanDevicesImportViewModel";
import viewModelsScanDevicesScanDevicesViewModel from "./viewModels/scanDevices/scanDevicesViewModel";
import viewModelsSettingsSettingsViewModel from "./viewModels/settings/settingsViewModel";
import viewModelsShopsDetailAccessControlViewModel from "./viewModels/shops/detail/accessControlViewModel";
import viewModelsShopsDetailActivityActivityDetailViewModel from "./viewModels/shops/detail/activity/activityDetailViewModel";
import viewModelsShopsDetailBoxDetailViewModel from "./viewModels/shops/detail/boxDetailViewModel";
import viewModelsShopsDetailCommonDetailViewModel from "./viewModels/shops/detail/commonDetailViewModel";
import viewModelsShopsDetailEditEditAdditionalInformationViewModel from "./viewModels/shops/detail/edit/editAdditionalInformationViewModel";
import viewModelsShopsDetailEditEditAddressesViewModel from "./viewModels/shops/detail/edit/editAddressesViewModel";
import viewModelsShopsDetailEditEditContactsViewModel from "./viewModels/shops/detail/edit/editContactsViewModel";
import viewModelsShopsDetailEditEditOpeningHoursViewModel from "./viewModels/shops/detail/edit/editOpeningHoursViewModel";
import viewModelsShopsDetailEditEditShopConfigurationViewModel from "./viewModels/shops/detail/edit/editShopConfigurationViewModel";
import viewModelsShopsDetailEditEditShopInformationViewModel from "./viewModels/shops/detail/edit/editShopInformationViewModel";
import viewModelsShopsDetailEditEditShopViewModel from "./viewModels/shops/detail/edit/editShopViewModel";
import viewModelsShopsDetailInvoicingBonusDetailViewModel from "./viewModels/shops/detail/invoicing/bonusDetailViewModel";
import viewModelsShopsDetailInvoicingBonusesViewModel from "./viewModels/shops/detail/invoicing/bonusesViewModel";
import viewModelsShopsDetailInvoicingBonusNoteDetailViewModel from "./viewModels/shops/detail/invoicing/bonusNoteDetailViewModel";
import viewModelsShopsDetailInvoicingBoxInvoicingViewModel from "./viewModels/shops/detail/invoicing/boxInvoicingViewModel";
import viewModelsShopsDetailInvoicingInvoicesViewModel from "./viewModels/shops/detail/invoicing/invoicesViewModel";
import viewModelsShopsDetailInvoicingK2ExportViewModel from "./viewModels/shops/detail/invoicing/k2ExportViewModel";
import viewModelsShopsDetailInvoicingPaymentStoppageDetailViewModel from "./viewModels/shops/detail/invoicing/paymentStoppageDetailViewModel";
import viewModelsShopsDetailInvoicingPaymentStoppageViewModel from "./viewModels/shops/detail/invoicing/paymentStoppageViewModel";
import viewModelsShopsDetailInvoicingRecurringPaymentsViewModel from "./viewModels/shops/detail/invoicing/recurringPaymentsViewModel";
import viewModelsShopsDetailInvoicingRewardsEditViewModel from "./viewModels/shops/detail/invoicing/rewardsEditViewModel";
import viewModelsShopsDetailInvoicingRewardsViewModel from "./viewModels/shops/detail/invoicing/rewardsViewModel";
import viewModelsShopsDetailInvoicingShopInvoicingViewModel from "./viewModels/shops/detail/invoicing/shopInvoicingViewModel";
import viewModelsShopsDetailParcelShopUserDetailViewModel from "./viewModels/shops/detail/parcelShopUserDetailViewModel";
import viewModelsShopsDetailPotentialDetailViewModel from "./viewModels/shops/detail/potentialDetailViewModel";
import viewModelsShopsDetailShopDetailViewModel from "./viewModels/shops/detail/shopDetailViewModel";
import viewModelsShopsDetailWarehouseStockTakingViewModel from "./viewModels/shops/detail/warehouse/stockTakingViewModel";
import viewModelsShopsDetailWarehouseWarehouseViewModel from "./viewModels/shops/detail/warehouse/warehouseViewModel";
import viewModelsShopsNewShopNewAdditionalInformationViewModel from "./viewModels/shops/newShop/newAdditionalInformationViewModel";
import viewModelsShopsNewShopNewAddressesViewModel from "./viewModels/shops/newShop/newAddressesViewModel";
import viewModelsShopsNewShopNewContactsViewModel from "./viewModels/shops/newShop/newContactsViewModel";
import viewModelsShopsNewShopNewOpeningHoursViewModel from "./viewModels/shops/newShop/newOpeningHoursViewModel";
import viewModelsShopsNewShopNewParcelShopViewModel from "./viewModels/shops/newShop/newParcelShopViewModel";
import viewModelsShopsNewShopNewShopConfigurationViewModel from "./viewModels/shops/newShop/newShopConfigurationViewModel";
import viewModelsShopsNewShopNewShopInformationViewModel from "./viewModels/shops/newShop/newShopInformationViewModel";
import viewModelsShopsNewSubjectViewModel from "./viewModels/shops/newSubjectViewModel";
import viewModelsShopsShopsViewModel from "./viewModels/shops/shopsViewModel";
import viewModelsSubjectSubjectDetailViewModel from "./viewModels/subject/subjectDetailViewModel";
import viewModelsSubjectSubjectViewModel from "./viewModels/subject/subjectViewModel";
import viewModelsUsersUserDetailViewModel from "./viewModels/users/userDetailViewModel";
import viewModelsUsersUsersViewModel from "./viewModels/users/usersViewModel";
decorate(injectable(), servicesAlertsService);
decorate(inject(servicesNotificationService) as any, servicesAlertsService, 0);
decorate(injectable(), servicesConfirmationService);
decorate(inject(servicesLocalizationService) as any, servicesConfirmationService, 0);
decorate(injectable(), servicesEnum);
decorate(inject(dataRepositoriesEnumRepository) as any, servicesEnum, 0);
decorate(injectable(), servicesInitializationService);
decorate(inject(servicesUserContext) as any, servicesInitializationService, 0);
decorate(inject(servicesLocalizationService) as any, servicesInitializationService, 1);
decorate(inject(servicesEnum) as any, servicesInitializationService, 2);
decorate(inject(servicesValidationValidationService) as any, servicesInitializationService, 3);
decorate(inject(servicesSettingsService) as any, servicesInitializationService, 4);
decorate(injectable(), servicesLocalizationService);
decorate(inject(servicesLocaleProvider) as any, servicesLocalizationService, 0);
decorate(inject(dataRepositoriesLocalizationRepository) as any, servicesLocalizationService, 1);
decorate(injectable(), servicesNotificationService);
decorate(inject(servicesLightEventBusEventBus) as any, servicesNotificationService, 0);
decorate(injectable(), servicesSecurityService);
decorate(inject(servicesUserContext) as any, servicesSecurityService, 0);
decorate(inject(servicesEnum) as any, servicesSecurityService, 1);
decorate(injectable(), servicesSettingsService);
decorate(inject(dataRepositoriesSettingsRepository) as any, servicesSettingsService, 0);
decorate(inject(servicesLightEventBusEventBus) as any, servicesSettingsService, 1);
decorate(inject(servicesLocalizationService) as any, servicesSettingsService, 2);
decorate(inject(servicesNotificationService) as any, servicesSettingsService, 3);
decorate(injectable(), servicesUnreadMessageService);
decorate(inject(servicesLightEventBusEventBus) as any, servicesUnreadMessageService, 0);
decorate(inject(dataRepositoriesMessagesRepository) as any, servicesUnreadMessageService, 1);
decorate(inject(servicesUserContext) as any, servicesUnreadMessageService, 2);
decorate(injectable(), servicesWarehouseService);
decorate(inject(dataRepositoriesParcelShopRepository) as any, servicesWarehouseService, 0);
decorate(injectable(), viewModelsAlertViewModel);
decorate(injectable(), viewModelsConfirmDialogViewModel);
decorate(inject("ButtonParams") as any, viewModelsConfirmDialogViewModel, 2);
decorate(inject("ButtonParams") as any, viewModelsConfirmDialogViewModel, 3);
decorate(injectable(), viewModelsLoginViewModel);
decorate(inject(servicesUserContext) as any, viewModelsLoginViewModel, 0);
decorate(inject(dataRepositoriesLoginRepository) as any, viewModelsLoginViewModel, 1);
decorate(inject(servicesLocalizationService) as any, viewModelsLoginViewModel, 2);
decorate(inject(servicesUnreadMessageService) as any, viewModelsLoginViewModel, 3);
decorate(injectable(), viewModelsRootViewModel);
decorate(inject(servicesUserContext) as any, viewModelsRootViewModel, 0);
decorate(inject(servicesConfirmationService) as any, viewModelsRootViewModel, 1);
decorate(inject(servicesAlertsService) as any, viewModelsRootViewModel, 2);
decorate(inject(viewModelsDashboardDashboardViewModel) as any, viewModelsRootViewModel, 3);
decorate(inject(servicesSecurityService) as any, viewModelsRootViewModel, 4);
decorate(inject(viewModelsShopsShopsViewModel) as any, viewModelsRootViewModel, 5);
decorate(inject(viewModelsUsersUsersViewModel) as any, viewModelsRootViewModel, 6);
decorate(inject(viewModelsMessagesMessagesViewModel) as any, viewModelsRootViewModel, 7);
decorate(inject(viewModelsSubjectSubjectViewModel) as any, viewModelsRootViewModel, 8);
decorate(inject(viewModelsPaymentTerminalsPaymentTerminalsViewModel) as any, viewModelsRootViewModel, 9);
decorate(inject(viewModelsParcelBoxParcelBoxesViewModel) as any, viewModelsRootViewModel, 10);
decorate(inject(viewModelsSettingsSettingsViewModel) as any, viewModelsRootViewModel, 11);
decorate(inject(viewModelsEnumerationsEnumerationsViewModel) as any, viewModelsRootViewModel, 12);
decorate(inject(viewModelsScanDevicesScanDevicesViewModel) as any, viewModelsRootViewModel, 13);
decorate(inject(dataRepositoriesLogoutRepository) as any, viewModelsRootViewModel, 14);
decorate(inject(servicesUnreadMessageService) as any, viewModelsRootViewModel, 15);
decorate(inject(viewModelsPickupPointNewPickupPointViewModel) as any, viewModelsRootViewModel, 16);
decorate(inject(viewModelsOpportunitiesOpportunitiesViewModel) as any, viewModelsRootViewModel, 17);
decorate(inject(servicesLightEventBusEventBus) as any, viewModelsRootViewModel, 18);
decorate(injectable(), servicesValidationValidationService);
decorate(inject(servicesLocalizationService) as any, servicesValidationValidationService, 0);
decorate(injectable(), viewModelsDashboardActivitiesViewModel);
decorate(injectable(), viewModelsDashboardDashboardViewModel);
decorate(inject(viewModelsDashboardStatisticsViewModel.Factory) as any, viewModelsDashboardDashboardViewModel, 0);
decorate(inject(viewModelsDashboardMemosViewModel.Factory) as any, viewModelsDashboardDashboardViewModel, 1);
decorate(inject(viewModelsDashboardActivitiesViewModel.Factory) as any, viewModelsDashboardDashboardViewModel, 2);
decorate(inject(servicesEnum) as any, viewModelsDashboardDashboardViewModel, 3);
decorate(inject(dataRepositoriesUsersRepository) as any, viewModelsDashboardDashboardViewModel, 4);
decorate(injectable(), viewModelsDashboardMemoDetailViewModel);
decorate(injectable(), viewModelsDashboardMemosViewModel);
decorate(injectable(), viewModelsDashboardStatisticsViewModel);
decorate(injectable(), viewModelsEnumerationsEnumerationDetailViewModel);
decorate(injectable(), viewModelsEnumerationsEnumerationScannerDetailViewModel);
decorate(injectable(), viewModelsEnumerationsEnumerationsViewModel);
decorate(inject(dataRepositoriesEnumRepository) as any, viewModelsEnumerationsEnumerationsViewModel, 0);
decorate(inject(servicesLocalizationService) as any, viewModelsEnumerationsEnumerationsViewModel, 1);
decorate(inject(servicesLightEventBusEventBus) as any, viewModelsEnumerationsEnumerationsViewModel, 2);
decorate(inject(viewModelsEnumerationsEnumerationDetailViewModel.Factory) as any, viewModelsEnumerationsEnumerationsViewModel, 3);
decorate(inject(viewModelsEnumerationsEnumerationScannerDetailViewModel.Factory) as any, viewModelsEnumerationsEnumerationsViewModel, 4);
decorate(injectable(), viewModelsMessageGroupsMessageGroupDetailViewModel);
decorate(injectable(), viewModelsMessageGroupsMessageGroupsViewModel);
decorate(injectable(), viewModelsMessagesMessageDetailViewModel);
decorate(injectable(), viewModelsMessagesMessagesViewModel);
decorate(inject(dataRepositoriesMessagesRepository) as any, viewModelsMessagesMessagesViewModel, 0);
decorate(inject(dataRepositoriesParcelShopRepository) as any, viewModelsMessagesMessagesViewModel, 1);
decorate(inject(servicesLightEventBusEventBus) as any, viewModelsMessagesMessagesViewModel, 2);
decorate(inject(servicesEnum) as any, viewModelsMessagesMessagesViewModel, 3);
decorate(inject(viewModelsMessagesMessageDetailViewModel.Factory) as any, viewModelsMessagesMessagesViewModel, 4);
decorate(inject(servicesConfirmationService) as any, viewModelsMessagesMessagesViewModel, 5);
decorate(inject(servicesLocalizationService) as any, viewModelsMessagesMessagesViewModel, 6);
decorate(inject(servicesUnreadMessageService) as any, viewModelsMessagesMessagesViewModel, 7);
decorate(inject(viewModelsMessageGroupsMessageGroupsViewModel.Factory) as any, viewModelsMessagesMessagesViewModel, 8);
decorate(inject(servicesUserContext) as any, viewModelsMessagesMessagesViewModel, 9);
decorate(injectable(), viewModelsOpportunitiesOpportunitiesViewModel);
decorate(inject(dataRepositoriesOpportunitiesRepository) as any, viewModelsOpportunitiesOpportunitiesViewModel, 0);
decorate(inject(dataRepositoriesParcelShopRepository) as any, viewModelsOpportunitiesOpportunitiesViewModel, 1);
decorate(inject(servicesLightEventBusEventBus) as any, viewModelsOpportunitiesOpportunitiesViewModel, 2);
decorate(inject(viewModelsOpportunitiesOpportunityDetailViewModel.Factory) as any, viewModelsOpportunitiesOpportunitiesViewModel, 3);
decorate(inject(servicesConfirmationService) as any, viewModelsOpportunitiesOpportunitiesViewModel, 4);
decorate(inject(servicesLocalizationService) as any, viewModelsOpportunitiesOpportunitiesViewModel, 5);
decorate(inject(servicesEnum) as any, viewModelsOpportunitiesOpportunitiesViewModel, 6);
decorate(inject(servicesUserContext) as any, viewModelsOpportunitiesOpportunitiesViewModel, 7);
decorate(inject(dataRepositoriesUsersRepository) as any, viewModelsOpportunitiesOpportunitiesViewModel, 8);
decorate(injectable(), viewModelsOpportunitiesOpportunityDetailViewModel);
decorate(injectable(), viewModelsParcelBoxParcelBoxDetailViewModel);
decorate(injectable(), viewModelsParcelBoxParcelBoxesViewModel);
decorate(inject(dataRepositoriesParcelBoxRepository) as any, viewModelsParcelBoxParcelBoxesViewModel, 0);
decorate(inject(servicesLocalizationService) as any, viewModelsParcelBoxParcelBoxesViewModel, 1);
decorate(inject(servicesEnum) as any, viewModelsParcelBoxParcelBoxesViewModel, 2);
decorate(inject(viewModelsParcelBoxParcelBoxDetailViewModel.Factory) as any, viewModelsParcelBoxParcelBoxesViewModel, 3);
decorate(inject(servicesLightEventBusEventBus) as any, viewModelsParcelBoxParcelBoxesViewModel, 4);
decorate(inject(dataRepositoriesParcelShopRepository) as any, viewModelsParcelBoxParcelBoxesViewModel, 5);
decorate(inject(servicesUserContext) as any, viewModelsParcelBoxParcelBoxesViewModel, 6);
decorate(injectable(), viewModelsPaymentTerminalsPaymentTerminalDetailViewModel);
decorate(injectable(), viewModelsPaymentTerminalsPaymentTerminalsImportViewModel);
decorate(injectable(), viewModelsPaymentTerminalsPaymentTerminalsViewModel);
decorate(inject(dataRepositoriesPaymentTerminalRepository) as any, viewModelsPaymentTerminalsPaymentTerminalsViewModel, 0);
decorate(inject(dataRepositoriesParcelShopRepository) as any, viewModelsPaymentTerminalsPaymentTerminalsViewModel, 1);
decorate(inject(servicesLightEventBusEventBus) as any, viewModelsPaymentTerminalsPaymentTerminalsViewModel, 2);
decorate(inject(viewModelsPaymentTerminalsPaymentTerminalDetailViewModel.Factory) as any, viewModelsPaymentTerminalsPaymentTerminalsViewModel, 3);
decorate(inject(viewModelsPaymentTerminalsPaymentTerminalsImportViewModel.Factory) as any, viewModelsPaymentTerminalsPaymentTerminalsViewModel, 4);
decorate(inject(servicesConfirmationService) as any, viewModelsPaymentTerminalsPaymentTerminalsViewModel, 5);
decorate(inject(servicesLocalizationService) as any, viewModelsPaymentTerminalsPaymentTerminalsViewModel, 6);
decorate(inject(servicesEnum) as any, viewModelsPaymentTerminalsPaymentTerminalsViewModel, 7);
decorate(inject(servicesUserContext) as any, viewModelsPaymentTerminalsPaymentTerminalsViewModel, 8);
decorate(injectable(), viewModelsPickupPointCheckSubjectViewModel);
decorate(injectable(), viewModelsPickupPointNewPickupPointViewModel);
decorate(inject(servicesLocalizationService) as any, viewModelsPickupPointNewPickupPointViewModel, 0);
decorate(inject(servicesSecurityService) as any, viewModelsPickupPointNewPickupPointViewModel, 1);
decorate(inject(servicesEnum) as any, viewModelsPickupPointNewPickupPointViewModel, 2);
decorate(inject(viewModelsPickupPointCheckSubjectViewModel.Factory) as any, viewModelsPickupPointNewPickupPointViewModel, 3);
decorate(injectable(), viewModelsScanDevicesScanDeviceDetailViewModel);
decorate(injectable(), viewModelsScanDevicesScanDevicesImportViewModel);
decorate(injectable(), viewModelsScanDevicesScanDevicesViewModel);
decorate(inject(dataRepositoriesScanDevicesRepository) as any, viewModelsScanDevicesScanDevicesViewModel, 0);
decorate(inject(dataRepositoriesParcelShopRepository) as any, viewModelsScanDevicesScanDevicesViewModel, 1);
decorate(inject(servicesLightEventBusEventBus) as any, viewModelsScanDevicesScanDevicesViewModel, 2);
decorate(inject(viewModelsScanDevicesScanDeviceDetailViewModel.Factory) as any, viewModelsScanDevicesScanDevicesViewModel, 3);
decorate(inject(viewModelsScanDevicesScanDevicesImportViewModel.Factory) as any, viewModelsScanDevicesScanDevicesViewModel, 4);
decorate(inject(servicesConfirmationService) as any, viewModelsScanDevicesScanDevicesViewModel, 5);
decorate(inject(servicesLocalizationService) as any, viewModelsScanDevicesScanDevicesViewModel, 6);
decorate(inject(servicesNotificationService) as any, viewModelsScanDevicesScanDevicesViewModel, 7);
decorate(inject(servicesEnum) as any, viewModelsScanDevicesScanDevicesViewModel, 8);
decorate(inject(servicesUserContext) as any, viewModelsScanDevicesScanDevicesViewModel, 9);
decorate(injectable(), viewModelsSettingsSettingsViewModel);
decorate(inject(servicesLocalizationService) as any, viewModelsSettingsSettingsViewModel, 0);
decorate(inject(servicesLightEventBusEventBus) as any, viewModelsSettingsSettingsViewModel, 1);
decorate(inject(servicesConfirmationService) as any, viewModelsSettingsSettingsViewModel, 2);
decorate(inject(servicesSettingsService) as any, viewModelsSettingsSettingsViewModel, 3);
decorate(inject(servicesNotificationService) as any, viewModelsSettingsSettingsViewModel, 4);
decorate(injectable(), viewModelsShopsNewSubjectViewModel);
decorate(injectable(), viewModelsShopsShopsViewModel);
decorate(inject(dataRepositoriesParcelShopRepository) as any, viewModelsShopsShopsViewModel, 0);
decorate(inject(servicesEnum) as any, viewModelsShopsShopsViewModel, 1);
decorate(inject(servicesLightEventBusEventBus) as any, viewModelsShopsShopsViewModel, 2);
decorate(inject(servicesLocalizationService) as any, viewModelsShopsShopsViewModel, 3);
decorate(inject(servicesSecurityService) as any, viewModelsShopsShopsViewModel, 4);
decorate(inject(viewModelsShopsDetailCommonDetailViewModel.Factory) as any, viewModelsShopsShopsViewModel, 5);
decorate(injectable(), viewModelsSubjectSubjectDetailViewModel);
decorate(injectable(), viewModelsSubjectSubjectViewModel);
decorate(inject(dataRepositoriesSubjectRepository) as any, viewModelsSubjectSubjectViewModel, 0);
decorate(inject(servicesLightEventBusEventBus) as any, viewModelsSubjectSubjectViewModel, 1);
decorate(inject(viewModelsSubjectSubjectDetailViewModel.Factory) as any, viewModelsSubjectSubjectViewModel, 2);
decorate(inject(servicesConfirmationService) as any, viewModelsSubjectSubjectViewModel, 3);
decorate(inject(servicesLocalizationService) as any, viewModelsSubjectSubjectViewModel, 4);
decorate(inject(servicesEnum) as any, viewModelsSubjectSubjectViewModel, 5);
decorate(injectable(), viewModelsUsersUserDetailViewModel);
decorate(injectable(), viewModelsUsersUsersViewModel);
decorate(inject(dataRepositoriesUsersRepository) as any, viewModelsUsersUsersViewModel, 0);
decorate(inject(servicesLightEventBusEventBus) as any, viewModelsUsersUsersViewModel, 1);
decorate(inject(viewModelsUsersUserDetailViewModel.Factory) as any, viewModelsUsersUsersViewModel, 2);
decorate(inject(servicesEnum) as any, viewModelsUsersUsersViewModel, 3);
decorate(inject(servicesSecurityService) as any, viewModelsUsersUsersViewModel, 4);
decorate(inject(servicesNotificationService) as any, viewModelsUsersUsersViewModel, 5);
decorate(inject(servicesConfirmationService) as any, viewModelsUsersUsersViewModel, 6);
decorate(inject(servicesLocalizationService) as any, viewModelsUsersUsersViewModel, 7);
decorate(injectable(), viewModelsShopsDetailAccessControlViewModel);
decorate(injectable(), viewModelsShopsDetailBoxDetailViewModel);
decorate(injectable(), viewModelsShopsDetailCommonDetailViewModel);
decorate(injectable(), viewModelsShopsDetailParcelShopUserDetailViewModel);
decorate(injectable(), viewModelsShopsDetailPotentialDetailViewModel);
decorate(injectable(), viewModelsShopsDetailShopDetailViewModel);
decorate(injectable(), viewModelsShopsNewShopNewAdditionalInformationViewModel);
decorate(injectable(), viewModelsShopsNewShopNewAddressesViewModel);
decorate(injectable(), viewModelsShopsNewShopNewContactsViewModel);
decorate(injectable(), viewModelsShopsNewShopNewOpeningHoursViewModel);
decorate(injectable(), viewModelsShopsNewShopNewParcelShopViewModel);
decorate(injectable(), viewModelsShopsNewShopNewShopConfigurationViewModel);
decorate(injectable(), viewModelsShopsNewShopNewShopInformationViewModel);
decorate(injectable(), viewModelsShopsDetailActivityActivityDetailViewModel);
decorate(injectable(), viewModelsShopsDetailEditEditAdditionalInformationViewModel);
decorate(injectable(), viewModelsShopsDetailEditEditAddressesViewModel);
decorate(injectable(), viewModelsShopsDetailEditEditContactsViewModel);
decorate(injectable(), viewModelsShopsDetailEditEditOpeningHoursViewModel);
decorate(injectable(), viewModelsShopsDetailEditEditShopConfigurationViewModel);
decorate(injectable(), viewModelsShopsDetailEditEditShopInformationViewModel);
decorate(injectable(), viewModelsShopsDetailEditEditShopViewModel);
decorate(injectable(), viewModelsShopsDetailInvoicingBonusDetailViewModel);
decorate(injectable(), viewModelsShopsDetailInvoicingBonusesViewModel);
decorate(injectable(), viewModelsShopsDetailInvoicingBonusNoteDetailViewModel);
decorate(injectable(), viewModelsShopsDetailInvoicingBoxInvoicingViewModel);
decorate(injectable(), viewModelsShopsDetailInvoicingInvoicesViewModel);
decorate(injectable(), viewModelsShopsDetailInvoicingK2ExportViewModel);
decorate(injectable(), viewModelsShopsDetailInvoicingPaymentStoppageDetailViewModel);
decorate(injectable(), viewModelsShopsDetailInvoicingPaymentStoppageViewModel);
decorate(injectable(), viewModelsShopsDetailInvoicingRecurringPaymentsViewModel);
decorate(injectable(), viewModelsShopsDetailInvoicingRewardsEditViewModel);
decorate(injectable(), viewModelsShopsDetailInvoicingRewardsViewModel);
decorate(injectable(), viewModelsShopsDetailInvoicingShopInvoicingViewModel);
decorate(injectable(), viewModelsShopsDetailWarehouseStockTakingViewModel);
decorate(injectable(), viewModelsShopsDetailWarehouseWarehouseViewModel);
