import { hasVisibleErrors } from "@frui.ts/validation";
import Address from "entities/address";
import { validateAll } from "helpers";
import { interfaces } from "inversify";
import ShopInformation from "models/shops/newShop/shopInformation";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import AddressesViewModelBase from "../detail/edit/addressesViewModelBase";
import { AddressModel } from "../detail/types";
import { IStepViewModel } from "./types";
import { action } from "mobx";
import isPotential from "../../../utils/isPotential";
import { ensureObservableProperty } from "@frui.ts/helpers";

export default class NewAddressesViewModel extends AddressesViewModelBase implements IStepViewModel {
  constructor(
    protected shop: ShopInformation,
    addresses: Address[],
    localizationService: LocalizationService,
    enums: EnumService
  ) {
    super(shop.name, localizationService, enums);
    this.name = localizationService.translateGeneral("parcel_shop.address");
    addresses.forEach(address => ensureObservableProperty(address, "isMandatory", false));
    this.items = [...addresses];
  }

  onInitialize() {
    this.enums.values("address_types").forEach(item => {
      if ((item.required && !this.isPotential) || (item.required_potential_ps && this.isPotential)) {
        const existingAddress = this.items.find(address => address.addressTypeId === item.id);
        if (existingAddress) {
          existingAddress.default = true;
          existingAddress.isMandatory = true;
        } else {
          this.addAddress(this.createDefaultAddress(item.id));
        }
      }
    });
  }

  get isPotential() {
    const partnerCode = this.enums.value("access_point_partners", this.shop.typeId)?.code;
    return isPotential(partnerCode ?? "");
  }

  get canContinue() {
    return this.items.every(x => !hasVisibleErrors(x)) && !this.customErrorMessage;
  }

  validate() {
    return validateAll(this.items);
  }

  @action removeAddress(address: AddressModel) {
    if (address.isMandatory) {
      return;
    }

    const index = this.items.indexOf(address);
    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }

  private createDefaultAddress(typeId: number) {
    const address = new Address() as AddressModel;
    address.addressTypeId = typeId;
    address.isMandatory = true;
    address.default = true;
    return address;
  }

  static Factory({ container }: interfaces.Context) {
    return (shop: ShopInformation, addresses: Address[] = []) =>
      new NewAddressesViewModel(shop, addresses, container.get(LocalizationService), container.get(EnumService));
  }
}
