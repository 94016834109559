import ParcelShopRepository from "data/repositories/parcelShopRepository";
import { mergeArrays } from "helpers";
import { groupBy, partition } from "lodash";
import WarehouseContent from "models/shops/warehouseContent";
import Parcel from "entities/parcel";

export enum ParcelStatus {
  HANDED_IN_BY_CUSTOMER = 235,
  PICKED_UP_BY_DRIVER = 236,
  DELIVERED_BY_DRIVER = 343,
  HANDED_OUT_TO_CUSTOMER = 450,
  REFUSED_LATE_DELIVERY = 464,
  REFUSED_INCOMPLETE = 465,
  REFUSED_OTHER_REASON = 466,
  REFUSED_DAMAGED = 472,
  OVERDUE = 480,
  CASH_PICKED_UP_BY_DRIVER = 919,
  PARCEL_FOUND = 996,
  PARCEL_STUCK = 1950,
}

export enum ParcelPaymentMethod {
  BY_CARD = 1,
  BY_CASH = 0,
}

export default class WarehouseService {
  constructor(private repository: ParcelShopRepository) {}

  async getWarehouseContent(shopId: number): Promise<WarehouseContent> {
    const data = await this.repository.getWarehouse(shopId);
    const [isMissing, notMissing] = partition(data, x => x.missing);

    const byStatus = groupBy(notMissing, x => x.status);

    const sortByTimestamp = (a: Parcel, b: Parcel) => {
      const aDate = new Date(a.timestamp);
      const bDate = new Date(b.timestamp);

      return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
    };

    return {
      total: data.length,
      missing: isMissing,
      stuck: mergeArrays([byStatus?.[ParcelStatus.PARCEL_STUCK]]).sort(sortByTimestamp),
      forCustomer: mergeArrays([byStatus?.[ParcelStatus.DELIVERED_BY_DRIVER]]).sort(sortByTimestamp),
      forDriver: mergeArrays([
        byStatus?.[ParcelStatus.HANDED_IN_BY_CUSTOMER],
        byStatus?.[ParcelStatus.REFUSED_LATE_DELIVERY],
        byStatus?.[ParcelStatus.REFUSED_INCOMPLETE],
        byStatus?.[ParcelStatus.REFUSED_OTHER_REASON],
        byStatus?.[ParcelStatus.REFUSED_DAMAGED],
        byStatus?.[ParcelStatus.OVERDUE],
      ]).sort(sortByTimestamp),
    };
  }

  changeParcelStatus(shopId: number, parcelId: number, status: number, paymentMethod: ParcelPaymentMethod): Promise<any> {
    return this.repository.changeParcelStatus(shopId, parcelId, status, paymentMethod);
  }
}
