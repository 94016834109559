import { isObservableArray } from "mobx";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "reflect-metadata";
import { runApp } from "./app";
import createContainer from "./di.config";
import * as serviceWorker from "./serviceWorker";

import "./views";

const apiUrl = `${process.env.REACT_APP_API_URL ?? ""}${process.env.REACT_APP_API_ENDPOINT ?? ""}`;
const container = createContainer(apiUrl);

// monkey patch the Array.isArray to support observable arrays (until mobx 6 is out or class-transformer fixed)
const originalIsArray = Array.isArray;
Array.isArray = function (arg: any): arg is any[] {
  return originalIsArray(arg) || isObservableArray(arg);
};

if (process.env.REACT_APP_MOCK_API === "true") {
  import("./mocks/apiMock").then(x => x.initialize()).then(() => runApp(container));
} else {
  runApp(container);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
