import { ViewComponent } from "@frui.ts/views";
import DataTable from "controls/dataTable";
import HeaderRow from "controls/headerRow";
import PagingInfo from "controls/pagingInfo";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonToolbar, Card, Col, Form } from "react-bootstrap";
import SubjectViewModel from "viewModels/subject/subjectViewModel";
import preventDefault from "views/preventDefault";
import { Select } from "@frui.ts/bootstrap";
import { StringInput } from "controls/stringInput";

const subjectList: ViewComponent<SubjectViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("subject", attribute);
  };

  const taPS = (attribute: string) => {
    return translateAttribute("parcel_shop", attribute);
  };

  return (
    <>
      <HeaderRow title={tg("subject.title")} />

      <Card body className="mb-4">
        <Form onSubmit={preventDefault(vm.applyFilterAndLoad)} onReset={vm.resetFilterAndLoad}>
          <Form.Row>
            <Form.Group as={Col} md={3}>
              <Form.Label>{ta("subject_id")}</Form.Label>
              <StringInput trim={true} target={vm.filter} property="subject_id" />
            </Form.Group>

            <Form.Group as={Col} md={3}>
              <Form.Label>{ta("ic")}</Form.Label>
              <StringInput trim={true} target={vm.filter} property="ic" />
            </Form.Group>

            <Form.Group as={Col} md={6}>
              <Form.Label>{ta("name")}</Form.Label>
              <StringInput trim={true} target={vm.filter} property="name" />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={3}>
              <Form.Label>{taPS("depo_id")}</Form.Label>
              <Select target={vm.filter} property="depo_id" items={vm.depos} mode="key" keyProperty="value" allowEmpty />
            </Form.Group>
            <Form.Group as={Col} md={3}>
              <Form.Label>{ta("subject_type")}</Form.Label>
              <Select
                target={vm.filter}
                property="subject_type_id"
                items={vm.subjectTypes}
                mode="key"
                keyProperty="value"
                allowEmpty
              />
            </Form.Group>
          </Form.Row>

          <ButtonToolbar>
            <Button type="submit" size="lg">
              {tg("search")}
            </Button>

            {vm.isFilterChanged && (
              <Button type="reset" variant="outline-danger">
                &#215; {tg("reset_filter")}
              </Button>
            )}
          </ButtonToolbar>
        </Form>
      </Card>

      <PagingInfo paging={vm.currentPaging} modelName="result" localization={vm.localizationService} />

      <DataTable
        columns={[
          {
            dataField: "subject_id" as any,
            formatter: (_cell, row) => row.subjectId,
            text: ta("subject_id"),
            sort: true,
            title: true,
          },
          {
            dataField: "subjectTypeId",
            text: ta("subject_type"),
            sort: false,
            formatter: value => vm.getSubjectType(value),
            title: value => vm.getSubjectType(value),
          },
          {
            dataField: "ic",
            text: ta("ic"),
            sort: true,
            title: true,
          },
          {
            dataField: "name",
            text: ta("name"),
            sort: true,
            title: true,
          },
          {
            dataField: "depo_id" as any,
            text: ta("depo"),
            sort: true,
            formatter: (cell, row) => vm.getDepoName(row.depoId),
            title: value => vm.getDepoName(value),
          },
        ]}
        vm={vm}
      />
    </>
  );
});

export default subjectList;
