import { Expose, Type } from "class-transformer";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CreateMemoDto {
  /**
   * Due date
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "due_date" })
  dueDate!: Date;

  /**
   * Subject
   */
  @observable
  subject!: string;

  /**
   * Body
   */
  @observable
  body?: string;

  /**
   * Assignees
   */
  @observable
  assignees!: number[];

  static ValidationRules = {
    dueDate: { required: true },
    subject: { maxLength: 250, minLength: 1, required: true },
    assignees: { required: true },
  };
}
