import { observable } from "mobx";
import { Expose } from "class-transformer";
import ScanDeviceImportStatusDtoResult from "./scanDeviceImportStatusDtoResult";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ScanDeviceImportStatusDto {
  /**
   * Status of the import
   */
  @observable
  result!: ScanDeviceImportStatusDtoResult;

  /**
   * IMEI
   */
  @observable
  imei!: string;

  /**
   * Device type
   */
  @observable
  @Expose({ name: "device_type_id" })
  deviceTypeId!: number;

  /**
   * Price
   */
  @observable
  price!: number;

  static ValidationRules = {
    result: { required: true },
    imei: { required: true },
    deviceTypeId: { required: true, number: true },
    price: { required: true, number: true },
  };
}
