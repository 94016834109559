import { IPagingFilter, PagedQueryResult } from "@frui.ts/data";
import IListResponse from "data/listResponse";
import { RepositoryBase } from "../repositoryBase";
import PaymentTerminal, { PaymentTerminalEvent } from "manualEntities/paymentTerminal";
import { Events } from "services/eventBus";
import PaymentTerminalImportStatusResponseDto from "../../entities/paymentTerminalImportStatusResponseDto";
import { plainToClass } from "class-transformer";
import { PaymentTerminalImportType } from "viewModels/paymentTerminals/paymentTerminalsImportViewModel";

export default class PaymentTerminalRepository extends RepositoryBase {
  getPaymentTerminals(paging: IPagingFilter, filter: any): Promise<PagedQueryResult<PaymentTerminal>> {
    const params = RepositoryBase.createListRequestParams(filter, paging);
    return this.callApi(api =>
      api.all("payment_terminals").get<IListResponse<PaymentTerminal>>(params).then(RepositoryBase.convertListResponse)
    );
  }

  getPaymentTerminal(id: number): Promise<PaymentTerminal> {
    return this.callApi(api => api.one("payment_terminals", id).get());
  }

  changeStatus(id: number, event: PaymentTerminalEvent, custId?: number): Promise<boolean> {
    return this.callApi(
      api =>
        api.one("payment_terminals", id).all("change_status").post({
          event,
          cust_id: custId,
        }),
      Events.PaymentTerminals.Changed
    );
  }

  import(file: File, type: PaymentTerminalImportType): Promise<PaymentTerminalImportStatusResponseDto> {
    return this.callApi(
      api => api.all("payment_terminals").all("import").one("type", type).postData(file),
      Events.PaymentTerminals.Imported
    ).then(x => plainToClass(PaymentTerminalImportStatusResponseDto, x.json()));
  }
}
