import { Input } from "@frui.ts/bootstrap";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React from "react";
import { Modal, Form, Col, Button } from "react-bootstrap";
import ParcelBoxDetailViewModel from "viewModels/parcelBox/parcelBoxDetailViewModel";

const parcelBoxDetailAssignView: ViewComponent<ParcelBoxDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute: ta } = vm.localizationService;

  if (!vm.isAssignFormVisible) {
    return null;
  }

  return (
    <Modal centered={true} show={true} onHide={vm.closeAssignForm}>
      <Form onSubmit={preventDefault(vm.updateBoxCustomer)}>
        <Modal.Header closeButton>
          <Modal.Title>{tg("parcel_box.events.assign_to_ps.button")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tg("parcel_box.events.assign_to_ps.body")}
          <br />
          <br />
          <Form.Row>
            <Form.Group as={Col} md={8} className="required">
              <Form.Label>{ta("parcel_shop", "cust_id")}</Form.Label>
              <Input target={vm} property="custId" type="number" />
            </Form.Group>
          </Form.Row>
          <LoadingOverlay loading={vm.busyWatcher.isBusy} />
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button type="submit" disabled={!vm.custId}>
            {tg("confirm_dialog.confirm")}
          </Button>
          <Button onClick={vm.closeAssignForm} variant="outline-secondary">
            {tg("confirm_dialog.cancel")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});

export default registerView(parcelBoxDetailAssignView, ParcelBoxDetailViewModel, "assign");
