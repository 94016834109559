import { bound } from "@frui.ts/helpers";
import { Router, watchBusy } from "@frui.ts/screens";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import { AddressDisplayModel } from "entities/address";
import { ContactDisplayModel } from "entities/contact";
import { OpeningHoursDisplayModel } from "entities/openingHourDto";
import { VacationDisplayModel } from "entities/vacation";
import { interfaces } from "inversify";
import ParcelShopStatus from "manualEntities/parcelShopStatus";
import { observable, runInAction } from "mobx";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import EventBus from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService from "services/notificationService";
import SecurityService from "services/securityService";
import AccessControlViewModel from "./accessControlViewModel";
import EditShopViewModel from "./edit/editShopViewModel";
import ShopInvoicingViewModel from "./invoicing/shopInvoicingViewModel";
import AccessPointViewModel from "./accessPointViewModel";
import WarehouseViewModel from "./warehouse/warehouseViewModel";
import ParcelShop from "../../../manualEntities/parcelShop";
import AttachmentsRepository from "data/repositories/attachmentsRepository";
import EnumRepository from "data/repositories/enumRepository";
import UsersRepository from "data/repositories/usersRepository";
import ActivityDetailViewModel from "./activity/activityDetailViewModel";
import UserContext from "services/userContext";

@Router.registerRoute({ name: Router.Self, route: ":id", children: [EditShopViewModel] })
export default class ShopDetailViewModel extends AccessPointViewModel<ParcelShop> {
  @observable contacts: ContactDisplayModel[];
  @observable addresses: AddressDisplayModel[];
  @observable openingHours: OpeningHoursDisplayModel[];
  @observable vacations: VacationDisplayModel[];

  constructor(
    shopId: number,
    warehouseVMFactory: ReturnType<typeof WarehouseViewModel.Factory>,
    editVMFactory: ReturnType<typeof EditShopViewModel.Factory>,
    activityVMFactory: ReturnType<typeof ActivityDetailViewModel.Factory>,
    protected repository: ParcelShopRepository,
    localizationService: LocalizationService,
    enumService: EnumService,
    security: SecurityService,
    confirmationService: ConfirmationService,
    notifications: NotificationService,
    eventBus: EventBus,
    private shopInvoicingVMFactory: ReturnType<typeof ShopInvoicingViewModel.Factory>,
    private accessControlVMFactory: ReturnType<typeof AccessControlViewModel.Factory>,
    attachmentsRepository: AttachmentsRepository,
    enumRepository: EnumRepository,
    usersRepository: UsersRepository,
    userContext: UserContext,
    router: Router
  ) {
    super(
      shopId,
      warehouseVMFactory,
      editVMFactory,
      activityVMFactory,
      repository,
      localizationService,
      enumService,
      security,
      confirmationService,
      notifications,
      eventBus,
      attachmentsRepository,
      enumRepository,
      usersRepository,
      userContext,
      router
    );
  }

  @watchBusy
  async loadDetail() {
    if (this.shopId) {
      const vacations = await this.repository.getVacations(this.shopId);

      runInAction(() => {
        this.vacations = vacations;
      });
    }
    return await super.loadDetail();
  }

  get canCreateMessage() {
    return this.security.isAllowed("create", "message", this.parcelShop);
  }

  get canOpenInvoicing() {
    return this.security.isAllowed("read", "invoicing", this.parcelShop);
  }

  @bound openInvoicing() {
    if (!this.canOpenInvoicing) {
      return;
    }

    const viewModel = this.shopInvoicingVMFactory(this.item);
    this.tryActivateChild(viewModel);
  }

  get canOpenWarehouse() {
    return this.parcelShop.status !== ParcelShopStatus.Disabled;
  }

  @bound openWarehouse() {
    const viewModel = this.warehouseVMFactory(this.item, false);
    this.tryActivateChild(viewModel);
  }

  get canOpenAccessControl() {
    return (
      this.parcelShop.status !== ParcelShopStatus.Disabled && this.security.isAllowed("read", "access_control", this.parcelShop)
    );
  }

  @bound openAccessControl() {
    if (!this.canOpenAccessControl) {
      return;
    }

    const viewModel = this.accessControlVMFactory(this.item);
    this.tryActivateChild(viewModel);
  }

  async findNavigationChild(name: string) {
    if (!this.item) {
      await this.onInitialize();
    }

    if (name === ShopInvoicingViewModel.DefaultNavigationName && this.canOpenInvoicing) {
      return this.shopInvoicingVMFactory(this.item);
    }

    if (name === WarehouseViewModel.DefaultNavigationName && this.canOpenWarehouse) {
      return this.warehouseVMFactory(this.item, false);
    }

    if (name === AccessControlViewModel.DefaultNavigationName && this.canOpenAccessControl) {
      return this.accessControlVMFactory(this.item);
    }

    return super.findNavigationChild(name);
  }

  get categoryItem() {
    return this.parcelShop.category_id ? this.enumService.value("ps_categories", this.parcelShop.category_id) : undefined;
  }

  static Factory({ container }: interfaces.Context) {
    return (id: number) => {
      return new ShopDetailViewModel(
        id,
        container.get(WarehouseViewModel.Factory),
        container.get(EditShopViewModel.Factory),
        container.get(ActivityDetailViewModel.Factory),
        container.get(ParcelShopRepository),
        container.get(LocalizationService),
        container.get(EnumService),
        container.get(SecurityService),
        container.get(ConfirmationService),
        container.get(NotificationService),
        container.get(EventBus),
        container.get(ShopInvoicingViewModel.Factory),
        container.get(AccessControlViewModel.Factory),
        container.get(AttachmentsRepository),
        container.get(EnumRepository),
        container.get(UsersRepository),
        container.get(UserContext),
        container.get(Router)
      );
    };
  }
}
