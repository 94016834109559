import PopperJS, { Data } from "popper.js";
import React, { CSSProperties } from "react";

interface OverlayProps {
  classNames: any;
  selectedDay: Date;
  children: React.ReactNode;
  containerRef: React.RefObject<Element>;
}

interface OverlayState {
  data?: Data;
}

export default class Overlay extends React.Component<OverlayProps, OverlayState> {
  state = {
    data: undefined,
  };

  private popupRef = React.createRef<HTMLDivElement>();
  private _poperInstance?: PopperJS;

  componentDidMount() {
    requestAnimationFrame(() => {
      // tslint:disable-next-line:no-commented-code
      const container = this.props.containerRef.current;
      const popper = this.popupRef.current;

      if (container && popper) {
        this._poperInstance = new PopperJS(container, popper, {
          placement: "bottom-start",
          onCreate: this.updatePosition,
          onUpdate: this.updatePosition,
          modifiers: {
            applyStyle: { enabled: false },
            offset: { offset: "-1", enabled: true },
            preventOverflow: { enabled: false },
          },
        });

        this._poperInstance.scheduleUpdate();
      }
    });
  }

  componentWillUnmount() {
    if (this._poperInstance) {
      this._poperInstance.destroy();
    }
  }

  updatePosition = (data: Data) => {
    this.setState({
      data,
    });
  };

  getPopperStyle(data: Data | undefined): CSSProperties {
    if (!data) {
      return {};
    }

    const left = Math.round(data.offsets.popper.left);
    const top = Math.round(data.offsets.popper.top);
    const transform = `translate3d(${left}px, ${top}px, 0)`;

    // Calculate TOP position
    let topMargin = 10;
    if (data.offsets.reference.top > data.offsets.popper.top) {
      topMargin *= -1;
    }

    return {
      position: (data.offsets.popper as any).position, // TODO is this intentional?
      transform,
      WebkitTransform: transform,
      top: topMargin, // This is size of button
      left: 0,
      visibility: "visible",
      display: "flex",
      opacity: 1,
    };
  }

  render() {
    const { classNames, children, containerRef, selectedDay, ...other } = this.props;

    return (
      <div ref={this.popupRef} style={this.getPopperStyle(this.state.data)} className={classNames.overlay} {...other}>
        {children}
      </div>
    );
  }
}
