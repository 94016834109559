import { observer } from "mobx-react-lite";
import React from "react";
import BootstrapTable, { BootstrapTableProps } from "react-bootstrap-table-next";

import "./table.scss";

export interface TableProps extends BootstrapTableProps {
  clickable?: boolean;
  rowClasses?: (row: any, rowIndex: number) => any;
}

const Table = observer((props: TableProps) => {
  const { clickable, ...rest } = props;

  return (
    <BootstrapTable
      striped={true}
      bordered={false}
      bootstrap4={true}
      noDataIndication={() => <>Žádné záznamy</>}
      classes={clickable ? "clickable" : ""}
      {...rest}
    />
  );
});

export default Table;
