import React, { FunctionComponent } from "react";
import { ConfigurationSectionProps } from "./types";
import { Col, Form, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";

const Device: FunctionComponent<ConfigurationSectionProps> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral: tg, formatCurrency: fc } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("parcel_shop", attribute);
  };

  const devices = vm.item?.scan_devices?.map(device => {
    return (
      <>
        <Row>
          <Form.Group as={Col} md={3}>
            <Form.Label>{ta("imei")}</Form.Label>
            <input className="form-control" disabled={true} value={device.imei} />
          </Form.Group>
          <Form.Group as={Col} md={3}>
            <Form.Label>{ta("device_type")}</Form.Label>
            <input className="form-control" disabled={true} value={vm.getDeviceTypeName(device.device_type_id)} />
          </Form.Group>
          <Form.Group as={Col} md={3}>
            <Form.Label>{translateAttribute("scan_device", "price")}</Form.Label>
            <input className="form-control" disabled={true} value={fc(device.price)} />
          </Form.Group>
          <Form.Group as={Col} md={3}>
            <a
              href={`#/scan_devices/${device.id}`}
              style={{ marginTop: "24px" }}
              target="_new"
              className="btn btn-outline-secondary">
              {tg("scan_device.manage_device")}
            </a>
          </Form.Group>
        </Row>
      </>
    );
  });

  return (
    <>
      <h3>{tg("parcel_shop.device")}</h3>
      {devices}
      <Form.Row>
        <Form.Group as={Col} md={9}>
          <a href={`#/scan_devices`} style={{ marginTop: "24px" }} target="_new" className="btn btn-light btn-block">
            &#43; {tg("new_parcel_shop.add_scan_device")}
          </a>
        </Form.Group>
      </Form.Row>
    </>
  );
});

export default Device;
