import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Scanner {
  /**
   * id
   */
  id!: number;

  /**
   * Device type
   */
  @observable
  @Expose({ name: "device_type" })
  deviceType!: string;

  @observable
  @Expose({ name: "daily_amortization_value" })
  dailyAmortizationValue!: number;

  @observable
  active!: boolean;

  @observable
  @Type(() => Date)
  @Expose({ name: "deleted_at" })
  deletedAt!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    deviceType: { required: true },
    dailyAmortizationValue: { required: true, number: true },
    active: { required: true },
    deletedAt: { required: true },
  };
}
