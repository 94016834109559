import React from "react";
import { ISelectItem } from "@frui.ts/views";
import RecurringPayment from "entities/recurringPayment";
import RecurringPaymentType from "entities/recurringPaymentType";
import AccessPointPartners from "models/enumerations/accessPointPartners";
import ParcelShop from "manualEntities/parcelShop";
import EnumService from "services/enum";
import iconSolved from "assets/icons/icon-solved.svg";
import iconCross from "assets/icons/icon-cross.svg";
import iconClock from "assets/icons/icon-clock.svg";
import { ActivityStatus } from "entities/activity";
import { models } from "models/shops/interface";

export const formatDepoCode = (depo?: models.Enum) => depo?.code.toString().padStart(2, "0") ?? "";

export const formatDepoName = (depo?: models.Enum) => (depo ? `${formatDepoCode(depo)} - ${depo.name}` : "");

export const formatSalesmanName = (salesman?: models.SalesmanEnum) =>
  salesman ? `${salesman.first_name ?? ""} ${salesman.last_name ?? ""}` : "";

export const subjectTypeSelectOption = (subjectType: models.Enum): ISelectItem => ({
  value: subjectType.id,
  label: subjectType.name,
});

export const accessPointTreeItem = (accessPoint: models.Enum): TreeItem => ({
  id: `${accessPoint.id}`,
  label: accessPoint.name,
});

export const psFilterStatusTreeItem = (accessPoint: models.Enum): TreeItem => ({
  id: `${accessPoint.id}`,
  label: accessPoint.name,
});

export const psCategoryTreeItem = (category: models.Enum): TreeItem => ({
  id: `${category.id}`,
  label: category.code,
});

export const accessPointSelectOption = (accessPoint: models.Enum): ISelectItem => ({
  value: accessPoint.id,
  label: accessPoint.name,
});

export const depoSelectOption = (depo: models.Enum): ISelectItem => ({
  value: depo.id,
  label: formatDepoName(depo),
});

export const bankSelectOption = (bank: models.Enum): ISelectItem => ({
  value: bank.code,
  // eslint-disable-next-line no-irregular-whitespace
  label: `${bank.code}　　${bank.name}`,
});

export const parcelBoxStateSelectOption = (state: models.Enum): ISelectItem => ({
  value: state.id,
  label: state.name,
});

export const crmRoleSelectOption = (role: models.Enum): ISelectItem => ({
  value: role.id,
  label: role.name,
});

export const salesmanSelectOption = (salesman: models.SalesmanEnum): ISelectItem => ({
  value: salesman.id,
  label: formatSalesmanName(salesman),
});

export const psCategorySelectOption = (category: models.Enum): ISelectItem => ({
  value: category.id,
  label: category.code,
});

export const pbIncomingCycleOption = (cycle: models.Enum): ISelectItem => ({
  value: cycle.id,
  label: cycle.name,
});

export const padNumber = (val: number, numbers = 2, pad = 0) => {
  return val.toString().padStart(numbers, `${pad}`);
};

export function formatMonthYear(month: number, year: number) {
  return `${month.toString().padStart(2, "0")}\u200A/\u200A${year}`; // hair-space
}

export function formatTime(time?: string) {
  if (time === undefined) {
    return "?";
  }

  const parts = time.split(":");

  if (parts.length >= 2) {
    return `${parts[0]}:${parts[1]}`;
  } else if (parts.length) {
    return `${parts[0]}:00`;
  } else {
    return "00:00";
  }
}

export function formatBankAccount(prefix?: string, number?: string, code?: string): string {
  if (!number || !code) {
    return "";
  }

  if (!prefix) {
    return `${number} / ${code}`;
  }

  return `${prefix} - ${number} / ${code}`;
}

export const scrollTop = (behavior: ScrollBehavior = "smooth") => {
  window.scrollTo({ top: 0, left: 0, behavior });
};

export const formatDate = (isoDate: string | Date, withTime = false) => {
  const date = typeof isoDate === "string" ? new Date(isoDate) : isoDate;
  let time = "";

  if (withTime) {
    time = ` ${padNumber(date.getHours())}:${padNumber(date.getMinutes())}`;
  }

  return `${padNumber(date.getDate())}.${padNumber(date.getMonth() + 1)}.${date.getFullYear()}${time}`;
};

export const isDate = (value: any): boolean => {
  return value instanceof Date && !isNaN(value.valueOf());
};

export const getSortedPaymentsByType = (payments: RecurringPayment[], types: RecurringPaymentType[]) => {
  return payments
    .filter(item => types.includes(item.type))
    .sort((left, right) => right.createdAt.getTime() - left.createdAt.getTime());
};

export const formatCategory = (category?: models.Enum) => {
  if (!category) {
    return null;
  }

  return (
    <div
      key={`category-${category.id}`}
      style={{
        margin: "0px 0.25rem",
        display: "inline-flex",
        backgroundColor: category.color,
        width: 28,
        height: 28,
        borderRadius: 8,
        border: "solid 1px #c2c6ca",
        justifyContent: "center",
        alignItems: "center",
      }}>
      {category.code}
    </div>
  );
};

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const snakeCaseToPascalCase = (str: string) =>
  str
    .split("_")
    .map(substr => substr.charAt(0).toUpperCase() + substr.slice(1))
    .join("");

export const isItParcelShopType = (ps: any, enumService: EnumService) => {
  if (ps instanceof ParcelShop) {
    return enumService.value("access_point_partners", ps.type_id)?.code == AccessPointPartners.PARCEL_SHOP;
  } else {
    return enumService.value("access_point_partners", ps.typeId)?.code == AccessPointPartners.PARCEL_SHOP_POTENTIAL;
  }
};

export const activityStatusIcon = (id: number) => {
  let icon;
  if (id === ActivityStatus.InProgress || id === ActivityStatus.Planned) {
    icon = iconClock;
  } else if (id === ActivityStatus.Resolved) {
    icon = iconSolved;
  } else {
    icon = iconCross;
  }
  return icon;
};
