import EnumRepository from "data/repositories/enumRepository";
import LocalizationService from "services/localizationService";
import { interfaces } from "inversify";
import { bound } from "@frui.ts/helpers";
import { computed, IObservableArray, observable } from "mobx";
import cloneDeep from "lodash/cloneDeep";
import { BusyWatcher, watchBusy } from "@frui.ts/screens";
import EventBus, { Events } from "services/eventBus";
import NotificationService, { SeverityLevel } from "services/notificationService";
import { attachAutomaticValidator, validateAll } from "@frui.ts/validation";
import Scanner from "entities/scanner";
import ScannerDto from "entities/scannerDto";
import { FilteredListViewModel } from "@frui.ts/datascreens";
import { EditableEnumType } from "services/enum";

export default class EnumerationScannerDetailViewModel extends FilteredListViewModel<Scanner> {
  @observable busyWatcher = new BusyWatcher();
  @observable editingItems: Map<Scanner, Scanner> = new Map<Scanner, Scanner>();
  itemsToAdd: IObservableArray<ScannerDto> = observable.array([]);
  itemsToDelete: IObservableArray<Scanner> = observable.array([]);

  @computed
  get noItems() {
    return this.items.length === 0 && this.itemsToAdd.length === 0 && !this.busyWatcher.isBusy;
  }

  @computed
  get hasChanges() {
    return this.editingItems.size !== 0 || this.itemsToAdd.length !== 0 || this.itemsToDelete.length !== 0;
  }

  constructor(
    private enumType: EditableEnumType,
    private repository: EnumRepository,
    private localizationService: LocalizationService,
    private eventBus: EventBus,
    private notificationService: NotificationService
  ) {
    super();
    this.navigationName = enumType;
    this.items = [];
    this.name = this.translate(this.enumType);
    this.pagingFilter = {
      limit: 1000,
      offset: 0,
    };
  }

  protected onInitialize(): Promise<any> | void {
    this.loadData();
    return super.onInitialize();
  }

  @watchBusy
  async loadData() {
    const response = await this.repository.getAllScanners(this.enumType, this.pagingFilter, true);
    this.setData(response);
  }

  @bound
  onEditItemClick(item: Scanner) {
    this.editingItems.set(item, attachAutomaticValidator(cloneDeep(item), ScannerDto.ValidationRules));
  }

  @bound
  onCancelEditItemClick(item: Scanner) {
    this.editingItems.delete(item);
  }

  @bound
  onDeleteItemClick(item: Scanner) {
    this.itemsToDelete.push(item);

    const indexOfItemToDelete = this.items.indexOf(item);
    if (~indexOfItemToDelete) {
      this.items.splice(indexOfItemToDelete, 1);
    }
  }

  @bound
  onRemoveNewClick(item: ScannerDto) {
    const index = this.itemsToAdd.indexOf(item);

    if (~index) {
      this.itemsToAdd.splice(index, 1);
    }
  }

  @bound
  onItemAdd() {
    const newScanner = new ScannerDto();
    this.itemsToAdd.push(attachAutomaticValidator(newScanner, ScannerDto.ValidationRules));
  }

  @bound
  onHide() {
    return this.requestClose();
  }

  @bound
  @watchBusy
  async onSubmit() {
    const itemsToEdit = Array.from(this.editingItems.values());
    const itemsToAdd = this.itemsToAdd;

    if (!validateAll([...itemsToAdd, ...itemsToEdit])) {
      return;
    }

    const updatePromises = itemsToEdit.map(item => this.repository.saveScanner(this.enumType, item));
    const createPromises = itemsToAdd
      .filter(item => !!item.dailyAmortizationValue && !!item.deviceType)
      .map(item => this.repository.createScanner(this.enumType, item));
    const deletePromises = this.itemsToDelete.map(item => this.repository.delete(this.enumType, item));

    try {
      const [savedItems, deletedItems] = await Promise.all([
        Promise.all([...updatePromises, ...createPromises]),
        Promise.all(deletePromises),
      ]);
      this.editingItems.clear();
      this.itemsToAdd.clear();

      savedItems.forEach(savedItem => {
        const indexOfItemToReplace = this.items.findIndex(item => savedItem.id === item.id);

        if (~indexOfItemToReplace) {
          this.items[indexOfItemToReplace] = savedItem;
        } else {
          this.items.push(savedItem);
        }
      });

      if (updatePromises.length || createPromises.length || deletePromises.length) {
        this.eventBus.publish(Events.Enumerations.Changed);
      }
    } catch (e) {
      this.notificationService.addNotification(this.translate("some_changes_not_saved"), SeverityLevel.critical, "enumerations");
      this.requestClose();
    }
  }

  translate(code: string) {
    return this.localizationService.translateGeneral(`enumerations.${code}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (enumType: EditableEnumType) =>
      new EnumerationScannerDetailViewModel(
        enumType,
        container.get(EnumRepository),
        container.get(LocalizationService),
        container.get(EventBus),
        container.get(NotificationService)
      );
  }
}
