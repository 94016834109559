import DateInput from "controls/dateInput";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AdditionalInformationSectionProps } from "./types";

const ContractSign: FunctionComponent<AdditionalInformationSectionProps> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral: tg } = vm.localizationService;
  const ta = (code: string) => translateAttribute("additional_information", code);

  return (
    <>
      <h3>{ta("contract_sign_date")}</h3>
      {!!vm.item.contract_sign_date && (
        <Row>
          <Form.Group as={Col} md={3}>
            <Form.Label>{ta("date")}</Form.Label>
            <DateInput target={vm.item} property="contract_sign_date" />
          </Form.Group>
          <Button onClick={vm.removeSignDate} variant="link">
            &#215; {tg("new_parcel_shop.remove_row")}
          </Button>
        </Row>
      )}
      {!vm.item.contract_sign_date && (
        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Button onClick={vm.addSignDate} variant="light" block>
              &#43; {tg("new_parcel_shop.add_contract_sign_date")}
            </Button>
          </Form.Group>
        </Form.Row>
      )}
    </>
  );
});

export default ContractSign;
