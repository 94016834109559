import { ScreenBase } from "@frui.ts/screens";
import { computed } from "mobx";
import { SeverityLevel } from "services/notificationService";

export default class AlertViewModel extends ScreenBase {
  constructor(public message: string, public severity: SeverityLevel, public onClose?: () => void) {
    super();
  }

  @computed
  get variant() {
    switch (this.severity) {
      case SeverityLevel.critical:
        return "danger";

      case SeverityLevel.important:
        return "warning";

      case SeverityLevel.success:
        return "success";

      case SeverityLevel.information:
      default:
        return "info";
    }
  }
}
