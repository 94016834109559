import { classToPlain, ClassTransformOptions, serialize } from "class-transformer";

const serializeOptions: ClassTransformOptions = {
  excludePrefixes: ["_"],
};

export function serializeEntity(value: any): string {
  return serialize(value, serializeOptions);
}

export function entityToPlain(entity: any) {
  return classToPlain(entity, serializeOptions);
}

export function entityToFormData(item: any) {
  const payload = entityToPlain(item);

  const data = new FormData();
  for (const [key, value] of Object.entries(payload)) {
    if (value !== undefined && value !== null) {
      data.append(key, value);
    }
  }

  return data;
}
