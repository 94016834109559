import { registerView, View, ViewComponent } from "@frui.ts/views";
import HeaderRow from "controls/headerRow";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React from "react";
import { Badge, Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import PotentialDetailViewModel from "viewModels/shops/detail/potentialDetailViewModel";
import Activities from "./activities";
import AdditionalInformation from "./additionalInformation";
import Addresses from "./addresses";
import Configuration from "./configuration";
import Contacts from "./contacts";
import Informations from "./informations";
import OpeningHours from "./openingHours";
import Opportunities from "./opportunities";
import "./shopDetailView.scss";

const PotentialDetailView: ViewComponent<PotentialDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute } = vm.localizationService;

  const ta = (key: string) => translateAttribute("parcel_shop", key);

  if (!vm.parcelShop) {
    return <LoadingOverlay loading />;
  } else if (vm.activeChild) {
    return <View vm={vm.activeChild} fallbackMode="message" />;
  } else {
    return (
      <>
        <HeaderRow title={vm.parcelShop.name}>
          <Badge className="align-self-center">{tg("potential")}</Badge>
        </HeaderRow>

        <ButtonToolbar>
          <Button variant="outline-secondary" onClick={vm.openEdit} disabled={!vm.canEdit}>
            {ta("edit")}
          </Button>
          <Button variant="outline-success" onClick={vm.onCreateItemFromPotential} disabled={!vm.canEdit}>
            {tg(`parcel_shop.upgrade_potential.${vm.isParcelShopType ? "to_parcel_shop" : "to_parcel_box"}`)}
          </Button>
        </ButtonToolbar>

        <Informations vm={vm} />
        <Contacts vm={vm} />
        <Addresses vm={vm} />

        <Row>
          <Col>
            <Configuration vm={vm} />
          </Col>
          <Col>
            <OpeningHours vm={vm} />
          </Col>
        </Row>
        <AdditionalInformation vm={vm} />
        <Activities vm={vm} />
        <Opportunities vm={vm} />
        <View vm={vm.childDialog} />
      </>
    );
  }
});

export default registerView(PotentialDetailView, PotentialDetailViewModel);
