import { registerView, View, ViewComponent } from "@frui.ts/views";
import iconLogout from "assets/icons/icon-logout.svg";
import logo from "assets/logo.svg";
import Breadcrumb from "controls/breadcrumb";
import flattenDepth from "lodash/flattenDepth";
import { observer, Observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { Badge, Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import RootViewModel from "../viewModels/rootViewModel";

const navMap = [
  {
    title: "Zákazníci",
    vmNames: ["shops", "newPickupPoint", "subject", "payment_terminal", "parcel_box", "scan_devices", "opportunities"],
  },
  {
    title: "Komunikace",
    vmNames: ["messages"],
  },
  {
    title: "Nastavení",
    vmNames: ["settings", "enumerations", "users"],
  },
];

const rootView: ViewComponent<RootViewModel> = observer(({ vm }) => {
  const messageCountBadge =
    vm.unreadMessageService.unreadMessages > 0 ? (
      <Badge variant="danger" className="messages">
        {vm.unreadMessageService.unreadMessages}
      </Badge>
    ) : undefined;

  const appMenu = useMemo(() => {
    const nonMainVmNames = flattenDepth(navMap.map(c => c.vmNames));
    const mainVMs = vm.children.filter(x => !nonMainVmNames.includes(x.navigationName));

    return (
      <>
        {mainVMs.map(x => (
          <Nav.Link key={x.navigationName} active={x === vm.activeChild} onClick={() => vm.tryActivateChild(x)}>
            {x.name} {x.navigationName === "messages" && messageCountBadge}
          </Nav.Link>
        ))}
        {navMap.map(c => {
          const vms = vm.children.filter(x => c.vmNames.includes(x.navigationName));
          if (vms.length > 1) {
            return (
              <NavDropdown title={c.title} id="basic-nav-dropdown" active={!!vms.find(x => x === vm.activeChild)}>
                {vms.map(x => (
                  <NavDropdown.Item onClick={() => vm.tryActivateChild(x)} key={x.navigationName} active={x === vm.activeChild}>
                    {x.name} {x.navigationName === "messages" && messageCountBadge}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            );
          } else {
            return vms.map(x => (
              <Nav.Link key={x.navigationName} active={x === vm.activeChild} onClick={() => vm.tryActivateChild(x)}>
                {x.name} {x.navigationName === "messages" && messageCountBadge}
              </Nav.Link>
            ));
          }
        })}
      </>
    );
  }, [vm.children, vm.activeChild, vm.unreadMessageService.unreadMessages]);

  return (
    <div className={process.env.REACT_APP_TEST_VERSION === "1" ? "show-test-label" : ""}>
      <div className="test-label">Nacházíte se na testovacím prostředí.</div>
      <Observer>
        {() => (
          <Navbar as="header" bg="white" variant="light" fixed="top">
            <Container>
              <Navbar.Brand>
                <img className="d-block" src={logo} alt="PPL" />
              </Navbar.Brand>
              <Nav className="mr-auto">{appMenu}</Nav>
              <div className="navbar-right">
                <Navbar.Text>
                  <div className="d-flex justify-content-end">
                    <div>
                      <span className="text-dark">{vm.userContext.userEmail}</span>
                      <br />
                      <span className="font-weight-light text-muted">{vm.userContext.userRole}</span>
                    </div>
                    <Button className="button-icon button-logout" variant="outline-secondary" size="sm" onClick={vm.doLogout}>
                      <img src={iconLogout} alt="Odhlásit se" />
                    </Button>
                  </div>
                </Navbar.Text>
              </div>
            </Container>
          </Navbar>
        )}
      </Observer>
      <Breadcrumb className="container" rootVM={vm} />
      <Container id="root-content">
        <Observer>
          {() => (
            <div className="alert-bar">
              {vm.alertsService.items.map((item, i) => (
                <View key={i} vm={item} useLifecycle />
              ))}
            </div>
          )}
        </Observer>
        <Observer>{() => <View vm={vm.activeChild} />}</Observer>
        <Observer>
          {() => (
            <>
              {vm.confirmationService.dialogs.map((dialog, i) => (
                <View key={i} vm={dialog} useLifecycle />
              ))}
            </>
          )}
        </Observer>
      </Container>
      <Navbar as="footer" variant="light" className="footer">
        <Container>
          <Navbar.Text>{new Date().getFullYear()} © PPL CZ s.r.o.</Navbar.Text>
          <Navbar.Text className="text-right">
            <div>
              <a href={vm.docUrl}>Dokumentace</a>
            </div>
            <div>
              <span>Verze aplikace: {vm.applicationVersion}</span>
            </div>
          </Navbar.Text>
        </Container>
      </Navbar>
    </div>
  );
});
export default registerView(rootView, RootViewModel);
