import { ViewComponent } from "@frui.ts/views";
import BoxDetailViewModel from "viewModels/shops/detail/boxDetailViewModel";
import { observer } from "mobx-react-lite";
import Table from "controls/table";
import React from "react";
import ShopDetailViewModel from "viewModels/shops/detail/shopDetailViewModel";
import PotentialDetailViewModel from "../../../viewModels/shops/detail/potentialDetailViewModel";
import HeaderRow from "../../../controls/headerRow";
import { Button } from "react-bootstrap";

import iconEdit from "../../../assets/icons/icon-external-link.svg";
import Opportunity from "entities/opportunity";
import { formatDate } from "utils/helpers";

const Opportunities: ViewComponent<ShopDetailViewModel | BoxDetailViewModel | PotentialDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute } = vm.localizationService;
  const ta = (key: string) => translateAttribute("opportunity", key);

  return (
    <>
      <HeaderRow title={tg("parcel_shop.opportunities")} level={3}>
        <div className="d-flex justify-content-end align-items-center">
          <Button onClick={vm.openNewOpportunity} disabled={!vm.canEdit}>
            {tg("opportunity.add")}
          </Button>
        </div>
      </HeaderRow>
      <Table
        data={(vm.opportunities || []).slice()}
        keyField="id"
        columns={[
          {
            dataField: "enumOpportunityId",
            text: ta("enum_opportunity_id"),
            title: true,
            formatter: (_cell, row: Opportunity) => {
              return vm.getOpportunityType(row.enumOpportunityId);
            },
          },
          {
            dataField: "author_id",
            text: ta("author_id"),
            title: true,
            formatter: (_cell, row: Opportunity) => {
              return vm.getUserName(row.authorId);
            },
          },
          {
            dataField: "created_at",
            text: ta("created_at"),
            title: true,
            formatter: (_cell, row: Opportunity) => {
              return row.createdAt ? formatDate(row.createdAt) : "";
            },
          },
          {
            dataField: "enum_status_id",
            text: ta("enum_status_id"),
            title: true,
            formatter: (_cell, row: Opportunity) => {
              return vm.getOpportunityStatus(row.enumStatusId);
            },
          },
          {
            dataField: "edit",
            isDummyField: true,
            text: ta("detail"),
            headerStyle: {
              width: "100px",
              textAlign: "center",
            },
            align: "center",
            formatter: (_cell, row: Opportunity) => {
              return (
                <a href={`#/opportunities/${row.id}`} className="noRowClick button-icon btn btn-outline-secondary btn-sm">
                  <img src={iconEdit} alt={ta("detail")} />
                </a>
              );
            },
          },
        ]}
      />
      <Button onClick={vm.toggleOpportunityHistory} variant="outline-secondary" className="align-center">
        {vm.showOpportunityHistory ? ta("hide_history") : ta("show_history")}
      </Button>
    </>
  );
});

export default Opportunities;
