import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Attachment {
  /**
   * id
   */
  id!: number;

  /**
   * ps_id
   */
  @observable
  @Expose({ name: "ps_id" })
  psId!: number;

  /**
   * entity_type
   */
  @observable
  @Expose({ name: "entity_type" })
  entityType!: number;

  /**
   * document type
   */
  @observable
  @Expose({ name: "document_type_id" })
  documentTypeId!: number;

  /**
   * original filename
   */
  @observable
  @Expose({ name: "original_filename" })
  originalFilename!: string;

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    psId: { required: true, number: true },
    entityType: { required: true, number: true },
    documentTypeId: { required: true, number: true },
    originalFilename: { required: true },
    createdAt: { required: true },
    updatedAt: { required: true },
  };
}
