import { FilteredListViewModel } from "@frui.ts/datascreens";
import { BusyWatcher, ScreenBase } from "@frui.ts/screens";
import { EventSubscription } from "services/eventBus";
import { AutomaticDirtyWatcher } from "@frui.ts/dirtycheck";
import { applySort } from "@frui.ts/data";
import { action } from "mobx";
import type { DataColumn } from "controls/tableHelpers";

export default abstract class ListViewModelBase<TEntity, TFilter, TDetail extends ScreenBase> extends FilteredListViewModel<
  TEntity,
  TFilter,
  TDetail
> {
  readonly busyWatcher = new BusyWatcher();
  protected eventSubscriptions = [] as EventSubscription[];

  protected filterChangedWatcher: AutomaticDirtyWatcher<TFilter>;

  get isFilterChanged() {
    return this.filterChangedWatcher.isDirty;
  }

  constructor() {
    super();

    this.filterChangedWatcher = new AutomaticDirtyWatcher(this.filter, false);
  }

  abstract openDetail(item: TEntity): void | Promise<any>;

  protected onDeactivate(close: boolean) {
    if (close) {
      const toUnsubscribe = this.eventSubscriptions;
      this.eventSubscriptions = [];
      toUnsubscribe.forEach(x => x.unsubscribe());
    }

    return super.onDeactivate(close);
  }

  @action.bound sortByColumn(column: DataColumn<TEntity>) {
    if (typeof column.property === "string") {
      applySort(this.pagingFilter, column.property);
      return this.loadData();
    }
  }
}
