import { ScreenBase } from "@frui.ts/screens";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import RecurringPayment from "entities/recurringPayment";
import { RecurringPaymentTypes } from "entities/recurringPaymentType";
import { interfaces } from "inversify";
import { observable, runInAction } from "mobx";
import EnumService from "services/enum";
import LocalizationService from "services/localizationService";
import { formatMonthYear, getSortedPaymentsByType } from "utils/helpers";

export default class RecurringPaymentsViewModel extends ScreenBase {
  @observable items: RecurringPayment[] = [];

  constructor(
    private shopId: number,
    public localization: LocalizationService,
    private repository: ParcelShopRepository,
    private enumService: EnumService
  ) {
    super();

    this.name = localization.translateGeneral("parcel_box.invoicing.recurring_payments");
  }

  onInitialize() {
    return this.loadData();
  }

  async loadData() {
    const result = await this.repository.getRecurringPayments(this.shopId);
    runInAction(() => {
      this.items = result ?? [];
    });
  }

  get depositHistory() {
    return getSortedPaymentsByType(this.items, [RecurringPaymentTypes.DEPOSIT, RecurringPaymentTypes.ELECTRICITY_STOPPED]);
  }

  get rentHistory() {
    return getSortedPaymentsByType(this.items, [RecurringPaymentTypes.RENT, RecurringPaymentTypes.RENT_STOPPED]);
  }

  getInvoicingCycleName(payment: RecurringPayment) {
    if ([RecurringPaymentTypes.ELECTRICITY_STOPPED, RecurringPaymentTypes.RENT_STOPPED].includes(payment.type)) {
      return this.localization.translateAttribute("recurring_payment", "stopped");
    } else {
      const item = this.enumService.value("pb_invoicing_cycles", payment.pbInvoicingCycle);
      if (item) {
        return item.name;
      }
    }

    return undefined;
  }

  getValidityName(payment: RecurringPayment) {
    if ([RecurringPaymentTypes.ELECTRICITY_STOPPED, RecurringPaymentTypes.RENT_STOPPED].includes(payment.type)) {
      return formatMonthYear(payment.month ?? 0, payment.year ?? 0);
    }

    return "";
  }

  static Factory({ container }: interfaces.Context) {
    return (shopId: number) =>
      new RecurringPaymentsViewModel(
        shopId,
        container.get(LocalizationService),
        container.get(ParcelShopRepository),
        container.get(EnumService)
      );
  }
}
