import { Type } from "class-transformer";
import { merge } from "lodash";
import { observable } from "mobx";
import RecipientDto from "./recipientDto";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class MessageGroupRecipientCreateDto {
  @observable
  name!: string;

  @observable
  @Type(() => RecipientDto)
  recipients!: RecipientDto[];

  static ValidationRules = {
    name: { required: true },
    recipients: { required: true },
  };
}

merge(MessageGroupRecipientCreateDto.ValidationRules, {
  name: {
    maxLength: 200,
  },
});
