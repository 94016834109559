import { IPagingFilter, PagedQueryResult } from "@frui.ts/data";
import ScanDevice, { ScanDeviceEvent } from "manualEntities/scanDevice";
import ScanDeviceDetailDto from "entities/scanDeviceDetailDto";
import { Events } from "services/eventBus";
import { RepositoryBase } from "../repositoryBase";
import ScanDeviceImportStatusResponseDto from "entities/scanDeviceImportStatusResponseDto";
import { plainToClass } from "class-transformer";

export default class ScanDevicesRepository extends RepositoryBase {
  getScanDevices(paging: IPagingFilter, filter: any): Promise<PagedQueryResult<ScanDevice>> {
    return this.callApi(api => api.all("scan_devices").getPagedEntities(ScanDevice, paging, filter));
  }

  getScanDevice(id: number): Promise<ScanDevice> {
    return this.callApi(api => api.one("scan_devices", id).getEntity(ScanDevice));
  }

  createScanDevice(payload: ScanDevice) {
    return this.callApi(api => api.all("scan_devices").postEntity(payload), Events.ScanDevices.Changed);
  }

  updateScanDevice(id: number, payload: ScanDeviceDetailDto) {
    return this.callApi(api => api.one("scan_devices", id).putEntity(payload), Events.ScanDevices.Changed);
  }

  deleteScanDevice(id: number) {
    return this.callApi(api => api.one("scan_devices", id).delete(), Events.ScanDevices.Changed);
  }

  changeStatus(id: number, event: ScanDeviceEvent, custId?: number): Promise<boolean> {
    return this.callApi(
      api =>
        api.one("scan_devices", id).all("change_status").post({
          event,
          cust_id: custId,
        }),
      Events.ScanDevices.Changed
    );
  }

  import(file: File): Promise<ScanDeviceImportStatusResponseDto> {
    return this.callApi(api => api.all("scan_devices").all("import").postData(file), Events.ScanDevices.Imported).then(x =>
      plainToClass(ScanDeviceImportStatusResponseDto, x.json())
    );
  }
}
