import React, { FunctionComponent } from "react";
import { ConfigurationSectionProps } from "../types";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import classnames from "classnames";
import { Input } from "@frui.ts/bootstrap";
import { observer } from "mobx-react-lite";
import Visibility from "./visibility";
import DHLExpress from "./dhlExpress";
import NetworkPartner from "./networkPartner";
import PaymentTerminal from "./paymentTerminal";
import BoxStation from "./boxStation";

const GeneralInfo: FunctionComponent<ConfigurationSectionProps> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral: tg } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("parcel_shop", attribute);
  };
  // Info message
  let message: string | undefined;

  if (vm.item?.capacity_watcher && vm.item?.capacity_watcher_active) {
    message = vm.item?.isActive ? "capacity_finder_disabled" : "capacity_disabled";
  } else if (vm.item?.vacation_watcher && vm.item?.vacation_watcher_active) {
    message = vm.item?.isActive ? "vacation_finder_disabled" : "vacation_disabled";
  }

  return (
    <>
      <h3>{tg("general")}</h3>

      <Row>
        <Form.Group as={Col} md={3} className={classnames({ required: !vm.isPotential })}>
          <Form.Label>{ta("latitude")}</Form.Label>
          <Input target={vm.item} property="latitude" placeholder={"49.960555"} type="number" />
        </Form.Group>
        <Form.Group as={Col} md={3} className={classnames({ required: !vm.isPotential })}>
          <Form.Label>{ta("longitude")}</Form.Label>
          <Input target={vm.item} property="longitude" placeholder={"14.619146"} type="number" />
        </Form.Group>
      </Row>

      {vm.canChooseVisibility && <Visibility vm={vm} />}

      {message && (
        <div className="infolabel">
          <svg width="24" height="24" viewBox="0 0 24 24">
            <g fill="#677078">
              <path d="M12 6q1.641 0 2.813 1.172t1.172 2.813q0 1.266-1.5 2.602t-1.5 2.414h-1.969q0-1.078 0.469-1.852t1.031-1.125 1.031-0.867 0.469-1.172q0-0.797-0.609-1.383t-1.406-0.586-1.406 0.586-0.609 1.383h-1.969q0-1.641 1.172-2.813t2.813-1.172zM12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM11.016 18v-2.016h1.969v2.016h-1.969z"></path>
            </g>
          </svg>
          {tg(`parcel_shop.${message}`)}
        </div>
      )}
      {vm.canChooseDhlExpress && <DHLExpress vm={vm} />}
      {vm.canChooseNetworkPartner && <NetworkPartner vm={vm} />}
      {vm.canEditPaymentTerminalsSection && <PaymentTerminal vm={vm} />}
      {vm.canChooseBoxStation && <BoxStation vm={vm} />}

      <Modal centered={true} show={vm.boxStationWarningShow} onHide={vm.hideBoxStationWarning}>
        <Modal.Header closeButton>
          <Modal.Title>{tg("parcel_box.warning_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {vm.canEditPaymentTerminal ? (
            <>
              {tg("parcel_box.warning")}
              <a href={`#/parcel_box`}>{vm.localizationService.translateModel("parcel_box_station", 2)}</a>
            </>
          ) : (
            tg("parcel_box.warning_user")
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button onClick={vm.hideBoxStationWarning}>{tg("confirm_dialog.ok")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default GeneralInfo;
