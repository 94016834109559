import { observable, action } from "mobx";
import EventBus, { Events } from "./eventBus";
import MessagesRepository from "data/repositories/messagesRepository";
import UserContext from "./userContext";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFunc = () => {};

export default class UnreadMessageService {
  @observable unreadMessages = 0;
  private interval?: NodeJS.Timeout;

  constructor(private eventBus: EventBus, private messagesRepository: MessagesRepository, private userContext: UserContext) {
    this.subscribeEventBus();
  }

  subscribeEventBus() {
    this.eventBus.subscribe(Events.Messages.Changed, message => {
      this.refreshCount();
    });
  }

  enableChecking() {
    this.interval = setInterval(this.refreshCount, 20000);
    this.refreshCount();
  }

  disableChecking() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  private refreshCount = () => {
    if (this.userContext.isLogged) {
      void this.messagesRepository
        .unreadMessages()
        .then(
          action(resp => {
            this.unreadMessages = resp;
          })
        )
        .catch(emptyFunc);
    }
  };
}
