import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import Parcel, { numberToChunks } from "entities/parcel";
import { interfaces } from "inversify";
import { groupBy, keys, sumBy } from "lodash";
import { action, observable, runInAction } from "mobx";
import ShopDetailContext from "models/shops/shopDetailContext";
import WarehouseContent from "models/shops/warehouseContent";
import ConfirmationService from "services/confirmationService";
import EventBus, { Events, EventSubscription } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import SecurityService from "services/securityService";
import WarehouseService, { ParcelStatus, ParcelPaymentMethod } from "services/warehouseService";
import ListViewModelBase from "viewModels/listViewModelBase";
import { EditShopNotificationScope } from "../types";
import StockTakingViewModel from "./stockTakingViewModel";
import ParcelShop from "../../../../manualEntities/parcelShop";

class WarehouseFilter {
  customerStatus: string[];
  customerStatusName?: string;

  driverStatus: string[];
  driverStatusName?: string;
}

export default class WarehouseViewModel extends ListViewModelBase<Parcel, WarehouseFilter, ScreenBase> {
  static DefaultNavigationName = "warehouse";

  busyWatcher = new BusyWatcher();
  protected eventSubscriptions = [] as EventSubscription[];
  @observable warehouse: WarehouseContent;
  @observable.shallow cash: Parcel[];
  @observable stockTakingViewModel: StockTakingViewModel;

  get parcelShop() {
    return this.context.shop;
  }

  get cashSums() {
    if (this.cash && this.cash.length) {
      const byCurrency = groupBy(this.cash, x => x.currency);
      return keys(byCurrency).map(currency => ({
        currency,
        total: sumBy(byCurrency[currency], x => x.cod || 0),
      }));
    } else {
      return [
        {
          currency: this.localizationService.translateGeneral("currency_code"),
          total: 0,
        },
      ];
    }
  }

  get canChangeParcelStatus() {
    return this.security.isAllowed("edit", "parcel");
  }

  openDetail(item: Parcel): void | Promise<any> {
    throw new Error("Method not implemented.");
  }

  constructor(
    private context: ShopDetailContext<ParcelShop>,
    public isParcelBox: boolean,
    private repository: ParcelShopRepository,
    private stockTakingViewModelFactory: ReturnType<typeof StockTakingViewModel.Factory>,
    private warehouseService: WarehouseService,
    public localizationService: LocalizationService,
    private security: SecurityService,
    private confirmationService: ConfirmationService,
    private notifications: NotificationService,
    private eventBus: EventBus
  ) {
    super();
    this.name = localizationService.translateGeneral("parcel_shop.warehouse.title");
    this.navigationName = WarehouseViewModel.DefaultNavigationName;
    this.stockTakingViewModel = this.stockTakingViewModelFactory(this.context);
  }

  @action.bound
  clearStatusFilterFor(
    property: "customerStatus" | "driverStatus",
    searchTextProperty: "customerStatusName" | "driverStatusName"
  ) {
    this.filter[property] = [];
    this.filter[searchTextProperty] = undefined;
    this.applyFilter();
  }

  onInitialize() {
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.ParcelShops.Parcels.Changed, this.loadData));
    return this.loadData();
  }

  @bound
  @watchBusy
  async loadData() {
    const [warehouse, cash] = await Promise.all([
      this.warehouseService.getWarehouseContent(this.parcelShop.id),
      this.repository.getCash(this.parcelShop.id),
    ]);

    runInAction(() => {
      this.warehouse = warehouse;
      this.cash = cash;
    });
  }

  protected onDeactivate(close: boolean) {
    if (close) {
      const toUnsubscribe = this.eventSubscriptions;
      this.eventSubscriptions = [];
      toUnsubscribe.forEach(x => x.unsubscribe());
    }

    return super.onDeactivate(close);
  }

  /**
   * Admin version 2.0
   *
   * @param parcel Parcel item
   * @param key Key for confirmations
   * @param status Status number
   * @param paymentMethod Payment method number
   */
  @action.bound async confirmChange(parcel: Parcel, key: string, status: ParcelStatus, paymentMethod?: ParcelPaymentMethod) {
    const tg = this.localizationService.translateGeneral;

    const localizationScope = `parcel_shop.warehouse.events.${key}`;
    const messageLocalizationKey = parcel.isGrouped && key !== "found" ? "body_grouped" : "body";

    // Show confirmation window
    const canContinue = await this.confirmationService.showConfirmation(
      tg(`${localizationScope}.${messageLocalizationKey}`)
        .replace(
          "%pid%",
          numberToChunks(parcel.extId)
            .map(x => x.join(""))
            .join(" ")
        )
        .replace(
          "%groupedParcelIds%",
          parcel.groupedParcelIds
            .map(parcelId =>
              numberToChunks(parcelId)
                .map(x => x.join(""))
                .join(" ")
            )
            .join(", ")
        ),
      tg(`${localizationScope}.title`),
      { variant: "primary", text: tg("confirm_dialog.confirm") },
      tg("parcel_shop.disable_shop.cancel")
    );

    if (canContinue) {
      await this.repository.changeParcelStatus(this.parcelShop.id, parcel.id, status, paymentMethod);

      this.notifications.addNotification(
        this.localizationService.translateGeneral("parcel_shop.warehouse.events.status_changed"),
        SeverityLevel.success,
        EditShopNotificationScope
      );
    }
  }

  protected resetFilterValues(filter: WarehouseFilter): void {
    filter.customerStatus = [];
    filter.customerStatusName = undefined;

    filter.driverStatus = [];
    filter.driverStatusName = undefined;
  }

  static Factory({ container }: interfaces.Context) {
    return (context: ShopDetailContext<ParcelShop>, isParcelBox: boolean) =>
      new WarehouseViewModel(
        context,
        isParcelBox,
        container.get(ParcelShopRepository),
        container.get(StockTakingViewModel.Factory),
        container.get(WarehouseService),
        container.get(LocalizationService),
        container.get(SecurityService),
        container.get(ConfirmationService),
        container.get(NotificationService),
        container.get(EventBus)
      );
  }
}
