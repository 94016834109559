import { ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import { Col, Form } from "react-bootstrap";
import FormLabel from "react-bootstrap/FormLabel";
import { Input, Select } from "@frui.ts/bootstrap";
import React from "react";
import { ViewModelWithBankAccount } from "viewModels/types";

const BankAccount: ViewComponent<ViewModelWithBankAccount> = observer(({ vm }) => {
  const { translateAttribute: ta } = vm.localizationService;

  return (
    <Form.Row>
      <Col md={2}>
        <Form.Group className="required">
          <FormLabel column={false}>{ta("subject", "bank_account")}</FormLabel>
          <Input target={vm.item} property="bankAccountPrefix" placeholder={ta("subject", "bank_account_prefix")} />
        </Form.Group>
      </Col>
      <div style={{ paddingTop: 32, color: "#677078", paddingLeft: 3, paddingRight: 3 }}>-</div>
      <Col md={3}>
        <Form.Group>
          <FormLabel column={false}>&nbsp;</FormLabel>
          <Input target={vm.item} property="bankAccountNumber" placeholder={ta("subject", "bank_account_number")} />
        </Form.Group>
      </Col>
      <div style={{ paddingTop: 32, color: "#677078", paddingLeft: 3, paddingRight: 3 }}>/</div>
      <Col md={1}>
        <Form.Group>
          <FormLabel column={false}>&nbsp;</FormLabel>
          <Select
            target={vm.item}
            property="bankAccountCode"
            items={vm.bankAccountCodes}
            allowEmpty={true}
            mode="key"
            keyProperty="value"
            textProperty="label"
            isNumeric={false}
          />
        </Form.Group>
      </Col>
    </Form.Row>
  );
});

export default BankAccount;
