import { registerView, ViewComponent } from "@frui.ts/views";
import LoadingOverlay from "controls/loadingOverlay";
import HeaderRow from "controls/headerRow";
import Table from "controls/table";
import Invoice, { InvoiceWithAttachment } from "entities/invoice";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button } from "react-bootstrap";
import { formatMonthYear } from "utils/helpers";
import InvoicesViewModel from "viewModels/shops/detail/invoicing/invoicesViewModel";

const invoicesView: ViewComponent<InvoicesViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute, formatDateTime, formatNumber } = vm.localization;
  const ta = (name: string) => translateAttribute("invoice", name);
  const taByType = (name: string) => translateAttribute(vm.isParcelBox ? "invoice_box" : "invoice", name);

  return (
    <>
      <HeaderRow title={vm.name} level={3} />

      <Table
        data={(vm.items || []).slice()}
        keyField="id"
        columns={[
          {
            dataField: "year",
            text: tg(`parcel_${vm.isParcelBox ? "box" : "shop"}.invoicing.invoicing_period`),
            formatter: (cell: any, row: Invoice) => formatMonthYear(row.month, row.year),
            title: (cell: any, row: Invoice) => formatMonthYear(row.month, row.year),
          },
          { dataField: "created_at", text: ta("created_at"), formatter: formatDateTime, title: formatDateTime },
          {
            dataField: "price",
            text: taByType("price"),
            align: "right",
            headerAlign: "right",
            formatter: (value: number) => (
              <>
                {formatNumber(value)} {tg("currency_code")}
              </>
            ),
            title: (value: number) => `${formatNumber(value)} ${tg("currency_code")}`,
          },
          {
            dataField: "file",
            text: taByType("file"),
            formatter: (value: string, item: InvoiceWithAttachment) => (
              <Button variant="link" className="inline" onClick={() => vm.downloadFile(item)}>
                {value}
              </Button>
            ),
          },
        ]}
      />

      {vm.canLoadMore && (
        <Button variant="secondary" className="align-center" onClick={vm.loadMore}>
          {tg("load_older_records")}
        </Button>
      )}

      <LoadingOverlay loading={vm.busyWatcher.isBusy} />
    </>
  );
});

export default registerView(invoicesView, InvoicesViewModel);
