import { registerView, ViewComponent } from "@frui.ts/views";
import StockTakingViewModel from "viewModels/shops/detail/warehouse/stockTakingViewModel";
import { observer } from "mobx-react-lite";
import HeaderRow from "controls/headerRow";
import React from "react";
import LocalLoadingOverlay from "controls/localLoadingOverlay";

const StockTakingView: ViewComponent<StockTakingViewModel> = observer(({ vm }) => {
  return (
    <LocalLoadingOverlay loading={vm.busyWatcher.isBusy}>
      <HeaderRow title={vm.translate("title")} level={3} />
      {vm.items.map(item => (
        <div key={item.createdAt.toISOString()} className="mb-2">
          <span className="text-muted pr-2">{vm.translate("status")}</span>
          <span className="font-weight-bold">
            {vm.localizationService.formatDate(item.createdAt)} - {vm.translate(item.ok ? "ok" : "mismatch")}
          </span>
        </div>
      ))}
      {!vm.items.length && <span>{vm.translate("empty")}</span>}
    </LocalLoadingOverlay>
  );
});

registerView(StockTakingView, StockTakingViewModel);
