import { observable } from "mobx";
import merge from "lodash/merge";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class BonusDto {
  /**
   * Value from ENUM - Bonus type
   */
  @observable
  type!: number;

  @observable
  price!: number;

  @observable
  month!: number;

  @observable
  year!: number;

  /**
   * Must be provided if BonusType.Other
   */
  @observable
  name?: string;

  @observable
  note?: string;

  static ValidationRules = {
    type: { required: true, number: true },
    price: { required: true, number: true },
    month: { required: true, number: true },
    year: { required: true, number: true },
  };
}

merge(BonusDto.ValidationRules, {
  price: { range: { min: 0 } },
  note: { required: false },
});
