import { interfaces } from "inversify";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import LocalizationService from "services/localizationService";
import ShopDetailContext from "models/shops/shopDetailContext";
import ListViewModel from "@frui.ts/datascreens/dist/listViewModel";
import StockTakingRecordDto from "entities/stockTakingRecordDto";
import { bound } from "@frui.ts/helpers";
import { observable } from "mobx";
import { BusyWatcher, watchBusy } from "@frui.ts/screens";
import ParcelShop from "../../../../manualEntities/parcelShop";

export default class StockTakingViewModel extends ListViewModel<StockTakingRecordDto> {
  @observable items: StockTakingRecordDto[] = [];
  @observable busyWatcher = new BusyWatcher();

  constructor(
    private context: ShopDetailContext<ParcelShop>,
    private repository: ParcelShopRepository,
    public localizationService: LocalizationService
  ) {
    super();
    this.navigationName = "";
    this.loadData();
  }

  @watchBusy
  protected async loadData() {
    const items = (await this.repository.stockTaking(this.context.shop.id)).items;
    this.setData([items, { limit: 10, totalItems: items.length, offset: 0 }]);
  }

  @bound
  translate(code: string) {
    return this.localizationService.translateGeneral(`parcel_shop.stock_taking.${code}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (context: ShopDetailContext<ParcelShop>) => {
      return new StockTakingViewModel(context, container.get(ParcelShopRepository), container.get(LocalizationService));
    };
  }
}
