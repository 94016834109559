import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import PaymentTerminalViewModel from "viewModels/paymentTerminals/paymentTerminalsViewModel";
import List from "./list";

const paymentTerminalsView: ViewComponent<PaymentTerminalViewModel> = observer(({ vm }) => {
  return vm.activeChild ? <View vm={vm.activeChild} /> : <List vm={vm} />;
});

export default registerView(paymentTerminalsView, PaymentTerminalViewModel);
