export enum PaymentTerminalStatus {
  Available = 0,
  Assigned = 1,
  Serviced = 2,
  Quarantine = 3,
  Discarded = 4,
}

export enum PaymentTerminalEvent {
  ToService = "to_service",
  Discard = "discard",
  RemoveFromPs = "remove_from_ps",
  ReturnedFromService = "returned_from_service",
  AssignToPs = "assign_to_ps",
}

export default class PaymentTerminal {
  id: number;
  terminal_id: string;
  terminal_sn: string;
  ps_id: number;
  status: number;
  quarantine_to?: string;
  ps: {
    id: number;
    depo_id: number;
    name: string;
  };
  journals?: {
    id: number;
    date_from: Date;
    date_to?: Date;
    status: number;
    ps_id?: number;
    ps?: {
      id: number;
      name: string;
    };
  }[];
  updated_at: Date;
}

export class PaymentTerminalFilter {
  terminal_id?: string;
  terminal_sn?: string;
  ps_ids?: number[];
  status?: number[];
  depo_ids?: number[];
  parcel_shop_name?: string;
  depo_name?: string;
  status_name?: string;
  show_discarded_terminals?: boolean;
}
