import { ValidationControlBase } from "@frui.ts/bootstrap";
import { CommonInputProps } from "@frui.ts/bootstrap/dist/controls/commonInputProps";
import { bound, ensureObservableProperty, PropertyName } from "@frui.ts/helpers";
import { IBindingProps } from "@frui.ts/views";
import { get, isObservable, isObservableProp } from "mobx";
import React from "react";
import { Form, FormCheckProps } from "react-bootstrap";

export interface CheckArrayProps {
  isNumeric?: boolean;
  className?: string;
}

type ValueType = string | number | undefined;

export class CheckArray<TTarget> extends ValidationControlBase<
  TTarget,
  CheckArrayProps & FormCheckProps & CommonInputProps & IBindingProps<TTarget>
> {
  protected get inheritedProps() {
    const { id, noValidation, errorMessage, className, target, property, onValueChanged, isNumeric, ...otherProps } = this.props;

    return otherProps;
  }

  @bound protected renderInner() {
    const { className, id, property, isInvalid } = this.props;
    const validationError = this.getValidationError();

    return (
      <Form.Check
        {...this.inheritedProps}
        id={`${property}-${id}`}
        className={`check-array ${className || ""}`}
        checked={!!this.value}
        onChange={this.handleValueChanged}
        isInvalid={!!validationError || isInvalid}
      />
    );
  }

  protected get value() {
    const { id, isNumeric } = this.props;

    if (!id) {
      throw new Error("'id' prop is required for CheckBoxArray!");
    }

    let value: ValueType = id;

    if (isNumeric) {
      value = parseInt(id, 10);
    }

    const targetArray = this.getTargetArray();
    return (targetArray || []).indexOf(value) >= 0;
  }

  @bound protected handleValueChanged(e: React.FormEvent<any>) {
    const { id, isNumeric } = this.props;
    let value: undefined | string | number;

    if (isNumeric && id) {
      value = parseInt(id, 10);
    } else {
      value = id;
    }

    if (value === undefined) {
      return;
    }

    const targetArray = this.getTargetArray();
    const array = targetArray ? targetArray.slice() : [];
    const index = array.indexOf(value);

    // Remove or add?
    if (index >= 0) {
      array.splice(index, 1);
    } else {
      array.push(value);
    }

    this.setValue(array);
  }

  private getTargetArray() {
    const { target } = this.props;
    const property = this.props.property as PropertyName<TTarget>;

    if (!target) {
      //// throw new Error("'target' prop has not been set");
      // tslint:disable-next-line: no-console
      console.warn("'target' prop has not been set");
      return undefined;
    }
    if (!property) {
      throw new Error("'property' prop has not been set");
    }

    if (!isObservable(target) || !isObservableProp(target, property)) {
      ensureObservableProperty(target, property, target[property as keyof TTarget]);
    }

    return get(target, property) as ValueType[];
  }
}
