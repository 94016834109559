import { ValidationControlBase } from "@frui.ts/bootstrap";
import { CommonInputProps } from "@frui.ts/bootstrap/dist/controls/commonInputProps";
import { bound, ensureObservableProperty, PropertyName } from "@frui.ts/helpers";
import { IBindingProps } from "@frui.ts/views";
import { get, isObservable, isObservableProp } from "mobx";
import React from "react";
import { Form, FormControlProps, InputGroup } from "react-bootstrap";

const PREFIX = "PBOX";

const parseParcelBoxName = (name: string | undefined): { city: string; street: string; additionalInfo: string } => {
  let nameWithoutPrefix = name?.replace(`${PREFIX} `, "") ?? "";
  const additionalInfoStartBracket = nameWithoutPrefix.indexOf("(");
  const hasAdditionalInfo = additionalInfoStartBracket > 0;

  let city = "";
  let street = "";
  let additionalInfo = "";

  if (hasAdditionalInfo) {
    const additionalInfoEndBracket = nameWithoutPrefix.indexOf(")");
    additionalInfo = nameWithoutPrefix.slice(additionalInfoStartBracket + 1, additionalInfoEndBracket);
    nameWithoutPrefix = nameWithoutPrefix.slice(0, additionalInfoStartBracket - 1);
  }

  city = nameWithoutPrefix.split(" ")[0];
  street = nameWithoutPrefix.replace(`${city} `, "");
  return { city, street, additionalInfo };
};

interface ParcelBoxNameInputProps {
  cityPlaceholder?: string;
  streetPlaceholder?: string;
  additionalInfoPlaceholder?: string;
}

export class ParcelBoxNameInput<TTarget> extends ValidationControlBase<
  TTarget,
  FormControlProps & CommonInputProps & IBindingProps<TTarget> & ParcelBoxNameInputProps
> {
  protected get value() {
    const target = this.props.target as TTarget;
    const property = this.props.property as PropertyName<TTarget>;

    if (!target) {
      // tslint:disable-next-line: no-console
      console.warn("'target' prop has not been set");
      return undefined;
    }
    if (!property) {
      throw new Error("'property' prop has not been set");
    }

    if (!isObservable(target) || !isObservableProp(target, property)) {
      ensureObservableProperty(target, property, target[property]);
    }

    return parseParcelBoxName(get(target, property));
  }

  @bound protected renderInner() {
    const validationError = this.getValidationError();

    const errorClassName = validationError ? "is-invalid" : "";

    return (
      <>
        <InputGroup className={errorClassName}>
          <InputGroup.Prepend>
            <InputGroup.Text>{PREFIX}</InputGroup.Text>
          </InputGroup.Prepend>
          <input
            type="text"
            placeholder={this.props.cityPlaceholder}
            className={`form-control ${errorClassName}`}
            value={this.value?.city}
            onChange={e => this.handleValueChanged(e, "city")}
            onBlur={e => this.handleValueTrimed()}
          />
          <input
            type="text"
            placeholder={this.props.streetPlaceholder}
            className={`form-control ${errorClassName}`}
            value={this.value?.street}
            onChange={e => this.handleValueChanged(e, "street")}
            onBlur={e => this.handleValueTrimed()}
          />
          <input
            type="text"
            placeholder={this.props.additionalInfoPlaceholder}
            className={`form-control ${errorClassName}`}
            value={this.value?.additionalInfo}
            onChange={e => this.handleValueChanged(e, "additionalInfo")}
            onBlur={e => this.handleValueTrimed()}
          />
        </InputGroup>
        {validationError && <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>}
      </>
    );
  }

  @bound protected handleValueChanged(e: React.FormEvent<any>, field: "city" | "street" | "additionalInfo") {
    const target = e.target as HTMLInputElement;
    const newValue = { ...this.value, [field]: target.value };
    newValue.city = newValue.city?.toUpperCase().trim();

    const { additionalInfo, ...cityAndStreet } = newValue;

    this.setValue(`${PREFIX} ${Object.values(cityAndStreet).join(" ")}${additionalInfo ? ` (${additionalInfo})` : ""}`);
  }

  @bound protected handleValueTrimed() {
    const newValue = {
      city: this.value?.city.trim(),
      street: this.value?.street.trim(),
      additionalInfo: this.value?.additionalInfo.trim(),
    };

    const { additionalInfo, ...cityAndStreet } = newValue;

    this.setValue(`${PREFIX} ${Object.values(cityAndStreet).join(" ")}${additionalInfo ? ` (${additionalInfo})` : ""}`);
  }
}
