import { Input, ValidationError } from "@frui.ts/bootstrap";
import { getValidationMessage } from "@frui.ts/validation";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import DateInput from "controls/dateInput";
import TreeList from "controls/treeList";
import MemoDetailDto from "entities/memoDetailDto";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import MemoDetailViewModel from "viewModels/dashboard/memoDetailViewModel";
import "./memoDetailView.scss";
import LocalLoadingOverlay from "../../controls/localLoadingOverlay";
import { StringInput } from "controls/stringInput";

// workaround until Input control supports 'as' props
const CustomInput = StringInput as any;

const memoDetailView: ViewComponent<MemoDetailViewModel> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral } = vm.localizationService;
  const ta = (code: string) => translateAttribute("memos", code);
  const tg = (code: string) => translateGeneral(`memos.detail.${code}`);
  const required: string = vm.isReadOnly ? "" : "required";

  return (
    <Modal centered={true} show={true} onHide={vm.requestClose}>
      <Form onSubmit={preventDefault(vm.save)}>
        <Modal.Header closeButton>
          <Modal.Title>{tg(`${vm.mode}_title`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {vm.item ? (
            <>
              <Form.Row>
                {vm.mode !== "create" && (
                  <>
                    <Form.Group as={Col} md={12}>
                      <Form.Label>{ta("author_id")}</Form.Label>
                      <TreeList options={toJS(vm.possibleUsers)} value={toJS(vm.authorUser)} multiple={false} disabled={true} />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>{ta("created_at")}</Form.Label>
                      <DateInput target={vm.item as MemoDetailDto} property="createdAt" disabled={true} />
                    </Form.Group>
                  </>
                )}
                <Form.Group as={Col} md={6} className={required}>
                  <Form.Label>{ta("due_date")}</Form.Label>
                  <DateInput target={vm.item} property="dueDate" disabled={vm.isReadOnly} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={12} className={required}>
                  <Form.Label>{ta("subject")}</Form.Label>
                  <StringInput trim={true} target={vm.item} property="subject" type="text" disabled={vm.isReadOnly} />
                </Form.Group>
                <Form.Group as={Col} md={12} className={required}>
                  <Form.Label>{ta("assignees")}</Form.Label>
                  <TreeList
                    options={toJS(vm.possibleUsersWithAll)}
                    value={toJS(vm.selectedUsers)}
                    onChange={vm.onSelectUser as any}
                    multiple={true}
                    disabled={vm.isReadOnly}
                    error={!!getValidationMessage(vm.item, "assignees")}
                  />
                  <ValidationError target={vm.item} property="assignees" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={12}>
                  <Form.Label>{ta("body")}</Form.Label>
                  <CustomInput as="textarea" trim={true} target={vm.item} property="body" rows="4" disabled={vm.isReadOnly} />
                </Form.Group>
              </Form.Row>
            </>
          ) : (
            <LocalLoadingOverlay loading={true}>
              <div style={{ minHeight: 200 }}></div>
            </LocalLoadingOverlay>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          {vm.isReadOnly && <Button onClick={vm.requestClose}>{translateGeneral("confirm_dialog.ok")}</Button>}
          {!vm.isReadOnly && (
            <Button type="submit" disabled={!vm.isDirty || vm.busyWatcher.isBusy}>
              {translateGeneral("confirm_dialog.confirm")}
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
});
export default registerView(memoDetailView, MemoDetailViewModel);
