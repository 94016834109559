import { registerView, View, ViewComponent } from "@frui.ts/views";
import HeaderRow from "controls/headerRow";
import { observer } from "mobx-react-lite";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import NewPickupPointViewModel, {
  PARCEL_BOX_SUBJECT_CODE,
  PARCEL_SHOP_SUBJECT_CODE,
} from "viewModels/pickupPoint/newPickupPointViewModel";

import ParcelShopImage from "assets/images/img-parcelshop.png";
import ParcelBoxImage from "assets/images/img-parcelbox.png";

import iconArrow from "assets/icons/icon-arrow.svg";

import styles from "./newPickupPoint.module.scss";

const newPickupPointView: ViewComponent<NewPickupPointViewModel> = observer(({ vm }) => {
  const { translateModel: tm } = vm.localizationService;

  if (vm.activeChild) {
    return <View vm={vm.activeChild} />;
  }

  return (
    <>
      <HeaderRow title={vm.translate("title")} />
      <p>{vm.translate("description")}</p>
      <Row>
        {vm.allowToAddParcelShop && (
          <Col md={6}>
            <Card className={styles.pointCard} onClick={() => vm.selectPickupPoint(PARCEL_SHOP_SUBJECT_CODE)}>
              <div>
                <label>{tm("parcel_shop", 1)}</label>
                <img src={iconArrow} />
              </div>
              <img src={ParcelShopImage} />
            </Card>
          </Col>
        )}
        {vm.allowToAddParcelBox && (
          <Col md={6}>
            <Card className={styles.pointCard} onClick={() => vm.selectPickupPoint(PARCEL_BOX_SUBJECT_CODE)}>
              <div>
                <label>{tm("parcel_box", 1)}</label>
                <img src={iconArrow} />
              </div>
              <img src={ParcelBoxImage} />
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
});
export default registerView(newPickupPointView, NewPickupPointViewModel);
