import { ValidationControlBase } from "@frui.ts/bootstrap";
import { CommonInputProps } from "@frui.ts/bootstrap/dist/controls/commonInputProps";
import { bound, ensureObservableProperty, PropertyName } from "@frui.ts/helpers";
import { IBindingProps } from "@frui.ts/views";
import { get, isObservable, isObservableProp } from "mobx";
import React from "react";
import { Form, FormControlProps } from "react-bootstrap";
import MaskedInput from "react-text-mask";

export class PhoneInput<TTarget> extends ValidationControlBase<
  TTarget,
  FormControlProps & CommonInputProps & IBindingProps<TTarget>
> {
  protected get value() {
    const target = this.props.target as TTarget;
    const property = this.props.property as PropertyName<TTarget>;

    if (!target) {
      // tslint:disable-next-line: no-console
      console.warn("'target' prop has not been set");
      return undefined;
    }
    if (!property) {
      throw new Error("'property' prop has not been set");
    }

    if (!isObservable(target) || !isObservableProp(target, property)) {
      ensureObservableProperty(target, property, target[property]);
    }

    let value = get(target, property);

    if (value === undefined || value === null) {
      value = "+420";
    }

    return value;
  }

  @bound protected renderInner() {
    const validationError = this.getValidationError();

    const autoCorrectPipe = (conformedValue: string, config: any) => {
      let rawValue = config.rawValue.split("");

      if (rawValue[1] === "+") {
        const firstCharacter = rawValue[0];
        rawValue.shift();
        rawValue = rawValue.join("");
        rawValue = rawValue.replace("_", firstCharacter);

        return {
          value: rawValue,
          indexesOfPipedChars: [0],
        };
      }

      return conformedValue;
    };

    return (
      <>
        <MaskedInput
          mask={["+", "4", "2", /[0-1]/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/]}
          className={`form-control ${validationError ? "is-invalid" : ""}`}
          guide={true}
          showMask={true}
          value={this.value}
          pipe={autoCorrectPipe}
          onChange={this.handleValueChanged}
        />
        {validationError && <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>}
      </>
    );
  }

  @bound protected handleValueChanged(e: React.FormEvent<any>) {
    const target = e.target as HTMLInputElement;
    let valueWithoutMask: string | undefined = target.value.replace(/[_\s]/g, "");

    if (valueWithoutMask === "+42") {
      valueWithoutMask = undefined;
    }

    this.setValue(valueWithoutMask);
  }
}
