import { attachAutomaticDirtyWatcher, isDirty } from "@frui.ts/dirtycheck";
import { bound } from "@frui.ts/helpers";
import { watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors } from "@frui.ts/validation";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import Contact from "entities/contact";
import ContactDto from "entities/contactDto";
import { validateAll } from "helpers";
import { interfaces } from "inversify";
import { EventBus } from "light-event-bus";
import NetworkStatusType from "manualEntities/networkStatusType";
import ParcelShop from "manualEntities/parcelShop";
import { action, computed, observable, runInAction } from "mobx";
import EnumService from "services/enum";
import { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import { scrollTop } from "utils/helpers";
import { ContactModel, EditShopNotificationScope, ISubDetailViewModel } from "../types";
import ContactsViewModelBase from "./contactsViewModelBase";
import PotentialRepository from "data/repositories/potentialRepository";
import IAccessPointRepository from "data/repositories/IAccessPointRepository";
import PotentialPs from "entities/potentialPs";
import PotentialPsDetailDto from "../../../../entities/potentialPsDetailDto";

export default class EditContactsViewModel extends ContactsViewModelBase implements ISubDetailViewModel {
  static defaultNavigationName = "contacts";

  @observable.shallow itemsToDelete: Contact[] = [];
  protected item: PotentialPsDetailDto | ParcelShop;

  @computed
  get mandatoryContactTypes() {
    if (!this.isPotential) {
      return this.enums.values("contact_types").filter(x => x.required);
    }
    return [];
  }

  constructor(
    private itemId: number,
    public isParcelBox: boolean,
    public readonly isPotential: boolean,
    private repository: IAccessPointRepository<PotentialPs | ParcelShop, PotentialPsDetailDto | ParcelShop>,
    localizationService: LocalizationService,
    private notifications: NotificationService,
    enums: EnumService,
    private eventBus: EventBus
  ) {
    super(isParcelBox, localizationService, enums);
    this.name = localizationService.translateGeneral("parcel_shop.contact");
    this.navigationName = EditContactsViewModel.defaultNavigationName;
  }

  onActivate() {
    return this.loadData();
  }

  discardChanges() {
    return this.onInitialize();
  }

  @watchBusy
  async loadData() {
    const [source, item] = await Promise.all([this.repository.getContacts(this.itemId), this.repository.getDetail(this.itemId)]);

    this.item = item;

    const models = source.map(x => {
      const item = observable(x);
      attachAutomaticDirtyWatcher(item);
      attachAutomaticValidator(item, ContactDto.ValidationRules);
      return item as ContactModel;
    });

    // Iterate over models and set mandatory to required contact types
    // when there is none of them, create it.
    for (const contactType of this.mandatoryContactTypes) {
      let contact = models.find(x => x.contactTypeId === contactType.id);

      if (!contact) {
        contact = new Contact();
        contact.contactTypeId = contactType.id;
        attachAutomaticValidator(contact, ContactDto.ValidationRules);
        models.push(contact);
      }

      contact.isMandatory = true;
    }

    runInAction(() => {
      this.itemsToDelete.length = 0;
      this.items = models;
    });
  }

  @action removeContact(contact: ContactModel) {
    if (contact.isMandatory) {
      return;
    }

    const index = this.items.indexOf(contact);
    if (index >= 0) {
      this.items.splice(index, 1);

      if (contact.id) {
        this.itemsToDelete.push(contact);
      }
    }
  }

  get isDirty() {
    return (this.items && (!!this.itemsToAdd.length || this.items.some(x => isDirty(x)))) || !!this.itemsToDelete.length;
  }

  get canSave() {
    return this.isDirty && !this.items.some(hasVisibleErrors);
  }

  get itemsToAdd() {
    return this.items.filter(x => !x.id);
  }

  get itemsToUpdate() {
    return this.items.filter(x => x.id && isDirty(x));
  }

  get isNetworkParent() {
    if (this.isPotential) {
      return false;
    }

    return (this.item as ParcelShop).network_partner === NetworkStatusType.Parent;
  }

  @bound
  @watchBusy
  async save() {
    if (!validateAll(this.items)) {
      return;
    }

    const added = this.itemsToAdd.map(x => this.repository.addContact(this.itemId, x));
    const deletes = this.itemsToDelete.map(x => this.repository.deleteContact(this.itemId, x.id));
    const updates = this.itemsToUpdate.map(x => this.repository.updateContact(this.itemId, x.id, x));

    try {
      await Promise.all([...added, ...deletes, ...updates]);

      this.notifications.addNotification(
        this.localizationService.translateGeneral("parcel_shop.edit.contacts.saved_message"),
        SeverityLevel.success,
        EditShopNotificationScope
      );

      this.eventBus.publish(Events.AccessPoints.Contacts.Changed);

      await this.loadData();
    } catch {
      // TODO: Add catch statement
    }

    scrollTop();
  }

  static Factory({ container }: interfaces.Context) {
    return (shopId: number, isParcelBox: boolean, isPotential: boolean) =>
      new EditContactsViewModel(
        shopId,
        isParcelBox,
        isPotential,
        isPotential ? container.get(PotentialRepository) : container.get(ParcelShopRepository),
        container.get(LocalizationService),
        container.get(NotificationService),
        container.get(EnumService),
        container.get(EventBus)
      );
  }
}
