import React, { FunctionComponent } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import classnames from "classnames";
import DateInput from "../../../../controls/dateInput";
import { ConfigurationSectionProps } from "./types";
import { observer } from "mobx-react-lite";

const Vacations: FunctionComponent<ConfigurationSectionProps> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral: tg } = vm.localizationService;

  return (
    <>
      <h3>{tg("parcel_shop.vacation")}</h3>
      {vm.item.vacations.map((item, index) => {
        return (
          <Row key={`vacation-${index}`} className="vacation-item">
            <Form.Group as={Col} md={3} className={classnames({ required: !vm.isPotential })}>
              <Form.Label>{translateAttribute("vacation", "date_from")}</Form.Label>
              <DateInput target={item} property="dateFrom" />
            </Form.Group>
            <Form.Group as={Col} md={3} className={classnames({ required: !vm.isPotential })}>
              <Form.Label>{translateAttribute("vacation", "date_to")}</Form.Label>
              <DateInput target={item} property="dateTo" />
            </Form.Group>
            <Button onClick={() => vm.removeVacation(item)} variant="link">
              &#215; {tg("new_parcel_shop.remove_row")}
            </Button>
          </Row>
        );
      })}
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Button onClick={vm.addVacation} variant="light" block>
            &#43; {tg("new_parcel_shop.add_vacation")}
          </Button>
        </Form.Group>
      </Form.Row>
    </>
  );
});

export default Vacations;
