import { registerView, View, ViewComponent } from "@frui.ts/views";
import Table from "controls/table";
import MemoDetailDto from "entities/memoDetailDto";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Dropdown, NavDropdown } from "react-bootstrap";
import { formatDate } from "utils/helpers";
import MemosViewModel from "viewModels/dashboard/memosViewModel";
import iconEdit from "assets/icons/icon-edit.svg";
import iconEye from "assets/icons/icon-eye.svg";
import iconSolved from "assets/icons/icon-solved.svg";
import iconCross from "assets/icons/icon-cross.svg";
import iconClock from "assets/icons/icon-clock.svg";
import HeaderRow from "../../controls/headerRow";

const memosView: ViewComponent<MemosViewModel> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral } = vm.localizationService;
  const ta = (code: string) => translateAttribute("memos", code);
  const tg = (code: string) => translateGeneral(`memos.${code}`);

  function memoStatusIcon(id: number) {
    let icon;
    if (id === 1) {
      icon = iconClock;
    } else if (id === 2) {
      icon = iconSolved;
    } else {
      icon = iconCross;
    }
    return icon;
  }

  return (
    <>
      {vm.isTeamSectionAllowed && (
        <div className="mb-5">
          <HeaderRow title={tg("team_title")} level={2}>
            <Button onClick={() => vm.openMemo("create", undefined)}>{tg("add_new")}</Button>
          </HeaderRow>
          <Table
            data={vm.teamMemos ?? []}
            keyField="id"
            columns={[
              {
                dataField: "dueDate",
                text: ta("due_date"),
                formatter: (_cell, row: MemoDetailDto) => formatDate(row.dueDate),
              },
              {
                dataField: "subject",
                text: ta("subject"),
              },
              {
                dataField: "assignees",
                text: ta("assignees"),
                formatter: (_cell, row: MemoDetailDto) => (row.assignees ? vm.getUsersNames(row.assignees) : ""),
              },
              {
                dataField: "authorId",
                text: ta("author_id"),
                formatter: (_cell, row: MemoDetailDto) => (row.authorId ? vm.getUsersNames([row.authorId]) : row.authorName!),
              },
              {
                dataField: "createdAt",
                text: ta("created_at"),
                formatter: (_cell, row: MemoDetailDto) => formatDate(row.createdAt),
              },
              {
                dataField: "edit",
                isDummyField: true,
                text: translateGeneral("edit"),
                headerStyle: {
                  width: "100px",
                  textAlign: "center",
                },
                align: "center",
                formatter: (_cell, row: MemoDetailDto) => {
                  return (
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      className="noRowClick button-icon"
                      onClick={() => vm.openMemo("edit", row.id)}>
                      <img src={iconEdit} alt="edit" />
                    </Button>
                  );
                },
              },
            ]}
          />
        </div>
      )}

      <h2>{tg("mine_title")}</h2>
      <div className="mineMemosTableWrapper">
        <Table
          data={vm.myMemos ?? []}
          keyField="id"
          columns={[
            {
              dataField: "dueDate",
              text: ta("due_date"),
              formatter: (_cell, row: MemoDetailDto) => formatDate(row.dueDate),
            },
            {
              dataField: "status",
              text: ta("status"),
              classes: "memoStatus",
              formatter: (_cell, row: MemoDetailDto) => (
                <Dropdown id="status">
                  <Dropdown.Toggle variant="link">
                    {row.status !== undefined ? (
                      <>
                        <img src={memoStatusIcon(row.status)} className="pr-2" />
                        {vm.getMemoStatus(row.status)}
                      </>
                    ) : (
                      " - "
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {vm.memoStatuses
                      .filter(item => item.id !== row.status)
                      .map(item => (
                        <Dropdown.Item onSelect={() => vm.changeMemoStatus(row.id, row, item.id)} key={item.id}>
                          {item.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              ),
            },
            {
              dataField: "subject",
              text: ta("subject"),
            },
            {
              dataField: "authorId",
              text: ta("author_id"),
              formatter: (_cell, row: MemoDetailDto) => (row.authorId ? vm.getUsersNames([row.authorId]) : row.authorName!),
            },
            {
              dataField: "createdAt",
              text: ta("created_at"),
              formatter: (_cell, row: MemoDetailDto) => formatDate(row.createdAt),
            },
            {
              dataField: "show",
              isDummyField: true,
              text: tg("show"),
              headerStyle: {
                width: "100px",
                textAlign: "center",
              },
              align: "center",
              formatter: (_cell, row: MemoDetailDto) => {
                return (
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    className="noRowClick button-icon"
                    onClick={() => vm.openMemo("show", row.id)}>
                    <img src={iconEye} alt="show" />
                  </Button>
                );
              },
            },
          ]}
        />
      </div>
      <View vm={vm.activeChild} />
    </>
  );
});
export default registerView(memosView, MemosViewModel);
