import { registerView, View, ViewComponent } from "@frui.ts/views";
import HeaderRow from "controls/headerRow";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonToolbar, Form, Nav } from "react-bootstrap";
import EditShopViewModel from "viewModels/shops/detail/edit/editShopViewModel";
import preventDefault from "../../preventDefault";
import ParcelShopSubheader from "./partials/parcelShopSubheader";
import ParcelShop from "../../../manualEntities/parcelShop";

const editShopView: ViewComponent<EditShopViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localization;

  return (
    <>
      <HeaderRow title={vm.name} />

      {vm.parcelShop instanceof ParcelShop && <ParcelShopSubheader shop={vm.parcelShop} localization={vm.localization} />}

      <Nav variant="pills" fill className="wizard-steps">
        {vm.children.map(x => (
          <Nav.Item key={x.name}>
            <Nav.Link active={vm.activeChild === x} eventKey={x.navigationName} onSelect={vm.openChild}>
              {x.name}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      <Form onSubmit={vm.activeChild ? preventDefault(vm.activeChild.save) : undefined}>
        <View vm={vm.activeChild} fallbackMode="message" />

        <ButtonToolbar>
          {vm.activeChild && (
            <Button type="submit" size="lg" disabled={!vm.activeChild || !vm.activeChild.canSave}>
              {tg("save")}
            </Button>
          )}
          <Button size="lg" variant="outline-secondary" onClick={vm.requestClose}>
            {tg("cancel")}
          </Button>
        </ButtonToolbar>
      </Form>

      <LoadingOverlay loading={vm.activeChild?.busyWatcher.isBusy} />
    </>
  );
});

export default registerView(editShopView, EditShopViewModel);
