import { nameof } from "@frui.ts/helpers";
import { watchBusy } from "@frui.ts/screens";
import MessageGroupsRepository from "data/repositories/messageGroupsRepository";
import MessageGroupListDto, { MessageGroupFilter } from "entities/messageGroupListDto";
import { interfaces } from "inversify";
import { EventBus } from "light-event-bus";
import { action, toJS } from "mobx";
import ConfirmationService from "services/confirmationService";
import { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import { scrollTop } from "utils/helpers";
import ListViewModelBase from "viewModels/listViewModelBase";
import MessageGroupDetailViewModel from "./messageGroupDetailViewModel";

export default class MessageGroupsViewModel extends ListViewModelBase<
  MessageGroupListDto,
  MessageGroupFilter,
  MessageGroupDetailViewModel
> {
  static notificationScope = "message_groups";
  static DefaultNavigationName = "message_groups";

  constructor(
    private repository: MessageGroupsRepository,
    private eventBus: EventBus,
    private messageGroupDetailViewModelFactory: ReturnType<typeof MessageGroupDetailViewModel.Factory>,
    public confirmationService: ConfirmationService,
    public localizationService: LocalizationService,
    private notificationService: NotificationService
  ) {
    super();
    this.navigationName = "message_groups";
    this.name = localizationService.translateModel("message_group", 3);
    this.pagingFilter.limit = 10; // just to show paging
    this.pagingFilter.sortColumn = nameof<MessageGroupListDto>("name");
  }

  @action.bound changeFilter(value: MessageGroupFilter) {
    this.loadData();
  }

  /* eslint-disable-next-line @typescript-eslint/no-empty-function */
  protected resetFilterValues(_filter: MessageGroupFilter): void {}

  onInitialize() {
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.MessageGroups.Changed, this.loadData));
    this.resetFilterAndLoad();
  }

  @action.bound
  @watchBusy
  async loadData() {
    this.notifyNavigationChanged(); // save filter to history

    return this.repository
      .getMessageGroups(this.pagingFilter, toJS(this.appliedFilter, { recurseEverything: true }))
      .then(([items, paging]) => {
        this.setData([items, paging]);
      });
  }

  @action.bound async remove(item: MessageGroupListDto) {
    const status = await this.confirmationService.showConfirmation(
      this.localizationService.translateGeneral("message_group.delete_confirm.message").replace("%name%", item.name),
      this.localizationService.translateGeneral("message_group.delete_confirm.title"),
      { variant: "danger", text: this.localizationService.translateGeneral("confirm_dialog.confirm") },
      { text: this.localizationService.translateGeneral("confirm_dialog.cancel") }
    );

    if (status) {
      try {
        await this.repository.deleteMessageGroup(item.id);
        this.notificationService.addNotification(
          this.localizationService.translateGeneral("message_group.deleted").replace("%name%", item.name),
          SeverityLevel.success,
          MessageGroupsViewModel.notificationScope
        );
      } catch {
        // TODO: Add missing catch statement
      }
    }

    scrollTop();
  }

  @action.bound openDetail(item: MessageGroupListDto) {
    return this.tryActivateChild(this.messageGroupDetailViewModelFactory(item));
  }

  @action.bound add() {
    return this.tryActivateChild(this.messageGroupDetailViewModelFactory());
  }

  protected async findNavigationChild(navigationName: string) {
    if (navigationName === "new") {
      return this.messageGroupDetailViewModelFactory();
    } else if (+navigationName) {
      const messageGroup = await this.repository.getMessageGroup(+navigationName);
      return this.messageGroupDetailViewModelFactory(messageGroup);
    }
  }

  static Factory({ container }: interfaces.Context) {
    return () =>
      new MessageGroupsViewModel(
        container.get(MessageGroupsRepository),
        container.get(EventBus),
        container.get(MessageGroupDetailViewModel.Factory),
        container.get(ConfirmationService),
        container.get(LocalizationService),
        container.get(NotificationService)
      );
  }
}
