import React from "react";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import MessagesViewModel from "viewModels/messages/messagesViewModel";
import MessagesList from "./list";

const messagesView: ViewComponent<MessagesViewModel> = observer(({ vm }) => {
  return vm.activeChild ? <View vm={vm.activeChild} /> : <MessagesList vm={vm} />;
});

export default registerView(messagesView, MessagesViewModel);
