import { Router, watchBusy } from "@frui.ts/screens";
import OpportunitysRepository from "data/repositories/opportunitiesRepository";
import { interfaces } from "inversify";
import { computed, action, observable, runInAction, toJS } from "mobx";
import EnumService from "services/enum";
import EventBus, { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import EditableDetailViewModelBase from "viewModels/editableDetailViewModelBase";
import Opportunity, { OpportunityStatus } from "entities/opportunity";
import UpdateOpportunityDto from "entities/updateOpportunityDto";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import extractErrorMessage from "data/extractErrorMessage";
import AccessControlViewModel from "viewModels/shops/detail/accessControlViewModel";
import { attachAutomaticDirtyWatcher, isDirty } from "@frui.ts/dirtycheck";
import { ISelectItem } from "@frui.ts/views";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import { bound } from "@frui.ts/helpers";
import UserContext from "services/userContext";
import ParcelShopStatus from "manualEntities/parcelShopStatus";
import { ParcelShopFilter } from "manualEntities/parcelShop";
import CreateOpportunityDto from "entities/createOpportunityDto";
import OpportunityDetailDto from "entities/opportunityDetailDto";
import { formatDepoName } from "utils/helpers";
import AccessPointPartners from "models/enumerations/accessPointPartners";
import PotentialRepository from "data/repositories/potentialRepository";
import { PagedQueryResult } from "@frui.ts/data";

export interface PortalSelectItem extends ISelectItem {
  isParcelBox: boolean;
  isPotential: boolean;
}

const potentialTypes: number[] = [+AccessPointPartners.PARCEL_BOX_POTENTIAL, +AccessPointPartners.PARCEL_SHOP_POTENTIAL];

@Router.registerRoute({ name: Router.Self, route: "new" })
export default class OpportunityDetailViewModel extends EditableDetailViewModelBase<OpportunityDetailDto, Opportunity> {
  // Target cust id to assign terminal
  @observable custId?: number;
  @observable updateItem: Opportunity;
  @observable errorMessage?: string;
  @observable.shallow allowedShops?: PortalSelectItem[];

  constructor(
    public originalItem: Opportunity | undefined,
    public isVMSelectionDisabled: boolean | undefined,
    private repository: OpportunitysRepository,
    private eventBus: EventBus,
    private enums: EnumService,
    private notificationService: NotificationService,
    public localizationService: LocalizationService,
    private parcelShopsRepository: ParcelShopRepository,
    private potentialRepository: PotentialRepository,
    private userContext: UserContext
  ) {
    super(originalItem);

    if (originalItem && originalItem.id) {
      this.name = this.localizationService.translateGeneral("opportunity.detail");
      this.navigationName = originalItem.id.toString();
    } else {
      this.name = this.localizationService.translateGeneral("opportunity.add");
      this.navigationName = "new";
    }

    void this.loadPortals();
  }

  @bound
  private async loadPortals() {
    type PsForSelect = {
      id: number;
      name: string;
      type_id: number;
    };

    const filter = new ParcelShopFilter();
    filter.depo_ids = toJS(this.userContext.assignedDepos);
    filter.states = [ParcelShopStatus.Active, ParcelShopStatus.Unactive];
    const parcelBoxTypes: number[] = [
      +AccessPointPartners.PARCEL_BOX,
      +AccessPointPartners.PARCEL_BOX_POTENTIAL,
      +AccessPointPartners.ALZA_BOX,
    ];

    const [parcelShops]: PagedQueryResult<PsForSelect> = await this.parcelShopsRepository.getList(
      { offset: 0, limit: 1000, sortColumn: "name" },
      filter
    );

    runInAction(() => {
      this.allowedShops = parcelShops.map(ps => {
        const typeId = ps.type_id;
        const isParcelBox = parcelBoxTypes.indexOf(typeId) !== -1;
        const isPotential = potentialTypes.indexOf(typeId) !== -1;
        return {
          value: isPotential ? -ps.id : ps.id,
          label: ps.name,
          isParcelBox: isParcelBox,
          isPotential: isPotential,
        } as PortalSelectItem;
      });
    });
  }

  getStatusReason(id: number) {
    return this.enums.value("ps_opportunity_status_reasons", id);
  }

  @action
  @watchBusy
  protected async loadDetail() {
    let editedItem: Opportunity;

    if (this.isCreating || !this.originalItem?.id) {
      editedItem = this.originalItem ?? new Opportunity();
      editedItem.psId = editedItem.entityType === 1 ? -editedItem.psId : editedItem.psId;
      attachAutomaticValidator(editedItem, CreateOpportunityDto.ValidationRules);
    } else {
      try {
        editedItem = (await this.repository.getOpportunity(this.originalItem.id)) as Opportunity;
        editedItem.psId = editedItem.entityType === 1 ? -editedItem.psId : editedItem.psId;
        attachAutomaticValidator(editedItem, UpdateOpportunityDto.ValidationRules);
      } catch (e) {
        this.eventBus.publish(
          Events.General.ServerError,
          this.localizationService.translateGeneral("message_groups.errors.fetch_failed")
        );
        void this.requestClose();
        throw e;
      }
    }

    attachAutomaticDirtyWatcher(editedItem);
    return editedItem;
  }

  get isCreating() {
    return !this.originalItem?.id;
  }

  get canSave() {
    return !hasVisibleErrors(this.item) && isDirty(this.item);
  }

  get opportunityTypes(): ISelectItem[] {
    return this.enumsForSelect("opportunities");
  }

  get opportunityStatuses(): ISelectItem[] {
    return this.enumsForSelect("opportunity_states");
  }

  get competitions(): ISelectItem[] {
    return this.enumsForSelect("competitions");
  }

  get opportunityStatusesReasons(): ISelectItem[] {
    return this.enumsForSelect("opportunity_status_reasons");
  }

  get isReasonRequired(): boolean {
    return this.item.enumStatusId == OpportunityStatus.UnableToGet;
  }

  get isDisabled(): boolean {
    return [OpportunityStatus.Complete, OpportunityStatus.UnableToGet].includes(this.item.enumStatusId) && !isDirty(this.item);
  }

  @computed
  get parcelShop() {
    const ps = (this.item as OpportunityDetailDto).ps;

    if (this.item?.entityType === 0) {
      return ps;
    }

    const potPs = this.allowedShops?.find(ps => ps.value === this.item.psId);

    return { name: potPs?.label, id: potPs?.value, depoId: null, parcelBox: potPs?.isParcelBox };
  }

  @computed
  get isParcelBox(): boolean {
    const portal: PortalSelectItem | undefined = this.allowedShops?.find(item => item.value === this.item.psId);

    return !!portal?.isParcelBox;
  }

  getDepoName(code: number) {
    const depo = this.enums.value("depos", code);
    return formatDepoName(depo);
  }

  @observable
  enumsForSelect(name: string): ISelectItem[] {
    if (this.isParcelBox) {
      return this.enums.values(`pb_${name}`).map(item => ({ value: item.id, label: item.name }));
    } else {
      return this.enums.values(`ps_${name}`).map(item => ({ value: item.id, label: item.name }));
    }
  }

  @action.bound
  @watchBusy
  async save() {
    this.errorMessage = undefined;
    const portal: PortalSelectItem | undefined = this.allowedShops?.find(item => item.value === this.item.psId);

    this.item.entityType = +!!portal?.isPotential;
    this.item.psId = this.item.entityType === 1 ? -this.item.psId : this.item.psId;

    if (!validate(this.item)) {
      return;
    }

    try {
      if (this.isCreating || !this.originalItem?.id) {
        await this.repository.createOpportunity(this.item as CreateOpportunityDto);
      } else {
        await this.repository.updateOpportunity(this.originalItem.id, this.item as UpdateOpportunityDto);
      }

      this.notificationService.addNotification(
        this.localizationService.translateGeneral(`opportunity.${this.isCreating ? "created" : "edited"}`),
        SeverityLevel.success,
        AccessControlViewModel.notificationScope
      );
    } catch (error: any) {
      runInAction(() => (this.errorMessage = extractErrorMessage(error)));
      return;
    }

    await this.requestClose();
  }

  static Factory({ container }: interfaces.Context) {
    return (item?: any, isVMSelectionDisabled?: boolean) => {
      return new OpportunityDetailViewModel(
        item,
        isVMSelectionDisabled,
        container.get(OpportunitysRepository),
        container.get(EventBus),
        container.get(EnumService),
        container.get(NotificationService),
        container.get(LocalizationService),
        container.get(ParcelShopRepository),
        container.get(PotentialRepository),
        container.get(UserContext)
      );
    };
  }
}
