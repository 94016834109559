import { watchBusy } from "@frui.ts/screens";
import PaymentTerminalsRepository from "data/repositories/paymentTerminalRepository";
import PaymentTerminal, { PaymentTerminalEvent } from "manualEntities/paymentTerminal";
import { interfaces } from "inversify";
import { computed, action, observable } from "mobx";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import EventBus, { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import SecurityService from "services/securityService";
import { scrollTop, formatDepoName } from "utils/helpers";
import EditableDetailViewModelBase from "viewModels/editableDetailViewModelBase";
import extractErrorMessage from "data/extractErrorMessage";
import PaymentTerminalsViewModel from "./paymentTerminalsViewModel";

export default class PaymentTerminalDetailViewModel extends EditableDetailViewModelBase<PaymentTerminal, PaymentTerminal> {
  // Target cust id to assign terminal
  @observable custId?: number;

  constructor(
    originalItem: PaymentTerminal,
    private repository: PaymentTerminalsRepository,
    private eventBus: EventBus,
    private enums: EnumService,
    private security: SecurityService,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService,
    public localizationService: LocalizationService
  ) {
    super(originalItem);

    this.navigationName = originalItem.id.toString();
    this.name = this.terminalTitle;
  }

  @action
  @watchBusy
  protected async loadDetail() {
    try {
      return await this.repository.getPaymentTerminal(this.originalItem!.id);
    } catch (e) {
      this.eventBus.publish(
        Events.General.ServerError,
        this.localizationService.translateGeneral("payment_terminal.errors.fetch_failed")
      );
      void this.requestClose();
      throw e;
    }
  }

  @computed get terminalTitle() {
    return `${this.localizationService.translateGeneral("payment_terminal.title")} ${
      (this.item || this.originalItem)?.terminal_sn
    }`;
  }

  @action.bound
  @watchBusy
  async statusConfirmation(state: PaymentTerminalEvent) {
    // Confirmation window
    const tg = this.localizationService.translateGeneral;

    const confirm = await this.confirmationService.showConfirmation(
      tg(`payment_terminal.events.${state}.body`).replace("%TERMINAL_ID%", this.item.terminal_id),
      tg(`payment_terminal.events.${state}.button`),
      { variant: state === PaymentTerminalEvent.Discard ? "danger" : "primary", text: tg("confirm_dialog.confirm") },
      tg("confirm_dialog.cancel")
    );

    if (!confirm) {
      return;
    }

    try {
      await this.repository.changeStatus(this.item.id, state);
      this.setItem(await this.loadDetail());
      this.notificationService.addNotification(
        this.localizationService.translateGeneral("payment_terminal.change_status_success"),
        SeverityLevel.success,
        PaymentTerminalsViewModel.notificationScope
      );
    } catch {
      // TODO: Add catch statement
    }

    scrollTop();
  }

  @action.bound
  @watchBusy
  async assignPs(): Promise<string | undefined> {
    try {
      await this.repository.changeStatus(this.item.id, PaymentTerminalEvent.AssignToPs, this.custId);
      this.setItem(await this.loadDetail());
      this.notificationService.addNotification(
        this.localizationService.translateGeneral("payment_terminal.change_status_success"),
        SeverityLevel.success,
        PaymentTerminalsViewModel.notificationScope
      );
      scrollTop();
      return undefined;
    } catch (e) {
      return extractErrorMessage(e);
    }
  }

  getDepoName(code: number) {
    const depo = this.enums.value("depos", code);
    return formatDepoName(depo);
  }

  getStatus(id: number) {
    return this.enums.values("payment_terminal_status").find(item => item.id === id);
  }

  static Factory({ container }: interfaces.Context) {
    return (item?: any) => {
      return new PaymentTerminalDetailViewModel(
        item,
        container.get(PaymentTerminalsRepository),
        container.get(EventBus),
        container.get(EnumService),
        container.get(SecurityService),
        container.get(ConfirmationService),
        container.get(NotificationService),
        container.get(LocalizationService)
      );
    };
  }
}
