import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ConductorSingleChild, ScreenBase, watchBusy } from "@frui.ts/screens";
import { action, observable } from "mobx";
import { EventSubscription } from "services/eventBus";

export default abstract class DetailWithSingleChildViewModelBase<
  TDetail,
  TChild extends ScreenBase
> extends ConductorSingleChild<TChild> {
  busyWatcher = new BusyWatcher();
  @observable item: TDetail;
  protected eventSubscriptions = [] as EventSubscription[];

  @action.bound setItem(item: TDetail) {
    this.item = item;
  }

  protected onInitialize() {
    return this.loadData().catch(() => {
      void this.requestClose();
    });
  }

  @bound
  @watchBusy
  loadData() {
    return this.loadDetail().then(this.setItem);
  }

  protected onDeactivate(close: boolean) {
    if (close) {
      const toUnsubscribe = this.eventSubscriptions;
      this.eventSubscriptions = [];
      toUnsubscribe.forEach(x => x.unsubscribe());
    }

    return super.onDeactivate(close);
  }
  protected abstract loadDetail(): Promise<TDetail>;
}
