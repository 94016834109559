import { Select } from "@frui.ts/bootstrap";
import { registerView, ViewComponent } from "@frui.ts/views";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import EditShopInformationViewModel from "viewModels/shops/detail/edit/editShopInformationViewModel";
import { IShopInformationViewModel } from "viewModels/shops/detail/types";
import NewShopInformationViewModel from "viewModels/shops/newShop/newShopInformationViewModel";
import { ParcelBoxNameInput } from "controls/parcelBoxNameInput";
import { StringInput } from "controls/stringInput";

const shopInformationView: ViewComponent<IShopInformationViewModel> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral: tg } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("parcel_shop", attribute);
  };

  return (
    <>
      {vm.item && (
        <>
          <Row>
            <Form.Group as={Col} md={2}>
              <Form.Label>{ta("ic")}</Form.Label>
              <StringInput trim={true} disabled={true} target={vm.item.subject} property="ic" />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={2}>
              <Form.Label>{ta("dic")}</Form.Label>
              <StringInput trim={true} target={vm.item.subject} property="dic" disabled={true} />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={vm.isParcelBox || vm.isAlzaBox ? 12 : 5} className="required">
              <Form.Label>{ta("name")}</Form.Label>
              <InputGroup>
                {!vm.isParcelBox && !vm.isAlzaBox && <StringInput trim={true} target={vm.item} property="name" />}
                {(vm.isParcelBox || vm.isAlzaBox) && (
                  <ParcelBoxNameInput
                    target={vm.item}
                    property="name"
                    cityPlaceholder={tg("parcel_box.city_code_placeholder")}
                    streetPlaceholder={tg("parcel_box.street_placeholder")}
                    additionalInfoPlaceholder={tg("parcel_box.additional_info_placeholder")}
                  />
                )}
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} md={5}>
              <Form.Label>{ta("subname")}</Form.Label>
              <StringInput trim={true} target={vm.item} property="subname" />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md={3} className="required">
              <Form.Label>{ta("type_id")}</Form.Label>
              <Select
                target={vm.item}
                property="typeId"
                items={vm.accessPointPartners || []}
                mode="key"
                textProperty="name"
                isNumeric={true}
                allowEmpty
                disabled={vm.isEditing || !!vm.item.potentialId}
              />
            </Form.Group>

            <Form.Group as={Col} md={3} className={!vm.isPotential ? "required" : ""}>
              <Form.Label>{ta("salesman_id")}</Form.Label>
              <Select
                target={vm.item}
                property="salesmanId"
                items={vm.salesmans || []}
                mode="key"
                keyProperty="value"
                isNumeric={true}
                allowEmpty
              />
            </Form.Group>

            <Form.Group as={Col} md={3} className="required">
              <Form.Label>{ta("depo_id")}</Form.Label>
              <Select
                target={vm.item}
                property="depoId"
                items={vm.depos || []}
                mode="key"
                keyProperty="value"
                isNumeric={true}
                allowEmpty
              />
            </Form.Group>
          </Row>
        </>
      )}

      <LoadingOverlay loading={!vm.item} />
    </>
  );
});

registerView(shopInformationView, NewShopInformationViewModel);
registerView(shopInformationView, EditShopInformationViewModel);

export default shopInformationView;
