import { handlePageChanged, handlePageSizeChanged, SortingDirection } from "@frui.ts/data";
import { bound } from "@frui.ts/helpers";
import { ScreenBase } from "@frui.ts/screens";
import { Observer } from "mobx-react-lite";
import React from "react";
import { Column } from "react-bootstrap-table-next";
import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory from "react-bootstrap-table2-paginator";
import Table from "./table";
import ListViewModelBase from "../viewModels/listViewModelBase";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

export interface DataTableProps<TEntity, TFilter, TDetail extends ScreenBase> {
  columns: Column<keyof TEntity & string>[];
  vm: ListViewModelBase<TEntity, TFilter, TDetail>;
  keyField?: string;
  clickable?: boolean;
}

export default abstract class DataTable<TEntity, TFilter, TDetail extends ScreenBase> extends React.PureComponent<
  DataTableProps<TEntity, TFilter, TDetail>
> {
  static defaultProps = {
    clickable: true,
  };

  @bound
  onSort(sortColumn: string | undefined, order: string) {
    const vm = this.props.vm;
    vm.pagingFilter.sortDirection = order === "asc" ? SortingDirection.Ascending : SortingDirection.Descending;
    vm.pagingFilter.sortColumn = sortColumn;
    void vm.loadData();
  }

  @bound
  onPaginate(page: number, pageSize: number) {
    const vm = this.props.vm;

    if (vm.pagingFilter.limit !== pageSize) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      handlePageSizeChanged(pageSize, vm.pagingFilter, vm.loadData);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      handlePageChanged(page, vm.pagingFilter, vm.loadData);
    }
  }

  @bound
  onTableChange(type: TableChangeType, newState: TableChangeNewState) {
    if (type === "sort") {
      this.onSort(newState.sortField, newState.sortOrder);
    } else if (type === "pagination" && newState.page !== undefined && newState.sizePerPage !== undefined) {
      this.onPaginate(newState.page, newState.sizePerPage);
    } else if (type === "filter") {
      // @TODO
    }
  }

  @bound
  parentByClassName(el: Element, className: string) {
    while ((el.className?.split(" ") ?? []).indexOf(className) === -1) {
      el = el.parentNode as Element;
      if (!el) {
        return null;
      }
    }
    return el;
  }

  @bound
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRowClick(e: any, row: TEntity, rowIndex: any) {
    if (this.parentByClassName(e.nativeEvent.target, "noRowClick") === null) {
      void this.props.vm.openDetail(row);
    }
  }

  render() {
    const { columns, clickable, vm, keyField } = this.props;
    let pagination: PaginationProps | undefined;

    if (vm.currentPaging) {
      pagination = {
        page: vm.currentPaging.offset / vm.currentPaging.limit + 1,
        sizePerPage: vm.currentPaging.limit,
        totalSize: vm.currentPaging.totalItems,
      };
    }

    const rowEvents: RowEvents = {};
    if (clickable) {
      rowEvents.onClick = this.onRowClick;
    }

    return (
      <Observer>
        {() => (
          <Table
            keyField={keyField || "id"}
            columns={columns}
            data={(vm.items || []).slice()}
            remote={true}
            onTableChange={this.onTableChange}
            loading={vm.busyWatcher.isBusy}
            clickable={clickable}
            overlay={overlayFactory({
              spinner: true,
              styles: {
                overlay: (base: any) => ({ ...base, background: "rgba(255, 255, 255, 0.5)" }),
                spinner: (base: any) => ({
                  ...base,
                  "& svg circle": {
                    stroke: "#004b93",
                  },
                }),
              },
            })}
            pagination={paginationFactory(pagination)}
            rowEvents={rowEvents}
          />
        )}
      </Observer>
    );
  }
}
