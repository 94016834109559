import { Input } from "@frui.ts/bootstrap";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Col, Form, InputGroup, Modal } from "react-bootstrap";
import RewardsEditViewModel from "viewModels/shops/detail/invoicing/rewardsEditViewModel";
import preventDefault from "../../../preventDefault";

const rewardsEditView: ViewComponent<RewardsEditViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute } = vm.localization;
  const ta = (code: string) => translateAttribute("parcel_shop", code);

  return (
    <Modal centered={true} show={true} onHide={vm.requestClose}>
      <Form onSubmit={preventDefault(vm.save)}>
        <Modal.Header closeButton>
          <Modal.Title>{vm.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} md={6} className="required">
              <Form.Label>{ta("lm_reward")}</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
                </InputGroup.Prepend>
                <Input target={vm.item} property="lmReward" type="number" />
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={6} className="required">
              <Form.Label>{ta("lmcod_reward")}</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
                </InputGroup.Prepend>
                <Input target={vm.item} property="lmcodReward" type="number" />
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={6} className="required">
              <Form.Label>{ta("fm_reward")}</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
                </InputGroup.Prepend>
                <Input target={vm.item} property="fmReward" type="number" />
              </InputGroup>
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button type="submit" disabled={!vm.isDirty}>
            {tg("save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
});

export default registerView(rewardsEditView, RewardsEditViewModel);
