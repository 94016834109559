import { IPagingFilter } from "@frui.ts/data";
import { RepositoryBase } from "data/repositoryBase";
import PsUserDto from "entities/psUserDto";
import User from "entities/user";
import { Events } from "services/eventBus";

export default class ParcelShopUsersRepository extends RepositoryBase {
  getUsers(shopId: number, paging: IPagingFilter) {
    return this.callApi(api => api.one("ps", shopId).all("users").getPagedEntities(User, paging));
  }

  getUserDetail(shopId: number, id: number) {
    return this.callApi(api => api.one("ps", shopId).one("users", id).getEntity(User));
  }

  createUser(shopId: number, user: PsUserDto) {
    return this.callApi(api => api.one("ps", shopId).all("users").postEntity(user, User), Events.ParcelShopUsers.Changed);
  }

  updateUser(shopId: number, userId: number, user: PsUserDto) {
    return this.callApi(api => api.one("ps", shopId).one("users", userId).putEntity(user), Events.ParcelShopUsers.Changed);
  }

  deleteUser(shopId: number, userId: number) {
    return this.callApi(api => api.one("ps", shopId).one("users", userId).delete(), Events.ParcelShopUsers.Changed);
  }

  resetPassword(shopId: number, userId: number) {
    return this.callApi(api => api.one("ps", shopId).one("users", userId).all("reset_password").post({}));
  }
}
