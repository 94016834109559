import { Select } from "@frui.ts/bootstrap";
import DateInput from "controls/dateInput";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { AdditionalInformationSectionProps } from "./types";

const BusinessState: FunctionComponent<AdditionalInformationSectionProps> = observer(({ vm }) => {
  const ta = (code: string) => vm.localizationService.translateAttribute("additional_information", code);

  return (
    <>
      <h3>{ta("business_state")}</h3>
      <Row>
        <Form.Group as={Col} lg={3} md={6} xs={12}>
          <Form.Label>{ta("business_state")}</Form.Label>
          <Select
            target={vm.item}
            property="business_state"
            items={vm.businessStates || []}
            mode="key"
            textProperty="label"
            keyProperty="value"
            isNumeric={true}
          />
        </Form.Group>
        {vm.isTerminationsVisible && (
          <Form.Group as={Col} lg={3} md={6} xs={12}>
            <Form.Label>{ta("termination_reason")}</Form.Label>
            <Select
              target={vm.item}
              property="termination_reason"
              items={vm.terminations || []}
              mode="key"
              textProperty="label"
              keyProperty="value"
              isNumeric={true}
            />
          </Form.Group>
        )}
        {vm.isContractTypesVisible && (
          <Form.Group as={Col} lg={3} md={6} xs={12}>
            <Form.Label>{ta("contract_type")}</Form.Label>
            <Select
              target={vm}
              property="selectedContractType"
              items={vm.contractTypes || []}
              mode="item"
              textProperty="label"
              keyProperty="value"
            />
          </Form.Group>
        )}
        {vm.isContractEndVisible && (
          <Form.Group as={Col} lg={3} md={6} xs={12}>
            <Form.Label>{ta("contract_ends_at")}</Form.Label>
            <DateInput target={vm.item} property="contract_ends_at" />
          </Form.Group>
        )}
      </Row>
    </>
  );
});

export default BusinessState;
