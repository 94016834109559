import { Select } from "@frui.ts/bootstrap";
import { registerView, ViewComponent } from "@frui.ts/views";
import HeaderRow from "controls/headerRow";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonToolbar, Col, Form } from "react-bootstrap";
import FormLabel from "react-bootstrap/FormLabel";
import Row from "react-bootstrap/Row";
import preventDefault from "../preventDefault";
import SubjectDetailViewModel from "viewModels/subject/subjectDetailViewModel";
import BankAccount from "../common/bankAccount";
import { StringInput } from "controls/stringInput";

const subjectDetailView: ViewComponent<SubjectDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute: ta } = vm.localizationService;

  if (!vm.item) {
    return (
      <>
        <HeaderRow title={vm.isCreating ? tg("user.create.title") : tg("user.edit.title")} />
        <LoadingOverlay loading={vm.busyWatcher.isBusy} />
      </>
    );
  }

  if (!vm.isEditing) {
    return (
      <>
        <HeaderRow title={vm.item.name} />
        <span className="info">{ta("subject", "subject_id")}:</span> {vm.item.subjectId}
        <br />
        {vm.canEditSubject && (
          <ButtonToolbar>
            <Button onClick={vm.toggleEdit} variant="outline-secondary">
              {tg("edit")}
            </Button>
          </ButtonToolbar>
        )}
        <br />
        <Row>
          <Col>
            <h3>{tg("parcel_shop.information")}</h3>

            <table className="table-form">
              <tbody>
                <tr>
                  <th>{ta("subject", "ic")}:</th>
                  <td>{vm.item.ic}</td>
                </tr>
                <tr>
                  <th>{ta("subject", "dic")}:</th>
                  <td>{vm.item.dic}</td>
                </tr>
                <tr>
                  <th>{ta("subject", "name")}:</th>
                  <td>{vm.item.name}</td>
                </tr>
                <tr>
                  <th>{ta("subject", "subname")}:</th>
                  <td>{vm.item.subname}</td>
                </tr>
                <tr>
                  <th>{ta("subject", "depo")}:</th>
                  <td>{vm.getDepoName(vm.item.depoId)}</td>
                </tr>
                <tr>
                  <th>{ta("subject", "subject_type")}:</th>
                  <td>{vm.getSubjectType(vm.item.subjectTypeId)}</td>
                </tr>
                <tr>
                  <th>{ta("subject", "bank_account")}:</th>
                  <td>{vm.getBankAccount(vm.item)}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <HeaderRow title={tg("subject.edit")} />
      <span className="info">{ta("subject", "subject_id")}:</span> {vm.item.subjectId}
      <br />
      <br />
      <Form onSubmit={preventDefault(vm.update)}>
        <Form.Row>
          <Col md={3}>
            <Form.Group>
              <FormLabel column={false}>{ta("subject", "ic")}</FormLabel>
              <StringInput trim={true} target={vm.item} property="ic" disabled={true} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <FormLabel column={false}>{ta("subject", "subject_type")}</FormLabel>
              <Select
                target={vm.item}
                property="subjectTypeId"
                items={vm.subjectTypes}
                mode="key"
                keyProperty="value"
                disabled={true}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={3}>
            <Form.Group>
              <FormLabel column={false}>{ta("subject", "dic")}</FormLabel>
              <StringInput trim={true} target={vm.item} property="dic" />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <Form.Group className="required">
              <FormLabel column={false}>{ta("subject", "name")}</FormLabel>
              <StringInput trim={true} target={vm.item} property="name" />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <FormLabel column={false}>{ta("subject", "subname")}</FormLabel>
              <StringInput trim={true} target={vm.item} property="subname" />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={4}>
            <Form.Group className="required">
              <FormLabel column={false}>{ta("subject", "depo")}</FormLabel>
              <Select
                target={vm.item}
                property="depoId"
                items={vm.depos}
                allowEmpty={true}
                mode="key"
                keyProperty="value"
                isNumeric={true}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <BankAccount vm={vm} />
        <ButtonToolbar>
          <Button type="submit" disabled={!vm.canSave} size="lg">
            {tg("save")}
          </Button>
          <Button variant="outline-secondary" onClick={vm.toggleEdit} size="lg">
            {tg("cancel")}
          </Button>
        </ButtonToolbar>
      </Form>
      <LoadingOverlay loading={vm.busyWatcher.isBusy} />
    </>
  );
});

export default registerView(subjectDetailView, SubjectDetailViewModel);
