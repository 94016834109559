import { IPagingFilter, PagedQueryResult } from "@frui.ts/data";
import CreateOpportunityDto from "entities/createOpportunityDto";
import OpportunityDetailDto from "entities/opportunityDetailDto";
import UpdateOpportunityDto from "entities/updateOpportunityDto";
import { Events } from "services/eventBus";
import { RepositoryBase } from "../repositoryBase";

export default class OpportunitiesRepository extends RepositoryBase {
  getOpportunity(id: number): Promise<OpportunityDetailDto> {
    return this.callApi(api => api.one("opportunities", id).getEntity(OpportunityDetailDto));
  }

  getOpportunities(paging: IPagingFilter, filter: any): Promise<PagedQueryResult<OpportunityDetailDto>> {
    return this.callApi(api => api.all("opportunities").getPagedEntities(OpportunityDetailDto, paging, filter));
  }

  createOpportunity(payload: CreateOpportunityDto) {
    return this.callApi(api => api.all("opportunities").postEntity(payload), Events.Opportunities.Changed);
  }

  updateOpportunity(id: number, payload: UpdateOpportunityDto) {
    return this.callApi(api => api.one("opportunities", id).putEntity(payload), Events.Opportunities.Changed);
  }

  deleteOpportunity(id: number) {
    return this.callApi(api => api.one("opportunities", id).delete(), Events.Opportunities.Changed);
  }
}
