import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CreateCompetitionInPsDto {
  @observable
  @Expose({ name: "enum_competition_id" })
  enumCompetitionId!: number;

  @observable
  @Expose({ name: "entity_type" })
  entityType!: number;

  @observable
  capacity?: number;

  @observable
  @Expose({ name: "cod_rate" })
  codRate?: number;

  @observable
  @Expose({ name: "without_cod_rate" })
  withoutCodRate?: number;

  @observable
  @Expose({ name: "returned_rate" })
  returnedRate?: number;

  @observable
  @Expose({ name: "monthly_rent" })
  monthlyRent?: number;

  @observable
  comment?: string;

  /**
   * Ps id
   */
  @observable
  @Expose({ name: "ps_id" })
  psId?: number;

  static ValidationRules = {
    enumCompetitionId: { required: true, number: true },
    entityType: { required: true, number: true },
    capacity: { number: true },
    codRate: { number: true },
    withoutCodRate: { number: true },
    returnedRate: { number: true },
    monthlyRent: { number: true },
    psId: { number: true },
  };
}
