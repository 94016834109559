import { registerView, ViewComponent, View } from "@frui.ts/views";
import LoadingOverlay from "controls/loadingOverlay";
import ParcelBoxStatusType from "entities/parcelBoxDtoState";
import { observer } from "mobx-react-lite";
import React from "react";
import { Alert, Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import ParcelBoxDetailViewModel, { ParcelBoxEvent } from "viewModels/parcelBox/parcelBoxDetailViewModel";
import { TERMINAL_SERVICE_URL } from "./detailOrderService";

// eslint-disable-next-line sonarjs/cognitive-complexity
const parcelBoxDetailView: ViewComponent<ParcelBoxDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute: ta } = vm.localizationService;

  if (!vm.item) {
    return (
      <>
        <LoadingOverlay loading={vm.busyWatcher.isBusy} />
      </>
    );
  }

  const actions = [];

  if (vm.item.state !== ParcelBoxStatusType.Serviced && vm.item.state !== ParcelBoxStatusType.Discarded) {
    actions.push(
      <Button
        variant="outline-secondary"
        key="pb_to_service_action"
        onClick={() => vm.statusConfirmation(ParcelBoxEvent.ToService)}>
        {tg("parcel_box.events.to_service.button")}
      </Button>
    );
  }

  if (vm.item.state === ParcelBoxStatusType.Unassigned || vm.item.state === ParcelBoxStatusType.Assigned) {
    actions.push(
      <a
        className="btn btn-outline-secondary"
        key="order_service_ter"
        href={TERMINAL_SERVICE_URL}
        target="_new"
        onClick={vm.closeOrderServiceForm}>
        {tg("parcel_box.events.order_terminal_service.button")}
      </a>
    );
  }

  if (vm.item.state === ParcelBoxStatusType.Serviced) {
    actions.push(
      <Button variant="outline-secondary" key="order_service" onClick={vm.openOrderServiceForm}>
        {tg("parcel_box.events.order_service.button")}
      </Button>,
      <Button
        variant="outline-secondary"
        key="pb_return_from_service_action"
        onClick={() => vm.statusConfirmation(ParcelBoxEvent.ReturnedFromService)}>
        {tg("parcel_box.events.returned_from_service.button")}
      </Button>
    );
  }

  if (!vm.item.ps && vm.item.state === ParcelBoxStatusType.Unassigned) {
    actions.push(
      <Button variant="outline-secondary" key="pb_assign_action" onClick={vm.openAssignForm}>
        {tg("parcel_box.events.assign_to_ps.button")}
      </Button>
    );
  }

  if (vm.item.ps && vm.item.state !== ParcelBoxStatusType.Serviced) {
    actions.push(
      <Button variant="outline-secondary" key="pb_unassign_action" onClick={vm.unassign}>
        {tg("parcel_box.events.remove_from_ps.button")}
      </Button>
    );
  }

  if (vm.item.state !== ParcelBoxStatusType.Discarded) {
    actions.push(
      <Button variant="outline-secondary" key="pb_edit_action" onClick={vm.openEditForm}>
        {tg("parcel_box.events.edit.button")}
      </Button>
    );
  }

  if (vm.item.state !== ParcelBoxStatusType.Discarded) {
    actions.push(
      <Button variant="outline-danger" key="pb_discard_action" onClick={() => vm.statusConfirmation(ParcelBoxEvent.Discard)}>
        {tg("parcel_box.events.discard.button")}
      </Button>
    );
  }

  return (
    <>
      {vm.item.state === ParcelBoxStatusType.Unassigned && <Alert variant="info">{tg("parcel_box.unassigned")}</Alert>}
      <div className="header-row row">
        <div className="col-md">
          <h1>
            {vm.name}
            {vm.stateEnumItem && <div className={`badge ${vm.stateEnumItem.color}`}>{vm.stateEnumItem.name}</div>}
          </h1>
        </div>
      </div>
      {!vm.isEditFormVisible && (
        <>
          <ButtonToolbar>{actions}</ButtonToolbar>
          <br />
          <Row>
            <Col>
              <h3>{tg("parcel_shop.information")}</h3>

              <table className="table-form">
                <tbody>
                  <tr>
                    <th>{ta("parcel_box", "icp")}:</th>
                    <td>{vm.item.icp}</td>
                  </tr>
                  <tr>
                    <th>{ta("parcel_box", "terminal_tid")}:</th>
                    <td>{vm.item.terminalTid}</td>
                  </tr>
                  <tr>
                    <th>{ta("parcel_box", "terminal_sn")}:</th>
                    <td>{vm.item.terminalSn}</td>
                  </tr>
                  {vm.item.ps && (
                    <tr>
                      <th>{ta("parcel_box", "type_id")}:</th>
                      <td>{vm.getTypeName(vm.item.ps.typeId)}</td>
                    </tr>
                  )}
                  {vm.item.ps && (
                    <tr>
                      <th>{ta("parcel_box", "ps_name")}:</th>
                      <td>{vm.item.ps.name}</td>
                    </tr>
                  )}
                  {vm.item.ps && (
                    <tr>
                      <th>{ta("parcel_box", "depo_id")}:</th>
                      <td>{vm.getDepoName(vm.item.ps.depoId)}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </>
      )}
      <View vm={vm} context="orderService" />
      <View vm={vm} context="assign" />
      <View vm={vm} context="edit" />
    </>
  );
});

export default registerView(parcelBoxDetailView, ParcelBoxDetailViewModel);
