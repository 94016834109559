import { observable } from "mobx";
import { Expose, Type } from "class-transformer";
import PsDepoInfoDto from "./psDepoInfoDto";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ScanDeviceDetailDto {
  /**
   * Internal generated ID
   */
  id!: number;

  /**
   * Scan device id
   */
  @observable
  @Expose({ name: "device_type_id" })
  deviceTypeId!: number;

  /**
   * Status
   */
  @observable
  state!: number;

  /**
   * IMEI
   */
  @observable
  imei!: string;

  /**
   * Parcelshop identifier
   */
  @observable
  @Expose({ name: "ps_id" })
  psId!: number;

  @observable
  @Type(() => PsDepoInfoDto)
  ps?: PsDepoInfoDto;

  /**
   * Amortized price
   */
  @observable
  price!: number;

  @observable
  history!: string[];

  static ValidationRules = {
    id: { required: true, number: true },
    deviceTypeId: { required: true, number: true },
    state: { required: true, number: true },
    imei: { required: true },
    psId: { required: true, number: true },
    price: { required: true, number: true },
    history: { required: true },
  };
}
