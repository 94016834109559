import { ReactComponent as AttachmentIcon } from "assets/icons/icon-attachment.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-delete.svg";
import Uploader from "controls/uploader";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useCallback } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FileRejection } from "react-dropzone";
import { UploadItem } from "viewModels/shops/detail/edit/additionalInformationViewModelBase";
import { AdditionalInformationSectionProps } from "./types";

interface AttachmentsListProps {
  files: UploadItem[];
  onDelete: (file: UploadItem) => void;
}

const AttachmentsList: React.FC<AttachmentsListProps> = ({ files, onDelete }) => {
  return (
    <>
      {files.map((item, index) => (
        <div key={index} className="d-flex mb-2" style={{ backgroundColor: "#ffffff", padding: "12px 14px", borderRadius: 5 }}>
          <AttachmentIcon className="mr-2" />
          <span style={{ flexGrow: 2 }}>{item.name}</span>
          <DeleteIcon
            width={20}
            height={20}
            className="align-self-end"
            style={{ cursor: "pointer" }}
            onClick={() => onDelete(item)}
          />
        </div>
      ))}
    </>
  );
};

const Attachments: FunctionComponent<AdditionalInformationSectionProps> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral: tg } = vm.localizationService;

  const ta = (code: string) => translateAttribute("additional_information", code);

  const handleOnAttachContract = useCallback(
    (acceptedFiles: File[], fileRejections?: FileRejection[]) => vm.onAttach(0, acceptedFiles, fileRejections),
    []
  );

  const handleOnAttachOther = useCallback(
    (acceptedFiles: File[], fileRejections?: FileRejection[]) => vm.onAttach(1, acceptedFiles, fileRejections),
    []
  );

  return (
    <>
      <h3>{ta("documents")}</h3>
      <Row>
        <Form.Group as={Col} md={6} xs={12}>
          <Form.Label>{ta("contracts")}</Form.Label>
          <Uploader
            onDrop={handleOnAttachContract}
            accept={[
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/vnd.oasis.opendocument.text",
            ]}
          />
          <AttachmentsList files={vm.contractFiles} onDelete={vm.onRemoveFile} />
        </Form.Group>
        <Form.Group as={Col} md={6} xs={12}>
          <Form.Label>{ta("other_docs")}</Form.Label>
          <Uploader
            onDrop={handleOnAttachOther}
            accept={[
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/vnd.oasis.opendocument.text",
              "image/jpeg",
              "image/png",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.oasis.opendocument.spreadsheet",
              "text/csv",
            ]}
          />
          <AttachmentsList files={vm.otherFiles} onDelete={vm.onRemoveFile} />
        </Form.Group>
      </Row>
    </>
  );
});

export default Attachments;
