import { IPagingFilter, PagedQueryResult } from "@frui.ts/data";
import CreateSubjectDto from "entities/createSubjectDto";
import Subject from "entities/subject";
import UpdateSubjectDto from "entities/updateSubjectDto";
import SubjectFindDto from "entities/subjectFindDto";
import { Events } from "services/eventBus";
import { RepositoryBase } from "../repositoryBase";

export default class SubjectRepository extends RepositoryBase {
  // Subjects

  getSubjects(paging: IPagingFilter, filter: any): Promise<PagedQueryResult<Subject>> {
    return this.callApi(api => api.all("subjects").getPagedEntities(Subject, paging, filter));
  }

  // Subject

  getSubject(id: number) {
    return this.callApi(api => api.one("subjects", id).getEntity(Subject));
  }

  getSubjectBySubjectTypeCodeAndIco(subjectTypeCode: string, ico: string) {
    return this.apiFactory().one("subjects/subject_type_code", subjectTypeCode).one("ic", ico).getEntity(SubjectFindDto);
  }

  createSubject(item: CreateSubjectDto) {
    return this.callApi(api => api.all("subjects").postEntity(item, Subject), Events.Subjects.Changed);
  }

  updateSubject(item: UpdateSubjectDto & { id: number }) {
    return this.callApi(api => api.one("subjects", item.id).putEntity(item, Subject), Events.Subjects.Changed);
  }
}
