import AccessPointViewModel from "./accessPointViewModel";
import WarehouseViewModel from "./warehouse/warehouseViewModel";
import EditShopViewModel from "./edit/editShopViewModel";
import LocalizationService from "services/localizationService";
import EnumService from "services/enum";
import SecurityService from "services/securityService";
import ConfirmationService from "services/confirmationService";
import NotificationService from "services/notificationService";
import EventBus from "services/eventBus";
import { Router, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import PotentialRepository from "data/repositories/potentialRepository";
import PotentialPsDetailDto from "entities/potentialPsDetailDto";
import { action } from "mobx";
import AttachmentsRepository from "data/repositories/attachmentsRepository";
import EnumRepository from "data/repositories/enumRepository";
import UsersRepository from "data/repositories/usersRepository";
import ActivityDetailViewModel from "./activity/activityDetailViewModel";
import UserContext from "services/userContext";

@Router.registerRoute({ name: Router.Self, route: ":id" })
export default class PotentialDetailViewModel extends AccessPointViewModel<PotentialPsDetailDto> {
  constructor(
    shopId: number,
    warehouseVMFactory: ReturnType<typeof WarehouseViewModel.Factory>,
    editVMFactory: ReturnType<typeof EditShopViewModel.Factory>,
    activityVMFactory: ReturnType<typeof ActivityDetailViewModel.Factory>,
    repository: PotentialRepository,
    localizationService: LocalizationService,
    enumService: EnumService,
    security: SecurityService,
    confirmationService: ConfirmationService,
    notifications: NotificationService,
    eventBus: EventBus,
    attachmentsRepository: AttachmentsRepository,
    enumRepository: EnumRepository,
    usersRepository: UsersRepository,
    userContext: UserContext,
    router: Router
  ) {
    super(
      shopId,
      warehouseVMFactory,
      editVMFactory,
      activityVMFactory,
      repository,
      localizationService,
      enumService,
      security,
      confirmationService,
      notifications,
      eventBus,
      attachmentsRepository,
      enumRepository,
      usersRepository,
      userContext,
      router
    );

    this.navigationName = `${shopId}-potential`;
  }

  @watchBusy
  async loadDetail() {
    return await super.loadDetail();
  }

  @action.bound
  onCreateItemFromPotential() {
    this.router.navigate(
      "newParcelShop",
      {
        subjectId: this.item.shop.subject.subjectTypeId,
        ico: this.item.shop.subject.ic,
      },
      {
        potentialId: this.item.shop.id,
      }
    );
  }

  static Factory({ container }: interfaces.Context) {
    return (id: number) => {
      return new PotentialDetailViewModel(
        id,
        container.get(WarehouseViewModel.Factory),
        container.get(EditShopViewModel.Factory),
        container.get(ActivityDetailViewModel.Factory),
        container.get(PotentialRepository),
        container.get(LocalizationService),
        container.get(EnumService),
        container.get(SecurityService),
        container.get(ConfirmationService),
        container.get(NotificationService),
        container.get(EventBus),
        container.get(AttachmentsRepository),
        container.get(EnumRepository),
        container.get(UsersRepository),
        container.get(UserContext),
        container.get(Router)
      );
    };
  }
}
