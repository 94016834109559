import { Expose, Type } from "class-transformer";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Vacation {
  /**
   * id
   */
  id!: number;

  /**
   * Parcel shop foreign key
   */
  @observable
  @Expose({ name: "ps_id" })
  psId!: number;

  /**
   * From
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "date_from" })
  dateFrom!: Date;

  /**
   * To
   */
  @observable
  @Type(() => Date)
  @Expose({ name: "date_to" })
  dateTo!: Date;

  /**
   * Already used?
   */
  @observable
  disabled!: boolean;

  @observable
  @Type(() => Date)
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Type(() => Date)
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    psId: { required: true, number: true },
    dateFrom: { required: true },
    dateTo: { required: true },
    disabled: { required: true },
    createdAt: { required: true },
    updatedAt: { required: true },
  };
}

export class VacationDisplayModel extends Vacation {}
