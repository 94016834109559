import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import CommonDetailViewModel from "viewModels/shops/detail/commonDetailViewModel";

const commonDetailView: ViewComponent<CommonDetailViewModel> = observer(({ vm }) => {
  return (
    <>
      <View vm={vm.activeChild} />
    </>
  );
});

export default registerView(commonDetailView, CommonDetailViewModel);
