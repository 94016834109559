import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import AuthorResponseDto from "./authorResponseDto";
import MessageListResponseDtoType from "./messageListResponseDtoType";
import ParcelShopResponseDto from "./parcelShopResponseDto";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class MessageListResponseDto {
  id!: number;

  @observable
  subject!: string;

  @observable
  @Type(() => AuthorResponseDto)
  author?: AuthorResponseDto;

  @observable
  @Type(() => ParcelShopResponseDto)
  @Expose({ name: "parcel_shops" })
  parcelShops?: ParcelShopResponseDto[];

  /**
   * For received messages, true if was already read by someone from PPL
   */
  @observable
  @Expose({ name: "read_by_ppl" })
  readByPpl!: boolean;

  /**
   * Readable list of recipients
   */
  @observable
  @Expose({ name: "recipients_list" })
  recipientsList!: string[];

  /**
   * Readable list of depo_ids of recipients
   */
  @observable
  @Expose({ name: "depo_ids" })
  depoIds?: number[];

  /**
   * Readable list of ps_category_ids of recipients
   */
  @observable
  @Expose({ name: "ps_category_ids" })
  psCategoryIds?: number[];

  /**
   * Readable list of salesman_ids of recipients
   */
  @observable
  @Expose({ name: "salesman_ids" })
  salesmanIds?: number[];

  /**
   * Message type Received / Sent
   */
  @observable
  type!: MessageListResponseDtoType;

  @observable
  resolved!: boolean;

  @observable
  @Expose({ name: "parent_id" })
  parentId!: number;

  @observable
  @Expose({ name: "reply_counter" })
  replyCounter!: number;

  /**
   * Could be reply done?
   */
  @observable
  @Expose({ name: "group_message" })
  groupMessage!: boolean;

  @observable
  @Expose({ name: "created_at" })
  createdAt!: string;

  @observable
  @Expose({ name: "updated_at" })
  updatedAt!: string;

  static ValidationRules = {
    id: { required: true, number: true },
    subject: { required: true },
    readByPpl: { required: true },
    recipientsList: { required: true },
    type: { required: true },
    resolved: { required: true },
    parentId: { required: true, number: true },
    replyCounter: { required: true, number: true },
    groupMessage: { required: true },
    createdAt: { required: true },
    updatedAt: { required: true },
  };
}
