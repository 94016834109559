import { bound } from "@frui.ts/helpers";
import { validateAll } from "@frui.ts/validation";
import { classToPlain, plainToClass } from "class-transformer";
import AttachmentsRepository from "data/repositories/attachmentsRepository";
import CompetitionsRepository from "data/repositories/competitionsRepository";
import EnumRepository from "data/repositories/enumRepository";
import type IAccessPointRepository from "data/repositories/IAccessPointRepository";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import PotentialRepository from "data/repositories/potentialRepository";
import UsersRepository from "data/repositories/usersRepository";
import PotentialPs from "entities/potentialPs";
import PotentialPsDetailDto from "entities/potentialPsDetailDto";
import { interfaces } from "inversify";
import ParcelShop from "manualEntities/parcelShop";
import AdditionalInformation from "models/shops/newShop/additionalInformation";
import ShopDetailContext from "models/shops/shopDetailContext";
import EnumService from "services/enum";
import EventBus, { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import { scrollTop } from "utils/helpers";
import AdditionalInformationViewModelBase, { UploadItem } from "../../detail/edit/additionalInformationViewModelBase";
import { EditShopNotificationScope, ISubDetailViewModel } from "../types";
import { Router, watchBusy } from "@frui.ts/screens";
import CreateAttachmentDto from "entities/createAttachmentDto";

const route = "additional";

@Router.registerRoute({ name: Router.Self, route })
export default class EditAdditionalInformationViewModel
  extends AdditionalInformationViewModelBase
  implements ISubDetailViewModel {
  constructor(
    context: ShopDetailContext<PotentialPsDetailDto | ParcelShop>,
    isParcelBox: boolean,
    isAlzaBox: boolean,
    isPotential: boolean,
    private repository: IAccessPointRepository<PotentialPs | ParcelShop, PotentialPsDetailDto | ParcelShop>,
    userRepository: UsersRepository,
    public localizationService: LocalizationService,
    enums: EnumService,
    enumRepository: EnumRepository,
    competitionsRepository: CompetitionsRepository,
    attachmentsRepository: AttachmentsRepository,
    eventBus: EventBus,
    private notifications: NotificationService
  ) {
    super(
      context,
      isParcelBox,
      isAlzaBox,
      isPotential,
      userRepository,
      localizationService,
      enums,
      enumRepository,
      competitionsRepository,
      attachmentsRepository,
      eventBus
    );
    this.name = localizationService.translateGeneral("parcel_shop.additional_information");
    this.navigationName = route;
  }

  get canSave() {
    return this.isDirty;
  }

  @watchBusy
  saveAttachments() {
    const { hasCreate, hasDelete } = this.calculateCRUD<UploadItem>(this.files, this.oldAttachments);
    return Promise.all([
      ...hasCreate.map(item => {
        const attachment = new CreateAttachmentDto();
        if (this.context && this.context.shop.id) {
          attachment.psId = `${this.context.shop.id}`;
        }
        attachment.entityType = this.isPotential ? "1" : "0";
        attachment.documentTypeId = `${item.documentTypeId}`;
        attachment.file = item.file!;

        return this.attachmentsRepository.uploadAttachment(attachment);
      }),
      ...hasDelete.map(item => this.attachmentsRepository.deleteAttachment(item.id!)),
    ]);
  }

  @bound
  async save(): Promise<any> {
    if (this.context && validateAll([this.item, ...this.item.competitions])) {
      let updateItem = {
        ...classToPlain(this.context.shop),
        contract_sign_date: this.item.contract_sign_date ?? null,
        business_state: this.item.business_state ?? null,
        termination_reason: this.item.termination_reason ?? null,
        business_type_id: this.item.business_type_id ?? null,
        contract_ends_at: this.item.contract_ends_at ?? null,
      } as ParcelShop | PotentialPsDetailDto;

      if (this.isPotential) {
        updateItem = plainToClass(PotentialPsDetailDto, updateItem);
        updateItem.phase = this.item.potential_phase;
        updateItem.phaseNote = this.item.potential_phase_note;
      } else {
        updateItem = plainToClass(ParcelShop, updateItem);
      }

      await Promise.all([
        this.saveCompetitions(),
        this.saveAttachments(),
        this.repository.updateItem(this.context.shop.id, updateItem),
      ]);

      this.notifications.addNotification(
        this.localizationService.translateGeneral("parcel_shop.edit.additional_information.saved_message"),
        SeverityLevel.success,
        EditShopNotificationScope
      );

      this.eventBus.publish(Events.ParcelShops.AdditionalInformation.Changed);
    }

    scrollTop();
    return Promise.resolve();
  }

  discardChanges(): void | Promise<any> {
    return Promise.resolve();
  }

  onInitialize() {
    return super.onInitialize();
  }

  protected loadDetail() {
    return Promise.resolve(new AdditionalInformation());
  }

  static Factory({ container }: interfaces.Context) {
    return (
      context: ShopDetailContext<PotentialPsDetailDto | ParcelShop>,
      isParcelBox: boolean,
      isAlzaBox: boolean,
      isPotential: boolean
    ) =>
      new EditAdditionalInformationViewModel(
        context,
        isParcelBox,
        isAlzaBox,
        isPotential,
        isPotential ? container.get(PotentialRepository) : container.get(ParcelShopRepository),
        container.get(UsersRepository),
        container.get(LocalizationService),
        container.get(EnumService),
        container.get(EnumRepository),
        container.get(CompetitionsRepository),
        container.get(AttachmentsRepository),
        container.get(EventBus),
        container.get(NotificationService)
      );
  }
}
