import { registerView, View, ViewComponent } from "@frui.ts/views";
import HeaderRow from "controls/headerRow";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import RewardsViewModel from "viewModels/shops/detail/invoicing/rewardsViewModel";

const rewardsView: ViewComponent<RewardsViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute: ta, formatNumber } = vm.localization;

  return (
    <>
      <HeaderRow level={3} title={vm.name}>
        {vm.canEdit && (
          <ButtonToolbar>
            <Button onClick={vm.openEdit}>{tg("edit")}</Button>
          </ButtonToolbar>
        )}
      </HeaderRow>

      <div className="table-wrapper">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>{ta("parcel_shop", "lm_reward")}</th>
              <th>{ta("parcel_shop", "lmcod_reward")}</th>
              <th>{ta("parcel_shop", "fm_reward")}</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{`${formatNumber(vm.item.lm_reward)} ${tg("currency_code")}`}</td>
              <td>{`${formatNumber(vm.item.lmcod_reward)} ${tg("currency_code")}`}</td>
              <td>{`${formatNumber(vm.item.fm_reward)} ${tg("currency_code")}`}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <View vm={vm.activeChild} />
    </>
  );
});

export default registerView(rewardsView, RewardsViewModel);
