import { Input, Check } from "@frui.ts/bootstrap";
import { DataTable } from "@frui.ts/dataviews";
import { ViewComponent } from "@frui.ts/views";
import FilterButton from "controls/filterButton";
import HeaderRow from "controls/headerRow";
import ItemsSelector from "controls/itemsSelector";
import LoadingOverlay from "controls/loadingOverlay";
import PagingInfo from "controls/pagingInfo";
import { createColumnsContext, DataColumn } from "controls/tableHelpers";
import TablePager from "controls/tablePager";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Form } from "react-bootstrap";
import { formatDate } from "utils/helpers";
import OpportunitiesViewModel from "viewModels/opportunities/opportunitiesViewModel";
import iconEdit from "assets/icons/icon-external-link.svg";
import OpportunityDetailDto from "entities/opportunityDetailDto";

const tableColumns: DataColumn<OpportunityDetailDto, OpportunitiesViewModel>[] = [
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("opportunity", "cust_id")}
        <FilterButton {...filterButtonProps} isActive={!!vm.appliedFilter?.cust_id} onClear={() => vm.clearFilter("cust_id")}>
          <Form.Group>
            <Input placeholder={tg("search")} size="sm" data-search target={vm.filter} property="cust_id" />
          </Form.Group>
        </FilterButton>
      </>
    ),
    property: "cust_id" as any,
    valueFormatter: ({ item, context }) => context.vm.custId(item),
    sortable: true,
  },
  {
    headerClassName: "vm",
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("opportunity", "ps_id")}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.ps_ids?.length}
          onClear={() => vm.clearFilter("ps_ids")}>
          <ItemsSelector
            target={vm.filter}
            property="ps_ids"
            searchTextProperty="parcel_shop_name"
            items={vm.allowedParcelShops || []}
            searchPlaceholder={tg("search")}
            selectAllText={tg("select_all")}
          />
        </FilterButton>
      </>
    ),
    property: "name" as any,
    valueFormatter: ({ item, context }) => <span title={context.vm.psName(item)}>{context.vm.psName(item)}</span>,
    tag: "received",
    sortable: true,
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("opportunity", "enum_opportunity_id")}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.enum_opportunity_ids?.length}
          onClear={() => vm.clearFilter("enum_opportunity_ids")}>
          <ItemsSelector
            target={vm.filter}
            property="enum_opportunity_ids"
            searchTextProperty="type_name"
            items={vm.opportunityTypes || []}
            searchPlaceholder={tg("search")}
            selectAllText={tg("select_all")}
          />
        </FilterButton>
      </>
    ),
    property: "enum_opportunity_id" as any,
    valueFormatter: ({ item, context }) => context.vm.getOpportunityType(item.enumOpportunityId, item.ps?.parcelBox ?? false),
    sortable: true,
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("opportunity", "author_id")}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.author_ids?.length}
          onClear={() => vm.clearFilter("author_ids")}>
          <ItemsSelector
            target={vm.filter}
            property="author_ids"
            searchTextProperty="author_name"
            items={vm.usersForFilter || []}
            searchPlaceholder={tg("search")}
            selectAllText={tg("select_all")}
          />
        </FilterButton>
      </>
    ),
    property: "author_id" as any,
    valueFormatter: ({ item, context }) => context.vm.getUserName(item.authorId),
    sortable: true,
  },
  {
    titleFactory: ({ ta }) => ta("opportunity", "created_at"),
    property: "created_at" as any,
    valueFormatter: ({ item }) => formatDate(item.createdAt),
    sortable: true,
    headerClassName: "column-date",
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("opportunity", "enum_status_id")}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.enum_status_ids?.length}
          onClear={() => vm.clearFilter("enum_status_ids")}>
          <ItemsSelector
            target={vm.filter}
            property="enum_status_ids"
            searchTextProperty="status_name"
            items={vm.opportunityStatuses || []}
            searchPlaceholder={tg("search")}
            selectAllText={tg("select_all")}
          />
        </FilterButton>
      </>
    ),
    property: "enum_status_id" as any,
    valueFormatter: ({ item, context }) => context.vm.getOpportunityStatus(item.enumStatusId, item.ps?.parcelBox ?? false),
    sortable: true,
  },
  {
    titleFactory: ({ ta }) => <span className="column-container">{ta("opportunity", "edit")}</span>,
    headerClassName: "edit",
    valueFormatter: ({ item, context }) => (
      <span className="column-container">
        <Button
          size="sm"
          variant="outline-secondary"
          className="noRowClick button-icon"
          onClick={() => context.vm.openDetail(item)}>
          <img src={iconEdit} alt="edit" />
        </Button>
      </span>
    ),
    property: "edit" as any,
  },
];

const PaymentTerminalList: ViewComponent<OpportunitiesViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateModel: tm } = vm.localizationService;

  const columnsContext = createColumnsContext(vm);

  return (
    <div className="opportunities-list">
      <HeaderRow title={tm("opportunity", 3)}>
        <div className="d-flex justify-content-end align-items-center">
          <Check
            custom
            inline
            target={vm.filter}
            property="show_complete_opportunites"
            label={tg("opportunity.show_complete_opportunities")}
            onValueChanged={vm.applyFilterAndLoad}
            disabled={vm.isShowCompleteOpportunitiesDisabled}
          />
          <Button onClick={vm.onItemAdd}>{tg("opportunity.add")}</Button>
        </div>
      </HeaderRow>

      <PagingInfo paging={vm.currentPaging} modelName="result" localization={vm.localizationService} />

      <div className="react-bootstrap-table">
        <DataTable
          className="table custom-table opportunities table-striped"
          pagingFilter={vm.pagingFilter}
          itemKey="id"
          items={vm.items || []}
          columns={tableColumns}
          context={columnsContext}
          onColumnSort={vm.sortByColumn}
        />
      </div>
      <TablePager vm={vm} />
      <LoadingOverlay loading={vm.busyWatcher.isBusy} />
    </div>
  );
});

export default PaymentTerminalList;
