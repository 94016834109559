import React, { FunctionComponent } from "react";
import { ConfigurationSectionProps } from "./types";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import classnames from "classnames";
import { Input, Select } from "@frui.ts/bootstrap";
import { observer } from "mobx-react-lite";

const RecurringPayments: FunctionComponent<ConfigurationSectionProps> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localizationService;

  return (
    <>
      <h3>{vm.localizationService.translateGeneral("parcel_box.recurring_payments.title")}</h3>
      <Row>
        <Form.Group as={Col} lg={3} md={6} xs={12} className={classnames({ required: vm.isRecurringPaymentsRequired })}>
          <Form.Label>{vm.localizationService.translateAttribute("parcel_box", "rent_price")}</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
            </InputGroup.Prepend>
            <Input target={vm.item} property="rentPrice" type="number" />
          </InputGroup>
        </Form.Group>

        <Form.Group as={Col} lg={3} md={6} xs={12} className={classnames({ required: vm.isRecurringPaymentsRequired })}>
          <Form.Label>{vm.localizationService.translateAttribute("parcel_box", "deposit_amount")}</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>{tg("currency_code")}</InputGroup.Text>
            </InputGroup.Prepend>
            <Input target={vm.item} property="electricityPrice" type="number" />
          </InputGroup>
        </Form.Group>

        <Form.Group as={Col} lg={3} md={6} xs={12} className={classnames({ required: vm.isRecurringPaymentsRequired })}>
          <Form.Label>{vm.localizationService.translateAttribute("parcel_box", "invoicing_cycle")}</Form.Label>
          <Select
            target={vm.item}
            property="pbInvoicingCycle"
            items={vm.pbInvoicingCycles || []}
            mode="key"
            textProperty="label"
            keyProperty="value"
            isNumeric={true}
            allowEmpty={vm.isAlzaBox}
          />
        </Form.Group>
      </Row>
    </>
  );
});

export default RecurringPayments;
