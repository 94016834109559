// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
type ParcelBoxDtoState = "0" | "1" | "2" | "3";

enum ParcelBoxStatusType {
  Unassigned = 0,
  Assigned = 1,
  Serviced = 2,
  Discarded = 3,
}

export default ParcelBoxStatusType;
