import { bound, nameof } from "@frui.ts/helpers";
import { watchBusy } from "@frui.ts/screens";
import { ISelectItem } from "@frui.ts/views";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import ScanDevicesRepository from "data/repositories/scanDevicesRepository";
import ScanDevice, { ScanDeviceFilter } from "manualEntities/scanDevice";
import { EventBus } from "light-event-bus";
import { ParcelShopFilter } from "manualEntities/parcelShop";
import { action, observable, runInAction, toJS } from "mobx";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import UserContext from "services/userContext";
import { depoSelectOption, formatDepoName, scrollTop } from "utils/helpers";
import ListViewModelBase from "viewModels/listViewModelBase";
import ScanDeviceDetailViewModel from "./scanDeviceDetailViewModel";
import ScanDevicesImportViewModel from "./scanDevicesImportViewModel";
import { SortingDirection } from "@frui.ts/data";
import AccessPointPartners from "models/enumerations/accessPointPartners";
import ParcelShopStatus from "manualEntities/parcelShopStatus";

export default class ScanDevicesViewModel extends ListViewModelBase<ScanDevice, ScanDeviceFilter, ScanDeviceDetailViewModel> {
  static notificationScope = "scan_devices";

  @observable scanDevicesImportViewModel?: ScanDevicesImportViewModel;
  @observable.shallow allowedParcelShops?: ISelectItem[];

  constructor(
    private repository: ScanDevicesRepository,
    private parcelShopsRepository: ParcelShopRepository,
    private eventBus: EventBus,
    private scanDeviceDetailViewModelFactory: ReturnType<typeof ScanDeviceDetailViewModel.Factory>,
    private scanDevicesImportViewModelFactory: ReturnType<typeof ScanDevicesImportViewModel.Factory>,
    public confirmationService: ConfirmationService,
    public localizationService: LocalizationService,
    private notificationService: NotificationService,
    private enums: EnumService,
    private userContext: UserContext
  ) {
    super();
    this.navigationName = "scan_devices";
    this.name = localizationService.translateModel("scan_device", 3);
    this.pagingFilter.limit = 10; // just to show paging
    this.pagingFilter.sortColumn = nameof<ScanDevice>("id");
    this.pagingFilter.sortDirection = SortingDirection.Descending;
  }

  onInitialize() {
    this.eventSubscriptions.push(
      this.eventBus.subscribe(Events.ScanDevices.Changed, this.loadData),
      this.eventBus.subscribe(Events.ScanDevices.Imported, this.loadData)
    );

    void this.loadEnums();
    void this.resetFilterAndLoad();
  }

  protected onActivate(): Promise<void> {
    return this.enums.fetchEnums();
  }

  @action.bound changeFilter(value: ScanDeviceFilter) {
    this.loadData();
  }
  protected resetFilterValues(filter: ScanDeviceFilter) {
    filter.ps_ids = [];
    filter.statuses = [];
    filter.depo_ids = [];
    filter.device_type_ids = [];
    filter.depo_name = undefined;
    filter.parcel_shop_name = undefined;
    filter.state_name = undefined;
    filter.price = undefined;
    filter.imei = undefined;
  }

  @bound
  onImportClick() {
    const onHide = () => (this.scanDevicesImportViewModel = undefined);
    this.scanDevicesImportViewModel = this.scanDevicesImportViewModelFactory(onHide);
  }

  @bound
  private async loadEnums() {
    const filter = new ParcelShopFilter();
    filter.depo_ids = toJS(this.userContext.assignedDepos);
    const ps = this.enums.value("access_point_partners", AccessPointPartners.PARCEL_SHOP, true);

    if (ps) {
      filter.type_ids = [ps.id];
    }
    filter.states = [ParcelShopStatus.Active, ParcelShopStatus.Unactive];

    const [parcelShops] = await this.parcelShopsRepository.getList({ offset: 0, limit: 1000, sortColumn: "name" }, filter);
    runInAction(() => {
      this.allowedParcelShops = parcelShops.map(x => ({ value: x.id, label: x.name } as ISelectItem));
    });
  }

  get depos() {
    return this.enums.values("depos").map(depoSelectOption);
  }

  getDepoName(code: number) {
    const depo = this.enums.value("depos", code);
    return formatDepoName(depo);
  }

  get statuses() {
    return this.enums.values("scan_device_status").map(item => ({ id: item.id, label: item.name }));
  }

  get devices() {
    return this.enums.values("scanners").map(item => ({ value: item.id, label: item.device_type } as ISelectItem));
  }

  getStatus(id: number) {
    return this.enums.values("scan_device_status").find(item => item.id === id);
  }

  getDeviceTypeName(id: number) {
    return this.enums.value("scanners", id)?.device_type;
  }

  get canImport() {
    return this.userContext.isAdmin;
  }

  @action.bound
  @watchBusy
  async loadData() {
    this.notifyNavigationChanged(); // save filter to history
    const filter = toJS(this.appliedFilter, { recurseEverything: true });

    return this.repository.getScanDevices(this.pagingFilter, filter).then(this.setData);
  }

  @action.bound async remove(item: ScanDevice) {
    const status = await this.confirmationService.showConfirmation(
      this.localizationService.translateGeneral("message_group.delete_confirm.message").replace("%imei%", item.imei),
      this.localizationService.translateGeneral("message_group.delete_confirm.title"),
      { variant: "danger", text: this.localizationService.translateGeneral("confirm_dialog.confirm") },
      { text: this.localizationService.translateGeneral("confirm_dialog.cancel") }
    );

    if (status) {
      try {
        await this.repository.deleteScanDevice(item.id);
        this.notificationService.addNotification(
          this.localizationService.translateGeneral("message_group.deleted").replace("%imei%", item.imei),
          SeverityLevel.success,
          ScanDevicesViewModel.notificationScope
        );
      } catch {
        // TODO: Add missing catch statement
      }
    }

    scrollTop();
  }

  @action.bound openDetail(item: ScanDevice) {
    return this.tryActivateChild(this.scanDeviceDetailViewModelFactory(item));
  }

  // eslint-disable-next-line
  @action.bound clearFilter(section: keyof ScanDeviceFilter) {
    switch (section) {
      case "depo_ids":
      case "ps_ids":
      case "statuses":
      case "device_type_ids":
        this.filter[section] = [];
        break;

      default:
        this.filter[section] = undefined as any;
        break;
    }

    void this.applyFilterAndLoad();
  }

  protected async findNavigationChild(navigationName: string) {
    if (+navigationName) {
      const scanDevice = await this.repository.getScanDevice(+navigationName);
      return this.scanDeviceDetailViewModelFactory(scanDevice);
    }
  }
}
