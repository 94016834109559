import React, { FunctionComponent } from "react";
import { ConfigurationSectionProps } from "../types";
import { observer } from "mobx-react-lite";
import { Col, Row } from "react-bootstrap";
import { Check } from "@frui.ts/bootstrap";

const Visibility: FunctionComponent<ConfigurationSectionProps> = observer(({ vm }) => {
  const { translateAttribute, translateGeneral: tg } = vm.localizationService;

  const ta = (attribute: string) => {
    return translateAttribute("parcel_shop", attribute);
  };

  return (
    <>
      <div className="sublabel">{tg(`${vm.isParcelBox || vm.isAlzaBox ? "parcel_box.box" : "parcel_shop.shop"}_visibility`)}</div>

      <Row className="active">
        <Col md={12}>
          <div className="form-group">
            <Check
              custom
              target={vm.item}
              property="isActive"
              label={ta("isActive")}
              id="isActive"
              disabled={!vm.canChooseActive || (vm.isParcelBox && !vm.item.parcelBox && !vm.isAlzaBox)}
            />
          </div>

          <div className="form-group">
            <Check
              custom
              target={vm.item}
              property="active_finder"
              label={ta("active_finder")}
              id="isActiveFinder"
              disabled={!vm.item.isActive || vm.isNetworkParent || vm.isAlzaBox}
            />
          </div>

          <div className="form-group">
            <Check
              custom
              target={vm.item}
              property="capacity_watcher"
              label={ta("capacity_watcher")}
              id="isCapacityWatcher"
              disabled={!vm.isEditing || vm.isNetworkParent || (vm.isParcelBox && !vm.item.parcelBox) || vm.isAlzaBox}
            />
          </div>

          {!vm.isParcelBox && !vm.isAlzaBox && (
            <>
              <div className="form-group">
                <Check
                  custom
                  target={vm.item}
                  property="vacation_watcher"
                  label={ta("vacation_watcher")}
                  disabled={!vm.isEditing || vm.isNetworkParent}
                  id="isVacationWatcher"
                />
              </div>

              <div className="form-group">
                <Check
                  custom
                  target={vm.item}
                  property="tribal_service_point"
                  label={ta("tribal_service_point")}
                  id="isTribalServicePoint"
                />
              </div>
            </>
          )}
        </Col>
        {vm.isParcelBox && !vm.item.parcelBox && (
          <Col>
            <span>{tg("parcel_box.warning_activation")}</span>
          </Col>
        )}
      </Row>
    </>
  );
});

export default Visibility;
