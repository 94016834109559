import Competition from "entities/competition";
import Opportunity from "entities/opportunity";
import { observable } from "mobx";
import { EntityValidationRules } from "services/validation";
import PotentialPsDetailDto from "../../../entities/potentialPsDetailDto";
import { classToPlain, plainToClass } from "class-transformer";
import Attachment from "../../../entities/attachment";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import ActivityDetailDto from "entities/activityDetailDto";

function omitProperties(item: Record<string, any>) {
  const modifiedObject = omitBy(
    omit(item, ["id", "psId", "ps_id", "created_at", "createdAt", "updated_at", "updatedAt"]),
    value => value === null
  );
  // Change object type from potential
  modifiedObject.entityType = 0;

  return modifiedObject;
}

export default class AdditionalInformation {
  @observable activities: ActivityDetailDto[];
  @observable contract_sign_date?: Date;
  @observable business_state?: number;
  @observable termination_reason?: number;
  @observable contract_ends_at?: Date;
  @observable competitions: Competition[];
  @observable business_type_id?: number;
  @observable potential_phase?: number;
  @observable potential_phase_note?: string;
  @observable attachments?: Attachment[];

  constructor() {
    this.activities = [];
    this.competitions = [];
    this.attachments = [];
    this.contract_sign_date = undefined;
    this.business_state = undefined;
    this.termination_reason = undefined;
    this.business_type_id = undefined;
  }

  static createFromPotential(potential: PotentialPsDetailDto) {
    const additionalInformation = new AdditionalInformation();
    additionalInformation.business_type_id = potential.businessTypeId;
    additionalInformation.activities = (potential.activities ?? []).map(activity =>
      plainToClass(ActivityDetailDto, omitProperties(classToPlain(activity)))
    );
    additionalInformation.competitions = (potential.competitions ?? []).map(competition =>
      plainToClass(Competition, omitProperties(classToPlain(competition)))
    );
    additionalInformation.attachments = (potential.attachments ?? []).map(attachment =>
      plainToClass(Attachment, classToPlain(attachment))
    );

    return additionalInformation;
  }
}

export const AdditionalInformationPotentialValidationRules = (
  requiredNotePhaseIds: number[]
): EntityValidationRules<AdditionalInformation> => ({
  potential_phase_note: {
    requiredIf: {
      condition: (x: AdditionalInformation) => requiredNotePhaseIds.includes(x.potential_phase ?? 0),
    },
  },
});
