import { attachAutomaticDirtyWatcher, isDirty, resetDirty } from "@frui.ts/dirtycheck";
import { watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import UsersRepository from "data/repositories/usersRepository";
import { RoleType, User, UserRole } from "manualEntities/user";
import { interfaces } from "inversify";
import { action, computed, observable, set, toJS } from "mobx";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import EventBus, { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import SecurityService from "services/securityService";
import { crmRoleSelectOption, depoSelectOption, psCategorySelectOption, scrollTop } from "utils/helpers";
import EditableDetailViewModelBase from "viewModels/editableDetailViewModelBase";
import UsersViewModel from "./usersViewModel";
import { getValidationMessage } from "@frui.ts/validation/dist/helpers";

export default class UserDetailViewModel extends EditableDetailViewModelBase<User, User> {
  set role(type: RoleType) {
    this.item.role = type === RoleType.ADMIN ? UserRole.PortalAdmin : UserRole.PortalUser;
  }

  @computed
  get role() {
    return this.item.role === UserRole.PortalAdmin ? RoleType.ADMIN : RoleType.USER;
  }

  set specialistType(items: UserRole[]) {
    if (items.length === 2 && items.includes(UserRole.PortalUserPB) && items.includes(UserRole.PortalUserPB)) {
      this.item.role = UserRole.PortalUser;
    } else if (items.length === 1) {
      this.item.role = items[0];
    } else {
      this.item.role = undefined;
    }
  }

  @computed
  get specialistType() {
    if (this.item.role === UserRole.PortalUser) {
      return [UserRole.PortalUserPB, UserRole.PortalUserPS];
    } else if (this.item.role === UserRole.PortalUserPB || this.item.role === UserRole.PortalUserPS) {
      return [this.item.role];
    } else {
      return [];
    }
  }

  @computed
  get specialistTypeIsInvalid() {
    return getValidationMessage(this.item, "role") !== undefined;
  }

  constructor(
    originalItem: User | undefined,
    private repository: UsersRepository,
    private eventBus: EventBus,
    private enumService: EnumService,
    private security: SecurityService,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService,
    public localizationService: LocalizationService
  ) {
    super(originalItem);

    if (originalItem) {
      this.name = this.localizationService.translateGeneral("user.edit.title");
      this.navigationName = originalItem.id.toString();
    } else {
      this.name = this.localizationService.translateGeneral("user.create.title");
      this.navigationName = "new";
    }
  }

  protected async loadDetail() {
    let editedItem: User;

    if (this.isCreating) {
      editedItem = observable.object(new User());
    } else {
      try {
        editedItem = await this.repository.getUser(this.originalItem!.id);
      } catch (e) {
        this.eventBus.publish(Events.General.ServerError, this.localizationService.translateGeneral("user.errors.fetch_failed"));
        void this.requestClose();
        throw e;
      }

      editedItem = Object.assign({}, toJS(editedItem));
      editedItem = observable.object(editedItem);
    }

    attachAutomaticDirtyWatcher(editedItem);
    attachAutomaticValidator(editedItem, User.ValidationRules, !this.isCreating);
    return editedItem;
  }

  @computed get canSave() {
    return !hasVisibleErrors(this.item);
  }

  @action.bound
  @watchBusy
  async update() {
    if (!validate(this.item)) {
      return;
    }

    try {
      await this.repository.updateUser(this.item);
      resetDirty(this.item);
      await this.requestClose();
      this.notificationService.addNotification(
        this.localizationService.translateGeneral("user.updated").replace("%email%", this.item.email),
        SeverityLevel.success,
        UsersViewModel.notificationScope
      );
    } catch {
      // TODO: Add missing catch statement
    }

    scrollTop();
  }

  @action.bound
  @watchBusy
  async create() {
    if (!validate(this.item)) {
      return;
    }

    try {
      await this.repository.createUser(this.item);
      resetDirty(this.item);
      await this.requestClose();
      this.notificationService.addNotification(
        this.localizationService.translateGeneral("user.created").replace("%email%", this.item.email),
        SeverityLevel.success,
        UsersViewModel.notificationScope
      );
    } catch {
      // TODO: Add missing catch statement
    }

    scrollTop();
  }

  @action.bound
  @watchBusy
  delete() {
    return this.repository.deleteUser(this.item).then(this.requestClose);
  }

  @action.bound
  toggleAllDepots() {
    if ((this.item.depo_ids || []).length === this.depots.length) {
      set(this.item, "depo_ids", []);
    } else {
      set(
        this.item,
        "depo_ids",
        this.depots.map(item => item.value)
      );
    }
  }

  @action.bound
  toggleAllCategories() {
    if ((this.item.ps_category_ids || []).length === this.categories.length) {
      set(this.item, "ps_category_ids", []);
    } else {
      set(
        this.item,
        "ps_category_ids",
        this.categories.map(item => item.value)
      );
    }
  }

  @computed
  get canChangeRole(): boolean {
    return this.security.isAllowed("edit", "user", this.item);
  }

  @computed
  get canChangeSpecialistType(): boolean {
    return this.canChangeRole && this.item.role !== UserRole.PortalAdmin;
  }

  @computed
  get allowedDepoSettings(): boolean {
    if (this.item.role) {
      return !(this.enumService.value("crm_users", this.item.role) ?? {}).admin;
    }

    return true;
  }

  @computed
  get allowedCategorySettings(): boolean {
    return this.allowedDepoSettings && this.item.role !== UserRole.PortalUserPB;
  }

  @computed
  get allChecked(): boolean {
    if (!this.allowedDepoSettings) {
      return true;
    } else {
      return this.item && this.item.depo_ids && this.item.depo_ids.length === this.depots.length;
    }
  }

  @computed
  get allCategoriesChecked(): boolean {
    if (!this.allowedDepoSettings) {
      return true;
    } else if (!this.allowedCategorySettings) {
      return false;
    } else {
      return this.item && this.item.ps_category_ids && this.item.ps_category_ids.length === this.categories.length;
    }
  }

  @computed
  get categoriesIndeterminate(): boolean {
    if (!this.allowedCategorySettings) {
      return false;
    }

    return (
      this.item &&
      this.item.ps_category_ids &&
      this.item.ps_category_ids.length !== this.categories.length &&
      this.item.ps_category_ids.length !== 0
    );
  }

  @computed
  get indeterminate(): boolean {
    if (!this.allowedDepoSettings) {
      return false;
    }

    return this.item && this.item.depo_ids && this.item.depo_ids.length !== this.depots.length && this.item.depo_ids.length !== 0;
  }

  canDeactivate(isClosing: boolean) {
    if (isClosing && isDirty(this.item)) {
      return this.confirmationService.showConfirmation(
        this.localizationService.translateGeneral("unsaved_changes_dialog.text"),
        this.localizationService.translateGeneral("unsaved_changes_dialog.title"),
        {
          text: this.localizationService.translateGeneral("unsaved_changes_dialog.exit"),
          variant: "outline-secondary",
        },
        {
          text: this.localizationService.translateGeneral("unsaved_changes_dialog.back"),
          variant: "primary",
        }
      );
    } else {
      return true;
    }
  }

  get roles() {
    return Object.values(RoleType).map(item => ({
      value: item,
      label: this.localizationService.translateGeneral(`role.${item}`),
    }));
  }

  get specialistTypes() {
    return this.enumService
      .values("crm_users")
      .filter(item => item.id !== UserRole.PortalAdmin && item.id !== UserRole.PortalUser)
      .map(crmRoleSelectOption);
  }

  get categories() {
    return this.enumService.values("ps_categories").map(psCategorySelectOption);
  }

  get depots() {
    return this.enumService.values("depos").map(depoSelectOption);
  }

  static Factory({ container }: interfaces.Context) {
    return (item?: any) => {
      return new UserDetailViewModel(
        item,
        container.get(UsersRepository),
        container.get(EventBus),
        container.get(EnumService),
        container.get(SecurityService),
        container.get(ConfirmationService),
        container.get(NotificationService),
        container.get(LocalizationService)
      );
    };
  }
}
