import { View, ViewComponent } from "@frui.ts/views";
import { DataTable } from "@frui.ts/dataviews";
import HeaderRow from "controls/headerRow";
import PagingInfo from "controls/pagingInfo";
import { Observer, observer } from "mobx-react-lite";
import React from "react";
import { Button, ButtonToolbar, Form } from "react-bootstrap";
import ScanDevicesViewModel from "viewModels/scanDevices/scanDevicesViewModel";
import FilterButton from "controls/filterButton";
import ItemsSelector from "controls/itemsSelector";
import ScanDevice from "manualEntities/scanDevice";
import { createColumnsContext, DataColumn } from "controls/tableHelpers";
import { CollectionCheck, Input } from "@frui.ts/bootstrap";
import LoadingOverlay from "controls/loadingOverlay";
import TablePager from "controls/tablePager";
import "./style.scss";

const tableColumns: DataColumn<ScanDevice, ScanDevicesViewModel>[] = [
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("scan_device", "device_type_id")}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.device_type_ids?.length}
          onClear={() => vm.clearFilter("device_type_ids")}>
          <ItemsSelector
            target={vm.filter}
            property="device_type_ids"
            searchTextProperty="device_name"
            items={vm.devices || []}
            searchPlaceholder={tg("search")}
            selectAllText={tg("select_all")}
          />
        </FilterButton>
      </>
    ),
    property: "device_type_id",
    valueFormatter: ({ value, context }) => value && context.vm.getDeviceTypeName(value),
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("scan_device", "imei")}
        <FilterButton {...filterButtonProps} isActive={!!vm.appliedFilter?.imei} onClear={() => vm.clearFilter("imei")}>
          <Form.Group>
            <Input placeholder={tg("search")} size="sm" data-search target={vm.filter} property="imei" />
          </Form.Group>
        </FilterButton>
      </>
    ),
    property: "imei",
    sortable: true,
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => (
      <>
        {ta("scan_device", "state")}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.statuses?.length}
          onClear={() => vm.clearFilter("statuses")}>
          <Form.Group>
            <Form.Group className="check-list">
              {vm.statuses.map(x => (
                <CollectionCheck key={x.id} custom target={vm.filter} property="statuses" value={x.id} label={x.label} />
              ))}
            </Form.Group>
          </Form.Group>
        </FilterButton>
      </>
    ),
    property: "state",
    cellFormatter: ({ value, item, context }) => {
      const status = context.vm.getStatus(value);
      return (
        <td key={item.id} className="device_state">
          <div className={`badge ${status?.color}`}>{status?.name}</div>
        </td>
      );
    },
    sortable: true,
  },
  {
    titleFactory: ({ vm, tg, tm, filterButtonProps }) => (
      <>
        {tm("parcel_shop", 1)}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.ps_ids?.length}
          onClear={() => vm.clearFilter("ps_ids")}>
          <ItemsSelector
            target={vm.filter}
            property="ps_ids"
            searchTextProperty="parcel_shop_name"
            items={vm.allowedParcelShops || []}
            searchPlaceholder={tg("search")}
            selectAllText={tg("select_all")}
          />
        </FilterButton>
      </>
    ),
    property: "ps_id",
    valueFormatter: ({ item }) => item.ps?.name,
    tag: "received",
  },
  {
    titleFactory: ({ vm, tm, tg, filterButtonProps }) => (
      <>
        {tm("depo", 1)}
        <FilterButton
          {...filterButtonProps}
          isActive={!!vm.appliedFilter?.depo_ids?.length}
          onClear={() => vm.clearFilter("depo_ids")}>
          <ItemsSelector
            target={vm.filter}
            property="depo_ids"
            searchTextProperty="depo_name"
            items={vm.depos || []}
            searchPlaceholder={tg("search")}
            selectAllText={tg("select_all")}
          />
        </FilterButton>
      </>
    ),
    property: "ps",
    valueFormatter: ({ value, context }) => value && context.vm.getDepoName(value.depo_id),
    sortable: true,
  },
  {
    titleFactory: ({ vm, ta, tg, filterButtonProps }) => <>{ta("scan_device", "price")}</>,
    property: "price",
    valueFormatter: ({ item, context }) => context.fc(item.price),
    sortable: true,
  },
];

const ScanDeviceList: ViewComponent<ScanDevicesViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateModel: tm } = vm.localizationService;

  const columnsContext = createColumnsContext(vm);

  return (
    <>
      <HeaderRow title={tm("scan_device", 3)}>
        {vm.canImport && (
          <ButtonToolbar className="justify-content-end">
            <Button variant="primary" onClick={vm.onImportClick}>
              {tg("scan_device.import.title")}
            </Button>
          </ButtonToolbar>
        )}
      </HeaderRow>

      <PagingInfo paging={vm.currentPaging} modelName="result" localization={vm.localizationService} />
      <div className="react-bootstrap-table">
        <DataTable
          className="table custom-table messages table-striped"
          pagingFilter={vm.pagingFilter}
          itemKey="id"
          items={vm.items || []}
          columns={tableColumns}
          context={columnsContext}
          onColumnSort={vm.sortByColumn}
          rowProps={item => ({ onClick: () => vm.openDetail(item) })}
        />
      </div>
      <TablePager vm={vm} />
      <LoadingOverlay loading={vm.busyWatcher.isBusy} />
      <Observer>{() => <View vm={vm.scanDevicesImportViewModel} />}</Observer>
    </>
  );
});

export default ScanDeviceList;
