import { bound } from "@frui.ts/helpers";
import { watchBusy } from "@frui.ts/screens";
import ParcelShopUsersRepository from "data/repositories/parcelShopUsersRepository";
import ParcelShopUser from "entities/user";
import { interfaces } from "inversify";
import ShopDetailContext from "models/shops/shopDetailContext";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import EventBus, { Events } from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService, { SeverityLevel } from "services/notificationService";
import ListViewModelBase from "viewModels/listViewModelBase";
import ParcelShopUserDetailViewModel from "./parcelShopUserDetailViewModel";
import ParcelShop from "../../../manualEntities/parcelShop";

export default class AccessControlViewModel extends ListViewModelBase<ParcelShopUser, null, ParcelShopUserDetailViewModel> {
  static DefaultNavigationName = "accesscontrol";
  static notificationScope = "users";

  get parcelShop() {
    return this.context.shop;
  }

  constructor(
    private context: ShopDetailContext<ParcelShop>,
    private repository: ParcelShopUsersRepository,
    private detailVMFactory: ReturnType<typeof ParcelShopUserDetailViewModel.Factory>,
    public localization: LocalizationService,
    private eventBus: EventBus,
    private enumService: EnumService,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService
  ) {
    super();
    this.name = localization.translateGeneral("parcel_shop.edit_access.title");
    this.navigationName = AccessControlViewModel.DefaultNavigationName;
    this.pagingFilter.limit = 10;
  }

  onInitialize() {
    this.eventSubscriptions.push(this.eventBus.subscribe(Events.ParcelShopUsers.Changed, this.loadData));

    return this.applyFilterAndLoad();
  }

  @bound
  add() {
    const viewModel = this.detailVMFactory(this.parcelShop.id);
    return this.tryActivateChild(viewModel);
  }

  openDetail(item: ParcelShopUser) {
    const viewModel = this.detailVMFactory(this.parcelShop.id, item.id);
    return this.tryActivateChild(viewModel);
  }

  @bound
  @watchBusy
  loadData() {
    return this.repository.getUsers(this.parcelShop.id, this.pagingFilter).then(this.setData);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues() {}

  @bound
  @watchBusy
  async delete(user: ParcelShopUser) {
    const confirm = await this.confirmationService.showConfirmation(
      this.localization.translateGeneral("parcel_shop.edit_access.delete_confirm.message"),
      this.localization.translateGeneral("parcel_shop.edit_access.delete_confirm.title"),
      {
        variant: "danger",
        text: this.localization.translateGeneral("parcel_shop.edit_access.delete_confirm.confirm"),
      }
    );

    if (confirm) {
      const status = await this.repository.deleteUser(this.parcelShop.id, user.id);

      if (status) {
        this.notificationService.addNotification(
          this.localization.translateGeneral("parcel_shop.edit_access.deleted").replace("%email%", user.email),
          SeverityLevel.success,
          AccessControlViewModel.notificationScope
        );
      }
    }
  }

  @bound
  async resetPassword(user: ParcelShopUser) {
    const status = await this.repository.resetPassword(this.parcelShop.id, user.id);

    if (status) {
      this.notificationService.addNotification(
        this.localization.translateGeneral("parcel_shop.edit_access.password_reset").replace("%email%", user.email),
        SeverityLevel.information,
        AccessControlViewModel.notificationScope
      );
    }
  }

  getRoleName(roleId: number) {
    return this.enumService.value("mobile_users", roleId)?.name;
  }

  protected onDeactivate(close: boolean): Promise<any> {
    this.notificationService.removeNotification(AccessControlViewModel.notificationScope);
    return super.onDeactivate(close);
  }

  static Factory({ container }: interfaces.Context) {
    return (context: ShopDetailContext<ParcelShop>) =>
      new AccessControlViewModel(
        context,
        container.get(ParcelShopUsersRepository),
        container.get(ParcelShopUserDetailViewModel.Factory),
        container.get(LocalizationService),
        container.get(EventBus),
        container.get(EnumService),
        container.get(ConfirmationService),
        container.get(NotificationService)
      );
  }
}
