import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import SubjectViewModel from "viewModels/subject/subjectViewModel";
import List from "./list";

const subjectsView: ViewComponent<SubjectViewModel> = observer(({ vm }) => {
  return vm.activeChild ? <View vm={vm.activeChild} /> : <List vm={vm} />;
});

export default registerView(subjectsView, SubjectViewModel);
