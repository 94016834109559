import { ViewProps } from "@frui.ts/views";
import Competition from "entities/competition";
import ListAttachmentDto from "entities/listAttachmentDto";
import ParcelShop from "manualEntities/parcelShop";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent, ReactNode, useMemo } from "react";
import { Button } from "react-bootstrap";
import { formatDate } from "utils/helpers";
import BoxDetailViewModel from "viewModels/shops/detail/boxDetailViewModel";
import PotentialDetailViewModel from "viewModels/shops/detail/potentialDetailViewModel";
import ShopDetailViewModel from "viewModels/shops/detail/shopDetailViewModel";
import HeaderRow from "../../../controls/headerRow";
import NetworkStatusType from "manualEntities/networkStatusType";
import PotentialPsDetailDto from "entities/potentialPsDetailDto";

const AdditionalInformation: FunctionComponent<
  ViewProps<ShopDetailViewModel | BoxDetailViewModel | PotentialDetailViewModel>
  // eslint-disable-next-line sonarjs/cognitive-complexity
> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute } = vm.localizationService;
  const ta = (key: string) => translateAttribute("additional_information", key);
  const shop = vm.item.shop;

  let contractSignPart;
  if (shop instanceof ParcelShop && shop.network_partner !== NetworkStatusType.Child) {
    contractSignPart = useMemo(() => {
      const date = shop.contractSignDate;
      if (date) {
        return (
          <tr>
            <th>{ta("contract_sign_date")}:</th>
            <td>{formatDate(date)}</td>
          </tr>
        );
      }

      return null;
    }, [shop.contractSignDate]);
  }

  const competitionsPart = useMemo(() => {
    if (vm.parcelShop.competitions?.length) {
      return vm.parcelShop.competitions.map((c: Competition, index: number) => (
        <tr key={c.id}>
          <th>{index === 0 && <>{ta("competition")}:</>}</th>
          <td>{vm.printCompetition(c)}</td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <th>{ta("competition")}:</th>
          <td></td>
        </tr>
      );
    }
  }, [vm.parcelShop.competitions]);

  let businessStatePart;
  if (!(vm instanceof PotentialDetailViewModel)) {
    businessStatePart = useMemo(
      () => (
        <tr>
          <th>{ta("business_state")}:</th>
          <td>{vm.printBusinessState()}</td>
        </tr>
      ),
      [vm.parcelShop.businessState]
    );
  }

  const attachmentsPart = useMemo(() => {
    const contracts: ListAttachmentDto[] = [];
    const docs: ListAttachmentDto[] = [];

    (vm.parcelShop.attachments ?? []).forEach(f => (f.documentTypeId ? docs.push(f) : contracts.push(f)));

    const renderDownloadLinks = (items: ListAttachmentDto[] = []) =>
      items
        .map<ReactNode>((f: ListAttachmentDto) => (
          <Button key={f.id} variant="link" className="inline" onClick={() => vm.downloadFile(f)}>
            {f.originalFilename}
          </Button>
        ))
        .map((item, index) => [index > 0 && ", ", item]);

    return (
      <>
        <tr>
          <th>{ta("contracts")}:</th>
          <td>{renderDownloadLinks(contracts)}</td>
        </tr>
        <tr>
          <th>{ta("other_docs")}:</th>
          <td>{renderDownloadLinks(docs)}</td>
        </tr>
      </>
    );
  }, [vm.parcelShop.attachments]);

  let businessTypePart;
  if ((shop instanceof ParcelShop && shop.network_partner !== NetworkStatusType.Parent) || shop instanceof PotentialPsDetailDto) {
    businessTypePart = useMemo(
      () => (
        <tr>
          <th>
            {ta("business_type")} {vm.isParcelShopType ? "parcelshopu" : "parcelboxu"}:
          </th>
          <td>{vm.printBusinessType()}</td>
        </tr>
      ),
      [vm.parcelShop.businessTypeId]
    );
  }

  let potentialPhase;
  if (vm instanceof PotentialDetailViewModel) {
    potentialPhase = useMemo(
      () => (
        <>
          <tr>
            <th>{ta("potential_phase")}:</th>
            <td>{vm.potentialPhase}</td>
          </tr>
          <tr>
            <th>{ta("potential_phase_note")}:</th>
            <td>{vm.parcelShop?.phaseNote}</td>
          </tr>
        </>
      ),
      [vm.parcelShop?.phase, vm.parcelShop?.phaseNote]
    );
  }

  return (
    <>
      <HeaderRow title={tg("parcel_shop.additional_information")} level={3} />
      <table className="table-form">
        <tbody>
          {contractSignPart}
          {competitionsPart}
          {businessStatePart}
          {attachmentsPart}
          {businessTypePart}
          {potentialPhase}
        </tbody>
      </table>
    </>
  );
});

export default AdditionalInformation;
