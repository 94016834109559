import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import CountsByState from "./countsByState";
import CountsByOpportunityState from "./countsByOpportunityState";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class GraphsDto {
  @observable
  @Type(() => CountsByState)
  @Expose({ name: "parcel_shops" })
  parcelShops!: CountsByState;

  @observable
  @Type(() => CountsByState)
  @Expose({ name: "parcel_boxes" })
  parcelBoxes!: CountsByState;

  @observable
  @Type(() => CountsByState)
  totals!: CountsByState;

  @observable
  @Type(() => CountsByOpportunityState)
  @Expose({ name: "opportunity_states" })
  opportunityStates!: CountsByOpportunityState;

  static ValidationRules = {
    parcelShops: { required: true },
    parcelBoxes: { required: true },
    totals: { required: true },
    opportunityStates: { required: true },
  };
}
