import { IPagingFilter, PagedQueryResult } from "@frui.ts/data";
import ActivityDashboardUpdateDto from "entities/activityDashboardUpdateDto";
import ActivityDetailDto from "entities/activityDetailDto";
import CreateActivityDto from "entities/createActivityDto";
import UpdateActivityDto from "entities/updateActivityDto";
import { Events } from "services/eventBus";
import { RepositoryBase } from "../repositoryBase";

export default class ActivitiesRepository extends RepositoryBase {
  getActivity(id: number): Promise<ActivityDetailDto> {
    return this.callApi(api => api.one("activities", id).getEntity(ActivityDetailDto));
  }

  getActivities(paging: IPagingFilter, filter: any): Promise<PagedQueryResult<ActivityDetailDto>> {
    return this.callApi(api => api.all("activities").getPagedEntities(ActivityDetailDto, paging, filter));
  }

  createActivities(payload: CreateActivityDto) {
    return this.callApi(api => api.all("activities").postEntity(payload), Events.Activities.Changed);
  }

  updateActivities(id: number, payload: UpdateActivityDto) {
    return this.callApi(api => api.one("activities", id).putEntity(payload), Events.Activities.Changed);
  }

  updateActivityStatus(id: number, payload: ActivityDashboardUpdateDto) {
    return this.callApi(api => api.one("activities/update_from_dashboard", id).putEntity(payload), Events.Activities.Changed);
  }

  deleteActivities(id: number) {
    return this.callApi(api => api.one("activities", id).delete(), Events.Activities.Changed);
  }
}
