import { ScreenBase } from "@frui.ts/screens";
import { action, computed, IReactionDisposer, observable, reaction } from "mobx";
import LocalizationService from "services/localizationService";
import { OpeningHoursDayModel, OpeningHoursModel } from "models/shops/newShop/openingHoursModel";
import OpeningHourDto from "entities/openingHourDto";

import { IOpeningHoursViewModel } from "../types";

export default abstract class OpeningHoursViewModelBase extends ScreenBase implements IOpeningHoursViewModel {
  protected reactionDisposers = [] as IReactionDisposer[];
  @observable days: OpeningHoursDayModel[];
  @observable alwaysOpen = false;
  @observable isAlwaysVisible = false;

  @computed
  get isAllAmActive() {
    return this.days.every(d => d.hours[0].isActive);
  }

  @computed
  get isAmIndeterminate() {
    return !this.isAllAmActive && this.days.some(d => d.hours[0].isActive);
  }

  @computed
  get openingHours(): OpeningHourDto[] {
    return (
      this.days?.flatMap(d =>
        d.hours.reduce((accumulator: OpeningHourDto[], h, i) => {
          if (h.isActive) {
            const hours = h as Required<OpeningHoursModel>;
            const entity = OpeningHourDto.fromModel(i, hours);
            accumulator.push(entity);
          }

          return accumulator;
        }, [])
      ) || []
    );
  }

  @action.bound
  toggleAlwaysOpen(value: boolean) {
    this.alwaysOpen = value;
    this.days.forEach(d => {
      d.hours[0].isActive = value;
      d.hours[0].openFrom = value ? "00:00" : undefined;
      d.hours[0].openTo = value ? "12:00" : undefined;

      d.hours[1].isActive = value;
      d.hours[1].openFrom = value ? "12:00" : undefined;
      d.hours[1].openTo = value ? "23:59" : undefined;
    });
  }

  @action.bound
  setNonStopHours() {
    this.toggleAllAmActive();
    this.toggleAllPmActive();
  }

  @action.bound
  toggleAllAmActive() {
    if (this.isAllAmActive) {
      this.days.forEach(d => (d.hours[0].isActive = false));
    } else {
      this.days.forEach(d => (d.hours[0].isActive = true));
    }
  }

  @computed
  get isAllPmActive() {
    return this.days.every(d => d.hours[1].isActive);
  }

  @computed
  get isPmIndeterminate() {
    return !this.isAllPmActive && this.days.some(d => d.hours[1].isActive);
  }

  @action.bound
  toggleAllPmActive() {
    if (this.isAllPmActive) {
      this.days.forEach(d => (d.hours[1].isActive = false));
    } else {
      this.days.forEach(d => (d.hours[1].isActive = true));
    }
  }

  constructor(public localizationService: LocalizationService) {
    super();
  }

  onInitialize() {
    this.registerReactions();
    return super.onInitialize();
  }

  protected registerReactions() {
    this.days.forEach(day => {
      this.reactionDisposers.push(
        reaction(
          () => day.hours[0].isActive,
          () => this.onOpeningHoursChange(day.hours[0])
        )
      );

      this.reactionDisposers.push(
        reaction(
          () => day.hours[1].isActive,
          () => this.onOpeningHoursChange(day.hours[1])
        )
      );
    });
  }

  protected onDeactivate(close: boolean) {
    if (close) {
      const reactionDisposers = this.reactionDisposers;
      this.reactionDisposers = [];
      reactionDisposers.forEach(disposer => disposer());
    }

    return super.onDeactivate(close);
  }

  onOpeningHoursChange(day: OpeningHoursModel) {
    if (!day.isActive) {
      day.openFrom = undefined;
      day.openTo = undefined;
    }
  }
}
