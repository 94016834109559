import React, { FunctionComponent } from "react";
import { ViewProps } from "@frui.ts/views";
import ShopDetailViewModel from "../../../viewModels/shops/detail/shopDetailViewModel";
import BoxDetailViewModel from "../../../viewModels/shops/detail/boxDetailViewModel";
import PotentialDetailViewModel from "../../../viewModels/shops/detail/potentialDetailViewModel";
import { observer } from "mobx-react-lite";
import ParcelShop from "../../../manualEntities/parcelShop";
import PotentialPsDetailDto from "entities/potentialPsDetailDto";

const Configuration: FunctionComponent<ViewProps<ShopDetailViewModel | BoxDetailViewModel | PotentialDetailViewModel>> = observer(
  ({ vm }) => {
    const { translateGeneral: tg, translateAttribute, formatCurrency: fc } = vm.localizationService;

    const ta = (key: string) => translateAttribute("parcel_shop", key);
    function formatGPS(item: ParcelShop | PotentialPsDetailDto) {
      let gpsText = "";
      if (item.latitude) {
        gpsText = `${item.latitude}N`;
      }

      if (item.longitude) {
        if (gpsText) {
          gpsText += ", ";
        }

        gpsText += `${item.longitude}E`;
      }

      return gpsText;
    }

    return (
      <>
        <h3>{tg("parcel_shop.configuration")}</h3>

        <table className="table-form">
          <tbody>
            <tr>
              <th>{ta("gps")}:</th>
              <td>{formatGPS(vm.parcelShop)}</td>
            </tr>
            {!(vm instanceof PotentialDetailViewModel) && (
              <>
                <tr>
                  <th>{ta("isActive")}:</th>
                  <td>{vm.activeName}</td>
                </tr>
                <tr>
                  <th>{ta("active_finder")}:</th>
                  <td>{vm.translateYesNo(vm.parcelShop.active_finder)}</td>
                </tr>
                <tr>
                  <th>{ta("capacity_watcher")}:</th>
                  <td>{vm.translateYesNo(vm.parcelShop.capacity_watcher)}</td>
                </tr>
                <tr>
                  <th>{ta("vacation_watcher")}:</th>
                  <td>{vm.translateYesNo(vm.parcelShop.vacation_watcher)}</td>
                </tr>
                <tr>
                  <th>{ta("tribal_service_point")}:</th>
                  <td>{vm.translateYesNo(vm.parcelShop.tribal_service_point)}</td>
                </tr>
                {vm.isParcelShopType && (
                  <tr>
                    <th>{ta("dhl_express")}:</th>
                    <td>{vm.translateYesNo(vm.parcelShop.dhl_express)}</td>
                  </tr>
                )}
              </>
            )}
            {!(vm instanceof PotentialDetailViewModel) && (
              <tr>
                <th>{ta("isNetworkPartner")}:</th>
                <td>{vm.networkPartnerName}</td>
              </tr>
            )}
            {!(vm instanceof PotentialDetailViewModel) && (
              <>
                <tr>
                  <th>{ta("card_payment")}:</th>
                  <td>{vm.translateYesNo(vm.parcelShop.card_payment)}</td>
                </tr>
                {!vm.parcelShop.payment_terminals?.length && vm.parcelShop.tid && (
                  <tr>
                    <th>{ta("terminal_id")}:</th>
                    <td>{vm.parcelShop.tid}</td>
                  </tr>
                )}
                {!!vm.parcelShop.payment_terminals?.length && (
                  <>
                    <tr>
                      <th>{ta("terminal_id")}:</th>
                      <td>{vm.parcelShop.payment_terminals[0].terminal_id}</td>
                    </tr>
                    <tr>
                      <th>{ta("terminal_sn")}:</th>
                      <td>{vm.parcelShop.payment_terminals[0].terminal_sn}</td>
                    </tr>
                  </>
                )}
                {!!vm.parcelShop.scan_devices?.length && (
                  <tr>
                    <th className="vert-top">{ta("scan_devices")}:</th>
                    {vm.parcelShop.scan_devices?.map(device => {
                      return (
                        <>
                          <tr>
                            <td>
                              {vm.getDeviceTypeName(device.device_type_id)} ({ta("imei_simple")}:&nbsp;{device.imei}),&nbsp;
                              {fc(device.price)}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tr>
                )}
              </>
            )}
            <tr style={{ verticalAlign: "top" }}>
              <th>{ta("enforce_sizes")}:</th>
              <td>{vm.translateYesNo(vm.parcelShop.enforceSizes)}</td>
            </tr>
            <tr style={{ verticalAlign: "top" }}>
              <th>{ta("accepted_size")}:</th>
              <td>{vm.parcelShop.acceptedSize?.join(", ")}</td>
            </tr>
            <tr style={{ verticalAlign: "top" }}>
              <th>{ta("description")}:</th>
              <td>{vm.parcelShop.description}</td>
            </tr>
            <tr style={{ verticalAlign: "top" }}>
              <th>{ta("description_crm")}:</th>
              <td>{vm.parcelShop.descriptionCrm}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
);

export default Configuration;
