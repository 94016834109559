import { ConductorOneChildActive, Router, ScreenBase } from "@frui.ts/screens";
import LogoutRepository from "data/repositories/logoutRepository";
import { action } from "mobx";
import AlertsService from "services/alertsService";
import ConfirmationService from "services/confirmationService";
import EventBus, { Events } from "services/eventBus";
import SecurityService from "services/securityService";
import UnreadMessageService from "services/unreadMessageService";
import UserContext from "services/userContext";
import { scrollTop } from "utils/helpers";
import DashboardViewModel from "./dashboard/dashboardViewModel";
import EnumerationsViewModel from "./enumerations/enumerationsViewModel";
import MessagesViewModel from "./messages/messagesViewModel";
import OpportunitiesViewModel from "./opportunities/opportunitiesViewModel";
import ParcelBoxesViewModel from "./parcelBox/parcelBoxesViewModel";
import PaymentTerminalViewModel from "./paymentTerminals/paymentTerminalsViewModel";
import NewPickupPointViewModel from "./pickupPoint/newPickupPointViewModel";
import ScanDevicesViewModel from "./scanDevices/scanDevicesViewModel";
import SettingsViewModel from "./settings/settingsViewModel";
import ShopsViewModel from "./shops/shopsViewModel";
import SubjectViewModel from "./subject/subjectViewModel";
import UsersViewModel from "./users/usersViewModel";

@Router.registerRoute({
  name: Router.Self,
  route: "",
  children: [ShopsViewModel, NewPickupPointViewModel, UsersViewModel, MessagesViewModel, OpportunitiesViewModel],
})
export default class RootViewModel extends ConductorOneChildActive<ScreenBase> {
  navigationName = "";
  name = "";
  docUrl = process.env.REACT_APP_DOC_URL ?? "https://www.ppl.cz";
  applicationVersion = process.env.REACT_APP_VERSION ?? "0.1";

  constructor(
    public userContext: UserContext,
    public confirmationService: ConfirmationService,
    public alertsService: AlertsService,
    dashboard: DashboardViewModel,
    security: SecurityService,
    shops: ShopsViewModel,
    users: UsersViewModel,
    messages: MessagesViewModel,
    subjects: SubjectViewModel,
    paymentTerminals: PaymentTerminalViewModel,
    parcelBoxes: ParcelBoxesViewModel,
    settings: SettingsViewModel,
    enumerations: EnumerationsViewModel,
    scanDevices: ScanDevicesViewModel,
    private logoutRepository: LogoutRepository,
    public unreadMessageService: UnreadMessageService,
    newPickupPoint: NewPickupPointViewModel,
    opportunities: OpportunitiesViewModel,
    eventBus: EventBus
  ) {
    super();

    this.children.push(dashboard);

    this.children.push(shops, newPickupPoint);

    if (security.isAllowed("read", "subject")) {
      this.children.push(subjects);
    }

    if (security.isAllowedToManage("parcelShop")) {
      this.children.push(messages);
    }

    if (security.isAllowed("edit", "paymentTerminal")) {
      this.children.push(paymentTerminals);
    }

    if (security.isAllowed("edit", "parcelBox")) {
      this.children.push(parcelBoxes);
    }

    if (security.isAllowed("read", "settings")) {
      this.children.push(settings, enumerations);
    }

    if (security.isAllowed("read", "user")) {
      this.children.push(users);
    }

    if (security.isAllowed("read", "scanDevices")) {
      this.children.push(scanDevices);
    }

    this.children.push(opportunities);

    eventBus.subscribe(Events.General.Unauthorized, this.refreshCredentials);
  }

  protected onInitialize() {
    if (!this.activeChild && this.children.length) {
      return this.tryActivateChild(this.children[0]);
    }
  }

  protected async changeActiveChild(newChild: ScreenBase | undefined, closePrevious: boolean): Promise<any> {
    if (this.activeChild) {
      const canChange = await this.activeChild.canDeactivate(closePrevious);
      if (!canChange) {
        return false;
      }
    }

    scrollTop("auto");
    return super.changeActiveChild(newChild, closePrevious);
  }

  @action.bound async doLogout() {
    try {
      await this.logoutRepository.logout();
      this.processLogoutResult();
    } catch (error) {
      console.log(error);
    }
  }

  @action.bound refreshCredentials() {
    if (this.userContext.isLogged) {
      this.processLogoutResult();
    }
  }

  protected processLogoutResult() {
    this.userContext.logout();
  }
}
