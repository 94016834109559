import { observable } from "mobx";
import { Expose } from "class-transformer";
import { uniqueId } from "lodash";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class ScannerDto {
  @observable
  @Expose({ name: "device_type" })
  deviceType!: string;

  @observable
  @Expose({ name: "daily_amortization_value" })
  dailyAmortizationValue!: number;

  static ValidationRules = {
    deviceType: { required: true },
    dailyAmortizationValue: { required: true, number: true },
  };
}

export default class CustomScanner extends ScannerDto {
  @observable
  id: string;

  constructor() {
    super();
    this.id = uniqueId();
  }
}
