import { ConductorAllChildrenActive, ScreenBase } from "@frui.ts/screens";
import { interfaces } from "inversify";
import ShopDetailContext from "models/shops/shopDetailContext";
import LocalizationService from "services/localizationService";
import BonusesViewModel from "./bonusesViewModel";
import InvoicesViewModel from "./invoicesViewModel";
import RecurringPaymentsViewModel from "./recurringPaymentsViewModel";
import PaymentStoppageViewModel from "./paymentStoppageViewModel";
import ParcelShop from "../../../../manualEntities/parcelShop";

export default class BoxInvoicingViewModel extends ConductorAllChildrenActive<ScreenBase> {
  static DefaultNavigationName = "invoicing";

  get parcelShop() {
    return this.context.shop;
  }

  constructor(
    private context: ShopDetailContext<ParcelShop>,
    public localization: LocalizationService,
    invoicesVMFactory: ReturnType<typeof InvoicesViewModel.Factory>,
    paymentStoppageVMFactory: ReturnType<typeof PaymentStoppageViewModel.Factory>,
    recurringPaymentsVMFactory: ReturnType<typeof RecurringPaymentsViewModel.Factory>,
    bonusesVMFactory: ReturnType<typeof BonusesViewModel.Factory>
  ) {
    super();
    this.name = localization.translateGeneral("parcel_box.invoicing.title");
    this.navigationName = BoxInvoicingViewModel.DefaultNavigationName;
    this.childNavigationPathClosed = true;

    this.children.push(
      invoicesVMFactory(context.shop.id, true),
      paymentStoppageVMFactory(context),
      recurringPaymentsVMFactory(context.shop.id),
      bonusesVMFactory(context.shop.id, true)
    );
  }

  static Factory({ container }: interfaces.Context) {
    return (context: ShopDetailContext<ParcelShop>) =>
      new BoxInvoicingViewModel(
        context,
        container.get(LocalizationService),
        container.get(InvoicesViewModel.Factory),
        container.get(PaymentStoppageViewModel.Factory),
        container.get(RecurringPaymentsViewModel.Factory),
        container.get(BonusesViewModel.Factory)
      );
  }
}
