import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class UpdatePsRewardsDto {
  @observable
  @Expose({ name: "fm_reward" })
  fmReward!: number;

  @observable
  @Expose({ name: "lm_reward" })
  lmReward!: number;

  @observable
  @Expose({ name: "lmcod_reward" })
  lmcodReward!: number;

  static ValidationRules = {
    fmReward: { required: true, number: true },
    lmReward: { required: true, number: true },
    lmcodReward: { required: true, number: true },
  };
}
