import { Check, Input } from "@frui.ts/bootstrap";
import { registerView, ViewComponent } from "@frui.ts/views";
import logo from "assets/logo.svg";
import "assets/themes/default.theme.scss";
import { StringInput } from "controls/stringInput";
import { observer } from "mobx-react-lite";
import React from "react";
import { Alert, Button, Form } from "react-bootstrap";
import LoginViewModel from "viewModels/loginViewModel";
import preventDefault from "../preventDefault";
import "./style.scss";

const loginView: ViewComponent<LoginViewModel> = observer(({ vm }) => {
  return (
    <div className="wrapper-login">
      <Form onSubmit={preventDefault(vm.doLogin)} className="form-login">
        <img className="mb-4 mx-auto d-block" src={logo} alt="PPL" />
        <div className="card">
          <fieldset className="card-body">
            {vm.errorMessage && <Alert variant="danger">{vm.errorMessage}</Alert>}
            <Form.Group controlId="login">
              <Form.Label>{vm.localizationService.translateAttribute("login", "login")}</Form.Label>
              <StringInput trim={true} target={vm.credentials} property="email" />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>{vm.localizationService.translateAttribute("login", "password")}</Form.Label>
              <Input target={vm.credentials} property="password" type="password" />
            </Form.Group>
            <Form.Group controlId="remember">
              <Check
                custom
                target={vm}
                property="remember"
                label={vm.localizationService.translateAttribute("login", "remember_me")}
                id="remember"
              />
            </Form.Group>
            <Button variant="primary" size="lg" type="submit" disabled={!vm.canDoLogin} block>
              {vm.localizationService.translateGeneral("login.button")}
            </Button>
          </fieldset>
        </div>
      </Form>
    </div>
  );
});
export default registerView(loginView, LoginViewModel);
