import { bound } from "@frui.ts/helpers";
import ParcelShopRepository from "data/repositories/parcelShopRepository";
import { interfaces } from "inversify";
import ParcelShopStatus from "manualEntities/parcelShopStatus";
import { observable, runInAction } from "mobx";
import ConfirmationService from "services/confirmationService";
import EnumService from "services/enum";
import EventBus from "services/eventBus";
import LocalizationService from "services/localizationService";
import NotificationService from "services/notificationService";
import SecurityService from "services/securityService";
import EditShopViewModel from "./edit/editShopViewModel";
import BoxInvoicingViewModel from "./invoicing/boxInvoicingViewModel";
import WarehouseViewModel from "./warehouse/warehouseViewModel";
import RecurringPayment from "entities/recurringPayment";
import AccessPointViewModel from "./accessPointViewModel";
import { Router, watchBusy } from "@frui.ts/screens";
import ParcelShop from "../../../manualEntities/parcelShop";
import AttachmentsRepository from "data/repositories/attachmentsRepository";
import EnumRepository from "data/repositories/enumRepository";
import UsersRepository from "data/repositories/usersRepository";
import ActivityDetailViewModel from "./activity/activityDetailViewModel";
import UserContext from "services/userContext";

@Router.registerRoute({ name: Router.Self, route: ":id", children: [EditShopViewModel] })
export default class BoxDetailViewModel extends AccessPointViewModel<ParcelShop> {
  @observable recurringPayments: RecurringPayment[] = [];

  constructor(
    shopId: number,
    warehouseVMFactory: ReturnType<typeof WarehouseViewModel.Factory>,
    editVMFactory: ReturnType<typeof EditShopViewModel.Factory>,
    activityVMFactory: ReturnType<typeof ActivityDetailViewModel.Factory>,
    protected repository: ParcelShopRepository,
    localizationService: LocalizationService,
    enumService: EnumService,
    security: SecurityService,
    confirmationService: ConfirmationService,
    notifications: NotificationService,
    eventBus: EventBus,
    private boxInvoicingVMFactory: ReturnType<typeof BoxInvoicingViewModel.Factory>,
    attachmentsRepository: AttachmentsRepository,
    enumRepository: EnumRepository,
    usersRepository: UsersRepository,
    userContext: UserContext,
    router: Router
  ) {
    super(
      shopId,
      warehouseVMFactory,
      editVMFactory,
      activityVMFactory,
      repository,
      localizationService,
      enumService,
      security,
      confirmationService,
      notifications,
      eventBus,
      attachmentsRepository,
      enumRepository,
      usersRepository,
      userContext,
      router
    );
  }

  @watchBusy
  async loadDetail() {
    if (this.shopId) {
      const recurringPayments = await this.repository.getRecurringPayments(this.shopId);

      runInAction(() => {
        this.recurringPayments = recurringPayments;
      });
    }
    return await super.loadDetail();
  }

  get depositAmount() {
    return this.recurringPayments.find(item => item.type === 0)?.price ?? 0;
  }

  get rentPrice() {
    return this.recurringPayments.find(item => item.type === 1)?.price ?? 0;
  }

  get invoicingCycle() {
    const rp = this.recurringPayments.find(item => item.type === 0);

    if (rp) {
      return this.enumService.value("pb_invoicing_cycles", rp.pbInvoicingCycle)?.name;
    }

    return "";
  }

  get canOpenWarehouse() {
    return this.parcelShop.status !== ParcelShopStatus.Disabled;
  }

  @bound openWarehouse() {
    const viewModel = this.warehouseVMFactory(this.item, true);
    this.tryActivateChild(viewModel);
  }

  get canOpenInvoicing() {
    return this.security.isAllowed("read", "invoicing", this.parcelShop);
  }

  @bound openInvoicing() {
    if (!this.canOpenInvoicing) {
      return;
    }

    const viewModel = this.boxInvoicingVMFactory(this.item);
    this.tryActivateChild(viewModel);
  }

  protected async findNavigationChild(name: string) {
    if (!this.item) {
      await this.onInitialize();
    }

    if (name === BoxInvoicingViewModel.DefaultNavigationName && this.canOpenInvoicing) {
      return this.boxInvoicingVMFactory(this.item);
    }

    if (name === WarehouseViewModel.DefaultNavigationName && this.canOpenWarehouse) {
      return this.warehouseVMFactory(this.item, true);
    }

    return super.findNavigationChild(name);
  }

  static Factory({ container }: interfaces.Context) {
    return (id: number) => {
      return new BoxDetailViewModel(
        id,
        container.get(WarehouseViewModel.Factory),
        container.get(EditShopViewModel.Factory),
        container.get(ActivityDetailViewModel.Factory),
        container.get(ParcelShopRepository),
        container.get(LocalizationService),
        container.get(EnumService),
        container.get(SecurityService),
        container.get(ConfirmationService),
        container.get(NotificationService),
        container.get(EventBus),
        container.get(BoxInvoicingViewModel.Factory),
        container.get(AttachmentsRepository),
        container.get(EnumRepository),
        container.get(UsersRepository),
        container.get(UserContext),
        container.get(Router)
      );
    };
  }
}
