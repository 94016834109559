import { FilteredListViewModel } from "@frui.ts/datascreens";
import { ColumnDefinition } from "@frui.ts/dataviews";
import LocalizationService from "services/localizationService";
import { FilterButtonProps } from "./filterButton";

export type ColumnsContext<TViewModel> = {
  vm: TViewModel;
  tg: (code: string) => string;
  ta: (model: string, name: string) => string;
  tm: (name: string, size: number) => string;
  fc: (value: number) => string;
  filterButtonProps: FilterButtonProps;
};

export type DataColumn<TEntity, TViewModel = any> = ColumnDefinition<TEntity, ColumnsContext<TViewModel>> & {
  tag?: string;
};

export function createColumnsContext<
  TViewModel extends FilteredListViewModel<any, any, any> & { localizationService: LocalizationService }
>(viewModel: TViewModel): ColumnsContext<TViewModel> {
  const { translateGeneral: tg, translateAttribute: ta, translateModel: tm, formatCurrency: fc } = viewModel.localizationService;

  const filterButtonProps: FilterButtonProps = {
    confirmLabel: tg("confirm_dialog.confirm"),
    clearLabel: tg("confirm_dialog.clear_all"),
    onConfirm: viewModel.applyFilterAndLoad,
  };

  return {
    vm: viewModel,
    tg,
    ta,
    tm,
    fc,
    filterButtonProps,
  };
}
