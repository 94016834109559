import { registerView, ViewComponent, preventDefault } from "@frui.ts/views";
import LoadingOverlay from "controls/loadingOverlay";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Button, ButtonToolbar, Col, Modal, Form, Alert } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import PaymentTerminalDetailViewModel from "viewModels/paymentTerminals/paymentTerminalDetailViewModel";
import { PaymentTerminalStatus, PaymentTerminalEvent } from "manualEntities/paymentTerminal";
import { formatDate } from "../../utils/helpers";
import { Input } from "@frui.ts/bootstrap";
import { TERMINAL_SERVICE_URL } from "views/parcelBox/detailOrderService";

const paymentTerminalDetailView: ViewComponent<PaymentTerminalDetailViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg, translateAttribute: ta, translateModel: tm } = vm.localizationService;

  // Hooks must be defined before first return, otherwise React complains
  // Error: Rendered more hooks than during the previous render.
  const [assignModal, setModalState] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  if (!vm.item) {
    return (
      <>
        <LoadingOverlay loading={vm.busyWatcher.isBusy} />
      </>
    );
  }

  const statusId = vm.item.status;
  const status = vm.getStatus(statusId);

  // Available actions
  const actions = [];

  // To Service action
  if (statusId !== PaymentTerminalStatus.Discarded && statusId !== PaymentTerminalStatus.Serviced) {
    actions.push(
      <Button
        variant="outline-secondary"
        key="to_service_action"
        onClick={() => vm.statusConfirmation(PaymentTerminalEvent.ToService)}>
        {tg("payment_terminal.events.to_service.button")}
      </Button>
    );
  }

  // Service actions
  if (statusId === PaymentTerminalStatus.Serviced) {
    actions.push(
      <a className="btn btn-outline-secondary" key="order_service" href={TERMINAL_SERVICE_URL} target="_new">
        {tg("payment_terminal.events.order_service.button")}
      </a>,
      <Button
        variant="outline-secondary"
        key="returned_from_service"
        onClick={() => vm.statusConfirmation(PaymentTerminalEvent.ReturnedFromService)}>
        {tg("payment_terminal.events.returned_from_service.button")}
      </Button>
    );
  }

  if (statusId === PaymentTerminalStatus.Available) {
    actions.push(
      <Button variant="outline-secondary" key="assign_to_ps" onClick={() => setModalState(true)}>
        {tg("payment_terminal.events.assign_to_ps.button")}
      </Button>
    );
  }

  // Available actions
  if (statusId === PaymentTerminalStatus.Assigned) {
    actions.push(
      <Button
        variant="outline-secondary"
        key="remove_from_ps"
        onClick={() => vm.statusConfirmation(PaymentTerminalEvent.RemoveFromPs)}>
        {tg("payment_terminal.events.remove_from_ps.button")}
      </Button>
    );
  }

  // Discard PS
  if (statusId !== PaymentTerminalStatus.Discarded) {
    actions.push(
      <Button variant="outline-danger" key="discard_action" onClick={() => vm.statusConfirmation(PaymentTerminalEvent.Discard)}>
        {tg("payment_terminal.events.discard.button")}
      </Button>
    );
  }

  // Parcel shop modal
  const assignTerminal = async () => {
    const result = await vm.assignPs();
    setModalState(result !== undefined);
    setErrorMessage(result);
  };

  return (
    <>
      <div className="header-row row">
        <div className="col-md">
          <h1>
            {vm.terminalTitle}
            <div className={`badge ${status!.color}`}>{status!.name}</div>
          </h1>
        </div>
      </div>
      {vm.item.terminal_id && (
        <>
          <span className="info">{ta("payment_terminal", "terminal_id")}:</span> {vm.item.terminal_id}
        </>
      )}
      {vm.item.ps && (
        <>
          <span className="dot" />
          <span className="info">{tm("parcel_shop", 1)}:</span> {vm.item.ps.name}
        </>
      )}
      {vm.item.ps && (
        <>
          <span className="dot" />
          <span className="info">{tm("depo", 1)}:</span> {vm.getDepoName(vm.item.ps.depo_id)}
        </>
      )}
      <br />
      <ButtonToolbar>{actions}</ButtonToolbar>
      <br />
      {vm.item.journals && vm.item.journals.length > 0 && (
        <Row>
          <Col>
            <h3>{tg("payment_terminal.history")}</h3>

            <div className="react-bootstrap-table">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{ta("payment_terminal", "date_from")}</th>
                    <th>{ta("payment_terminal", "date_to")}</th>
                    <th>{ta("payment_terminal", "status")}</th>
                    <th>{tm("parcel_shop", 1)}</th>
                  </tr>
                </thead>
                <tbody>
                  {vm.item.journals
                    .sort((a, b) => b.id - a.id)
                    .map(item => {
                      const itemStatus = vm.getStatus(item.status);

                      return (
                        <tr key={`journal-${item.id}`}>
                          <td>{formatDate(item.date_from)}</td>
                          <td>{item.date_to ? formatDate(item.date_to) : "Nyní"}</td>
                          <td>
                            <div className={`badge ${itemStatus!.color}`}>{itemStatus!.name}</div>
                          </td>
                          <td>{item.ps?.name}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      )}
      <Modal centered={true} show={assignModal} onHide={() => setModalState(false)}>
        <Form onSubmit={preventDefault(assignTerminal)}>
          <Modal.Header closeButton>
            <Modal.Title>{tg("payment_terminal.events.assign_to_ps.button")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {tg("payment_terminal.events.assign_to_ps.body")}
            <br />
            <br />
            <Form.Row>
              <Form.Group as={Col} md={8} className="required">
                <Form.Label>{ta("parcel_shop", "cust_id")}</Form.Label>
                <Input target={vm} property="custId" type="number" />
              </Form.Group>
            </Form.Row>

            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <LoadingOverlay loading={vm.busyWatcher.isBusy} />
          </Modal.Body>
          <Modal.Footer className="justify-content-start">
            <Button type="submit">{tg("confirm_dialog.confirm")}</Button>
            <Button onClick={() => setModalState(false)} variant="outline-secondary">
              {tg("confirm_dialog.cancel")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
});

export default registerView(paymentTerminalDetailView, PaymentTerminalDetailViewModel);
