import { handlePageChanged, IPagerProps } from "@frui.ts/data";
import { observer } from "mobx-react-lite";
import React from "react";

const itemClass = "page-item";
const disabledItemClass = "page-item disabled";
const activeItemClass = "page-item active";
const linkClass = "page-link";

const displayPagesOffset = 2;
const totalPagesToDisplay = 2 * displayPagesOffset + 1;

const PageButton: React.FunctionComponent<{ onClick?: React.MouseEventHandler<HTMLButtonElement>; page: number }> = ({
  onClick,
  page,
  children,
}) => (
  <button type="button" data-page={page} className={linkClass} onClick={onClick}>
    {children}
  </button>
);

const Pager: React.FunctionComponent<Partial<IPagerProps>> = observer(({ paging, filter, onPageChanged }) => {
  if (!paging) {
    return <React.Fragment />;
  }

  const pageChangedHandler: React.MouseEventHandler<HTMLButtonElement> = e => {
    const pageNumber = +(e.currentTarget.dataset.page || 0);
    handlePageChanged(pageNumber, filter, onPageChanged);
  };

  const totalPages = Math.ceil(paging.totalItems / paging.limit);
  if (!totalPages) {
    return (
      <nav aria-label="Page navigation">
        <ul className="pagination">
          <li className={disabledItemClass}>
            <PageButton page={1}>0</PageButton>
          </li>
        </ul>
      </nav>
    );
  }

  const currentPage = Math.ceil(paging.offset / paging.limit) + 1;

  const minPageOffset = Math.max(currentPage + displayPagesOffset - totalPages, 0) + displayPagesOffset;
  const minPage = Math.max(1, currentPage - minPageOffset);

  const pageNumbers = [];
  for (let i = minPage; i <= totalPages && pageNumbers.length < totalPagesToDisplay; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">
        {pageNumbers[0] !== 1 && (
          <li className={itemClass}>
            <PageButton page={1} onClick={pageChangedHandler}>
              &lt;&lt;
            </PageButton>
          </li>
        )}

        {currentPage > 1 && (
          <li className={itemClass}>
            <PageButton page={currentPage - 1} onClick={pageChangedHandler}>
              &lt;
            </PageButton>
          </li>
        )}

        {pageNumbers.map(x => (
          <li key={x} className={currentPage === x ? activeItemClass : itemClass}>
            <PageButton page={x} onClick={pageChangedHandler}>
              {x}
            </PageButton>
          </li>
        ))}

        {currentPage < totalPages && (
          <li className={itemClass}>
            <PageButton page={currentPage + 1} onClick={pageChangedHandler}>
              &gt;
            </PageButton>
          </li>
        )}

        {pageNumbers[pageNumbers.length - 1] !== totalPages && (
          <li className={itemClass}>
            <PageButton page={totalPages} onClick={pageChangedHandler}>
              &gt;&gt;
            </PageButton>
          </li>
        )}
      </ul>
    </nav>
  );
});
export default Pager;
